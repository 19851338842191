import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { doRequest } from '../../../../../shared/redux/sagas/api';
import { takeEvery } from 'redux-saga';
import * as types from './showInMobile.constants';
import {
  getCategoryShowInMobileHomeSuccess,
  CategoryShowInMobileHomePositionChangeSuccess
} from './showInMobile.actions';

function* getUserLocale() {
  const [id, languageList] = yield select(s => [s.profile.language, s.languageList.languageList]);
  const language = languageList.find(language => language.id === id);
  return language.locale;
}
function getLocaleBasedTitle(language = {}, keyToExtract, locale) {
  if (locale in language) {
    if (keyToExtract && language[locale][keyToExtract]) {
      return language[locale][keyToExtract];
    } else if (!keyToExtract) {
      return language[locale];
    }
  }

  if ('en_US' in language) {
    if (keyToExtract && language.en_US[keyToExtract]) {
      return language.en_US[keyToExtract];
    } else if (!keyToExtract) {
      return language.en_US;
    }
  }

  for (let lang of Object.values(language)) {
    if (lang) {
      if (!keyToExtract) {
        return lang;
      }

      if (keyToExtract && lang[keyToExtract]) {
        return lang[keyToExtract];
      }
    }
  }

  if (!keyToExtract) {
    return {};
  }

  return '';
}
function getCategoryTitle(categories, locale) {
  return categories.map(category => {
    category.title = category.languages
      ? getLocaleBasedTitle(category.languages, '', locale)
      : category.title;
    return { ...category };
  });
}

function* getCategoryShowInMobile() {
  const locale = yield call(getUserLocale);
  try {
    const response = yield call(doRequest, {
      url: 'prodsup/categories/categoryShowInMobileHome'
    });

    if (response.status === 200) {
      getCategoryTitle(response.data, locale);
      yield put(getCategoryShowInMobileHomeSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
  }
}
function* setCategoryCircleNewPosition(params) {
  // const data = {newPosition:params.payload.newPosition, categoryId:params.payload.categoryId}
  // console.log('data',data)
  // try {
  //     const response = yield call(doRequest, {
  //         method: 'PUT',
  //         url: 'prodsup/category-circle-position/position',
  //         data: data
  //     });
  //     yield put(positionChanged())
  // } catch (error) {
  //     console.log(error.response)
  // }
}

function* getCategoryShowInMobileHomeWatcher() {
  yield takeLatest(types.GET_SHOW_IN_MOBILE_CATEGORIES, getCategoryShowInMobile);
}
function* setCategoryShowInMobileHomeNewPositionWatcher() {
  yield takeLatest(types.SHOW_IN_MOBILE_POSITION_CHANGE_SUCCESS, setCategoryCircleNewPosition);
}

export default function* rootSaga() {
  yield all([
    fork(getCategoryShowInMobileHomeWatcher),
    fork(setCategoryShowInMobileHomeNewPositionWatcher)
  ]);
}
