import textEditorType from './textEditorPopup.constants';

const initialState = {
  isModalOpen: false,
  spinLoader: false,
  editorBtnDisable: false,
  originalString: '',
  manipulateString: '',
  ingArray: [],
  eNumArray: [],
  modifyObjArray: [],
  editAddSelectedValueNode: {},
  originalIngArray: [],
  originalEnumArray: [],
  originalEnumArrayIds: [],
  productDetailsForm: null,
  originalIngValue: null,
  editorType: null,
  leftSecIngredients: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case textEditorType.MODAL_OPEN_CLOSE_STATE: {
      const { modalState, ingArray, eNumArray, eNumArrayIds, form, editorType } = payload;

      if (modalState === 'open') {
        return {
          ...state,
          isModalOpen: true,
          originalIngArray: ingArray,
          originalEnumArray: eNumArray,
          originalEnumArrayIds: eNumArrayIds,
          productDetailsForm: form,
          editorType: editorType
        };
      } else {
        return {
          ...state,
          isModalOpen: false,
          spinLoader: false,
          editorBtnDisable: false,
          originalString: '',
          manipulateString: '',
          modifyObjArray: [],
          editAddSelectedValueNode: {},
          originalIngArray: [],
          originalEnumArray: [],
          originalEnumArrayIds: [],
          productDetailsForm: null,
          originalIngValue: null,
          editorType: null
          // leftSecIngredients: null
        };
      }
    }
    case textEditorType.Set_LEFT_INGREDIENTS: {
      const { data } = payload;

      return {
        ...state,
        leftSecIngredients: data
      };
    }
    case textEditorType.ORIGINAL_ING_VALUE: {
      const { data } = payload;
      if (!state.originalIngValue) {
        return {
          ...state,
          originalIngValue: data
        };
      } else {
        return;
      }
    }
    case textEditorType.SPIN_LOADER: {
      const { loader } = payload;

      if (loader === 'true') {
        return {
          ...state,
          spinLoader: true
        };
      } else {
        return {
          ...state,
          spinLoader: false
        };
      }
    }
    case textEditorType.EDITOR_BTN_DISABLE: {
      const { disabled } = payload;

      if (disabled === 'true') {
        return {
          ...state,
          editorBtnDisable: true
        };
      } else {
        return {
          ...state,
          editorBtnDisable: false
        };
      }
    }
    case textEditorType.SET_ORIGINAL_STRING: {
      const { data } = payload;

      return {
        ...state,
        originalString: data
      };
    }
    case textEditorType.SET_MANIPULATE_STRING_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        manipulateString: data
      };
    }
    case textEditorType.RESET: {
      return {
        ...state,
        isModalOpen: false,
        spinLoader: false,
        editorBtnDisable: false,
        originalString: '',
        manipulateString: '',
        modifyObjArray: []
      };
    }

    case textEditorType.SET_INGREDIENT_ARRAY: {
      const { data } = payload;

      return {
        ...state,
        ingArray: data
      };
    }

    case textEditorType.SET_ENUMBERS_ARRAY: {
      const { data } = payload;

      return {
        ...state,
        eNumArray: data
      };
    }

    case textEditorType.SET_TEXT_EDITOR_DATA: {
      //console.log('SET_TEXT_EDITOR_DATA', payload);
      const { data } = payload;

      return {
        ...state,
        modifyObjArray: data
      };
    }
    case textEditorType.SET_EDIT_ADD_SELECTED_VALUE_NODE: {
      //console.log('SET_EDIT_ADD_SELECTED_VALUE_NODE', payload);
      const { data } = payload;

      return {
        ...state,
        editAddSelectedValueNode: data
      };
    }

    case textEditorType.ADD_NEW_INGREDIENT_TO_ARRAY: {
      // console.log('SET_EDIT_ADD_SELECTED_VALUE_NODE', payload);
      const { data } = payload;

      let oldArray = [...state.ingArray];

      if (data && data.length > 0) {
        data.forEach(element => {
          //console.log(element);
          oldArray.push(element);
        });
      }

      return {
        ...state,
        ingArray: oldArray
      };
    }

    case textEditorType.UPDATE_INGREDIENT_TO_ARRAY: {
      //console.log('SET_EDIT_ADD_SELECTED_VALUE_NODE', payload);
      const { data } = payload;

      let { array, id } = data;

      let oldArray = [...state.ingArray];

      let filteredArray = oldArray.filter(item => item.id != id);
      if (array && array.length > 0) {
        array.forEach(element => {
          //console.log(element);
          filteredArray.push(element);
        });
      }

      //console.log(filteredArray);

      return {
        ...state,
        ingArray: filteredArray
      };
    }

    default:
      return state;
  }
};
