import { combineReducers } from 'redux';
import catalogue_productsReducer from './products/redux/catalogue_products.reducer';
import catalogue_distributorReducer from './distributor/redux/catalogue_distributor.reducer';
import catalogue_manufacturerReducer from './manufacturer/redux/catalogue_manufacturer.reducer';
import catalogue_brandReducer from './brand/redux/catalogue_brand.reducer';

export default combineReducers({
  products: catalogue_productsReducer,
  distributor: catalogue_distributorReducer,
  manufacturer: catalogue_manufacturerReducer,
  brand: catalogue_brandReducer
});
