import productTypes from '../../../module/contentManagement/catalogue/products/redux/catalogue_products.constants';

/**
 * @description
 *   Reducer to index the product catalogue items to efficiently
 *  retrieve brand item and set image of the brand in catalogue_producer_reducer.js
 */
export default (state = {}, { type, payload }) => {
  switch (type) {
    case productTypes.FETCH_ROWS_SUCCESS: {
      const { data } = payload;

      const index = {};
      if (data.length) {
        for (let i = 0; i < data.length; i++) {
          index[data[i].id] = i;
        }
      }

      return index;
    }

    default:
      return state;
  }
};
