import { actionCreator } from 'redux-action-creator';
import moderationTypes from './details.constants';

export const getDataList = actionCreator(moderationTypes.GET_DATA_LIST);

export const getNutrientsList = actionCreator(moderationTypes.GET_LIST_NUTRITION_VALUES, 'data');
export const getNutrientsValueListSuccess = actionCreator(
  moderationTypes.GET_NUTRITION_VALUE_LIST_SUCCESS,
  'data'
);
export const updateNutritionLoadedStatus = actionCreator(
  moderationTypes.UPDATE_NUTRITION_LOADED_STATUS,
  'data'
);
export const getProductList = actionCreator(moderationTypes.GET_PRODUCT_LIST);
export const getProductListSuccess = actionCreator(
  moderationTypes.GET_PRODUCT_LIST_SUCCESS,
  'data'
);
export const getProductByLocale = actionCreator(moderationTypes.GET_PRODUCT_BY_LOCALE, 'data');
export const setProductStore = actionCreator(moderationTypes.SET_PRODUCT_STORE, 'store');
export const getProductByLocalSuccess = actionCreator(
  moderationTypes.GET_PRODUCT_BY_LOCALE_SUCCESS,
  'data'
);
export const updateCategoryLoader = actionCreator(moderationTypes.UPDATE_CATEGORY_LOADER);
export const getIngredientList = actionCreator(moderationTypes.GET_INGREDIENT_LIST, 'data');
export const updateIngredientLoader = actionCreator(
  moderationTypes.UPDATE_INGREDIENT_LOADER,
  'loader'
);
export const getIngredientListSuccess = actionCreator(
  moderationTypes.GET_INGREDIENT_LIST_SUCCESS,
  'data'
);
export const getStoreTypeList = actionCreator(moderationTypes.GET_STORE_TYPE_LIST);
export const getStoreTypeListSuccess = actionCreator(
  moderationTypes.GET_STORE_TYPE_LIST_SUCCESS,
  'data'
);
export const getEnumberList = actionCreator(moderationTypes.GET_ENUMBER_LIST, 'data');
export const getEnumberListSuccess = actionCreator(
  moderationTypes.GET_ENUMBER_LIST_SUCCESS,
  'data'
);
export const addProduct = actionCreator(moderationTypes.ADD_PRODUCT, 'data');
export const editProduct = actionCreator(moderationTypes.EDIT_PRODUCT, 'data');
export const deleteProduct = actionCreator(moderationTypes.DELETE_PRODUCT, 'data');
export const deleteTicket = actionCreator(moderationTypes.DELETE_TICKET, 'data', 'history');
export const deleteProductSuccess = actionCreator(moderationTypes.DELETE_PRODUCT_SUCCESS, 'data');
export const updateProductDetailLoadStatus = actionCreator(
  moderationTypes.UPDATE_PRODUCT_DETAIL_LOAD_STATUS,
  'data'
);
export const updateProductLocale = actionCreator(moderationTypes.UPDATE_PRODUCT_LOCALE, 'data');
export const updateSaveButtonStatus = actionCreator(moderationTypes.UPDATE_SAVE_BUTTON_STATUS);
export const getMayContainValueSuccess = actionCreator(
  moderationTypes.GET_MAY_CONTAIN_VALUES_SUCCESS,
  'data'
);
export const getLabelsList = actionCreator(moderationTypes.GET_LABEL_LIST);
export const setFilterLabelList = actionCreator(moderationTypes.SET_FILTER_LABEL_LIST, 'data');
export const getLabelsListSuccess = actionCreator(moderationTypes.GET_LABEL_LIST_SUCCESS, 'data');
export const mayContaintLoader = actionCreator(moderationTypes.MAY_CONTAINT_LOADER, 'loader');
export const lablesListLoader = actionCreator(moderationTypes.LABEL_LIST_LOADER, 'loader');
export const reset = actionCreator(moderationTypes.RESET);

export const setDefaultUnit = actionCreator(moderationTypes.GET_DEFAULT_UNIT, 'unit');

export const currancyObjUpdate = actionCreator(moderationTypes.CURRANCY_OBJ_UPDATE, 'currancy');

export const getProductID = actionCreator(moderationTypes.GET_PRODUCT_ID, 'id');
export const disabledPrice = actionCreator(moderationTypes.DISABLED_PRICE_INPUT, 'value');

export const getSIngleData = actionCreator(moderationTypes.GET_SINGLE_DATA, 'id', 'location');
export const getSIngleDataSuccess = actionCreator(
  moderationTypes.GET_SINGLE_DATA_SUCCESS,
  'data',
  'droupdownLocale'
);

export const setFormChangesArray = actionCreator(moderationTypes.SET_FORMCHANGES_ARRAY, 'data');
export const getSelectedLocale = actionCreator(
  moderationTypes.GET_ROW_SELECTED_LOCALE,
  'locale',
  'record',
  'getEditObj'
);
export const manufactureList = actionCreator(moderationTypes.GET_MANUFACTURE_LIST);
export const manufactureListSuccess = actionCreator(
  moderationTypes.GET_MANUFACTURE_LIST_SUCCESS,
  'data'
);
export const brandList = actionCreator(moderationTypes.GET_BRAND_LIST);
export const brandListSuccess = actionCreator(moderationTypes.GET_BRAND_LIST_SUCCESS, 'data');
export const distributorList = actionCreator(moderationTypes.GET_DISTRIBUTOR_LIST);
export const distributorListSuccess = actionCreator(
  moderationTypes.GET_DISTRIBUTOR_LIST_SUCCESS,
  'data'
);

export const getTagList = actionCreator(moderationTypes.GET_TAG_LIST);
export const getTagListSuccess = actionCreator(
  moderationTypes.GET_TAG_LIST_SUCCESS,
  'data',
  'locale'
);
export const setTagImageSuccess = actionCreator(
  moderationTypes.SET_TAG_IMAGE,
  'id',
  'image',
  'indexes'
);

export const getStoreList = actionCreator(moderationTypes.GET_STORE_LIST, 'data');
export const getStoreListSuccess = actionCreator(moderationTypes.GET_STORE_LIST_SUCCESS, 'data');
export const setStoreImage = actionCreator(moderationTypes.SET_STORE_IMAGE, 'images', 'indexes');
export const setStoreTypeImageSuccess = actionCreator(
  moderationTypes.SET_STORE_TYPE_IMAGE,
  'id',
  'image',
  'indexes'
);

export const getCategoryList = actionCreator(moderationTypes.GET_CATEGORY_LIST);
export const getCategoryListSuccess = actionCreator(
  moderationTypes.GET_CATEGORY_LIST_SUCCESS,
  'data'
);
export const getBracketprice = actionCreator(moderationTypes.GET_BRACKET_PRICE, 'oldData');
export const getBracketpriceSuccess = actionCreator(
  moderationTypes.GET_BRACKET_PRICE_SUCCESS,
  'value',
  'pid'
);
export const getOldSingleProductData = actionCreator(
  moderationTypes.GET_OLD_SINGLE_PRODUCT_DATA,
  'productID',
  'locale',
  'collectionData',
  'location'
);

export const getOldSingleProductDataSuccess = actionCreator(
  moderationTypes.GET_OLD_SINGLE_PRODUCT_DATA_SUCCESS,
  'data'
);
export const getStoreUnicProductPrice = actionCreator(
  moderationTypes.GET_STORE_UNIC_PRODUCT_PRICE,
  'requestObj'
);

export const getStoreUnicProductPriceSuccess = actionCreator(
  moderationTypes.GET_STORE_UNIC_PRODUCT_PRICE_SUCCESS,
  'data'
);
export const setNutritionInputValues = actionCreator(
  moderationTypes.SET_NUTRITION_INPUT_VALUES,
  'data',
  'type'
);
export const getTiketSelectedStoreImage = actionCreator(
  moderationTypes.GET_TIKET_SELECTED_STORE_IMAGE,
  'storeId',
  'collectionId'
);
export const getTiketSelectedStoreImageSuccess = actionCreator(
  moderationTypes.GET_TIKET_SELECTED_STORE_IMAGE_SUCCESS,
  'image',
  'id'
);

export const getOldStorePrice = actionCreator(moderationTypes.GET_OLD_STORE_PRICE_DATA);

export const getOldStorePriceSuccess = actionCreator(
  moderationTypes.GET_OLD_STORE_PRICE_DATA_SUCCESS,
  'data'
);
export const setTicketSaveLoader = actionCreator(moderationTypes.SET_TICKET_SAVE_LOADER, 'data');
export const updateProductDataIngredientOnly = actionCreator(
  moderationTypes.UPDATE_PRODUCT_DATA_INGREDIENT_ONLY,
  'data'
);
