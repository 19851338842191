import React from "react";
import {Button, Checkbox, Form, Icon, Input, message} from "antd";
import {connect} from "react-redux";
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn
} from "appRedux/actions/Auth";
import {injectIntl } from 'react-intl';
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";
import {Link} from 'react-router-dom';

const FormItem = Form.Item;

export class SignIn extends React.Component {

  handleSubmit = (e) => {
 
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
      
        this.props.showAuthLoader();
        this.props.userSignIn(values);
      }
    });
  };

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  renderErrorMessage = () => {
    //const {intl}=this.props
    setTimeout(() => {
      message.error('Email or Password is invalid');
    }, 10);
    
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {showMessage, loader, intl} = this.props;
  
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src="https://via.placeholder.com/272x395" alt='Neature'/>
              </div>
              <div className="gx-app-logo-wid fixedTxtContenWithCharacter">
                <h1>
                Sign In
                  {/* <IntlMessages defaultMessage='Sign In' id="AuthenticationPages.SignIn.SignIn"/> */}
                  </h1>
                <p>
                By Signing Up, you can avail full features of our services.
                  {/* <IntlMessages defaultMessage='By Signing Up, you can avail full features of our services.' id="AuthenticationPages.SignIn.Txt_content"/> */}
                  </p>
                <p>
                Get an account !!!
                  {/* <IntlMessages defaultMessage='Get an account !!!' id="AuthenticationPages.SignIn.GetAccount"/> */}
                </p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo.png")}/>
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0 validation-align">

                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [{
                  type: 'email',
                  message:'The input is not valid E-mail!' 
                  //  message: intl.formatMessage({ id: "AuthenticationPages.SignIn.ValidationMessages.Msg_InputEmailNotValid", defaultMessage: 'The input is not valid E-mail!' }),
                }, {
                  required: true,
                  message:'Please input your E-mail!'
                  //  message: intl.formatMessage({ id: "AuthenticationPages.SignIn.ValidationMessages.Msg_InputEmailRequired", defaultMessage: 'Please input your E-mail!' }),
                }],
                  })(
                    // <Input name="username" placeholder={intl.formatMessage({ id: "AuthenticationPages.SignIn.Email", defaultMessage: 'Email' })}/>

                    <Input name="username" placeholder= 'Email' />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{required: true, 
                      message:'Please input your Password!' 
                      // message: intl.formatMessage({ id: "AuthenticationPages.SignIn.ValidationMessages.Msg_InputPasswordRequired", defaultMessage: 'Please input your Password!' })
                    }],
                  })(
                    // <Input type="password" autoComplete="new-password" placeholder={intl.formatMessage({ id: "AuthenticationPages.SignIn.Password", defaultMessage: 'Password' })}/>
                    <Input type="password" autoComplete="new-password" placeholder='Password' />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true,
                  })(
                    <Checkbox>
                      Remember me
                      {/* <IntlMessages defaultMessage='Remember me' id="AuthenticationPages.SignIn.Rememberme"/> */}
                      </Checkbox>
                  )}
                  <Link className="gx-login-form-forgot" to="/forgetpassword"> 
                  Forgot Password
                  {/* <IntlMessages defaultMessage='Forgot Password' id="AuthenticationPages.ForgotPassword.ForgotYourPassword"/> */}
                  </Link>
                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                  Sign In
                    {/* <IntlMessages defaultMessage='Sign In' id="Generic.Buttons.SignIn"/> */}
                  </Button>
                  {/* <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signup"><IntlMessages
                  id="app.userAuth.signUp"/></Link> */}
                </FormItem>
                <div className="gx-flex-row gx-justify-content-between">
                  <span>
                  or connect with
                    {/* <IntlMessages defaultMessage='or connect with' id="AuthenticationPages.SignIn.OrConnectWith"/> */}
                    </span>
                  <ul className="gx-social-link">
                    <li>
                      <Icon type="google" onClick={() => {
                        //this.props.showAuthLoader();
                        //this.props.userGoogleSignIn();
                      }}/>
                    </li>
                    <li>
                      <Icon type="facebook" onClick={() => {
                        //this.props.showAuthLoader();
                        //this.props.userFacebookSignIn();
                      }}/>
                    </li>
                  </ul>
                </div>
              </Form>
            </div>

            {loader &&
              <div className="gx-loader-view">
                <CircularProgress/>
              </div>}
            {showMessage && this.renderErrorMessage() }
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);
const withIntl = injectIntl(WrappedNormalLoginForm);

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn
})(withIntl);
