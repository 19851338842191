import { createTypes } from 'redux-action-creator';

const textEditorType = createTypes(
  [
    'MODAL_OPEN_CLOSE_STATE',
    'SPIN_LOADER',
    'SET_ORIGINAL_STRING',
    'SET_MANIPULATE_STRING',
    'GET_INGREDIENT_ARRAY_WORKER_SUCCESS',
    'SET_INGREDIENT_ARRAY',
    'GET_ENUMBER_ARRAY_WORKER_SUCCESS',
    'SET_ENUMBERS_ARRAY',
    'SET_TEXT_EDITOR_DATA',
    'SET_MANIPULATE_STRING_SUCCESS',
    'IS_FILTER_OK',
    'RESET',
    'SET_EDIT_ADD_SELECTED_VALUE_NODE',
    'ADD_NEW_INGREDIENT_TO_ARRAY',
    'UPDATE_INGREDIENT_TO_ARRAY',
    'ORIGINAL_ING_VALUE',
    'EDITOR_BTN_DISABLE',
    'Set_LEFT_INGREDIENTS'
  ],
  'TEXT_EDITOR'
);

export default textEditorType;
