const ListTypesEnum = Object.freeze({
  COMPARISON: 'comparison',
  NUTRITIONAL_VALUE: 'nutritionalValue',
  VITAMINS: 'vitamins',
  MINERALS: 'minerals',
  OTHER: 'other',
  COMMON_SUPPLEMENTS: 'commonSupplements',
  ENEREGY: 'energy'
});

export default ListTypesEnum;
