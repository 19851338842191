import { actionCreator } from 'redux-action-creator';
import moderationTypes from './list.constants';

export const getDataList = actionCreator(moderationTypes.GET_DATA_LIST, 'pagination', 'sort');
export const getDataListSuccess = actionCreator(
  moderationTypes.GET_DATA_LIST_SUCCESS,
  'dataList',
  'pagination',
  'sort',
  'originalData'
);
export const getDataListFail = actionCreator(moderationTypes.GET_DATA_LIST_FAIL);
export const setDataListProductImageSuccess = actionCreator(
  moderationTypes.SET_DATA_LIST_PRODUCT_IMAGE_SUCCESS,
  'id',
  'image',
  'indexes'
);
export const setDataListStoreImageSuccess = actionCreator(
  moderationTypes.SET_DATA_LIST_STORE_IMAGE_SUCCESS,
  'id',
  'image',
  'indexes'
);
export const reset = actionCreator(moderationTypes.RESET);
export const setDataListUserImageSuccess = actionCreator(
  moderationTypes.SET_USER_PROFILE_IMAGE_SUCCESS,
  'image'
);
export const deleteTicketSuccess = actionCreator(moderationTypes.DELETE_TICKET_SUCCESS, 'data');
