import * as imageActions from '../constant/imageUploader.constants';

const initialState = {
  fileList: [],
  CollectionId: '',
  newImageUrl: '',
  preImgUID: '',
  imageUploadLoading: false,
  showIconButton: true,
  pageType: '',
  imageUploadPageIsMount: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case GET_IMAGE_LIST:
    //   return {
    //     ...state,
    //     fileList: action.payload
    //   };
    case imageActions.SET_IMAGE_LIST:
      return {
        ...state,
        fileList: action.payload.imageList
      };

    case imageActions.DELETE_IMAGE:
      return {
        ...state,
        fileList: action.payload.imageList
      };

    case imageActions.IMAGE_UPLOAD_LOADER:
      const { loader } = action.payload.loader;
      return {
        ...state,
        imageUploadLoading: loader
      };

    case imageActions.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        fileList: action.payload.imageList,
        CollectionId: action.payload.CollectionId,
        pageType: action.payload.pageType
      };

    case imageActions.SET_COLLECTION_ID:
      return {
        ...state,
        CollectionId: action.payload.CollectionId.CollectionId,
        pageType: action.payload.pageType
      };
    case imageActions.SET_NEW_IMAGE_URL:
      return {
        ...state,
        newImageUrl: action.payload.newImageUrl,
        imageId: action.payload.imageId
      };

    case imageActions.DISPLAY_SAVE_DELETE_BUTTON:
      const { status } = action.payload;

      return {
        ...state,
        showIconButton: status
      };

    case imageActions.RESET_IMAGES:
      return {
        ...state,
        fileList: [],
        CollectionId: '',
        newImageUrl: '',
        preImgUID: '',
        imageUploadLoading: false,
        showIconButton: true,
        pageType: ''
      };

    case imageActions.CHANGE_IMAGE_UPLOAD_PAGE_MOUNT: {
      const { payload } = action;
      return {
        ...state,
        imageUploadPageIsMount: payload.mountState.mountState
      };
    }

    default:
      return state;
  }
};
