import { combineReducers } from 'redux';
import countries_obj_list_reducer from './indexedCountries.reducer';
import brandCatalogueIndexReducer from './brandCatalogueIndex.reducer';
import manufacturerCatalogueIndexReducer from './manufacturerCatalogueIndex.reducer';
import catalogueProductsIndexReducer from './CatalogueProductsIndex.reducer';
import distributorCatelogueIndexReducer from './distributorCatalogue.reducer';
import storeGetLableReducer from './storeGetLableSection.reducer';
import storeTypeReducer from './storeType.reducer';
import tagsReducer from './tags.reducer';
import storeReducer from './indexedStore.reducer';
import moderationReducer from './moderationCatalogue.reducer';
import adManagementReducer from './adManagementCatalogue.reducer';

export default combineReducers({
  countries: countries_obj_list_reducer,
  brandCatalogue: brandCatalogueIndexReducer,
  manufacturerCatalogue: manufacturerCatalogueIndexReducer,
  productCatalogue: catalogueProductsIndexReducer,
  distributorCatalogue: distributorCatelogueIndexReducer,
  storeGetLableSection: storeGetLableReducer,
  storeType: storeTypeReducer,
  tags: tagsReducer,
  stores: storeReducer,
  moderation: moderationReducer,
  adManagement: adManagementReducer
});
