import { takeLatest, all, fork, put, select, call } from 'redux-saga/effects';
import { tagsTypes } from './tagModal.constants';
import {
  editTagsSuccess,
  updateTagsModal,
  showTagsLoader,
  hideTagsLoader,
  saveTagsImageSuccess,
  removeTagsImageSuccess,
  setCollectionId,
  saveTagsImageFail,
  manuallyTagAdd,
  detectFormChanges,
  getEditedDataObj,
  updateAlternativeLisr,
  duplicateTagResponse
} from './tagModal.actions';
import {
  editNewTagSuccess,
  addNewTagInProduct,
  getNewAddedTagProductImage,
  getremoveTagImageFromProd
} from '../product.actions';
import { doRequest } from 'app/shared/redux/sagas/api';
import { message } from 'antd';

import {
  generateEditTagImagesUrls,
  deleteImgUrl,
  generateNewTagImagesUrls
} from './../getTagImages';
/** Utilities::Begin */

// add api translation
function* getIntlMessage(id) {
  const intl = yield select(({ intlReact: { intl } }) => intl);
  return intl.formatMessage({ id });
}

function* getFormData(formData, defaultLocale, collectId) {
  const formattedFormData = yield call(formatData, formData, defaultLocale, collectId);

  const dataToSend = {
    ...formattedFormData
  };

  return dataToSend;
}

function formatData(formData, defaultLocale, collectionId) {
  let tagsObj = {};
  const data = {};

  for (let key in formData) {
    const langData = formData[key];

    const newLangData = {};

    for (let name in langData) {
      if (typeof langData[name] !== 'undefined') {
        let keyName = name;
        if (name.startsWith(key)) {
          keyName = name.slice(key.length + 1);
        }

        newLangData[keyName] = langData[name];
      }
    }

    data[key] = newLangData;
  }

  const { copyrightText, imageSrc, ...translations } = data[defaultLocale];

  data[defaultLocale] = translations;

  const basicProperties = {
    copyrightText,
    imageSrc,
    collectionId
  };
  for (let keys in basicProperties) {
    if (!basicProperties[keys]) {
      basicProperties[keys] = '';
    }
  }

  /** transform links to array */
  for (let [locale, value] of Object.entries(data)) {
    const links = Object.entries(value)
      .map(([key, value]) => (key.includes('link') ? value : null))
      .filter(item => item !== null);

    if (links.length) {
      data[locale].links = links;
    }

    for (let key in data[locale]) {
      if (key.includes('links_')) {
        delete data[locale][key];
      }
    }
  }

  /** transform alternative to array */
  for (let [locale, value] of Object.entries(data)) {
    if (value.alternativeTitle && value.alternativeTitle.length > 0) {
      const alternativeTitle = Object.entries(value)
        .map(([key, value]) => (key.includes('alternativeTitle') ? value : null))
        .filter(item => item !== null);

      if (alternativeTitle[0].length > 0) {
        data[locale].alternativeTitle = alternativeTitle[0];
      } else {
      }
    }
    if (data[locale].alternativeTitle && data[locale].alternativeTitle.length === 0) {
      for (let key in data[locale]) {
        if (key.includes('alternativeTitle')) {
          data[locale][key] = [];
        }
      }
    }

    for (let key in data[locale]) {
      if (key.includes('_alternativeTitle')) {
        delete data[locale][key];
      }
    }
  }

  tagsObj = {
    ...basicProperties,
    translations: data
  };

  return tagsObj;
}

// create alternative duplicates check sending data structure
function getAlternativeTitleList(alternativeList) {
  let getAllAlterNatives = {};

  for (let key in alternativeList) {
    if (alternativeList[key] && alternativeList[key].alternativeTitle) {
      let getFilterValue = alternativeList[key].alternativeTitle.filter(
        item => item && item.status === undefined
      );

      getAllAlterNatives[key] = getFilterValue;
    }
  }

  return getAllAlterNatives;
}

// get alternative locale value list
function getAlternativeListWithDup(translationList) {
  let getAllAlterNatives = {};

  for (let key in translationList) {
    if (translationList[key] && translationList[key].alternativeTitle) {
      getAllAlterNatives[key] = {
        alternativeTitle: translationList[key].alternativeTitle
      };
    } else {
      getAllAlterNatives[key] = {
        alternativeTitle: []
      };
    }
  }

  return getAllAlterNatives;
}

// display duplicate values in alternatives
function duplicatesAlternatives(dataList, alternatives) {
  const alternativesClone = JSON.parse(JSON.stringify(alternatives));
  for (let data of dataList) {
    for (let [locale, value] of Object.entries(data)) {
      if (locale in alternativesClone) {
        const { alternativeTitle } = alternativesClone[locale];
        for (let title of alternativeTitle) {
          const { name } = title;

          if (value.includes(name.toLowerCase().trim())) {
            title['className'] = 'addColorTag';
          }
        }
      }
    }
  }

  return alternativesClone;
}

// set store alternative data list with update status to old values
function getAlternativeList(translationList) {
  let getAllAlterNatives = {};

  for (let key in translationList) {
    if (translationList[key] && translationList[key].alternativeTitle) {
      getAllAlterNatives[key] = {
        alternativeTitle: translationList[key].alternativeTitle
      };
      getAllAlterNatives[key].alternativeTitle.map(item => {
        if (item && item.className) {
          return delete item.className;
        }
        if (item) {
          return (item['status'] = 'OldValue');
        }
      });
    } else {
      getAllAlterNatives[key] = {
        alternativeTitle: []
      };
    }
  }

  return getAllAlterNatives;
}

// get main title send obj
function getMainTitleDuplicateCheckObj(mailTitleTranslationOBJ) {
  for (let key in mailTitleTranslationOBJ) {
    if (!mailTitleTranslationOBJ[key].name) {
      delete mailTitleTranslationOBJ[key];
    }
  }
  const dataToSend = Object.entries(mailTitleTranslationOBJ).reduce((dataObject, row) => {
    const [key, value] = row;
    dataObject[key] = value.name;

    return dataObject;
  }, {});
  return dataToSend;
}

// update and add form data
function* saveTagsDetails({ payload: { form, cb } }) {
  yield put(showTagsLoader());
  yield put(manuallyTagAdd({ newAddedValue: null }));

  const [id] = yield select(s => [s.productModals.tagsModel.id]);
  const InitialCollectionId = yield select(s => s.productModals.tagsModel.collectionId);
  const tagListLocal = yield select(s => s.product.tagListLocal);

  const formData = yield call(getFormData, form, tagListLocal, InitialCollectionId);

  const isEditingTagsForm = yield select(s => s.productModals.tagsModel.isEditingTagsForm);
  const defaultValue = yield select(s => s.productModals.tagsModel.defaultFormValue);

  let imageUrl = '';
  try {
    if (formData.translations[tagListLocal].name) {
      if (isEditingTagsForm) {
        const mailTitleTranslationOBJ = formData.translations;
        const mainTitlesendObj = yield call(getMainTitleDuplicateCheckObj, mailTitleTranslationOBJ);

        const responseMainTitle = yield call(doRequest, {
          url: `contrans/translations/mainTitleCheck/TAGS/${id}`,
          method: 'POST',
          data: mainTitlesendObj
        });

        if (responseMainTitle.status === 200) {
          yield put(duplicateTagResponse({ duplicateTitles: [] }));
          let getAlternativeTitle = yield call(getAlternativeTitleList, formData.translations);
          try {
            let responseAlternative = yield call(doRequest, {
              url: `contrans/translations/dupsBulkCheck/TAGS`,
              method: 'POST',
              data: getAlternativeTitle
            });

            if (responseAlternative.status === 200) {
              //  edit form data
              const response = yield call(doRequest, {
                url: `prodprop/tags/locale/${id}`,
                method: 'PATCH',
                data: formData
              });

              if (response.data && response.data.collectionId) {
                imageUrl = yield call(fetchIngredientImage, response.data.collectionId);
              }

              let editedObj = {
                imageUrl: imageUrl,
                image: formData.collectionId,
                serial: response.data.serial,
                title: formData.translations[tagListLocal].name,
                _id: response.data._id
              };

              let getAlternatives = yield call(getAlternativeList, form.translations);

              yield put(editNewTagSuccess({ data: editedObj, id: id }));
              yield put(getEditedDataObj({ obj: editedObj, formValue: defaultValue }));
              message.info(
                yield getIntlMessage('Generic.ApiMessages.Products.Tags.TagEditSuccess')
              );
              yield put(updateAlternativeLisr({ alternatives: getAlternatives }));
              yield fork(cb);
              yield put(updateTagsModal({ state: 'close' }));
              yield put(detectFormChanges({ changes: false }));
              yield put(hideTagsLoader());
              yield put(duplicateTagResponse({ duplicateTitles: [] }));
              console.log('JJJJJJJJ 11', id, imageUrl);
              const tagsArray = yield select(state => state.product.tagsArray);
              generateEditTagImagesUrls(id, imageUrl, tagsArray);
            }
          } catch (error) {
            if (error && error.response.status === 419) {
              const form = JSON.parse(JSON.stringify(formData));
              let getAlternatives = yield call(getAlternativeListWithDup, form.translations);
              let responseData = error.response.data;
              let getAlternativesDuplicates = yield call(
                duplicatesAlternatives,
                responseData,
                getAlternatives
              );

              yield put(updateAlternativeLisr({ alternatives: getAlternativesDuplicates }));
              message.error(
                yield getIntlMessage('Generic.ApiMessages.Tags.AlternativeDuplicateError')
              );
              yield put(detectFormChanges({ changes: true }));
            } else if (error && error.response.status === 400) {
              message.error(yield getIntlMessage('Generic.ApiMessages.Products.Tags.InvalidData'));
            } else {
              message.error(yield getIntlMessage('Generic.ApiMessages.Products.Tags.SavingError'));
            }
            yield put(hideTagsLoader());
          }
        }
      } else {
        const mailTitleTranslationOBJ = formData.translations;
        const mainTitlesendObj = yield call(getMainTitleDuplicateCheckObj, mailTitleTranslationOBJ);

        const responseMainTitle = yield call(doRequest, {
          url: `contrans/translations/mainTitleAddCheck/TAGS`,
          method: 'POST',
          data: mainTitlesendObj
        });

        if (responseMainTitle.status === 200) {
          yield put(duplicateTagResponse({ duplicateTitles: [] }));
          let getAlternativeTitle = yield call(getAlternativeTitleList, formData.translations);
          try {
            let responseAlternative = yield call(doRequest, {
              url: `contrans/translations/dupsBulkCheck/TAGS`,
              method: 'POST',
              data: getAlternativeTitle
            });

            if (responseAlternative.status === 200) {
              const response = yield call(doRequest, {
                url: 'prodprop/tags',
                method: 'POST',
                data: formData
              });

              if (response.data && response.data.collectionId) {
                imageUrl = yield call(fetchIngredientImage, response.data.collectionId);
              }

              let newAddedObj = {
                imageUrl: imageUrl,
                image: formData.collectionId,
                serial: response.data.serial,
                title: formData.translations[tagListLocal].name,
                _id: response.data._id
              };

              let sampleArray = {
                label: formData.translations[tagListLocal].name,
                value: response.data._id,
                image: formData.collectionId,
                serial: response.data.serial
              };

              yield put(manuallyTagAdd({ newAddedValue: sampleArray }));

              let getAlternatives = yield call(getAlternativeList, form.translations);

              const getCallBack = yield put(addNewTagInProduct({ tagData: newAddedObj }));
              message.info(yield getIntlMessage('Generic.ApiMessages.Products.Tags.TagAddSuccess'));
              yield put(updateAlternativeLisr({ alternatives: getAlternatives }));
              yield fork(cb);
              yield put(updateTagsModal({ state: 'close' }));
              yield put(detectFormChanges({ changes: false }));
              yield put(hideTagsLoader());
              yield put(duplicateTagResponse({ duplicateTitles: [] }));

              // yield delay(500);
              if (getCallBack && getCallBack.payload && getCallBack.payload.tagData) {
                console.log('JJJJJJJJ CAll bacj', getCallBack);
                const tagsArray = yield select(state => state.product.tagsArray);
                generateNewTagImagesUrls(
                  getCallBack.payload.tagData._id,
                  imageUrl,
                  tagsArray,
                  sampleArray
                );
              }
            }
          } catch (error) {
            if (error && error.response.status === 419) {
              const form = JSON.parse(JSON.stringify(formData));
              let getAlternatives = yield call(getAlternativeListWithDup, form.translations);
              let responseData = error.response.data;
              let getAlternativesDuplicates = yield call(
                duplicatesAlternatives,
                responseData,
                getAlternatives
              );

              yield put(updateAlternativeLisr({ alternatives: getAlternativesDuplicates }));
              message.error(
                yield getIntlMessage('Generic.ApiMessages.Tags.AlternativeDuplicateError')
              );
              yield put(detectFormChanges({ changes: true }));
            } else if (error && error.response.status === 400) {
              message.error(yield getIntlMessage('Generic.ApiMessages.Products.Tags.InvalidData'));
            } else {
              message.error(yield getIntlMessage('Generic.ApiMessages.Products.Tags.SavingError'));
            }
            yield put(hideTagsLoader());
          }
        }
      }
    } else {
      yield put(detectFormChanges({ changes: true }));
      yield put(hideTagsLoader());
    }
  } catch (error) {
    if (error && error.response.status === 419) {
      if (error && error.response.status === 419) {
        message.error(yield getIntlMessage('Generic.ApiMessages.Tags.AlternativeDuplicateError'));
        yield put(detectFormChanges({ changes: true }));
      }
      yield put(hideTagsLoader());
      yield put(duplicateTagResponse({ duplicateTitles: error.response.data }));
      yield put(detectFormChanges({ changes: true }));
    } else if (error && error.response.status === 400) {
      message.error(yield getIntlMessage('Generic.ApiMessages.Products.Tags.InvalidData'));
    } else {
      message.error(yield getIntlMessage('Generic.ApiMessages.Products.Tags.SavingError'));
    }
    yield put(hideTagsLoader());
  }
}

// get eddited tags data and image
function* editFromCatalogue(id) {
  yield put(duplicateTagResponse({ duplicateTitles: [] }));
  const response = yield call(doRequest, {
    url: `prodprop/tags/${id}`,
    method: 'GET'
  });

  const { data } = response;

  let image = '';

  if (data && data.collectionId && data.collectionId != '') {
    // call ingredient image get
    put(setCollectionId({ id: data.collectionId }));

    image = yield call(fetchIngredientImage, data.collectionId);
  }

  return [data, image];
}

// get data to edit tags
function* editTagsDetails({ payload: { id } }) {
  yield put(duplicateTagResponse({ duplicateTitles: [] }));
  yield put(updateTagsModal({ state: 'open' }));
  yield put(showTagsLoader());
  try {
    let transformedItem = {};
    let image = '';

    // call get ingredient image and ingredient data
    [transformedItem, image] = yield call(editFromCatalogue, id);

    let getAlternatives = yield call(getAlternativeList, transformedItem.translations);

    yield put(editTagsSuccess({ data: transformedItem, id, image, alternatives: getAlternatives }));
  } catch (error) {
    console.log({ error });
    yield put(updateTagsModal({ state: 'close' }));
    message.error(yield getIntlMessage('Generic.ApiMessages.Products.Tags.GetDetailsError'));
  } finally {
    yield put(hideTagsLoader());
  }
}

// get tags image
function* fetchIngredientImage(collectionId) {
  const getImageResponse = yield call(doRequest, {
    url: `unimup/objctupload/prodlabel/${collectionId}`,
    method: 'GET'
  });

  let imageUrls = imageList(getImageResponse.data.pages);

  for (var i = 0; i < imageUrls.length; i++) {
    const response = yield call(doRequest, {
      method: 'GET',
      url: `/unimup/objctupload${imageUrls[i].tempUrl}`,
      responseType: 'blob'
    });

    let image = URL.createObjectURL(response.data);

    return image;
  }
}

// get image blob url
function imageList(array) {
  let newArr = [];
  let tempObj = {};
  if (array) {
    for (var i = 0; i < array.length; i++) {
      tempObj = {};

      tempObj = {
        tempUrl: array[i].thumbnail.fetchUrl
          ? array[i].thumbnail.fetchUrl
          : array[i].optimized.fetchUrl,
        pageId: array[i].pageId,
        active: array[i].active
      };
      newArr.push(tempObj);
    }
  }

  return newArr;
}

// save and add new image
function* saveImage() {
  yield put(showTagsLoader());
  let newCollectionId = '';
  try {
    const [image, imageBinary, id, tagsEditForm, isEditingTagsForm, collectionId] = yield select(
      s => [
        s.productModals.tagsModel.image,
        s.productModals.tagsModel.imageBinary,
        s.productModals.tagsModel.id,
        s.productModals.tagsModel.tagsEditForm,
        s.productModals.tagsModel.isEditingTagsForm,
        s.productModals.tagsModel.collectionId
      ]
    );

    if (tagsEditForm && collectionId) {
      const imageCollectionID = collectionId;

      if (imageCollectionID) {
        yield call(doRequest, {
          url: `unimup/objctupload/prodlabel/${imageCollectionID}`,
          method: 'DELETE'
        });
      }
    }

    const formData = new FormData();
    formData.append('media', imageBinary);

    if (tagsEditForm && isEditingTagsForm) {
      const response = yield call(doRequest, {
        url: `unimup/objctupload/prodlabel/${id}`,
        method: 'POST',
        data: formData
      });

      const imgObj = {
        collectionId: response.data.CollectionId
      };

      yield call(doRequest, {
        url: `prodprop/tags/${id}`,
        method: 'PATCH',
        data: imgObj
      });

      newCollectionId = imgObj.collectionId;
      yield put(getNewAddedTagProductImage({ image: image, id: id }));
    } else {
      const response = yield call(doRequest, {
        url: `unimup/objctupload/prodlabel`,
        method: 'POST',
        data: formData
      });
      newCollectionId = response.data.CollectionId;
    }
    console.log('JJJJJJJJ,', id, image);
    const tagsArray = yield select(state => state.product.tagsArray);
    generateEditTagImagesUrls(id, image, tagsArray);
    yield put(saveTagsImageSuccess({ img: image }));
    yield put(setCollectionId({ id: newCollectionId }));
    message.info(yield getIntlMessage('Generic.ApiMessages.Tags.ImageUploadSuccess'));
  } catch (error) {
    console.log({ error });
    message.error(yield getIntlMessage('Generic.ApiMessages.Tags.ImageUploadError'));
    yield put(saveTagsImageFail());
  } finally {
    yield put(hideTagsLoader());
  }
}

// delete tag image if have collection id
function* deleteImage() {
  try {
    const [id, isEditingTagsForm] = yield select(s => [
      s.productModals.tagsModel.id,
      s.productModals.tagsModel.isEditingTagsForm
    ]);

    const InitialCollectionId = yield select(s => s.productModals.tagsModel.collectionId);

    if (InitialCollectionId) {
      yield call(doRequest, {
        url: `unimup/objctupload/prodlabel/${InitialCollectionId}`,
        method: 'DELETE'
      });

      if (isEditingTagsForm) {
        const imgObj = {
          collectionId: ''
        };

        yield call(doRequest, {
          url: `prodprop/tags/${id}`,
          method: 'PATCH',
          data: imgObj
        });
      }
      yield put(setCollectionId({ id: '' }));
      yield put(removeTagsImageSuccess());
      const tagsArray = yield select(state => state.product.tagsArray);
      deleteImgUrl(id, tagsArray);
      yield put(getremoveTagImageFromProd({ id: id }));
      message.info(yield getIntlMessage('Generic.ApiMessages.Tags.ImageDeleteSuccess'));
    }
  } catch (error) {
    console.log({ error });
    message.error(yield getIntlMessage('Generic.ApiMessages.Tags.ImageDeleteError'));
  }
}

function* openCatalogueModalSaga() {
  yield put(updateTagsModal({ state: 'open' }));
}

/** Watchers */

function* saveTagsDetailsWatcher() {
  yield takeLatest(tagsTypes.SAVE_DETAILS, saveTagsDetails);
}

function* editTagsDetailsWatcher() {
  yield takeLatest(tagsTypes.EDIT, editTagsDetails);
}

function* saveImageWatcher() {
  yield takeLatest(tagsTypes.SAVE_IMG, saveImage);
}

function* deleteImageWatcher() {
  yield takeLatest(tagsTypes.REMOVE_IMG, deleteImage);
}

function* openCatalogueModalSagaWatcher() {
  yield takeLatest(tagsTypes.OPEN_CATALOGUE, openCatalogueModalSaga);
}

export default function* rootSaga() {
  yield all([
    fork(saveTagsDetailsWatcher),
    fork(editTagsDetailsWatcher),
    fork(saveImageWatcher),
    fork(deleteImageWatcher),
    fork(openCatalogueModalSagaWatcher)
  ]);
}
