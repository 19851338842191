import { createTypes, async } from 'redux-action-creator';

const types = createTypes(
  [
    ...async('GET_LIST'),
    ...async('SAVE_NODE'),
    ...async('UPDATE_ORDER'),
    ...async('SET_SELECTED_NODE'),
    ...async('SAVE_CHANGES'),
    ...async('SEARCH'),
    ...async('DELETE_NODE'),
    'ADD_NODE',
    'ADD_NODE_SUCCESS',
    'UPDATE_BUTTON_STATE',
    'UPDATE_FORMDATA',
    'UPDATE_SAVE_MODAL',
    'SAVE_CHANGES_AND_SWITCH',
    'CANCEL_CHANGES_AND_SWITCH',
    'CANCEL_CHANGES_AND_STAY',
    'RESET_NUTRIENTS_STATE'
  ],
  'NUTRITION_VALUES'
);

export default types;
