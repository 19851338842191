import {
  all,
  fork,
  takeEvery,
  put,
  select,
  call,
  take,
  race,
  cancelled,
  takeLatest
} from 'redux-saga/effects';
import * as ingredientsActions from './ingredients.constants';
import {
  getIngredientsSuccess,
  addIngredientSuccess,
  addIngredient,
  deleteIngredientSuccess,
  handleBlurIngredientSuccess,
  getIngredientLanguagesSuccess,
  saveIngredientTreeKeySuccess,
  cancelIngredientTreeKeySuccess,
  setSelectedIngredient,
  getIngredientImageSuccess,
  updateIngredientImageSuccess,
  treeSelectedKey,
  updateIngredientSaveLoader,
  getIngredientImage,
  deleteIngredientImageSuccess,
  updateIngredientImageError,
  updateIngredientImagePatchSuccess,
  identifyGetIngredientData,
  resetIngredientSelected,
  saveIngredientLastGeneratedRandomNumber,
  updateFormValuesSuccess,
  reUpdateIngredientSaveDetails,
  updateIngredientData,
  deleteIngredientFail,
  updateAlternativeLisr,
  getDuplicatedIngListSuccess,
  getDuplicatedIngListFail,
  searchIngsuccess,
  searchJobsSuccess,
  deleteJobSuccess,
  addJobSuccess,
  updateJobSuccess,
  duplicateIngredientResponse
} from './ingredients.actions';
import normalizeTree from '../../../../shared/utils/normalizeTree';
import { addJobToQueue } from './../../../../shared/redux/actions/index';
import { doRequest } from '../../../../shared/redux/sagas/api';
import { message } from 'antd';
import Axios from 'axios';
import { array } from 'prop-types';

import uuid from 'uuid';

function* getIngredients() {
  try {
    const response = yield call(doRequest, {
      url: 'prodsup/ingredient/get'
    });

    let languageList = yield select(state => state.languageList.languageList);
    let defLocalState = yield select(state => state.profile.language);

    let defaultLocalCode = 'en_US';
    if (languageList) {
      let defaultLanguageLenth = languageList.find(item => item.id === defLocalState);
      if (defaultLanguageLenth) {
        defaultLocalCode = defaultLanguageLenth.locale;
      }
    }

    const data = response.data.map(item => ({ ...item, serial: `I${item.serial}` }));

    yield put(getIngredientsSuccess({ data: data }));
  } catch (error) {
    console.log(error);
  }
}

function* plusBtnClick({ payload }) {
  const { clickedValue, dataOld, randomNumb } = payload;

  const { save } = yield race({
    save: take(ingredientsActions.SAVE_TREE_INGREDIENT_VALUE_SUCCESS),
    cancel: take(ingredientsActions.CANCEL_TREE_INGREDIENT_VALUE)
  });

  if (save) {
    yield put(addIngredient(clickedValue, dataOld, randomNumb));
    payload.cb();
  }
}

function* addIngredients({ payload }) {
  let { dataOld, randomNumb } = payload;
  const dataWithTempNode = [{ title: '', id: randomNumb }, ...dataOld];
  yield put(addIngredientSuccess({ dataOld: dataWithTempNode }));
  yield put(saveIngredientLastGeneratedRandomNumber({ randomNumb }));
}

function* deleteIngredients({ payload }) {
  let { clickedValue, dataOld } = payload;
  let intl = yield select(state => state.intlReact.intl);
  try {
    const response = yield call(doRequest, {
      method: 'DELETE',
      url: `prodsup/ingredient/${clickedValue}/delete`
    });

    if ((response.status = 200)) {
      const ingredientsState = yield select(state => state.ingredients);

      const workerParams = {
        originalData: ingredientsState.originalData,
        duplicatedList: ingredientsState.duplicatedList,
        id: clickedValue,
        searchKey: ingredientsState.searchKey
      };

      yield put(
        addJobToQueue({
          workerAction: 'ING_DELETE',
          data: workerParams,
          actionToDispatch: deleteJobSuccess
        })
      );

      const {
        payload: { result }
      } = yield take('DELETE_JOB_SUCCESS');

      const { originalData, duplicatedList, searchData } = result;

      let mesage = intl.formatMessage({
        id: 'Generic.ApiMessages.Ingredient.IngredientDeleteSuccess'
      });

      let selectedKey = yield select(state => state.ingredients.selectedKeys);
      let isSelectedKeyDeleted = false;
      if (selectedKey && selectedKey.length && selectedKey[0] === clickedValue) {
        isSelectedKeyDeleted = true;
      }

      //yield call(getIngredients);
      yield put(
        deleteIngredientSuccess({ isSelectedKeyDeleted, originalData, duplicatedList, searchData })
      );
      message.info(mesage);
    }
  } catch (error) {
    message.error(error.response.data.message);
    yield put(deleteIngredientFail());
  }
}

function* search(action) {
  const key = action.payload.key.toLowerCase();

  if (!key) {
    return yield put(searchIngsuccess({ data: null }));
  }
  const [originalData] = yield select(({ ingredients: { originalData } }) => [originalData]);
  const duplicateList = yield select(({ ingredients: { duplicatedList } }) => duplicatedList);
  const userLocale = yield select(({ ingredients: { userLocale } }) => userLocale);

  const taskId = uuid();

  yield put(
    addJobToQueue({
      id: taskId,
      workerAction: 'ING_SEARCH',
      data: { list: duplicateList, searchKey: key, userLocale: userLocale },
      actionToDispatch: searchJobsSuccess
    })
  );

  let resultAction;

  while (true) {
    resultAction = yield take('ING_SEARCH_JOB_SUCCESS');

    /** watch for the specific id of this task */
    if (resultAction.payload.id === taskId) {
      break;
    }
  }

  // const [comparisonResult] = yield all([call(individualSearch, `${key}`, comparison)]);

  yield put(
    searchIngsuccess({
      data: resultAction.payload.data.result
    })
  );
}

function* handleBlurIngredients({ payload }) {
  let { changedValue, dataOld, changedId, defaultLocal } = payload;
  let localeMapLanguageList = yield select(state => state.languageList.languageList);
  let intl = yield select(state => state.intlReact.intl);

  let getDefLocaleArray = localeMapLanguageList.filter(item => item.id == defaultLocal);
  let deflocaleStatus = 'en_US';
  if (changedValue) {
    try {
      getDefLocaleArray.forEach(element => {
        deflocaleStatus = element.locale;
      });

      const alternativeTitleObj = {
        [deflocaleStatus]: changedValue
      };

      let responseAlternative = yield call(doRequest, {
        url: `contrans/translations/mainTitleAddCheck/ING`,
        method: 'POST',
        data: alternativeTitleObj
      });

      if (responseAlternative.status === 200) {
        const parentId = getParentKey(changedId, dataOld);

        const formData = {
          title: changedValue ? changedValue.trim() : changedValue,
          isVegan: 0,
          imageSrc: '',
          isHealthy: 0,
          isMayContain: 0,
          imageLinkDetails: '',
          parentId: parentId ? parentId : null
        };

        const response = yield call(doRequest, {
          method: 'POST',
          url: 'prodsup/ingredient/add',
          data: formData
        });

        if (response.status === 200) {
          const responseData = response.data;
          const newIngredient = {};
          newIngredient.id = responseData.id;
          newIngredient.title = responseData.title;
          newIngredient.serial = responseData.serial;
          newIngredient.parentId = responseData.parentId;
          newIngredient.hierarchyLevel = responseData.hierarchyLevel;
          newIngredient.position = responseData.position;

          const newData = [newIngredient, ...dataOld.slice(1)];

          let localeMap = {};
          localeMapLanguageList.forEach(element => {
            localeMap[element.id] = element.locale;
          });

          let defaultLocalSelect;

          if (defaultLocal) {
            if (defaultLocal) defaultLocalSelect = localeMap[defaultLocal];
          }

          if (defaultLocalSelect) {
          } else {
            defaultLocal = '3';
            defaultLocalSelect = localeMap[defaultLocal];
          }

          let formData2 = {};
          localeMapLanguageList.forEach(element => {
            formData2[element.locale] = {
              id: element.locale,
              title: '',
              description: '',
              links: []
            };
          });

          formData2[defaultLocalSelect] = {
            id: defaultLocalSelect,
            title: response.data.title ? response.data.title.trim() : response.data.title,
            description: '',
            links: []
          };

          try {
            if (response.data.id) {
              const response2 = yield call(doRequest, {
                method: 'PATCH',
                url: 'contrans/translations/ING/' + response.data.id,
                data: formData2
              });

              if (response2.status === 200) {
                yield fork(triggerIngredientAlternativeListUpdate, responseData.id);

                yield put(resetIngredientSelected());

                const ingredientsState = yield select(state => state.ingredients);

                const workerParams = {
                  originalData: ingredientsState.originalData,
                  duplicatedList: ingredientsState.duplicatedList,
                  searchKey: ingredientsState.searchKey,
                  newItem: newIngredient
                };

                yield put(
                  addJobToQueue({
                    workerAction: 'ING_ADD',
                    data: workerParams,
                    actionToDispatch: addJobSuccess
                  })
                );

                const {
                  payload: { result }
                } = yield take('ADD_JOB_SUCCESS');

                const { originalData, duplicatedList, searchData, newItem } = result;

                yield put(
                  handleBlurIngredientSuccess({
                    dataOld: searchData,
                    originalData,
                    duplicatedList,
                    newItem
                  })
                );
                yield put(setSelectedIngredient({ selectedIngredient: [response.data.id] }));
                yield put(treeSelectedKey([response.data.id]));
              }
            }
          } catch (error) {
            const newData = dataOld.slice(1);
            const ingredientsState = yield select(state => state.ingredients);
            yield put(
              updateIngredientData({ data: newData, originalData: ingredientsState.originalData })
            );
            if (error.response.data.message) {
              message.error(error.response.data.message.toString());
            }
          }
        } else {
          const newData = dataOld.slice(1);
          const ingredientsState = yield select(state => state.ingredients);
          yield put(
            updateIngredientData({ data: newData, originalData: ingredientsState.originalData })
          );
        }
      }
    } catch (error) {
      if (error.response.status === 419) {
        const newData = dataOld.slice(1);
        const ingredientsState = yield select(state => state.ingredients);
        yield put(
          updateIngredientData({ data: newData, originalData: ingredientsState.originalData })
        );
        let mesage = intl.formatMessage({
          id: 'Generic.ApiMessages.Ingredient.SavingErrorWithDuplicates'
        });
        message.error(mesage);
      } else {
        const newData = dataOld.slice(1);
        const ingredientsState = yield select(state => state.ingredients);
        yield put(
          updateIngredientData({ data: newData, originalData: ingredientsState.originalData })
        );
        if (error.response.data.message) {
          message.error(error.response.data.message.toString());
        }
      }
    }
  } else {
    const newData = dataOld.slice(1);
    const ingredientsState = yield select(state => state.ingredients);
    yield put(updateIngredientData({ data: newData, originalData: ingredientsState.originalData }));
  }
}

function* updateIngredientsOrder({ payload }) {
  let intl = yield select(state => state.intlReact.intl);
  let mesageLoading = intl.formatMessage({
    id: 'Generic.ApiMessages.UpdatingPositions'
  });

  const hide = message.loading(mesageLoading, 0);
  const { id, newIndex, data, prevData, cb } = payload;
  try {
    yield put(updateIngredientData({ data: data, originalData: data }));

    yield call(doRequest, {
      method: 'PUT',
      url: `prodsup/draganddrop/ingredient`,
      data: {
        new_parent: null,
        node: Number(id),
        new_pos: Number(newIndex)
      }
    });

    yield call(getIngredients);
    if (cb) {
      yield call(cb);
    }
    hide();
    let mesageUpdate = intl.formatMessage({
      id: 'Generic.ApiMessages.Ingredient.IngredientOrderUpdated'
    });
    message.info(mesageUpdate);
  } catch (error) {
    yield call(getIngredients);
    if (cb) {
      yield call(cb);
    }
    hide();
    yield put(updateIngredientData({ data: prevData, originalData: prevData }));
    if (error.response.data.message) {
      message.error(error.response.data.message.toString());
    } else {
      console.error(error);
    }
  }
}

function* getSelectedIngredientLanguage({ payload }) {
  yield put(duplicateIngredientResponse({ duplicateTitles: [] }));
  if (payload['selectedIngredient'][0]) {
    try {
      if (!payload['selectedIngredient'] || !payload['selectedIngredient'][0]) {
        return;
      }

      const response = yield call(doRequest, {
        url: 'prodsup/ingredient/get/' + payload['selectedIngredient'][0],
        method: 'GET'
      });

      const response1 = yield call(doRequest, {
        url: 'contrans/translations/ING/' + payload['selectedIngredient'][0],
        method: 'GET'
      });
      console.log('response', response, 'response1', response1);

      let getResponse = response.data;

      const getResponseArr = response1.data.map(item => item.values_translation);

      let getAlternatives = yield call(getAlternativeList, getResponseArr);

      const data = {
        id: '' + getResponse.id + '',
        attributes: {
          title: '' + getResponse.title + '',
          isVegan: getResponse.isVegan,
          isHealthy: getResponse.isHealthy,
          isMayContain: getResponse.isMayContain,
          imageSrc: '' + getResponse.imageSrc + '',
          imageLinkDetails: '' + getResponse.imageLinkDetails + '',
          parentId: getResponse.parentId,
          uploadedImageUrl: getResponse.uplodedImageUrl
        },
        languages: getResponseArr
      };

      yield put(getIngredientImage(`unimup/objctupload${data.attributes.uploadedImageUrl}`));

      let newRandomKeyValue = yield select(state => state.ingredients.randomNumberLastIngredient);

      setTimeout(() => {
        if (newRandomKeyValue && newRandomKeyValue.randomNumb) {
          const element = document.getElementById(newRandomKeyValue.randomNumb.toString());
          if (element) {
            element.focus();
          }
        }
      }, 100);

      yield put(getIngredientLanguagesSuccess({ data: data, alternatives: getAlternatives }));
    } catch (error) {
      console.log(error);
    }
  }
}

function* updateIngredientImage({ payload }) {
  let intl = yield select(state => state.intlReact.intl);
  try {
    const { id, attributes, image } = payload;

    const data = new FormData();
    data.append('media', image);
    if (attributes.uploadedImageUrl && attributes.uploadedImageUrl.includes('ings')) {
      const url = `unimup/objctupload${attributes.uploadedImageUrl}`;
      yield call(doRequest, {
        url,
        method: 'PATCH',
        data
      });
      yield put(updateIngredientImagePatchSuccess());
    } else {
      const url = `unimup/objctupload/ings/${id}`;

      const response = yield call(doRequest, {
        url: url,
        method: 'POST',
        data: data
      });

      if (response.status === 200) {
        const { fetchUrl } = response.data;
        yield call(doRequest, {
          method: 'PUT',
          url: `prodsup/ingredient/${id}/update`,
          data: {
            imageSrc: attributes.imageSrc,
            uplodedImageUrl: fetchUrl,
            imageLinkDetails: attributes.imageLinkDetails,
            isHealthy: attributes.isNotHealthy ? 1 : 0,
            isVegan: attributes.isVegan ? 1 : 0,
            isMayContain: attributes.isMayContain ? 1 : 0,
            title: attributes.title,
            parentId: attributes.parentId
          }
        });
        yield put(updateIngredientImageSuccess(fetchUrl));
      }
    }
    let mesageImageUpload = intl.formatMessage({
      id: 'Generic.ApiMessages.Ingredient.IngredientImageUpdated'
    });
    message.info(mesageImageUpload);
  } catch (error) {
    let mesageImageUploadFileSize = intl.formatMessage({
      id: 'Generic.ApiMessages.FileSizeExceed'
    });
    if (error.response && error.response.status === 413) {
      message.error(mesageImageUploadFileSize);
    } else {
      if (error.response && error.response.data.error && error.response.data.error.message)
        message.error(error.response.data.error.message);
    }

    yield put(updateIngredientImageError());
  }
  if (payload.cb) {
    payload.cb();
  }
}

function* ingredientImageGet({ payload }) {
  const cancelSource = Axios.CancelToken.source();
  try {
    if (payload === null || typeof payload === 'undefined') return;

    if (!payload.includes('ings')) {
      return yield put(getIngredientImageSuccess('', false));
    }
    const response = yield call(doRequest, {
      url: payload,
      method: 'GET',
      responseType: 'blob',
      cancelToken: cancelSource.token
    });

    let imageLoader = true;

    if (response.status === 200) {
      if (response.data) {
        imageLoader = false;
        let image = URL.createObjectURL(response.data);
        yield put(getIngredientImageSuccess(image, imageLoader));
        let newRandomKeyValue = yield select(state => state.ingredients.randomNumberLastIngredient);

        setTimeout(() => {
          if (newRandomKeyValue && newRandomKeyValue.randomNumb) {
            const element = document.getElementById(newRandomKeyValue.randomNumb.toString());
            if (element) {
              element.focus();
            }
          }
        }, 100);
      }
    }
  } catch (error) {
    yield put(getIngredientImageSuccess('', false));
    let newRandomKeyValue = yield select(state => state.ingredients.randomNumberLastIngredient);

    setTimeout(() => {
      if (newRandomKeyValue && newRandomKeyValue.randomNumb) {
        const element = document.getElementById(newRandomKeyValue.randomNumb.toString());
        if (element) {
          element.focus();
        }
      }
    }, 100);
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

function* getIngredientsListener() {
  yield takeEvery(ingredientsActions.GET_INGREDIENTS, getIngredients);
}

function* cancelIngredientAlert() {
  yield put(cancelIngredientTreeKeySuccess());
}

function* cancelIngredientAlertListener() {
  yield takeEvery(ingredientsActions.CANCEL_TREE_INGREDIENT_VALUE, cancelIngredientAlert);
}

function* saveIngredientAlert() {
  let newKeyValue = yield select(state => state.ingredients.nextSelectedkey);
  yield put(saveIngredientTreeKeySuccess());
  if (newKeyValue && newKeyValue.length !== 0) {
    yield put(identifyGetIngredientData(true));
  }
  yield put(setSelectedIngredient({ selectedIngredient: newKeyValue }));
  yield put(treeSelectedKey(newKeyValue));
}

// set store alternative data list with update status to old values
function getAlternativeList(translationList) {
  let getAllAlterNatives = {};

  translationList.forEach(element => {
    if (element.id && element.alternativeTitle && Array.isArray(element.alternativeTitle)) {
      getAllAlterNatives[element.id] = {
        alternativeTitle: element.alternativeTitle
      };
      getAllAlterNatives[element.id].alternativeTitle.map(item => {
        if (item && item.className) {
          return delete item.className;
        }
        if (item) {
          return (item['status'] = 'OldValue');
        }
      });
    } else {
      getAllAlterNatives[element.id] = {
        alternativeTitle: []
      };
    }
  });

  return getAllAlterNatives;
}

// create alternative duplicates check sending data structure
function getAlternativeTitleList(alternativeList) {
  let getAllAlterNatives = {};

  alternativeList.forEach(element => {
    if (element.id && element.alternativeTitle) {
      let getFilterValue = element.alternativeTitle.filter(
        item => item && item.status === undefined
      );

      getAllAlterNatives[element.id] = getFilterValue;
    }
  });

  return getAllAlterNatives;
}

// get alternative locale value list
function getAlternativeListWithDup(translationList) {
  let getAllAlterNatives = {};

  translationList.forEach(element => {
    if (element.id && element.alternativeTitle) {
      getAllAlterNatives[element.id] = {
        alternativeTitle: element.alternativeTitle
      };
    } else {
      getAllAlterNatives[element.id] = {
        alternativeTitle: []
      };
    }
  });

  return getAllAlterNatives;
}

// display duplicate values in alternatives
function duplicatesAlternatives(dataList, alternatives) {
  const alternativesClone = JSON.parse(JSON.stringify(alternatives));
  for (let data of dataList) {
    for (let [locale, value] of Object.entries(data)) {
      if (locale in alternativesClone) {
        const { alternativeTitle } = alternativesClone[locale];
        for (let title of alternativeTitle) {
          const { name } = title;

          if (value.includes(name.toLowerCase().trim())) {
            title['className'] = 'addColorTag';
          }
        }
      }
    }
  }

  return alternativesClone;
}

// get main title send obj
function getMainTitleDuplicateCheckObj(mailTitleTranslationOBJ) {
  for (let key in mailTitleTranslationOBJ) {
    if (!mailTitleTranslationOBJ[key].title) {
      delete mailTitleTranslationOBJ[key];
    }
  }
  const dataToSend = Object.entries(mailTitleTranslationOBJ).reduce((dataObject, row) => {
    const [key, value] = row;
    dataObject[key] = value.title;

    return dataObject;
  }, {});
  return dataToSend;
}

function* saveIngredientDetails({ payload: { values, successCb, errorCb } }) {
  yield put(updateIngredientSaveLoader(true));

  // const getIngredientLanguageObj=

  const { attributes, id, title, serial, parentId, defaultLang } = values;
  let obj = {};
  values.languageArray.forEach(item => {
    langSingleOBJ(obj, item);
  });

  const data = {
    id: id,
    title: '' + title ? title.trim() : title + '',
    isVegan: attributes.isVegan ? 1 : 0,
    isHealthy: attributes.isNotHealthy ? 1 : 0,
    isMayContain: attributes.isMayContain ? 1 : 0,
    imageLinkDetails: '' + attributes.imageLinkDetails + '',
    imageSrc: '' + attributes.imageSrc + '',
    ingredientLinks: defaultLang.links,
    ingredientText: defaultLang.description,
    parentId: parentId
  };

  try {
    const mailTitleTranslationOBJ = JSON.parse(JSON.stringify(obj));

    const mainTitlesendObj = yield call(getMainTitleDuplicateCheckObj, mailTitleTranslationOBJ);

    const responseMainTitle = yield call(doRequest, {
      url: `contrans/translations/mainTitleCheck/ING/${id}`,
      method: 'POST',
      data: mainTitlesendObj
    });

    if (responseMainTitle.status === 200) {
      if (id) {
        try {
          let getAlternativeTitle = yield call(getAlternativeTitleList, values.languageArray);

          let responseAlternative = yield call(doRequest, {
            url: `contrans/translations/dupsBulkCheck/ING`,
            method: 'POST',
            data: getAlternativeTitle
          });

          if (responseAlternative.status === 200) {
            const [updateResponse] = yield all([
              call(doRequest, {
                method: 'PUT',
                url: `prodsup/ingredient/${id}/update`,
                data: data
              }),

              call(doRequest, {
                url: `contrans/translations/ING/${id}`,
                method: 'PATCH',
                data: obj
              })
            ]);

            yield fork(triggerIngredientAlternativeListUpdate, id);

            let ingredientList = yield select(state => state.ingredients.data);
            //let originalIngredientList = yield select(state => state.ingredients.originalData);

            const alternativeTitle = values.languageArray.reduce((titleObj, translation) => {
              titleObj[translation.id] = translation.alternativeTitle || '';
              return titleObj;
            }, {});

            ingredientList = [...ingredientList];

            let objMofified = {
              id: id,
              title: title,
              isVegan: attributes.isVegan,
              defaultLang: defaultLang,
              alternativeTitle: alternativeTitle
            };

            //loop(ingredientList, id, title, defaultLang, attributes.isVegan, alternativeTitle);
            //loop(originalIngredientList, id, title, defaultLang, attributes.isVegan, alternativeTitle);
            // yield call(
            //   loop,
            //   ingredientList,
            //   id,
            //   title,
            //   defaultLang,
            //   attributes.isVegan,
            //   alternativeTitle
            // );

            const ingredientsState = yield select(state => state.ingredients);

            const workerParams = {
              data: ingredientList,
              originalData: ingredientsState.originalData,
              duplicatedList: ingredientsState.duplicatedList,
              searchKey: ingredientsState.searchKey,
              modifiedObj: objMofified
            };

            yield put(
              addJobToQueue({
                workerAction: 'ING_UPDATE',
                data: workerParams,
                actionToDispatch: updateJobSuccess
              })
            );

            const {
              payload: { result }
            } = yield take('ING_UPDATE_JOB_SUCCESS');

            const { originalData, duplicatedList, searchData } = result;

            let getAlternatives = yield call(getAlternativeList, values.languageArray);

            yield put(updateAlternativeLisr({ alternatives: getAlternatives }));

            yield put(updateFormValuesSuccess(updateResponse.data));
            yield put(
              reUpdateIngredientSaveDetails({
                data: searchData,
                originalData: originalData,
                duplicatedList: duplicatedList
              })
            );
            if (successCb) successCb();

            // ************************************************
          }
        } catch (error) {
          if (error.response.status === 419) {
            let getAlternatives = yield call(getAlternativeListWithDup, values.languageArray);
            let responseData = error.response.data;
            let getAlternativesDuplicates = yield call(
              duplicatesAlternatives,
              responseData,
              getAlternatives
            );

            yield put(updateAlternativeLisr({ alternatives: getAlternativesDuplicates }));
            let intl = yield select(state => state.intlReact.intl);
            let mesage = intl.formatMessage({
              id: 'Generic.ApiMessages.Ingredient.SavingErrorWithDuplicates'
            });
            message.error(mesage);
          }

          if (error.response.data.message) message.error(error.response.data.message);
          if (errorCb) errorCb();
        }
      }
      yield put(duplicateIngredientResponse({ duplicateTitles: [] }));
    }
  } catch (error) {
    if (error.response.status === 419) {
      let intl = yield select(state => state.intlReact.intl);
      let mesage = intl.formatMessage({
        id: 'Generic.ApiMessages.Ingredient.SavingErrorWithDuplicates'
      });
      message.error(mesage);

      yield put(duplicateIngredientResponse({ data: error.response.data }));
    }
  }

  yield put(updateIngredientSaveLoader(false));
}

function loop(ingredientList, id, title, defaultLang, isVegan, alternativeTitle) {
  for (let i = 0; i < ingredientList.length; i++) {
    const tree = ingredientList[i];
    if (tree.id === id) {
      tree.title = title;
      tree.isVegan = isVegan;
      tree.alternativeTitle = alternativeTitle;

      if (!tree.languages) {
        tree.languages = {};
      }

      tree.languages[defaultLang.id] = title;
      return;
    }
    if (tree.children) {
      loop(tree.children, id, title, defaultLang, isVegan);
    }
  }
}

function* deleteIngredientImage({ payload }) {
  let intl = yield select(state => state.intlReact.intl);
  try {
    const { cb, attributes, id } = payload;
    const url = 'unimup/objctupload' + attributes.uploadedImageUrl;
    yield call(doRequest, {
      method: 'DELETE',
      url: `${url}`
    });

    const data = {
      imageSrc: attributes.imageSrc,
      uplodedImageUrl: '',
      imageLinkDetails: attributes.imageLinkDetails,
      isHealthy: attributes.isNotHealthy ? 1 : 0,
      isVegan: attributes.isVegan ? 1 : 0,
      isMayContain: attributes.isMayContain ? 1 : 0,
      title: attributes.title,
      parentId: attributes.parentId
    };

    yield call(doRequest, {
      method: 'PUT',
      url: `prodsup/ingredient/${id}/update`,
      data: data
    });

    if (cb) cb();
    let mesageImageDelete = intl.formatMessage({
      id: 'Generic.ApiMessages.Ingredient.IngredientImageDeleted'
    });
    message.info(mesageImageDelete);
    yield put(deleteIngredientImageSuccess());
  } catch (error) {
    console.log(error);
  }
}

function* getUserLocale() {
  const [id, languageList] = yield select(s => [s.profile.language, s.languageList.languageList]);
  const language = languageList.find(language => language.id === id);
  return language.locale;
}

function* getDuplicatedIngListSaga() {
  try {
    const userLocale = yield call(getUserLocale);
    const response = yield call(doRequest, {
      method: 'GET',
      url: `prodsup/ingredient/alternativelist/${userLocale}`
    });

    yield put(getDuplicatedIngListSuccess({ data: response.data }));
  } catch (error) {
    yield put(getDuplicatedIngListFail());
  }
}

export function* triggerIngredientAlternativeListUpdate(id) {
  if (!id) {
    return;
  }

  try {
    yield call(doRequest, {
      method: 'PATCH',
      url: `prodsup/ingredient/alternativelist/${id}`
    });
  } catch (error) {
    console.warn('Alternative List Backend API refresh failed');
  }
}

/**
 * Listeners
 */
function* saveIngredientAlertListener() {
  yield takeEvery(ingredientsActions.SAVE_TREE_INGREDIENT_VALUE, saveIngredientAlert);
}

function* plusBtnClickListener() {
  yield takeEvery(ingredientsActions.PLUS_BTN_CLICK, plusBtnClick);
}

function* addIngredientsListener() {
  yield takeEvery(ingredientsActions.ADD_INGREDIENT, addIngredients);
}

function* deleteIngredientsListener() {
  yield takeEvery(ingredientsActions.DELETE_INGREDIENT, deleteIngredients);
}

function* handleBlurIngredientsListener() {
  yield takeEvery(ingredientsActions.HANDLE_BLUR_INGREDIENT, handleBlurIngredients);
}

function* updateIngredientsOrderListener() {
  while (true) {
    const action = yield take(ingredientsActions.UPDATE_INGREDIENT_ORDER);
    yield call(updateIngredientsOrder, action);
  }
}

function* getIngredientLanguagesListener() {
  yield takeLatest(ingredientsActions.SET_SELECTED_INGREDIENT, getSelectedIngredientLanguage);
}

function* updateIngredientImageListener() {
  yield takeEvery(ingredientsActions.UPDATE_INGREDIENT_IMAGE, updateIngredientImage);
}

function* getIngredientImageListener() {
  yield takeLatest(ingredientsActions.GET_INGREDIENT_IMAGE, ingredientImageGet);
}

function* saveIngredientDetailsListener() {
  yield takeEvery(ingredientsActions.UPDATE_INGREDIENT_FORM_DETAILS, saveIngredientDetails);
}

function* deleteIngredientImageListener() {
  yield takeEvery(ingredientsActions.DELETE_INGREDIENT_IMAGE, deleteIngredientImage);
}

function* searchLabelWatcher() {
  yield takeLatest(ingredientsActions.ING_SEARCH, search);
}

/**
 * Individual watcher to invoke duplicate list GET request
 */
function* getDuplicatedListWatcher() {
  yield takeLatest(ingredientsActions.GET_DUPLICATED_LIST, getDuplicatedIngListSaga);
}

/**
 * Watch for GET_LIST action and parallely get the new
 * duplicate list
 */
function* getDuplicatedListWithGetListWatcher() {
  yield takeLatest(ingredientsActions.GET_INGREDIENTS, getDuplicatedIngListSaga);
}

export default function* rootSaga() {
  yield all([
    fork(getIngredientsListener),
    fork(getDuplicatedListWatcher),
    fork(getDuplicatedListWithGetListWatcher),
    fork(addIngredientsListener),
    fork(deleteIngredientsListener),
    fork(handleBlurIngredientsListener),
    fork(updateIngredientsOrderListener),
    fork(getIngredientLanguagesListener),
    fork(cancelIngredientAlertListener),
    fork(saveIngredientAlertListener),
    fork(updateIngredientImageListener),
    fork(getIngredientImageListener),
    fork(plusBtnClickListener),
    fork(saveIngredientDetailsListener),
    fork(deleteIngredientImageListener),
    fork(searchLabelWatcher)
  ]);
}

function addKeyToNested(obj, parent, value, i, randomNumb) {
  if (obj.id === value) {
    if (obj.children) {
      obj.children.splice(0, 0, { title: '', id: randomNumb });
    } else {
      obj['children'] = [];
      obj['children'].push({ title: '', id: randomNumb });
    }

    setTimeout(() => {
      const element = document.getElementById(randomNumb.toString());
      if (element) {
        element.focus();
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
      }
    }, 300);
  }
  if (obj && obj.children && obj.children.length > 0) {
    for (let j = 0; j < obj.children.length; j++) {
      addKeyToNested(obj.children[j], obj.children, value, j, randomNumb);
    }
  }
}

function deleteKeyNested(obj, parent, value, i) {
  if (obj.id === value) {
    parent.splice(i, 1);
  }
  if (obj && obj.children && obj.children.length > 0) {
    for (let j = 0; j < obj.children.length; j++) {
      deleteKeyNested(obj.children[j], obj.children, value, j);
    }
  }
}

function replaceExistingKey(obj, parent, changedId, newData, i) {
  if (obj.id === changedId) {
    obj.id = newData.id;
    obj.title = newData.title;
    obj.serial = newData.serial;
    obj.parentId = newData.parentId;
    obj.hierarchyLevel = newData.hierarchyLevel;
    obj.position = newData.position;
  }
  if (obj && obj.children) {
    for (let j = 0; j < obj.children.length; j++) {
      replaceExistingKey(obj.children[j], obj.children, changedId, newData, j);
    }
  }
}

const getParentKey = (key, tree) => {
  let parentKey = null;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.id === key)) {
        parentKey = node.id;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

function langSingleOBJ(obj, item) {
  item['title'] = item['title'] ? item['title'].trim() : item['title'];
  obj[item.id] = item;
  return obj;
}
