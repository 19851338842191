import { takeLatest, all, fork, put, select, call, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { manufacturerTypes } from './productModals.constants';
import {
  editManufacturerSuccess,
  saveManufacturerDetailsSuccess,
  updateManufacturerModal,
  showManufacturerLoader,
  hideManufacturerLoader,
  saveManufactImageSuccess,
  removeManufactImageSuccess,
  getManufacturerListSuccess,
  setCollectionId,
  removeCollectionId,
  saveManufactImageFail,
  getManufactureListWorkerSuccess
} from './productModals.actions';
import { doRequest } from 'app/shared/redux/sagas/api';
import { message } from 'antd';
import {
  getDataList,
  setFilterIdList,
  getSearchSuggestionSuccess,
  addTagsSucess
} from './../../../catalogue/manufacturer/redux/catalogue_manufacturer.actions';
import { getSearchResults } from './../../../catalogue/manufacturer/redux/catalogue_manufacturer.saga';

import { addJobToQueue } from './../../../../../shared/redux/actions/index';
/** Utilities::Begin */
function mapFormValueToApi({
  name,
  url,
  email,
  phone,
  contactFormUrl,
  animalTesting,
  veganManufacturing,
  crueltyFree,
  address,
  googleAddress,
  country,
  description
}) {
  const booleanKeys = ['may_perform_animal_testing', 'total_vegan_manufacturing', 'cruelty_free'];

  const apiValues = {
    manufacture_name: name,
    manufacture_url: url,
    email: email,
    phone: phone,
    contact_form_url: contactFormUrl,
    may_perform_animal_testing: animalTesting,
    total_vegan_manufacturing: veganManufacturing,
    cruelty_free: crueltyFree,
    address: address,
    google_address: googleAddress,
    country: country ? Number(country) : country,
    image: '',
    description
  };

  for (const key of Object.keys(apiValues)) {
    if (typeof apiValues[key] === 'undefined' && !booleanKeys.includes(key)) {
      apiValues[key] = '';
    } else if (typeof apiValues[key] === 'undefined' && booleanKeys.includes(key)) {
      apiValues[key] = false;
    }
  }

  return apiValues;
}

function transformItem({
  manufacture_name,
  manufacture_url,
  email,
  phone,
  contact_form_url,
  may_perform_animal_testing,
  total_vegan_manufacturing,
  cruelty_free,
  address,
  google_address,
  country,
  image,
  description
}) {
  return {
    name: manufacture_name,
    url: manufacture_url,
    email,
    phone,
    contactFormUrl: contact_form_url,
    animalTesting: may_perform_animal_testing,
    veganManufacturing: total_vegan_manufacturing,
    crueltyFree: cruelty_free,
    address,
    googleAddress: google_address,
    country,
    description: description || ''
  };
}

function transformItemCatalog({
  id,
  manufacture_name,
  manufacture_url,
  email,
  phone,
  contact_form_url,
  may_perform_animal_testing,
  total_vegan_manufacturing,
  cruelty_free,
  address,
  google_address,
  country,
  description
}) {
  return {
    id: id,
    name: manufacture_name,
    url: manufacture_url,
    email,
    phone,
    contactFormUrl: contact_form_url,
    animalTesting: may_perform_animal_testing,
    veganManufacturing: total_vegan_manufacturing,
    crueltyFree: cruelty_free,
    address,
    googleAddress: google_address,
    country,
    description: description || ''
  };
}

function* storeCollectionId(id) {
  yield put(setCollectionId({ id }));
}

function* getCollectionId() {
  const item = yield select(s => s.productModals.manufacture.collectionId);
  yield put(removeCollectionId());
  return item;
}

function* getCollectionIdManu() {
  const item = yield select(s => s.catalogue.manufacturer.collectionId);
  yield put(removeCollectionId());
  return item;
}

function* getIntlMessage(id) {
  const intl = yield select(({ intlReact: { intl } }) => intl);
  return intl.formatMessage({ id });
}

/** Utilities::End */

function* getManufactureList() {
  try {
    const response = yield call(doRequest, {
      url: '/prodmanu/manufacture/MANU',
      method: 'GET'
    });

    const { rows } = response.data;
    const workerParamsGetManufacture = {
      dataArray: rows
    };

    yield put(
      addJobToQueue({
        workerAction: 'MANUFACTURE_LIST',
        data: workerParamsGetManufacture,
        actionToDispatch: getManufactureListWorkerSuccess
      })
    );

    let payloadResponse = yield take('MANUFACTURER_GET_MANUFACTURE_WORKER_LIST_SUCCESS');
    // filtertedData = rows.map(item => item.doc);
    yield put(getManufacturerListSuccess({ data: payloadResponse.payload.data.result }));
  } catch (error) {
    console.log(error);
    message.error(yield getIntlMessage('Generic.ApiMessages.Products.Manufacturer.GetListError'));
  }
}

function* createManufacturer(formData) {
  if (!formData.country) {
    formData.country = '';
  }

  const response = yield call(doRequest, {
    url: '/prodmanu/manufacture/MANU',
    method: 'POST',
    data: formData
  });

  return response.data.id;
}

function* updateManufacturerDetail(id, formData) {
  if (!formData.country) {
    formData.country = '';
  }

  yield call(doRequest, {
    url: `/prodmanu/manufacture/MANU/${id}`,
    method: 'PUT',
    data: formData
  });
}

function* saveManufacturerDetails({ payload: { cb, form, popupFrom } }) {
  yield put(showManufacturerLoader());
  let [id, list] = yield select(s => [
    s.productModals.manufacture.id,
    s.productModals.manufacture.list
  ]);

  const formData = yield call(mapFormValueToApi, form);

  if (popupFrom) {
    const item = yield select(s => s.productModals.manufacture.manufactEditForm);

    if (item && item.collectionId) {
      formData.image = item.collectionId;
    }
  } else {
    // if an item exists, we set its image url
    const item = list.find(item => item._id === id);
    if (item) {
      formData.image = item.image;
    }
  }

  const collectionId = yield call(getCollectionId);

  if (collectionId) {
    formData.image = collectionId;
  }

  try {
    if (id) {
      yield call(updateManufacturerDetail, id, formData);
      message.info(yield getIntlMessage('Generic.ApiMessages.Products.Manufacturer.DetailUpdated'));

      if (popupFrom) {
        const { pagination, sort } = yield select(
          ({
            catalogue: {
              manufacturer: { pagination, sort }
            }
          }) => ({ pagination, sort })
        );

        const tags = yield select(s => s.catalogue.manufacturer.tags);
        if (tags.length) {
          yield fork(getSearchResults, tags);
        } else {
          yield put(getDataList({ pagination, sort }));
        }
      }
    } else {
      id = yield call(createManufacturer, formData);
      message.info(yield getIntlMessage('Generic.ApiMessages.Products.Manufacturer.CreateSuccess'));
      if (popupFrom) {
        yield put(addTagsSucess({ tags: [] }));
        yield put(getSearchSuggestionSuccess({ searchResults: [] }));
        yield put(setFilterIdList({ idList: [] }));

        yield put(
          getDataList({
            pagination: {
              total: 0,
              pageSize: 50,
              current: 1
            },
            sort: { order: 'desc', columnKey: 'create' }
          })
        );
      }
    }

    yield fork(cb);

    yield put(saveManufacturerDetailsSuccess({ data: formData, id }));
    yield put(updateManufacturerModal({ state: 'close' }));
  } catch (error) {
    console.log(error);
    if (error.response.status === 400) {
      message.error(yield getIntlMessage('Generic.ApiMessages.Products.Manufacturer.InvalidData'));
    } else {
      message.error(yield getIntlMessage('Generic.ApiMessages.Products.Manufacturer.SavingError'));
    }
  } finally {
    yield put(hideManufacturerLoader());
  }
}

function* editFromProductPage(id) {
  const list = yield select(s => s.productModals.manufacture.list);
  const item = list.find(i => i._id === id);
  const transformedItem = yield call(transformItem, item);
  let image = '';

  if (item.image) {
    image = yield call(fetchBrandImage, item.image);
  }

  return [transformedItem, image];
}

function* editFromCatalogue(id) {
  const response = yield call(doRequest, {
    url: `prodmanu/manufacture/node/${id}`,
    method: 'GET'
  });

  const { data } = response;

  const brandData = yield call(transformItem, data);

  brandData.collectionId = data.image;

  let image = '';

  if (data.image) {
    image = yield call(fetchBrandImage, data.image);
  }

  return [brandData, image];
}

function* fetchBrandImage(collectionId) {
  const imageUrlResponse = yield call(doRequest, {
    url: `unimup/objctupload/manuf/${collectionId}`,
    method: 'GET'
  });
  const { data } = imageUrlResponse;

  if (data.pages.length) {
    const imageUrl = data.pages[0].optimized.fetchUrl;
    const imageResponse = yield call(doRequest, {
      url: `unimup/objctupload${imageUrl}`,
      method: 'GET',
      responseType: 'blob'
    });

    const { data: imageData } = imageResponse;
    const image = URL.createObjectURL(imageData);

    return image;
  }
}

function* editManufacturerDetails({ payload: { id, popupFrom } }) {
  yield put(updateManufacturerModal({ state: 'open' }));
  yield put(showManufacturerLoader());
  try {
    let transformedItem = {};
    let image = '';

    if (popupFrom && popupFrom === 'manu_catalog') {
      [transformedItem, image] = yield call(editFromCatalogue, id);
    } else {
      [transformedItem, image] = yield call(editFromProductPage, id);
    }
    yield put(editManufacturerSuccess({ data: transformedItem, id, image }));
  } catch (error) {
    console.log({ error });
    yield put(updateManufacturerModal({ state: 'close' }));
    message.error(
      yield getIntlMessage('Generic.ApiMessages.Products.Manufacturer.GetDetailsError')
    );
  } finally {
    yield put(hideManufacturerLoader());
  }
}

function* saveImage({ payload: { popupFrom } }) {
  yield put(showManufacturerLoader());

  let updatedItem = null;

  try {
    const [image, file, id, list, manufactEditForm] = yield select(s => [
      s.productModals.manufacture.image,
      s.productModals.manufacture.imageBinary,
      s.productModals.manufacture.id,
      s.productModals.manufacture.list,
      s.productModals.manufacture.manufactEditForm
    ]);

    const item = list.find(item => item._id === id);
    const collectionId = yield call(getCollectionId);

    let imageId = '';

    if (!popupFrom && item && item.image) {
      imageId = item.image;
    } else if (popupFrom && manufactEditForm && manufactEditForm.collectionId) {
      imageId = manufactEditForm.collectionId;
    } else if (collectionId) {
      imageId = collectionId;
    }

    const formData = new FormData();
    formData.append('media', file);

    const response = yield call(doRequest, {
      url: '/unimup/objctupload/manuf/',
      method: 'POST',
      data: formData
    });

    if (response.status === 200) {
      const {
        data: { CollectionId }
      } = response;

      /**
       * if the item is previsously created
       * */

      if (id) {
        /**
         * if open from catalogue then
         * update the editform value
         */
        if (popupFrom) {
          manufactEditForm.image = CollectionId;
          manufactEditForm.collectionId = CollectionId;
          updatedItem = manufactEditForm;

          const apiFormData = yield call(mapFormValueToApi, manufactEditForm);
          apiFormData.image = CollectionId;

          yield call(updateManufacturerDetail, id, apiFormData);

          const { pagination, sort } = yield select(
            ({
              catalogue: {
                manufacturer: { pagination, sort }
              }
            }) => ({ pagination, sort })
          );
          yield put(getDataList({ pagination, sort }));
        } else {
          item.image = CollectionId;
          updatedItem = item;
          yield call(updateManufacturerDetail, id, item);
        }
      } else {
        yield call(storeCollectionId, CollectionId);
      }
    }

    // if a previous image is uploaded
    // delete it and then upload new one
    if (imageId) {
      yield call(doRequest, {
        url: `unimup/objctupload/manuf/${imageId}`,
        method: 'DELETE'
      });
    }

    yield put(saveManufactImageSuccess({ img: image, item: updatedItem }));
    message.info(
      yield getIntlMessage('Generic.ApiMessages.Products.Manufacturer.ImageUploadSuccess')
    );
  } catch (error) {
    console.log({ error });
    message.error(
      yield getIntlMessage('Generic.ApiMessages.Products.Manufacturer.ImageUploadError')
    );
    yield put(saveManufactImageFail());
  } finally {
    yield put(hideManufacturerLoader());
  }
}

function* deleteImage({ payload: { popupFrom } }) {
  try {
    const [list, id, manufactEditForm] = yield select(s => [
      s.productModals.manufacture.list,
      s.productModals.manufacture.id,
      s.productModals.manufacture.manufactEditForm
    ]);
    const item = list.find(i => i._id === id);
    const collectionId = yield call(getCollectionId);

    let imageId = '';

    if (!popupFrom && item && item.image) {
      imageId = item.image;
    } else if (popupFrom && manufactEditForm && manufactEditForm.collectionId) {
      imageId = manufactEditForm.collectionId;
    } else if (collectionId) {
      imageId = collectionId;
    }

    if (item && item.image) {
      imageId = item.image;
    } else if (collectionId) {
      imageId = collectionId;
    }

    if (imageId) {
      yield call(doRequest, {
        url: `unimup/objctupload/manuf/${imageId}`,
        method: 'DELETE'
      });

      if (item) {
        item.image = '';
        yield call(updateManufacturerDetail, id, item);
      } else if (popupFrom && manufactEditForm) {
        manufactEditForm.image = '';
        const apiFormData = yield call(mapFormValueToApi, manufactEditForm);
        yield call(updateManufacturerDetail, id, apiFormData);
      }

      yield put(removeManufactImageSuccess({ popupFrom }));
      message.info(
        yield getIntlMessage('Generic.ApiMessages.Products.Manufacturer.ImageDeleteSuccess')
      );

      if (popupFrom) {
        const { pagination, sort } = yield select(
          ({
            catalogue: {
              manufacturer: { pagination, sort }
            }
          }) => ({ pagination, sort })
        );
        yield put(getDataList({ pagination, sort }));
      }
    }
  } catch (error) {
    console.log({ error });
    message.error(
      yield getIntlMessage('Generic.ApiMessages.Products.Manufacturer.ImageDeleteError')
    );
  }
}

function* openCatalogueModalSaga() {
  yield put(updateManufacturerModal({ state: 'open' }));
  //yield put(showManufacturerLoader());

  // try {
  //   yield put(showManufacturerLoader());
  // } catch (error) {
  //   yield put(updateManufacturerModal({ state: 'close' }));
  // }
}

/** Watchers */

function* saveManufacturerDetailsWatcher() {
  yield takeLatest(manufacturerTypes.SAVE_DETAILS, saveManufacturerDetails);
}

function* editManufacturerDetailsWatcher() {
  yield takeLatest(manufacturerTypes.EDIT, editManufacturerDetails);
}

function* saveImageWatcher() {
  yield takeLatest(manufacturerTypes.SAVE_IMG, saveImage);
}

function* deleteImageWatcher() {
  yield takeLatest(manufacturerTypes.REMOVE_IMG, deleteImage);
}

function* getManufactureListWatcher() {
  yield takeLatest(manufacturerTypes.GET_LIST, getManufactureList);
}

function* openCatalogueModalSagaWatcher() {
  yield takeLatest(manufacturerTypes.OPEN_CATALOGUE, openCatalogueModalSaga);
}

export default function* rootSaga() {
  yield all([
    fork(getManufactureListWatcher),
    fork(saveManufacturerDetailsWatcher),
    fork(editManufacturerDetailsWatcher),
    fork(saveImageWatcher),
    fork(deleteImageWatcher),
    fork(openCatalogueModalSagaWatcher)
  ]);
}
