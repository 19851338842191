import {
  all,
  fork,
  put,
  takeLatest,
  takeEvery,
  select,
  call,
  race,
  take
} from 'redux-saga/effects';
import arrayMove from 'array-move';
import types from './nutrition-values.constants';
import {
  getNutrientsListSuccess,
  saveNewNodeSuccess,
  updateSortOrderSuccess,
  setSelectedNodeSuccess,
  updateSaveButtonState,
  saveChangesSuccess,
  searchNutritionSuccess,
  deleteNodeSuccess,
  updateSaveModal,
  saveChanges,
  addNewNodeSuccess,
  getNutrientsList,
  saveChangesFail
} from './nutrition-values.actions';
import { message } from 'antd';
import { doRequest } from '../../../../shared/redux/sagas/api';
import ListTypeEnums from '../enums/list-types';

function* getUserLocale() {
  const [id, languageList] = yield select(s => [s.profile.language, s.languageList.languageList]);
  const language = languageList.find(language => language.id === id);
  return language.locale;
}

function* getList() {
  let intl = yield select(state => state.intlReact.intl);
  try {
    const locale = yield call(getUserLocale);

    const { data } = yield call(doRequest, {
      method: 'GET',
      url: 'prodprop/nutritions/'
    });
    const stateData = yield call(buildListData, locale, data);

    if (Object.values(stateData).length === 0) {
      stateData[ListTypeEnums.COMPARISON] = [];
      stateData[ListTypeEnums.NUTRITIONAL_VALUE] = [];
      stateData[ListTypeEnums.VITAMINS] = [];
      stateData[ListTypeEnums.MINERALS] = [];
      stateData[ListTypeEnums.OTHER] = [];
      stateData[ListTypeEnums.COMMON_SUPPLEMENTS] = [];
      stateData[ListTypeEnums.ENEREGY] = [];
    }

    yield put(
      getNutrientsListSuccess({
        data: stateData
      })
    );
  } catch (error) {
    console.log({ error });
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.NutritionValues.GetNutritonListError'
      })
    );
  }
}

function buildListData(userLocale, data) {
  const stateData = {};
  for (let type in data) {
    const typeSpecificValues = getNodeValues(userLocale, data[type]);
    const key = getListKeyForType(type);
    if (key) stateData[key] = typeSpecificValues;
  }
  return stateData;
}

function getListKeyForType(type) {
  const mapping = {
    comp: ListTypeEnums.COMPARISON,
    nut_val: ListTypeEnums.NUTRITIONAL_VALUE,
    vitamin: ListTypeEnums.VITAMINS,
    minaral: ListTypeEnums.MINERALS,
    other: ListTypeEnums.OTHER,
    comm_in_supp: ListTypeEnums.COMMON_SUPPLEMENTS,
    energy: ListTypeEnums.ENEREGY
  };

  return mapping[type];
}

function getTypeForKey(key) {
  const mapping = {
    [ListTypeEnums.COMPARISON]: 'comp',
    [ListTypeEnums.NUTRITIONAL_VALUE]: 'nut_val',
    [ListTypeEnums.VITAMINS]: 'vitamin',
    [ListTypeEnums.MINERALS]: 'minaral',
    [ListTypeEnums.OTHER]: 'other',
    [ListTypeEnums.COMMON_SUPPLEMENTS]: 'comm_in_supp',
    [ListTypeEnums.ENEREGY]: 'energy'
  };

  return mapping[key];
}

function getNodeValues(userLocale, items) {
  return items.map(item => {
    const node = {};
    node.id = item.id;
    node.position = item.position;
    node.value = getNodeLanguageSpecificName(userLocale, item.languages);

    return node;
  });
}

function getNodeLanguageSpecificName(userLocale, languages) {
  if (languages) {
    if (languages[userLocale] && languages[userLocale]['name']) {
      return languages[userLocale]['name'];
    }

    if (languages['en_US'] && languages['en_US']['name']) {
      return languages['en_US']['name'];
    }

    for (let locale in languages) {
      if (languages[locale]['name']) {
        return languages[locale]['name'];
      }
    }
  }
}

function* addNode({ payload: { key } }) {
  const shouldProceed = yield call(handleChangesBeforeSwitching);

  if (!shouldProceed) return;

  yield put(addNewNodeSuccess({ key }));
}

function* saveNode(node) {
  const { key, newNode, values } = node;
  let intl = yield select(state => state.intlReact.intl);

  let arrValues = values.slice();
  arrValues.shift();

  // remove the temp node and set the values if new node value is empty
  if (!newNode) {
    return yield put(saveNewNodeSuccess({ key: key, values: arrValues }));
  }

  const [id, languageList] = yield select(s => [s.profile.language, s.languageList.languageList]);
  const language = languageList.find(language => language.id === id);
  const nodeType = yield call(getTypeForKey, key);
  let formData = {};

  if (key === ListTypeEnums.COMPARISON || key === ListTypeEnums.ENEREGY) {
    formData = {
      nutrition_type: nodeType,
      translations: {
        [language.locale]: { name: newNode }
      }
    };
  } else {
    formData = {
      nutrition_type: nodeType,
      translations: {
        [language.locale]: { name: newNode, scale: 'metric', units: 'μg' }
      }
    };
  }

  try {
    const response = yield call(doRequest, {
      url: 'prodprop/nutritions',
      method: 'POST',
      data: formData
    });
    const responseData = response.data;
    const newNodeItem = {
      value: newNode,
      id: responseData['_id'],
      position: responseData.position
    };
    arrValues = [newNodeItem, ...arrValues];

    yield put(saveNewNodeSuccess({ key: key, values: arrValues, newNode: newNodeItem }));
    yield call(setSelectedNode, newNodeItem.id, key);
  } catch (error) {
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.NutritionValues.NodeSaveError'
      })
    );
    //revert to original values

    return yield put(saveNewNodeSuccess({ key: key, values: arrValues }));
  }
}

function* updateOrder({ payload: { key, values, oldIndex, newIndex } }) {
  let intl = yield select(state => state.intlReact.intl);
  const updatedValues = arrayMove(values, oldIndex, newIndex);
  const id = values[oldIndex]['id'];
  const newPosition = values[newIndex]['position'];
  // update the values to update ui element positions immediately

  yield put(updateSortOrderSuccess({ key, values: updatedValues }));

  try {
    const response = yield call(doRequest, {
      method: 'PUT',
      url: `prodprop/nutritions/dragndrop/${encodeURIComponent(id)}`,
      data: {
        new_pos: newPosition
      }
    });
    message.info(
      intl.formatMessage({
        id: 'Generic.ApiMessages.NutritionValues.NodeOrderChangedSuccess'
      })
    );
    yield put(getNutrientsList());
  } catch (error) {
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.NutritionValues.NodeOrderChangedError'
      })
    );

    yield put(updateSortOrderSuccess({ key, values: values }));
  }
}

function* setSelectedNode(id, type) {
  const btnState = yield select(s => s.nutrients.saveButtonState);

  if (btnState === 'saving-changes') {
    message.warn('You cannot switch nodes while saving!');
    return;
  }

  const shouldProceed = yield call(handleChangesBeforeSwitching);

  if (!shouldProceed) return;

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: `prodprop/nutritions/${encodeURIComponent(id)}`
    });

    const selectedData = response.data || {};

    yield put(setSelectedNodeSuccess({ id, type, selectedData }));
  } catch (error) {}
}

/**
 * when to handle
 *  1 - When switching nodes
 *  2 - When user creates a node while has changes
 */
function* handleChangesBeforeSwitching() {
  const btnState = yield select(s => s.nutrients.saveButtonState);

  if (!(yield call(shouldHandle, btnState))) {
    return true;
  }

  yield put(updateSaveModal({ state: 'show' }));

  const action = yield race({
    saveChanges: take(types.SAVE_CHANGES_AND_SWITCH),
    cancelAndSwitch: take(types.CANCEL_CHANGES_AND_SWITCH),
    cancelAndStay: take(types.CANCEL_CHANGES_AND_STAY)
  });

  if (action.saveChanges) {
    yield put(saveChanges());

    const { saveFailed } = yield race({
      saveSuccess: take(types.SAVE_CHANGES_SUCCESS),
      saveFailed: take(types.SAVE_CHANGES_FAIL)
    });

    if (saveFailed) {
      yield put(updateSaveModal({ state: 'hide' }));
      return false;
    }
  } else if (action.cancelAndStay) {
    yield put(updateSaveModal({ state: 'hide' }));
    return false;
  }

  yield put(updateSaveModal({ state: 'hide' }));
  return true;
}

function shouldHandle(btnState) {
  const btnStateToHandle = ['unsaved', 'unsaved-errors'];
  return btnStateToHandle.includes(btnState);
}

function* saveFormChanges() {
  let intl = yield select(state => state.intlReact.intl);
  const form = yield call(getFormData);
  const [id, nodeData] = yield select(s => [
    s.nutrients.selectedNode,
    s.nutrients.selectedNodeData
  ]);
  const translations = {
    ...nodeData,
    ...form
  };

  try {
    yield put(updateSaveButtonState({ btnState: 'saving-changes' }));

    yield call(doRequest, {
      url: `prodprop/nutritions/${encodeURIComponent(id)}`,
      method: 'PUT',
      data: translations
    });

    yield put(getNutrientsList());

    yield put(saveChangesSuccess({ form: translations }));
  } catch (error) {
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.NutritionValues.FormSaveError'
      })
    );
    yield put(saveChangesFail());
    yield put(updateSaveButtonState({ btnState: 'unsaved' }));
  }
}

function* getFormData() {
  const [formData, type] = yield select(s => [s.nutrients.formData, s.nutrients.selectedNodeType]);

  const formattedFormData = yield call(formatData, formData, type);

  const dataToSend = {
    ...formattedFormData
  };
  return dataToSend;
}

function formatData(formData, type) {
  const nodeTypesToSkipNameCheck = [ListTypeEnums.COMPARISON, ListTypeEnums.ENEREGY];
  const data = {};
  for (let key in formData) {
    const langData = formData[key];
    const newLangData = {};

    //skip if the name is empty
    if (
      !langData ||
      (!nodeTypesToSkipNameCheck.includes(type) && typeof langData['name']['value'] == 'undefined')
    ) {
      continue;
    }

    for (let name in langData) {
      if (typeof langData[name]['value'] !== 'undefined')
        if (langData['name']['value']) {
          newLangData[name] = langData[name]['value'];
        } else {
          newLangData[name] = '';
        }
    }

    data[key] = newLangData;
  }

  return data;
}

function* search(action) {
  const key = action.payload.key.toLowerCase();

  if (!key) {
    return yield put(searchNutritionSuccess({ data: null }));
  }

  const [
    comparison,
    nutritionalValue,
    vitamins,
    minerals,
    other,
    commonSupplements,
    energy
  ] = yield select(({ nutrients: { originalData: _ } }) => [
    _.comparison,
    _.nutritionalValue,
    _.vitamins,
    _.minerals,
    _.other,
    _.commonSupplements,
    _.energy
  ]);

  const [
    comparisonResult,
    nutritionalValueResult,
    vitaminsResult,
    mineralsResult,
    otherResult,
    commonSupplementsResult,
    energyResult
  ] = yield all([
    call(individualSearch, key, comparison),
    call(individualSearch, key, nutritionalValue),
    call(individualSearch, key, vitamins),
    call(individualSearch, key, minerals),
    call(individualSearch, key, other),
    call(individualSearch, key, commonSupplements),
    call(individualSearch, key, energy)
  ]);

  yield put(
    searchNutritionSuccess({
      data: {
        comparison: comparisonResult,
        nutritionalValue: nutritionalValueResult,
        vitamins: vitaminsResult,
        minerals: mineralsResult,
        other: otherResult,
        commonSupplements: commonSupplementsResult,
        energy: energyResult
      }
    })
  );
}

function individualSearch(key, items) {
  if (!items) return [];
  return items.filter(item =>
    item && item.value ? item.value.toLowerCase().includes(key) : false
  );
}

function* deleteNode(action) {
  const { type, id } = action.payload;
  let intl = yield select(state => state.intlReact.intl);

  try {
    const response = yield call(doRequest, {
      url: `prodprop/nutritions/${encodeURIComponent(id)}`,
      method: 'DELETE'
    });
    yield put(deleteNodeSuccess({ type, id }));
  } catch (error) {
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.NutritionValues.NodeDeleteError'
      })
    );
  }
}

/** Watchers */

function* getListWatcher() {
  yield takeLatest(types.GET_LIST, getList);
}

function* saveNodeWatcher() {
  while (true) {
    const action = yield take(types.SAVE_NODE);
    yield call(saveNode, action.payload);
  }
}

function* updateOrderWatcher() {
  yield takeEvery(types.UPDATE_ORDER, updateOrder);
}

function* setSelectedNodeWatcher() {
  yield takeLatest(types.SET_SELECTED_NODE, ({ payload: { id, type } }) =>
    setSelectedNode(id, type)
  );
}

function* saveFormChangesWatcher() {
  yield takeLatest(types.SAVE_CHANGES, saveFormChanges);
}

function* searchWatcher() {
  yield takeLatest(types.SEARCH, search);
}

function* deleteNodeWatcher() {
  yield takeLatest(types.DELETE_NODE, deleteNode);
}

function* addNodeWatcher() {
  while (true) {
    const action = yield take(types.ADD_NODE);
    yield call(addNode, action);
  }
}

export default function* rootSaga() {
  yield all([
    fork(getListWatcher),
    fork(addNodeWatcher),
    fork(saveNodeWatcher),
    fork(updateOrderWatcher),
    fork(setSelectedNodeWatcher),
    fork(saveFormChangesWatcher),
    fork(searchWatcher),
    fork(deleteNodeWatcher)
  ]);
}
