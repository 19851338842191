import React from "react";
import {Button, Form, Input, message} from "antd";
import IntlMessages from "util/IntlMessages";
import {injectIntl } from 'react-intl';
import { connect } from "react-redux";
import CircularProgress from "components/CircularProgress/index";
import OtpInput from 'react-otp-input';



import {
  hideMessage,
  showAuthLoader,
  forgetPassword,
  resetPasswordToken,
  otpSent
} from "appRedux/actions/Auth";

const FormItem = Form.Item;

export class OtpVerification extends React.Component {


    state = { otp: '' };

  handleChange = (otp) => this.setState({ otp });

  handleSubmit = (e) => {
    e.preventDefault();
    const values ={
        email:this.props.email,
        otp:this.state.otp
    }
    this.props.otpSent(values)

    // this.props.form.validateFields((err, values) => {
    //   if (!err) {
    //     this.props.showAuthLoader();
    //     this.props.forgetPassword(values);
    //   }
    // });


  };

  handleBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  }

  componentDidMount(){
   this.props.resetPasswordToken();
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
    
    if (this.props.otpVerification) {
      this.props.history.push(`/resetpassword`);
      
    }
  }

  renderErrorMessage = (messagePassed) => {
    setTimeout(() => {
      message.error(messagePassed);
    }, 10);
    
  }

  render() {
    const {showMessage, loader, alertMessage, intl} = this.props;
    const {getFieldDecorator} = this.props.form;
    console.log(this.props.email)

    return (
      <div className="gx-login-container gx-loader-container">
        <div className="gx-login-content">

          <div className="gx-login-header">
            <img src={require("assets/images/logo-white.png")} alt="wieldy" title="wieldy"/>
          </div>
          <div className="gx-mb-4 fixedCotentWithTxtAutontication">
            <h2>
            Forgot Your Password ?
              {/* <IntlMessages defaultMessage='Forgot Your Password ?' id="AuthenticationPages.ForgotPassword.ForgotYourPassword"/> */}
              
              </h2>
            <p>
            Don't worry. Recovering the password is easy.Just tell us the email you have registered with jumbo
              {/* <IntlMessages defaultMessage={`Don't worry. Recovering the password is easy.Just tell us the email you have registered with jumbo`} id="AuthenticationPages.ForgotPassword.Txt_content"/> */}
              </p>
          </div>
          <div style={{display:'flex', justifyContent:'center'}} >
          <OtpInput className="gx-mb-4"
        value={this.state.otp}
        onChange={this.handleChange}
        numInputs={4}
        separator={<span> - </span>}
        inputStyle={{height:40,width:40,color:'#545454', borderWidth:1, borderRadius:10, borderColor:'#545454'}}
        containerStyle={{height:60}}
      />
          </div>

          <FormItem className="gx-mt-2">
              <Button type="normal"  onClick={this.handleBack} htmlType="button">
                <i className="icon icon-arrow-left back-signin"/>
                Sign In
                {/* <IntlMessages defaultMessage='Sign In' id="Generic.Buttons.SignIn"/> */}
              </Button>
              <Button type="normal" onClick={this.handleSubmit}>
              SEND
                {/* <IntlMessages defaultMessage='SEND' id="Generic.Buttons.Send"/> */}
              </Button>
            </FormItem>
          

          {loader &&
              <div className="gx-loader-view">
                <CircularProgress/>
              </div>}
            {showMessage && this.renderErrorMessage(alertMessage.toString())}
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, reset_token, authUser,email, otpVerification} = auth;
  return {loader, alertMessage, showMessage, reset_token, authUser,email, otpVerification}
}

const WrappedForgotPasswordForm = Form.create()(OtpVerification);
const withIntl = injectIntl(WrappedForgotPasswordForm);

export default connect(mapStateToProps, {
  hideMessage,
  forgetPassword,
  showAuthLoader,
  resetPasswordToken,
  otpSent
})(withIntl);
