import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import profileSagas from 'app/module/profile/redux/profile.saga';
import categoriesSaga from '../../app/module/system/categories/redux/categories.saga';
import ingredientsSaga from '../../app/module/system/ingredients/redux/ingredients.saga';
import langaugeListSaga from '../../app/shared/languageList/languageList.saga';
import localesSaga from '../../app/shared/redux/sagas/locales.saga';
import nutrientsSaga from '../../app/module/system/nutrition-values/redux/nutrition-values.saga';
import imageUploaderSaga from '../../app/shared/redux/sagas/imageUploader.saga';
import productSaga from './../../app/module/contentManagement/products/redux/product.saga';
import storeSaga from './../../app/module/contentManagement/stores/redux/store.saga';
import productModalsSaga from './../../app/module/contentManagement/products/redux/index.saga';
import countriesSage from '../../app/shared/redux/sagas/countries.saga';
import catalogueSaga from '../../app/module/contentManagement/catalogue/index.saga';
import storeInformationSaga from '../../app/module/system/store-information/redux/store-information.saga';
import labelsSaga from './../../app/module/system/lables/redux/labels.saga';
import eNumbersSaga from './../../app/module/system/e-numbers/redux/e-numbers.saga';
import tagsSaga from './../../app/module/system/tags/redux/tags.saga';
import workerSaga from '../../app/shared/redux/sagas/worker-saga';
import moderationSaga from '../../app/module/contentManagement/moderation/redux/index.saga';
import adManagementSaga from '../../app/module/adManagement/redux/index.saga';
import textEditorSaga from '../../app/shared/textEditor/redux/textEditorPopup.saga';
import categoryCircleSaga from './../../app/module/system/categories/categoryCircle/redux/categoryCircle.saga';
import showInMobileHome from './../../app/module/system/categories/categoryShowInMobileHome/redux/showInMobile.saga';
import newIngredients from './../../app/module/system/newIngredients/redux/newIngredient.saga';
export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    workerSaga(),
    profileSagas(),
    categoriesSaga(),
    ingredientsSaga(),
    langaugeListSaga(),
    localesSaga(),
    nutrientsSaga(),
    imageUploaderSaga(),
    productSaga(),
    storeSaga(),
    productModalsSaga(),
    labelsSaga(),
    countriesSage(),
    catalogueSaga(),
    eNumbersSaga(),
    storeInformationSaga(),
    tagsSaga(),
    moderationSaga(),
    adManagementSaga(),
    textEditorSaga(),
    categoryCircleSaga(),
    showInMobileHome(),
    newIngredients()
  ]);
}
