import React from 'react';
import { takeLatest, all, fork, put, select, call, takeEvery, cancelled } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { ingredientTypes } from './ingredientModel.constants';
import {
  editIngredientSuccess,
  updateIngredientModal,
  showIngredientLoader,
  hideIngredientLoader,
  manuallyIngredientAdd,
  deleteIngredientImageSuccess,
  setTempIngredientImage,
  getIngredientImageSuccess,
  reset,
  ingredientImageLoaderOnly,
  identifyFormChanges,
  updateAlternativeList,
  duplicateIngredientResponse,
  IngredientTagVeganDetectModal
} from './ingredientModel.actions';
import { addNewIngredient, editIngredient } from './../product.actions';
import { doRequest } from 'app/shared/redux/sagas/api';
import { message } from 'antd';
import Axios from 'axios';
import { triggerIngredientAlternativeListUpdate } from '../../../../system/ingredients/redux/ingredients.saga';
import {
  setTextEditorModifyData,
  addNewIngredientToArray,
  updateIngredientToArray,
  setManipulateString,
  spinningLoader
} from '../../../../../shared/textEditor/redux/textEditorPopup.actions';

/** Utilities::Begin */

function* getIntlMessage(id) {
  const intl = yield select(({ intlReact: { intl } }) => intl);
  return intl.formatMessage({ id });
}

// create alternative duplicates check sending data structure
function getAlternativeTitleList(alternativeList, ingredientDefLangLocal, locale, subLangLocale) {
  let getAllAlterNatives = {};

  if (alternativeList.alternativeTitle && Array.isArray(alternativeList.alternativeTitle)) {
    getAllAlterNatives[locale] = alternativeList.alternativeTitle.filter(
      item => item && item.status === undefined
    );
  } else {
    getAllAlterNatives[locale] = [];
  }

  if (alternativeList[ingredientDefLangLocal]) {
    if (Array.isArray(alternativeList[ingredientDefLangLocal].alternativeTitle)) {
      getAllAlterNatives[ingredientDefLangLocal] = alternativeList[
        ingredientDefLangLocal
      ].alternativeTitle.filter(item => item && item.status === undefined);
    }
  }
  if (alternativeList[subLangLocale]) {
    if (Array.isArray(alternativeList[subLangLocale].alternativeTitle)) {
      getAllAlterNatives[subLangLocale] = alternativeList[subLangLocale].alternativeTitle.filter(
        item => item && item.status === undefined
      );
    }
  }
  return getAllAlterNatives;
}

// get alternative locale value list
function getAlternativeListWithDup(translationList, ingredientDefLangLocal, locale, subLangLocale) {
  let getAllAlterNatives = {};

  getAllAlterNatives[locale] = {
    alternativeTitle: Array.isArray(translationList.alternativeTitle)
      ? translationList.alternativeTitle
      : []
  };

  if (translationList[ingredientDefLangLocal]) {
    getAllAlterNatives[ingredientDefLangLocal] = {
      alternativeTitle: Array.isArray(translationList[ingredientDefLangLocal].alternativeTitle)
        ? translationList[ingredientDefLangLocal].alternativeTitle
        : []
    };
  } else {
    if (locale !== ingredientDefLangLocal) {
      getAllAlterNatives[ingredientDefLangLocal] = {
        alternativeTitle: []
      };
    }
  }
  if (translationList[subLangLocale]) {
    getAllAlterNatives[subLangLocale] = {
      alternativeTitle: Array.isArray(translationList[subLangLocale].alternativeTitle)
        ? translationList[subLangLocale].alternativeTitle
        : []
    };
  } else {
    if (locale !== subLangLocale && ingredientDefLangLocal !== subLangLocale) {
      getAllAlterNatives[subLangLocale] = {
        alternativeTitle: []
      };
    }
  }

  return getAllAlterNatives;
}

// display duplicate values in alternatives
function duplicatesAlternatives(dataList, alternatives) {
  const alternativesClone = JSON.parse(JSON.stringify(alternatives));
  for (let data of dataList) {
    for (let [locale, value] of Object.entries(data)) {
      if (locale in alternativesClone) {
        const { alternativeTitle } = alternativesClone[locale];
        for (let title of alternativeTitle) {
          const { name } = title;

          if (value.includes(name.toLowerCase().trim())) {
            title['className'] = 'addColorTag';
          }
        }
      }
    }
  }

  return alternativesClone;
}

// get main title send obj
function getMainTitleDuplicateCheckObj(mailTitleTranslationOBJ) {
  for (let key in mailTitleTranslationOBJ) {
    if (!mailTitleTranslationOBJ[key].title) {
      delete mailTitleTranslationOBJ[key];
    }
  }
  const dataToSend = Object.entries(mailTitleTranslationOBJ).reduce((dataObject, row) => {
    const [key, value] = row;
    dataObject[key] = value.title;

    return dataObject;
  }, {});
  return dataToSend;
}

function* saveIngredientDetails({ payload }) {
  const { obj, type, locale, newImage, cb, ingredientDefLangLocal, subLangLocale } = payload;
  console.log('SAVE ING SAGA', payload);
  // let getCursorPositionPath = yield select(state => state.textEditorPopup.ingPath);
  // console.log(' -> getcusorPositionPath', getCursorPositionPath);

  // let getCursorPositionPath = yield select(state => state.textEditorPopup.ingPath);
  // console.log(' -> getcusorPositionPath', getCursorPositionPath);

  let response3 = '';
  yield put(manuallyIngredientAdd({ newAddedValue: null }));
  const imageDeleted = yield select(s => s.productModals.ingredientModel.imageDeleted);
  const tempImageURL = yield select(s => s.productModals.ingredientModel.imageFormData);
  let newModifyObjArray2 = yield select(s => s.textEditorPopup.modifyObjArray);
  let newModifyObjArray = [...newModifyObjArray2];

  const uplodedImageUrl = obj.uplodedImageUrl;
  yield put(showIngredientLoader());

  let obj2 = {
    [locale]: {
      id: locale,
      title: obj.title,
      alternativeTitle: obj.alternativeTitle,
      description: obj.ingredientText,
      links: obj.ingredientLinks
    }
  };

  console.log(obj[ingredientDefLangLocal]);

  if (obj && obj[ingredientDefLangLocal]) {
    const objenUS = obj[ingredientDefLangLocal];
    console.log(obj[ingredientDefLangLocal]);
    if (locale !== ingredientDefLangLocal) {
      obj2 = { ...obj2, [ingredientDefLangLocal]: { ...objenUS, id: ingredientDefLangLocal } };
    }
  }

  yield delay(10);

  if (obj && obj[subLangLocale]) {
    const objsubLangLocale = obj[subLangLocale];
    obj2 = { ...obj2, [subLangLocale]: { ...objsubLangLocale, id: subLangLocale } };
  }

  if (type === 'edit') {
    try {
      const mailTitleTranslationOBJ = JSON.parse(JSON.stringify(obj2));
      const mainTitlesendObj = yield call(getMainTitleDuplicateCheckObj, mailTitleTranslationOBJ);
      const editAddSelectedValueNode = yield select(
        s => s.textEditorPopup.editAddSelectedValueNode
      );
      const responseMainTitle = yield call(doRequest, {
        url: `contrans/translations/mainTitleCheck/ING/${obj.id}`,
        method: 'POST',
        data: mainTitlesendObj
      });

      if (responseMainTitle.status === 200) {
        try {
          let getAlternativeTitle = yield call(
            getAlternativeTitleList,
            obj,
            ingredientDefLangLocal,
            locale,
            subLangLocale
          );

          let responseAlternative = yield call(doRequest, {
            url: `contrans/translations/dupsBulkCheck/ING`,
            method: 'POST',
            data: getAlternativeTitle
          });

          if (responseAlternative.status === 200) {
            let newObj = JSON.parse(JSON.stringify(obj));

            delete newObj[ingredientDefLangLocal];
            delete newObj[subLangLocale];
            if (imageDeleted && tempImageURL) {
              const tempImageURLdata = new FormData();
              tempImageURLdata.append('media', tempImageURL);

              const url = `unimup/objctupload/ings/${obj.id}`;
              let dataObject = { ...newObj };
              const response = yield call(doRequest, {
                url: url,
                method: 'POST',
                data: tempImageURLdata
              });
              if (response.status === 200) {
                dataObject = { ...newObj, uplodedImageUrl: response.data.fetchUrl };
              }

              yield call(doRequest, {
                method: 'PUT',
                url: `prodsup/ingredient/${obj.id}/update`,
                data: dataObject
              });
            } else if (imageDeleted && !tempImageURL) {
              yield call(doRequest, {
                method: 'PUT',
                url: `prodsup/ingredient/${obj.id}/update`,
                data: { ...newObj, uplodedImageUrl: '' }
              });
            } else if (!imageDeleted && tempImageURL) {
              if (uplodedImageUrl && uplodedImageUrl.length > 0) {
                const tempImageURLdata = new FormData();
                tempImageURLdata.append('media', tempImageURL);

                const url = `unimup/objctupload${uplodedImageUrl}`;

                const response5 = yield call(doRequest, {
                  url: url,
                  method: 'PATCH',
                  data: tempImageURLdata
                });
                if (response5.status === 200) {
                  yield call(doRequest, {
                    method: 'PUT',
                    url: `prodsup/ingredient/${obj.id}/update`,
                    data: { ...newObj, uplodedImageUrl: response5.data.fetchUrl }
                  });
                }
              } else {
                const tempImageURLdata = new FormData();
                tempImageURLdata.append('media', tempImageURL);

                const url = `unimup/objctupload/ings/${obj.id}`;
                const response = yield call(doRequest, {
                  url: url,
                  method: 'POST',
                  data: tempImageURLdata
                });
                if (response.status === 200) {
                  yield call(doRequest, {
                    method: 'PUT',
                    url: `prodsup/ingredient/${obj.id}/update`,
                    data: { ...newObj, uplodedImageUrl: response.data.fetchUrl }
                  });
                }
              }
            } else if (!imageDeleted && !tempImageURL) {
              yield call(doRequest, {
                method: 'PUT',
                url: `prodsup/ingredient/${obj.id}/update`,
                data: newObj
              });
            }
            yield call(doRequest, {
              method: 'PATCH',
              // url: `contrans/translations/ING/${obj.id}/${locale}`,
              url: `contrans/translations/ING/${obj.id}`,
              data: obj2
            });

            let { ingredientsArray } = yield select(state => state.product);

            for (let index = 0; index < ingredientsArray.length; index++) {
              const element = ingredientsArray[index];
              if (element.id === obj.id) {
                element.title = obj.title;
                element.isVegan = obj.isVegan;
                element.alternativeTitle = obj.alternativeTitle;
                element.ingLocale = locale;
                // element.alternativeTitle = '';
              }
            }

            let sampleArray = {
              label: obj.title,
              value: obj.id,
              group: 'ingredient',
              style: {
                color: obj.isVegan ? 'green' : 'red'
              },
              isVegan: obj.isVegan,
              isEdit: true
            };

            yield put(manuallyIngredientAdd({ newAddedValue: sampleArray }));

            yield put(editIngredient({ data: [...ingredientsArray] }));

            let objIngredient = {
              id: newObj.id,
              serial: newObj.serial,
              position: 0,
              title: obj.title,
              alternativeTitle: obj.alternativeTitle,
              isVegan: newObj.isVegan,
              ingLocale: locale
              // alternativeTitle: ''
            };

            yield call(addTextEditorIngArray, 'edit', objIngredient, obj2);

            message.info(
              yield getIntlMessage('Generic.ApiMessages.Products.Ingredient.DetailUpdated')
            );

            const id = response3.data ? response3.data.id : obj ? obj.id : null;
            yield fork(triggerIngredientAlternativeListUpdate, id);

            yield put(identifyFormChanges({ state: 'close' }));
            yield fork(cb);
            yield put(updateIngredientModal({ state: 'close' }));
            yield put(deleteIngredientImageSuccess({ imageDeleted: false }));
            yield put(duplicateIngredientResponse({ duplicateTitles: [] }));
            yield put(
              IngredientTagVeganDetectModal({
                title: obj.title,
                isVegan: obj.isVegan ? 'green' : 'red'
              })
            );

            // console.log(newModifyObjArray);
            // console.log(obj);
            // console.log(editAddSelectedValueNode);
            if (newModifyObjArray && newModifyObjArray.length > 0) {
              let tempArray = [];
              let tempObj = {};
              let altTitle = '';
              newModifyObjArray.map(element => {
                tempObj = {};
                if (element.attribs.id === editAddSelectedValueNode.id) {
                  if (element.attribs.title) {
                    if (obj.alternativeTitle && Array.isArray(obj.alternativeTitle)) {
                      obj.alternativeTitle.forEach(altItem =>
                        editAddSelectedValueNode.name
                          ? altItem.name.trim() === editAddSelectedValueNode.name.trim()
                            ? (altTitle = editAddSelectedValueNode.name)
                            : ''
                          : editAddSelectedValueNode.title
                          ? altItem.name.trim() === editAddSelectedValueNode.title.trim()
                            ? (altTitle = editAddSelectedValueNode.title)
                            : ''
                          : ''
                      );
                    }
                    tempObj = {
                      ...element,
                      attribs: { ...element.attribs, title: altTitle ? altTitle : obj.title }
                    };
                  }

                  if (element.children[0].text !== '...') {
                    var mySubString = element.children[0].text.split(' #')[1];
                    if (mySubString) {
                      let childrenArr = [...tempObj.children];
                      let tarray = { ...tempObj.children[0] };
                      tarray = {
                        text: altTitle
                          ? altTitle + ' #' + mySubString
                          : obj.title + ' #' + mySubString
                      };
                      childrenArr[0] = tarray;
                      tempObj = { ...tempObj, children: childrenArr };
                      // tempObj.children[0].text = obj.title + ' #' + mySubString;
                    } else {
                      let childrenArr = [...tempObj.children];
                      let tarray = { ...tempObj.children[0] };
                      tarray = { text: altTitle ? altTitle : obj.title };
                      childrenArr[0] = tarray;
                      tempObj = { ...tempObj, children: childrenArr };
                    }
                  }
                } else {
                  tempObj = { ...element };
                }

                tempArray.push(tempObj);
              });

              yield put(setTextEditorModifyData({ data: tempArray }));
              delay(100);
              yield put(spinningLoader({ loader: 'true' }));
              let createString = tempArray
                .map((item, i) =>
                  item.children
                    .map((element, x) => {
                      return x === 0 && element.text === '...'
                        ? item.attribs.title + ' '
                        : element.text;
                    })
                    .join('')
                )
                .join('');

              yield put(
                setManipulateString({
                  data: createString
                })
              );
            }
          }
        } catch (error) {
          console.log(error);
          if (error.response.status === 419) {
            let getAlternatives = yield call(
              getAlternativeListWithDup,
              obj,
              ingredientDefLangLocal,
              locale,
              subLangLocale
            );

            let responseData = error.response.data;
            let getAlternativesDuplicates = yield call(
              duplicatesAlternatives,
              responseData,
              getAlternatives
            );
            message.error(
              yield getIntlMessage('Generic.ApiMessages.Tags.AlternativeDuplicateError')
            );

            yield put(updateAlternativeList({ alternatives: getAlternativesDuplicates }));
          }
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 419) {
        message.error(
          yield getIntlMessage('Generic.ApiMessages.Products.Ingredient.SavingErrorWithDuplicates')
        );
        yield put(hideIngredientLoader());
        yield put(duplicateIngredientResponse({ duplicateTitles: error.response.data }));
      }
    }
  } else if (type === 'add') {
    try {
      const mailTitleTranslationOBJ = JSON.parse(JSON.stringify(obj2));
      const mainTitlesendObj = yield call(getMainTitleDuplicateCheckObj, mailTitleTranslationOBJ);

      const responseMainTitle = yield call(doRequest, {
        url: `contrans/translations/mainTitleAddCheck/ING`,
        method: 'POST',
        data: mainTitlesendObj
      });

      if (responseMainTitle.status === 200) {
        yield put(duplicateIngredientResponse({ duplicateTitles: [] }));
        try {
          let getAlternativeTitle = yield call(
            getAlternativeTitleList,
            obj,
            ingredientDefLangLocal,
            locale,
            subLangLocale
          );

          let responseAlternative = yield call(doRequest, {
            url: `contrans/translations/dupsBulkCheck/ING`,
            method: 'POST',
            data: getAlternativeTitle
          });

          if (responseAlternative.status === 200) {
            let newObj = JSON.parse(JSON.stringify(obj));
            delete newObj[ingredientDefLangLocal];
            delete newObj[subLangLocale];
            let obj3 = { ...newObj };
            delete obj3.id;

            response3 = yield call(doRequest, {
              method: 'POST',
              url: 'prodsup/ingredient/add',
              data: obj3
            });
            if (response3.status === 200) {
              // let obj4 = {
              //   id: locale,
              //   title: obj.title,
              //   description: obj.ingredientText,
              //   links: obj.ingredientLinks,
              //   alternativeTitle: obj.alternativeTitle
              // };

              yield call(doRequest, {
                method: 'PATCH',
                // url: `contrans/translations/ING/${response3.data.id}/${locale}`,
                url: `contrans/translations/ING/${response3.data.id}`,
                data: obj2
              });
              const tempImageURL = yield select(s => s.productModals.ingredientModel.imageFormData);
              if (tempImageURL) {
                const tempImageURLdata = new FormData();
                tempImageURLdata.append('media', tempImageURL);
                const url = `unimup/objctupload/ings/${response3.data.id}`;

                const response5 = yield call(doRequest, {
                  url: url,
                  method: 'POST',
                  data: tempImageURLdata
                });
                if (response5.status === 200) {
                  yield call(doRequest, {
                    method: 'PUT',
                    url: `prodsup/ingredient/${response3.data.id}/update`,
                    data: { ...obj, uplodedImageUrl: response5.data.fetchUrl }
                  });
                }
              }
            }

            let objIngredient = {
              id: response3.data.id,
              serial: response3.data.serial,
              position: 0,
              title: obj.title,
              alternativeTitle: obj.alternativeTitle,
              isVegan: obj.isVegan,
              ingLocale: locale
              // alternativeTitle: ''
            };

            let sampleArray = {
              label: obj.title,
              value: response3.data.id,
              serial: response3.data.serial,
              group: 'ingredient',
              style: {
                color: obj.isVegan ? 'green' : 'red'
              },
              isEdit: false
            };
            yield put(addNewIngredient({ data: objIngredient }));

            yield call(addTextEditorIngArray, 'add', objIngredient, obj2);

            message.info(
              yield getIntlMessage('Generic.ApiMessages.Products.Ingredient.DetailUpdated')
            );
            yield delay(100);
            yield put(manuallyIngredientAdd({ newAddedValue: sampleArray }));
            yield put(reset());

            const id = response3.data ? response3.data.id : obj ? obj.id : null;
            yield fork(triggerIngredientAlternativeListUpdate, id);

            yield put(identifyFormChanges({ state: 'close' }));
            yield fork(cb);
            yield put(updateIngredientModal({ state: 'close' }));
            yield put(deleteIngredientImageSuccess({ imageDeleted: false }));
            yield put(duplicateIngredientResponse({ duplicateTitles: [] }));
            yield put(
              IngredientTagVeganDetectModal({
                title: obj.title,
                isVegan: obj.isVegan ? 'green' : 'red'
              })
            );
            const editAddSelectedValueNode = yield select(
              s => s.textEditorPopup.editAddSelectedValueNode
            );
            if (newModifyObjArray && newModifyObjArray.length > 0) {
              let tempArray = [];
              let tempObj = {};
              newModifyObjArray.map(element => {
                tempObj = {};
                if (element.attribs.i === editAddSelectedValueNode.i) {
                  if (element.attribs.title) {
                    tempObj = { ...element, attribs: { ...element.attribs, title: obj.title } };
                    // tempObj.attribs.title = obj.title;
                  }
                  if (element.children[0].text !== '...') {
                    var mySubString = element.children[0].text.split(' #')[1];
                    if (mySubString) {
                      let childrenArr = [...tempObj.children];
                      let tarray = { ...tempObj.children[0] };
                      tarray = { text: obj.title };
                      childrenArr[0] = tarray;
                      tempObj = { ...tempObj, children: childrenArr };
                      // tempObj.children[0].text = obj.title + ' #' + mySubString;
                    } else {
                      let childrenArr = [...tempObj.children];
                      let tarray = { ...tempObj.children[0] };
                      tarray = { text: obj.title };
                      childrenArr[0] = tarray;
                      tempObj = { ...tempObj, children: childrenArr };
                    }
                  }
                } else {
                  tempObj = { ...element };
                }
                tempArray.push(tempObj);
                console.log('CREATE ARRAY', tempArray);
              });

              yield put(setTextEditorModifyData({ data: tempArray }));
              delay(100);
              yield put(spinningLoader({ loader: 'true' }));
              let createString = tempArray
                .map((item, i) =>
                  item.children
                    .map((element, x) => {
                      return x === 0 && element.text === '...'
                        ? item.attribs.title + ' '
                        : element.text;
                    })
                    .join('')
                )
                .join('');

              yield put(
                setManipulateString({
                  data: createString
                })
              );
            }
          }
        } catch (error) {
          console.log(error);

          if (error.response.status === 419) {
            let getAlternatives = yield call(
              getAlternativeListWithDup,
              obj,
              ingredientDefLangLocal,
              locale,
              subLangLocale
            );

            let responseData = error.response.data;
            let getAlternativesDuplicates = yield call(
              duplicatesAlternatives,
              responseData,
              getAlternatives
            );
            message.error(
              yield getIntlMessage('Generic.ApiMessages.Tags.AlternativeDuplicateError')
            );

            yield put(updateAlternativeList({ alternatives: getAlternativesDuplicates }));
          }
        }
      }
    } catch (error) {
      if (error.response.status === 400) {
        message.error(yield getIntlMessage('Generic.ApiMessages.Products.Ingredient.InvalidData'));
      } else if (error.response.status === 419) {
        message.error(
          yield getIntlMessage('Generic.ApiMessages.Products.Ingredient.SavingErrorWithDuplicates')
        );
        yield put(hideIngredientLoader());
        yield put(duplicateIngredientResponse({ duplicateTitles: error.response.data }));
      } else {
        message.error(yield getIntlMessage('Generic.ApiMessages.Products.Ingredient.SavingError'));
      }
    }
  }

  yield put(hideIngredientLoader());
}

function* addTextEditorIngArray(type, objPassed, objAlternative) {
  console.log(objPassed);

  let array = [];

  if (type === 'add') {
    for (var key in objAlternative) {
      if (objAlternative[key]) {
        let ingObj = {
          id: objPassed.id,
          title: objAlternative[key].title,
          isVegan: objPassed.isVegan,
          serial: objPassed.serial,
          locale: key,
          status: 'mainTitle'
        };

        array.push(ingObj);

        let alterTitleArray = objAlternative[key].alternativeTitle
          ? objAlternative[key].alternativeTitle
          : [];
        if (alterTitleArray && alterTitleArray.length > 0) {
          for (let index = 0; index < alterTitleArray.length; index++) {
            let objAltTemp = alterTitleArray[index];

            let objAlt = {
              id: objPassed.id,
              title: objAltTemp.name,
              isVegan: objPassed.isVegan,
              serial: objPassed.serial,
              locale: key,
              status: 'alternativeTitle'
            };

            array.push(objAlt);
          }
        }
      }
      // object[prop]
    }

    yield put(addNewIngredientToArray({ data: array }));
  } else if (type === 'edit') {
    for (var key in objAlternative) {
      if (objAlternative[key]) {
        let ingObj = {
          id: objPassed.id,
          title: objAlternative[key].title,
          isVegan: objPassed.isVegan,
          serial: objPassed.serial,
          locale: key,
          status: 'mainTitle'
        };

        array.push(ingObj);

        let alterTitleArray = objAlternative[key].alternativeTitle
          ? objAlternative[key].alternativeTitle
          : [];
        if (alterTitleArray && alterTitleArray.length > 0) {
          for (let index = 0; index < alterTitleArray.length; index++) {
            let objAltTemp = alterTitleArray[index];

            let objAlt = {
              id: objPassed.id,
              title: objAltTemp.name,
              isVegan: objPassed.isVegan,
              serial: objPassed.serial,
              locale: key,
              status: 'alternativeTitle'
            };

            array.push(objAlt);
          }
        }
      }
      // object[prop]
    }

    let xx = {
      array: array,
      id: objPassed.id
    };

    yield put(updateIngredientToArray({ data: xx }));
  }
}
// get eddited ingredient data and image
function* editFromCatalogue(id, locale, subLangLocale) {
  let data = '';
  if (locale !== 'en_US') {
    const response3 = yield call(doRequest, {
      url: `contrans/translations/ING/${id}/en_US`,
      method: 'GET'
    });
    if (response3 && response3.data) {
      if (response3.data[0] && response3.data[0].values_translation) {
        data = {
          ...data,
          en_US: {
            links: response3.data[0].values_translation.links,
            description: response3.data[0].values_translation.description,
            title: response3.data[0].values_translation.title,
            alternativeTitle: response3.data[0].values_translation.alternativeTitle
          }
        };
      } else {
        data = {
          ...data,
          links: [],
          description: '',
          title: '',
          alternativeTitle: ''
        };
      }
    }
  }

  if (
    subLangLocale &&
    subLangLocale !== '' &&
    subLangLocale !== 'en_US' &&
    subLangLocale !== locale
  ) {
    const responseSub = yield call(doRequest, {
      url: `contrans/translations/ING/${id}/${subLangLocale}`,
      method: 'GET'
    });

    if (responseSub && responseSub.data) {
      if (responseSub.data[0] && responseSub.data[0].values_translation) {
        data = {
          ...data,
          [subLangLocale]: {
            links: responseSub.data[0].values_translation.links,
            description: responseSub.data[0].values_translation.description,
            title: responseSub.data[0].values_translation.title,
            alternativeTitle: responseSub.data[0].values_translation.alternativeTitle
          }
        };
      } else {
        data = {
          ...data,
          links: [],
          description: '',
          title: '',
          alternativeTitle: ''
        };
      }
    }
  }

  const response2 = yield call(doRequest, {
    url: `contrans/translations/ING/${id}/${locale}`,
    method: 'GET'
  });
  const response1 = yield call(doRequest, {
    url: `prodsup/ingredient/get/${id}`,
    method: 'GET'
  });

  if (response2 && response2.data) {
    if (response1 && response1.data) {
      const response1Data = response1.data;
      data = { ...data, ...response1Data };
      if (response2.data[0] && response2.data[0].values_translation) {
        const {
          description,
          links,
          title,
          alternativeTitle
        } = response2.data[0].values_translation;
        data = {
          ...data,
          links: links,
          description: description,
          title: title,
          alternativeTitle: alternativeTitle
        };
      } else {
        data = {
          ...data,
          links: [],
          description: '',
          title: '',
          alternativeTitle: ''
        };
      }
    }
  }

  let imageBlob = '';
  if (data.uplodedImageUrl) {
    const uploadURL = data.uplodedImageUrl;

    const cancelSource = Axios.CancelToken.source();
    try {
      if (!uploadURL.includes('ings')) {
        yield put(getIngredientImageSuccess('', false));
        return [data, imageBlob];
      }
      const response = yield call(doRequest, {
        url: `unimup/objctupload${uploadURL}`,
        method: 'GET',
        responseType: 'blob',
        cancelToken: cancelSource.token
      });

      let imageLoader = true;

      imageLoader = false;
      let image = URL.createObjectURL(response.data);
      imageBlob = image;

      if (response.status === 200) {
        if (response.data) {
          imageLoader = false;
          let image = URL.createObjectURL(response.data);
          imageBlob = image;

          yield put(getIngredientImageSuccess(image, imageLoader));
          yield put(setTempIngredientImage(image));
          let newRandomKeyValue = yield select(
            state => state.ingredients.randomNumberLastIngredient
          );

          setTimeout(() => {
            if (newRandomKeyValue && newRandomKeyValue.randomNumb) {
              const element = document.getElementById(newRandomKeyValue.randomNumb.toString());
              if (element) {
                element.focus();
              }
            }
          }, 100);
        }
      }
    } catch (error) {
      yield put(getIngredientImageSuccess('', false));
      let newRandomKeyValue = yield select(state => state.ingredients.randomNumberLastIngredient);

      setTimeout(() => {
        if (newRandomKeyValue && newRandomKeyValue.randomNumb) {
          const element = document.getElementById(newRandomKeyValue.randomNumb.toString());
          if (element) {
            element.focus();
          }
        }
      }, 100);
    } finally {
      if (yield cancelled()) {
        yield call(cancelSource.cancel);
      }
    }
  }

  return [data, imageBlob];
}

// set store alternative data list with update status to old values
function getAlternativeList(translationList, locale) {
  let getAllAlterNatives = {};

  if (locale !== 'en_US') {
    if (
      translationList['en_US'] &&
      translationList['en_US'].alternativeTitle &&
      Array.isArray(translationList['en_US'].alternativeTitle)
    ) {
      getAllAlterNatives['en_US'] = {
        alternativeTitle: translationList['en_US'].alternativeTitle
      };

      getAllAlterNatives['en_US'].alternativeTitle.map(item => {
        if (item && item.className) {
          return delete item.className;
        }
        if (item) {
          return (item['status'] = 'OldValue');
        }
      });
    } else {
      getAllAlterNatives['en_US'] = {
        alternativeTitle: []
      };
    }

    if (
      translationList &&
      translationList.alternativeTitle &&
      Array.isArray(translationList.alternativeTitle)
    ) {
      getAllAlterNatives[locale] = {
        alternativeTitle: translationList.alternativeTitle
      };

      getAllAlterNatives[locale].alternativeTitle.map(item => {
        if (item && item.className) {
          return delete item.className;
        }
        if (item) {
          return (item['status'] = 'OldValue');
        }
      });
    } else {
      getAllAlterNatives[locale] = {
        alternativeTitle: []
      };
    }
  } else {
    if (
      translationList &&
      translationList.alternativeTitle &&
      Array.isArray(translationList.alternativeTitle)
    ) {
      getAllAlterNatives['en_US'] = {
        alternativeTitle: translationList.alternativeTitle
      };

      getAllAlterNatives['en_US'].alternativeTitle.map(item => {
        if (item && item.className) {
          return delete item.className;
        }
        if (item) {
          return (item['status'] = 'OldValue');
        }
      });
    }
  }

  return getAllAlterNatives;
}

// get data to edit ingredient
function* editIngredientDetails({ payload }) {
  const { id, locale, ingredientDefLangLocal, subLangLocale } = payload;
  yield put(updateIngredientModal({ state: 'open' }));
  yield put(showIngredientLoader());
  try {
    let transformedItem = {};
    let imageUrl = '';
    let image = '';
    const imageLoader = false;
    // call get ingredient image and ingredient data
    [transformedItem, image] = yield call(editFromCatalogue, id, locale, subLangLocale);
    imageUrl = yield select(s => s.productModals.ingredientModel.imageUrl);

    let getAlternatives = yield call(getAlternativeList, transformedItem, locale);

    yield put(
      editIngredientSuccess({ data: transformedItem, id, imageUrl, imageLoader, getAlternatives })
    );
    yield put(duplicateIngredientResponse({ duplicateTitles: [] }));
  } catch (error) {
    console.log({ error });
    yield put(updateIngredientModal({ state: 'close' }));
    message.error(yield getIntlMessage('Generic.ApiMessages.Products.Ingredient.GetDetailsError'));
  } finally {
    yield put(hideIngredientLoader());
  }
}

function* ingredientImageGet({ payload }) {
  const cancelSource = Axios.CancelToken.source();
  try {
    if (payload === null || typeof payload === 'undefined') return;

    if (!payload.includes('ings')) {
      return yield put(getIngredientImageSuccess('', false));
    }
    const response = yield call(doRequest, {
      url: payload,
      method: 'GET',
      responseType: 'blob',
      cancelToken: cancelSource.token
    });

    let imageLoader = true;

    if (response.status === 200) {
      if (response.data) {
        imageLoader = false;
        let image = URL.createObjectURL(response.data);
        yield put(getIngredientImageSuccess(image, imageLoader));
        let newRandomKeyValue = yield select(state => state.ingredients.randomNumberLastIngredient);

        setTimeout(() => {
          if (newRandomKeyValue && newRandomKeyValue.randomNumb) {
            const element = document.getElementById(newRandomKeyValue.randomNumb.toString());
            if (element) {
              element.focus();
            }
          }
        }, 100);
      }
    }
  } catch (error) {
    yield put(getIngredientImageSuccess('', false));
    let newRandomKeyValue = yield select(state => state.ingredients.randomNumberLastIngredient);

    setTimeout(() => {
      if (newRandomKeyValue && newRandomKeyValue.randomNumb) {
        const element = document.getElementById(newRandomKeyValue.randomNumb.toString());
        if (element) {
          element.focus();
        }
      }
    }, 100);
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

function* deleteIngredientImage({ payload }) {
  let intl = yield select(state => state.intlReact.intl);
  const attributes = yield select(s => s.productModals.ingredientModel.ingredientEditForm);
  try {
    if (attributes && attributes.uplodedImageUrl) {
      const url = 'unimup/objctupload' + attributes.uplodedImageUrl;
      yield call(doRequest, {
        method: 'DELETE',
        url: `${url}`
      });

      const data = {
        imageSrc: attributes.imageSrc ? attributes.imageSrc : '',
        uplodedImageUrl: '',
        imageLinkDetails: attributes.imageLinkDetails ? attributes.imageLinkDetails : '',
        isHealthy: attributes.isHealthy ? 1 : 0,
        isVegan: attributes.isVegan ? 1 : 0,
        isMayContain: attributes.isMayContain ? 1 : 0,
        title: attributes.title ? attributes.title : '',
        parentId: attributes.parentId
      };

      yield call(doRequest, {
        method: 'PUT',
        url: `prodsup/ingredient/${attributes.id}/update`,
        data: data
      });
      message.info(yield getIntlMessage('Generic.ApiMessages.Ingredient.IngredientImageDeleted'));
      yield put(deleteIngredientImageSuccess({ imageDeleted: true }));
      yield put(ingredientImageLoaderOnly({ imageLoader: false }));
    } else {
      yield put(deleteIngredientImageSuccess({ imageDeleted: true }));
      yield put(ingredientImageLoaderOnly({ imageLoader: false }));
    }
  } catch (error) {
    console.log(error);
  }
}

//END Image model saga
function* openCatalogueModalSaga() {
  yield put(updateIngredientModal({ state: 'open' }));
}

/** Watchers */

function* saveIngredientDetailsWatcher() {
  yield takeLatest(ingredientTypes.SAVE_DETAILS, saveIngredientDetails);
}

function* editIngredientDetailsWatcher() {
  yield takeLatest(ingredientTypes.EDIT, editIngredientDetails);
}

function* getIngredientImageListener() {
  yield takeLatest(ingredientTypes.GET_INGREDIENT_IMAGE, ingredientImageGet);
}

function* deleteIngredientImageListener() {
  yield takeEvery(ingredientTypes.DELETE_INGREDIENT_IMAGE, deleteIngredientImage);
}

function* openCatalogueModalSagaWatcher() {
  yield takeLatest(ingredientTypes.OPEN_CATALOGUE, openCatalogueModalSaga);
}

export default function* rootSaga() {
  yield all([
    fork(saveIngredientDetailsWatcher),
    fork(editIngredientDetailsWatcher),
    fork(openCatalogueModalSagaWatcher),
    fork(getIngredientImageListener),
    fork(deleteIngredientImageListener)
  ]);
}
