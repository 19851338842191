import {
  takeLatest,
  takeEvery,
  all,
  fork,
  select,
  call,
  put,
  cancel,
  cancelled,
  take,
  race
} from 'redux-saga/effects';
import productTypes from './catalogue_products.constants';
import { doRequest } from 'app/shared/redux/sagas/api';
import {
  deleteProductSuccess,
  fetchRowsSuccess,
  fetchRows,
  fetchRowsFailure,
  setProductImage,
  setProductUserImage,
  getSearchSuggestionSuccess,
  addTagsSuccess,
  deleteTagSuccess,
  setSearchProductIds
} from './catalogue_products.actions';
import { createSelector } from 'reselect';
import { message } from 'antd';
import { filterCountryEmojis } from '../../../../../shared/utils/index';

/** Selectors::Begin */
const currentLocaleIdSelector = state => state.profile.language;
const languageListSelector = state => state.languageList.languageList;
const localeSelector = createSelector(
  languageListSelector,
  currentLocaleIdSelector,
  (list, localeId) => {
    const language = list.find(item => item.id === localeId);

    if (language) {
      return language.locale;
    }

    return 'en_US';
  }
);
const indexedItemSelector = state => state.indexedItem;
const indexedCountriesSelector = createSelector(
  indexedItemSelector,
  items => items.countries
);
const intlSelector = state => state.intlReact.intl;

/** Selectors::End */
/**
 *
 * @Utils
 */
/**
 * Split given array into chunks
 */
function chunk(array, size) {
  const chunked_arr = [];
  let index = 0;
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
}

// get sorted value
function* getCountryFlagList(dataList) {
  let newDataListArray = [];
  const countryList = yield select(indexedCountriesSelector);
  for (let index = 0; index < dataList.length; index++) {
    const item = dataList[index];
    // get country flag with  country name
    if (item.country_id) {
      item.country_code =
        countryList[item.country_id] && countryList[item.country_id].code
          ? countryList[item.country_id].code.toLowerCase()
          : '';
    } else {
      item.country_code = '';
    }
    newDataListArray.push(item);
  }
  return newDataListArray;
}

function getSortKeyAndDirection(sort = {}) {
  sort = { ...sort };

  if (sort.columnKey && sort.order) {
    if (sort.order === 'ascend') {
      sort.order = 'ASC';
    }
    if (sort.order === 'descend') {
      sort.order = 'DESC';
    }
  } else {
    sort.columnKey = 'date';
    sort.order = 'DESC';
  }

  if (sort.columnKey && sort.columnKey === 'lastEdit') {
    sort.columnKey = 'last_edit';
  }

  sort.order = sort.order.toUpperCase();

  return sort;
}

function* getLocalizedMessage(key) {
  const intl = yield select(intlSelector);
  return intl.formatMessage({ id: key });
}

// get data range in requested
function getPaginationValues(pagination = {}) {
  let current = pagination.current || 0;
  let pageSize = pagination.pageSize || 5;

  let newDataValue = pageSize * (current - 1);

  let reqDataObj = {};

  if (current > 1) {
    reqDataObj.offset = newDataValue;
    reqDataObj.limit = pageSize;
  } else {
    reqDataObj.offset = 0;
    reqDataObj.limit = pageSize;
  }

  return reqDataObj;
}

/**@description utils */
function* getIndexedProductIds() {
  const items = yield select(state => state.indexedItem.productCatalogue);
  return items;
}

function transformOptionValues(data = {}, countryList) {
  const countries = data['country']['values'].map(id => {
    return id ? countryList[id] : '';
  });

  return [
    {
      title: 'SearchName',
      values: data['product_name']['values']
    },
    {
      title: 'Countries',
      values: countries
    },
    {
      title: 'Ean',
      values: data['ean']['values']
    }
  ];
}

function transformOptionValuesToPostData(tags = []) {
  const values = { product_name: { values: [] }, country: { values: [] }, ean: { values: [] } };
  for (let tag of tags) {
    if (tag.group === 'SearchName') {
      values.product_name.values.push(tag.value);
    } else if (tag.group === 'Countries') {
      values.country.values.push(tag.value);
    } else {
      values.ean.values.push(tag.value);
    }
  }

  return values;
}

/**
 *
 * @Utils END
 */

function* getTableConfig() {
  const { pagination, sort } = yield select(s => ({
    pagination: s.catalogue.products.pagination,
    sort: s.catalogue.products.sort
  }));
  return { pagination, sort };
}

function* getRegularDataList(paramsObj) {
  const response = yield call(doRequest, {
    url: 'prodres/products/list',
    method: 'GET',
    params: paramsObj
  });

  return response;
}

function* getSearchDataList(data) {
  const response = yield call(doRequest, {
    url: 'prodres/products/idlist',
    method: 'POST',
    data: data
  });

  return response;
}

function* fetchRowsSaga({ payload }) {
  let userImageFork;
  let imageFork;

  try {
    const {
      config: { pagination, sort }
    } = payload;

    const [searchIds, tags] = yield select(({ catalogue: { products } }) => [
      products.searchProductIds,
      products.tags
    ]);
    const { columnKey, order } = yield call(getSortKeyAndDirection, sort);
    const { offset, limit } = yield call(getPaginationValues, pagination);
    const locale = yield select(localeSelector);

    const requestBody = {
      offset: offset,
      limit,
      order: order,
      sort_by: columnKey,
      locale: locale
    };
    let response;

    if (tags.length) {
      requestBody.idlist = searchIds;
      response = yield call(getSearchDataList, requestBody);
    } else {
      response = yield call(getRegularDataList, requestBody);
    }

    if (response.status === 200) {
      let {
        data: { data, row_total }
      } = response;
      pagination.total = row_total;

      if (data.length > 0) {
        data = yield call(getCountryFlagList, data);

        imageFork = yield fork(getProductsImage, data);
        userImageFork = yield fork(getProductsUserImage, data);
      }

      yield put(fetchRowsSuccess({ data: data, config: { pagination, sort }, locale }));
      /**
       * pause the execution of function without
       * exiting to find if its get cancelled and
       * cancel any ongoing picture requests
       */
      const { reset } = yield race({
        get: take(productTypes.FETCH_ROWS),
        reset: take(productTypes.RESET)
      });

      if (reset) {
        yield cancel(imageFork);
        yield cancel(userImageFork);
      }
    }
  } catch (error) {
    console.log(error);
    const errorMessage = yield call(
      getLocalizedMessage,
      'Generic.ApiMessages.Catalogue.Products.GetListError'
    );
    message.error(errorMessage);
    yield put(fetchRowsFailure());
  } finally {
    // cancel ongoing requests if new row list is fetched
    if (yield cancelled()) {
      if (userImageFork) {
        yield cancel(userImageFork);
        yield cancel(imageFork);
      }
    }
  }
}

function* deleteProductSaga({ payload }) {
  let intl = yield select(state => state.intlReact.intl);

  const { id } = payload;

  try {
    const response = yield call(doRequest, {
      method: 'DELETE',
      url: 'prodres/products/' + id
    });
    if (response.status === 200) {
      message.info(
        intl.formatMessage({
          id: 'Generic.ApiMessages.Catalogue.Products.ProductDeleteSuccess'
        })
      );

      const config = yield call(getTableConfig);
      yield put(fetchRows({ config }));

      yield put(deleteProductSuccess({ id }));
    }
  } catch (error) {
    console.log(error);
    const errorMessage = yield call(
      getLocalizedMessage,
      'Generic.ApiMessages.Catalogue.Products.ProductDeleteError'
    );
    message.error(errorMessage);
  }
}

function* getProductImage(id, collectionId) {
  if (!collectionId) return;
  try {
    const imageResponse = yield call(doRequest, {
      url: `unimup/objctupload/manuf/${collectionId}`,
      method: 'GET'
    });

    const { data } = imageResponse;

    if (data.pages.length) {
      const imageObj = data.pages.find(image => image.active === 1);

      if (imageObj) {
        const imageUrl =
          imageObj.icon_128 && imageObj.icon_128.fetchUrl
            ? imageObj.icon_128.fetchUrl
            : imageObj.thumbnail.fetchUrl;

        const imageResponse2 = yield call(doRequest, {
          url: `unimup/objctupload${imageUrl}`,
          method: 'GET',
          responseType: 'blob'
        });
        if (imageResponse2) {
          const { data: imageData } = imageResponse2;
          const image = URL.createObjectURL(imageData);
          return { id, image };
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* getProductsImage(dataList = []) {
  const chunkedList = yield call(chunk, dataList, 5);

  for (let list of chunkedList) {
    const requests = list.map(item => call(getProductImage, item.id, item.mediaCollectionId));
    const responses = yield all(requests);

    const indexes = yield call(getIndexedProductIds);
    const filteredResponse = responses.filter(item => (item ? true : false));
    yield put(setProductImage({ image: filteredResponse, indexes }));
  }
}

function* getProductUserImage(id, userId) {
  try {
    if (userId) {
      const imageResponse = yield call(doRequest, {
        url: `resource/users/profilePic/${userId}`,
        method: 'GET'
      });

      const {
        data: { image }
      } = imageResponse;

      return { id, image };
    }
  } catch (error) {
    console.error(error);
  }
}

function* getProductsUserImage(dataList = []) {
  const chunkedList = yield call(chunk, dataList, 5);

  for (let list of chunkedList) {
    const requests = list.map(item => call(getProductUserImage, item.id, item.user.id));
    const responses = yield all(requests);

    const indexes = yield call(getIndexedProductIds);
    const filteredResponse = responses.filter(item => (item ? true : false));
    yield put(setProductUserImage({ image: filteredResponse, indexes }));
  }
}

function* getSearchResults(tags = []) {
  try {
    const modifiedTags = yield call(filterCountryEmojis, tags);
    const requestBody = yield call(transformOptionValuesToPostData, modifiedTags);
    const locale = yield select(localeSelector);

    requestBody.locale = locale;

    const response = yield call(doRequest, {
      method: 'POST',
      url: `/prodres/searchbox/bykeys/`,
      data: requestBody
    });

    const productIds = response.data;

    yield put(setSearchProductIds({ ids: productIds }));

    const { sort, pagination } = yield select(state => state.catalogue.products);

    // reset offset
    pagination.current = 0;

    yield put(fetchRows({ config: { pagination: pagination, sort: sort } }));
  } catch (error) {
    console.error(error);
    let intl = yield select(state => state.intlReact.intl);
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Catalogue.Filter.GetError'
      })
    );
  }
}

function* getSearchSuggestionsSaga({ payload }) {
  const { searchValue } = payload;

  if (!searchValue) {
    // clear the current suggestion list
    yield put(getSearchSuggestionSuccess({ searchResults: [] }));
    return;
  }

  try {
    const locale = yield select(localeSelector);
    const response = yield call(doRequest, {
      url: `prodres/searchbox/phrase/${locale}/${encodeURIComponent(searchValue)}`,
      method: 'GET'
    });

    const countries = yield select(s => s.indexedItem.countries);

    const dataList = yield call(transformOptionValues, response.data, countries);

    yield put(getSearchSuggestionSuccess({ searchResults: dataList }));
  } catch (error) {
    console.error(error);
    let intl = yield select(state => state.intlReact.intl);
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Catalogue.Filter.GetError'
      })
    );
  }
}

function* addTagSaga({ payload }) {
  const { tag, group } = payload;

  let intl = yield select(state => state.intlReact.intl);
  const currentTags = yield select(s => s.catalogue.products.tags);
  const isTagAlreadyInserted = currentTags.find(t => t.value === tag);

  if (isTagAlreadyInserted) {
    yield put(getSearchSuggestionSuccess({ searchResults: [] }));

    return message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Catalogue.Products.FilterAlreadyAdd'
      })
    );
  }

  const newTags = [...currentTags, { value: tag, group }];

  yield put(addTagsSuccess({ tags: newTags }));
  yield put(getSearchSuggestionSuccess({ searchResults: [] }));
  yield call(getSearchResults, newTags);
}

function* deleteTagSaga({ payload }) {
  const { tag } = payload;
  const currentTags = yield select(s => s.catalogue.products.tags);
  const newTags = currentTags.filter(t => t !== tag);

  yield put(deleteTagSuccess({ tags: newTags }));

  if (newTags.length === 0) {
    yield put(setSearchProductIds({ ids: [] }));
    const { sort, pagination } = yield select(state => state.catalogue.products);
    // reset offset
    pagination.current = 0;
    yield put(fetchRows({ config: { pagination: pagination, sort: sort } }));
  } else {
    yield call(getSearchResults, newTags);
  }
}

/**
 * Watchers
 */
function* fetchRowsSagaWatcher() {
  yield takeLatest(productTypes.FETCH_ROWS, fetchRowsSaga);
}

function* deleteProductSagaWatcher() {
  yield takeLatest(productTypes.DELETE_PRODUCT, deleteProductSaga);
}

function* getSearchSuggestionsSagaWatcher() {
  yield takeLatest(productTypes.GET_SEARCH_SUGGESTION, getSearchSuggestionsSaga);
}

function* addTagSagaWatcher() {
  yield takeEvery(productTypes.ADD_TAG, addTagSaga);
}

function* deleteTagSagaWatcher() {
  yield takeEvery(productTypes.DELETE_TAG, deleteTagSaga);
}

export default function* rootSaga() {
  yield all([
    fork(fetchRowsSagaWatcher),
    fork(deleteProductSagaWatcher),
    fork(getSearchSuggestionsSagaWatcher),
    fork(addTagSagaWatcher),
    fork(deleteTagSagaWatcher)
  ]);
}
