import { createTypes, async } from 'redux-action-creator';

const types = createTypes(
  [
    ...async('GET_DATA_LIST'),
    'GET_DATA_LIST_FAIL',
    ...async('DELETE'),
    'SET_MANUFACTURER_IMG',
    'SET_SEARCH_LOADER',
    'GET_SEARCH_SUGGESTION',
    'GET_SEARCH_SUGGESTION_SUCCESS',
    'ADD_TAG',
    'ADD_TAG_SUCCESS',
    'DELETE_TAG',
    'DELETE_TAG_SUCCESS',
    'SET_ID_LIST',
    'RESET'
  ],
  'MANUFACTURER'
);

export default types;
