import { takeLatest, all, fork, put, select, call, take } from 'redux-saga/effects';
import { distributorTypes } from './distributorModal.constants';
import {
  editDistributorSuccess,
  saveDistributorDetailsSuccess,
  updateDistributorModal,
  showDistributorLoader,
  hideDistributorLoader,
  saveDistributorImageSuccess,
  removeDistributorImageSuccess,
  getDistributorListSuccess,
  setCollectionId,
  removeCollectionId,
  saveDistributorDetailsFail,
  getDistributorListWorkerSuccess
} from './distributorModal.actions';
import { doRequest } from 'app/shared/redux/sagas/api';
import { message } from 'antd';
import {
  getDataList,
  setFilterIdList,
  getSearchSuggestionSuccess,
  addTagsSucess
} from '../../../catalogue/distributor/redux/catalogue_distributor.actions';
import { getSearchResults } from './../../../catalogue/distributor/redux/catalogue_distributor.saga';

import { addJobToQueue } from './../../../../../shared/redux/actions/index';

/** Utilities::Begin */
function mapFormValueToApi({
  name,
  url,
  email,
  phone,
  contactFormUrl,
  address,
  googleAddress,
  country,
  description
}) {
  const apiValues = {
    distributors_name: name,
    distributors_url: url,
    email: email,
    phone: phone,
    contact_form_url: contactFormUrl,
    address: address,
    google_address: googleAddress,
    country: country ? Number(country) : country,
    image: '',
    description
  };

  for (const key of Object.keys(apiValues)) {
    if (typeof apiValues[key] === 'undefined') {
      apiValues[key] = '';
    }
  }

  return apiValues;
}

function transformItem({
  distributors_name,
  distributors_url,
  email,
  phone,
  contact_form_url,
  address,
  google_address,
  country,
  image,
  description
}) {
  return {
    name: distributors_name,
    url: distributors_url,
    image,
    email,
    phone,
    address,
    contactFormUrl: contact_form_url,
    googleAddress: google_address,
    country,
    description: description || ''
  };
}

function transformItemCatalog({
  address,
  contact_form_url,
  country,
  description,
  distributors_name,
  distributors_url,
  email,
  google_address,
  image,
  last_update,
  phone,
  _id
}) {
  return {
    id: _id,
    name: distributors_name,
    url: distributors_url,
    image,
    email,
    phone,
    address,
    contactFormUrl: contact_form_url,
    googleAddress: google_address,
    country,
    description: description || '',
    image_id: image,
    last_update: last_update
  };
}

function* storeCollectionId(id) {
  yield put(setCollectionId({ id }));
}

function* getCollectionId() {
  const item = yield select(s => s.productModals.distributor.collectionId);
  yield put(removeCollectionId());
  return item;
}

function* getIntlMessage(id) {
  const intl = yield select(({ intlReact: { intl } }) => intl);
  return intl.formatMessage({ id });
}

/** Utilities::End */

function* getDistributorList() {
  try {
    const response = yield call(doRequest, {
      url: '/prodmanu/manufacture/DIST',
      method: 'GET'
    });

    // let response = {
    //   data: {
    //     total_rows: 2858,
    //     offset: 410,
    //     rows: [
    //       {
    //         id: '2abc06205ba21279597563e9bd00124b',
    //         key: ['DIST'],
    //         value: null,
    //         doc: {
    //           _id: '2abc06205ba21279597563e9bd00124b',
    //           _rev: '1-9d682465efeae05d4d7e03f0ea63fafb',
    //           distributors_name: 'ABCD',
    //           distributors_url: '',
    //           email: '',
    //           phone: '',
    //           contact_form_url: '',
    //           address: '',
    //           google_address: '',
    //           country: '',
    //           image: '',
    //           description: '',
    //           type: 'DIST',
    //           country_name: '',
    //           created_date: 1571206150607,
    //           last_update: ''
    //         }
    //       },
    //       {
    //         id: '2abc06205ba21279597563e9bd0016fc',
    //         key: ['DIST'],
    //         value: null,
    //         doc: {
    //           _id: '2abc06205ba21279597563e9bd0016fc',
    //           _rev: '1-ff68124bf780af568f18a5f66d2f821c',
    //           distributors_name: 'jlhksjbsxk 63599 ',
    //           distributors_url: 'https://app.slcjqunqsv.io/project/5b03bd7dc9d434cf563599a',
    //           email: 'mikpvohynw.aexaj@yahoo.com',
    //           phone: '6359957863599',
    //           contact_form_url: 'http://vg.fe.react.wpthrenmqqbfenphjnav.vegofy.com',
    //           address:
    //             'cwcyehadmjltiamltuevmmzjmuethoxljkxxalhocjksiysuncxbwagxkzkzzihnxoqcwcukcxgpnezviwxvokqshnchwpmwhbvouxfngynxydklpyyiyxpgqjoirfsvmtmkwloxtaogihlqfuqyfb road, Wellawatta',
    //           google_address:
    //             'rrtnrzgkzfuasmjvkuygpjurarukxkqddgtelubhniwegvjahkvvwdgurmuuhpeugfyjbddznmgnrsniubutsupgucyukjwjuqrchkhaxhvxitulckqapqsdhzuumljyydjuwkunlaanyhvtdutmojcrfqvogmqvspsugnowpluxcgfmfbgtxkwwkitkshwnktcvyfklaytajnzmhmhtthqtzgeewrmaktwxbofchxfgnfhvlegdnltglljqjfwbpjyscpxpgjjsbtypmbijmlyfsofpurjjabraixovyfsnubmoswtybfujlwuiqitygsdzpnbeoikkwntcxszncnbyiqfzhimvgfyflzewbuwilepfhxgmowcmcqrtugmduuxtdasixkknupovsthwsopnkqtzcoprjrbilglwfmikumbnoarzrzyqpajszcnenbsyezgeunupcvsovhzrfliarwslarosjhplbchoalcugdjlflyn road, Wellawatta',
    //           country: 222,
    //           image: '',
    //           description:
    //             'Test distributers epysigfhbufqocvxmrbwxhdcpsbjpqspwnlathesetopdjgikwtpttdwlcafkhvxrdttokaeqtmqecfyuyoucogrubfrgvkvuijxdgyslgtofautrcpkssgephpaqiztfmfhntrymxrtpbubokyashatbhgdddxgqinqssptuonqzcfepmsashpcymhxwrarozdqffzcxlwjpjtmjtnkxsllyirlrajushgugzvsuhuaehdkrnbfnohjjjdezarrakgftwwefmcdofbimypqpfplbeebscswumugiagzuxdbnepywlwopthbhkihkdysqibgfimwbxsycchloqwjxqfmtiexhxywukewafynccdkmqlfisowoecpjxyzmisnlxcpdbwsvztbhptkidnmibqxlhhunobisdamwfykswkmvfhvtyyjyvwvptpblzprgi',
    //           type: 'DIST',
    //           country_name: 'Swaziland',
    //           created_date: 1571207727559,
    //           last_update: ''
    //         }
    //       },

    //       {
    //         id: '671944c5e4a0afbbef0424217f2831c4',
    //         key: ['DIST'],
    //         value: null,
    //         doc: {
    //           _id: '671944c5e4a0afbbef0424217f2831c4',
    //           _rev: '1-87ee482645ba518926fa103403d941d9',
    //           distributors_name: 'uaaewhaezd 30254 ',
    //           distributors_url: 'https://app.axdxklnoxa.io/project/5b03bd7dc9d434cf530254a',
    //           email: 'jhabpftsdw.ajdzi@yahoo.com',
    //           phone: '3025457830254',
    //           contact_form_url: 'http://vg.fe.react.pgnmnryscsdavreygziy.vegofy.com',
    //           address:
    //             'cpwajwolouvorchcwjojikfxpidcumzbhkeklcvirnwmwxnfyckszbeiisnqknusdwzgulevebqlmilgaskssrnvszenjbsktdlzmhvztogsgclcxewikmhgnqrskaeazkslzpgekkoiuavtbcjqjr road, Wellawatta',
    //           google_address:
    //             'talhcuxryzucmujcjcmgmqfntvpwzirzubaxvczulvtvlxupzhwqmylgpnykyoxtibzebniuhaxktqtmipofbixtdylppnckbsrvhbpospojqkcciinrckkgyourlzhnaodtcjibrblvgkmxehngjtmyorfiwkozebzuqijsmdkamyoyqgieldhbcmrqwpyvkykiucqheqaodyfsojirlvinzsuuksgspmvzkdwvfvrvexavxggkzvyyxaewqjicmrmmeeuqtlgqjwwvvsfjoldqwbetzrbgxtrwcpwidkxukajftzmojgfyxlzetdghgmqhawcrfunazwiydkqgnhexnvfxnmsonvdndiotkejgqeokkjqhmipdrygfbshkxmespcabnjivgprtzpxcoppswpcsftoqcyzedktzdgggmhteifkfntccqdhghnomcuyglzyubcpxjoyfqmoiviffglrbhkfspmovgbrfyxwlglxhveyt road, Wellawatta',
    //           country: 183,
    //           image: '',
    //           description:
    //             'Test distributers mzkmhjzarslmkzkvsyjhjnqagusjdngmbdirwdifnndccsprotcrquurvewzioomycntqzgactbrmyzubnamfqrpipediwdxflbdvlfgforywdmouzewmksjilafakpnapxbskuqrqjkiofqcihclgerzmdwjpwijycdfccmexpjzcjuqqynqockwakdzmdvqoebyqbcihzwbsmljmigbvrmeorgapmbqyoorqwsqaaqbyupeajrlvvbmkuxgpsddpqnxueddbqgsydtymunkmdmpxlnzyxeynxrxyvufidvpheqstmycnxiwjqlgetmbqxblsstvoihjghdbznnkjwaelppaujfanmwubuheiikdblapsbehlqorzsspnrvtqjmoxvzpolluevhgakwtlwosraqnwbwpapbviqceeetdmuidjgwhrdeccfrpdfcan',
    //           type: 'DIST',
    //           country_name: 'Paraguay',
    //           created_date: 1571317001958,
    //           last_update: ''
    //         }
    //       },
    //       {
    //         id: '6ed3d427045228dce9e3b38d1162f1e0',
    //         key: ['DIST'],
    //         value: null,
    //         doc: {
    //           _id: '6ed3d427045228dce9e3b38d1162f1e0',
    //           _rev: '3-807f1dd122a21a997cca297f16f9620b',
    //           distributors_name: 'qzoqamwwts 59099 ',
    //           distributors_url:
    //             'https://app.zbguhnyosekobbpyygztmtgiashpddoeftuqcezgyumbmlpxruwfieexkrmseqmpimkcpsxwplqhkgyqpqmpgaclsydxzhceqncf.io/project/5b03bd7dc9d434cf559099a',
    //           email: 'oxajrjtlybnrpuwzxfau.vlnoiajevuvpdwt@yahoo.com',
    //           phone: '5909957859099',
    //           contact_form_url: 'http://vg.fe.react.flukvxawdqvpopttyujz.vegofy.com',
    //           address:
    //             'slawphtaqczovzzdzyzispxknglnprlbhvbxuhbcvnkrmjdzktgcvausnxccekfgskgxkmimnexbvrufwosgpzycmznahkzfxjuzgrqxoxrsnmalybzkucusffjjxrjngjoylgaevgvtzxcaocikqv road, Wellawatta',
    //           google_address:
    //             'iiscmgwcflrpiykcqzwjmiqgnqrgairnwmpljmlsurewrfzzelvmwggqatsqdwwjbczvviwusxrweyomvqcjkmvhkrzmjdutxjevxidsvkvnsgtlibwzacawyapgsgntagshuwklrrpmhyftwjpdfzzdcbndsoynyxopoxfnsckefgsmvkafddmfiysasaixzopqfqxbvknmvmrefipvgbmlsiqxghkasyuwwttkgrpzazygoqmynlvlopetynkrcmopvclsjwypcgmzkbumvwxptzrxaqbmcwuaaeperwipxjqpivwhgbdchkvkkhlxjnobxnsdibmqkvpgtilpbnsylcpplwezeelpsurfrzqjdxncetcrbzwecqeevcflcmytviseeyeqbfekhsqrgqlqqcunstzftwcnrddpcphmwmwextpidjyctxclcejowgdnfqxuatdgckodirpybzpostxaicezojhzmelamauvgrsuxloe road, Wellawatta',
    //           country: 233,
    //           image: '288c11e1-6b06-4091-8e07-19d387773f1c',
    //           description:
    //             'Test distributers favfmucxatsnlkxfsifvgtizgtwglixbxncrsusbnetbketyxxfxuvebjmbabscgadnowirlgthebeknkenoicdnrmfmbnxzhyldikioydemekqevyrqhwohwbyvsihyzxzohjgodbcjilnijazbzqydieymydwbvtnjxrmsjhnhvyylxsvxhhuvqcfuiublplawjqsuhsqmbcedbvkhznbexrvguronlyygwdsuuywxmuymcapqsnyltienxllhfecyefrfiuyqlplsgredukkukqkgrolqfxmohruuzkooveczewwurcezjioahrutyiqmggvwmypsozlxtpdvcqiopvwcrxeybsvbodiiizjkxlgfeqvxdpafjlchhumrlwtzimzijltndpwxumetwdtbriitrkvrmsybfxamcztpzadiipmbwvnksabsdymqhd',
    //           type: 'DIST',
    //           country_name: 'Trinidad and Tobago',
    //           created_date: 1570703668941,
    //           last_update: '1570704018694'
    //         }
    //       },
    //       {
    //         id: '6ed3d427045228dce9e3b38d11630678',
    //         key: ['DIST'],
    //         value: null,
    //         doc: {
    //           _id: '6ed3d427045228dce9e3b38d11630678',
    //           _rev: '6-aec09cfa06dd9cde24861987271eebbc',
    //           distributors_name: 'gmlaidwwhy 55531 ',
    //           distributors_url:
    //             'https://app.utkqvmfqphuzeuyishisuwtlqpbrydvchhlxjfooymugwxzvzboezgnxqolzesvbvhmqicsmmmeldglhzauhsatgugutcscwwnfe.io/project/5b03bd7dc9d434cf555531a',
    //           email: 'zmfykbigtdnftkkzdntq.eembicictrzifks@yahoo.com',
    //           phone: '5553157855531',
    //           contact_form_url: 'http://vg.fe.react.uzduburmkjiddujfkuib.vegofy.com',
    //           address:
    //             'zswdjohwnkqrhkbynkgofyojtderruzvwyxkjrwszlofnkmgsvfjfrbcwsvqnqrwnnjzqvampasxwzfodezbwgpcktugylejxufankukzlefrjhukuvmzqhmuovzqzstnufgtzjbfoounfqaskiewh road, Wellawatta',
    //           google_address:
    //             'vxacqsplfufdeuxdbvqlmrtvifgngpyfsjbnkgxkdofpbhgzumifvxadzxdgdcxuxjpfmgssrsyvlbkldanpngnshcgagqnfasvpeyyurhgqhlnigcjdfvjysobdganodpocykcgwoxlnvivxsabutnmqanweztxttmboopnongmwlljzztkpeipwjaistemafknphwdykbebipfeahjhcytyakwbvwlpitoejyhnkydqfunvxoejzggkzkkacmfwawshbmyorsqttvuqzmdnnwkdgpaetnnbnulqzjvduotrvmpqazujulmtpibsyaqjuyaydlqpstckqolwxmdnymlqabhwvhhozsjbthbluoffbzdqfamvmbgbvshyjdetdwcusjrwrpkcftmthwhotvzlaxjmcgzjrgnvbffboofgjxbvfnytgeuunmkftjloxqdivppkurtrwwefefrosgihezwmxckcetoyftelqpvksfazpbq road, Wellawatta',
    //           country: 97,
    //           image: '92c3a544-9631-4ffd-a911-4f36efa72a7d',
    //           description:
    //             'Test distributers vmdstsgzcnadlefkkggnmzhqkvjopjfvpugtdafuafdqhhigfrdhtwznionzohjezinnjrifounsnpkarizdxvdktlqpxzphuohqciaawdljwxqzkjlmtlvoxawoucdaqpjevihdcgobqlbvilxkyyodynhxtwhlyplcbfwcuzztwgufqgqwapeadkdjzrhxeiyvpjqqancdhcfwuskfxcnbhuuucswfyzrmwecbzyxfolkjwcyrhxtdmzkugyvolytjlbccldigpvzhplxhuviarjahibmvybksprehmhxdzkvwobftvrgniemruqdkmorleqreurrrbciipmlbexjnhhgpjhgddccrgtbbdzhuunvbnqvbcobhtxebzgvihxhloeqsyquucilbzqmicgrmjmiwnsehswzhwizbcclseyavjeiiyxkpslqpvtrqmy',
    //           type: 'DIST',
    //           country_name: 'Gibraltar',
    //           created_date: 1570703669134,
    //           last_update: '1571130477058'
    //         }
    //       }
    //     ]
    //   }
    // };

    const { rows } = response.data;
    // let filtertedData = rows || [];

    const workerParamsGetDistribute = {
      dataArray: rows
      // newArray: []
      // passedLocal: selectLocal,
      // defaultLocal: defaulLocal
    };

    yield put(
      addJobToQueue({
        workerAction: 'MANUFACTURE_LIST',
        data: workerParamsGetDistribute,
        actionToDispatch: getDistributorListWorkerSuccess
      })
    );

    let payloadResponse = yield take('DISTRIBUTOR_GET_DISTRIBUTOR_LIST_WORKER_SUCCESS');
    // filtertedData = rows.map(item => item.doc);

    yield put(getDistributorListSuccess({ data: payloadResponse.payload.data.result }));
  } catch (error) {
    console.log(error);
    message.error(yield getIntlMessage('Generic.ApiMessages.Products.Distributor.GetListError'));
  }
}

function* createDistributor(formData) {
  if (!formData.country) {
    formData.country = '';
  }

  const response = yield call(doRequest, {
    url: '/prodmanu/manufacture/DIST',
    method: 'POST',
    data: formData
  });

  return response.data.id;
}

function* updateDistributorDetail(id, formData) {
  if (!formData.country) {
    formData.country = '';
  }

  yield call(doRequest, {
    url: `/prodmanu/manufacture/DIST/${id}`,
    method: 'PUT',
    data: formData
  });
}

function* saveDistributorDetails({ payload: { cb, form, popupFrom } }) {
  yield put(showDistributorLoader());
  let [id, list] = yield select(s => [
    s.productModals.distributor.id,
    s.productModals.distributor.list
  ]);

  const formData = yield call(mapFormValueToApi, form);

  if (popupFrom) {
    const item = yield select(s => s.productModals.distributor.editForm);

    if (item && item.collectionId) {
      formData.image = item.collectionId;
    }
  } else {
    // if an item exists, we set its image url
    const item = list.find(item => item._id === id);
    if (item) {
      formData.image = item.image;
    }
  }

  // Add Image URL before saving
  const collectionId = yield call(getCollectionId);

  if (collectionId) {
    formData.image = collectionId;
  }

  try {
    if (id) {
      yield call(updateDistributorDetail, id, formData);
      message.info(yield getIntlMessage('Generic.ApiMessages.Products.Distributor.DetailUpdated'));

      if (popupFrom) {
        const { pagination, sort } = yield select(
          ({
            catalogue: {
              distributor: { pagination, sort }
            }
          }) => ({ pagination, sort })
        );

        const tags = yield select(s => s.catalogue.distributor.tags);
        if (tags.length) {
          yield fork(getSearchResults, tags);
        } else {
          yield put(getDataList({ pagination, sort }));
        }
      }
    } else {
      id = yield call(createDistributor, formData);
      message.info(yield getIntlMessage('Generic.ApiMessages.Products.Distributor.CreateSuccess'));
      if (popupFrom) {
        yield put(addTagsSucess({ tags: [] }));
        yield put(getSearchSuggestionSuccess({ searchResults: [] }));
        yield put(setFilterIdList({ idList: [] }));

        yield put(
          getDataList({
            pagination: {
              total: 0,
              pageSize: 50,
              current: 1
            },
            sort: { order: 'desc', columnKey: 'create' }
          })
        );
      }
    }

    yield fork(cb);

    yield put(saveDistributorDetailsSuccess({ data: formData, id }));
    yield put(updateDistributorModal({ state: 'close' }));
  } catch (error) {
    console.log(error);
    if (error.response.status === 400) {
      message.error(yield getIntlMessage('Generic.ApiMessages.Products.Distributor.InvalidData'));
    } else {
      message.error(yield getIntlMessage('Generic.ApiMessages.Products.Distributor.SavingError'));
    }
  } finally {
    yield put(hideDistributorLoader());
  }
}

function* editFromProductPage(id) {
  const list = yield select(s => s.productModals.distributor.list);
  const item = list.find(i => i._id === id);
  const transformedItem = yield call(transformItem, item);
  let image = '';

  if (item.image) {
    image = yield call(fetchBrandImage, item.image);
  }

  return [transformedItem, image];
}

function* editFromCatalogue(id) {
  const response = yield call(doRequest, {
    url: `prodmanu/manufacture/node/${id}`,
    method: 'GET'
  });

  const { data } = response;

  const distributorData = yield call(transformItem, data);

  distributorData.collectionId = data.image;

  let image = '';

  if (data.image) {
    image = yield call(fetchBrandImage, data.image);
  }

  return [distributorData, image];
}

function* fetchBrandImage(collectionId) {
  const imageUrlResponse = yield call(doRequest, {
    url: `unimup/objctupload/manuf/${collectionId}`,
    method: 'GET'
  });
  const { data } = imageUrlResponse;

  if (data.pages.length) {
    const imageUrl = data.pages[0].optimized.fetchUrl;
    const imageResponse = yield call(doRequest, {
      url: `unimup/objctupload${imageUrl}`,
      method: 'GET',
      responseType: 'blob'
    });

    const { data: imageData } = imageResponse;
    const image = URL.createObjectURL(imageData);

    return image;
  }
}

function* editDistributorDetails({ payload: { id, popupFrom } }) {
  yield put(updateDistributorModal({ state: 'open' }));
  yield put(showDistributorLoader());

  try {
    let transformedItem = {};
    let image = '';

    if (popupFrom && popupFrom === 'dist_catalog') {
      [transformedItem, image] = yield call(editFromCatalogue, id);
    } else {
      [transformedItem, image] = yield call(editFromProductPage, id);
    }
    yield put(editDistributorSuccess({ data: transformedItem, id, image }));
  } catch (error) {
    yield put(updateDistributorModal({ state: 'close' }));
    console.log({ error });
    message.error(yield getIntlMessage('Generic.ApiMessages.Products.Distributor.GetDetailsError'));
  }
  yield put(hideDistributorLoader());
}

function* saveImage({ payload: { popupFrom } }) {
  yield put(showDistributorLoader());

  let updatedItem = null;

  try {
    const [image, file, id, list, editForm] = yield select(s => [
      s.productModals.distributor.image,
      s.productModals.distributor.imageBinary,
      s.productModals.distributor.id,
      s.productModals.distributor.list,
      s.productModals.distributor.editForm
    ]);

    const item = list.find(item => item._id === id);
    const collectionId = yield call(getCollectionId);

    let imageId = '';

    if (!popupFrom && item && item.image) {
      imageId = item.image;
    } else if (popupFrom && editForm && editForm.collectionId) {
      imageId = editForm.collectionId;
    } else if (collectionId) {
      imageId = collectionId;
    }

    const formData = new FormData();
    formData.append('media', file);

    const response = yield call(doRequest, {
      url: '/unimup/objctupload/manuf/',
      method: 'POST',
      data: formData
    });

    if (response.status === 200) {
      const {
        data: { CollectionId }
      } = response;

      /**
       * if the item is previsously created
       * */

      if (id) {
        /**
         * if open from catalogue then
         * update the editform value
         */
        if (popupFrom) {
          editForm.image = CollectionId;
          editForm.collectionId = CollectionId;
          updatedItem = editForm;

          const apiFormData = yield call(mapFormValueToApi, editForm);
          apiFormData.image = CollectionId;

          yield call(updateDistributorDetail, id, apiFormData);

          const { pagination, sort } = yield select(
            ({
              catalogue: {
                distributor: { pagination, sort }
              }
            }) => ({ pagination, sort })
          );
          yield put(getDataList({ pagination, sort }));
        } else {
          item.image = CollectionId;
          updatedItem = item;
          yield call(updateDistributorDetail, id, item);
        }
      } else {
        yield call(storeCollectionId, CollectionId);
      }
    }
    // if a previous image is uploaded
    // delete it and then upload new one
    if (imageId) {
      yield call(doRequest, {
        url: `unimup/objctupload/manuf/${imageId}`,
        method: 'DELETE'
      });
    }
    yield put(saveDistributorImageSuccess({ img: image, item: updatedItem }));
    message.info(
      yield getIntlMessage('Generic.ApiMessages.Products.Distributor.ImageUploadSuccess')
    );
  } catch (error) {
    console.log({ error });
    message.error(
      yield getIntlMessage('Generic.ApiMessages.Products.Distributor.ImageUploadError')
    );
    yield put(saveDistributorDetailsFail());
  } finally {
    yield put(hideDistributorLoader());
  }
}

function* deleteImage({ payload: { popupFrom } }) {
  try {
    const [list, id, editForm] = yield select(s => [
      s.productModals.distributor.list,
      s.productModals.distributor.id,
      s.productModals.distributor.editForm
    ]);

    const item = list.find(i => i._id === id);
    const collectionId = yield call(getCollectionId);

    let imageId = '';

    if (!popupFrom && item && item.image) {
      imageId = item.image;
    } else if (popupFrom && editForm && editForm.collectionId) {
      imageId = editForm.collectionId;
    } else if (collectionId) {
      imageId = collectionId;
    }

    if (item && item.image) {
      imageId = item.image;
    } else if (collectionId) {
      imageId = collectionId;
    }

    if (imageId) {
      yield call(doRequest, {
        url: `unimup/objctupload/manuf/${imageId}`,
        method: 'DELETE'
      });

      if (item) {
        item.image = '';
        yield call(updateDistributorDetail, id, item);
      } else if (popupFrom && editForm) {
        editForm.image = '';
        const apiFormData = yield call(mapFormValueToApi, editForm);
        yield call(updateDistributorDetail, id, apiFormData);
      }

      yield put(removeDistributorImageSuccess({ popupFrom }));
      message.info(
        yield getIntlMessage('Generic.ApiMessages.Products.Distributor.ImageDeleteSuccess')
      );
      if (popupFrom) {
        const { pagination, sort } = yield select(
          ({
            catalogue: {
              distributor: { pagination, sort }
            }
          }) => ({ pagination, sort })
        );
        yield put(getDataList({ pagination, sort }));
      }
    }
  } catch (error) {
    console.log({ error });
    message.error(
      yield getIntlMessage('Generic.ApiMessages.Products.Distributor.ImageDeleteError')
    );
  }
}

function* openCatalogueModalSaga() {
  yield put(updateDistributorModal({ state: 'open' }));
}
/** Watchers */

function* saveDistributorDetailsWatcher() {
  yield takeLatest(distributorTypes.SAVE_DETAILS, saveDistributorDetails);
}

function* editDistributorDetailsWatcher() {
  yield takeLatest(distributorTypes.EDIT, editDistributorDetails);
}

function* saveImageWatcher() {
  yield takeLatest(distributorTypes.SAVE_IMG, saveImage);
}

function* deleteImageWatcher() {
  yield takeLatest(distributorTypes.REMOVE_IMG, deleteImage);
}

function* getDistributorListWatcher() {
  yield takeLatest(distributorTypes.GET_LIST, getDistributorList);
}

function* openCatalogueModalSagaWatcher() {
  yield takeLatest(distributorTypes.OPEN_CATALOGUE, openCatalogueModalSaga);
}

export default function* rootSaga() {
  yield all([
    fork(getDistributorListWatcher),
    fork(saveDistributorDetailsWatcher),
    fork(editDistributorDetailsWatcher),
    fork(saveImageWatcher),
    fork(deleteImageWatcher),
    fork(openCatalogueModalSagaWatcher)
  ]);
}
