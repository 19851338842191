import types from './product.constants';
import setdefaultDataSort from '../../../../shared/utils/setdefaultDataSort';

const initialState = {
  formData: null,
  productData: null,
  productList: [],
  nutritionalValueList: {
    comparison: [],
    energy: [],
    nutritionalValue: [],
    vitamins: [],
    minerals: [],
    other: [],
    commonSupplements: []
  },
  mayContainList: [],
  isProductDetailsLoaded: false,
  storesArray: [],
  tagsArray: [],
  storesTypeArray: [],
  categoryArray: [],
  store: '',
  ingredientsArray: [],
  eNumbersArray: [],
  isSaveModalOpen: false,
  ingredientLoader: true,
  categoryLoader: true,
  nutritionLoaded: true,
  saveButtonClick: false,
  productLocale: '',
  mayContainLoader: true,
  labelsList: [],
  filterdLabelList: [],
  labelsLoader: true,
  initialUnit: '',
  tagListLocal: 'en_US',
  priceData: null,
  currancyValue: '',
  bracketText: null,
  productId: null,
  disabledPriceFeild: true,
  productAddPageIsMount: false,
  setData: true,
  storeLoader: true,
  tagListLoader: true,
  clearBracketText: true,
  productUrl: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_STORE_LIST: {
      return {
        ...state,
        storeLoader: true
      };
    }

    case types.GET_TAG_LIST: {
      return {
        ...state,
        tagListLoader: true
      };
    }

    case types.TAG_LIST_LOADER: {
      return {
        ...state,
        tagListLoader: false
      };
    }

    case types.STORE_DATA_ENABLED: {
      return {
        ...state,
        storeLoader: false
      };
    }

    case types.GET_CATEGORY_LIST: {
      const { data } = action.payload;
      return {
        ...state,
        categoryLoader: true,
        tagListLocal: data.locale
      };
    }

    case types.GET_CATEGORY_LIST_SUCCESS: {
      const { data } = action.payload;
      let passedArray = data;
      let sortedArary = setdefaultDataSort(passedArray, 'title');
      return {
        ...state,
        categoryArray: sortedArary
      };
    }

    case types.UPDATE_CATEGORY_LOADER: {
      return {
        ...state,
        categoryLoader: false,
        saveButtonClick: false
      };
    }

    case types.GET_INGREDIENT_LIST_SUCCESS: {
      const { data } = action.payload;

      let passedArray = data;
      let sortedArary = setdefaultDataSort(passedArray, 'title');
      return {
        ...state,
        ingredientsArray: sortedArary
      };
    }

    case types.GET_STORE_LIST_SUCCESS: {
      // const { data } = action.payload;

      // let passedArray = data;

      // let sortedArary = setdefaultDataSort(passedArray, 'branch');
      // let newSortArray = setdefaultDataSort(sortedArary, 'title');

      // newSortArray.splice(0, 0, {
      //   id: '0000',
      //   title: 'None',
      //   branch: '',
      //   mediaCollectionId: '',
      //   imageUrl: ''
      // });

      return {
        ...state
        // storesArray: newSortArray
      };
    }

    case types.GET_TAG_LIST_SUCCESS: {
      const { data, locale } = action.payload;
      let passedArray = data;
      let sortedArary = setdefaultDataSort(passedArray, 'title');

      return {
        ...state,
        tagsArray: sortedArary,
        tagListLocal: locale
      };
    }

    case types.NEW_ADDED_TAG_IMAGE_GET: {
      const { id, image } = action.payload;

      let data = JSON.parse(JSON.stringify(state.tagsArray));
      const indexes = data.findIndex(item => item._id === id);

      if (image && image.length !== 0) {
        if (indexes !== -1) {
          const item = data[indexes];

          const clonedItem = JSON.parse(JSON.stringify(item));

          if (clonedItem && clonedItem._id) {
            clonedItem['imageUrl'] = image;
            data = [...data.slice(0, indexes), clonedItem, ...data.slice(indexes + 1)];
          }
        }
      }
      let passedArray = [...data];
      let sortedArary = setdefaultDataSort(passedArray, 'title');
      return {
        ...state,
        tagsArray: sortedArary
      };
    }

    case types.REMOVE_TAG_IMAGE_FROM_PROD: {
      const { id } = action.payload;

      let data = JSON.parse(JSON.stringify(state.tagsArray));
      const indexes = data.findIndex(item => item._id === id);

      if (indexes !== -1) {
        const item = data[indexes];

        const clonedItem = JSON.parse(JSON.stringify(item));

        if (clonedItem && clonedItem._id) {
          clonedItem['imageUrl'] = '';
          data = [...data.slice(0, indexes), clonedItem, ...data.slice(indexes + 1)];
        }
      }

      let passedArray = [...data];
      let sortedArary = setdefaultDataSort(passedArray, 'title');

      return {
        ...state,
        tagsArray: sortedArary
      };
    }

    case types.EDIT_TAG_SUCCESS: {
      const { data, id } = action.payload;

      let tagsArray = JSON.parse(JSON.stringify(state.tagsArray));

      const itemIndex = tagsArray.findIndex(item => item._id === id);

      if (itemIndex !== -1) {
        const item = tagsArray[itemIndex];

        const clonedItem = JSON.parse(JSON.stringify(item));

        if (clonedItem && clonedItem._id) {
          clonedItem['title'] = data.title;

          tagsArray = [
            ...tagsArray.slice(0, itemIndex),
            clonedItem,
            ...tagsArray.slice(itemIndex + 1)
          ];
        }
      }

      let passedArray = [...tagsArray];
      let sortedArary = setdefaultDataSort(passedArray, 'title');
      return {
        ...state,
        tagsArray: sortedArary
      };
    }

    case types.ADD_NEW_TAG: {
      const { tagData } = action.payload;
      let { tagsArray } = state;

      let passedArray = [tagData, ...tagsArray];
      let sortedArary = setdefaultDataSort(passedArray, 'title');
      return {
        ...state,
        tagsArray: sortedArary
      };
    }

    case types.SAVE_NEW_TAG_SUCCESS: {
      return {
        ...state
      };
    }

    case types.GET_STORE_TYPE_LIST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        storesTypeArray: data
      };
    }

    case types.GET_ENUMBER_LIST_SUCCESS: {
      const { data } = action.payload;
      let passedArray = data;
      let sortedArary = setdefaultDataSort(passedArray, 'title');

      return {
        ...state,
        eNumbersArray: sortedArary
      };
    }

    case types.UPDATE_INGREDIENT_LOADER: {
      const { loader } = action.payload;

      return {
        ...state,
        ingredientLoader: loader
      };
    }

    case types.GET_PRODUCT_BY_LOCALE: {
      return {
        ...state,
        ingredientLoader: true
      };
    }

    // case types.SET_PRODUCT_STORE: {
    //   const { store } = action.payload;
    //   return {
    //     ...state
    //     // store: store ? store : ''
    //   };
    // }

    case types.GET_NUTRITION_VALUE_LIST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        nutritionalValueList: {
          ...data
        }
      };
    }

    case types.GET_MAY_CONTAIN_VALUES_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        mayContainList: data
      };
    }

    case types.MAY_CONTAINT_LOADER: {
      const { loader } = action.payload;
      return {
        ...state,
        mayContainLoader: loader
      };
    }

    case types.GET_LABEL_LIST_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        labelsList: data
      };
    }

    case types.SET_FILTER_LABEL_LIST: {
      const { data } = action.payload;

      let passedArray = data;
      let sortedArary = setdefaultDataSort(passedArray, 'title');

      return {
        ...state,
        filterdLabelList: sortedArary
      };
    }

    case types.LABEL_LIST_LOADER: {
      const { loader } = action.payload;
      return {
        ...state,
        labelsLoader: loader
      };
    }

    case types.UPDATE_NUTRITION_LOADED_STATUS: {
      const { data } = action.payload;

      return {
        ...state,
        nutritionLoaded: data
      };
    }

    case types.GET_PRODUCT_LIST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        productList: data
      };
    }

    case types.GET_PRODUCT_BY_LOCALE_SUCCESS: {
      const { data } = action.payload;

      if (data.translatable && data.translatable.nutritionValue) {
        let nutrition = data.translatable.nutritionValue;

        for (var key in nutrition) {
          if (key.includes('energy')) {
            if (nutrition[key]) {
              nutrition[key] = nutrition[key].toString();
            }
          }
        }
      }

      return {
        ...state,
        productData: data,
        clearBracketText: true
      };
    }
    case types.UPDATE_PRODUCT_DATA_INGREDIENT_ONLY: {
      const { ingarray, enumarray } = action.payload;
      let emptyObj = { ...state.productData };
      if (emptyObj.non_translate) {
        emptyObj = {
          ...emptyObj,
          non_translate: { ...emptyObj.non_translate, ingeredent: ingarray, eNumber: enumarray }
        };
      } else {
        emptyObj = {
          ...emptyObj,
          non_translate: { ingeredent: ingarray, eNumber: enumarray }
        };
      }
      //console.log(emptyObj, 'UPDATE_PRODUCT_DATA_INGREDIENT_ONLY', action.payload);
      return {
        ...state,
        // productData: { ...state.productData, ingredient: data }
        productData: { ...emptyObj }
      };
    }
    case types.GET_PRODUCT_ID: {
      const { id } = action.payload;

      return {
        ...state,
        productId: id
      };
    }

    case types.ADD_PRODUCT: {
      return {
        ...state,
        saveButtonClick: true
      };
    }

    case types.EDIT_PRODUCT: {
      return {
        ...state,
        saveButtonClick: true
      };
    }

    case types.GET_STORE_UNIC_PRODUCT_PRICE_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        priceData: data
      };
    }

    case types.CURRANCY_OBJ_UPDATE: {
      const { currancy } = action.payload;

      return {
        ...state,
        currancyValue: currancy
      };
    }

    case types.GET_BRACKET_PRICE_SUCCESS: {
      const { value, pId } = action.payload;

      return {
        ...state,
        bracketText: value,
        productUrl: pId
      };
    }
    case types.RESET_BRACKET_PRICE: {
      const { value } = action.payload;

      return {
        ...state,
        clearBracketText: value,
        bracketText: null,
        productId: null
      };
    }

    case types.DISABLED_PRICE_INPUT: {
      const { value } = action.payload;

      return {
        ...state,
        disabledPriceFeild: value
      };
    }

    case types.UPDATE_SAVE_BUTTON_STATUS: {
      return {
        ...state,
        saveButtonClick: false
      };
    }

    case types.DELETE_PRODUCT_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        productList: data
      };
    }

    case types.UPDATE_PRODUCT_DETAIL_LOAD_STATUS: {
      const { data } = action.payload;

      return {
        ...state,
        isProductDetailsLoaded: data
      };
    }

    case types.UPDATE_PRODUCT_LOCALE: {
      const { data } = action.payload;

      return {
        ...state,
        productLocale: data
      };
    }

    case types.RESET: {
      return {
        ...state,
        ingredientsArray: [],
        eNumbersArray: [],
        storesArray: [],
        labelsList: [],
        filterdLabelList: [],
        saveButtonClick: false,
        ingredientLoader: true,
        productData: null,
        productId: null,
        priceData: null,
        bracketText: null
      };
    }

    case types.GET_DEFAULT_UNIT: {
      const { unit } = action.payload;

      return {
        ...state,
        categoryArray: [],
        initialUnit: unit
      };
    }

    case types.SET_STORE_TYPE_IMAGE: {
      const {
        payload: { image, indexes }
      } = action;

      // let OriginalData = JSON.parse(JSON.stringify(state.storesTypeArray));
      let data = JSON.parse(JSON.stringify(state.storesTypeArray));

      if (image && image.length !== 0) {
        for (let distributorImage of image) {
          const itemIndex = indexes[distributorImage.id];

          if (itemIndex || itemIndex === 0) {
            const item = data[itemIndex];

            const clonedItem = JSON.parse(JSON.stringify(item));

            if (clonedItem && clonedItem._id) {
              clonedItem['imageUrl'] = distributorImage.image;
              data = [...data.slice(0, itemIndex), clonedItem, ...data.slice(itemIndex + 1)];
            }
          }
        }
      }

      return {
        ...state,
        storesTypeArray: [...data]
      };
    }

    case types.IDENTIFY_SET_FIELD_VALUE: {
      const { setData } = action.payload;
      return {
        ...state,
        setData: setData
      };
    }

    // case types.SET_STORE_IMAGE: {
    //   const { images, indexes } = action.payload;

    //   if (!images || !images.length) {
    //     return state;
    //   }

    //   let storeList = JSON.parse(JSON.stringify(state.storesArray));

    //   for (let storeImage of images) {
    //     const storeIndex = indexes[storeImage.id];

    //     if (storeIndex || storeIndex === 0) {
    //       const store = JSON.parse(JSON.stringify(storeList[storeIndex]));

    //       if (store && store.id) {
    //         store['imageUrl'] = storeImage.image;
    //         storeList = [
    //           ...storeList.slice(0, storeIndex),
    //           store,
    //           ...storeList.slice(storeIndex + 1)
    //         ];
    //       }
    //     }
    //   }

    //   return {
    //     ...state,
    //     storesArray: storeList
    //   };
    // }

    case types.SET_TAG_IMAGE: {
      const {
        payload: { image, indexes }
      } = action;

      let data = JSON.parse(JSON.stringify(state.tagsArray));

      if (image && image.length !== 0) {
        for (let distributorImage of image) {
          const itemIndex = indexes[distributorImage.id];

          if (itemIndex || itemIndex === 0) {
            const item = data[itemIndex];

            const clonedItem = JSON.parse(JSON.stringify(item));

            if (clonedItem && clonedItem._id) {
              clonedItem['imageUrl'] = distributorImage.image;
              data = [...data.slice(0, itemIndex), clonedItem, ...data.slice(itemIndex + 1)];
            }
          }
        }
      }
      let passedArray = [...data];
      let sortedArary = setdefaultDataSort(passedArray, 'title');
      return {
        ...state,
        tagsArray: sortedArary
      };
    }

    // Ingredient Popup

    case types.ADD_NEW_INGREDIENT: {
      const { data } = action.payload;

      let { ingredientsArray } = state;
      let passedArray = [data, ...ingredientsArray];
      let sortedArary = setdefaultDataSort(passedArray, 'title');
      return {
        ...state,
        ingredientsArray: sortedArary
      };
    }

    case types.EDIT_INGREDIENT: {
      const { data } = action.payload;
      let passedArray = data;
      let sortedArary = setdefaultDataSort(passedArray, 'title');
      return {
        ...state,
        ingredientsArray: sortedArary
      };
    }

    case types.CHANGE_PRODUCT_ADD_PAGE_MOUNT: {
      const { payload } = action;
      //console.log(payload);

      return {
        ...state,
        productAddPageIsMount: payload.mountState
      };
    }
    default: {
      return state;
    }
  }
};
