import {
  all,
  fork,
  put,
  takeLatest,
  takeEvery,
  select,
  call,
  race,
  take
} from 'redux-saga/effects';
import arrayMove from 'array-move';
import types from './store-information.constants';
import {
  getStoreListSuccess,
  saveNewNodeSuccess,
  updateSortOrderSuccess,
  setSelectedNodeSuccess,
  updateSaveButtonState,
  saveChangesSuccess,
  searchStoreSuccess,
  deleteNodeSuccess,
  updateSaveModal,
  saveChanges,
  addNewNodeSuccess,
  getStoreList,
  saveChangesFail,
  countrySelectedListUpdate,
  showStoreInfoLoader,
  saveStoreInfoImageSuccess,
  saveStoreInfoImageFail,
  hideStoreInfoImgLoader,
  removeStoreInfoImageSuccess,
  getStoreInfoImageSuccess
} from './store-information.actions';
import { message } from 'antd';
import { doRequest } from '../../../../shared/redux/sagas/api';
import ListTypeEnums from '../enums/list-types';
import { displaySaveDeleteBtn } from '../../../../shared/redux/actions/imageUploader.actions';

function* getIntlMessage(id) {
  const intl = yield select(({ intlReact: { intl } }) => intl);
  return intl.formatMessage({ id });
}

function* getUserLocale() {
  const [id, languageList] = yield select(s => [s.profile.language, s.languageList.languageList]);
  const language = languageList.find(language => language.id === id);
  return language.locale;
}

function* getList() {
  let intl = yield select(state => state.intlReact.intl);
  try {
    const locale = yield call(getUserLocale);

    const { data } = yield call(doRequest, {
      method: 'GET',
      url: 'storeprop/storeprop'
    });

    const stateData = yield call(buildListData, locale, data);

    if (Object.values(stateData).length === 0) {
      stateData[ListTypeEnums.STORE_TYPE] = [];
      stateData[ListTypeEnums.SOCIAL_MEDIA] = [];
      stateData[ListTypeEnums.FEATURES] = [];
      stateData[ListTypeEnums.ACCEPTED_PAYMENTS] = [];
      stateData[ListTypeEnums.MOBILE_APP] = [];
    }

    yield put(
      getStoreListSuccess({
        data: stateData
      })
    );
  } catch (error) {
    console.log({ error });
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.StoreInformation.GetStoreInformationListError'
      })
    );
  }
}

function buildListData(userLocale, data) {
  const stateData = {};
  for (let type in data) {
    const typeSpecificValues = getNodeValues(userLocale, data[type]);
    const key = getListKeyForType(type);
    if (key) stateData[key] = typeSpecificValues;
  }
  return stateData;
}

function getListKeyForType(type) {
  const mapping = {
    store_type: ListTypeEnums.STORE_TYPE,
    social_media: ListTypeEnums.SOCIAL_MEDIA,
    mobile_app: ListTypeEnums.MOBILE_APP,
    features: ListTypeEnums.FEATURES,
    accepted_payments: ListTypeEnums.ACCEPTED_PAYMENTS
  };

  return mapping[type];
}

function getTypeForKey(key) {
  const mapping = {
    [ListTypeEnums.STORE_TYPE]: 'store_type',
    [ListTypeEnums.SOCIAL_MEDIA]: 'social_media',
    [ListTypeEnums.MOBILE_APP]: 'mobile_app',
    [ListTypeEnums.FEATURES]: 'features',
    [ListTypeEnums.ACCEPTED_PAYMENTS]: 'accepted_payments'
  };

  return mapping[key];
}

function getNodeValues(userLocale, items) {
  return items.map(item => {
    const node = {};
    node.id = item.non_translate._id;
    node.position = item.non_translate.position;
    node.value = getNodeLanguageSpecificName(userLocale, item.translations);

    return node;
  });
}

function getNodeLanguageSpecificName(userLocale, languages) {
  if (languages) {
    if (languages[userLocale] && languages[userLocale]['name']) {
      return languages[userLocale]['name'];
    }

    if (languages['en_US'] && languages['en_US']['name']) {
      return languages['en_US']['name'];
    }

    for (let locale in languages) {
      if (languages[locale]['name']) {
        return languages[locale]['name'];
      }
    }
  }
}

// add new node
function* addNode({ payload: { key } }) {
  const shouldProceed = yield call(handleChangesBeforeSwitching);

  if (!shouldProceed) return;

  yield put(addNewNodeSuccess({ key }));
}

// save added node with tree view
function* saveNode(node) {
  const { key, newNode, values } = node;

  let intl = yield select(state => state.intlReact.intl);

  let arrValues = values.slice();
  arrValues.shift();

  // remove the temp node and set the values if new node value is empty
  if (!newNode) {
    return yield put(saveNewNodeSuccess({ key: key, values: arrValues }));
  }

  const [id, languageList] = yield select(s => [s.profile.language, s.languageList.languageList]);
  const language = languageList.find(language => language.id === id);
  const nodeType = yield call(getTypeForKey, key);
  let formData = {};

  if (
    key === ListTypeEnums.STORE_TYPE ||
    key === ListTypeEnums.SOCIAL_MEDIA ||
    key === ListTypeEnums.MOBILE_APP
  ) {
    formData = {
      non_translate: {
        type: nodeType,
        image: ''
      },
      translations: {
        [language.locale]: { name: newNode }
      }
    };
  } else {
    formData = {
      non_translate: {
        type: nodeType,
        image: '',
        enable_all_country: false,
        country: []
      },
      translations: {
        [language.locale]: { name: newNode }
      }
    };
  }

  try {
    const response = yield call(doRequest, {
      url: 'storeprop/storeprop',
      method: 'POST',
      data: formData
    });

    const responseData = response.data;
    const newNodeItem = {
      value: newNode,
      id: responseData['id'],
      position: responseData.position
    };

    arrValues = [newNodeItem, ...arrValues];

    yield put(saveNewNodeSuccess({ key: key, values: arrValues, newNode: newNodeItem }));
    yield call(setSelectedNode, newNodeItem.id, key);
  } catch (error) {
    message.error(intl.formatMessage({ id: 'Generic.ApiMessages.StoreInformation.NodeSaveError' }));
    //revert to original values
    return yield put(saveNewNodeSuccess({ key: key, values: arrValues }));
  }
}

// drag and drop with position update in tree view
function* updateOrder({ payload: { key, values, oldIndex, newIndex } }) {
  let intl = yield select(state => state.intlReact.intl);
  const updatedValues = arrayMove(values, oldIndex, newIndex);
  const id = values[oldIndex]['id'];
  const newPosition = values[newIndex]['position'];
  // update the values to update ui element positions immediately
  yield put(updateSortOrderSuccess({ key, values: updatedValues }));

  try {
    yield call(doRequest, {
      method: 'PUT',
      url: `storeprop/storeprop/dragndrop/${encodeURIComponent(id)}`,
      data: {
        new_pos: newPosition
      }
    });

    message.info(
      intl.formatMessage({ id: 'Generic.ApiMessages.StoreInformation.NodeOrderChangedSuccess' })
    );
    yield put(getStoreList());
  } catch (error) {
    message.error(
      intl.formatMessage({ id: 'Generic.ApiMessages.StoreInformation.NodeOrderChangedError' })
    );
    yield put(updateSortOrderSuccess({ key, values: values }));
  }
}

// get data with selected nod id
function* setSelectedNode(id, type) {
  yield put(displaySaveDeleteBtn(true));
  let intl = yield select(state => state.intlReact.intl);
  const btnState = yield select(s => s.storeInformation.saveButtonState);

  if (btnState === 'saving-changes') {
    message.warn(
      intl.formatMessage({
        id: 'Generic.ApiMessages.StoreInformation.NodeSelectedError'
      })
    );
    return;
  }

  const shouldProceed = yield call(handleChangesBeforeSwitching);

  if (!shouldProceed) return;
  yield put(showStoreInfoLoader());

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: `storeprop/storeprop/${id}`
    });

    const selectedData = response.data || {};

    const getImageId = selectedData.non_translate.image;

    yield put(setSelectedNodeSuccess({ id, type, selectedData }));
    yield put(getStoreInfoImageSuccess({ imagedata: [] }));

    if (getImageId) {
      yield call(getStoreImageRequest, getImageId);
    } else {
      yield put(hideStoreInfoImgLoader());
    }

    yield put(countrySelectedListUpdate({ keys: selectedData.non_translate.country }));
  } catch (error) {}
}

/**
 * when to handle
 *  1 - When switching nodes
 *  2 - When user creates a node while has changes
 */
function* handleChangesBeforeSwitching() {
  const btnState = yield select(s => s.storeInformation.saveButtonState);

  if (!(yield call(shouldHandle, btnState))) {
    return true;
  }

  yield put(updateSaveModal({ state: 'show' }));

  const action = yield race({
    saveChanges: take(types.SAVE_CHANGES_AND_SWITCH),
    cancelAndSwitch: take(types.CANCEL_CHANGES_AND_SWITCH),
    cancelAndStay: take(types.CANCEL_CHANGES_AND_STAY)
  });

  if (action.saveChanges) {
    yield put(saveChanges());

    const { saveFailed } = yield race({
      saveSuccess: take(types.SAVE_CHANGES_SUCCESS),
      saveFailed: take(types.SAVE_CHANGES_FAIL)
    });

    if (saveFailed) {
      yield put(updateSaveModal({ state: 'hide' }));
      return false;
    }
  } else if (action.cancelAndStay) {
    yield put(updateSaveModal({ state: 'hide' }));
    return false;
  }

  yield put(updateSaveModal({ state: 'hide' }));
  return true;
}

// handle button state
function shouldHandle(btnState) {
  const btnStateToHandle = ['unsaved', 'unsaved-errors'];
  return btnStateToHandle.includes(btnState);
}

// send API request for save form changes
function* saveFormChanges() {
  let intl = yield select(state => state.intlReact.intl);

  const form = yield call(getFormDataRequestObj);

  const id = yield select(s => s.storeInformation.selectedNode);

  try {
    yield put(updateSaveButtonState({ btnState: 'saving-changes' }));

    yield call(doRequest, {
      url: `storeprop/storeprop/${encodeURIComponent(id)}`,
      method: 'PUT',
      data: form
    });

    yield put(getStoreList());
    yield put(saveChangesSuccess({ form }));
  } catch (error) {
    message.error(intl.formatMessage({ id: 'Generic.ApiMessages.StoreInformation.FormSaveError' }));
    yield put(saveChangesFail());
    yield put(updateSaveButtonState({ btnState: 'unsaved' }));
  }
}

// Customize request Object
function* getFormDataRequestObj() {
  const type = yield select(s => s.storeInformation.selectedNodeType);
  const form = yield call(getFormData);

  const sendFormObj = yield call(customFormObj, form, type);

  return sendFormObj;
}

// custorm form object request format
function* customFormObj(formattedFormData, type) {
  const locale = yield call(getUserLocale);
  const collectionId = yield select(s => s.storeInformation.collectionId);

  if (
    type === ListTypeEnums.STORE_TYPE ||
    type === ListTypeEnums.SOCIAL_MEDIA ||
    type === ListTypeEnums.MOBILE_APP
  ) {
    let formObj1 = {
      non_translate: {
        type: type,
        image: collectionId
      },
      translations: { ...formattedFormData }
    };
    return formObj1;
  } else {
    let formObj2 = {
      non_translate: {
        type: type,
        image: collectionId,
        country: [],
        enable_all_country: false
      },
      translations: {}
    };

    const defaultData = formattedFormData[locale];

    if (defaultData && defaultData.countryFilter) {
      if (Array.isArray(defaultData.countryFilter)) {
        let countryArray = [];

        defaultData.countryFilter.forEach(elemet => {
          if (elemet.value != '0') {
            countryArray.push(Number(elemet.value));
          } else {
            countryArray = [];
          }
        });

        formObj2.non_translate.country = countryArray;

        delete defaultData.countryFilter;
      } else {
        const countryList = yield call(getFormCountryArrayList, defaultData.countryFilter);
        formObj2.non_translate.country = countryList;

        delete defaultData.countryFilter;
      }
    }

    if (defaultData) {
      if (defaultData.enable_all_country) {
        const enable_all_countryNew = defaultData.enable_all_country;
        formObj2.non_translate.enable_all_country = enable_all_countryNew;

        delete defaultData.enable_all_country;
      } else {
        formObj2.non_translate.enable_all_country = defaultData.enable_all_country;

        delete defaultData.enable_all_country;
      }
    }

    formObj2.translations = formattedFormData;

    return formObj2;
  }
}

// custom country array list as interger array
function getFormCountryArrayList(val) {
  let countrySelectedArray = [];

  if (val) {
    const selectedval = val.split(',');
    selectedval.forEach(element => {
      countrySelectedArray.push(Number(element));
    });
  }
  return countrySelectedArray;
}

// get translation form data
function* getFormData() {
  const [formData, type] = yield select(s => [
    s.storeInformation.formData,
    s.storeInformation.selectedNodeType
  ]);

  const formattedFormData = yield call(formatData, formData, type);

  const dataToSend = {
    ...formattedFormData
  };

  return dataToSend;
}

// get form translation values without undefiend value
function formatData(formData, type) {
  const nodeTypesToSkipNameCheck = [
    ListTypeEnums.STORE_TYPE,
    ListTypeEnums.SOCIAL_MEDIA,
    ListTypeEnums.MOBILE_APP,
    ListTypeEnums.FEATURES,
    ListTypeEnums.ACCEPTED_PAYMENTS
  ];
  const data = {};
  for (let key in formData) {
    const langData = formData[key];
    const newLangData = {};

    //skip if the name is empty
    if (!langData || (!nodeTypesToSkipNameCheck.includes(type) && !langData['name']['value'])) {
      continue;
    }

    for (let name in langData) {
      if (typeof langData[name]['value'] !== 'undefined')
        newLangData[name] = langData[name]['value'];
    }

    if (newLangData.name) {
      data[key] = newLangData;
    }
  }

  return data;
}

// search tree list
function* search(action) {
  const key = action.payload.key.toLowerCase();

  if (!key) {
    return yield put(searchStoreSuccess({ data: null }));
  }

  const [store_type, social_media, mobile_app, features, accepted_payments] = yield select(
    ({ storeInformation: { originalData: _ } }) => [
      _.store_type,
      _.social_media,
      _.mobile_app,
      _.features,
      _.accepted_payments
    ]
  );

  const [
    store_typeResult,
    social_mediaResult,
    mobile_appResult,
    featuresResult,
    accepted_paymentsResult
  ] = yield all([
    call(individualSearch, key, store_type),
    call(individualSearch, key, social_media),
    call(individualSearch, key, mobile_app),
    call(individualSearch, key, features),
    call(individualSearch, key, accepted_payments)
  ]);

  yield put(
    searchStoreSuccess({
      data: {
        store_type: store_typeResult,
        social_media: social_mediaResult,
        mobile_app: mobile_appResult,
        features: featuresResult,
        accepted_payments: accepted_paymentsResult
      }
    })
  );
}

// search item with type
function individualSearch(key, items) {
  if (!items) return [];
  return items.filter(item =>
    item && item.value ? item.value.toLowerCase().includes(key) : false
  );
}

// delete node
function* deleteNode(action) {
  const { type, id } = action.payload;
  let intl = yield select(state => state.intlReact.intl);

  try {
    yield call(doRequest, {
      url: `storeprop/storeprop/${encodeURIComponent(id)}`,
      method: 'DELETE'
    });

    yield put(deleteNodeSuccess({ type, id }));
  } catch (error) {
    message.error(
      intl.formatMessage({ id: 'Generic.ApiMessages.StoreInformation.NodeDeleteError' })
    );
  }
}

// save image
function* saveImage() {
  yield put(showStoreInfoLoader());

  try {
    const id = yield select(state => state.storeInformation.selectedNode);
    const image = yield select(state => state.storeInformation.image);
    const imageBinary = yield select(state => state.storeInformation.imageBinary);
    const collectionId = yield select(state => state.storeInformation.collectionId);

    // if a previous image is uploaded
    // delete it and then upload new one
    if (collectionId) {
      yield call(doRequest, {
        url: `unimup/objctupload/prodlabel/${collectionId}`,
        method: 'DELETE'
      });
    }
    if (imageBinary && imageBinary.type === 'image/svg+xml') {
      const formData = new FormData();
      formData.append('media', imageBinary);

      const response = yield call(doRequest, {
        url: `unimup/objctupload/prodlabel/${id}`,
        method: 'POST',
        data: formData
      });

      const imgObj = {
        image: response.data.CollectionId
      };

      yield call(doRequest, {
        url: `storeprop/storeprop/${id}`,
        method: 'PATCH',
        data: imgObj
      });

      const newCollectionId = response.data.CollectionId;
      yield put(saveStoreInfoImageSuccess({ img: image, item: newCollectionId }));

      message.info(yield getIntlMessage('Generic.ApiMessages.StoreInformation.ImageUploadSuccess'));
    } else {
      message.warn(yield getIntlMessage('Generic.ApiMessages.StoreInformation.FileTypeError'));
      yield put(saveStoreInfoImageFail());
    }
  } catch (error) {
    console.log({ error });

    message.error(yield getIntlMessage('Generic.ApiMessages.StoreInformation.ImageUploadError'));
    yield put(saveStoreInfoImageFail());
  } finally {
    yield put(hideStoreInfoImgLoader());
  }
}

function* deleteImage() {
  try {
    const collectionId = yield select(state => state.storeInformation.collectionId);
    const id = yield select(state => state.storeInformation.selectedNode);

    if (collectionId) {
      yield call(doRequest, {
        url: `unimup/objctupload/prodlabel/${collectionId}`,
        method: 'DELETE'
      });

      const imgObj = {
        image: ''
      };
      yield call(doRequest, {
        url: `storeprop/storeprop/${id}`,
        method: 'PATCH',
        data: imgObj
      });

      yield put(removeStoreInfoImageSuccess());
      message.info(yield getIntlMessage('Generic.ApiMessages.StoreInformation.ImageDeleteSuccess'));
    }
  } catch (error) {
    console.log({ error });
    message.error(yield getIntlMessage('Generic.ApiMessages.StoreInformation.ImageDeleteError'));
  }
}

// request image get with collection id
function* getStoreImageRequest(id) {
  let fileList = [];
  let tempObj = {};
  let response;
  let response1;

  try {
    if (id && id != '') {
      response = yield call(doRequest, {
        url: `unimup/objctupload/prodlabel/${id}`,
        method: 'GET'
      });

      let imageUrls = imageList(response.data.pages);

      for (var i = 0; i < imageUrls.length; i++) {
        tempObj = {};
        response1 = yield call(doRequest, {
          method: 'GET',
          url: `/unimup/objctupload${imageUrls[i].tempUrl}`,
          responseType: 'blob'
        });

        let image = URL.createObjectURL(response1.data);
        tempObj = {
          uid: `${imageUrls[i].pageId}`,
          url: image,
          active: imageUrls[i].active,
          position: `${i}`,
          type: response1.data ? response1.data.type : ''
        };
        fileList.push(tempObj);
      }
    }

    if (id === '') {
      fileList = [];
    }
    if (response1.status === 200) {
      yield put(getStoreInfoImageSuccess({ imagedata: fileList }));
    }
  } catch (error) {
    return false;
  } finally {
    yield put(hideStoreInfoImgLoader());
  }
}

// filter image get
function imageList(array) {
  let newArr = [];
  let tempObj = {};
  if (array) {
    for (var i = 0; i < array.length; i++) {
      tempObj = {};

      tempObj = {
        tempUrl: array[i].optimized.fetchUrl,
        pageId: array[i].pageId,
        active: array[i].active
      };
      newArr.push(tempObj);
    }
  }

  return newArr;
}

/** Watchers */

function* getListWatcher() {
  yield takeLatest(types.GET_LIST, getList);
}
function* saveImageWatcher() {
  yield takeLatest(types.SAVE_IMG, saveImage);
}

function* deleteImageWatcher() {
  yield takeLatest(types.REMOVE_IMG, deleteImage);
}

function* saveNodeWatcher() {
  while (true) {
    const action = yield take(types.SAVE_NODE);
    yield call(saveNode, action.payload);
  }
}

function* updateOrderWatcher() {
  yield takeEvery(types.UPDATE_ORDER, updateOrder);
}

function* setSelectedNodeWatcher() {
  yield takeLatest(types.SET_SELECTED_NODE, ({ payload: { id, type } }) =>
    setSelectedNode(id, type)
  );
}

function* saveFormChangesWatcher() {
  yield takeLatest(types.SAVE_CHANGES, saveFormChanges);
}

function* searchWatcher() {
  yield takeLatest(types.SEARCH, search);
}

function* deleteNodeWatcher() {
  yield takeLatest(types.DELETE_NODE, deleteNode);
}

function* addNodeWatcher() {
  while (true) {
    const action = yield take(types.ADD_NODE);
    yield call(addNode, action);
  }
}

export default function* rootSaga() {
  yield all([
    fork(getListWatcher),
    fork(addNodeWatcher),
    fork(saveNodeWatcher),
    fork(updateOrderWatcher),
    fork(setSelectedNodeWatcher),
    fork(saveFormChangesWatcher),
    fork(searchWatcher),
    fork(deleteNodeWatcher),
    fork(saveImageWatcher),
    fork(deleteImageWatcher)
  ]);
}
