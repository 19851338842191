import { takeLatest, all, fork, put, select, call, take } from 'redux-saga/effects';
import { brandTypes } from './brandModal.constants';
import {
  editBrandSuccess,
  saveBrandDetailsSuccess,
  updateBrandModal,
  showBrandLoader,
  hideBrandLoader,
  saveBrandImageSuccess,
  removeBrandImageSuccess,
  getBrandListSuccess,
  setCollectionId,
  removeCollectionId,
  saveBrandDetailsFail,
  getBrandsListWorkerSuccess
} from './brandModal.actions';
import { doRequest } from 'app/shared/redux/sagas/api';
import { message } from 'antd';
import { getManufacturerList } from '../manufactureModal/productModals.actions';
import { manufacturerTypes } from '../manufactureModal/productModals.constants';
import {
  getDataList,
  setSearchProductIds,
  getSearchSuggestionSuccess,
  addTagsSuccess
} from '../../../catalogue/brand/redux/catalogue_brand.actions';
import { getSearchResults } from '../../../catalogue/brand/redux/catalogue_brand.saga';

import { addJobToQueue } from './../../../../../shared/redux/actions/index';
/** Utilities::Begin */
function mapFormValueToApi({
  manufactureId,
  name,
  url,
  email,
  phone,
  contactFormUrl,
  animalTesting,
  veganManufacturing,
  crueltyFree,
  address,
  googleAddress,
  country,
  description
}) {
  const booleanKeys = ['may_perform_animal_testing', 'total_vegan_manufacturing', 'cruelty_free'];

  const apiValues = {
    manufacture: manufactureId,
    brand_name: name,
    brand_url: url,
    email: email,
    phone: phone,
    contact_form_url: contactFormUrl,
    may_perform_animal_testing: animalTesting,
    total_vegan_manufacturing: veganManufacturing,
    cruelty_free: crueltyFree,
    address: address,
    google_address: googleAddress,
    country: country ? Number(country) : country,
    image: '',
    description
  };

  for (const key of Object.keys(apiValues)) {
    if (typeof apiValues[key] === 'undefined' && !booleanKeys.includes(key)) {
      apiValues[key] = '';
    } else if (typeof apiValues[key] === 'undefined' && booleanKeys.includes(key)) {
      apiValues[key] = false;
    }
  }

  return apiValues;
}

function transformItem({
  manufacture,
  brand_name,
  brand_url,
  email,
  phone,
  contact_form_url,
  may_perform_animal_testing,
  total_vegan_manufacturing,
  cruelty_free,
  address,
  google_address,
  country,
  image,
  description
}) {
  return {
    manufactureId: manufacture,
    name: brand_name,
    url: brand_url,
    email,
    phone,
    contactFormUrl: contact_form_url,
    animalTesting: may_perform_animal_testing,
    veganManufacturing: total_vegan_manufacturing,
    crueltyFree: cruelty_free,
    address,
    googleAddress: google_address,
    country,
    description: description || ''
  };
}

function* storeCollectionId(id) {
  yield put(setCollectionId({ id }));
}

function* getCollectionId() {
  const item = yield select(s => s.productModals.brand.collectionId);
  yield put(removeCollectionId());
  return item;
}

function* getIntlMessage(id) {
  const intl = yield select(({ intlReact: { intl } }) => intl);
  return intl.formatMessage({ id });
}
/** Utilities::End */

function* getBrandList() {
  try {
    const response = yield call(doRequest, {
      url: '/prodmanu/manufacture/BRAN',
      method: 'GET'
    });

    // let response = {
    //   data: {
    //     total_rows: 2858,
    //     offset: 0,
    //     rows: [
    //       {
    //         id: '2abc06205ba21279597563e9bd077e99',
    //         key: ['BRAN'],
    //         value: null,
    //         doc: {
    //           _id: '2abc06205ba21279597563e9bd077e99',
    //           _rev: '3-d99c260a2188b37eb9577c8e04e2d9e5',
    //           manufacture: '2abc06205ba21279597563e9bd00027b',
    //           brand_name: 'New Bran++++++',
    //           brand_url: '',
    //           email: '',
    //           phone: '',
    //           contact_form_url: '',
    //           may_perform_animal_testing: false,
    //           total_vegan_manufacturing: true,
    //           cruelty_free: false,
    //           address: '',
    //           google_address: '',
    //           country: 27,
    //           image: '',
    //           description: '',
    //           type: 'BRAN',
    //           country_name: 'Angola',
    //           created_date: 1571221918139,
    //           last_update: '1571378715983'
    //         }
    //       },
    //       {
    //         id: '2abc06205ba21279597563e9bd078132',
    //         key: ['BRAN'],
    //         value: null,
    //         doc: {
    //           _id: '2abc06205ba21279597563e9bd078132',
    //           _rev: '3-146654c7d5eb8556939efab4a08c1ad9',
    //           manufacture: '6ed3d427045228dce9e3b38d1150ac96',
    //           brand_name:
    //             'BR Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cu',
    //           brand_url: '',
    //           email: '',
    //           phone: '',
    //           contact_form_url: '',
    //           may_perform_animal_testing: false,
    //           total_vegan_manufacturing: false,
    //           cruelty_free: false,
    //           address: '',
    //           google_address: '',
    //           country: 27,
    //           image: '',
    //           description: '',
    //           type: 'BRAN',
    //           country_name: 'Angola',
    //           created_date: 1571222801431,
    //           last_update: '1581569279920'
    //         }
    //       },
    //       {
    //         id: '2abc06205ba21279597563e9bd078e5f',
    //         key: ['BRAN'],
    //         value: null,
    //         doc: {
    //           _id: '2abc06205ba21279597563e9bd078e5f',
    //           _rev: '2-bcd63236cf50a186e391fb14815a2d95',
    //           manufacture: '6ed3d427045228dce9e3b38d115139b7',
    //           brand_name: 'NewBrandIndex',
    //           brand_url: '',
    //           email: '',
    //           phone: '',
    //           contact_form_url: '',
    //           may_perform_animal_testing: false,
    //           total_vegan_manufacturing: true,
    //           cruelty_free: false,
    //           address: '',
    //           google_address: '',
    //           country: 26,
    //           image: '',
    //           description: '',
    //           type: 'BRAN',
    //           country_name: 'Andorra',
    //           created_date: 1571223254796,
    //           last_update: '1571223535002'
    //         }
    //       }
    //     ]
    //   }
    // }

    const { rows } = response.data;
    const workerParamsGetBrands = {
      dataArray: rows
    };

    yield put(
      addJobToQueue({
        workerAction: 'BRAND_LIST',
        data: workerParamsGetBrands,
        actionToDispatch: getBrandsListWorkerSuccess
      })
    );

    let payloadResponse = yield take('BRAND_GET_BRAND__WORKER_SUCCESS');
    // filtertedData = rows.map(item => item.doc);

    yield put(getBrandListSuccess({ data: payloadResponse.payload.data.result }));
  } catch (error) {
    console.log(error);
    message.error(yield getIntlMessage('Generic.ApiMessages.Products.Brand.GetListError'));
  }
}

function* createBrand(formData) {
  if (!formData.country) {
    formData.country = '';
  }
  const response = yield call(doRequest, {
    url: '/prodmanu/manufacture/BRAN',
    method: 'POST',
    data: formData
  });

  return response.data.id;
}

function* updateBrandDetail(id, formData) {
  if (!formData.country) {
    formData.country = '';
  }

  yield call(doRequest, {
    url: `/prodmanu/manufacture/BRAN/${id}`,
    method: 'PUT',
    data: formData
  });
}

function* saveBrandDetails({ payload: { cb, form, popupFrom } }) {
  yield put(showBrandLoader());
  let [id, list] = yield select(s => [s.productModals.brand.id, s.productModals.brand.list]);

  const formData = yield call(mapFormValueToApi, form);

  /**
   * if open from @catalogue
   * then get the image id from @editForm
   */
  if (popupFrom) {
    const item = yield select(s => s.productModals.brand.editForm);

    if (item && item.collectionId) {
      formData.image = item.collectionId;
    }
  } else {
    // if an item exists, we set its image url
    const item = list.find(item => item._id === id);
    if (item) {
      formData.image = item.image;
    }
  }

  // Add Image URL before saving
  const collectionId = yield call(getCollectionId);

  if (collectionId) {
    formData.image = collectionId;
  }

  try {
    if (id) {
      yield call(updateBrandDetail, id, formData);
      message.info(yield getIntlMessage('Generic.ApiMessages.Products.Brand.DetailUpdated'));

      if (popupFrom) {
        const { pagination, sort } = yield select(
          ({
            catalogue: {
              brand: { pagination, sort }
            }
          }) => ({ pagination, sort })
        );

        const tags = yield select(s => s.catalogue.brand.tags);
        if (tags.length) {
          yield fork(getSearchResults, tags);
        } else {
          yield put(getDataList({ pagination, sort }));
        }
      }
    } else {
      id = yield call(createBrand, formData);
      message.info(yield getIntlMessage('Generic.ApiMessages.Products.Brand.CreateSuccess'));

      if (popupFrom) {
        // reset tags, searchids, suggestions
        yield put(addTagsSuccess({ tags: [] }));
        yield put(getSearchSuggestionSuccess({ searchResults: [] }));
        yield put(setSearchProductIds({ ids: [] }));

        yield put(
          getDataList({
            pagination: {
              total: 0,
              pageSize: 50,
              current: 1
            },
            sort: { order: 'desc', columnKey: 'create' }
          })
        );
      }
    }

    yield fork(cb);

    yield put(saveBrandDetailsSuccess({ data: formData, id }));
    yield put(updateBrandModal({ state: 'close' }));
  } catch (error) {
    console.log(error);
    if (error.response.status === 400) {
      message.error(yield getIntlMessage('Generic.ApiMessages.Products.Brand.InvalidData'));
    } else {
      message.error(yield getIntlMessage('Generic.ApiMessages.Products.Brand.SavingError'));
    }
  } finally {
    yield put(hideBrandLoader());
  }
}

function* editFromProductPage(id) {
  const list = yield select(s => s.productModals.brand.list);
  const item = list.find(i => i._id === id);
  const transformedItem = yield call(transformItem, item);
  let image = '';

  if (item.image) {
    image = yield call(fetchBrandImage, item.image);
  }

  return [transformedItem, image];
}

function* editFromCatalogue(id) {
  const response = yield call(doRequest, {
    url: `prodmanu/manufacture/node/${id}`,
    method: 'GET'
  });

  const { data } = response;

  const brandData = yield call(transformItem, data);

  brandData.collectionId = data.image;

  let image = '';

  if (data.image) {
    image = yield call(fetchBrandImage, data.image);
  }

  return [brandData, image];
}

function* fetchBrandImage(collectionId) {
  const imageUrlResponse = yield call(doRequest, {
    url: `unimup/objctupload/manuf/${collectionId}`,
    method: 'GET'
  });
  const { data } = imageUrlResponse;

  if (data.pages.length) {
    const imageUrl = data.pages[0].optimized.fetchUrl;
    const imageResponse = yield call(doRequest, {
      url: `unimup/objctupload${imageUrl}`,
      method: 'GET',
      responseType: 'blob'
    });

    const { data: imageData } = imageResponse;
    const image = URL.createObjectURL(imageData);

    return image;
  }
}

function* loadManufactureList() {
  yield put(getManufacturerList());
  yield take(manufacturerTypes.GET_LIST_SUCCESS);
}

function* editBrandDetails({ payload: { id, popupFrom } }) {
  yield put(updateBrandModal({ state: 'open' }));
  yield put(showBrandLoader());

  try {
    let transformedItem = {};
    let image = '';

    /**
     * Popup can be open from the product page or
     * Catalogue Page
     */
    if (popupFrom) {
      yield call(loadManufactureList);

      [transformedItem, image] = yield call(editFromCatalogue, id);
    } else {
      [transformedItem, image] = yield call(editFromProductPage, id);
    }

    yield put(editBrandSuccess({ data: transformedItem, id, image }));
  } catch (error) {
    yield put(updateBrandModal({ state: 'close' }));
    console.log({ error });
    message.error(yield getIntlMessage('Generic.ApiMessages.Products.Brand.GetDetailsError'));
  }
  yield put(hideBrandLoader());
}

function* saveImage({ payload: { popupFrom } }) {
  yield put(showBrandLoader());
  let updatedItem = null;

  try {
    const [image, file, id, list, editForm] = yield select(s => [
      s.productModals.brand.image,
      s.productModals.brand.imageBinary,
      s.productModals.brand.id,
      s.productModals.brand.list,
      s.productModals.brand.editForm
    ]);

    const item = list.find(item => item._id === id);
    const collectionId = yield call(getCollectionId);

    let imageId = '';

    /**
     * check for popupFrom to identify where the popup
     * was open from product or catalogue page
     */
    if (!popupFrom && item && item.image) {
      imageId = item.image;
    } else if (popupFrom && editForm && editForm.collectionId) {
      imageId = editForm.collectionId;
    } else if (collectionId) {
      imageId = collectionId;
    }

    const formData = new FormData();
    formData.append('media', file);

    const response = yield call(doRequest, {
      url: '/unimup/objctupload/manuf/',
      method: 'POST',
      data: formData
    });

    if (response.status === 200) {
      const {
        data: { CollectionId }
      } = response;

      /**
       * if the item is previsously created
       * */
      if (id) {
        /**
         * if open from catalogue then
         * update the editform value
         */
        if (popupFrom) {
          editForm.image = CollectionId;
          editForm.collectionId = CollectionId;
          updatedItem = editForm;

          const apiFormData = yield call(mapFormValueToApi, editForm);
          apiFormData.image = CollectionId;

          yield call(updateBrandDetail, id, apiFormData);

          const { pagination, sort } = yield select(
            ({
              catalogue: {
                brand: { pagination, sort }
              }
            }) => ({ pagination, sort })
          );
          yield put(getDataList({ pagination, sort }));
        } else {
          item.image = CollectionId;
          updatedItem = item;
          yield call(updateBrandDetail, id, item);
        }
      } else {
        yield call(storeCollectionId, CollectionId);
      }
    }

    // if a previous image is uploaded
    // delete it and then upload new one
    if (imageId) {
      yield call(doRequest, {
        url: `unimup/objctupload/manuf/${imageId}`,
        method: 'DELETE'
      });
    }

    yield put(saveBrandImageSuccess({ img: image, item: updatedItem, popupFrom }));
    message.info(yield getIntlMessage('Generic.ApiMessages.Products.Brand.ImageUploadSuccess'));
  } catch (error) {
    console.log({ error });
    message.error(yield getIntlMessage('Generic.ApiMessages.Products.Brand.ImageUploadError'));
    yield put(saveBrandDetailsFail());
  } finally {
    yield put(hideBrandLoader());
  }
}

function* deleteImage({ payload: { popupFrom } }) {
  try {
    const [list, id, editForm] = yield select(s => [
      s.productModals.brand.list,
      s.productModals.brand.id,
      s.productModals.brand.editForm
    ]);
    const item = list.find(i => i._id === id);
    const collectionId = yield call(getCollectionId);

    let imageId = '';

    if (!popupFrom && item && item.image) {
      imageId = item.image;
    } else if (popupFrom && editForm && editForm.collectionId) {
      imageId = editForm.collectionId;
    } else if (collectionId) {
      imageId = collectionId;
    }

    if (imageId) {
      yield call(doRequest, {
        url: `unimup/objctupload/manuf/${imageId}`,
        method: 'DELETE'
      });

      if (item) {
        item.image = '';
        yield call(updateBrandDetail, id, item);
      } else if (popupFrom && editForm) {
        editForm.image = '';
        const apiFormData = yield call(mapFormValueToApi, editForm);
        yield call(updateBrandDetail, id, apiFormData);
      }

      yield put(removeBrandImageSuccess({ popupFrom }));
      message.info(yield getIntlMessage('Generic.ApiMessages.Products.Brand.ImageDeleteSuccess'));

      if (popupFrom) {
        const { pagination, sort } = yield select(
          ({
            catalogue: {
              brand: { pagination, sort }
            }
          }) => ({ pagination, sort })
        );
        yield put(getDataList({ pagination, sort }));
      }
    }
  } catch (error) {
    console.log({ error });
    message.error(yield getIntlMessage('Generic.ApiMessages.Products.Brand.ImageDeleteError'));
  }
}

function* openCatalogueModalSaga() {
  yield put(updateBrandModal({ state: 'open' }));
  yield put(showBrandLoader());

  try {
    yield call(loadManufactureList);
    yield put(hideBrandLoader());
  } catch (error) {
    yield put(updateBrandModal({ state: 'close' }));
  }
}

/** Watchers */

function* saveBrandDetailsWatcher() {
  yield takeLatest(brandTypes.SAVE_DETAILS, saveBrandDetails);
}

function* editBrandDetailsWatcher() {
  yield takeLatest(brandTypes.EDIT, editBrandDetails);
}

function* saveImageWatcher() {
  yield takeLatest(brandTypes.SAVE_IMG, saveImage);
}

function* deleteImageWatcher() {
  yield takeLatest(brandTypes.REMOVE_IMG, deleteImage);
}

function* getBrandListWatcher() {
  yield takeLatest(brandTypes.GET_LIST, getBrandList);
}

function* openCatalogueModalSagaWatcher() {
  yield takeLatest(brandTypes.OPEN_CATALOGUE, openCatalogueModalSaga);
}

export default function* rootSaga() {
  yield all([
    fork(getBrandListWatcher),
    fork(saveBrandDetailsWatcher),
    fork(editBrandDetailsWatcher),
    fork(saveImageWatcher),
    fork(deleteImageWatcher),
    fork(openCatalogueModalSagaWatcher)
  ]);
}
