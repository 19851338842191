import {
  takeLatest,
  all,
  fork,
  put,
  call,
  select,
  cancelled,
  cancel,
  take,
  race
} from 'redux-saga/effects';
import { doRequest } from 'app/shared/redux/sagas/api';
import { message } from 'antd';
import adsTypes from './addetails.constants';
import { setCountryCounter, setadDetails, getCountryCounter } from './addetails.actions';
import { setCollectionId } from './../imageRedux/imageUploader.actions';

// pass intl for get translation message
function* getLocalizedMessage(key) {
  const intlSelector = state => state.intlReact.intl;
  const intl = yield select(intlSelector);
  return intl.formatMessage({ id: key });
}
// pass intl for get translation message

function* getSingleAdFun({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: 'adsman/adsman/' + id
    });

    if (response.status === 200) {
      yield put(setadDetails({ data: response.data }));
      if (response.data && response.data.media_collection) {
        yield put(setCollectionId({ CollectionId: response.data.media_collection, pageType: '' }));
      }

      if (response.data.country && response.data.country !== '') {
        yield put(getCountryCounter({ counter: response.data.country }));
      }
    } else {
      message.error(' Error ');
    }
    // yield put(setTicketSaveLoader({ data: false }));
  } catch (error) {
    if (error.response.data && error.response.data.message) {
      message.error(' Error ');
    }
  }
}
function* getAdCounterByCountry({ payload }) {
  const { counter } = payload;
  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: 'adsman/adsman/adscount/' + counter
    });
    if (response.status === 200) {
      yield put(setCountryCounter({ counter: response.data.count }));
    } else {
      message.error(' Error ');
    }
    // yield put(setTicketSaveLoader({ data: false }));
  } catch (error) {
    if (error.response.data && error.response.data.message) {
      message.error(' Error ');
    }
  }
}
function* adSaveFun({ payload }) {
  const { data, type, history } = payload;
  try {
    let collectionId = yield select(state => state.adManagement.adsImageList.CollectionId);
    if (collectionId) {
      data.media_collection = collectionId;
    }

    if (type === 'save') {
      const response = yield call(doRequest, {
        method: 'POST',
        url: 'adsman/adsman',
        data: data
      });

      if (response.status === 200) {
        message.info('Ad added success');
        // delay(1000);
        history.push('/ad-management/Ads');
      }
    } else if (type && type !== 'save') {
      const response = yield call(doRequest, {
        method: 'PUT',
        url: 'adsman/adsman/' + type,
        data: data
      });

      if (response.status === 200) {
        message.info('Edit success');
        // delay(1000);
        history.push('/ad-management/Ads');
      }
    }
    // yield put(setTicketSaveLoader({ data: false }));
  } catch (error) {
    if (error.response.data && error.response.data.message) {
      if (error.response.data.message === 'Selected_Country_have_maximum_12_ads_limit') {
        const errorMessage = yield call(
          getLocalizedMessage,
          'LeftMenu.AdManagement.DetailsSection.AdsMessage'
        );
        message.error(errorMessage);
      } else {
        message.error(' Error ');
      }
    }
  }
}
/**
 * Watchers
 */

function* getAdCounterByCountrySagaWatcher() {
  yield takeLatest(adsTypes.GET_COUNTRY_COUNTER, getAdCounterByCountry);
}
function* adSaveFunSagaWatcher() {
  yield takeLatest(adsTypes.AD_SAVE, adSaveFun);
}
function* getSingleAdFunSagaWatcher() {
  yield takeLatest(adsTypes.GET_SINGLE_AD, getSingleAdFun);
}

export default function* rootSaga() {
  yield all([
    fork(getAdCounterByCountrySagaWatcher),
    fork(adSaveFunSagaWatcher),
    fork(getSingleAdFunSagaWatcher)
  ]);
}
