import distributorTypes from './catalogue_distributor.constants';

const initialState = {
  dataSource: [],
  tableLoader: true,
  sort: { order: 'desc', columnKey: 'edit' },
  pagination: { total: 0, pageSize: 50, current: 1 },
  searchLoader: false,
  searchData: [],
  searchResults: [],
  tags: [],
  idList: []
};
export default (state = initialState, action) => {
  switch (action.type) {
    case distributorTypes.GET_DATA_LIST: {
      return { ...state, tableLoader: true };
    }

    case distributorTypes.GET_DATA_LIST_SUCCESS: {
      const { dataList, pagination, sort } = action.payload;

      return {
        ...state,
        dataSource: dataList.docs,
        pagination: { ...pagination },
        sort: { ...sort },
        tableLoader: false
      };
    }
    case distributorTypes.GET_DATA_LIST_FAIL: {
      return { ...state, tableLoader: false };
    }

    case distributorTypes.SET_DATA_LIST_IMAGE_SUCCESS: {
      const {
        payload: { image, indexes }
      } = action;
      let data = state.dataSource;
      for (let distributorImage of image) {
        const itemIndex = indexes[distributorImage.id];
        if (itemIndex || itemIndex === 0) {
          const item = data[itemIndex];
          const clonedItem = { ...item };
          if (clonedItem && clonedItem._id) {
            clonedItem.image = distributorImage.image;
            data = [...data.slice(0, itemIndex), clonedItem, ...data.slice(itemIndex + 1)];
          }
        }
      }

      return { ...state, dataSource: data };
    }

    case distributorTypes.SET_SEARCH_LOADER: {
      const {
        payload: { searchLoader }
      } = action;

      return {
        ...state,
        searchLoader: searchLoader
      };
    }
    case distributorTypes.GET_SEARCH_SUGGESTION_SUCCESS: {
      const {
        payload: { searchResults }
      } = action;

      

      return {
        ...state,
        searchResults: searchResults
      };
    }
    case distributorTypes.ADD_TAG_SUCCESS: {
      const {
        payload: { tags }
      } = action;


      return {
        ...state,
        tags: tags
      };
    }

    case distributorTypes.DELETE_TAG_SUCCESS: {
      const {
        payload: { tags }
      } = action;

     

      return {
        ...state,
        tags: tags
      };
    }

    case distributorTypes.TABLE_DATA_SORT: {
      const { order, columnkey } = action.payload;
      return {
        ...state,
        sort: { order: order, columnKey: columnkey }
      };
    }
    case distributorTypes.RESET: {
      return initialState;
    }

    case distributorTypes.SET_ID_LIST: {
      const {
        payload: { idList }
      } = action;



      return {
        ...state,
        idList: idList
      };
    }

    default:
      return state;
  }
};
