import React, {Component} from "react";
import {connect} from "react-redux";
import {Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import { Link } from "react-router-dom"
import IntlMessages from "../../util/IntlMessages"

class UserInfo extends Component {
  constructor(props){
    super(props);
    this.state = {
      imageUrl: this.props.imageUrl
    };
  }

  render() {
    const userMenuOptions = (
      <ul style={{paddingLeft:'15px', paddingRight:'15px', paddingTop:'5px', paddingBottom:'5px'}} className="gx-user-popover">
        {/* need to  change the inline style color */}
        <Link className="profile-menu-side-li" to="/profile" >
          <li onClick={this.handleVisibleChange} > <IntlMessages id="Generic.MyProfile"/></li>
        </Link>
        <li onClick={() => this.props.userSignOut()}> <IntlMessages id="Generic.Logout"/>
        </li>
      </ul>
    );

    return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
               trigger={['focus', 'click']}>
                <a href="##" onClick={e => {e.preventDefault()}}>
       <img className="gx-avatar gx-pointer" alt="" src={this.props.imageUrl} />
         </a>
      </Popover>
    )

  }
}

const mapStateToProps = ({profile, loaders}) => ({imageUrl: profile.imageUrl, loaders})

export default connect(mapStateToProps, {userSignOut})(UserInfo);
