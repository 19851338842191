import { actionCreator } from 'redux-action-creator';
import types from './tags.constants';

export const getTagsList = actionCreator(types.GET_LIST, 'search');
export const getTagsListFail = actionCreator(types.GET_LIST_FAIL);
export const getTagsListSuccess = actionCreator(types.GET_LIST_SUCCESS, 'data', 'search');
export const addNewNode = actionCreator(types.ADD_NODE);
export const addNewNodeSuccess = actionCreator(types.ADD_NODE_SUCCESS);
export const saveNewNode = actionCreator(types.SAVE_NODE, 'values', 'newNode', 'cb');
export const saveNewNodeSuccess = actionCreator(
  types.SAVE_NODE_SUCCESS,
  'values',
  'newNode',
  'dupNewNodeItem'
);
export const saveNewNodeFail = actionCreator(types.SAVE_NODE_FAIL);
export const updateSortOrder = actionCreator(types.UPDATE_ORDER, 'values', 'oldIndex', 'newIndex');
export const updateSortOrderSuccess = actionCreator(types.UPDATE_ORDER_SUCCESS, 'values');
export const updateSortOrderFail = actionCreator(types.UPDATE_ORDER_FAIL);
export const setSelectedNode = actionCreator(types.SET_SELECTED_NODE, 'id', 'type');
export const setSelectedNodeSuccess = actionCreator(
  types.SET_SELECTED_NODE_SUCCESS,
  'id',
  'selectedData',
  'alternatives'
);
export const updateSaveButtonState = actionCreator(types.UPDATE_BUTTON_STATE, 'btnState');
export const saveChanges = actionCreator(types.SAVE_CHANGES);
export const saveChangesSuccess = actionCreator(types.SAVE_CHANGES_SUCCESS, 'form', 'alternatives');
export const saveChangesFail = actionCreator(types.SAVE_CHANGES_FAIL);
export const searchLabel = actionCreator(types.SEARCH, 'key');
export const searchTagsuccess = actionCreator(types.SEARCH_SUCCESS, 'data');
export const deleteNode = actionCreator(types.DELETE_NODE, 'id');
export const deleteNodeSuccess = actionCreator(
  types.DELETE_NODE_SUCCESS,
  'id',
  'originalData',
  'items',
  'duplicatedList'
);
export const deleteNodeFail = actionCreator(types.DELETE_NODE_FAIL, 'id');
export const updateFormData = actionCreator(types.UPDATE_FORMDATA, 'key', 'formData');
export const updateSaveModal = actionCreator(types.UPDATE_SAVE_MODAL, 'state');
export const saveChangesAndSwitch = actionCreator(types.SAVE_CHANGES_AND_SWITCH);
export const cancelChangesAndSwitch = actionCreator(types.CANCEL_CHANGES_AND_SWITCH);
export const cancelChangesAndStay = actionCreator(types.CANCEL_CHANGES_AND_STAY);
export const resetTagsState = actionCreator(types.RESET_STATE);
export const cuntrySelectedListUpdate = actionCreator(types.COUNTRY_SELECTED_LIST, 'keys');
export const countryAllSelectDisabled = actionCreator(types.DISABLED_WITH_ALL_COUNTRY, 'disabled');
export const selectTagsImage = actionCreator(types.SET_TEMP_IMG, 'img', 'file');
export const removeSelectedTagsImage = actionCreator(types.REMOVE_TEMP_IMG);
export const saveTagsImage = actionCreator(types.SAVE_IMG);
export const saveTagsImageSuccess = actionCreator(types.SAVE_IMG_SUCCESS, 'img', 'item');
export const saveTagsImageFail = actionCreator(types.SAVE_IMG_FAIL);
export const removeTagsImage = actionCreator(types.REMOVE_IMG);
export const removeTagsImageSuccess = actionCreator(types.REMOVE_IMG_SUCCESS);
export const removeTagsImageFail = actionCreator(types.REMOVE_IMG_FAIL);
export const showTagsLoader = actionCreator(types.SHOW_LOADER);
export const hideTagsImgLoader = actionCreator(types.HIDE_LOADER);
export const getTagsImage = actionCreator(types.GET_IMG);
export const getTagsImageSuccess = actionCreator(types.GET_IMG_SUCCESS, 'imagedata');
export const getDuplicatedTagList = actionCreator(types.GET_DUPLICATED_LIST);
export const getDuplicatedTagListSuccess = actionCreator(types.GET_DUPLICATED_LIST_SUCCESS, 'data');
export const getDuplicatedTagListFail = actionCreator(types.GET_DUPLICATED_LIST_FAIL);
export const searchJobsSuccess = actionCreator(types.SEARCH_JOB_SUCCESS, 'data', 'id');
export const updateAlternativeLisr = actionCreator(types.UPDATE_ALTERNATIVE_LIST, 'alternatives');
export const deleteJobSuccess = actionCreator(types.DELETE_JOB_SUCCESS, 'data');
export const duplicateTagsResponse = actionCreator(
  types.DUPLICATE_TAGS_RESPONSE,
  'duplicateTitles'
);
export const searchDuplicateJobsSuccess = actionCreator(
  types.SEARCH_DUPLICATE_JOB_SUCCESS,
  'data',
  'id'
);
