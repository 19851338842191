import brandTypes from './catalogue_brand.constants';

const initialState = {
  dataSource: [],
  tableLoader: true,
  sort: { order: 'desc', columnKey: 'edit' },
  pagination: { total: 0, pageSize: 50, current: 1 },
  searchLoader: false,
  searchSuggestions: [],
  tags: [],
  searchProductIds: []
};
export default (state = initialState, action) => {
  switch (action.type) {
    case brandTypes.GET_DATA_LIST: {
      return {
        ...state,
        tableLoader: true
      };
    }

    case brandTypes.GET_DATA_LIST_SUCCESS: {
      const { dataList, pagination, sort } = action.payload;

      return {
        ...state,
        dataSource: dataList.docs,
        pagination: { ...pagination },
        sort: { ...sort },
        tableLoader: false
      };
    }

    case brandTypes.GET_DATA_LIST_FAIL: {
      return {
        ...state,
        tableLoader: false
      };
    }

    case brandTypes.SET_BRAND_IMG: {
      const {
        payload: { image, indexes }
      } = action;

      let data = state.dataSource;
      for (let userImage of image) {
        const itemIndex = indexes[userImage.id];
        if (itemIndex || itemIndex === 0) {
          const item = data[itemIndex];
          const clonedItem = { ...item };

          if (clonedItem && clonedItem._id) {
            clonedItem.image = userImage.image;
            data = [...data.slice(0, itemIndex), clonedItem, ...data.slice(itemIndex + 1)];
          }
        }
      }

      return { ...state, dataSource: data };
    }

    case brandTypes.RESET: {
      return initialState;
    }

    case brandTypes.SET_SEARCH_LOADER: {
      const { searchLoader } = action.payload;

      return {
        ...state,
        searchLoader
      };
    }

    case brandTypes.GET_SEARCH_SUGGESTION_SUCCESS: {
      const { searchResults } = action.payload;

      return {
        ...state,
        searchSuggestions: searchResults
      };
    }

    case brandTypes.ADD_TAG_SUCCESS: {
      const { tags } = action.payload;

      return {
        ...state,
        tags
      };
    }

    case brandTypes.DELETE_TAG_SUCCESS: {
      const { tags } = action.payload;

      return {
        ...state,
        tags
      };
    }

    case brandTypes.SET_SEARCH_IDS: {
      const { ids } = action.payload;

      return {
        ...state,
        searchProductIds: ids
      };
    }

    default:
      return state;
  }
};
