import { call, takeEvery, all, fork, put, select } from 'redux-saga/effects';
import types from './imageUploader.constants';
import {
  setImageLIst,
  imageUploadSuccess,
  imageUploadLoader,
  setnewImageUrl
} from './imageUploader.actions';
import { doRequest } from './../../../../shared/redux/sagas/api';
import { message } from 'antd';

function* getTheImageLIst() {
  let CollectionId = yield select(state => state.adManagement.adsImageList.CollectionId);
  let fileList = [];
  let tempObj = {};
  let response;
  let response1;
  try {
    if (CollectionId && CollectionId != '') {
      response = yield call(doRequest, {
        method: 'GET',
        url: `/unimup/objctupload/ads/${CollectionId}`,
        shouldCancel: true
      });

      let imageUrls = imageList(response.data.pages);

      for (var i = 0; i < imageUrls.length; i++) {
        tempObj = {};
        response1 = yield call(doRequest, {
          method: 'GET',
          url: `/unimup/objctupload${imageUrls[i].tempUrl}`,
          responseType: 'blob',
          shouldCancel: true
        });

        let image = URL.createObjectURL(response1.data);
        tempObj = {
          uid: `${imageUrls[i].pageId}`,
          url: image,
          active: imageUrls[i].active,
          position: `${i}`,
          type: response1.data ? response1.data.type : ''
        };
        fileList.push(tempObj);
      }
    }

    if (CollectionId === '') {
      fileList = [];
    }
    if (response1.status === 200) {
      yield put(setImageLIst({ imageList: fileList }));
    }
  } catch (error) {
    return false;
  }
}
function* imageDelete(payload) {
  let intl = yield select(state => state.intlReact.intl);

  let CollectionId = yield select(state => state.adManagement.adsImageList.CollectionId);
  try {
    let response;

    response = yield call(doRequest, {
      method: 'DELETE',
      url: `/unimup/objctupload/collection/${CollectionId}/${payload.payload.imageId}`
    });

    if (response.status === 200) {
      message.info(
        intl.formatMessage({
          id: 'Generic.ApiMessages.AdManagement.ImageDeleteSuccess'
        })
      );

      yield put(setImageLIst({ imageList: payload.payload.imageList }));
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

function* imageUploadFunc(payload) {
  console.log([payload]);
  let intl = yield select(state => state.intlReact.intl);
  let fileList = payload.payload.imageList;
  let tempObj = {};
  const data = new FormData();
  data.append('media', payload.payload.singleImage);

  try {
    let response;
    if (payload.payload.CollectionId && payload.payload.CollectionId != '') {
      response = yield call(doRequest, {
        method: 'PUT',
        url: `/unimup/objctupload/ads/` + payload.payload.CollectionId,
        data: data
      });
    } else {
      response = yield call(doRequest, {
        method: 'POST',
        url: `/unimup/objctupload/ads/`,
        data: data
      });
    }
    if (response.status === 200) {
      let response1;

      response1 = yield call(doRequest, {
        method: 'GET',
        url: `/unimup/objctupload/collectionitem/${response.data.PageId}`,
        responseType: 'blob'
      });

      if (response1.status === 200) {
        message.info(
          intl.formatMessage({
            id: 'Generic.ApiMessages.AdManagement.ImageUploadSuccess'
          })
        );

        tempObj = {};
        let image = URL.createObjectURL(response1.data);
        tempObj = {
          uid: `${response.data.PageId}`,
          url: image,
          active: true,
          position: `${fileList.length}`,
          type: response1.data ? response1.data.type : ''
        };
        fileList.push(tempObj);
        yield put(
          imageUploadSuccess({ CollectionId: response.data.CollectionId, imageList: fileList })
        );
        yield put(setnewImageUrl({ newImageUrl: image, imageId: response.data.PageId }));
        yield put(imageUploadLoader({ loader: false }));
      }
    }
  } catch (error) {
    console.log(error);
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.AdManagement.ImageUploadError'
      })
    );

    yield put(imageUploadLoader({ loader: false }));
    return false;
  }
}
function* imageSwapPositionFunc(payload) {
  let intl = yield select(state => state.intlReact.intl);
  let CollectionId = yield select(state => state.adManagement.adsImageList.CollectionId);
  try {
    let response;

    response = yield call(doRequest, {
      method: 'PUT',
      url: `/unimup/objctupload/collection/${CollectionId}/${payload.payload.currentPosition}/${payload.payload.movePosition}`
    });

    if (response.status === 200) {
      message.info(
        intl.formatMessage({
          id: 'Generic.ApiMessages.AdManagement.ImageMoved'
        })
      );

      yield put(setImageLIst({ imageList: payload.payload.imageList }));
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}
function* imageActivateFunc(payload) {
  let intl = yield select(state => state.intlReact.intl);
  let active = 1;
  let CollectionId = yield select(state => state.adManagement.adsImageList.CollectionId);
  if (payload.payload.activateImage) {
    active = 1;
  } else {
    active = 0;
  }
  try {
    let response;

    response = yield call(doRequest, {
      method: 'PATCH',
      url: `/unimup/objctupload/collection/${CollectionId}/${payload.payload.preImgUID}/${active}`
    });

    if (response.status === 200) {
      message.info(
        intl.formatMessage({
          id: 'Generic.ApiMessages.AdManagement.ImageStatusChanged'
        })
      );
      yield put(setImageLIst({ imageList: payload.payload.imageList }));
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

function* getImageListListener() {
  yield takeEvery(types.GET_IMAGE_LIST, getTheImageLIst);
}
function* imageDeleteListener() {
  yield takeEvery(types.DELETE_IMAGE, imageDelete);
}
function* imageUploadListener() {
  yield takeEvery(types.UPLOAD_IMAGE, payload => imageUploadFunc(payload));
}
function* imagePositionUpdateListener() {
  yield takeEvery(types.IMAGE_POSITION_UPDATE, imageSwapPositionFunc);
}
function* imageActivateListener() {
  yield takeEvery(types.IMAGE_ACTIVATE, imageActivateFunc);
}

export default function* rootSaga() {
  yield all([
    fork(getImageListListener),
    fork(imageDeleteListener),
    fork(imageUploadListener),
    fork(imagePositionUpdateListener),
    fork(imageActivateListener)
  ]);
}
function imageList(array) {
  let newArr = [];
  let tempObj = {};
  if (array) {
    for (var i = 0; i < array.length; i++) {
      tempObj = {};

      tempObj = {
        tempUrl: array[i].optimized.fetchUrl,
        pageId: array[i].pageId,
        active: array[i].active
      };
      // newArr.push(array[i].optimized.fetchUrl)
      newArr.push(tempObj);
    }
  }

  return newArr;
}
