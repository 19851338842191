import { all, fork, put, takeLatest, select, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import types from './store.constants';
import moment from 'moment';
import normalizeTreeByLocale from '../../../../shared/utils/normalizeTreeByLocale';
import {
  getStoreListSuccess,
  getStoreInformationsValueListSuccess,
  updateStoreInfomationLoadedStatus,
  getStoreByLocalSuccess,
  deleteStoreSuccess,
  updateSaveButtonStatus,
  updateStoreDetailLoadStatus,
  updateStoreLocale,
  mayContaintLoader,
  lablesListLoader,
  setStoreLableImageSuccess
} from './store.actions';

import {
  setCollectionId,
  getImageLIst
} from './../../../../shared/redux/actions/imageUploader.actions';
import { doRequest } from './../../../../shared/redux/sagas/api';
import { message } from 'antd';

// get image indexes
function* getIndexedDistributorIds(type) {
  const items = yield select(state => state.indexedItem.storeGetLableSection);
  return items[type];
}

function* getUserLocale() {
  const [id, languageList] = yield select(s => [s.profile.language, s.languageList.languageList]);
  const language = languageList.find(language => language.id === id);
  return language.locale;
}

function storeInfoArr(arr) {
  let sortedArr = [];
  if (arr.length > 0) {
    arr.forEach(element => {
      let country = element.non_translate.country ? element.non_translate.country : [];
      if (element.non_translate.enable_all_country) {
        if (country && country.length === 0) {
          country.push('0');
        }
      }

      let elementModified = {
        id: element.non_translate._id,
        position: element.non_translate.position,
        image: element.non_translate.image,
        languages: { ...element.translations },
        country: element.non_translate.country,
        enable_all_country: element.non_translate.enable_all_country
      };
      sortedArr.push(elementModified);
    });
  }
  return sortedArr;
}

function* getStoreList() {
  yield put(mayContaintLoader({ loader: true }));
  yield put(lablesListLoader({ loader: true }));

  yield put(updateStoreInfomationLoadedStatus({ data: false }));

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: 'storeres/storedata/list'
      // url: 'storeres/store'
    });

    let modifiedArray = [];
    const local = yield call(getUserLocale);
    const defaulLocal = 'en_US';
    if (response.data.length > 0) {
      let modifiedArray = getStoreListByLocale(response.data, [], local, defaulLocal);
      // response.data.forEach(element => {
      //   if(element.data[local]){
      //     let elementModified = {
      //       _id: element._id,
      //       key: element._id,
      //       ean: element.data[local].translatable.details.store_name
      //     };
      //     modifiedArray.push(elementModified);
      //   }
      //   // else{
      //   //   let elementModified = {
      //   //     _id: element._id,
      //   //     key: element._id,
      //   //     ean: element.data['en'].translatable.details.store_name
      //   //   };
      //   //   modifiedArray.push(elementModified);
      //   // }
      // });
      yield put(updateStoreInfomationLoadedStatus({ data: false }));

      yield put(getStoreListSuccess({ data: modifiedArray }));
    }
  } catch (error) {
    console.log(error);
  }
}

function filterDate(passedArray) {
  let filteredObj = {
    oldDays: [],
    newDays: []
  };

  let customDate =
    new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();
  if (passedArray) {
    passedArray.forEach(element => {
      let date = moment(element.date, 'YYYY-MM-DD');
      let todayDate = moment(customDate, 'YYYY-MM-DD');

      if (date >= todayDate) {
        filteredObj.newDays.push(element);
      } else {
        filteredObj.oldDays.push(element);
      }
    });
  }

  return filteredObj;
}

function* getStoreByLocal(payloadPassed) {
  const { payload } = payloadPassed;

  const { data } = payload;

  yield put(updateStoreDetailLoadStatus({ data: false }));

  let urlPassing = `storeres/store/` + data.pid + '/' + data.locale;

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: urlPassing
    });

    let createObj = response.data;

    if (response.data.mediaCollectionId) {
      yield put(
        setCollectionId({ CollectionId: response.data.mediaCollectionId, pageType: 'store' })
      );
    }

    yield put(updateStoreDetailLoadStatus({ data: true }));

    let specialDaysArray = [];
    if (createObj.non_translate && createObj.non_translate.specialDays) {
      specialDaysArray = createObj.non_translate.specialDays;
      if (specialDaysArray.length > 0) {
        specialDaysArray.map(function(item) {
          return (item['isPreserve'] = true);
        });
      }
    }

    let filterdSpecialDays = filterDate(specialDaysArray);

    let sortedArray = filterdSpecialDays.oldDays.sort(function(a, b) {
      return Number(a.key) > Number(b.key);
    });

    // yield put(getStoreByLocalSuccess({ data: response.data[data.locale] }));
    yield put(
      getStoreByLocalSuccess({
        data: createObj,
        specialDays: filterdSpecialDays.newDays,
        oldSpecialDays: sortedArray
      })
    );

    yield put(updateStoreLocale({ data: data.locale }));
  } catch (error) {
    if (error.response.status === 404) {
      let languageList = yield select(state => state.store.storeData);
      if (languageList) {
        if (languageList.translatable) {
          languageList.translatable['details'] = {};
          languageList.translatable['mobileapps'] = {};
          languageList.translatable['socialmedias'] = {};
        } else {
          languageList.translatable = {
            details: {},
            mobileapps: {},
            socialmedias: {}
          };
        }
        yield put(getStoreByLocalSuccess({ data: languageList }));
      } else {
        yield put(updateStoreInfomationLoadedStatus({ data: false }));
        yield put(getStoreByLocalSuccess({ data: {} }));
      }
    }
  }
}

function getStoreListByLocale(dataArray, newArray, passedLocal, defaultLocal) {
  dataArray.forEach(item => {
    let objIngredient = {
      _id: item._id,
      key: item._id,
      ean: ''
    };

    if (item.translations) {
      let languagesArray = item.translations;
      if (languagesArray[passedLocal]) {
        objIngredient.ean = languagesArray[passedLocal].name;

        newArray.push(objIngredient);
      } else if (languagesArray[defaultLocal]) {
        objIngredient.ean = languagesArray[defaultLocal].name;
        newArray.push(objIngredient);
      } else {
        for (var key in languagesArray) {
          if (languagesArray[key]) {
            let firstChild = languagesArray[key];
            objIngredient.ean = firstChild.name;
            newArray.push(objIngredient);
            break;
          }
          // object[prop]
        }
      }
    } else {
      // objIngredient.title = item.title;
      // newArray.push(objIngredient);
    }
  });

  return newArray;
}

function getNutriententListValue(type, dataArray, newArray, passedLocal, defaultLocal) {
  dataArray.forEach(item => {
    if (item.languages) {
      if (type !== 'energy') {
        if (type === 'comp') {
          let objNutritioValue = {
            id: item.id,
            position: item.position,
            image: item.image,
            countries: item.countries,
            name: ''
          };
          let languagesArray = item.languages;
          if (languagesArray[passedLocal] && languagesArray[passedLocal].name) {
            objNutritioValue.name = languagesArray[passedLocal].name;
            if (languagesArray[passedLocal].measure_standard) {
              objNutritioValue['measure_standard'] = languagesArray[passedLocal].measure_standard;
            }

            newArray.push(objNutritioValue);
          } else if (languagesArray[defaultLocal] && languagesArray[defaultLocal].name) {
            objNutritioValue.name = languagesArray[defaultLocal].name;

            if (languagesArray[defaultLocal].measure_standard) {
              objNutritioValue['measure_standard'] = languagesArray[defaultLocal].measure_standard;
            }

            newArray.push(objNutritioValue);
          } else {
            for (var key in languagesArray) {
              if (languagesArray[key] && languagesArray[key].name) {
                let firstChild = languagesArray[key];
                objNutritioValue.name = firstChild.name;

                if (firstChild.measure_standard) {
                  objNutritioValue['measure_standard'] = firstChild.measure_standard;
                }

                newArray.push(objNutritioValue);
                break;
              }
              // object[prop]
            }
          }
        } else {
          let country = item.country || [];
          if (item.enable_all_country === true) {
            country = ['0'];
          }

          let objNutritioValue = {
            id: item.id,
            position: item.position,
            image: item.image,
            name: '',
            countries: country
          };
          let languagesArray = item.languages;
          if (languagesArray[passedLocal] && languagesArray[passedLocal].name) {
            objNutritioValue.name = languagesArray[passedLocal].name;

            newArray.push(objNutritioValue);
          } else if (languagesArray[defaultLocal] && languagesArray[defaultLocal].name) {
            objNutritioValue.name = languagesArray[defaultLocal].name;

            newArray.push(objNutritioValue);
          } else {
            for (var key in languagesArray) {
              if (languagesArray[key] && languagesArray[key].name) {
                let firstChild = languagesArray[key];
                objNutritioValue.name = firstChild.name;

                newArray.push(objNutritioValue);
                break;
              }
              // object[prop]
            }
          }
        }
      } else {
        let objNutritioValue = {
          id: item.id,
          position: item.position,
          image: item.image,
          countries: item.countries,
          name: '',
          name_2: '',
          name_3: ''
        };
        let languagesArray = item.languages;
        if (languagesArray[passedLocal] && languagesArray[passedLocal].name) {
          if (languagesArray[passedLocal].name) {
            objNutritioValue.name = languagesArray[passedLocal].name;
          }
          if (languagesArray[passedLocal].name_2) {
            objNutritioValue.name_2 = languagesArray[passedLocal].name_2;
          }
          if (languagesArray[passedLocal].name_3) {
            objNutritioValue.name_3 = languagesArray[passedLocal].name_3;
          }

          newArray.push(objNutritioValue);
        } else if (languagesArray[defaultLocal] && languagesArray[defaultLocal].name) {
          if (languagesArray[defaultLocal].name) {
            objNutritioValue.name = languagesArray[defaultLocal].name;
          }
          if (languagesArray[defaultLocal].name_2) {
            objNutritioValue.name_2 = languagesArray[defaultLocal].name_2;
          }
          if (languagesArray[defaultLocal].name_3) {
            objNutritioValue.name_3 = languagesArray[defaultLocal].name_3;
          }
          newArray.push(objNutritioValue);
        } else {
          for (var key in languagesArray) {
            if (languagesArray[key]) {
              if (languagesArray[key].name) {
                objNutritioValue.name = languagesArray[key].name;
              }
              if (languagesArray[key].name_2) {
                objNutritioValue.name_2 = languagesArray[key].name_2;
              }
              if (languagesArray[key].name_3) {
                objNutritioValue.name_3 = languagesArray[key].name_3;
              }

              newArray.push(objNutritioValue);
              break;
            }
            // object[prop]
          }
        }
      }
    }
  });

  return newArray;
}

function* getListStoreInfomationValues(payload) {
  const dataLocal = payload.payload.data;
  let imageFork;
  yield put(updateStoreInfomationLoadedStatus({ data: false }));

  const response = yield call(doRequest, {
    method: 'GET',
    url: 'storeprop/storeprop'
  });

  let selectLocal = 'en_US';
  if (dataLocal) {
    selectLocal = dataLocal.locale ? dataLocal.locale : 'en_US';
  }

  let defaulLocal = 'en_US';
  let accepted_paymentsArr = [];
  let featuresArr = [];
  let mobile_appArr = [];
  let social_mediaArr = [];
  let store_typeArr = [];
  let open_HourArr = [];
  if (response.data.accepted_payments.length > 0) {
    accepted_paymentsArr = yield call(storeInfoArr, response.data.accepted_payments);
  }
  if (response.data.features.length > 0) {
    featuresArr = yield call(storeInfoArr, response.data.features);
  }
  if (response.data.mobile_app.length > 0) {
    mobile_appArr = yield call(storeInfoArr, response.data.mobile_app);
  }
  if (response.data.social_media.length > 0) {
    social_mediaArr = yield call(storeInfoArr, response.data.social_media);
  }
  if (response.data.store_type.length > 0) {
    store_typeArr = yield call(storeInfoArr, response.data.store_type);
  }
  if (response.data.store_type.length > 0) {
    open_HourArr = yield call(storeInfoArr, response.data.store_type);
  }

  let features = response.data.features
    ? getNutriententListValue('features', featuresArr, [], selectLocal, defaulLocal)
    : [];
  let mobileapps = response.data.mobile_app
    ? getNutriententListValue('mobileapps', mobile_appArr, [], selectLocal, defaulLocal)
    : [];
  let socialmedias = response.data.social_media
    ? getNutriententListValue('socialmedias', social_mediaArr, [], selectLocal, defaulLocal)
    : [];
  let acceptedpayments = response.data.accepted_payments
    ? getNutriententListValue(
        'acceptedpayments',
        accepted_paymentsArr,
        [],
        selectLocal,
        defaulLocal
      )
    : [];
  let storetypes = response.data.store_type
    ? getNutriententListValue('storetypes', store_typeArr, [], selectLocal, defaulLocal)
    : [];

  yield put(
    getStoreInformationsValueListSuccess({
      data: {
        features: features,
        mobileapps: mobileapps,
        socialmedias: socialmedias,
        acceptedpayments: acceptedpayments,
        storetypes: storetypes
      }
    })
  );

  // get lable images
  if (response.data.store_type && response.data.store_type.length) {
    imageFork = yield fork(getStoreAllLableimages, response.data.store_type, 'storetypes');
  }
  if (response.data.accepted_payments && response.data.accepted_payments.length) {
    imageFork = yield fork(
      getStoreAllLableimages,
      response.data.accepted_payments,
      'acceptedpayments'
    );
  }
  if (response.data.social_media && response.data.social_media.length) {
    imageFork = yield fork(getStoreAllLableimages, response.data.social_media, 'socialmedias');
  }
  if (response.data.features && response.data.features.length) {
    imageFork = yield fork(getStoreAllLableimages, response.data.features, 'features');
  }
  if (response.data.mobile_app && response.data.mobile_app.length) {
    imageFork = yield fork(getStoreAllLableimages, response.data.mobile_app, 'mobileapps');
  }

  yield put(updateStoreInfomationLoadedStatus({ data: true }));
}

function* addStoreFun({ payload }) {
  let intl = yield select(state => state.intlReact.intl);
  const { data, history } = payload.data;

  if (data && data.data && data.data.non_translate.date) {
    delete data.non_translate.date;
  }

  try {
    const response = yield call(doRequest, {
      method: 'POST',
      url: 'storeres/store',
      data: data
    });

    if (response.status === 200) {
      message.info(
        intl.formatMessage({
          id: 'Generic.ApiMessages.Store.StoreAddSuccess'
        })
      );
    }

    delay(500);
    history.push('/content-management/store');
  } catch (error) {
    console.log(error);
  }
}

function* editStoreFun({ payload }) {
  let intl = yield select(state => state.intlReact.intl);

  const { data, pid, history } = payload.data;

  if (data && data.data.non_translate.date) {
    // api check date format string and it also check date is required true
    delete data.non_translate.date;
  }

  try {
    const response = yield call(doRequest, {
      method: 'PUT',
      url: 'storeres/store/' + pid,
      data: data
    });

    if (response.status === 200) {
      message.info(
        intl.formatMessage({
          id: 'Generic.ApiMessages.Store.StoreEditSuccess'
        })
      );
    }

    let specialDaysArray = [];
    if (data.data.non_translate && data.data.non_translate.specialDays) {
      specialDaysArray = data.data.non_translate.specialDays;
    }
    yield put(getStoreByLocalSuccess({ data: data.data, specialDays: specialDaysArray }));
    yield put(updateSaveButtonStatus());
    delay(1000);
    history.push('/content-management/store');
  } catch (error) {
    console.log(error);
    yield put(updateSaveButtonStatus());
  }
}

function* deleteStoreFun({ payload }) {
  let intl = yield select(state => state.intlReact.intl);

  const { data } = payload;

  let storeList = yield select(s => s.store.storeList);

  try {
    const response = yield call(doRequest, {
      method: 'DELETE',
      url: 'storeres/store/' + data.storeId
    });
    if (response.status === 200) {
      message.info(
        intl.formatMessage({
          id: 'Generic.ApiMessages.Store.StoreDeleteSuccess'
        })
      );
      let newStoreList = storeList.filter(item => item._id !== data.storeId);
      yield put(deleteStoreSuccess({ data: newStoreList }));
    }
  } catch (error) {
    console.log(error);
  }
}

// get All lable images in module
function* getStoreAllLableimages(dataList = [], type) {
  const chunkedList = yield call(chunk, dataList, 5);

  for (let list of chunkedList) {
    const requests = list.map(item =>
      call(getStoreLableimage, item.non_translate._id, item.non_translate.image)
    );

    const responses = yield all(requests);
    const filtertedReponse = responses.filter(response => (response ? true : false));

    const indexes = yield call(getIndexedDistributorIds, type);
    yield delay(3000);
    yield put(setStoreLableImageSuccess({ image: filtertedReponse, indexes, type }));
  }
}

function* getImages(list, type) {
  const requests = list.map(item =>
    call(getStoreLableimage, item.non_translate._id, item.non_translate.image)
  );

  const responses = yield all(requests);
  const indexes = yield call(getIndexedDistributorIds, type);
  const filtertedReponse = responses.filter(response => (response ? true : false));
  yield delay(3000);
  yield put(setStoreLableImageSuccess({ image: filtertedReponse, indexes, type }));
}
// get image array
function chunk(array, size) {
  const chunked_arr = [];
  let index = 0;
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
}

//  get avatar image
function* getStoreLableimage(id, collectionId) {
  if (!collectionId) return;
  try {
    const imageResponse = yield call(doRequest, {
      url: `unimup/objctupload/manuf/${collectionId}`,
      method: 'GET'
    });

    const { data } = imageResponse;

    if (data.pages.length) {
      const imageUrl = data.pages[0].thumbnail.fetchUrl;
      const imageResponse2 = yield call(doRequest, {
        url: `unimup/objctupload${imageUrl}`,
        method: 'GET',
        responseType: 'blob'
      });

      const { data: imageData } = imageResponse2;
      const image = URL.createObjectURL(imageData);
      return { id, image };
    }
  } catch (error) {}
}

/** Watchers */

function* getListWatcher() {
  yield takeLatest(types.GET_LIST_STOREINFORMATION_VALUES, getListStoreInfomationValues);
}

function* getStoreListWatcher() {
  yield takeLatest(types.GET_STORE_LIST, getStoreList);
}

function* addStoreListner() {
  yield takeLatest(types.ADD_STORE, addStoreFun);
}

function* editStoreListner() {
  yield takeLatest(types.EDIT_STORE, editStoreFun);
}

function* deleteStoreListner() {
  yield takeLatest(types.DELETE_STORE, deleteStoreFun);
}

function* getStoreByLocaleWatcher() {
  yield takeLatest(types.GET_STORE_BY_LOCALE, getStoreByLocal);
}

export default function* rootSaga() {
  yield all([
    fork(getListWatcher),
    fork(getStoreListWatcher),
    fork(addStoreListner),
    fork(editStoreListner),
    fork(getStoreByLocaleWatcher),
    fork(deleteStoreListner)
  ]);
}
