import {
  all,
  fork,
  put,
  takeLatest,
  takeEvery,
  select,
  call,
  race,
  take,
  cancelled
} from 'redux-saga/effects';
import arrayMove from 'array-move';
import { delay } from 'redux-saga';

import types from './labels.constants';
import {
  getLabelsListSuccess,
  updateSaveButtonState,
  saveChangesFail,
  saveChangesSuccess,
  addNewNodeSuccess,
  saveNewNodeSuccess,
  updateSortOrderSuccess,
  searchLabelSuccess,
  deleteNodeSuccess,
  updateSaveModal,
  saveChanges,
  getLabelsList,
  setSelectedNodeSuccess,
  saveLableImageSuccess,
  removeLableImageSuccess,
  showLableLoader,
  saveLableImageFail,
  hideLableImgLoader,
  getLableImageSuccess,
  cuntrySelectedListUpdate
} from './labels.actions';

import { message } from 'antd';
import { doRequest } from '../../../../shared/redux/sagas/api';
import ListTypeEnums from '../../nutrition-values/enums/list-types';
import { displaySaveDeleteBtn } from '../../../../shared/redux/actions/imageUploader.actions';

function* getIntlMessage(id) {
  const intl = yield select(({ intlReact: { intl } }) => intl);
  return intl.formatMessage({ id });
}

function* getUserLocale() {
  const [id, languageList] = yield select(s => [s.profile.language, s.languageList.languageList]);
  const language = languageList.find(language => language.id === id);
  return language.locale;
}

function* getList() {
  let intl = yield select(state => state.intlReact.intl);
  try {
    const locale = yield call(getUserLocale);

    const { data } = yield call(doRequest, {
      method: 'GET',
      url: 'prodprop/labels/'
    });
    const stateData = yield call(buildListData, locale, data);

    if (Object.values(stateData).length === 0) {
      stateData[ListTypeEnums.COMPARISON] = [];
    }

    yield put(
      getLabelsListSuccess({
        data: stateData
      })
    );
  } catch (error) {
    console.log({ error });
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Labels.FormSaveError'
      })
    );
  }
}

function buildListData(userLocale, data) {
  const stateData = {};

  const typeSpecificValues = getNodeValues(userLocale, data);

  stateData['comparison'] = typeSpecificValues;

  return stateData;
}

function getNodeValues(userLocale, items) {
  return items.map((item, index) => {
    const node = {};
    node.id = item.id;
    node.position = item.position;
    node.value =
      'L' +
      (item.serial ? item.serial : '') +
      ' - ' +
      getNodeLanguageSpecificName(userLocale, item.languages);
    // node.value =
    //   (item.serial ? item.serial : '') +
    //   ' - ' +
    //   getNodeLanguageSpecificName(userLocale, item.languages);

    return node;
  });
}

function getNodeLanguageSpecificName(userLocale, languages) {
  if (languages) {
    if (languages[userLocale] && languages[userLocale]['name']) {
      return languages[userLocale]['name'];
    }

    if (languages['en_US'] && languages['en_US']['name']) {
      return languages['en_US']['name'];
    }

    for (let locale in languages) {
      if (languages[locale]['name']) {
        return languages[locale]['name'];
      }
    }
  }
}

function* addNode({ payload }) {
  const shouldProceed = yield call(handleChangesBeforeSwitching);

  if (!shouldProceed) return;

  yield put(addNewNodeSuccess());
}

function* saveNode(node) {
  const { newNode, values } = node;
  let intl = yield select(state => state.intlReact.intl);

  let arrValues = values.slice();
  arrValues.shift();

  // remove the temp node and set the values if new node value is empty
  if (!newNode) {
    return yield put(saveNewNodeSuccess({ values: arrValues }));
  }

  const [id, languageList] = yield select(s => [s.profile.language, s.languageList.languageList]);
  const language = languageList.find(language => language.id === id);

  let formData = {};

  formData = {
    translations: {
      [language.locale]: { name: newNode }
    },
    countries: [],
    collectionId: '',
    eligible_food_contain: 0
  };

  try {
    const response = yield call(doRequest, {
      url: 'prodprop/labels',
      method: 'POST',
      data: formData
    });

    const responseData = response.data;
    const newNodeItem = {
      value: 'L' + (responseData['serial'] ? responseData['serial'] + ' - ' : '') + newNode,
      id: responseData['id'],
      position: responseData.position
    };
    arrValues = [newNodeItem, ...arrValues];

    yield put(saveNewNodeSuccess({ values: arrValues, newNode: newNodeItem }));
    yield call(setSelectedNode, newNodeItem.id);
  } catch (error) {
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Labels.NodeSaveError'
      })
    );
    //revert to original values
    return yield put(saveNewNodeSuccess({ values: arrValues }));
  }
}

function* updateOrder({ payload: { values, oldIndex, newIndex } }) {
  let intl = yield select(state => state.intlReact.intl);
  const updatedValues = arrayMove(values, oldIndex, newIndex);
  const id = values[oldIndex]['id'];
  const newPosition = values[newIndex]['position'];
  // update the values to update ui element positions immediately
  yield put(updateSortOrderSuccess({ values: updatedValues }));

  try {
    const response = yield call(doRequest, {
      method: 'PUT',
      url: `prodprop/labels/dragndrop/${encodeURIComponent(id)}`,
      data: {
        new_pos: newPosition
      }
    });
    message.info(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Labels.NodeOrderChangedSuccess'
      })
    );
    yield put(getLabelsList());
  } catch (error) {
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Labels.NodeOrderChangedError'
      })
    );
    yield put(updateSortOrderSuccess({ values: values }));
  }
}

// get selected nod data *****************************
function* setSelectedNode(id) {
  yield put(displaySaveDeleteBtn(true));

  let intl = yield select(state => state.intlReact.intl);
  const btnState = yield select(s => s.labels.saveButtonState);
  if (btnState === 'saving-changes') {
    message.warn(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Labels.NodeSelectedError'
      })
    );
    return;
  }

  const shouldProceed = yield call(handleChangesBeforeSwitching);

  if (!shouldProceed) return;
  yield put(showLableLoader());

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: `prodprop/labels/${encodeURIComponent(id)}`
    });

    const selectedData = response.data || {};

    let newCountryArray = [];
    if (selectedData.countries) {
      if (selectedData.countries.length > 0) {
        selectedData.countries.forEach(element => {
          if (element) {
            if (element.value) {
              newCountryArray.push(element.value);
            } else {
              newCountryArray.push(element);
            }
          }
        });
      }
    }

    selectedData['eligible_food_contain'] = selectedData.eligible_food_contain ? true : false;
    selectedData.countries = newCountryArray;
    const getImageId = selectedData.collectionId;

    if (newCountryArray.length > 0 && newCountryArray.length === 1) {
      newCountryArray.forEach(element => {
        if (element === '0') {
          selectedData['allCountries'] = true;
        } else {
          selectedData['allCountries'] = false;
        }
      });
    } else {
      selectedData['allCountries'] = false;
    }

    yield put(setSelectedNodeSuccess({ id, selectedData }));
    yield put(getLableImageSuccess({ imagedata: [] }));
    if (getImageId) {
      yield call(getLableImageRequest, getImageId);
    } else {
      yield put(hideLableImgLoader());
    }
    yield put(cuntrySelectedListUpdate({ keys: selectedData.countries }));
  } catch (error) {}
}
// get selected nod data *****************************/////////////

/**
 * when to handle
 *  1 - When switching nodes
 *  2 - When user creates a node while has changes
 */
function* handleChangesBeforeSwitching() {
  const btnState = yield select(s => s.labels.saveButtonState);

  if (!(yield call(shouldHandle, btnState))) {
    return true;
  }

  yield put(updateSaveModal({ state: 'show' }));

  const action = yield race({
    saveChanges: take(types.SAVE_CHANGES_AND_SWITCH),
    cancelAndSwitch: take(types.CANCEL_CHANGES_AND_SWITCH),
    cancelAndStay: take(types.CANCEL_CHANGES_AND_STAY)
  });

  if (action.saveChanges) {
    yield put(saveChanges({ saveType: 'switch' }));

    const { saveFailed } = yield race({
      saveSuccess: take(types.SAVE_CHANGES_SUCCESS),
      saveFailed: take(types.SAVE_CHANGES_FAIL)
    });

    if (saveFailed) {
      yield put(updateSaveModal({ state: 'hide' }));
      return false;
    }
  } else if (action.cancelAndStay) {
    yield put(updateSaveModal({ state: 'hide' }));
    return false;
  }

  yield put(updateSaveModal({ state: 'hide' }));
  return true;
}

function shouldHandle(btnState) {
  const btnStateToHandle = ['unsaved', 'unsaved-errors'];
  return btnStateToHandle.includes(btnState);
}

// post data******************************************
function* saveFormChanges(payload) {
  console.log(payload);
  let intl = yield select(state => state.intlReact.intl);
  const formPassed = yield call(getFormData, payload.payload.saveType);
  const [id, nodeData] = yield select(s => [s.labels.selectedNode, s.labels.selectedNodeData]);

  console.log(nodeData);

  const translations = {
    ...formPassed.translations
  };

  console.log(JSON.parse(JSON.stringify(formPassed)));

  const keysToSkip = [
    'position',
    'countries',
    'collectionId',
    'allCountries',
    'eligible_food_contain'
  ];

  for (let [key, value] of Object.entries(nodeData)) {
    if (
      (!keysToSkip.includes(key) && !(key in translations)) ||
      (key in translations && !Object.values(translations[key]).length)
    ) {
      let saveType = payload.payload.saveType;
      if (saveType == 'switch') {
        translations[key] = value;
      } else {
        let formPassedKeyData = formPassed.translations[key];
        console.log(formPassedKeyData);
        if (formPassedKeyData) {
          translations[key] = value;
        }
      }
    }
  }

  formPassed.translations = translations;

  const defaultLocale = yield call(getUserLocale);

  if (formPassed.eligible_food_contain == undefined) {
    formPassed['eligible_food_contain'] = nodeData.eligible_food_contain;
    formPassed['countries'] = nodeData.countries;
    formPassed['collectionId'] = nodeData.collectionId;
  }

  if (formPassed.translations[defaultLocale] && formPassed.translations[defaultLocale].name) {
    try {
      yield put(updateSaveButtonState({ btnState: 'saving-changes' }));

      yield call(doRequest, {
        url: `prodprop/labels/${id}`,
        method: 'PUT',
        data: formPassed
      });

      let form = {
        collectionId: formPassed.collectionId,
        countries: formPassed.countries,
        eligible_food_contain: formPassed.eligible_food_contain ? true : false
      };

      if (formPassed.translations) {
        for (let key in formPassed.translations) {
          form[key] = formPassed.translations[key];
        }
      }

      yield put(getLabelsList());
      yield take(types.GET_LIST_SUCCESS);

      if (form.countries.length > 0 && form.countries.length === 1) {
        form.countries.forEach(element => {
          if (element === '0') {
            form['allCountries'] = true;
          } else {
            form['allCountries'] = false;
          }
        });
      } else {
        form['allCountries'] = false;
      }
      yield put(saveChangesSuccess({ form }));
    } catch (error) {
      message.error(
        intl.formatMessage({
          id: 'Generic.ApiMessages.Labels.FormSaveError'
        })
      );
      yield put(saveChangesFail());
      yield put(updateSaveButtonState({ btnState: 'unsaved' }));
    }
  } else {
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Labels.FormSaveError'
      })
    );
    yield put(saveChangesFail());
    yield put(updateSaveButtonState({ btnState: 'unsaved' }));
  }
}

function* getFormData(saveType) {
  const [formData] = yield select(s => [s.labels.formData]);
  const collectId = yield select(s => s.labels.collectionId);

  const formattedFormData = yield call(formatData, formData, collectId, saveType);

  const dataToSend = {
    ...formattedFormData
  };

  return dataToSend;
}

function formatData(formData, collectId, saveType) {
  let lableObj = {};
  const data = {};
  let countryKey = [];
  let eligible_food_contain = 0;
  for (let key in formData) {
    const langData = formData[key];

    const newLangData = {};

    //skip if the name is empty
    if (
      !langData ||
      (!langData['name']['value'] &&
        !langData['textAboutLabel']['value'] &&
        !langData['links']['value'])
    ) {
      continue;
    }

    for (let name in langData) {
      if (typeof langData[name]['value'] !== 'undefined') {
        newLangData[name] = langData[name]['value'];
      }
    }

    if (newLangData.allCountries) {
      countryKey.push('0');
    }
    if (newLangData.eligible_food_contain) {
      eligible_food_contain = newLangData.eligible_food_contain ? 1 : 0;
    }

    let newCountryArray = [];
    if (newLangData.countryFilter && newLangData.countryFilter !== 'undefined') {
      if (Array.isArray(newLangData.countryFilter)) {
        if (newLangData.countryFilter.length > 0) {
          newLangData.countryFilter.forEach(element => {
            if (element) {
              if (element.value) {
                newCountryArray.push(element.value);
              }
            }
          });
          countryKey = newCountryArray;
        }
      } else {
        const selectedval = newLangData.countryFilter.split(',');
        countryKey = selectedval;
      }
    }

    newLangData.countryFilter = countryKey;

    for (let obj in newLangData) {
      const translationObj = {
        name: newLangData.name ? newLangData.name : '',
        textAboutLabel: newLangData.textAboutLabel ? newLangData.textAboutLabel : '',
        links: newLangData.links ? newLangData.links : ''
      };
      if (obj) {
        data[key] = translationObj;
      }
    }

    lableObj = {
      translations: data,
      countries: countryKey,
      collectionId: collectId,
      eligible_food_contain: eligible_food_contain
    };
  }

  return lableObj;
}

function* search(action) {
  const key = action.payload.key.toLowerCase();

  if (!key) {
    return yield put(searchLabelSuccess({ data: null }));
  }

  const [comparison] = yield select(({ labels: { originalData: _ } }) => [_.comparison]);

  const [comparisonResult] = yield all([call(individualSearch, key, comparison)]);

  yield put(
    searchLabelSuccess({
      data: {
        comparison: comparisonResult
      }
    })
  );
}

function individualSearch(key, items) {
  if (!items) return [];
  return items.filter(item =>
    item && item.value ? item.value.toLowerCase().includes(key) : false
  );
}

function* deleteNode(action) {
  const { id } = action.payload;
  let intl = yield select(state => state.intlReact.intl);

  try {
    const response = yield call(doRequest, {
      url: `prodprop/labels/${encodeURIComponent(id)}`,
      method: 'DELETE'
    });
    yield put(deleteNodeSuccess({ id }));
  } catch (error) {
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Labels.NodeDeleteError'
      })
    );
  }
}

function* saveImage() {
  yield put(showLableLoader());

  try {
    const id = yield select(state => state.labels.selectedNode);
    const image = yield select(state => state.labels.image);
    const imageBinary = yield select(state => state.labels.imageBinary);
    const collectionId = yield select(state => state.labels.collectionId);

    // if a previous image is uploaded
    // delete it and then upload new one
    if (collectionId) {
      yield call(doRequest, {
        url: `unimup/objctupload/prodlabel/${collectionId}`,
        method: 'DELETE'
      });
    }

    const formData = new FormData();
    formData.append('media', imageBinary);

    const response = yield call(doRequest, {
      url: `unimup/objctupload/prodlabel/${id}`,
      method: 'POST',
      data: formData
    });

    const imgObj = {
      collectionId: response.data.CollectionId
    };
    yield call(doRequest, {
      url: `prodprop/labels/${id}`,
      method: 'PATCH',
      data: imgObj
    });

    const newCollectionId = response.data.CollectionId;
    yield put(saveLableImageSuccess({ img: image, item: newCollectionId }));

    message.info(yield getIntlMessage('Generic.ApiMessages.Labels.ImageUploadSuccess'));
  } catch (error) {
    console.log({ error });

    message.error(yield getIntlMessage('Generic.ApiMessages.Labels.ImageUploadError'));
    yield put(saveLableImageFail());
  } finally {
    yield put(hideLableImgLoader());
  }
}

function* deleteImage() {
  try {
    const collectionId = yield select(state => state.labels.collectionId);
    const id = yield select(state => state.labels.selectedNode);

    if (collectionId) {
      yield call(doRequest, {
        url: `unimup/objctupload/prodlabel/${collectionId}`,
        method: 'DELETE'
      });

      const imgObj = {
        collectionId: ''
      };
      yield call(doRequest, {
        url: `prodprop/labels/${id}`,
        method: 'PATCH',
        data: imgObj
      });

      yield put(removeLableImageSuccess());
      message.info(yield getIntlMessage('Generic.ApiMessages.Labels.ImageDeleteSuccess'));
    }
  } catch (error) {
    console.log({ error });
    message.error(yield getIntlMessage('Generic.ApiMessages.Labels.ImageDeleteError'));
  }
}

function* getLableImageRequest(id) {
  let fileList = [];
  let tempObj = {};
  let response;
  let response1;

  try {
    if (id && id != '') {
      response = yield call(doRequest, {
        url: `unimup/objctupload/prodlabel/${id}`,
        method: 'GET'
      });

      let imageUrls = imageList(response.data.pages);

      for (var i = 0; i < imageUrls.length; i++) {
        tempObj = {};
        response1 = yield call(doRequest, {
          method: 'GET',
          url: `/unimup/objctupload${imageUrls[i].tempUrl}`,
          responseType: 'blob'
        });

        let image = URL.createObjectURL(response1.data);
        tempObj = {
          uid: `${imageUrls[i].pageId}`,
          url: image,
          active: imageUrls[i].active,
          position: `${i}`,
          type: response1.data ? response1.data.type : ''
        };
        fileList.push(tempObj);
      }
    }

    if (id === '') {
      fileList = [];
    }
    if (response1.status === 200) {
      yield put(getLableImageSuccess({ imagedata: fileList }));
    }
  } catch (error) {
    return false;
  } finally {
    yield put(hideLableImgLoader());
  }
}

function imageList(array) {
  let newArr = [];
  let tempObj = {};
  if (array) {
    for (var i = 0; i < array.length; i++) {
      tempObj = {};

      tempObj = {
        tempUrl: array[i].optimized.fetchUrl,
        pageId: array[i].pageId,
        active: array[i].active
      };
      newArr.push(tempObj);
    }
  }

  return newArr;
}
/** Watchers */

function* getListWatcher() {
  yield takeLatest(types.GET_LIST, getList);
}
function* saveImageWatcher() {
  yield takeLatest(types.SAVE_IMG, saveImage);
}

function* deleteImageWatcher() {
  yield takeLatest(types.REMOVE_IMG, deleteImage);
}

function* saveNodeLabelWatcher() {
  while (true) {
    const action = yield take(types.SAVE_NODE);
    yield call(saveNode, action.payload);
  }
}

function* updateOrderLabelWatcher() {
  yield takeEvery(types.UPDATE_ORDER, updateOrder);
}

function* setSelectedNodeLabelWatcher() {
  yield takeLatest(types.SET_SELECTED_NODE, ({ payload: { id, type } }) =>
    setSelectedNode(id, type)
  );
}

function* saveFormChangesLabelWatcher() {
  yield takeLatest(types.SAVE_CHANGES, saveFormChanges);
}

function* searchLabelWatcher() {
  yield takeLatest(types.SEARCH, search);
}

function* deleteNodeLabelWatcher() {
  yield takeLatest(types.DELETE_NODE, deleteNode);
}

function* addNodeLabelWatcher() {
  while (true) {
    const action = yield take(types.ADD_NODE);
    yield call(addNode, action);
  }
}

export default function* rootSaga() {
  yield all([
    fork(getListWatcher),
    fork(addNodeLabelWatcher),
    fork(saveNodeLabelWatcher),
    fork(updateOrderLabelWatcher),
    fork(setSelectedNodeLabelWatcher),
    fork(saveFormChangesLabelWatcher),
    fork(searchLabelWatcher),
    fork(deleteNodeLabelWatcher),
    fork(saveImageWatcher),
    fork(deleteImageWatcher)
  ]);
}
