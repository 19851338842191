import * as profileActions from 'app/module/profile/redux/profile.constants';
import { GET_USER_TRANSLATIONS, GET_USER_TRANSLATIONS_SUCCESS } from '../actions/locales.actions';

export default (state = { profile: false, locales: true }, action) => {
  switch (action.type) {
    case profileActions.CHANGE_PASSWORD_UPDATE_SUCCESS: {
      return {
        ...state,
        profile: false
      };
    }
    case profileActions.CHANGE_PASSWORD_UPDATE: {
      return {
        ...state,
        profile: true
      };
    }
    case profileActions.SHOW_MESSAGE: {
      return {
        ...state,
        profile: false
      };
    }

    case GET_USER_TRANSLATIONS: {
      return {
        ...state,
        locales: true
      };
    }

    case GET_USER_TRANSLATIONS_SUCCESS: {
      return {
        ...state,
        locales: false
      };
    }

    default: {
      return state;
    }
  }
};
