import moderationTypes from './details.constants';
import setdefaultDataSort from '../../../../../shared/utils/setdefaultDataSort';

const initialState = {
  formData: null,
  productData: null,
  productList: [],
  nutritionalValueList: {
    comparison: [],
    energy: [],
    nutritionalValue: [],
    vitamins: [],
    minerals: [],
    other: [],
    commonSupplements: []
  },
  mayContainList: [],
  isProductDetailsLoaded: false,
  storesTypeArray: [],
  categoryArray: [],
  store: '',
  ingredientsArray: [],
  eNumbersArray: [],
  isSaveModalOpen: false,
  ingredientLoader: true,
  categoryLoader: true,
  nutritionLoaded: true,
  saveButtonClick: false,
  productLocale: '',
  mayContainLoader: true,
  labelsList: [],
  filterdLabelList: [],
  labelsLoader: true,
  initialUnit: '',
  tagListLocal: 'en_US',
  priceData: null,
  currancyValue: '',
  productId: null,
  disabledPriceFeild: true,
  editAddModerationData: null,
  oldProductData: null,
  hasChangesArray: [],
  droupdownLocale: '',
  rowData: null,
  getEditObj: null,
  storesArray: [],
  tagsArray: [],
  manufactureArray: [],
  distributorArray: [],
  bracketText: null,
  brandArray: [],
  storeObj: null,
  storeObjOld: null,
  ticketCollectionId: '',
  productCollectionId: '',
  nutritionInputlValuesArray: {
    nutritionValueInput: {},
    vitaminsInput: {},
    mineralsInput: {},
    otherInput: {},
    commonSupplementsInput: {}
  },
  ticketSaveLoader: false,
  productUrl: ''
};
export default (state = initialState, action) => {
  switch (action.type) {
    case moderationTypes.GET_DATA_LIST: {
      return {
        ...state
      };
    }

    case moderationTypes.UPDATE_CATEGORY_LOADER: {
      return {
        ...state,
        categoryLoader: false,
        saveButtonClick: false
      };
    }

    case moderationTypes.GET_INGREDIENT_LIST_SUCCESS: {
      const { data } = action.payload;

      let passedArray = data;
      let sortedArary = setdefaultDataSort(passedArray, 'title');
      return {
        ...state,
        ingredientsArray: sortedArary
      };
    }

    case moderationTypes.GET_STORE_TYPE_LIST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        storesTypeArray: data
      };
    }

    case moderationTypes.GET_ENUMBER_LIST_SUCCESS: {
      const { data } = action.payload;
      let passedArray = data;
      let sortedArary = setdefaultDataSort(passedArray, 'title');

      return {
        ...state,
        eNumbersArray: sortedArary
      };
    }

    case moderationTypes.UPDATE_INGREDIENT_LOADER: {
      const { loader } = action.payload;

      return {
        ...state,
        ingredientLoader: loader
      };
    }

    case moderationTypes.GET_PRODUCT_BY_LOCALE: {
      return {
        ...state,
        ingredientLoader: true
      };
    }

    case moderationTypes.GET_NUTRITION_VALUE_LIST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        nutritionalValueList: {
          ...data
        }
      };
    }

    case moderationTypes.GET_MAY_CONTAIN_VALUES_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        mayContainList: data
      };
    }

    case moderationTypes.MAY_CONTAINT_LOADER: {
      const { loader } = action.payload;
      return {
        ...state,
        mayContainLoader: loader
      };
    }

    case moderationTypes.GET_LABEL_LIST_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        labelsList: data
      };
    }

    case moderationTypes.SET_FILTER_LABEL_LIST: {
      const { data } = action.payload;

      let passedArray = data;
      let sortedArary = setdefaultDataSort(passedArray, 'title');

      return {
        ...state,
        filterdLabelList: sortedArary
      };
    }

    case moderationTypes.LABEL_LIST_LOADER: {
      const { loader } = action.payload;
      return {
        ...state,
        labelsLoader: loader
      };
    }

    case moderationTypes.UPDATE_NUTRITION_LOADED_STATUS: {
      const { data } = action.payload;

      return {
        ...state,
        nutritionLoaded: data
      };
    }

    case moderationTypes.GET_PRODUCT_LIST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        productList: data
      };
    }

    case moderationTypes.GET_PRODUCT_BY_LOCALE_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        productData: data
      };
    }
    case moderationTypes.GET_PRODUCT_ID: {
      const { id } = action.payload;
      return {
        ...state,
        productId: id
      };
    }

    case moderationTypes.ADD_PRODUCT: {
      return {
        ...state,
        saveButtonClick: true
      };
    }

    case moderationTypes.EDIT_PRODUCT: {
      return {
        ...state,
        saveButtonClick: true
      };
    }

    case moderationTypes.CURRANCY_OBJ_UPDATE: {
      const { currancy } = action.payload;
      return {
        ...state,
        currancyValue: currancy
      };
    }

    case moderationTypes.DISABLED_PRICE_INPUT: {
      const { value } = action.payload;

      return {
        ...state,
        disabledPriceFeild: value
      };
    }

    case moderationTypes.UPDATE_SAVE_BUTTON_STATUS: {
      return {
        ...state,
        saveButtonClick: false
      };
    }

    case moderationTypes.UPDATE_PRODUCT_DETAIL_LOAD_STATUS: {
      const { data } = action.payload;

      return {
        ...state,
        isProductDetailsLoaded: data
      };
    }

    case moderationTypes.UPDATE_PRODUCT_LOCALE: {
      const { data } = action.payload;

      return {
        ...state,
        productLocale: data
      };
    }

    case moderationTypes.RESET: {
      return {
        ...state,
        ...initialState,
        nutritionLoaded: false
        // ingredientsArray: [],
        // eNumbersArray: [],
        // storesArray: [],
        // labelsList: [],
        // filterdLabelList: [],
        // saveButtonClick: false,
        // ingredientLoader: true,
        // productData: null,
        // productId: null,
        // priceData: null,
        // bracketText: null,

        // editAddModerationData: null,
        // oldProductData: null,
        // hasChangesArray: [],
        // droupdownLocale: '',
        // rowData: null,
        // getEditObj: null,
        // tagsArray: [],
        // manufactureArray: [],
        // distributorArray: [],
        // brandArray: [],
        // storeObj: null,
        // ticketCollectionId: '',
        // productCollectionId: ''
      };
    }

    case moderationTypes.GET_DEFAULT_UNIT: {
      const { unit } = action.payload;

      return {
        ...state,
        categoryArray: [],
        initialUnit: unit
      };
    }

    case moderationTypes.SET_STORE_TYPE_IMAGE: {
      const {
        payload: { image, indexes }
      } = action;

      // let OriginalData = JSON.parse(JSON.stringify(state.storesTypeArray));
      let data = JSON.parse(JSON.stringify(state.storesTypeArray));

      if (image && image.length !== 0) {
        for (let distributorImage of image) {
          const itemIndex = indexes[distributorImage.id];

          if (itemIndex || itemIndex === 0) {
            const item = data[itemIndex];

            const clonedItem = JSON.parse(JSON.stringify(item));

            if (clonedItem && clonedItem._id) {
              clonedItem['imageUrl'] = distributorImage.image;
              data = [...data.slice(0, itemIndex), clonedItem, ...data.slice(itemIndex + 1)];
            }
          }
        }
      }

      return {
        ...state,
        storesTypeArray: [...data]
      };
    }

    // Ingredient Popup
    case moderationTypes.GET_ROW_SELECTED_LOCALE: {
      const { locale, record, getEditObj } = action.payload;
      let cusStore = null;
      if (getEditObj && getEditObj.store) {
        cusStore = {
          id: getEditObj.store._id,
          title: getEditObj.store.store_name,
          branch: getEditObj.store.branch_name,
          mediaCollectionId: getEditObj.store.mediaCollectionId
        };
      }

      // getEditObj same as editAddModerationData
      return {
        ...state,
        droupdownLocale: locale,
        rowData: record,
        getEditObj: getEditObj,
        editAddModerationData: getEditObj ? { ...getEditObj } : getEditObj,
        storeObj: cusStore
      };
    }

    case moderationTypes.GET_SINGLE_DATA_SUCCESS: {
      const { data, droupdownLocale } = action.payload;

      let cusStore = null;
      if (data && data.store) {
        cusStore = {
          id: data.store._id,
          title: data.store.store_name,
          branch: data.store.branch_name,
          mediaCollectionId: data.store.mediaCollectionId
        };
      }

      let ticketMediaCollectionId = '';
      if (data && data.non_translate && data.non_translate.mediaCollectionId) {
        ticketMediaCollectionId = data.non_translate.mediaCollectionId;
      }

      return {
        ...state,
        editAddModerationData: data ? { ...data } : data,
        storeObj: cusStore,
        droupdownLocale: droupdownLocale,
        ticketCollectionId: ticketMediaCollectionId
      };
    }
    case moderationTypes.GET_OLD_SINGLE_PRODUCT_DATA_SUCCESS: {
      const { data } = action.payload;

      let productMediaCollectionId = '';
      if (data && data.non_translate && data.non_translate.mediaCollectionId) {
        productMediaCollectionId = data.non_translate.mediaCollectionId;
      }

      // if (getEditObj && getEditObj.store) {
      //   cusStore = {
      //     id: getEditObj.store._id,
      //     title: getEditObj.store.store_name,
      //     branch: getEditObj.store.branch_name,
      //     mediaCollectionId: getEditObj.store.mediaCollectionId
      //   };
      // }

      return {
        ...state,
        oldProductData: data ? { ...data } : data,
        productCollectionId: productMediaCollectionId
      };
    }

    case moderationTypes.GET_OLD_STORE_PRICE_DATA_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        storeObjOld: data
      };
    }

    case moderationTypes.SET_FORMCHANGES_ARRAY: {
      const { data } = action.payload;

      return {
        ...state,
        hasChangesArray: data
      };
    }

    case moderationTypes.GET_MANUFACTURE_LIST_SUCCESS: {
      const { data } = action.payload;
      let sortedArary = setdefaultDataSort(data, 'manufacture_name');
      return {
        ...state,
        manufactureArray: sortedArary
      };
    }

    case moderationTypes.GET_BRAND_LIST_SUCCESS: {
      const { data } = action.payload;
      let sortedArary = setdefaultDataSort(data, 'brand_name');
      return {
        ...state,
        brandArray: sortedArary
      };
    }

    case moderationTypes.GET_DISTRIBUTOR_LIST_SUCCESS: {
      const { data } = action.payload;
      let sortedArary = setdefaultDataSort(data, 'distributors_name');
      return {
        ...state,
        distributorArray: sortedArary
      };
    }

    case moderationTypes.GET_STORE_LIST_SUCCESS: {
      const { data } = action.payload;

      let passedArray = data;

      let sortedArary = setdefaultDataSort(passedArray, 'branch');
      let newSortArray = setdefaultDataSort(sortedArary, 'title');

      newSortArray.splice(0, 0, {
        id: '0000',
        title: 'None',
        branch: '',
        mediaCollectionId: '',
        imageUrl: ''
      });

      return {
        ...state,
        storesArray: newSortArray
      };
    }

    case moderationTypes.SET_STORE_IMAGE: {
      const { images, indexes } = action.payload;

      if (!images || !images.length) {
        return state;
      }

      let storeList = JSON.parse(JSON.stringify(state.storesArray));

      for (let storeImage of images) {
        const storeIndex = storeList.findIndex(item => item.id === storeImage.id);

        if (storeIndex || storeIndex === 0) {
          const store = JSON.parse(JSON.stringify(storeList[storeIndex]));

          if (store && store.id) {
            store['imageUrl'] = storeImage.image;
            storeList = [
              ...storeList.slice(0, storeIndex),
              store,
              ...storeList.slice(storeIndex + 1)
            ];
          }
        }
      }

      return {
        ...state,
        storesArray: storeList
      };
    }

    case moderationTypes.GET_TIKET_SELECTED_STORE_IMAGE_SUCCESS: {
      const { image, id } = action.payload;
      let storeObjWithImg = state.storeObj;
      if (storeObjWithImg && storeObjWithImg.id === id) {
        storeObjWithImg['imageUrl'] = image;
      }

      return {
        ...state
      };
    }

    case moderationTypes.GET_TAG_LIST_SUCCESS: {
      const { data, locale } = action.payload;

      let passedArray = data;
      let sortedArary = setdefaultDataSort(passedArray, 'title');

      return {
        ...state,
        tagsArray: sortedArary,
        tagListLocal: locale
      };
    }

    case moderationTypes.SET_TAG_IMAGE: {
      const {
        payload: { image }
      } = action;

      let data = JSON.parse(JSON.stringify(state.tagsArray));

      if (image && image.length !== 0) {
        for (let tagsImage of image) {
          const itemIndex = data.findIndex(item => item._id === tagsImage.id);

          if (itemIndex || itemIndex === 0) {
            const item = data[itemIndex];

            const clonedItem = JSON.parse(JSON.stringify(item));

            if (clonedItem && clonedItem._id) {
              clonedItem['imageUrl'] = tagsImage.image;
              data = [...data.slice(0, itemIndex), clonedItem, ...data.slice(itemIndex + 1)];
            }
          }
        }
      }
      let passedArray = [...data];
      let sortedArary = setdefaultDataSort(passedArray, 'title');

      return {
        ...state,
        tagsArray: sortedArary
      };
    }

    case moderationTypes.GET_CATEGORY_LIST_SUCCESS: {
      const { data } = action.payload;

      let passedArray = data;
      let sortedArary = setdefaultDataSort(passedArray, 'title');
      return {
        ...state,
        categoryArray: sortedArary
      };
    }

    case moderationTypes.GET_BRACKET_PRICE_SUCCESS: {
      const { value, pid } = action.payload;

      return {
        ...state,
        bracketText: value,
        productUrl: pid
      };
    }

    case moderationTypes.GET_STORE_UNIC_PRODUCT_PRICE_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        priceData: data
      };
    }
    case moderationTypes.SET_TICKET_SAVE_LOADER: {
      const { data } = action.payload;

      return {
        ...state,
        ticketSaveLoader: data
      };
    }
    case moderationTypes.SET_NUTRITION_INPUT_VALUES: {
      const { data, type } = action.payload;
      let tempObj = { ...state.nutritionInputlValuesArray };

      if (type === 'commonSupplements') {
        tempObj.commonSupplementsInput = { ...tempObj.commonSupplementsInput, ...data };
      } else if (type === 'other') {
        tempObj.otherInput = { ...tempObj.otherInput, ...data };
      } else if (type === 'minerals') {
        tempObj.mineralsInput = { ...tempObj.mineralsInput, ...data };
      } else if (type === 'vitamins') {
        tempObj.vitaminsInput = { ...tempObj.vitaminsInput, ...data };
      } else if (type === 'nutritionValue') {
        tempObj.nutritionValueInput = { ...tempObj.nutritionValueInput, ...data };
      }
      return {
        ...state,
        nutritionInputlValuesArray: tempObj
      };
    }
    case moderationTypes.UPDATE_PRODUCT_DATA_INGREDIENT_ONLY: {
      const { data } = action.payload;
      let emptyObj = { ...state.productData };
      if (emptyObj.non_translate) {
        emptyObj = { ...emptyObj, non_translate: { ...emptyObj.non_translate, ingeredent: data } };
      }

      return {
        ...state,
        // productData: { ...state.productData, ingredient: data }
        productData: { ...emptyObj }
      };
    }
    default:
      return state;
  }
};
