import { createTypes, async } from 'redux-action-creator';

const types = createTypes(
  [
    ...async('GET_LIST'),
    ...async('SAVE_NODE'),
    ...async('UPDATE_ORDER'),
    ...async('SET_SELECTED_NODE'),
    ...async('SAVE_CHANGES'),
    ...async('SEARCH'),
    ...async('DELETE_NODE'),
    ...async('SAVE_IMG'),
    ...async('REMOVE_IMG'),
    ...async('GET_IMG'),
    'ADD_NODE',
    'ADD_NODE_SUCCESS',
    'UPDATE_BUTTON_STATE',
    'UPDATE_FORMDATA',
    'UPDATE_SAVE_MODAL',
    'SAVE_CHANGES_AND_SWITCH',
    'CANCEL_CHANGES_AND_SWITCH',
    'CANCEL_CHANGES_AND_STAY',
    'RESET_STORE_STATE',
    'COUNTRY_SELECTED_LIST',
    'DISABLED_WITH_ALL_COUNTRY',
    'SET_TEMP_IMG',
    'REMOVE_TEMP_IMG',
    'SHOW_LOADER',
    'HIDE_LOADER'
  ],
  'STORE'
);

export default types;
