export function getEnvironmentVariable(value) {
  var environment;
  var data = {};
  environment = window.location.hostname;
  //console.log(environment);
  switch (environment) {
    case 'vg.qa.vegofy.com':
      data = {
        apiUrl: 'https://vg.qa.vegofy.com:8020/'
      };
      break;

    case 'vg.staging.vegofy.com':
      data = {
        apiUrl: 'https://vg.staging.vegofy.com:8020/'
      };
      break;

    case '142.93.239.78':
      data = {
        apiUrl: 'http://142.93.239.78:8021/'
      };
      break;

    default:
      data = {
        // apiUrl: 'http://vg.fe.react.development.vegofy.com:8020/'
        apiUrl: 'https://vg.qa.vegofy.com:8020/'
        //apiUrl: 'https://vg.staging.vegofy.com:8020/'
      };
  }
  return data[value];
}
