import { createTypes, async } from 'redux-action-creator';

const types = createTypes(
  [
    ...async('GET_DATA_LIST'),
    ...async('DELETE'),
    ...async('GET_SEARCH_SUGGESTION'),
    'GET_DATA_LIST_FAIL',
    'SET_SEARCH_LOADER',
    'ADD_TAG',
    'ADD_TAG_SUCCESS',
    'DELETE_TAG',
    'DELETE_TAG_SUCCESS',
    'SET_BRAND_IMG',
    'SET_SEARCH_IDS',
    'RESET'
  ],
  'CATALOGUE_BRAND'
);

export default types;
