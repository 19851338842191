export const BASIC_INFO_UPDATE = 'BASIC_INFO_UPDATE';
export const BASIC_INFO_UPDATE_SUCCESS = 'BASIC_INFO_UPDATE_SUCCESS';
export const BASIC_INFO_UPDATE_FAILED = 'BASIC_INFO_UPDATE_FAILED';
export const CHANGE_PASSWORD_UPDATE = 'CHANGE_PASSWORD_UPDATE';
export const CHANGE_PASSWORD_UPDATE_SUCCESS = 'CHANGE_PASSWORD_UPDATE_SUCCESS';
export const CHANGE_PASSWORD_UPDATE_FAILED = 'CHANGE_PASSWORD_UPDATE_FAILED';
export const PROFILE_IMAGE_UPDATE = 'PROFILE_IMAGE_UPDATE';
export const PROFILE_IMAGE_UPDATE_SUCCESS = 'PROFILE_IMAGE_UPDATE_SUCCESS';
export const PROFILE_IMAGE_UPDATE_FAILURE = 'PROFILE_IMAGE_UPDATE_FAILURE';
export const BASIC_SETTINGS_UPDATE = 'BASIC_SETTINGS_UPDATE';
export const BASIC_SETTINGS_UPDATE_SUCCESS = 'PBASIC_SETTINGS_UPDATE_SUCCESS';
export const BASIC_SETTINGS_UPDATE_FAILURE = 'BASIC_SETTINGS_UPDATE_FAILURE';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const PROFILE_IMAGE_GET = 'PROFILE_IMAGE_GET';
export const PROFILE_IMAGE_GET_SUCCESS = 'PROFILE_IMAGE_GET_SUCESS';
export const PROFILE_IMAGE_GET_ERROR = 'PROFILE_IMAGE_GET_ERROR';
export const PROFILE_INFO_GET = 'PROFILE_INFO_GET';
export const PROFILE_INFO_GET_SUCCESS = 'PROFILE_INFO_GET_SUCCESS';
export const PROFILE_INFO_GET_ERROR = 'PROFILE_INFO_GET_ERROR';
export const RESET_PROFILE_TO_INITIAL = 'RESET_PROFILE_TO_INITIAL';
