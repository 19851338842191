import * as types from './newIngredient.constants';

const initialState = {
  newIngredients: [],
  newIngredient: {},
  countries: [],
  pagination: { total: 0, current: 1, pageSize: 50 },
  whitelistCountries: [],
  blacklistCountries: [],
  locales: [],
  defaultLocale: {},
  selectedValue: {},
  imageUrl: '',
  imageLoader: false,
  originalImage: null,
  defaultUserLocale: '',
  updatedImageUrl: '',
  ingredientName: ''
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_NEW_INGREDIENTS_SUCCESS: {
      return {
        ...state,
        newIngredients: [...payload.data],
        pagination: payload.config
      };
    }

    case types.GET_NEW_INGREDIENT_SUCCESS: {
      return {
        ...state,
        newIngredient: payload
      };
    }

    case types.CLEAR_NEW_INGREDIENT: {
      return {
        ...state,
        newIngredient: {}
      };
    }
    case types.GET_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: payload
      };
    }
    case types.ADD_TO_WHITELIST_COUNTRIES: {
      return {
        ...state,
        whitelistCountries: [...state.whitelistCountries, payload]
      };
    }
    case types.REMOVE_FROM_WHITELIST_COUNTRIES: {
      const updatedData = state.whitelistCountries.filter(item => item.id !== payload);

      return {
        ...state,
        whitelistCountries: [...updatedData]
      };
    }
    case types.CLEAR_WHITELIST_COUNTRIES: {
      return {
        ...state,
        whitelistCountries: []
      };
    }
    case types.ADD_TO_BLACKLIST_COUNTRIES: {
      return {
        ...state,
        blacklistCountries: [...state.blacklistCountries, payload]
      };
    }
    case types.REMOVE_FROM_BLACKLIST_COUNTRIES: {
      const updatedData = state.blacklistCountries.filter(item => item.id !== payload);

      return {
        ...state,
        blacklistCountries: [...updatedData]
      };
    }
    case types.CLEAR_BLACKLIST_COUNTRIES: {
      return {
        ...state,
        blacklistCountries: []
      };
    }
    case types.SET_WHITELIST_COUNTRIES: {
      return {
        ...state,
        whitelistCountries: [...payload]
      };
    }
    case types.SET_BLACKLIST_COUNTRIES: {
      return {
        ...state,
        blacklistCountries: [...payload]
      };
    }
    case types.RESET_PAGINATION: {
      return {
        ...state,
        pagination: {
          total: state.pagination.total,
          current: 1,
          pageSize: state.pagination.pageSize
        }
      };
    }
    case types.SET_LOCALE_LIST: {
      return {
        ...state,
        locales: payload.filteredData,
        defaultLocale: payload.defaultLanguage,
        defaultUserLocale: payload.defaultUserLocale
      };
    }
    case types.SET_SELECTED_VALUES: {
      return {
        ...state,
        selectedValue: payload
      };
    }
    case types.GET_IMAGE_SUCCESS: {
      return {
        ...state,
        imageUrl: payload,
        imageLoader: false
      };
    }
    case types.GET_IMAGE_FAIL: {
      return {
        ...state,
        imageLoader: false
      };
    }
    case types.SET_IMAGE_LOADER: {
      return {
        ...state,
        imageLoader: true
      };
    }
    case types.RESET_IMAGE_LOADER: {
      return {
        ...state,
        imageLoader: false
      };
    }
    case types.ADD_TEMP_IMAGE: {
      return {
        ...state,
        imageUrl: payload,
        originalImage: state.imageUrl,
        imageLoader: false
      };
    }
    case types.REMOVE_TEMP_IMAGE: {
      return {
        ...state,
        imageUrl: state.originalImage,
        originalImage: '',
        imageLoader: false
      };
    }
    case types.SET_UPDATED_IMAGE_URL: {
      return {
        ...state,
        updatedImageUrl: payload,
        originalImage: ''
      };
    }
    case types.CLEAR_IMAGES: {
      return {
        ...state,
        whitelistCountries: [],
        blacklistCountries: [],
        locales: [],
        defaultLocale: {},
        selectedValue: {},
        imageUrl: '',
        imageLoader: false,
        originalImage: null,
        defaultUserLocale: '',
        updatedImageUrl: null
      };
    }
    case types.DELETE_IMAGE_SUCCESS: {
      return {
        ...state,
        imageUrl: '',
        originalImage: '',
        updatedImageUrl: null
      };
    }
    case types.SET_NEW_INGREDIENT_NAME: {
      return {
        ...state,
        ingredientName: payload
      };
    }
    default:
      return state;
  }
};
