import { ingredientTypes } from './ingredientModel.constants';

const initialState = {
  list: [],
  id: '',
  isIngredientModalOpen: false,
  ingredientEditForm: null,
  isEditingIngredientForm: false,
  loading: false,
  imageUrl: null,
  imageFormData: null,
  imageLoader: null,
  originalImage: null,
  imageBinary: null,
  collectionId: null,
  initialTitle: '',
  initialIngredient: '',
  changeFormValues: null,
  hasFormChanges: false,
  imageDeleted: false,
  showIconButtonStore: true,
  alternatives: {},
  duplicateTitles: [],
  IngredientTagVeganDetect: {},
  subLangLocale: ''
};

export default function(state = initialState, action) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      if (action.payload.pathname.includes('/content-management/products')) {
        return initialState;
      } else {
        return state;
      }
    }

    case ingredientTypes.GET_LIST_SUCCESS: {
      return {
        ...state,
        list: action.payload.data
      };
    }

    case ingredientTypes.UPDATE_MODAL_STATE: {
      const { state: modalState } = action.payload;
      if (modalState === 'open') {
        return {
          ...state,
          isIngredientModalOpen: true
        };
      } else {
        return {
          ...state,
          isIngredientModalOpen: false,
          isEditingIngredientForm: false,
          imageUrl: null,
          originalImage: null,
          imageBinary: null,
          id: '',
          collectionId: null,
          ingredientEditForm: null,
          duplicateTitles: []
        };
      }
    }

    case ingredientTypes.EDIT_SUCCESS: {
      const { data, id, imageUrl, imageLoader, getAlternatives } = action.payload;

      return {
        ...state,
        ingredientEditForm: data,
        isEditingIngredientForm: true,
        id: id || '',
        imageUrl: imageUrl,
        originalImage: imageUrl,
        imageLoader: imageLoader,
        alternatives: getAlternatives ? getAlternatives : {}
      };
    }

    case ingredientTypes.GET_SUB_LANG_LOCALE: {
      const { locale } = action.payload;
      //console.log('locale', locale);

      return {
        ...state,
        subLangLocale: locale
      };
    }

    //************* lakshan
    case ingredientTypes.UPDATE_INGREDIENT_IMAGE: {
      const { imageUrl, imageLoader } = action.payload;

      return {
        ...state,
        imageUrl: imageUrl,
        imageLoader: imageLoader
      };
    }
    case ingredientTypes.UPDATE_INGREDIENT_IMAGE_DETAILS: {
      const { imageUrl, imageLoader } = action.payload;

      return {
        ...state,
        imageUrl: imageUrl,
        imageLoader: imageLoader
      };
    }
    case ingredientTypes.UPDATE_CLOSED_INITIAL_TITLE: {
      const { closedInitialTitle } = action.payload;

      return {
        ...state,
        initialTitle: closedInitialTitle
      };
    }
    case ingredientTypes.INGREDIENT_IMAGE_CANCEL_CHANGES: {
      return {
        ...state,
        imageUrl: state.originalImage,
        imageFormData: null
      };
    }
    case ingredientTypes.DELETE_INGREDIENT_IMAGE_SUCCESS: {
      const { imageDeleted } = action.payload;
      return {
        ...state,
        imageUrl: '',
        originalImage: null,
        imageDeleted: imageDeleted,
        imageFormData: null
      };
    }
    case ingredientTypes.INGREDIENT_IMAGE_LOADER_ONLY: {
      const { imageLoader } = action.payload;
      return {
        ...state,
        loading: imageLoader
      };
    }
    case ingredientTypes.INGREDIENT_SHOW_ICONS: {
      const { showIconButtonStore } = action.payload;
      return {
        ...state,
        showIconButtonStore: showIconButtonStore
      };
    }

    /**************/
    case ingredientTypes.ADD_INGREDIENT_MANUALLY: {
      const { newAddedValue } = action.payload;
      return {
        ...state,
        changeFormValues: newAddedValue
      };
    }

    case ingredientTypes.SAVE_DETAILS_SUCCESS: {
      const { id, data } = action.payload;
      let { list } = state;

      if (state.isEditingIngredientForm) {
        const itemIndex = list.findIndex(item => item._id === id);
        const newList = [
          ...list.slice(0, itemIndex),
          { ...data, _id: id },
          ...list.slice(itemIndex + 1)
        ];

        return {
          ...state,
          list: newList
        };
      } else {
        const newItem = { _id: id, ...data };
        return {
          ...state,
          list: [newItem, ...list]
        };
      }
    }

    case ingredientTypes.UPDATE_ALTERNATIVE_LIST: {
      const { alternatives } = action.payload;

      return {
        ...state,
        alternatives: alternatives
      };
    }

    case ingredientTypes.SHOW_LOADER: {
      return {
        ...state,
        loading: true
      };
    }

    case ingredientTypes.HIDE_LOADER: {
      return {
        ...state,
        loading: false
      };
    }

    case ingredientTypes.SET_TEMP_IMG: {
      return {
        ...state,
        imageUrl: action.payload.img,
        imageBinary: action.payload.file
      };
    }

    case ingredientTypes.REMOVE_TEMP_IMG: {
      return {
        ...state,
        imageUrl: state.originalImage,
        imageBinary: null
      };
    }

    case ingredientTypes.SAVE_IMG_FAIL: {
      return {
        ...state,
        imageUrl: state.originalImage,
        imageBinary: null
      };
    }

    case ingredientTypes.SAVE_IMG_SUCCESS: {
      let newList = state.list;
      let ingredientEditForm = state.ingredientEditForm;

      // update list with new image url
      if (!action.payload.popupFrom && action.payload.item) {
        const item = action.payload.item;
        let list = state.list;

        const id = item._id;
        const itemIndex = list.findIndex(item => item._id === id);
        newList = [...list.slice(0, itemIndex), item, ...list.slice(itemIndex + 1)];
      } else if (action.payload.popupFrom && action.payload.item) {
        const form = action.payload.item;
        ingredientEditForm = form;
      }

      return {
        ...state,
        imageUrl: action.payload.img,
        originalImage: action.payload.img,
        imageBinary: null,
        list: newList,
        ingredientEditForm
      };
    }

    case ingredientTypes.REMOVE_IMG_SUCCESS: {
      if (action.payload.popupFrom) {
        return {
          ...state,
          ingredientEditForm: {
            ...state.ingredientEditForm,
            collectionId: ''
          },
          imageUrl: null,
          originalImage: null,
          imageBinary: null
        };
      }
      return {
        ...state,
        imageUrl: null,
        originalImage: null
      };
    }

    case ingredientTypes.SET_INITIAL_TITLE: {
      return {
        ...state,
        initialTitle: action.payload.title,
        initialIngredient: action.payload.manufacturer
      };
    }

    case ingredientTypes.REMOVE_INITIAL_TITLE: {
      return {
        ...state,
        initialTitle: '',
        initialIngredient: ''
      };
    }

    case ingredientTypes.SET_COLLECTION_ID: {
      return {
        ...state,
        collectionId: action.payload.id
      };
    }

    case ingredientTypes.RESET_COLLECTION_ID: {
      return {
        ...state,
        collectionId: null
      };
    }
    //IMG Reducers
    case ingredientTypes.GET_INGREDIENT_IMAGE: {
      return {
        ...state,
        imageLoader: true
      };
    }

    //ingredientImage
    case ingredientTypes.GET_INGREDIENT_IMAGE_SUCCESS: {
      return {
        ...state,
        imageUrl: action.payload,
        imageLoader: action.imageLoader,
        originalImage: action.payload
      };
    }

    case ingredientTypes.UPDATE_INGREDIENT_IMAGE_SUCCESS: {
      const selectedDetails = state.selectedIngredientDetails;
      selectedDetails.attributes.uploadedImageUrl = action.payload;
      return {
        ...state,
        imageLoader: false,
        originalImage: null,
        selectedIngredientDetails: selectedDetails
      };
    }

    case ingredientTypes.UPDATE_INGREDIENT_IMAGE_PATCH_SUCESS: {
      return {
        ...state,
        imageLoader: false,
        originalImage: null
      };
    }

    case ingredientTypes.UPDATE_INGREDIENT_IMAGE_FAILURE: {
      return {
        ...state,
        imageUrl: state.originalImage,
        originalImage: null,
        imageLoader: false
      };
    }
    case ingredientTypes.INGREDIENT_IMAGE_TEMP_IMAGE: {
      return {
        ...state,
        originalImage: state.imageUrl,
        imageUrl: action.payload
      };
    }
    case ingredientTypes.INGREDIENT_IMAGE_TEMP_IMAGE_FORMDATA: {
      return {
        ...state,
        imageFormData: action.payload
      };
    }

    //END IMG reducers
    case ingredientTypes.DUPLICATE_ING_RESPONSE: {
      return {
        ...state,
        duplicateTitles: action.payload.duplicateTitles ? action.payload.duplicateTitles : []
      };
    }
    case ingredientTypes.IDENTIFY_FORM_CHANGES: {
      const { data } = action.payload;

      return {
        ...state,
        hasFormChanges: data
      };
    }
    case ingredientTypes.INGREDIENT_TAG_VEGAN_DETECT_MODAL: {
      const { title, isVegan } = action.payload;

      return {
        ...state,
        IngredientTagVeganDetect: { title: title, isVegan: isVegan }
      };
    }

    case ingredientTypes.RESET: {
      return {
        ...state,
        list: [],
        id: '',
        isIngredientModalOpen: false,
        ingredientEditForm: null,
        isEditingIngredientForm: false,
        loading: false,
        imageFormData: null,
        imageLoader: null,
        imageUrl: null,
        originalImage: null,
        imageBinary: null,
        collectionId: null,
        initialTitle: '',
        initialIngredient: '',
        changeFormValues: null
      };
      // initialState;
    }

    default:
      return state;
  }
}
