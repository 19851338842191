import * as types from './categoryCircle.constants'

export const getCategoryCircles = () =>{
    return{type:types.GET_CATEGORIES_CIRCLES}
}

export const getCategoryCirclesSuccess = (payload) =>{
    return{type:types.GET_CATEGORIES_CIRCLES_SUCCESS, payload}
}

export const positionChanged = (payload) => {
    return{type: types.CATEGORIES_CIRCLES_POSITION_CHANGE_SUCCESS,payload}
} 