import { all, fork, put, takeEvery, call, select } from 'redux-saga/effects';
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SIGNOUT_SESSION_EXPIRED
} from 'constants/ActionTypes';
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userForgotPasswordSuccess,
  userResetPasswordSuccess,
  otpSentFail,
  otpSentSuccess
} from '../../appRedux/actions/Auth';

import { getUserTranslations } from './../../app/shared/redux/actions/locales.actions';
import { doRequest } from 'app/shared/redux/sagas/api';
import jwt_decode from 'jwt-decode';
import { message } from 'antd';

import { setToken, clearToken, getToken } from '../../app/shared/utils/storage';
import { OTP_SENT } from '../../constants/ActionTypes';

export function* signInUserWithEmail({ payload }) {
  try {
    const { email, password, remember } = payload;

    const params = new URLSearchParams();
    params.append('client_id', 'cccy');
    params.append('client_secret', 'Hello');
    params.append('grant_type', 'password');
    params.append('username', email);
    params.append('password', password);
    //Client phase add
    params.append('client_phase','aaaaa');

    const response = yield call(doRequest, {
      url: 'auth/login',
      method: 'POST',
      data: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    });

    if (response.status === 200) {
      const decodedToken = jwt_decode(response.data['access_token']);

      localStorage.setItem('user_id', decodedToken['userId']);
      setToken(remember, response.data['access_token']);
      //localStorage.setItem('access_token', response.data['access_token']);
      localStorage.setItem('refresh_token', response.data['refresh_token']);

      yield put(userSignInSuccess(response.data['access_token']));
      yield put(getUserTranslations());
    }
  } catch (error) {
    console.error(error);
    yield put(showAuthMessage(error.message));
  }
}

export function* forgetPasswordByEmail({ payload }) {
  try {
    const { email } = payload;

    const response = yield call(doRequest, {
      url: `auth/forgotpassword/${email}/`,
      method: 'GET'
    });

    if (response.status === 200) {
      yield put(userForgotPasswordSuccess());
    }
  } catch (error) {
    if (error.response.status === 404) {
      yield put(showAuthMessage('Email not found'));
    }
    if (error.response.status === 400) {
      yield put(showAuthMessage('Invalid email address'));
    }
    if (error.response.status === 500) {
      yield put(showAuthMessage('Internal server error'));
    }
  }
}

export function* resetPassword({ payload }) {
  try {
    const { password, token, history,email } = payload;

    const params2 = new URLSearchParams();
    params2.append('email', email);
    params2.append('password', password);

    const response = yield call(doRequest, {
      url: 'auth/forgotpassword/',
      method: 'PATCH',
      data: params2
    });

    if (response.status === 200) {
      yield put(userResetPasswordSuccess());
      history.push('/signin');
    }
  } catch (error) {
    if (error.response.status === 400) {
      yield put(showAuthMessage('Invalid or not import'));
    } else if (error.response.status === 500) {
      yield put(showAuthMessage('Internal server error'));
    } else if (error.response.status === 404) {
      yield put(showAuthMessage('Invalid User'));
    } else {
      yield put(showAuthMessage('Not a valid request'));
    }
  }
}
export function* signOut() {
  try {
    const token = getToken();

    if (token) {
      yield call(doRequest, {
        url: '/auth/logout',
        method: 'GET',
        data: token
      });
    }

    yield put(userSignOutSuccess());

    clearToken();
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user_id');
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOutExpiredUser({ payload: { logOutType } }) {
  let intl = yield select(state => state.intlReact.intl);
  clearToken();
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('user_id');
  yield put(userSignOutSuccess());

  if (logOutType === 'session_expired' && intl) {
    let mesage = intl.formatMessage({
      id: 'Generic.ApiMessages.SessionExpired'
    });
    message.warn(mesage);
  } else if (logOutType === 'user_deactivated' && intl) {
    let mesage = intl.formatMessage({
      id: 'Generic.ApiMessages.UserDeactivated'
    });
    message.warn(mesage);
  }
}

function* sentOtp({payload:{email,otp}}){
  try {
    const params2 = new URLSearchParams();
    params2.append('username', email);
    params2.append('otp', otp);
    const response =  yield call(doRequest,{
      url:'/auth/forgetPassword/resetOtpStandardOtpCheck',
      method:'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      data:params2
    })
    if(response.status === 200){
      yield put(otpSentSuccess())
    }
  } catch (error) {
    yield put(otpSentFail)
    setTimeout(() => {
      message.error('Otp validation fail');
    }, 10);
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmail);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* forgetPasswordUser() {
  yield takeEvery(FORGOT_PASSWORD, forgetPasswordByEmail);
}

export function* resetPasswordUser() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* sessionExpiredListener() {
  yield takeEvery(SIGNOUT_SESSION_EXPIRED, signOutExpiredUser);
}

export function* sentOtpListener(){
  yield takeEvery(OTP_SENT,sentOtp)
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(signOutUser),
    fork(forgetPasswordUser),
    fork(resetPasswordUser),
    fork(sessionExpiredListener),
    fork(sentOtpListener)
  ]);
}
