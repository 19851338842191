import * as languageActions from './languagelist.constants';

export const getlanguageList = () => ({
  type: languageActions.GET_LANGUAGE_LIST
});

export const getlanguageListSuccess = payload => ({
  type: languageActions.GET_LANGUAGE_LIST_SUCCESS,
  payload
});
