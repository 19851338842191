import { createTypes, async } from 'redux-action-creator';

const types = createTypes(
  [
    ...async('GET_DATA_LIST'),
    'GET_DATA_LIST_FAIL',
    'SET_DATA_LIST_IMAGE_SUCCESS',
    'TABLE_DATA_SORT',
    'RESET',
    ...async('DELETE_TABLE_ROW'),
    ...async('GET_SEARCH_SUGGESTION'),
    'SET_SEARCH_LOADER',
    ...async('ADD_TAG'),
    ...async('DELETE_TAG'),
    'SET_ID_LIST'
  ],
  'DISTRIBUTOR'
);

export default types;
