import { actionCreator } from 'redux-action-creator';
import { distributorTypes } from './distributorModal.constants';

export const getDistributorList = actionCreator(distributorTypes.GET_LIST);
export const getDistributorListSuccess = actionCreator(distributorTypes.GET_LIST_SUCCESS, 'data');
export const getDistributorListWorkerSuccess = actionCreator(
  distributorTypes.GET_DISTRIBUTOR_LIST_WORKER_SUCCESS,
  'data'
);
export const updateDistributorModal = actionCreator(distributorTypes.UPDATE_MODAL_STATE, 'state');
export const saveDistributorDetails = actionCreator(
  distributorTypes.SAVE_DETAILS,
  'form',
  'popupFrom',
  'cb'
);
export const saveDistributorDetailsSuccess = actionCreator(
  distributorTypes.SAVE_DETAILS_SUCCESS,
  'data',
  'id'
);
export const getDistributorListFail = actionCreator(distributorTypes.GET_LIST_FAIL);
export const saveDistributorDetailsFail = actionCreator(distributorTypes.SAVE_DETAILS_FAIL);
export const editDistributor = actionCreator(distributorTypes.EDIT, 'id', 'popupFrom');
export const editDistributorSuccess = actionCreator(
  distributorTypes.EDIT_SUCCESS,
  'data',
  'id',
  'image'
);
export const editDistributorFail = actionCreator(distributorTypes.EDIT_FAIL);
export const showDistributorLoader = actionCreator(distributorTypes.SHOW_LOADER);
export const hideDistributorLoader = actionCreator(distributorTypes.HIDE_LOADER);
export const selectDistributorImage = actionCreator(distributorTypes.SET_TEMP_IMG, 'img', 'file');
export const removeSelectedDistributorImage = actionCreator(distributorTypes.REMOVE_TEMP_IMG);
export const saveDistributorImage = actionCreator(distributorTypes.SAVE_IMG, 'popupFrom');
export const saveDistributorImageSuccess = actionCreator(
  distributorTypes.SAVE_IMG_SUCCESS,
  'img',
  'item',
  'popupFrom'
);
export const saveDistributorImageFail = actionCreator(distributorTypes.SAVE_IMG_FAIL);
export const removeDistributorImage = actionCreator(distributorTypes.REMOVE_IMG, 'popupFrom');
export const removeDistributorImageSuccess = actionCreator(
  distributorTypes.REMOVE_IMG_SUCCESS,
  'popupFrom'
);
export const removeDistributorImageFail = actionCreator(distributorTypes.REMOVE_IMG_FAIL);
export const setInitialTitle = actionCreator(
  distributorTypes.SET_INITIAL_TITLE,
  'title',
  'distributor'
);
export const removeInitialTitle = actionCreator(distributorTypes.REMOVE_INITIAL_TITLE);
export const setCollectionId = actionCreator(distributorTypes.SET_COLLECTION_ID, 'id');
export const removeCollectionId = actionCreator(distributorTypes.RESET_COLLECTION_ID);
export const openCatalogueModal = actionCreator(distributorTypes.OPEN_CATALOGUE);
export const reset = actionCreator(distributorTypes.RESET);
