import { all } from 'redux-saga/effects';
import catalogue_productsSaga from './products/redux/catalogue_products.saga';
import catalogue_distributorSaga from './distributor/redux/catalogue_distributor.saga';
import catalogue_manufacturerSaga from './manufacturer/redux/catalogue_manufacturer.saga';
import catalogue_brandSaga from './brand/redux/catalogue_brand.saga';

export default function* rootSaga() {
  yield all([
    catalogue_productsSaga(),
    catalogue_distributorSaga(),
    catalogue_manufacturerSaga(),
    catalogue_brandSaga()
  ]);
}
