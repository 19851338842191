import React from "react";
import {Button, Form, Input, message} from "antd";
import IntlMessages from "util/IntlMessages";
import {injectIntl } from 'react-intl';
import { connect } from "react-redux";
import CircularProgress from "components/CircularProgress/index";


import {
  hideMessage,
  showAuthLoader,
  forgetPassword,
  resetPasswordToken
} from "appRedux/actions/Auth";
import { setForgetPasswordUserEmail } from "../appRedux/actions/Auth";

const FormItem = Form.Item;

export class ForgetPassword extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(values)
      if (!err) {
        this.props.showAuthLoader();
        this.props.forgetPassword(values);
        this.props.setForgetPasswordUserEmail(values.email)
      }
    });
  };

  handleBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  }

  componentDidMount(){
   this.props.resetPasswordToken();
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
    
    if (this.props.reset_token) {
      this.props.history.push(`/otpverification`);
      
    }
  }

  renderErrorMessage = (messagePassed) => {
    setTimeout(() => {
      message.error(messagePassed);
    }, 10);
    
  }

  render() {
    console.log("RESET_TOKEN",this.props.email)
    const {showMessage, loader, alertMessage, intl} = this.props;
    const {getFieldDecorator} = this.props.form;

    return (
      <div className="gx-login-container gx-loader-container">
        <div className="gx-login-content">

          <div className="gx-login-header">
            <img src={require("assets/images/logo-white.png")} alt="wieldy" title="wieldy"/>
          </div>
          <div className="gx-mb-4 fixedCotentWithTxtAutontication">
            <h2>
            Forgot Your Password ?
              {/* <IntlMessages defaultMessage='Forgot Your Password ?' id="AuthenticationPages.ForgotPassword.ForgotYourPassword"/> */}
              
              </h2>
            <p>
            Don't worry. Recovering the password is easy.Just tell us the email you have registered with jumbo
              {/* <IntlMessages defaultMessage={`Don't worry. Recovering the password is easy.Just tell us the email you have registered with jumbo`} id="AuthenticationPages.ForgotPassword.Txt_content"/> */}
              </p>
          </div>

          <Form layout="vertical" onSubmit={this.handleSubmit} className="gx-login-form gx-form-row0 validation-align">

            <FormItem>
              {getFieldDecorator('email', {
                rules: [{
                  type: 'email',
                  message:'The input is not valid E-mail!'
                  // message: intl.formatMessage({ id: "AuthenticationPages.ForgotPassword.ValidationMessages.Msg_InputEmailNotValid", defaultMessage: 'The input is not valid E-mail!' }),
                }, {
                  required: true, 
                  message:'Please input your E-mail!'
                  // message: intl.formatMessage({ id: "AuthenticationPages.ForgotPassword.ValidationMessages.Msg_InputEmailRequired", defaultMessage: 'Please input your E-mail!' }),
                }],
              })(
                <Input name="userEmail" type="email" placeholder= 'E-Mail Address'/>                
                // <Input name="userEmail" type="email" placeholder= {intl.formatMessage({ id: "AuthenticationPages.ForgotPassword.PlaceHolder", defaultMessage: 'E-Mail Address' })}/>
              )}
            </FormItem>

            <FormItem>
              <Button type="normal"  onClick={this.handleBack} htmlType="button">
                <i className="icon icon-arrow-left back-signin"/>
                Sign In
                {/* <IntlMessages defaultMessage='Sign In' id="Generic.Buttons.SignIn"/> */}
              </Button>
              <Button type="primary" htmlType="submit">
              SEND
                {/* <IntlMessages defaultMessage='SEND' id="Generic.Buttons.Send"/> */}
              </Button>
            </FormItem>

          </Form>

          {loader &&
              <div className="gx-loader-view">
                <CircularProgress/>
              </div>}
            {showMessage && this.renderErrorMessage(alertMessage.toString())}
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, reset_token, authUser,email} = auth;
  return {loader, alertMessage, showMessage, reset_token, authUser}
}

const WrappedForgotPasswordForm = Form.create()(ForgetPassword);
const withIntl = injectIntl(WrappedForgotPasswordForm);

export default connect(mapStateToProps, {
  hideMessage,
  forgetPassword,
  showAuthLoader,
  resetPasswordToken,
  setForgetPasswordUserEmail,
})(withIntl);
