import React, {Component} from "react";
import {connect} from "react-redux";
import URLSearchParams from 'url-search-params'
import {Redirect, Route, Switch} from "react-router-dom";
import {LocaleProvider} from "antd";
import {IntlProvider} from "react-intl";
import { setIntl } from '../../../src/app/shared/redux/actions/intlReact.actions';

// import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import ForgetPassword from "../ForgetPassword";
import ResetPassword from "../ResetPassword";
import {setInitUrl} from "appRedux/actions/Auth";
import {onLayoutTypeChange, onNavStyleChange, setThemeType} from "appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK
} from "../../constants/ThemeSetting";

import {getUserTranslations} from 'app/shared/redux/actions/locales.actions';
import {getLocales} from 'app/shared/redux/sagas/locales.saga'
import {terminateJobQueue} from 'app/shared/redux/actions/index'

import AppLocale from '../../lngProvider/index'
import OtpVerification from "../OtpVerification";

const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />}
  />;


class App extends Component {

  constructor(props){
    super(props);
    this.newRef = React.createRef();
  }

  state = {
    defaultLocale: 'en_US'
  }

  setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  componentWillMount() {
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);

    if (params.has("theme")) {
      this.props.setThemeType(params.get('theme'));
    }
    if (params.has("nav-style")) {
      this.props.onNavStyleChange(params.get('nav-style'));
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get('layout-type'));
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.userLocale !== prevProps.userLocale || this.props.languageList !== prevProps.languageList) {
      const locale = this.props.languageList.find(locale => locale.id === this.props.userLocale.toString());
      if(!locale) return;
      this.props.getUserTranslations(locale.locale);
      this.setState({defaultLocale: locale.locale});
    }
  }

  componentWillUnmount() {
    this.props.terminateJobQueue()
  }

  render() {
    const {match, location, themeType, layoutType, navStyle, authUser, initURL} = this.props;
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
    }
    if (location.pathname === '/') {
      if (authUser === null) {
        return ( <Redirect to={'/signin'}/> );
      } 
      else if (initURL === '' || initURL === '/' || initURL === '/signin' || initURL === "/forgetpassword" || ( initURL && initURL.includes('/resetpassword'))) {
        return ( <Redirect to={'/dashboard'}/> );
      } 
      else {
        return ( <Redirect to={initURL}/> );
      }
    }
    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);
    const localeMessage = getLocales(this.state.defaultLocale); 
    const langKey = this.state.defaultLocale.split('_')[0]
    return (
      <LocaleProvider locale={AppLocale[langKey].antd}>
        <IntlProvider
          ref= {this.newRef}
          locale={langKey}
          // messages={AppLocale.en.messages}
           messages={localeMessage}
          >
          <Switch>
            <Route exact path='/signin' component={SignIn}/>
            <Route exact path='/signup' component={SignUp}/>
            <Route exact path='/forgetpassword' component={ForgetPassword}/>
            <Route exact path='/otpverification' component={OtpVerification}/>
            <Route exact path='/resetpassword' component={ResetPassword}/>
            <RestrictedRoute path={`${match.url}`} authUser={authUser}
                             component={MainApp} setIntl={setIntl}/>
          </Switch>
        </IntlProvider>
      </LocaleProvider>
    )
  }
}

const mapStateToProps = ({settings, auth, loaders, profile, languageList}) => {
  const {locale, navStyle, themeType, layoutType} = settings;
  const {authUser, initURL} = auth;
  return {locale, navStyle, themeType, layoutType, authUser, initURL, localeLoader: loaders.locales, userLocale: profile.language, languageList: languageList.languageList}
};
export default connect(mapStateToProps, {setInitUrl, setThemeType, onNavStyleChange, onLayoutTypeChange, getUserTranslations, setIntl, terminateJobQueue})(App);
