export const GET_NEW_INGREDIENTS = 'GET_NEW_INGREDIENTS';
export const GET_NEW_INGREDIENTS_SUCCESS = 'GET_NEW_INGREDIENTS_SUCCESS';
export const GET_NEW_INGREDIENT = 'GET_NEW_INGREDIENT';
export const GET_NEW_INGREDIENT_SUCCESS = 'GET_NEW_INGREDIENT_SUCCESS';
export const CLEAR_NEW_INGREDIENT = 'CLEAR_NEW_INGREDIENT';
export const ADD_TO_LIST = 'ADD_TO_LIST';
export const REMOVE_FROM_COUNTRY_LIST = 'REMOVE_FROM_COUNTRY_LIST';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const ADD_TO_WHITELIST_COUNTRIES = 'ADD_TO_WHITELIST_COUNTRIES';
export const REMOVE_FROM_WHITELIST_COUNTRIES = 'REMOVE_FROM_WHITELIST_COUNTRIES';
export const CLEAR_WHITELIST_COUNTRIES = 'CLEAR_TO_WHITELIST_COUNTRIES';
export const ADD_TO_BLACKLIST_COUNTRIES = 'ADD_TO_BLACKLIST_COUNTRIES';
export const REMOVE_FROM_BLACKLIST_COUNTRIES = 'REMOVE_FROM_BLACKLIST_COUNTRIES';
export const CLEAR_BLACKLIST_COUNTRIES = 'CLEAR_TO_BLACKLIST_COUNTRIES';
export const SET_WHITELIST_COUNTRIES = 'SET_WHITELIST_COUNTRIES';
export const SET_BLACKLIST_COUNTRIES = 'SET_BLACKLIST_COUNTRIES';
export const RESET_PAGINATION = 'RESET_PAGINATION';
export const GET_LOCALE_LIST = 'GET_LOCALE_LIST';
export const SET_LOCALE_LIST = 'SET_LOCALE_LIST';
export const SET_DEFAULT_LOCALE = 'SET_DEFAULT_LOCALE';
export const SET_SELECTED_VALUES = 'SET_SELECTED_VALUES';
export const GET_IMAGE = 'GET_IMAGE';
export const GET_IMAGE_SUCCESS = 'GET_IMAGE_SUCCESS';
export const GET_IMAGE_FAIL = 'GET_IMAGE_FAIL';
export const SET_IMAGE_LOADER = 'SET_IMAGE_LOADER';
export const RESET_IMAGE_LOADER = 'RESET_IMAGE_LOADER';
export const ADD_TEMP_IMAGE = 'ADD_TEMP_IMAGE';
export const REMOVE_TEMP_IMAGE = 'REMOVE_TEMP_IMAGE';
export const UPDATE_IMAGE = 'UPDATE_IMAGE';
export const UPDATE_IMAGE_SUCCESS = 'UPDATE_IMAGE_SUCCESS';
export const UPDATE_IMAGE_FAIL = 'UPDATE_IMAGE_FAIL';
export const SET_UPDATED_IMAGE_URL = 'SET_UPDATED_IMAGE_URL';
export const UPDATE_INGREDIENT = 'UPDATE_INGREDIENT';
export const CLEAR_IMAGES = 'CLEAR_IMAGES';

export const DELETE_IMAGE = 'DELETE_IMAGE';
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';

export const DELETE_NEW_INGREDIENT = 'DELETE_NEW_INGREDIENT';
export const SET_NEW_INGREDIENT_NAME = 'SET_NEW_INGREDIENT_NAME';

export const SET_NEW_INGREDIENT_FIELDS = 'SET_NEW_INGREDIENT_FIELDS';
