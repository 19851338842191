import { actionCreator } from 'redux-action-creator';
import types from './product.constants';

export const saveNewNode = actionCreator(types.SAVE_NODE, 'key', 'values', 'newNode');
export const getNutrientsList = actionCreator(types.GET_LIST_NUTRITION_VALUES, 'data');
export const getNutrientsValueListSuccess = actionCreator(
  types.GET_NUTRITION_VALUE_LIST_SUCCESS,
  'data'
);
export const updateNutritionLoadedStatus = actionCreator(
  types.UPDATE_NUTRITION_LOADED_STATUS,
  'data'
);
export const getProductList = actionCreator(types.GET_PRODUCT_LIST);
export const getProductListSuccess = actionCreator(types.GET_PRODUCT_LIST_SUCCESS, 'data');
export const getProductByLocale = actionCreator(types.GET_PRODUCT_BY_LOCALE, 'data');
export const setProductStore = actionCreator(types.SET_PRODUCT_STORE, 'store');
export const getProductByLocalSuccess = actionCreator(types.GET_PRODUCT_BY_LOCALE_SUCCESS, 'data');
export const getCategoryList = actionCreator(types.GET_CATEGORY_LIST, 'data');
export const updateCategoryLoader = actionCreator(types.UPDATE_CATEGORY_LOADER);
export const getCategoryListSuccess = actionCreator(types.GET_CATEGORY_LIST_SUCCESS, 'data');
export const getIngredientList = actionCreator(types.GET_INGREDIENT_LIST, 'data');
export const updateIngredientLoader = actionCreator(types.UPDATE_INGREDIENT_LOADER, 'loader');
export const getIngredientListSuccess = actionCreator(types.GET_INGREDIENT_LIST_SUCCESS, 'data');
export const getStoreList = actionCreator(types.GET_STORE_LIST, 'data');
export const getStoreTypeList = actionCreator(types.GET_STORE_TYPE_LIST);
export const getStoreTypeListSuccess = actionCreator(types.GET_STORE_TYPE_LIST_SUCCESS, 'data');
export const getStoreListSuccess = actionCreator(types.GET_STORE_LIST_SUCCESS, 'data');
export const getEnumberList = actionCreator(types.GET_ENUMBER_LIST, 'data');
export const getEnumberListSuccess = actionCreator(types.GET_ENUMBER_LIST_SUCCESS, 'data');
export const addProduct = actionCreator(types.ADD_PRODUCT, 'data');
export const editProduct = actionCreator(types.EDIT_PRODUCT, 'data');
export const deleteProduct = actionCreator(types.DELETE_PRODUCT, 'data');
export const deleteProductSuccess = actionCreator(types.DELETE_PRODUCT_SUCCESS, 'data');
export const updateProductDetailLoadStatus = actionCreator(
  types.UPDATE_PRODUCT_DETAIL_LOAD_STATUS,
  'data'
);
export const updateProductLocale = actionCreator(types.UPDATE_PRODUCT_LOCALE, 'data');
export const updateSaveButtonStatus = actionCreator(types.UPDATE_SAVE_BUTTON_STATUS);
export const getMayContainValueSuccess = actionCreator(
  types.GET_MAY_CONTAIN_VALUES_SUCCESS,
  'data'
);
export const getLabelsList = actionCreator(types.GET_LABEL_LIST);
export const setFilterLabelList = actionCreator(types.SET_FILTER_LABEL_LIST, 'data');
export const getLabelsListSuccess = actionCreator(types.GET_LABEL_LIST_SUCCESS, 'data');
export const mayContaintLoader = actionCreator(types.MAY_CONTAINT_LOADER, 'loader');
export const lablesListLoader = actionCreator(types.LABEL_LIST_LOADER, 'loader');
export const reset = actionCreator(types.RESET);

export const setStoreTypeImageSuccess = actionCreator(
  types.SET_STORE_TYPE_IMAGE,
  'id',
  'image',
  'indexes'
);

export const getTagList = actionCreator(types.GET_TAG_LIST, 'data');
export const getTagListSuccess = actionCreator(types.GET_TAG_LIST_SUCCESS, 'data', 'locale');
export const setTagImageSuccess = actionCreator(types.SET_TAG_IMAGE, 'id', 'image', 'indexes');
export const setDefaultUnit = actionCreator(types.GET_DEFAULT_UNIT, 'unit');

export const editNewTagSuccess = actionCreator(types.EDIT_TAG_SUCCESS, 'data', 'id');
export const addNewTagInProduct = actionCreator(types.ADD_NEW_TAG, 'tagData');
export const getNewAddedTagProductImage = actionCreator(
  types.NEW_ADDED_TAG_IMAGE_GET,
  'image',
  'id'
);
export const getremoveTagImageFromProd = actionCreator(
  types.REMOVE_TAG_IMAGE_FROM_PROD,

  'id'
);
export const addNewIngredient = actionCreator(types.ADD_NEW_INGREDIENT, 'data');
export const editIngredient = actionCreator(types.EDIT_INGREDIENT, 'data');
export const setStoreImage = actionCreator(types.SET_STORE_IMAGE, 'images', 'indexes');
export const getStoreUnicProductPrice = actionCreator(
  types.GET_STORE_UNIC_PRODUCT_PRICE,
  'requestObj'
);

export const getStoreUnicProductPriceSuccess = actionCreator(
  types.GET_STORE_UNIC_PRODUCT_PRICE_SUCCESS,
  'data'
);

export const currancyObjUpdate = actionCreator(types.CURRANCY_OBJ_UPDATE, 'currancy');
export const getBracketprice = actionCreator(types.GET_BRACKET_PRICE);
export const getBracketpriceSuccess = actionCreator(
  types.GET_BRACKET_PRICE_SUCCESS,
  'value',
  'pId'
);
export const resetBracketPrice = actionCreator(types.RESET_BRACKET_PRICE, 'value');
export const getProductID = actionCreator(types.GET_PRODUCT_ID, 'id');
export const disabledPrice = actionCreator(types.DISABLED_PRICE_INPUT, 'value');
export const changeMountState = actionCreator(types.CHANGE_PRODUCT_ADD_PAGE_MOUNT, 'mountState');
export const updateProductDataIngredientOnly = actionCreator(
  types.UPDATE_PRODUCT_DATA_INGREDIENT_ONLY,
  'ingarray',
  'enumarray'
);

// product worker function

export const getCategoryWorkerSuccess = actionCreator(types.GET_CATEGORY_WORKER_SUCCESS, 'data');
export const getIngArrayWorkerSuccess = actionCreator(types.GET_INGREDIENT_WORKER_SUCCESS, 'data');
export const getENumbersWorkerSuccess = actionCreator(types.GET_ENUMBERS_WORKER_SUCCESS, 'data');
export const getMayContainWorkerSuccess = actionCreator(
  types.GET_MAY_CONTAIN_WORKER_SUCCESS,
  'data'
);
export const getTagsWorkerSuccess = actionCreator(types.GET_TAGS_WORKER_SUCCESS, 'data');
export const getNutritionWorkerSuccess = actionCreator(types.GET_NUTRITION_WORKER_SUCCESS, 'data');
export const getLabelsWorkerSuccess = actionCreator(types.GET_LABELS_WORKER_SUCCESS, 'data');
export const identifySetFeildValue = actionCreator(types.IDENTIFY_SET_FIELD_VALUE, 'setData');
export const storeDataEnabled = actionCreator(types.STORE_DATA_ENABLED);
export const tagListEnabled = actionCreator(types.TAG_LIST_LOADER);
