import { createTypes, async } from 'redux-action-creator';

export const ingredientTypes = createTypes(
  [
    ...async('GET_LIST'),
    'UPDATE_MODAL_STATE',
    ...async('SAVE_DETAILS'),
    ...async('EDIT'),
    'SHOW_LOADER',
    'HIDE_LOADER',
    'SET_TEMP_IMG',
    'REMOVE_TEMP_IMG',
    ...async('SAVE_IMG'),
    ...async('REMOVE_IMG'),
    'SET_INITIAL_TITLE',
    'REMOVE_INITIAL_TITLE',
    'SET_COLLECTION_ID',
    'RESET_COLLECTION_ID',
    'OPEN_CATALOGUE',
    'GET_SUB_LANG_LOCALE',
    'RESET',
    'ADD_INGREDIENT_MANUALLY',
    'UPDATE_INGREDIENT_IMAGE',
    'UPDATE_INGREDIENT_IMAGE_DETAILS',
    'UPDATE_INGREDIENT_IMAGE_PATCH_SUCESS',
    'UPDATE_INGREDIENT_IMAGE_SUCCESS',
    'UPDATE_INGREDIENT_IMAGE_FAILURE',
    'UPDATE_CLOSED_INITIAL_TITLE',
    'DELETE_INGREDIENT_IMAGE',
    'DELETE_INGREDIENT_IMAGE_SUCCESS',
    'DELETE_INGREDIENT_IMAGE_ERROR',
    'INGREDIENT_IMAGE_TEMP_IMAGE',
    'INGREDIENT_IMAGE_TEMP_IMAGE_FORMDATA',
    'INGREDIENT_IMAGE_CANCEL_CHANGES',
    'GET_INGREDIENT_IMAGE',
    'GET_INGREDIENT_IMAGE_SUCCESS',
    'INGREDIENT_IMAGE_LOADER_ONLY',
    'IDENTIFY_FORM_CHANGES',
    'INGREDIENT_SHOW_ICONS',
    'UPDATE_ALTERNATIVE_LIST',
    'DUPLICATE_ING_RESPONSE',
    'INGREDIENT_TAG_VEGAN_DETECT_MODAL'
  ],
  'INGREDIENT_MODAL'
);
