import { createTypes, async } from 'redux-action-creator';

const types = createTypes(
  [
    ...async('FETCH_ROWS'),
    ...async('DELETE_PRODUCT'),
    ...async('GET_SEARCH_SUGGESTION'),
    'SET_SEARCH_LOADER',
    'ADD_TAG',
    'ADD_TAG_SUCCESS',
    'DELETE_TAG',
    'DELETE_TAG_SUCCESS',
    'SET_LOADER',
    'SET_PRODUCT_IMAGE',
    'SET_PRODUCT_USER_IMAGE',
    'SET_SEARCH_IDS',
    'RESET'
  ],
  'CATALOGUE_PRODUCTS'
);

export default types;
