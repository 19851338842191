import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_TOKEN,
  SIGNOUT_SESSION_EXPIRED,
  FORGET_PASSWORD_USER_EMAIL, FORGET_PASSWORD_USER_EMAIL_RESET, OTP_SENT, OTP_SENT_FAIL, OTP_SENT_SUCCESS
} from 'constants/ActionTypes';

export const userSignUp = user => {
  return {
    type: SIGNUP_USER,
    payload: user
  };
};
export const userSignIn = user => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};
export const userSignOut = (isSessionExpired = false) => {
  return {
    type: SIGNOUT_USER,
    payload: {
      isSessionExpired
    }
  };
};
export const forgetPassword = userEmail => {
  return {
    type: FORGOT_PASSWORD,
    payload: userEmail
  };
};
export const resetPassword = payload => {
  return {
    type: RESET_PASSWORD,
    payload: payload
  };
};

export const resetPasswordToken = () => {
  return {
    type: RESET_PASSWORD_TOKEN
  };
};
export const userSignUpSuccess = authUser => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser
  };
};

export const userSignInSuccess = authUser => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  };
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS
  };
};
export const userForgotPasswordSuccess = () => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    // payload: passedToken
  };
};
export const userResetPasswordSuccess = () => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: 'success'
  };
};

export const showAuthMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const userGoogleSignIn = () => {
  return {
    type: SIGNIN_GOOGLE_USER
  };
};
export const userGoogleSignInSuccess = authUser => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser
  };
};
export const userFacebookSignIn = () => {
  return {
    type: SIGNIN_FACEBOOK_USER
  };
};
export const userFacebookSignInSuccess = authUser => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser
  };
};
export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};
export const userTwitterSignIn = () => {
  return {
    type: SIGNIN_TWITTER_USER
  };
};
export const userTwitterSignInSuccess = authUser => {
  return {
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser
  };
};
export const userGithubSignIn = () => {
  return {
    type: SIGNIN_GITHUB_USER
  };
};
export const userGithubSignInSuccess = authUser => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};

export const signOutSessionExpiredUser = (logOutType = 'session_expired') => ({
  type: SIGNOUT_SESSION_EXPIRED,
  payload: { logOutType }
});

export const setForgetPasswordUserEmail = (email) => {
  console.log(email)
  return {
    type: FORGET_PASSWORD_USER_EMAIL,
    payload: email
  }
}

export const resetForgetPasswordUserEmail = () => {
  return {
    type: FORGET_PASSWORD_USER_EMAIL_RESET,
  }
}

export const otpSent = (payload) => {
  return{
    type: OTP_SENT,
    payload:payload
  }
}

export const otpSentSuccess = () => {
  return{
    type: OTP_SENT_SUCCESS
  }
}

export const otpSentFail = () => {
  return{
    type: OTP_SENT_FAIL
  }
}
