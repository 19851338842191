import setdefaultDataSort from '../../../../shared/utils/setdefaultDataSort';

let newStoreArray = [];

function ReturnStoreImageUrl(passArray = [], index) {
  let data = [...newStoreArray];

  if (passArray && passArray.length !== 0) {
    for (let storeImage of passArray) {
      const itemIndex = index[storeImage.id];

      if (itemIndex || itemIndex === 0) {
        const item = data[itemIndex];

        const clonedItem = JSON.parse(JSON.stringify(item));

        if (clonedItem && clonedItem.id) {
          clonedItem['imageUrl'] = storeImage.image;
          data = [...data.slice(0, itemIndex), clonedItem, ...data.slice(itemIndex + 1)];

          newStoreArray = [...data];
        }
      }
    }
  }
}

function getOptionArray() {
  return newStoreArray;
}

function resetImageUrl() {
  newStoreArray = [];
}

function getStoreDataList(passedArray) {
  let filterArray = passedArray.filter(item => item.id !== '0000');

  let sortedArary = setdefaultDataSort(filterArray, 'branch');

  newStoreArray = setdefaultDataSort(sortedArary, 'title');

  newStoreArray.splice(0, 0, {
    id: '0000',
    title: 'None',
    branch: '',
    mediaCollectionId: '',
    imageUrl: ''
  });
}
export { ReturnStoreImageUrl, getOptionArray, resetImageUrl, getStoreDataList };
