import setdefaultDataSort from '../../../../shared/utils/setdefaultDataSort';

let sortedArary = [];
let newOptions = [];

function generateTagImagesUrls(options) {
  const { image, indexes, tagsArray } = options;

  let data = JSON.parse(JSON.stringify(tagsArray));

  if (image && image.length !== 0) {
    for (let distributorImage of image) {
      const itemIndex = indexes[distributorImage.id];
      if (itemIndex || itemIndex === 0) {
        sortedArary[itemIndex] = distributorImage.image;
      }
    }
  }
  if (data && data.length > 0) {
    data.map((ele, x) => {
      newOptions[x] = {
        serial: ele.serial,
        imageUrl: sortedArary[x] ? sortedArary[x] : '',
        label: ele.title,
        value: ele._id
      };
    });
  }
  //   console.log('ARRAY ARRAY', sortedArary);

  return sortedArary;
}
function generateEditTagImagesUrls(id, image, tagsArray) {
  console.log('CALLE FUNCTION EDIT', id, image, tagsArray);
  tagsArray.map((item, index) => {
    if (id == item._id) {
      let findIndex = newOptions.findIndex(e => e.value == id);
      sortedArary[findIndex] = image;
      newOptions[findIndex].imageUrl = image;
      newOptions[findIndex].label = item.title;
    }
  });

  let sortedArary2 = setdefaultDataSort(newOptions, 'label');
  newOptions = [...sortedArary2];
}
function generateNewTagImagesUrls(id, image, tagsArray, newObj) {
  tagsArray.some(function(item, index) {
    if (id == item._id) {
      let newTagObj = {
        imageUrl: image,
        label: newObj.label,
        serial: newObj.serial,
        value: newObj.value
      };
      console.log('Loop ', index);
      sortedArary.splice(index, 0, image);
      newOptions.splice(index, 0, newTagObj);
      //   sortedArary[index] = image;
      return true;
    }
  });
  console.log(newObj, 'NEW TAG ADD', newOptions);
}
function searchTagsUrl() {
  return newOptions;
}
function deleteImgUrl(id, tagsArray) {
  let data = JSON.parse(JSON.stringify(tagsArray));
  const indexes = data.findIndex(item => item._id === id);

  if (indexes !== -1) {
    console.log('DELETE IMAGE', indexes, data);

    sortedArary[indexes] = '';
    newOptions[indexes].imageUrl = '';
  }
}
function returnImgUrls() {
  return sortedArary;
}
function resetImgUrls() {
  sortedArary = [];
}
export {
  generateTagImagesUrls,
  returnImgUrls,
  resetImgUrls,
  generateEditTagImagesUrls,
  generateNewTagImagesUrls,
  deleteImgUrl,
  searchTagsUrl
};
