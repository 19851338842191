import { createTypes, async } from 'redux-action-creator';

const types = createTypes(
  [
    ...async('GET_LIST'),
    ...async('SAVE_NODE'),
    ...async('UPDATE_ORDER'),
    ...async('SET_SELECTED_NODE'),
    ...async('SAVE_CHANGES'),
    ...async('SEARCH'),
    ...async('DELETE_NODE'),
    'SAVE_CHANGES_FAIL',
    'COUNTRY_SELECTED_LIST',
    'ADD_NODE',
    'ADD_NODE_SUCCESS',
    'UPDATE_BUTTON_STATE',
    'UPDATE_FORMDATA',
    'UPDATE_SAVE_MODAL',
    'SAVE_CHANGES_AND_SWITCH',
    'CANCEL_CHANGES_AND_SWITCH',
    'CANCEL_CHANGES_AND_STAY',
    'RESET_STATE',
    'DISABLED_WITH_ALL_COUNTRY',
    'SET_TEMP_IMG',
    'REMOVE_TEMP_IMG',
    ...async('SAVE_IMG'),
    ...async('REMOVE_IMG'),
    'GET_IMG',
    'GET_IMG_SUCCESS',
    'SHOW_LOADER',
    'HIDE_LOADER',
    ...async('GET_DUPLICATED_LIST'),
    'GET_DUPLICATED_LIST_FAIL',
    'SEARCH_JOB_SUCCESS',
    'UPDATE_ALTERNATIVE_LIST',
    'DELETE_JOB_SUCCESS',
    'DUPLICATE_TAGS_RESPONSE',
    'SEARCH_DUPLICATE_JOB_SUCCESS'
  ],
  'TAGS'
);

export default types;
