import adsTypes from './addetails.constants';

const initialState = {
  // dataSource: [],
  // tableLoader: true,
  // sort: { order: 'desc', columnKey: 'edit' },
  // pagination: { total: 0, pageSize: 50, current: 1 }
  countryCounter: null,
  adDetails: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case adsTypes.SET_COUNTRY_COUNTER: {
      const { counter } = action.payload;
      return { ...state, countryCounter: counter };
    }
    case adsTypes.SET_AD_DETAILS: {
      const { data } = action.payload;

      return { ...state, adDetails: data };
    }
    case adsTypes.RESET: {
      return { ...state, ...initialState };
    }

    default:
      return state;
  }
};
