import { actionCreator } from 'redux-action-creator';
import { ingredientTypes } from './ingredientModel.constants';

export const getIngredientList = actionCreator(ingredientTypes.GET_LIST);
export const getIngredientListSuccess = actionCreator(ingredientTypes.GET_LIST_SUCCESS, 'data');
export const updateIngredientModal = actionCreator(ingredientTypes.UPDATE_MODAL_STATE, 'state');
export const saveIngredientDetails = actionCreator(
  ingredientTypes.SAVE_DETAILS,
  'obj',
  'type',
  'locale',
  'newImage',
  'cb',
  'ingredientDefLangLocal',
  'subLangLocale'
);
export const saveIngredientDetailsSuccess = actionCreator(
  ingredientTypes.SAVE_DETAILS_SUCCESS,
  'data',
  'id'
);
export const manuallyIngredientAdd = actionCreator(
  ingredientTypes.ADD_INGREDIENT_MANUALLY,
  'newAddedValue'
);
export const getIngredientListFail = actionCreator(ingredientTypes.GET_LIST_FAIL);
export const saveIngredientDetailsFail = actionCreator(ingredientTypes.SAVE_DETAILS_FAIL);
export const editIngredient = actionCreator(
  ingredientTypes.EDIT,
  'id',
  'locale',
  'ingredientDefLangLocal',
  'subLangLocale'
);
export const editIngredientSuccess = actionCreator(
  ingredientTypes.EDIT_SUCCESS,
  'data',
  'id',
  'imageUrl',
  'imageLoader'
);
export const editIngredientFail = actionCreator(ingredientTypes.EDIT_FAIL);
export const showIngredientLoader = actionCreator(ingredientTypes.SHOW_LOADER);
export const hideIngredientLoader = actionCreator(ingredientTypes.HIDE_LOADER);
export const selectIngredientImage = actionCreator(ingredientTypes.SET_TEMP_IMG, 'img', 'file');
export const removeSelectedIngredientImage = actionCreator(ingredientTypes.REMOVE_TEMP_IMG);
export const saveIngredientImage = actionCreator(ingredientTypes.SAVE_IMG, 'popupFrom');
export const saveIngredientImageSuccess = actionCreator(
  ingredientTypes.SAVE_IMG_SUCCESS,
  'img',
  'item',
  'popupFrom'
);
export const saveIngredientImageFail = actionCreator(ingredientTypes.SAVE_IMG_FAIL);
export const removeIngredientImage = actionCreator(ingredientTypes.REMOVE_IMG, 'popupFrom');
export const removeIngredientImageSuccess = actionCreator(
  ingredientTypes.REMOVE_IMG_SUCCESS,
  'popupFrom'
);
export const removeIngredientImageFail = actionCreator(ingredientTypes.REMOVE_IMG_FAIL);
//Lakshan
export const updateIngredientImageDetails = actionCreator(
  ingredientTypes.UPDATE_INGREDIENT_IMAGE_DETAILS,
  'imageUrl',
  'imageLoader'
);
export const updateClosedInitialTitle = actionCreator(
  ingredientTypes.UPDATE_CLOSED_INITIAL_TITLE,
  'closedInitialTitle'
);
export const updateIngredientImageRequest = actionCreator(
  ingredientTypes.UPDATE_INGREDIENT_IMAGE,
  'imageUrl',
  'imageLoader'
);
export const deleteIngredientImage = actionCreator(ingredientTypes.DELETE_INGREDIENT_IMAGE);
export const removeTempIngredientImage = () => ({
  type: ingredientTypes.INGREDIENT_IMAGE_CANCEL_CHANGES
});
export const deleteIngredientImageSuccess = actionCreator(
  ingredientTypes.DELETE_INGREDIENT_IMAGE_SUCCESS,
  'imageDeleted'
);
export const ingredientImageLoaderOnly = actionCreator(
  ingredientTypes.INGREDIENT_IMAGE_LOADER_ONLY,
  'imageLoader'
);
export const showIconButtonStoreOnly = actionCreator(
  ingredientTypes.INGREDIENT_SHOW_ICONS,
  'showIconButtonStore'
);

//end lakshan
export const updateIngredientImageSuccess = image => ({
  type: ingredientTypes.UPDATE_INGREDIENT_IMAGE_SUCCESS,
  payload: image
});

export const updateIngredientImageError = () => ({
  type: ingredientTypes.UPDATE_INGREDIENT_IMAGE_FAILURE
});

export const updateIngredientImagePatchSuccess = () => ({
  type: ingredientTypes.UPDATE_INGREDIENT_IMAGE_PATCH_SUCESS
});

export const getIngredientImage = url => ({
  type: ingredientTypes.GET_INGREDIENT_IMAGE,
  payload: url
});

export const getIngredientImageSuccess = (payload, imageLoader) => ({
  type: ingredientTypes.GET_INGREDIENT_IMAGE_SUCCESS,
  payload,
  imageLoader
});

export const setTempIngredientImage = image => ({
  type: ingredientTypes.INGREDIENT_IMAGE_TEMP_IMAGE,
  payload: image
});
export const setTempIngredientImageFormData = imageFormData => ({
  type: ingredientTypes.INGREDIENT_IMAGE_TEMP_IMAGE_FORMDATA,
  payload: imageFormData
});

export const deleteIngredientImageError = () => ({
  type: ingredientTypes.DELETE_INGREDIENT_IMAGE_ERROR
});
// END ingradient image
export const setInitialTitle = actionCreator(
  ingredientTypes.SET_INITIAL_TITLE,
  'title',
  'manufacturer'
);
export const removeInitialTitle = actionCreator(ingredientTypes.REMOVE_INITIAL_TITLE);
export const setCollectionId = actionCreator(ingredientTypes.SET_COLLECTION_ID, 'id');
export const removeCollectionId = actionCreator(ingredientTypes.RESET_COLLECTION_ID);
export const openCatalogueModal = actionCreator(ingredientTypes.OPEN_CATALOGUE);
export const identifyFormChanges = actionCreator(ingredientTypes.IDENTIFY_FORM_CHANGES, 'data');
export const IngredientTagVeganDetectModal = actionCreator(
  ingredientTypes.INGREDIENT_TAG_VEGAN_DETECT_MODAL,
  'title',
  'isVegan'
);
export const reset = actionCreator(ingredientTypes.RESET);
export const updateAlternativeList = actionCreator(
  ingredientTypes.UPDATE_ALTERNATIVE_LIST,
  'alternatives'
);
export const duplicateIngredientResponse = payload => ({
  type: ingredientTypes.DUPLICATE_ING_RESPONSE,
  payload: payload
});

export const getSubLangIngLocale = actionCreator(ingredientTypes.GET_SUB_LANG_LOCALE, 'locale');
