import productTypes from '../../../module/contentManagement/products/redux/product.constants';

/**
 * @description
 *   Reducer to index the distributor catalogue items to efficiently
 *  retrieve brand item and set image of the brand in catalogue_distributor_reducer.js
 */
export default (state = {}, { type, payload }) => {
  switch (type) {
    case productTypes.GET_TAG_LIST_SUCCESS: {
      const { data } = payload;

      const index = {};
      if (data.length) {
        for (let i = 0; i < data.length; i++) {
          index[data[i]._id] = i;
        }
      }

      return index;
    }

    default:
      return state;
  }
};
