import * as imgUloader from '../constant/imageUploader.constants';

export const getImageLIst = processToken => ({
  type: imgUloader.GET_IMAGE_LIST,
  payload: { processToken }
});
export const setImageLIst = imageList => ({
  type: imgUloader.SET_IMAGE_LIST,
  payload: { imageList }
});
export const imageDelete = (imageId, imageList) => ({
  type: imgUloader.DELETE_IMAGE,
  payload: { imageId, imageList }
});
export const imageUpload = (singleImage, imageList, CollectionId, pageType) => ({
  type: imgUloader.UPLOAD_IMAGE,
  payload: { singleImage, imageList, CollectionId, pageType }
});
export const imageUploadSuccess = (CollectionId, imageList, pageType) => ({
  type: imgUloader.UPLOAD_IMAGE_SUCCESS,
  payload: { CollectionId, imageList, pageType }
});
export const setnewImageUrl = (newImageUrl, imageId) => ({
  type: imgUloader.SET_NEW_IMAGE_URL,
  payload: { newImageUrl, imageId }
});
export const imageSwapPosition = (currentPosition, movePosition, imageList) => ({
  type: imgUloader.IMAGE_POSITION_UPDATE,
  payload: { currentPosition, movePosition, imageList }
});
export const imageActivate = (preImgUID, activateImage, imageList) => ({
  type: imgUloader.IMAGE_ACTIVATE,
  payload: { preImgUID, activateImage, imageList }
});
export const setCollectionId = (CollectionId, pageType) => ({
  type: imgUloader.SET_COLLECTION_ID,
  payload: { CollectionId, pageType }
});
export const imageUploadLoader = loader => ({
  type: imgUloader.IMAGE_UPLOAD_LOADER,
  payload: { loader }
});

export const displaySaveDeleteBtn = status => ({
  type: imgUloader.DISPLAY_SAVE_DELETE_BUTTON,
  payload: { status }
});

export const resetImages = () => ({
  type: imgUloader.RESET_IMAGES
});

export const changeMountState = mountState => ({
  type: imgUloader.CHANGE_IMAGE_UPLOAD_PAGE_MOUNT,
  payload: { mountState }
});

export const getClickedImage = (file, cb, props) => ({
  type: imgUloader.GET_CLICKED_IMAGE,
  payload: { file, cb, props }
});
