import * as profileActions from './profile.constants';

export const updateBasicInfo = values => ({
  type: profileActions.BASIC_INFO_UPDATE,
  payload: values
});

export const updateBasicInfoSuccess = values => ({
  type: profileActions.BASIC_INFO_UPDATE_SUCCESS,
  payload: values
});

export const updateChangePassword = values => ({
  type: profileActions.CHANGE_PASSWORD_UPDATE,
  payload: values
});

export const updateChangePasswordSuccess = values => ({
  type: profileActions.CHANGE_PASSWORD_UPDATE_SUCCESS,
  payload: values
});

export const updateProfileImageRequest = (image, cb = null) => ({
  type: profileActions.PROFILE_IMAGE_UPDATE,
  payload: {
    image,
    cb
  }
});

export const updateProfileImageSuccess = image => ({
  type: profileActions.PROFILE_IMAGE_UPDATE_SUCCESS,
  payload: image
});

export const updateProfileImageError = () => ({
  type: profileActions.PROFILE_IMAGE_UPDATE_FAILURE
});

export const updateBasicSettings = values => ({
  type: profileActions.BASIC_SETTINGS_UPDATE,
  payload: values
});

export const updateBasicSettingsSuccess = values => ({
  type: profileActions.BASIC_SETTINGS_UPDATE_SUCCESS,
  payload: values
});

export const getProfileInfo = () => ({ type: profileActions.PROFILE_INFO_GET });

export const getProfileInfoSuccess = payload => ({
  type: profileActions.PROFILE_INFO_GET_SUCCESS,
  payload
});

export const resetProfileToInitial = () => ({
  type: profileActions.RESET_PROFILE_TO_INITIAL
});

export const getProfileImage = () => ({ type: profileActions.PROFILE_IMAGE_GET });

export const getProfileImageSuccess = (payload, imageLoader) => ({
  type: profileActions.PROFILE_IMAGE_GET_SUCCESS,
  payload,
  imageLoader
});

export const showBtnLoader = () => ({
  type: profileActions.SHOW_LOADER
});

export const hideBtnLoader = () => ({
  type: profileActions.HIDE_LOADER
});

export const showMessage = () => ({
  type: profileActions.SHOW_MESSAGE
});

export const hideMessage = () => ({
  type: profileActions.HIDE_MESSAGE
});
