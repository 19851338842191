import { actionCreator } from 'redux-action-creator';
import brandTypes from './catalogue_brand.constants';

export const getDataList = actionCreator(brandTypes.GET_DATA_LIST, 'pagination', 'sort');
export const getDataListSuccess = actionCreator(
  brandTypes.GET_DATA_LIST_SUCCESS,
  'dataList',
  'pagination',
  'sort'
);
export const getDataListFail = actionCreator(brandTypes.GET_DATA_LIST_FAIL);
export const deleteBrand = actionCreator(brandTypes.DELETE, 'row');
export const deleteBrandSuccess = actionCreator(brandTypes.DELETE_SUCCESS, 'id');
export const deleteBrandFail = actionCreator(brandTypes.DELETE_FAIL);
export const setBrandImage = actionCreator(brandTypes.SET_BRAND_IMG, 'id', 'image', 'indexes');
export const reset = actionCreator(brandTypes.RESET);
export const setSearchLoader = actionCreator(brandTypes.SET_SEARCH_LOADER, 'searchLoader');
export const getSearchSuggestion = actionCreator(brandTypes.GET_SEARCH_SUGGESTION, 'searchValue');
export const getSearchSuggestionSuccess = actionCreator(
  brandTypes.GET_SEARCH_SUGGESTION_SUCCESS,
  'searchResults'
);
export const addTags = actionCreator(brandTypes.ADD_TAG, 'tag', 'group');
export const addTagsSuccess = actionCreator(brandTypes.ADD_TAG_SUCCESS, 'tags');
export const deleteTag = actionCreator(brandTypes.DELETE_TAG, 'tag');
export const deleteTagSuccess = actionCreator(brandTypes.DELETE_TAG_SUCCESS, 'tags');
export const setSearchProductIds = actionCreator(brandTypes.SET_SEARCH_IDS, 'ids');
