import { actionCreator } from 'redux-action-creator';
import types from './imageUploader.constants';

export const getImageLIst = actionCreator(types.GET_IMAGE_LIST);
export const setImageLIst = actionCreator(types.SET_IMAGE_LIST, 'imageList');
export const imageDelete = actionCreator(types.DELETE_IMAGE, 'imageId', 'imageList');
export const imageUpload = actionCreator(
  types.UPLOAD_IMAGE,
  'singleImage',
  'imageList',
  'CollectionId',
  'pageType'
);
export const imageUploadSuccess = actionCreator(
  types.UPLOAD_IMAGE_SUCCESS,
  'CollectionId',
  'imageList',
  'pageType'
);
export const setnewImageUrl = actionCreator(types.SET_NEW_IMAGE_URL, 'newImageUrl', 'imageId');
export const imageSwapPosition = actionCreator(
  types.IMAGE_POSITION_UPDATE,
  'currentPosition',
  'movePosition',
  'imageList'
);
export const imageActivate = actionCreator(
  types.IMAGE_ACTIVATE,
  'preImgUID',
  'activateImage',
  'imageList'
);
export const setCollectionId = actionCreator(types.SET_COLLECTION_ID, 'CollectionId', 'pageType');
export const imageUploadLoader = actionCreator(types.IMAGE_UPLOAD_LOADER, 'loader');
export const displaySaveDeleteBtn = actionCreator(types.DISPLAY_SAVE_DELETE_BUTTON, 'status');
export const resetImage = actionCreator(types.RESET_IMAGE);
