import productTypes from '../../../module/contentManagement/products/redux/product.constants';

export default (state = {}, { type, payload }) => {
  switch (type) {
    case productTypes.GET_STORE_LIST_SUCCESS: {
      const { data } = payload;
      data.splice(0, 0, {
        id: '0000',
        title: 'None',
        branch: '',
        mediaCollectionId: '',
        imageUrl: ''
      });
      const index = {};
      if (data.length) {
        for (let i = 0; i < data.length; i++) {
          index[data[i].id] = i;
        }
      }

      return index;
    }

    default:
      return state;
  }
};
