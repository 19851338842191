import {
  takeLatest,
  all,
  fork,
  put,
  call,
  select,
  cancelled,
  cancel,
  take,
  race
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { doRequest } from 'app/shared/redux/sagas/api';
import { message } from 'antd';
import { filterCountryEmojis } from '../../../../../shared/utils/index';
import moderationTypes from './details.constants';
import {
  getSIngleDataSuccess,
  getDataList,
  getProductListSuccess,
  getNutrientsValueListSuccess,
  updateNutritionLoadedStatus,
  getCategoryListSuccess,
  updateCategoryLoader,
  getIngredientListSuccess,
  updateIngredientLoader,
  getEnumberListSuccess,
  getStoreListSuccess,
  getStoreTypeListSuccess,
  getTagListSuccess,
  setTagImageSuccess,
  getProductByLocalSuccess,
  setProductStore,
  deleteProductSuccess,
  updateSaveButtonStatus,
  updateProductDetailLoadStatus,
  updateProductLocale,
  getMayContainValueSuccess,
  getLabelsListSuccess,
  mayContaintLoader,
  setStoreTypeImageSuccess,
  lablesListLoader,
  setStoreImage,
  getStoreUnicProductPriceSuccess,
  getProductID,
  getBracketpriceSuccess,
  getBracketprice,
  getOldSingleProductDataSuccess,
  manufactureListSuccess,
  brandListSuccess,
  distributorListSuccess,
  reset,
  getTiketSelectedStoreImageSuccess,
  getOldStorePriceSuccess,
  setTicketSaveLoader
} from './details.action';
import { deleteTicketSuccess } from '../listredux/list.actions';
import normalizeTreeByLocale from '../../../../../shared/utils/normalizeTreeByLocale';
import { setCollectionId, getImageLIst } from '../imageRedux/imageUploader.actions';
import { getAlternativeTitle } from '../../../../system/ingredients/redux/ingredients.reducer';
import setdefaultDataSort from '../../../../../shared/utils/setdefaultDataSort';
import { map } from 'react-sortable-tree';

import { addJobToQueue } from './../../../../../shared/redux/actions/index';
import {
  setIngredientArray,
  setENumbersArray,
  getIngredientArrayWorkerSuccess,
  getEnumberArrayWorkerSuccess
} from './../../../../../shared/textEditor/redux/textEditorPopup.actions';
import {
  createProcessToken,
  returnProcessToken
} from './../../../../../shared/redux/sagas/productImageRequest';

function getmoderationDataList() {}
function* getProductList() {
  yield put(mayContaintLoader({ loader: true }));
  yield put(lablesListLoader({ loader: true }));

  yield put(updateNutritionLoadedStatus({ data: false }));

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: 'prodres/products'
    });

    let modifiedArray = [];

    if (response.data.length > 0) {
      response.data.forEach(element => {
        let elementModified = {
          _id: element._id,
          key: element._id,
          ean: element.ean
        };
        modifiedArray.push(elementModified);
      });
    }

    yield put(updateNutritionLoadedStatus({ data: false }));

    yield put(getProductListSuccess({ data: modifiedArray }));
  } catch (error) {}
}

function* getIngredientList(payload) {
  const dataLocal = payload.payload.data;

  yield put(updateIngredientLoader({ loader: true }));

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: 'prodsup/ingredient/get'
    });

    let selectLocal = 'en_US';
    if (dataLocal) {
      selectLocal = dataLocal.locale ? dataLocal.locale : 'en_US';
    }

    let defaulLocal = 'en_US';
    let languageList = yield select(state => state.languageList.languageList);
    let profileLang = yield select(state => state.profile.language);

    let profileLocal = 'en_US';
    languageList.filter(item => {
      if (item.id == profileLang) {
        profileLocal = item.locale;
      }
    });

    let mayContaintArrayPassing = getMayContaintByProfileLocal(
      response.data,
      [],
      profileLocal,
      defaulLocal
    );

    let arrayPassing = getIngredientListByLocale(response.data, [], selectLocal, defaulLocal);

    let sortedArary = setdefaultDataSort(arrayPassing, 'title');

    yield put(getMayContainValueSuccess({ data: mayContaintArrayPassing }));
    yield put(getIngredientListSuccess({ data: sortedArary }));
    yield delay(100);
    yield put(updateIngredientLoader({ loader: false }));
    yield put(mayContaintLoader({ loader: false }));

    const workerParams = {
      ingList: response.data,
      locale: 'en_US'
    };

    yield put(
      addJobToQueue({
        workerAction: 'SET_ARRAY_LIST',
        data: workerParams,
        actionToDispatch: getIngredientArrayWorkerSuccess
      })
    );

    const { payload } = yield take('TEXT_EDITOR_GET_INGREDIENT_ARRAY_WORKER_SUCCESS');

    if (payload.data.result) {
      let ingArray = payload.data.result;

      yield put(setIngredientArray({ data: ingArray }));
    }
  } catch (error) {
    yield put(updateIngredientLoader({ loader: false }));
  }
}

function* getEnumberList(payload) {
  const dataLocal = payload.payload.data;

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: 'prodprop/enums/'
    });

    let selectLocal = 'en_US';
    if (dataLocal) {
      selectLocal = dataLocal.locale ? dataLocal.locale : 'en_US';
    }

    let defaulLocal = 'en_US';

    let arrayPassing = getEnumberListByLocale(response.data, [], selectLocal, defaulLocal);
    yield put(getEnumberListSuccess({ data: arrayPassing }));

    const workerParams = {
      enumList: response.data,
      locale: 'en_US'
    };

    yield put(
      addJobToQueue({
        workerAction: 'SET_ENUM_ARRAY_LIST',
        data: workerParams,
        actionToDispatch: getEnumberArrayWorkerSuccess
      })
    );

    let payload1 = yield take('TEXT_EDITOR_GET_ENUMBER_ARRAY_WORKER_SUCCESS');

    if (payload1.payload.data.result) {
      let eNumbersArray = payload1.payload.data.result;

      yield put(setENumbersArray({ data: eNumbersArray }));
    }
  } catch (error) {}
}

function compareStoreNameAndBranch(a, b) {
  const { title, branch = '' } = a;
  const { title: titleB, branch: branchB = '' } = b;

  const leftString = `${title} ${branch}`.toLowerCase();
  const rightString = `${titleB} ${branchB}`.toLowerCase();

  if (leftString > rightString) {
    return 1;
  } else if (leftString < rightString) {
    return -1;
  }

  return 0;
}

function getStoreListByLocale(
  dataArray,
  newArray,
  passedLocal,
  defaultLocal,
  countryList,
  selectLocalCountry,
  selectedStore
) {
  dataArray.forEach((item, index) => {
    if (item.country == selectLocalCountry) {
      const countrySelected = item.country ? countryList[item.country] : '';

      let objEnumber = {
        id: item._id,
        country: countrySelected,
        storeTypeId: item.storetype ? item.storetype : '',
        title: '',
        branch: '',
        mediaCollectionId: item.mediaCollectionId
      };

      if (item.translations) {
        let languagesArray = item.translations;

        objEnumber.title = getStoreFieldByLocal(languagesArray, passedLocal, defaultLocal, 'name');
        objEnumber.branch = getStoreFieldByLocal(
          languagesArray,
          passedLocal,
          defaultLocal,
          'branch'
        );

        newArray.push(objEnumber);
      } else {
        if (item.title) {
          objEnumber.title = item.title;
        }
        newArray.push(objEnumber);
      }
    }
  });

  return newArray;
}

function getStoreFieldByLocal(languagesArray, passedLocal, defaultLocal, field) {
  let xxx = '';
  if (languagesArray[passedLocal] && languagesArray[passedLocal][field]) {
    xxx = languagesArray[passedLocal][field];
  } else if (languagesArray[defaultLocal] && languagesArray[defaultLocal][field]) {
    xxx = languagesArray[defaultLocal][field];
  } else {
    for (var key in languagesArray) {
      if (languagesArray[key] && languagesArray[key][field]) {
        let firstChild = languagesArray[key][field];
        xxx = firstChild;
        break;
      }
      // object[prop]
    }
  }

  return xxx;
}

function* getAllLabelList() {
  let defaulLocal = 'en_US';
  let languageList = yield select(state => state.languageList.languageList);
  let profileLang = yield select(state => state.profile.language);

  let profileLocal = 'en_US';

  try {
    languageList.filter(item => {
      if (item.id == profileLang) {
        profileLocal = item.locale;
      }
    });

    const { data } = yield call(doRequest, {
      method: 'GET',
      url: 'prodprop/labels/'
    });

    let LablesArrayPassing = getLabelsByProfileLocal(data, [], profileLocal, defaulLocal);

    yield put(getLabelsListSuccess({ data: LablesArrayPassing }));
  } catch (error) {
  } finally {
    yield put(lablesListLoader({ loader: false }));
  }
}

function getLabelsByProfileLocal(dataArray, newArray, profileLocal, defaulLocal) {
  dataArray.forEach(item => {
    let objLabel = {
      id: item.id,
      title: '',
      countries: item.countries ? item.countries : []
    };

    if (item.languages) {
      let languagesArray = item.languages;
      if (languagesArray[profileLocal]) {
        objLabel.title = languagesArray[profileLocal];
        newArray.push(objLabel);
      } else if (languagesArray[defaulLocal]) {
        objLabel.title = languagesArray[defaulLocal];
        newArray.push(objLabel);
      } else {
        for (var key in languagesArray) {
          if (languagesArray[key]) {
            let firstChild = languagesArray[key];
            objLabel.title = firstChild;

            newArray.push(objLabel);
            break;
          }
        }
      }
    } else {
      objLabel.title = item.title;
      newArray.push(objLabel);
    }
  });

  return newArray;
}

function getMayContaintByProfileLocal(dataArray, newArray, profileLocal, defaulLocal) {
  let mayContaintValArray = dataArray.filter(item => item.isMayContain === true);

  mayContaintValArray.forEach(item => {
    let objMayContaint = {
      id: item.id,
      title: ''
    };

    if (item.languages) {
      let languagesArray = item.languages;
      if (languagesArray[profileLocal]) {
        objMayContaint.title = languagesArray[profileLocal];
        newArray.push(objMayContaint);
      } else if (languagesArray[defaulLocal]) {
        objMayContaint.title = languagesArray[defaulLocal];
        newArray.push(objMayContaint);
      } else {
        for (var key in languagesArray) {
          if (languagesArray[key]) {
            let firstChild = languagesArray[key];
            objMayContaint.title = firstChild;

            newArray.push(objMayContaint);
            break;
          }
        }
      }
    } else {
      objMayContaint.title = item.title;
      newArray.push(objMayContaint);
    }
  });

  return newArray;
}

function getEnumberListByLocale(dataArray, newArray, passedLocal, defaultLocal) {
  dataArray.forEach(item => {
    let objEnumber = {
      id: item._id,
      title: '',
      serial: 'E' + item.eNumber,
      isVegan: item.isVegan
    };

    if (item.translations) {
      let languagesArray = item.translations;
      if (languagesArray[passedLocal] && languagesArray[passedLocal].name) {
        objEnumber.title = languagesArray[passedLocal].name;

        newArray.push(objEnumber);
      } else if (languagesArray[defaultLocal] && languagesArray[defaultLocal].name) {
        objEnumber.title = languagesArray[defaultLocal].name;
        newArray.push(objEnumber);
      } else {
        let firstChild;
        for (var key in languagesArray) {
          if (languagesArray[key] && languagesArray[key].name) {
            firstChild = '';
            firstChild = languagesArray[key].name;
            objEnumber.title = firstChild;

            newArray.push(objEnumber);
            break;
          }
          // object[prop]
        }
        if (!firstChild) {
          newArray.push(objEnumber);
        }
      }
    } else {
      if (item.title) {
        objEnumber.title = item.title;
      }
      newArray.push(objEnumber);
    }
  });

  return newArray;
}

function getIngredientListByLocale(dataArray, newArray, passedLocal, defaultLocal) {
  dataArray.forEach(item => {
    let objIngredient = {
      id: item.id,
      position: item.position,
      title: '',
      ingLocale: 'en_US',
      isVegan: item.isVegan,
      alternativeTitle: ''
    };

    if (item.alternativeTitle) {
      objIngredient.alternativeTitle = getAlternativeTitle(passedLocal, item.alternativeTitle);
    }

    if (item.languages) {
      let languagesArray = item.languages;
      if (languagesArray[passedLocal]) {
        objIngredient.title = languagesArray[passedLocal];
        objIngredient.ingLocale = passedLocal;

        newArray.push(objIngredient);
      } else if (languagesArray[defaultLocal]) {
        objIngredient.title = languagesArray[defaultLocal];
        objIngredient.ingLocale = defaultLocal;
        newArray.push(objIngredient);
      } else {
        for (var key in languagesArray) {
          if (languagesArray[key]) {
            let firstChild = languagesArray[key];
            objIngredient.title = firstChild;
            objIngredient.ingLocale = key;

            newArray.push(objIngredient);
            break;
          }
          // object[prop]
        }
      }
    } else {
      objIngredient.title = item.title;
      newArray.push(objIngredient);
    }
  });

  return newArray;
}

function getUnit(languagesArray, passedLocal, defaultLocal) {
  let unitsValue = '';
  if (languagesArray[passedLocal] && languagesArray[passedLocal].units) {
    unitsValue = languagesArray[passedLocal].units;
  } else if (languagesArray[defaultLocal] && languagesArray[defaultLocal].units) {
    unitsValue = languagesArray[defaultLocal].units;
  } else {
    for (var key in languagesArray) {
      if (languagesArray[key] && languagesArray[key].units) {
        unitsValue = languagesArray[key].units;
        break;
      }
      // object[prop]
    }
  }
  return unitsValue;
}

function getScale(languagesArray, passedLocal, defaultLocal) {
  let scaleValue = '';
  if (languagesArray[passedLocal] && languagesArray[passedLocal].scale) {
    scaleValue = languagesArray[passedLocal].scale;
  } else if (languagesArray[defaultLocal] && languagesArray[defaultLocal].scale) {
    scaleValue = languagesArray[defaultLocal].scale;
  } else {
    for (var key in languagesArray) {
      if (languagesArray[key] && languagesArray[key].scale) {
        scaleValue = languagesArray[key].scale;
        break;
      }
      // object[prop]
    }
  }
  return scaleValue;
}

function getNutriententListValue(type, dataArray, newArray, passedLocal, defaultLocal) {
  dataArray.forEach(item => {
    if (item.languages) {
      if (type !== 'energy') {
        if (type === 'comp') {
          let objNutritioValue = {
            id: item.id,
            position: item.position,
            name: ''
          };
          let languagesArray = item.languages;
          if (languagesArray[passedLocal] && languagesArray[passedLocal].name) {
            objNutritioValue.name = languagesArray[passedLocal].name;
            if (languagesArray[passedLocal].measure_standard) {
              objNutritioValue['measure_standard'] = languagesArray[passedLocal].measure_standard;
            }

            newArray.push(objNutritioValue);
          } else if (languagesArray[defaultLocal] && languagesArray[defaultLocal].name) {
            objNutritioValue.name = languagesArray[defaultLocal].name;

            if (languagesArray[defaultLocal].measure_standard) {
              objNutritioValue['measure_standard'] = languagesArray[defaultLocal].measure_standard;
            }

            newArray.push(objNutritioValue);
          } else {
            for (var key in languagesArray) {
              if (languagesArray[key] && languagesArray[key].name) {
                let firstChild = languagesArray[key];
                objNutritioValue.name = firstChild.name;

                if (firstChild.measure_standard) {
                  objNutritioValue['measure_standard'] = firstChild.measure_standard;
                }

                newArray.push(objNutritioValue);
                break;
              }
              // object[prop]
            }
          }
        } else {
          let objNutritioValue = {
            id: item.id,
            position: item.position,
            name: '',
            scale: '',
            units: ''
          };
          let languagesArray = item.languages;
          if (languagesArray[passedLocal] && languagesArray[passedLocal].name) {
            objNutritioValue.name = languagesArray[passedLocal].name;

            objNutritioValue.units = getUnit(languagesArray, passedLocal, defaultLocal);
            // if (languagesArray[passedLocal].units) {
            //   objNutritioValue.units = languagesArray[passedLocal].units;
            // }

            objNutritioValue.scale = getScale(languagesArray, passedLocal, defaultLocal);

            newArray.push(objNutritioValue);
          } else if (languagesArray[defaultLocal] && languagesArray[defaultLocal].name) {
            objNutritioValue.name = languagesArray[defaultLocal].name;

            objNutritioValue.units = getUnit(languagesArray, passedLocal, defaultLocal);

            objNutritioValue.scale = getScale(languagesArray, passedLocal, defaultLocal);

            newArray.push(objNutritioValue);
          } else {
            for (var key in languagesArray) {
              if (languagesArray[key] && languagesArray[key].name) {
                let firstChild = languagesArray[key];
                objNutritioValue.name = firstChild.name;

                objNutritioValue.scale = getScale(languagesArray, passedLocal, defaultLocal);

                objNutritioValue.units = getUnit(languagesArray, passedLocal, defaultLocal);

                newArray.push(objNutritioValue);
                break;
              }
              // object[prop]
            }
          }
        }
      } else {
        let objNutritioValue = {
          id: item.id,
          position: item.position,
          name: '',
          name_2: '',
          name_3: ''
        };
        let languagesArray = item.languages;
        if (languagesArray[passedLocal] && languagesArray[passedLocal].name) {
          if (languagesArray[passedLocal].name) {
            objNutritioValue.name = languagesArray[passedLocal].name;
          }
          if (languagesArray[passedLocal].name_2) {
            objNutritioValue.name_2 = languagesArray[passedLocal].name_2;
          }
          if (languagesArray[passedLocal].name_3) {
            objNutritioValue.name_3 = languagesArray[passedLocal].name_3;
          }

          newArray.push(objNutritioValue);
        } else if (languagesArray[defaultLocal] && languagesArray[defaultLocal].name) {
          if (languagesArray[defaultLocal].name) {
            objNutritioValue.name = languagesArray[defaultLocal].name;
          }
          if (languagesArray[defaultLocal].name_2) {
            objNutritioValue.name_2 = languagesArray[defaultLocal].name_2;
          }
          if (languagesArray[defaultLocal].name_3) {
            objNutritioValue.name_3 = languagesArray[defaultLocal].name_3;
          }
          newArray.push(objNutritioValue);
        } else {
          for (var key in languagesArray) {
            if (languagesArray[key]) {
              if (languagesArray[key].name) {
                objNutritioValue.name = languagesArray[key].name;
              }
              if (languagesArray[key].name_2) {
                objNutritioValue.name_2 = languagesArray[key].name_2;
              }
              if (languagesArray[key].name_3) {
                objNutritioValue.name_3 = languagesArray[key].name_3;
              }

              newArray.push(objNutritioValue);
              break;
            }
            // object[prop]
          }
        }
      }
    }
  });

  return newArray;
}

function* getListNutritionValues(payload) {
  const dataLocal = payload.payload.data;

  yield put(updateNutritionLoadedStatus({ data: false }));

  const response = yield call(doRequest, {
    method: 'GET',
    url: 'prodprop/nutritions/'
  });

  let dataResponse = response.data ? response.data : {};
  let selectLocal = 'en_US';
  if (dataLocal) {
    selectLocal = dataLocal.locale ? dataLocal.locale : 'en_US';
  }

  let defaulLocal = 'en_US';

  let comparison = dataResponse.comp
    ? getNutriententListValue('comp', dataResponse.comp, [], selectLocal, defaulLocal)
    : [];

  let energy = dataResponse.energy
    ? getNutriententListValue('energy', dataResponse.energy, [], selectLocal, defaulLocal)
    : [];

  let nutritionalValue = dataResponse.nut_val
    ? getNutriententListValue(
        'nutritionalValue',
        dataResponse.nut_val,
        [],
        selectLocal,
        defaulLocal
      )
    : [];

  let vitamins = dataResponse.vitamin
    ? getNutriententListValue('vitamins', dataResponse.vitamin, [], selectLocal, defaulLocal)
    : [];

  let minerals = dataResponse.minaral
    ? getNutriententListValue('minerals', dataResponse.minaral, [], selectLocal, defaulLocal)
    : [];

  let other = dataResponse.other
    ? getNutriententListValue('other', dataResponse.other, [], selectLocal, defaulLocal)
    : [];

  let commonSupplements = dataResponse.comm_in_supp
    ? getNutriententListValue(
        'commonSupplements',
        dataResponse.comm_in_supp,
        [],
        selectLocal,
        defaulLocal
      )
    : [];

  yield put(
    getNutrientsValueListSuccess({
      data: {
        comparison: comparison,
        energy: energy,
        nutritionalValue: nutritionalValue,
        vitamins: vitamins,
        minerals: minerals,
        other: other,
        commonSupplements: commonSupplements
      }
    })
  );

  yield put(updateNutritionLoadedStatus({ data: true }));
}

function* getStoreTypeList() {
  try {
    const { data } = yield call(doRequest, {
      method: 'GET',
      url: 'storeprop/storeprop'
    });

    let storeTypeArray = [];
    if (data && data.store_type && data.store_type.length > 0) {
      data.store_type.forEach(element => {
        if (element.non_translate) {
          storeTypeArray.push(element.non_translate);
        }
      });
    }

    yield put(
      getStoreTypeListSuccess({
        data: storeTypeArray
      })
    );
    let imageFork;

    if (storeTypeArray && storeTypeArray.length > 0) {
      // imageFork = yield fork(getStoreAllStoreTypeimages, storeTypeArray);
    }
  } catch (error) {}
}

// get image array
function chunk(array, size) {
  const chunked_arr = [];
  let index = 0;
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
}

/**
 *
 * @param {Array} dataList StoreList
 *
 * Retrieve Store Images
 */

// start get store droupdown list and image get

function* getStoreList(payload) {
  const dataLocal = payload.payload.data;

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: 'storeres/storedata/list'
    });

    let selectLocal = 'en_US';
    let selectLocalCountry = '6';
    if (dataLocal) {
      selectLocal = dataLocal.locale ? dataLocal.locale : 'en_US';
      selectLocalCountry = dataLocal.localeCountryId ? dataLocal.localeCountryId : '6';
    }

    let defaulLocal = 'en_US';

    const countries = yield select(s => s.indexedItem.countries);
    const selectedStore = yield select(s => s.moderation.moderationDetails.store);

    let arrayPassing = getStoreListByLocale(
      response.data,
      [],
      selectLocal,
      defaulLocal,
      countries,
      selectLocalCountry,
      selectedStore
    );

    let storesTypeArray = yield select(state => state.moderation.moderationDetails.storesTypeArray);

    if (arrayPassing && arrayPassing.length > 0 && storesTypeArray && storesTypeArray.length > 0) {
      arrayPassing.forEach((element, index) => {
        if (element.storeTypeId) {
          let xxx = storesTypeArray.find(
            elementStoreType => elementStoreType._id === element.storeTypeId
          );
          if (xxx) {
            element['store_type_url'] = xxx.imageUrl;
          }
        }
      });
    }

    let sortedArary = arrayPassing.slice().sort(compareStoreNameAndBranch);

    yield put(getStoreListSuccess({ data: sortedArary }));
    yield fork(getStoreimages, arrayPassing);
  } catch (error) {}
}

function* getStoreimages(dataList = []) {
  let storeSampleObj = {
    id: '0000',
    title: 'None',
    branch: '',
    mediaCollectionId: '',
    imageUrl: ''
  };
  dataList.push(storeSampleObj);

  const chunkedList = yield call(chunk, dataList, 10);

  for (let list of chunkedList) {
    const requests = list.map(item =>
      item && item.id !== '0000' && item.mediaCollectionId
        ? call(getStoreLableimage, item.id, item.mediaCollectionId)
        : ''
    );
    const responses = yield all(requests);
    const filtertedReponse = responses.filter(response => (response ? true : false));
    const indexes = yield call(getStoreIndex);
    yield put(setStoreImage({ images: filtertedReponse, indexes }));
    yield delay(2000);
  }
}

function* getStoreIndex() {
  const items = yield select(state => state.indexedItem.stores);
  return items;
}
// ................end dtore list and store image get

// get image indexes
function* getIndexedTagsIds() {
  const items = yield select(state => state.indexedItem.tags);

  return items;
}

function* addProductFun({ payload }) {
  let intl = yield select(state => state.intlReact.intl);
  const { data, history, ticketId } = payload.data;

  data['ticketID'] = ticketId;
  data['ticketStatus'] = 'accept';

  if (data && data.non_translate.date) {
    delete data.non_translate.date;
  }

  let fileList = yield select(state => state.moderation.moderationImageList.fileList);

  if (fileList.length > 0) {
    try {
      let modImageArray = fileList.map(item => {
        let obj = {
          collectionId: item.collectionId,
          pageId: item.uid,
          status: item.active
        };
        return obj;
      });

      let dataPassed = {
        productID: '',
        CollectionId: '',
        images: modImageArray
      };

      const response = yield call(doRequest, {
        method: 'PATCH',
        url: 'unimup/objctupload/ticketmerge/',
        data: dataPassed
      });

      if (response.status === 200) {
        if (response.data[0].id) {
          data.non_translate.mediaCollectionId = response.data[0].id;
        }
        try {
          const response = yield call(doRequest, {
            method: 'POST',
            url: 'prodres/products',
            data: data
          });
          if (response.status === 200) {
            message.info(
              intl.formatMessage({
                id: 'Generic.ApiMessages.Products.ProductAddSuccess'
              })
            );
          }
          delay(1000);
          history.push('/content-management/moderation');
          yield put(setTicketSaveLoader({ data: false }));
        } catch (error) {
          yield put(updateSaveButtonStatus());
          if (
            error.response.data &&
            error.response.data.message &&
            error.response.data.message.trim() == 'Ean number alrady in use'
          ) {
            message.error(
              intl.formatMessage({
                id: 'Generic.ApiMessages.Products.ProductEANAlreadyUseError'
              })
            );
          } else {
            message.error(
              intl.formatMessage({
                id: 'Generic.ApiMessages.Products.ProductSavingError'
              })
            );
          }
          yield put(setTicketSaveLoader({ data: false }));
        }
      }
    } catch (error) {}
  } else {
    try {
      const response = yield call(doRequest, {
        method: 'POST',
        url: 'prodres/products',
        data: data
      });
      if (response.status === 200) {
        message.info(
          intl.formatMessage({
            id: 'Generic.ApiMessages.Products.ProductAddSuccess'
          })
        );
      }

      delay(1000);
      history.push('/content-management/moderation');
      yield put(setTicketSaveLoader({ data: false }));
    } catch (error) {
      yield put(updateSaveButtonStatus());
      if (
        error.response.data &&
        error.response.data.message &&
        error.response.data.message.trim() == 'Ean number alrady in use'
      ) {
        message.error(
          intl.formatMessage({
            id: 'Generic.ApiMessages.Products.ProductEANAlreadyUseError'
          })
        );
      } else {
        message.error(
          intl.formatMessage({
            id: 'Generic.ApiMessages.Products.ProductSavingError'
          })
        );
      }
      yield put(setTicketSaveLoader({ data: false }));
    }
  }
}

function* editProductFun({ payload }) {
  let intl = yield select(state => state.intlReact.intl);

  const { data, pid, history, ticketId } = payload.data;
  if (data && data.non_translate.date) {
    // api check date format string and it also check date is required true
    delete data.non_translate.date;
  }

  data['ticketID'] = ticketId;
  data['ticketStatus'] = 'accept';

  let fileList = yield select(state => state.moderation.moderationImageList.fileList);

  if (fileList.length > 0) {
    try {
      let modImageArray = fileList.map(item => {
        let obj = {
          collectionId: item.collectionId,
          pageId: item.uid,
          status: item.active
        };
        return obj;
      });

      let collectionId = '';
      if (data && data.non_translate && data.non_translate.mediaCollectionId) {
        collectionId = data.non_translate.mediaCollectionId;
      }

      let dataPassed = {
        productID: pid,
        CollectionId: collectionId,
        images: modImageArray
      };

      const response = yield call(doRequest, {
        method: 'PATCH',
        url: 'unimup/objctupload/ticketmerge/',
        data: dataPassed
      });

      if (response.status === 200) {
        if (response.data[0].id) {
          data.non_translate.mediaCollectionId = response.data[0].id;
        }

        try {
          const response = yield call(doRequest, {
            method: 'PUT',
            url: 'prodres/products/' + pid,
            data: data
          });
          yield put(
            setProductStore({ store: data.non_translate.store ? data.non_translate.store : '' })
          );
          message.info(
            intl.formatMessage({
              id: 'Generic.ApiMessages.Products.ProductEditSuccess'
            })
          );
          delay(1000);
          history.push('/content-management/moderation');
          yield put(setTicketSaveLoader({ data: false }));
        } catch (error) {
          if (
            error.response.data &&
            error.response.data.message &&
            error.response.data.message.trim() == 'Invalied EAN'
          ) {
            message.error(
              intl.formatMessage({
                id: 'Generic.ApiMessages.Products.ProductEANSavingError'
              })
            );
          } else {
            message.error(
              intl.formatMessage({
                id: 'Generic.ApiMessages.Products.ProductSavingError'
              })
            );
          }
          yield put(updateSaveButtonStatus());
          yield put(setTicketSaveLoader({ data: false }));
        }
      }
    } catch (error) {}
  } else {
    try {
      const response = yield call(doRequest, {
        method: 'PUT',
        url: 'prodres/products/' + pid,
        data: data
      });

      yield put(
        setProductStore({ store: data.non_translate.store ? data.non_translate.store : '' })
      );

      message.info(
        intl.formatMessage({
          id: 'Generic.ApiMessages.Products.ProductEditSuccess'
        })
      );

      delay(1000);
      history.push('/content-management/moderation');
      yield put(setTicketSaveLoader({ data: false }));
    } catch (error) {
      if (
        error.response.data &&
        error.response.data.message &&
        error.response.data.message.trim() == 'Invalied EAN'
      ) {
        message.error(
          intl.formatMessage({
            id: 'Generic.ApiMessages.Products.ProductEANSavingError'
          })
        );
      } else {
        message.error(
          intl.formatMessage({
            id: 'Generic.ApiMessages.Products.ProductSavingError'
          })
        );
      }
      yield put(updateSaveButtonStatus());
      yield put(setTicketSaveLoader({ data: false }));
    }
  }
}
function* deleteProductFun({ payload }) {
  let intl = yield select(state => state.intlReact.intl);

  const { data } = payload;

  let productList = yield select(s => s.product.productList);

  try {
    const response = yield call(doRequest, {
      method: 'DELETE',
      url: 'prodres/products/' + data.productId
    });
    if (response.status === 200) {
      message.info(
        intl.formatMessage({
          id: 'Generic.ApiMessages.Products.ProductDeleteSuccess'
        })
      );
      let newProductList = productList.filter(item => item._id !== data.productId);
      yield put(deleteProductSuccess({ data: newProductList }));
      yield put(setTicketSaveLoader({ data: false }));
    }
  } catch (error) {}
}
function* deleteTicketFun({ payload }) {
  let intl = yield select(state => state.intlReact.intl);

  const { data, history } = payload;

  let ticketList = yield select(state => state.moderation.moderationList.originalData);
  try {
    const response = yield call(doRequest, {
      method: 'PATCH',
      url: 'prodmod/product_moderation/status/' + data + '/reject'
    });
    if (response.status === 200) {
      message.info(
        intl.formatMessage({
          id: 'Generic.ApiMessages.Products.ProductDeleteSuccess'
        })
      );
      //let newTicketList = ticketList.filter(item => item._id !== data);
      //yield put(deleteTicketSuccess({ data: newTicketList }));
      delay(100);
      history.push('/content-management/moderation');
      yield put(reset());
      yield put(setTicketSaveLoader({ data: false }));
    } else {
      yield put(setTicketSaveLoader({ data: false }));
    }
  } catch (error) {}
}

// get store unic pricess
function* getStorePrice({ payload }) {
  const { productId, storeId } = payload.requestObj;

  // if (!productId && !storeId) return
  if (!productId || !storeId) {
    let priceObj = {
      price: '',
      currency: ''
    };
    yield put(getStoreUnicProductPriceSuccess({ data: priceObj }));
  } else {
    if (productId && storeId) {
      try {
        const response = yield call(doRequest, {
          method: 'GET',
          url: `prodres/products/store/${storeId}/product/${productId}`
        });

        yield put(getStoreUnicProductPriceSuccess({ data: response.data }));
      } catch (error) {
        let priceObj = {
          price: '',
          currency: ''
        };
        yield put(getStoreUnicProductPriceSuccess({ data: priceObj }));
      }
    }
  }
}

// get old store price data
// get store unic pricess
function* getOldStorePrice() {
  let editData = yield select(state => state.moderation.moderationDetails.editAddModerationData);

  if (editData) {
    if (editData.store && editData.non_translate) {
      const storeId = editData.store._id;
      const productId = editData.non_translate.productId;
      if (storeId && productId) {
        try {
          const response = yield call(doRequest, {
            method: 'GET',
            url: `prodres/products/store/${storeId}/product/${productId}`
          });

          yield put(getOldStorePriceSuccess({ data: response.data }));
        } catch (error) {
          let priceObj = {
            price: '',
            currency: ''
          };
          yield put(getOldStorePriceSuccess({ data: priceObj }));
        }
      }
    }
  }
}

// function* getProductByTicket() {
//   const tempProductDataFromTicket = {
//     _id: 'b1197dafbcc907eb173594334e02a7be',
//     _rev: '1-1fd421c955216006ac05841667211f4e',
//     non_translate: {
//       productId: '6b645d3b173b4776db38eb9fe60d4142',
//       ean: '84979808',
//       alcohol_content: 100,
//       product_url: 'https://jsoneditoronline.org/',
//       vegan_type: 0,
//       freezer_be: true,
//       category: [10],
//       store: '0364e82c13b66325ee86f99f53023596',
//       price: '600',
//       currency: 'KR',
//       ingeredent: [[6]],
//       mediaCollectionId: 'de8af2cc-f492-4bdd-8699-e8e0033d503d',
//       palm_oil: [
//         {
//           contains: true,
//           palm_oil_certified: false
//         }
//       ],
//       measuring_type: 2,
//       mayContain: [],
//       manufacturer: '',
//       temperature: -10.88,
//       temperature_type: 'd',
//       brand: '',
//       local_distributor: '',
//       internal_comment:
//         'sfkjshdfhkdsh kfhdskhfkksdhfkjhdskj fhkjdsh kfjhdksj hkjhdskj hgkjhsdjkgh jkdshkjghsdkjhg kjhfskjhgkjsdhkjhgskjhk jdgshkjhgskjh gkjdsh kjghds kjhgdsk jhgdskjhkjsgdh jkhsdkjhgfkjsdhkghsdk jhgkjvjshckjhkjvhkjxchvkjhxk jchvxjkxkjhvjkcxghjvxc jgvjhxcgjhvgx jhgvcxjhgv jcxhhcjvx gjhcxvgjcxgvjhcxgvjhgvjhd fgjvkhjkxckhvkjxcjhgdgfvdhghjj jfjhgfjhgjfjfdjgjsdfjhvbjbxc jhbjhvxcjjcxbjhvbjcxhjvxck gjhfdj kxjvxcjbjvbxjbvjcxbjhbvchjxcb vdfvjbdjksbvjisdkbkhvis hiugdhiuhiudhfifkjbkjbchvic vhbkbvcnk bhodpkjxvjbidfprljfnckhirpkfjcifgv[erogkir',
//       country: 120
//     },
//     translatable: {
//       locale: 'da_DK',
//       product_name: 'chicken buger with extra cheese',
//       sample_key: 'ආදාන මෙවලම්',
//       newMMMM: 'ආදාන මෙවලම්',
//       unit: 222,
//       unit_type: 'g',
//       hfhhfh: ' හි නොමිළේ සේවාවෙන් ක්ෂි'
//     },
//     source: 'ios',
//     store: {
//       store_name: 'සතොස ',
//       branch_name: 'Maharagama',
//       address: '',
//       _id: '0364e82c13b66325ee86f99f53023596',
//       country: 5,
//       mediaCollectionId: 'e2992f26-3a1f-4fff-8240-829c506b5811'
//     },
//     user: '426557cec5f70f83b45223b341002cf1',
//     userName: 'Milinda Kariyawasam',
//     serial: -31,
//     country: 'Sri Lanka',
//     lang: 'Danish',
//     date: 1580451727017,
//     type: 'ticket'
//   };
//   const { payload } = payloadPassed;
//   const { languageList } = yield select(state => state.languageList);

//   const { data } = payload;

//   const languagebyLocaleData = languageList.filter(item => item.locale == data.locale);

//   let unitTypeWithLocale;
//   languagebyLocaleData.forEach(element => {
//     unitTypeWithLocale = element.unit;
//   });
//   yield put(updateProductDetailLoadStatus({ data: false }));

//   try {
//     const response = yield call(doRequest, {
//       method: 'GET',
//       url: urlPassing
//     });
//     const { non_translate } = response.data;

//     if (non_translate) {
//       if (non_translate.mediaCollectionId) {
//         yield put(
//           setCollectionId({ CollectionId: non_translate.mediaCollectionId, pageType: 'product' })
//         );
//       }

//       yield put(setProductStore({ store: non_translate.store ? non_translate.store : '' }));
//     }

//     yield put(updateProductDetailLoadStatus({ data: true }));

//     let value;
//     let getdefaltunit = yield call(
//       getdefaultunitWithLocal,
//       languagebyLocaleData,
//       unitTypeWithLocale,
//       response.data,
//       value
//     );

//     if (getdefaltunit && getdefaltunit != '' && value) {
//       response.data.translatable.unit_type = value;
//     }

//     yield put(getProductByLocalSuccess({ data: response.data }));
//     yield put(getProductID({ id: response.data.non_translate._id }));
//     yield put(getBracketprice());

//     yield put(updateProductLocale({ data: data.locale }));
//   } catch (error) {
//     // if (error.response.status === 404) {
//     //   yield put(getProductByLocalSuccess({ data: {} }));
//     // }
//   }
// }
// getEdited data in list
function* getSingleDataInTikets({ payload }) {
  yield put(getSIngleDataSuccess({ data: null }));
  yield put(getOldSingleProductDataSuccess({ data: null }));
  // const { id } = payload.params;
  const id = payload.id.params.id;
  let intl = yield select(state => state.intlReact.intl);

  if (id) {
    try {
      const response = yield call(doRequest, {
        url: `/prodmod/product_moderation/ticket/${id}`,
        method: 'GET'
      });
      if (response && response.data) {
        let collectionData = {
          ticketCollectionId: '',
          productCollectionId: ''
        };
        yield delay(500);
        yield put(getSIngleDataSuccess({ data: response.data }));

        if (
          response.data &&
          response.data.non_translate &&
          response.data.non_translate.mediaCollectionId
        ) {
          collectionData.ticketCollectionId = response.data.non_translate.mediaCollectionId;
        }

        yield put(
          getSIngleDataSuccess({
            data: response.data,
            droupdownLocale: response.data.translatable.locale
          })
        );

        if (
          response.data &&
          response.data.non_translate &&
          response.data.non_translate.productId &&
          response.data.non_translate.productId !== ''
        ) {
          yield call(
            getOldeProductData,
            response.data.non_translate.productId,
            response.data.translatable.locale,
            collectionData,
            payload.location
          );
        } else {
          // const location = `/prodmod/product_moderation/ticket/${id}`;
          const location = { pathname: payload.id.url };
          const processToken = createProcessToken('moderation', location);
          console.log(payload, 'Componenet Did Update GET SINGLE DATA TICKET', processToken);
          // this.props.getImageLIst({ processToken: processToken, location: this });
          // yield put(getImageLIst(collectionData));
          yield put(
            getImageLIst({
              collectionData: collectionData,
              processToken: processToken,
              location: payload.location
            })
          );
          yield put(getOldSingleProductDataSuccess({ data: null }));
        }
      }
    } catch (error) {
      console.log(error);
      message.info(
        intl.formatMessage({
          id: 'Generic.ApiMessages.Moderation.ErrorWithData'
        })
      );
    }
  }
}

// gget old data with row select
function* getoldDataObject({ payload }) {
  const { productID, locale, collectionData, location } = payload;
  yield call(getOldeProductData, productID, locale, collectionData, location);
}

// get old product data
function* getOldeProductData(productId, locale, collectionData, locationUrl) {
  const location = { pathname: locationUrl.props.location.pathname };
  if (productId) {
    let urlPassing = `prodres/products/${productId}/${locale}`;
    const response2 = yield call(doRequest, {
      method: 'GET',
      url: urlPassing
    });

    if (response2 && response2.data) {
      const non_translate = response2.data.non_translate;

      if (non_translate) {
        if (non_translate.mediaCollectionId) {
          if (collectionData) {
            collectionData.productCollectionId = non_translate.mediaCollectionId;
          }
        }
      }
      const processToken = createProcessToken('didUpdate', location);
      console.log(location, 'Componenet Did Update 1', processToken);
      // this.props.getImageLIst({ processToken: processToken, location: location });
      yield put(
        getImageLIst({
          collectionData: collectionData,
          processToken: processToken,
          location: locationUrl
        })
      );
      // yield put(getImageLIst(collectionData));

      yield put(getOldSingleProductDataSuccess({ data: response2.data }));

      yield put(getBracketprice({ oldData: response2.data }));
    }
  } else {
    if (collectionData && collectionData.ticketCollectionId) {
      // yield put(getImageLIst(collectionData));
      const processToken = createProcessToken('didUpdate', location);
      console.log(location, 'Componenet Did Update 2', processToken);
      // this.props.getImageLIst({ processToken: processToken, location: location });
      yield put(
        getImageLIst({
          collectionData: collectionData,
          processToken: processToken,
          location: locationUrl
        })
      );
    }
  }
}

// get manufacture list
function* getManufactureList() {
  try {
    const response = yield call(doRequest, {
      url: '/prodmanu/manufacture/MANU',
      method: 'GET'
    });

    const { rows } = response.data;
    let filtertedData = rows || [];

    filtertedData = rows.map(item => item.doc);

    yield put(manufactureListSuccess({ data: filtertedData }));
  } catch (error) {}
}

// get brand list success
function* getBrandList() {
  try {
    const response = yield call(doRequest, {
      url: '/prodmanu/manufacture/BRAN',
      method: 'GET'
    });

    const { rows } = response.data;
    let filtertedData = rows || [];

    filtertedData = rows.map(item => item.doc);

    yield put(brandListSuccess({ data: filtertedData }));
  } catch (error) {}
}

// get distributorList
function* getDistributorList() {
  try {
    const response = yield call(doRequest, {
      url: '/prodmanu/manufacture/DIST',
      method: 'GET'
    });

    const { rows } = response.data;
    let filtertedData = rows || [];

    filtertedData = rows.map(item => item.doc);

    yield put(distributorListSuccess({ data: filtertedData }));
  } catch (error) {}
}

//get tags list
function* getTagsList() {
  let defaulLocalState = yield select(state => state.moderation.moderationDetails.droupdownLocale);

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: 'prodprop/tags/list'
    });

    let selectLocal = defaulLocalState && defaulLocalState.length > 0 ? defaulLocalState : 'en_US';

    let defaulLocal = 'en_US';

    let arrayPassing = getTagsListByLocale(response.data, [], selectLocal, defaulLocal);

    yield put(getTagListSuccess({ data: arrayPassing, locale: selectLocal }));

    let imageFork;

    if (arrayPassing && arrayPassing.length > 0) {
      let imageTags = arrayPassing.filter(item => {
        if (item.image) {
          return item;
        }
      });
      imageFork = yield fork(getTagsAllImages, imageTags);
    }
  } catch (error) {}
}

function getTagsListByLocale(dataArray, newArray, passedLocal, defaultLocal) {
  dataArray.forEach(item => {
    let objTags = {
      _id: item._id,
      image: item.collectionId ? item.collectionId : '',
      serial: item.serial ? item.serial : '',
      title: ''
    };

    if (item.translations) {
      let languagesArray = item.translations;

      objTags.title = getTagsFiledByLocal(languagesArray, passedLocal, defaultLocal, 'name');

      newArray.push(objTags);
    } else {
      if (item.title) {
        objTags.title = item.title;
      }
      newArray.push(objTags);
    }
  });

  return newArray;
}

function getTagsFiledByLocal(languagesArray, passedLocal, defaultLocal, field) {
  let xxx = '';
  if (languagesArray[passedLocal] && languagesArray[passedLocal][field]) {
    xxx = languagesArray[passedLocal][field];
  } else if (languagesArray[defaultLocal] && languagesArray[defaultLocal][field]) {
    xxx = languagesArray[defaultLocal][field];
  } else {
    for (var key in languagesArray) {
      if (languagesArray[key] && languagesArray[key][field]) {
        let firstChild = languagesArray[key][field];
        xxx = firstChild;
        break;
      }
      // object[prop]
    }
  }

  return xxx;
}

// get All lable images in module
function* getTagsAllImages(dataList = []) {
  const chunkedList = yield call(chunk, dataList, 10);

  for (let list of chunkedList) {
    yield call(getImages, list);
    yield delay(2000);
  }
}

function* getImages(list) {
  const requests = list.map(item =>
    item.image ? call(getStoreLableimage, item._id, item.image) : ''
  );

  const responses = yield all(requests);
  const filtertedReponse = responses.filter(response => (response ? true : false));

  const indexes = yield call(getIndexedTagsIds);

  yield put(setTagImageSuccess({ image: filtertedReponse, indexes }));
}

//  get avatar image
function* getStoreLableimage(id, collectionId) {
  if (!collectionId) return;

  try {
    const imageResponse = yield call(doRequest, {
      url: `unimup/objctupload/collection/${collectionId}`,
      method: 'GET'
    });

    const { data } = imageResponse;

    if (data.pages.length) {
      const imageUrl = data.pages[0].thumbnail.fetchUrl;
      const imageResponse2 = yield call(doRequest, {
        url: `unimup/objctupload${imageUrl}`,
        method: 'GET',
        responseType: 'blob'
      });

      const { data: imageData } = imageResponse2;
      const image = URL.createObjectURL(imageData);
      return { id, image };
    }
  } catch (error) {
    console.log(error);
  }
}

// get selected store tiket image
function* getTiketSelectedStoreImage({ payload }) {
  const { storeId, collectionId } = payload;

  const requests = yield call(getStoreLableimage, storeId, collectionId);
  if (requests && requests.image && requests.id) {
    yield put(getTiketSelectedStoreImageSuccess({ image: requests.image, id: requests.id }));
  }
}

// get category list
function* getCategoryList() {
  const dataLocal = yield select(state => state.moderation.moderationDetails.droupdownLocale);

  yield put(getCategoryListSuccess({ data: [] }));

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: 'prodsup/categories/get'
    });

    const dataList = normalizeTreeByLocale(response.data, dataLocal);

    yield put(getCategoryListSuccess({ data: dataList }));
    yield delay(100);
  } catch (error) {}
}

// get braket text
function* getBrakettextTodisplay({ payload }) {
  const productOldData = payload.oldData;

  const moderationId = yield select(
    state => state.moderation.moderationDetails.editAddModerationData._id
  );

  const productId = productOldData.non_translate._id;
  const locale = productOldData.translatable.locale;

  if (productId) {
    try {
      const response = yield call(doRequest, {
        method: 'GET',
        url: `prodres/products/bracket_price/product/${productId}`
      });

      if (response.data && response.data.currency) {
        let element = response.data.currency.split('_');
        let priceObj = {
          price: response.data.price,
          currency: element[0]
        };
        let productUrl = `/content-management/moderation/${moderationId}/${locale}`;

        yield put(getBracketpriceSuccess({ value: priceObj, pid: productUrl }));
      }
    } catch (error) {}
  }
}

/**
 * Watchers
 */

function* getmoderationDataListSagaWatcher() {
  yield takeLatest(moderationTypes.GET_DATA_LIST, getmoderationDataList);
}
/* +++++++++++++ */

function* getListWatcher() {
  yield takeLatest(moderationTypes.GET_LIST_NUTRITION_VALUES, getListNutritionValues);
}

function* getStorePriceWatcher() {
  yield takeLatest(moderationTypes.GET_STORE_UNIC_PRODUCT_PRICE, getStorePrice);
}

function* getOldStorePriceWatcher() {
  yield takeLatest(moderationTypes.GET_OLD_STORE_PRICE_DATA, getOldStorePrice);
}

function* getProductListWatcher() {
  yield takeLatest(moderationTypes.GET_PRODUCT_LIST, getProductList);
}
function* getAllLabelListWatcher() {
  yield takeLatest(moderationTypes.GET_LABEL_LIST, getAllLabelList);
}
function* addProductListner() {
  yield takeLatest(moderationTypes.ADD_PRODUCT, addProductFun);
}
function* editProductListner() {
  yield takeLatest(moderationTypes.EDIT_PRODUCT, editProductFun);
}
function* deleteProductListner() {
  yield takeLatest(moderationTypes.DELETE_PRODUCT, deleteProductFun);
}
function* deleteTicketListner() {
  yield takeLatest(moderationTypes.DELETE_TICKET, deleteTicketFun);
}

function* getStoreTypeListWatcher() {
  yield takeLatest(moderationTypes.GET_STORE_TYPE_LIST, getStoreTypeList);
}

function* getEnumberListWatcher() {
  yield takeLatest(moderationTypes.GET_ENUMBER_LIST, getEnumberList);
}

function* getIngredientListWatcher() {
  yield takeLatest(moderationTypes.GET_INGREDIENT_LIST, getIngredientList);
}
function* getProductByLocaleWatcher() {
  // yield takeLatest(moderationTypes.GET_PRODUCT_BY_LOCALE, getProductByLocal);
}

function* getSingleDataInTiketsWatcher() {
  yield takeLatest(moderationTypes.GET_SINGLE_DATA, getSingleDataInTikets);
}

function* getManufactureListWatcher() {
  yield takeLatest(moderationTypes.GET_MANUFACTURE_LIST, getManufactureList);
}

function* getBrandListWatcher() {
  yield takeLatest(moderationTypes.GET_BRAND_LIST, getBrandList);
}
function* getDistributorListWatcher() {
  yield takeLatest(moderationTypes.GET_DISTRIBUTOR_LIST, getDistributorList);
}

function* getTagListWatcher() {
  yield takeLatest(moderationTypes.GET_TAG_LIST, getTagsList);
}

function* getCategoryListWatcher() {
  yield takeLatest(moderationTypes.GET_CATEGORY_LIST, getCategoryList);
}

function* getStoreListWatcher() {
  yield takeLatest(moderationTypes.GET_STORE_LIST, getStoreList);
}

function* getBrakettextTodisplayWatcher() {
  yield takeLatest(moderationTypes.GET_BRACKET_PRICE, getBrakettextTodisplay);
}
function* getoldDataObjectWatcher() {
  yield takeLatest(moderationTypes.GET_OLD_SINGLE_PRODUCT_DATA, getoldDataObject);
}

function* getTiketSelectedStoreImageWatcher() {
  yield takeLatest(moderationTypes.GET_TIKET_SELECTED_STORE_IMAGE, getTiketSelectedStoreImage);
}

// +++++++++++++++

export default function* rootSaga() {
  yield all([
    fork(getmoderationDataListSagaWatcher),
    fork(getListWatcher),
    fork(getProductListWatcher),
    fork(getCategoryListWatcher),
    fork(getIngredientListWatcher),
    fork(getStoreListWatcher),
    fork(getTagListWatcher),
    fork(getStoreTypeListWatcher),
    fork(getEnumberListWatcher),
    fork(addProductListner),
    fork(editProductListner),
    fork(getProductByLocaleWatcher),
    fork(deleteProductListner),
    fork(deleteTicketListner),
    fork(getAllLabelListWatcher),
    fork(getStorePriceWatcher),
    fork(getBrakettextTodisplayWatcher),
    fork(getSingleDataInTiketsWatcher),
    fork(getManufactureListWatcher),
    fork(getBrandListWatcher),
    fork(getDistributorListWatcher),
    fork(getoldDataObjectWatcher),
    fork(getTiketSelectedStoreImageWatcher),
    fork(getOldStorePriceWatcher)
  ]);
}
