import { actionCreator } from 'redux-action-creator';
import productTypes from './catalogue_products.constants';

export const fetchRows = actionCreator(productTypes.FETCH_ROWS, 'config');
export const fetchRowsSuccess = actionCreator(
  productTypes.FETCH_ROWS_SUCCESS,
  'data',
  'config',
  'locale'
);
export const fetchRowsFailure = actionCreator(productTypes.FETCH_ROWS_FAIL);
export const deleteProduct = actionCreator(productTypes.DELETE_PRODUCT, 'id');
export const deleteProductSuccess = actionCreator(productTypes.DELETE_PRODUCT_SUCCESS, 'id');
export const deleteProductFail = actionCreator(productTypes.DELETE_PRODUCT_FAIL, 'id');
export const setLoader = actionCreator(productTypes.SET_LOADER, 'loader');
export const setProductImage = actionCreator(
  productTypes.SET_PRODUCT_IMAGE,
  'id',
  'image',
  'indexes'
);
export const setProductUserImage = actionCreator(
  productTypes.SET_PRODUCT_USER_IMAGE,
  'id',
  'image',
  'indexes'
);
export const reset = actionCreator(productTypes.RESET);
export const setSearchLoader = actionCreator(productTypes.SET_SEARCH_LOADER, 'searchLoader');
export const getSearchSuggestion = actionCreator(productTypes.GET_SEARCH_SUGGESTION, 'searchValue');
export const getSearchSuggestionSuccess = actionCreator(
  productTypes.GET_SEARCH_SUGGESTION_SUCCESS,
  'searchResults'
);
export const addTags = actionCreator(productTypes.ADD_TAG, 'tag', 'group');
export const addTagsSuccess = actionCreator(productTypes.ADD_TAG_SUCCESS, 'tags');
export const deleteTag = actionCreator(productTypes.DELETE_TAG, 'tag');
export const deleteTagSuccess = actionCreator(productTypes.DELETE_TAG_SUCCESS, 'tags');
export const setSearchProductIds = actionCreator(productTypes.SET_SEARCH_IDS, 'ids');
