import { createTypes, async } from 'redux-action-creator';

const adsTypes = createTypes(
  [
    ...async('GET_DATA_LIST'),
    'SET_COUNTRY_COUNTER',
    'RESET',
    'SET_AD_DETAILS',
    'GET_COUNTRY_COUNTER',
    'AD_SAVE',
    'GET_SINGLE_AD'
  ],
  'AD_MANAGEMENT_LIST'
);

export default adsTypes;
