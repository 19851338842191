import Axios from 'axios';
import axiosCancel from 'axios-cancel';
import { signOutSessionExpiredUser } from '../../../../appRedux/actions/Auth';
import { getToken, setTokenWithoutRemember } from '../../utils/storage';
import axiosRetry from 'axios-retry';
import { getEnvironmentVariable } from './server.config';
let apiUrl = getEnvironmentVariable('apiUrl');
axiosCancel(Axios, {
  debug: false // default
});
Axios.defaults.baseURL = apiUrl;

axiosRetry(Axios, {
  retries: 3,
  retryDelay: retryCount => retryCount * 1000
});

const setToken = () => {
  const accessToken = getToken();
  Axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
};
const CancelToken = Axios.CancelToken;
const source = CancelToken.source();
let cancelTokens = [];
export function doRequest(options) {
  // if (options.cancelCall) {
  //   source.cancel();
  //   console.log(options, 'axiosRequestaxiosRequest CANCEL', source);
  //   return;
  // }

  setToken();
  let axiosRequest = '';
  if (options.shouldCancel) {
    let myOptions = { ...options };
    delete myOptions.shouldCancel;
    // const newToken = source.token;
    const newToken = Math.random() * 9999999999;
    // console.log(newToken, 'axiosRequestaxiosRequest xxx Create', options);
    cancelTokens.push(newToken);
    // console.log('axiosRequestaxiosRequest xxx Create AFTER DELETE', myOptions);

    // axiosRequest = Axios.request({ ...myOptions, cancelToken: newToken });
    // axiosRequest = Axios.request({ ...myOptions, cancelToken: newToken });
    axiosRequest = Axios.request({ ...myOptions, requestId: newToken });
    // .then(res => {
    //   console.log('resolved promise 1');
    // })
    // .catch(thrown => {
    //   if (Axios.isCancel(thrown)) {
    //     console.log('request 2 cancelled');
    //   } else {
    //     console.log('some other reason');
    //   }
    // });
    // axiosRequest = Axios.request({ ...myOptions, requestId: newToken })
    //   .then(res => {
    //     console.log('resolved promise 1');
    //   })
    //   .catch(thrown => {
    //     if (Axios.isCancel(thrown)) {
    //       console.log('request 2 cancelled', newToken);
    //     } else {
    //       console.log('some other reason');
    //     }
    //   });
    // console.log('generate axiosRequest', axiosRequest);
  } else {
    axiosRequest = Axios.request(options);
  }

  return axiosRequest;
}
export function cancelAllServerCall() {
  // console.log(cancelTokens, 'CANCELLED', source);
  if (cancelTokens && cancelTokens.length > 0) {
    cancelTokens.forEach(element => {
      // source.cancel(element);
      // Axios.cancel(element);
    });
  }

  Axios.cancelAll();
  cancelTokens = [];
}
let isAlreadyFetchingAcessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

Axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      const {
        config,
        response: { status, data }
      } = error;
      const originalRequest = config;

      if (
        status === 401 &&
        (window.location && !window.location.pathname.includes('signin')) &&
        data.result !== 'FAILURE_IDENTITY_NOT_FOUND' &&
        data.result !== 'FAILURE_CREDENTIAL_INVALID'
      ) {
        if (!isAlreadyFetchingAcessToken) {
          isAlreadyFetchingAcessToken = true;
          const refreshToken = localStorage.getItem('refresh_token');

          const params = new URLSearchParams();
          params.append('refresh_token', refreshToken);
          params.append('grant_type', 'refresh_token');
          params.append('client_id', 'cccy');
          params.append('client_secret', 'Hello');
          params.append('client_phase','aaaaa');

          Axios.request({
            url: 'auth/tokenRefresh',
            method: 'POST',
            data: params
          })

            .then(response => response.data)
            .then(response => {
              const { access_token, refresh_token } = response;
              setTokenWithoutRemember(access_token);
              //localStorage.setItem('access_token', access_token);
              localStorage.setItem('refresh_token', refresh_token);
              isAlreadyFetchingAcessToken = false;
              onAccessTokenFetched(access_token);
            });
        }

        const retryOriginalRequest = new Promise((resolve, reject) => {
          addSubscriber(access_token => {
            originalRequest.headers.Authorization = 'Bearer ' + access_token;
            resolve(Axios(originalRequest));
          });
        });

        if (
          status === 401 &&
          (data.message &&
            (data.message === 'Refresh token has expired' ||
              data.message.includes('permissions have been revoked')))
        ) {
          let logOutType = 'session_expired';

          if (data.message.includes('permissions have been revoked')) {
            logOutType = 'user_deactivated';
          }

          console.log('Token Refresh Failed', { error });
          isAlreadyFetchingAcessToken = false;
          subscribers = [];
          window.store.dispatch(signOutSessionExpiredUser(logOutType));
        }
        return retryOriginalRequest;
      }
      return Promise.reject(error);
    }
  }
);
