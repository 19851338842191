import enLang from "./entries/en-US";
import zhLang from "./entries/zh-Hans-CN";
import arLang from "./entries/ar_SA";
import itLang from "./entries/it_IT";
import esLang from "./entries/es_ES";
import frLang from "./entries/fr_FR";
import svLang from './entries/sv_SE';
import deLang from './entries/de_DE';
import hiLang from './entries/hi_IN';
import ruLang from './entries/ru_RU';
import siLang from './entries/si_LK';
import taLang from './entries/ta_LK';
import fiLang from './entries/fi_FI';
import nbLang from './entries/nb_NO';
import daLang from './entries/da_DK';
import heLang from './entries/he_IL';
import trLang from './entries/tr_TR';
import laLang from './entries/la_int';
import koLang from './entries/ko_KR';
import eoLang from './entries/es_BO';
import csLang from './entries/cs_CZ';
import {addLocaleData} from "react-intl";

const AppLocale = {
  en: enLang,
  zh: zhLang,
  ar: arLang,
  it: itLang,
  es: esLang,
  fr: frLang,
  sv: svLang,
  de: deLang,
  hi: hiLang,
  ru: ruLang,
  si: siLang,
  ta: taLang,
  fi: fiLang,
  nb: nbLang,
  da: daLang,
  he: heLang,
  tr: trLang,
  la: laLang,
  ko: koLang,
  eo: eoLang,
  cs: csLang,
};

addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.zh.data);
addLocaleData(AppLocale.ar.data);
addLocaleData(AppLocale.it.data);
addLocaleData(AppLocale.es.data);
addLocaleData(AppLocale.fr.data);
addLocaleData(AppLocale.sv.data);
addLocaleData(AppLocale.de.data);
addLocaleData(AppLocale.hi.data);
addLocaleData(AppLocale.ru.data);
addLocaleData(AppLocale.si.data);
addLocaleData(AppLocale.ta.data);
addLocaleData(AppLocale.fi.data);
addLocaleData(AppLocale.nb.data);
addLocaleData(AppLocale.da.data);
addLocaleData(AppLocale.he.data);
addLocaleData(AppLocale.tr.data);
addLocaleData(AppLocale.tr.data);
addLocaleData(AppLocale.la.data);
addLocaleData(AppLocale.ko.data);
addLocaleData(AppLocale.eo.data);
addLocaleData(AppLocale.cs.data);

export default AppLocale;
