import matchSorter from 'match-sorter';
import * as categoriesActions from './categories.constants';
import { SIGNOUT_SESSION_EXPIRED, SIGNOUT_USER } from '../../../../../constants/ActionTypes';

let indexedTreeList;

function searchIngs(key, data) {
  const clonedData = clone(data);
  let searchArr = matchSorter(clonedData, key, {
    keys: [
      {
        threshold: matchSorter.rankings.CONTAINS,
        key: 'title'
      },
      { threshold: matchSorter.rankings.CONTAINS, key: item => `c${item.serial}` }
    ]
  });
  const ref = {};
  const rootTree = {};
  let newArr = [];

  indexedTreeList = data.reduce((index, value) => {
    index[value.id] = value;
    return index;
  }, {});

  for (let index = 0; index < searchArr.length; index++) {
    const item = searchArr[index];
    const newNode = indexedTreeList[item.id];
    if (newNode) {
      buildTree(clone(newNode), ref, rootTree);
    }
  }

  newArr = Object.values(rootTree);

  /**
   * Sort the tree to bring the root level leaf nodes
   * to the top
   */
  newArr.sort((leftNode, rightNode) => {
    if (
      leftNode.children &&
      leftNode.children.length &&
      (!rightNode.children || (rightNode.children && !rightNode.children.length))
    ) {
      return 1;
    }

    if (
      rightNode.children &&
      rightNode.children.length &&
      (!leftNode.children || (leftNode.children && !leftNode.children.length))
    ) {
      return -1;
    }
    return 0;
  });

  return newArr;
}

function clone(n) {
  return JSON.parse(JSON.stringify(n));
}

function buildTree(newNode, ref, rootTree) {
  /**
   * If a node has a parent id then we find its
   * parent nodes and build the tree
   */

  if (newNode.parentId) {
    /**
     * If the nodes parent is already in the reference
     * object, we can directly add the node inside parent
     */

    if (ref[newNode.parentId]) {
      const parent = ref[newNode.parentId];
      /**
       * if parent doesn't have a children
       * then create an array with the newNode
       */
      if (!parent.children) {
        parent.children = [newNode];
        // set reference for node havent any children -shifan
        ref[newNode.id] = newNode;
        parent.expanded = true;
      } else {
        /**
         * if children nodes exists
         * find if its the node is already added
         * if not push inside the children nodes
         */

        const isNodeRefAvailable = ref[newNode.id];
        const isNodeAlreadyInserted = parent.children.find(n => n.id === newNode.id);

        if (!isNodeAlreadyInserted) {
          newNode.expanded = true;
          parent.children.push(newNode);
        }

        if (!isNodeRefAvailable) {
          ref[newNode.id] = newNode;
        }
      }

      return parent;
    } else {
      /**
       * If the node's parent is not already built
       * then we'll get the node's parent
       * then assign the node as a child to the parent
       * then add referenes to the ref array for node and parent
       * then if the parent has a parent, recursively build the tree
       * then if the tree is not already in the rootTree then assign it to the root tree
       */

      const parentNode = getNode(newNode.parentId);

      let tree = parentNode;
      parentNode.expanded = true;
      parentNode.children = [newNode];

      ref[newNode.id] = newNode;
      ref[parentNode.id] = parentNode;

      if (parentNode.parentId) {
        tree = buildTree(parentNode, ref, rootTree);
      }

      const isTreeAlreadyPushed = rootTree[tree.id];

      if (!isTreeAlreadyPushed && !tree.parentId) {
        rootTree[tree.id] = tree;
      }

      return tree;
    }
  } else {
    /**
     * if the node is a root level element
     * we can append it to the root tree directly
     */
    if (!(newNode.id in rootTree)) {
      rootTree[newNode.id] = newNode;
    }

    if (!(newNode.id in ref)) {
      ref[newNode.id] = newNode;
    }
  }

  return newNode;
}

/**
 *
 * @description Retrieve a node from normalized tree list by parent id
 *
 * @param parentId {Number}
 *
 * @returns TreeNode {Object}
 */
function getNode(parentId) {
  if (!parentId) {
    return;
  }

  const item = indexedTreeList[parentId];

  if (item) {
    let parentOnly = clone(item);
    delete parentOnly.children;
    return parentOnly;
  }
}

function getParentKeys(id) {
  if (!id) {
    return;
  }

  const item = indexedTreeList[id];

  if (item) {
    const id = item.id;
    let ids = [id];
    if (item.parentId) {
      const idSet = getParentKeys(item.parentId);

      if (idSet && idSet.length) {
        ids = [...idSet, ...ids];
      }
    }

    return ids;
  }
}

function removeExpanedKeyList(treeList) {
  looplatest(treeList);
  return treeList;
}

function looplatest(treeList) {
  treeList.forEach(tree => {
    if (tree.id) {
      tree['expanded'] = false;

      if (tree.children) looplatest(tree.children);
    }
  });
}

function setExpandedKeys(tree, keys) {
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];

    if (keys.includes(node.id)) {
      node.expanded = true;
    }

    if (node.children) {
      setExpandedKeys(node.children, keys);
    }
  }
}

const initialState = {
  data: null,
  originData: null,
  dataList: [],
  selectedCategory: null,
  selectedCategoryDetails: null,
  isBtnRed: false,
  isAlertShow: false,
  selectedKeys: [],
  expandedKeys: ['0'],
  nextSelectedkey: [],
  defaultLocal: '',
  categoriesSaveLoader: false,
  identifyDeleteNodeCat: false,
  categoryLoader: true,
  addNewCategory: false,
  randomNumberLastCategory: 0,
  isCategoryTreeUpdated: false,
  searchStringVal: '',
  enableDrag: true,
  categoryListLoader: true,
  categorySpinLoader: false,
  titleCategory: '',
  deleteStatus: false,
  disabledCategoryAddInput: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case categoriesActions.SET_CATEGORY_TITLE_TRANSLATION: {
      const { title } = payload;
      return {
        ...state,
        titleCategory: title
      };
    }

    case categoriesActions.GET_CATEGORIES: {
      return {
        ...state,
        categoryListLoader: !state.categorySpinLoader
      };
    }

    case categoriesActions.GET_CATEGORIES_SUCCESS: {
      const { data, dataList } = payload;
      
      if (state.searchStringVal) {
        const searchedArr = searchIngs(state.searchStringVal, dataList);

        const dataWithRoot = [
          {
            id: 0,
            parentId: null,
            serial: '0000',
            title: state.titleCategory,
            expanded: true,
            children: searchedArr
          }
        ];

        return {
          ...state,
          data: dataWithRoot,
          originData: data,
          dataList,
          categoryListLoader: false
        };
      } else {
        const dataWithRoot = [
          {
            id: 0,
            parentId: null,
            serial: '0000',
            title: state.titleCategory,
            expanded: true,
            children: data
          }
        ];
        return {
          ...state,
          data: dataWithRoot,
          originData: dataWithRoot,
          dataList,
          categoryListLoader: false
        };
      }
    }
    case categoriesActions.CAT_SPIN_LOAD: {
      return {
        ...state,
        categorySpinLoader: payload
      };
    }

    case categoriesActions.ADD_CATEGORY_SUCCESS: {
      const { dataOld } = payload;
      return {
        ...state,
        data: dataOld,
        addNewCategory: false
      };
    }

    case categoriesActions.DELETE_CATEGORY_SUCCESS: {
      if (payload.isSelectedKeyDeleted) {
        return {
          ...state,
          selectedCategory: null,
          selectedCategoryDetails: null,
          selectedKeys: [],
          data: payload.dataOld,
          originData: payload.dataOld,
          isBtnRed: false
        };
      } else {
        return {
          ...state,
          data: payload.dataOld,
          originData: payload.dataOld
        };
      }
    }
    case categoriesActions.ADD_NODE_BTN_DISABLED: {
      return {
        ...state,
        disabledCategoryAddInput: payload
      };
    }

    case categoriesActions.HANDLE_BLUR_CATEGORY_SUCCESS: {
      const { dataOld, dataList, newNode, originData } = payload;

      if (state.searchStringVal && newNode) {
        const newNodeTitle = newNode.title.toLowerCase();
        const newNodeSerial = newNode.serial.toString().toLowerCase();
        const searchString = state.searchStringVal.toLowerCase();
        const isNewItemHasSearchTerm =
          newNodeTitle.includes(searchString) || newNodeSerial.includes(searchString);

        if (isNewItemHasSearchTerm) {
          const data = searchIngs(searchString, dataList);
          const dataWithRoot = [
            {
              id: 0,
              parentId: null,
              serial: '0000',
              title: state.titleCategory,
              expanded: true,
              children: data
            }
          ];

          return {
            ...state,
            data: dataWithRoot,
            originData: originData,
            dataList: dataList
          };
        } else {
          let ids = state.expandedKeys;
          let origin = originData;

          if (originData[0]['id']) {
            origin = originData;
          } else {
            origin = originData[0].children;
          }

          if (newNode.parentId) {
            const parentIds = getParentKeys(newNode.parentId);
            ids = [...ids, ...parentIds, newNode.parentId, newNode.id];
            ids = [...new Set(ids)];

            setExpandedKeys(origin, ids);
            origin[0].expanded = true;
          }

          if (origin[0]['id']) {
            origin = [
              {
                id: 0,
                parentId: null,
                serial: '0000',
                title: state.titleCategory,
                expanded: true,
                children: origin
              }
            ];
          }

          return {
            ...state,
            data: [...origin],
            originData: [...origin],
            dataList: dataList,
            searchStringVal: '',
            expandedKeys: ids
          };
        }
      } else {
        const dataWithRoot1 = [
          {
            id: 0,
            parentId: null,
            serial: '0000',
            title: state.titleCategory,
            expanded: true,
            children: dataOld
          }
        ];

        return {
          ...state,
          data: dataWithRoot1,
          originData: dataWithRoot1,
          dataList: payload.dataList
        };
      }
    }

    case categoriesActions.SET_SELECTED_CATEGORY:
      let { selectedCategory } = payload;
      if (selectedCategory.length > 0) {
        return {
          ...state,
          selectedCategory
        };
      } else {
        return {
          ...state
        };
      }

    case categoriesActions.GET_CATEGORY_LANGUAGES_SUCCESS:
      return {
        ...state,
        selectedCategoryDetails: payload.data
      };

    case categoriesActions.UPDATE_CATEGORIES_ORDER_SUCCESS:
      return {
        ...state,
        data: payload.data
      };

    case categoriesActions.IDENTIFY_BTN_RED:
      return {
        ...state,
        isBtnRed: payload
      };

    case categoriesActions.PLUS_BTN_CLICK:
      return {
        ...state,
        addNewCategory: true
      };

    case categoriesActions.SHOW_ALERT_WINDOW:
      return {
        ...state,
        isAlertShow: true
      };
    case categoriesActions.HIDE_ALERT_WINDOW:
      return {
        ...state,
        isAlertShow: false
      };

    case categoriesActions.IDENTIFY_BTN_WITHOUT_RED:
      return {
        ...state,
        isBtnRed: false
      };

    case categoriesActions.TREE_SELECTED_KEY:
      return {
        ...state,
        selectedKeys: payload
      };

    case categoriesActions.NEXT_TREE_SELECTED_KEY:
      return {
        ...state,
        nextSelectedkey: payload
      };

    case categoriesActions.SAVE_TREE_CATEGORY_VALUE_SUCCESS:
      return {
        ...state,
        selectedKeys: state.nextSelectedkey,
        nextSelectedkey: []
      };

    case categoriesActions.CANCEL_TREE_CATEGORY_VALUE_SUCCESS:
      return {
        ...state,
        isAlertShow: false,
        addNewCategory: false
      };

    case categoriesActions.RESET_CATEGORY_VALUES:
      return {
        ...state,
        selectedCategory: null,
        selectedCategoryDetails: {},
        isBtnRed: false,
        categoryLoader: true
      };

    case categoriesActions.GET_DEFUALT_LOCAL_SUCCESS:
      return {
        ...state,
        defaultLocal: payload
      };

    case categoriesActions.SEARCH_WITH_DELETE_CATEGORY: {
      return {
        ...state,
        deleteStatus: payload
      };
    }

    case categoriesActions.UPDATE_CATEGORY_FORM_DETAILS_SUCCESS:
      return {
        ...payload
      };

    case categoriesActions.UPDATE_SAVE_CATEGORY_LOADER:
      return {
        ...state,
        categoriesSaveLoader: payload
      };

    case categoriesActions.DELETE_NODE_IDENTIFY:
      return {
        ...state,
        identifyDeleteNodeCat: payload
      };

    case categoriesActions.IDENTIFY_GET_CATEGORY_DATA_LOADER:
      return {
        ...state,
        categoryLoader: payload
      };

    case categoriesActions.SET_LAST_GENERATED_CATEGORY_RANDOM_NUMBER:
      return {
        ...state,
        addNewCategory: false,
        randomNumberLastCategory: payload
      };

    case categoriesActions.RE_UPDATE_SAVE_CATEGORY_DETAILS: {
      const { data, dataList, originData } = payload;

      return {
        ...state,
        data: [...data],
        originData: [...originData],
        dataList: dataList
      };
    }

    case categoriesActions.UPDATE_CATEGORY__TREE: {
      const { isUpdated } = payload;
      return {
        ...state,
        isCategoryTreeUpdated: isUpdated
      };
    }
    case categoriesActions.SET_SEARCH_STRING: {
      const { searchStringVal } = payload;
      return {
        ...state,
        searchStringVal: searchStringVal
      };
    }

    case categoriesActions.ADD_EXPAND_KEY: {
      const { key } = payload;
      return {
        ...state,
        expandedKeys: [...state.expandedKeys, key]
      };
    }

    case categoriesActions.SET_EXPAND_KEY: {
      const { expandedKeys } = payload;
      return {
        ...state,
        expandedKeys: expandedKeys,
        categoryListLoader: !state.categorySpinLoader
      };
    }

    case categoriesActions.REMOVE_EXPAND_KEY_SUCCESS: {
      const { keys, data } = payload;
      const dataWithRoot = [
        {
          id: 0,
          parentId: null,
          serial: '0000',
          title: state.titleCategory,
          expanded: true,
          children: data
        }
      ];

      return {
        ...state,
        data: dataWithRoot,
        expandedKeys: keys,
        categoryListLoader: false
      };
    }

    case categoriesActions.SET_DRAG_ENABLE: {
      const { isDragEnable } = payload;

      return {
        ...state,
        enableDrag: isDragEnable
      };
    }

    case categoriesActions.CAT_SEARCH: {
      const { key } = payload;
      if (!key) {
        let s = clone(state.originData[0].children);
        let data = removeExpanedKeyList(s);

        const dataWithRoot = [
          {
            id: 0,
            parentId: null,
            serial: '0000',
            title: state.titleCategory,
            expanded: true,
            children: data
          }
        ];

        return {
          ...state,
          data: dataWithRoot,
          originData: dataWithRoot,
          searchKey: '',
          userLocale: '',
          expandedKeys: []
        };
      } else {
        const data = searchIngs(key, state.dataList);

        const dataWithRoot1 = [
          {
            id: 0,
            parentId: null,
            serial: '0000',
            title: state.titleCategory,
            expanded: true,
            children: data
          }
        ];

        return {
          ...state,
          data: dataWithRoot1,
          searchKey: key
        };
      }
    }
    case categoriesActions.UPDATE_CATEGORY_LIST_DETAILS:
      const dataListCopy = state.dataList
      const items = dataListCopy.findIndex((x) => x.id === payload.categorySelectedId)
      dataListCopy[items].isCircle = payload.isCircle
      dataListCopy[items].show_in_mobile_home = payload.show_in_mobile_home
      dataListCopy[items].icon_base64_string = payload.icon_base64_string

      return {
        ...state,
        dataList: dataListCopy
      }

    case SIGNOUT_SESSION_EXPIRED:
      return initialState;

    case SIGNOUT_USER:
      return initialState;

    case categoriesActions.RESET_TO_INITIAL_STATE:
      return initialState;

    default:
      return state;
  }
};
