/**
 * @description index reducer for ads list and ads reducer connect
 */
import { combineReducers } from 'redux';
import adManagement_list from './listRedux/list.reducer';
import adsImage_list from './imageRedux/imageUploader.reducer';
import adManagement_details from './detailsRedux/addetails.reducer';

export default combineReducers({
  adManagementList: adManagement_list,
  adsImageList: adsImage_list,
  adManagementDetails: adManagement_details
});
