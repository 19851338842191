import { call, takeEvery, all, fork, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { GET_USER_TRANSLATIONS, getUserTranslationsSuccess } from '../actions/locales.actions';
import { doRequest } from './api';

function storeLocales(key, locales, hash = '') {
  if (!locales) return;
  localStorage.setItem(`trans_${key}`, JSON.stringify(locales));
  localStorage.setItem(`trans_${key}_hash`, hash);
}

export function getLocales(key) {
  if (!key) return;
  const locales = localStorage.getItem(`trans_${key}`);
  return locales ? JSON.parse(locales) : null;
}

function getHash(key) {
  return localStorage.getItem(`trans_${key}_hash`);
}

function* checkHash(key, hash) {
  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: `/apptrans/locales/hash/${key}`
    });

    const responseHash = response.data.hash;

    return hash === responseHash;
  } catch (error) {
    console.log(error);
    return false;
  }
}

function* getLocalesFromApi(key) {
  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: `/apptrans/locales/${key}`
    });

    const locales = response.data[key].data;
    const hash = response.data[key].hash;
    yield call(storeLocales, key, locales, hash);
    return [locales, hash];
  } catch (error) {
    console.error(error);

    if (error.response.status === 404) {
      return {};
    }
  }
}

function* getTranslations({ payload: { key } }) {
  if (!key) return;

  let isSameHash = true;
  let isSameEnLangHash = true;

  const hash = yield call(getHash, key);

  if (hash) {
    isSameHash = yield call(checkHash, key, hash);
  }

  const enHash = yield call(getHash, 'en_US');

  if (enHash) {
    isSameEnLangHash = yield call(checkHash, 'en_US', hash);
  }

  let translations = yield call(getLocales, key);

  if (!translations || !isSameHash || !hash || !enHash || !isSameEnLangHash) {
    let xxTemp = yield call(getLocalesFromApi, key);
    if (Object.keys(xxTemp).length !== 0) {
      let [locales, hashFromApi] = xxTemp;
      locales = { ...locales };

      if (key !== 'en_US') {
        let enLocales = getLocales('en_US');

        if (!enLocales || !isSameEnLangHash) {
          yield getLocalesFromApi('en_US');
          enLocales = getLocales('en_US');
        }

        // fill english keys for non existing locale values
        for (let trKey in enLocales) {
          let value = locales[trKey];

          if (value) {
            value = value.trim();
          }
          if (!value) {
            locales[trKey] = enLocales[trKey];
          }
        }

        yield call(storeLocales, key, locales, hashFromApi);
      }
    } else {
      if (key !== 'en_US') {
        let enLocales = getLocales('en_US');

        if (!enLocales || !isSameEnLangHash) {
          yield getLocalesFromApi('en_US');
          enLocales = getLocales('en_US');
        }

        console.log('sdfsdfsd');

        // fill english keys for non existing locale values

        let locales = {};
        for (let trKey in enLocales) {
          let value = locales[trKey];

          if (value) {
            value = value.trim();
          }
          if (!value) {
            locales[trKey] = enLocales[trKey];
          }
        }

        yield call(storeLocales, key, locales, '');
      }
    }
  }
  yield delay(100);
  yield put(getUserTranslationsSuccess());
}

function* userTranslationListener() {
  yield takeEvery(GET_USER_TRANSLATIONS, getTranslations);
}

export default function* rootSaga() {
  yield all([fork(userTranslationListener)]);
}
