import types from '../../../module/contentManagement/stores/redux/store.constants';

/**
 * @description
 *   Reducer to index the distributor catalogue items to efficiently
 *  retrieve brand item and set image of the brand in catalogue_distributor_reducer.js
 */
export default (state = {}, { type, payload }) => {
  switch (type) {
    case types.GET_STOREINFORMATION_VALUE_LIST_SUCCESS: {
      const { data } = payload;

      let indexObj = {
        acceptedpayments: {},
        features: {},
        mobileapps: {},
        socialmedias: {},
        storetypes: {}
      };

      if (data.acceptedpayments.length) {
        for (let i = 0; i < data.acceptedpayments.length; i++) {
          indexObj.acceptedpayments[data.acceptedpayments[i].id] = i;
        }
      }
      if (data.features.length) {
        for (let i = 0; i < data.features.length; i++) {
          indexObj.features[data.features[i].id] = i;
        }
      }

      if (data.mobileapps.length) {
        for (let i = 0; i < data.mobileapps.length; i++) {
          indexObj.mobileapps[data.mobileapps[i].id] = i;
        }
      }

      if (data.socialmedias.length) {
        for (let i = 0; i < data.socialmedias.length; i++) {
          indexObj.socialmedias[data.socialmedias[i].id] = i;
        }
      }

      if (data.storetypes.length) {
        for (let i = 0; i < data.storetypes.length; i++) {
          indexObj.storetypes[data.storetypes[i].id] = i;
        }
      }

      return indexObj;
    }

    default:
      return state;
  }
};
