import { all, fork, takeEvery, put, call, select, take } from 'redux-saga/effects';
import * as profileActions from './profile.constants';
import {
  updateBasicInfoSuccess,
  updateProfileImageSuccess,
  updateChangePasswordSuccess,
  updateBasicSettingsSuccess,
  getProfileInfoSuccess,
  getProfileImageSuccess,
  showBtnLoader,
  hideBtnLoader,
  showMessage,
  hideMessage
} from './profile.actions';
import ImgAvatarDefault from '../../../../assets/images/profileAvatar.jpg';
import { getDefualtLocalSuccsess } from '../../system/categories/redux/categories.actions';
import { getDefualtLocalSuccsessIng } from '../../system/ingredients/redux/ingredients.actions';
import {
  setIngredientArray,
  setENumbersArray
} from './../../../shared/textEditor/redux/textEditorPopup.actions';
import { doRequest } from '../../../shared/redux/sagas/api';
import { message } from 'antd';
import { checkErrResponse } from '../../../shared/utils/errorHandler';
import { addJobToQueue } from './../../../shared/redux/actions/index';

function* updateUserBasicInfo({ payload }) {
  let intl = yield select(state => state.intlReact.intl);

  try {
    const userId = localStorage.getItem('user_id');

    yield call(doRequest, {
      url: `resource/users/${userId}/profile`,
      method: 'PUT',
      data: {
        first_name: payload.firstName,
        last_name: payload.lastName,
        country: payload.country,
        local: payload.language,
        userId: userId
      }
    });

    let mesage = intl.formatMessage({
      id: 'Generic.ApiMessages.Profile.ProfileUpdateSuccess'
    });
    message.info(mesage);
    yield put(updateBasicInfoSuccess(payload));
  } catch (error) {
    if (error.response.data['error']) {
      //yield put(updateProfileImageError());
      let errorMSg = checkErrResponse(
        error,
        intl,
        'Generic.ApiMessages.Profile.ProfileUpdateError'
      );
      if (errorMSg) message.error(errorMSg);
      //message.error(error.response.data['error'].message);
    }
  }
}

function* updateUserBasicSettings({ payload }) {
  try {
    const userId = localStorage.getItem('user_id');

    yield call(doRequest, {
      url: `resource/users/${userId}/profile`,
      method: 'PUT',
      data: {
        first_name: payload.firstName,
        last_name: payload.lastName,
        country: payload.country,
        local: payload.language,
        userId: userId
      }
    });

    yield put(updateBasicSettingsSuccess(payload));
    yield put(getDefualtLocalSuccsess(payload.language));
    yield put(getDefualtLocalSuccsessIng(payload.language));

    let intl = yield select(state => state.intlReact.intl);
    let mesage = intl.formatMessage({
      id: 'Generic.ApiMessages.Profile.ProfileUpdateSuccess'
    });
    message.info(mesage);
  } catch (error) {
    let intl = yield select(state => state.intlReact.intl);
    let errorMSg = checkErrResponse(error, intl, 'Generic.ApiMessages.Profile.ProfileUpdateError');
    if (errorMSg) message.error(errorMSg);
  }
}

function* updateProfileImage({ payload }) {
  let intl = yield select(state => state.intlReact.intl);
  try {
    const userId = localStorage.getItem('user_id');

    yield call(doRequest, {
      url: 'resource/users/profilePic/manage',
      method: 'PUT',
      data: {
        image: payload.image,
        user: userId
      }
    });
    let mesage = intl.formatMessage({
      id: 'Generic.ApiMessages.Profile.ProfileImageUpdateSuccess'
    });
    message.info(mesage);
    yield put(updateProfileImageSuccess(payload));
  } catch (error) {
    console.log({ error });

    if (error.response.status === 413) {
      let mesage = intl.formatMessage({
        id: 'Generic.ApiMessages.FileSizeExceed'
      });
      message.warn(mesage);
    } else {
      let errorMSg = checkErrResponse(
        error,
        intl,
        'Generic.ApiMessages.Profile.ProfileImageUpdateError'
      );
      if (errorMSg) {
        message.error(errorMSg);
      }
      // message.error(error.response.data.error.message);
    }
  }
  if (payload.cb) {
    payload.cb();
  }
}

function* getProfilePicture() {
  let image = '';
  let imageLoader = true;
  try {
    const userId = localStorage.getItem('user_id');
    if (userId) {
      const response = yield call(doRequest, {
        url: `resource/users/profilePic/${userId}`,
        method: 'GET'
      });

      if (response && response.status === 200) {
        if (response.data.image) {
          image = response.data.image;
          imageLoader = false;
        }
      }
    }

    yield put(getProfileImageSuccess(image, imageLoader));
  } catch (error) {
    //console.log(error);

    if (error && error.response && error.response.status === 404) {
      image = ImgAvatarDefault;
      imageLoader = false;
      yield put(getProfileImageSuccess(image, imageLoader));
    }
  }
}

function* getProfileInfo() {
  let intl = yield select(state => state.intlReact.intl);
  try {
    const userId = localStorage.getItem('user_id');
    const response = yield call(doRequest, {
      url: `resource/users/${userId}/profile`,
      method: 'GET'
    });

    if (response.data.country) {
      if (response.data.country == '0094') {
        response.data.country = '5';
      }

      if (response.data.country == '0046') {
        response.data.country = '4';
      }
    } else {
      response.data.country = '4';
    }

    yield put(getProfileInfoSuccess(response.data));
    yield put(getDefualtLocalSuccsess(response.data.local));
    yield put(getDefualtLocalSuccsessIng(response.data.local));

    // const workerParams = {
    //   originalData: 'xxx'
    // };

    // yield put(
    //   addJobToQueue({
    //     workerAction: 'SET_ARRAY_LIST',
    //     data: workerParams,
    //     actionToDispatch: getProfileInfoSuccess
    //   })
    // );

    // const { payload } = yield take('PROFILE_INFO_GET_SUCCESS');

    // console.log(payload);

    // if (payload.data.result) {
    //   let ingArray = payload.data.result;

    //   console.log(ingArray);

    //   yield put(setIngredientArray({ data: ingArray }));
    // }

    // const workerParams2 = {
    //   originalData: 'xxx'
    // };

    // yield put(
    //   addJobToQueue({
    //     workerAction: 'SET_ENUM_ARRAY_LIST',
    //     data: workerParams,
    //     actionToDispatch: getProfileInfoSuccess
    //   })
    // );

    // let payload1 = yield take('PROFILE_INFO_GET_SUCCESS');

    // console.log(payload1);

    // if (payload1.payload.data.result) {
    //   let eNumbersArray = payload1.payload.data.result;

    //   console.log(eNumbersArray);

    //   yield put(setENumbersArray({ data: eNumbersArray }));
    // }
  } catch (error) {
    if (error.response.data[1]) {
      message.error(error.response.data[1]);
    } else {
      if (intl) {
        let errorMSg = checkErrResponse(
          error,
          intl,
          'Generic.ApiMessages.Profile.ProfileRetrieveError'
        );
        if (errorMSg) message.error(errorMSg);
      }
      // if (error.response.data['error']) {
      //   message.error(error.response.data['error'].message);
      // }
    }
  }
}

export function* updateChangePasswordReq({ payload }) {
  let intl = yield select(state => state.intlReact.intl);
  yield put(showBtnLoader());
  try {
    const { newPassword, oldPassword, verifyPassword } = payload;

    const params = new URLSearchParams();
    params.append('currentPassword', oldPassword);
    params.append('newPassword', newPassword);
    params.append('newPasswordConfirm', verifyPassword);

    const response = yield call(doRequest, {
      url: 'auth/resetpassword',
      method: 'POST',
      data: params
    });

    if (response.status === 200) {
      let mesage = intl.formatMessage({
        id: 'Generic.ApiMessages.Profile.PasswordUpdated'
      });
      message.info(mesage);
      yield put(updateChangePasswordSuccess());
    }
  } catch (error) {
    //console.log(error);

    const { status, data } = error.response;
    if (status === 424) {
      yield put(showMessage());
      let mesage = intl.formatMessage({
        id: 'Generic.ApiMessages.Profile.PasswordIncorrect'
      });
      message.warn(mesage);
      yield put(hideBtnLoader());
    }

    if (status === 400) {
      yield put(showMessage());
      message.error(data.message);
      yield put(hideBtnLoader());
    } else if (status === 500) {
      yield put(showMessage());
      let mesage = intl.formatMessage({
        id: 'Generic.ApiMessages.ServerError'
      });
      message.error(mesage);
      yield put(hideBtnLoader());
    }
  }

  yield put(hideBtnLoader());
  yield put(hideMessage());
}

function* updateBasicInfo() {
  yield takeEvery(profileActions.BASIC_INFO_UPDATE, updateUserBasicInfo);
}

function* updateBasicSettings() {
  yield takeEvery(profileActions.BASIC_SETTINGS_UPDATE, updateUserBasicSettings);
}

function* updateProfileImageListener() {
  yield takeEvery(profileActions.PROFILE_IMAGE_UPDATE, updateProfileImage);
}

function* updateChangePassword() {
  yield takeEvery(profileActions.CHANGE_PASSWORD_UPDATE, updateChangePasswordReq);
}

function* profileImageGetListener() {
  yield takeEvery(profileActions.PROFILE_IMAGE_GET, getProfilePicture);
}

function* profileInfoGetListener() {
  yield takeEvery(profileActions.PROFILE_INFO_GET, getProfileInfo);
}

export default function* rootSaga() {
  yield all([
    fork(updateBasicInfo),
    fork(updateProfileImageListener),
    fork(updateChangePassword),
    fork(updateBasicSettings),
    fork(profileInfoGetListener),
    fork(profileImageGetListener)
  ]);
}
