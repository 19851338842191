import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import Settings from './Settings';
import Auth from './Auth';
import profileReducer from 'app/module/profile/redux/profile.reducer';
import loadersReducers from '../../app/shared/redux/reducers/loaders.reducers';
import categoriesReducer from '../../app/module/system/categories/redux/categories.reducer';
import ingredientsReducer from '../../app/module/system/ingredients/redux/ingredients.reducer';
import languageListReducer from '../../app/shared/languageList/languageList.reducer';
import intelReactReducer from '../../app/shared/redux/reducers/IntelReact.reducers';
import nutritionValuesReducer from '../../app/module/system/nutrition-values/redux/nutrition-values.reducer';
import imageUploaderReducer from '../../app/shared/redux/reducers/imageUploader.reducers';
import productReducer from '../../app/module/contentManagement/products/redux/product.reducer';
import storeReducer from '../../app/module/contentManagement/stores/redux/store.reducer';
import productModalsReducer from '../../app/module/contentManagement/products/redux/index.reducer';
import labelsReducer from '../../app/module/system/lables/redux/labels.reducer';
import eNumbersReducer from '../../app/module/system/e-numbers/redux/e-numbers.reducer';
import countriesReducer from '../../app/shared/redux/reducers/countries.reducer';
import catalogueReducer from '../../app/module/contentManagement/catalogue/index.reducer';
import indexedItemReducer from '../../app/shared/redux/reducers/indexedItem.reducer';
import storeInformationReducer from '../../app/module/system/store-information/redux/store-information.reducer';
import tagsReducer from '../../app/module/system/tags/redux/tags.reducer';
import moderationReducer from '../../app/module/contentManagement/moderation/redux/index.reducer';
import adManagementReducer from '../../app/module/adManagement/redux/index.reducer';
import textEditorPopupReducer from '../../app/shared/textEditor/redux/textEditorPopup.reducer';
import categoryCircleReducer from '../../app/module/system/categories/categoryCircle/redux/categoryCircle.reducer';
import CategoryShowInMobileHome from '../../app/module/system/categories/categoryShowInMobileHome/redux/showInMobile.reducer';
import newIngredientReducer from '../../app/module/system/newIngredients/redux/newIngredient.reducer';
const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  profile: profileReducer,
  loaders: loadersReducers,
  categories: categoriesReducer,
  ingredients: ingredientsReducer,
  languageList: languageListReducer,
  intlReact: intelReactReducer,
  nutrients: nutritionValuesReducer,
  imageUploader: imageUploaderReducer,
  product: productReducer,
  store: storeReducer,
  productModals: productModalsReducer,
  labels: labelsReducer,
  eNumbers: eNumbersReducer,
  countries: countriesReducer,
  catalogue: catalogueReducer,
  indexedItem: indexedItemReducer,
  storeInformation: storeInformationReducer,
  tags: tagsReducer,
  moderation: moderationReducer,
  adManagement: adManagementReducer,
  textEditorPopup: textEditorPopupReducer,
  categoryCircle: categoryCircleReducer,
  categoryShowInMobileHome: CategoryShowInMobileHome,
  newIngredient: newIngredientReducer
});

export default reducers;
