import { createTypes, async } from 'redux-action-creator';

export const brandTypes = createTypes(
  [
    ...async('GET_LIST'),
    'UPDATE_MODAL_STATE',
    ...async('SAVE_DETAILS'),
    ...async('EDIT'),
    'SHOW_LOADER',
    'HIDE_LOADER',
    'SET_TEMP_IMG',
    'REMOVE_TEMP_IMG',
    ...async('SAVE_IMG'),
    ...async('REMOVE_IMG'),
    'SET_INITIAL_TITLE',
    'REMOVE_INITIAL_TITLE',
    'SET_COLLECTION_ID',
    'RESET_COLLECTION_ID',
    'OPEN_CATALOGUE',
    'RESET',
    'GET_BRAND__WORKER_SUCCESS'
  ],
  'BRAND'
);
