export const GET_INGREDIENTS = 'GET_INGREDIENTS';
export const GET_INGREDIENTS_SUCCESS = 'GET_INGREDIENTS_SUCCESS';
export const GET_INGREDIENTS_ERROR = 'GET_INGREDIENTS_ERROR';
export const ADD_INGREDIENT = 'ADD_INGREDIENT';
export const ADD_INGREDIENT_SUCCESS = 'ADD_INGREDIENT_SUCCESS';
export const DELETE_INGREDIENT = 'DELETE_INGREDIENT';
export const DELETE_INGREDIENT_SUCCESS = 'DELETE_INGREDIENT_SUCCESS';
export const DELETE_INGREDIENT_FAIL = 'DELETE_INGREDIENT_FAIL';
export const HANDLE_BLUR_INGREDIENT = 'HANDLE_BLUR_INGREDIENT';
export const HANDLE_BLUR_INGREDIENT_SUCCESS = 'HANDLE_BLUR_INGREDIENT_SUCCESS';
export const UPDATE_INGREDIENT_ORDER = 'UPDATE_INGREDIENT_ORDER';
export const UPDATE_INGREDIENT_ORDER_SUCCESS = 'UPDATE_INGREDIENT_ORDER_SUCCESS';
export const SET_SELECTED_INGREDIENT = 'SET_SELECTED_INGREDIENT';
export const GET_INGREDIENT_LANGUAGES = 'GET_INGREDIENT_LANGUAGES';
export const GET_INGREDIENT_LANGUAGES_SUCCESS = 'GET_INGREDIENT_LANGUAGES_SUCCESS';
export const IDENTIFY_BTN_RED_INGREDIENT = 'IDENTIFY_BTN_RED_INGREDIENT';
export const IDENTIFY_BTN_WITHOUT_RED_INGREDIENT = 'IDENTIFY_BTN_WITHOUT_RED_INGREDIENT';
export const VEGAN_CHECK_BOX_CHECKED = 'VEGAN_CHECK_BOX_CHECKED';
export const SHOW_ALERT_WINDOW_INGREDIENT = 'SHOW_ALERT_WINDOW_INGREDIENT';
export const HIDE_ALERT_WINDOW_INGREDIENT = 'HIDE_ALERT_WINDOW_INGREDIENT';
export const TREE_SELECTED_KEY_INGREDIENT = 'TREE_SELECTED_KEY_INGREDIENT';
export const NEXT_TREE_SELECTED_KEY_INGREDIENT = 'NEXT_TREE_SELECTED_KEY_INGREDIENT';
export const SAVE_TREE_INGREDIENT_VALUE = 'SAVE_TREE_INGREDIENT_VALUE';
export const SAVE_TREE_INGREDIENT_VALUE_SUCCESS = 'SAVE_TREE_INGREDIENT_VALUE_SUCCESS';
export const CANCEL_TREE_INGREDIENT_VALUE = 'CANCEL_TREE_INGREDIENT_VALUE';
export const CANCEL_TREE_INGREDIENT_VALUE_SUCCESS = 'CANCEL_TREE_INGREDIENT_VALUE_SUCCESS';
export const UPDATE_INGREDIENT_IMAGE = 'UPDATE_INGREDIENT_IMAGE';
export const UPDATE_INGREDIENT_IMAGE_PATCH_SUCESS = 'UPDATE_INGREDIENT_IMAGE_PATCH_SUCESS';
export const UPDATE_INGREDIENT_IMAGE_SUCCESS = 'UPDATE_INGREDIENT_IMAGE_SUCCESS';
export const UPDATE_INGREDIENT_IMAGE_FAILURE = 'UPDATE_INGREDIENT_IMAGE_FAILURE';
export const GET_INGREDIENT_IMAGE = 'GET_INGREDIENT_IMAGE';
export const GET_INGREDIENT_IMAGE_SUCCESS = 'GET_INGREDIENT_IMAGE_SUCCESS';
export const RESET_INGREDIENT_VALUES = 'RESET_INGREDIENT_VALUES';
export const PLUS_BTN_CLICK = 'PLUS_BTN_CLICK';
export const UPDATE_INGREDIENT_FORM_DETAILS = 'UPDATE_INGREDIENT_FORM_DETAILS';
export const UPDATE_INGREDIENT_FORM_DETAILS_SUCCESS = 'UPDATE_INGREDIENT_FORM_DETAILS_SUCCESS';
export const UPDATE_INGREDIENT_FORM_DETAILS_FAILURE = 'UPDATE_INGREDIENT_FORM_DETAILS_FAILURE';
export const UPDATE_SAVE_INGREDIENT_LOADER = 'UPDATE_SAVE_INGREDIENT_LOADER';
export const VERIFY_RANDOM_NUM_GENERATE = 'VERIFY_RANDOM_NUM_GENERATE';
export const VERIFY_RANDOM_NUM_GENERATE_SUCCESS = 'VERIFY_RANDOM_NUM_GENERATE_SUCCESS';
export const REMOVE_RANDOM_NUMBER = 'REMOVE_RANDOM_NUMBER';
export const REMOVE_RANDOM_NUMBER_SUCCESS = 'REMOVE_RANDOM_NUMBER_SUCCESS';
export const GET_DEFUALT_LOCAL_SUCCESS_ING = 'GET_DEFUALT_LOCAL_SUCCESS_ING';
export const DELETE_INGREDIENT_IMAGE = 'DELETE_INGREDIENT_IMAGE';
export const DELETE_INGREDIENT_IMAGE_SUCCESS = 'DELETE_INGREDIENT_IMAGE_SUCCESS';
export const DELETE_INGREDIENT_IMAGE_ERROR = 'DELETE_INGREDIENT_IMAGE_ERROR';
export const INGREDIENT_IMAGE_TEMP_IMAGE = 'INGREDIENT_IMAGE_TEMP_IMAGE';
export const INGREDIENT_IMAGE_CANCEL_CHANGES = 'INGREDIENT_IMAGE_CANCEL_CHANGES';
export const DELETE_NODE_IDENTIFY = 'DELETE_NODE_IDENTIFY_ING';
export const IDENTIFY_GET_INGREDIENT_DATA_LOADER = 'IDENTIFY_GET_INGREDIENT_DATA_LOADER';
export const UPDATE_SAVE_INGREDIENT_LOADER_CNT = 'UPDATE_SAVE_INGREDIENT_LOADER_CNT';
export const SET_LAST_GENERATED_INGREDIENT_RANDOM_NUMBER =
  'SET_LAST_GENERATED_INGREDIENT_RANDOM_NUMBER';
export const RE_UPDATE_SAVE_INGREDIENT_DETAILS = 'RE_UPDATE_SAVE_INGREDIENT_DETAILS';
export const UPDATE_ING_DATA = 'UPDATE_ING_DATA';
export const ING_SEARCH = 'ING_SEARCH';
export const ING_SEARCH_SUCCESS = 'ING_SEARCH_SUCCESS';
export const RESET_STATE = 'ING_RESET_TO_INITIAL_STATE';
export const UPDATE_ALTERNATIVE_LIST = 'UPDATE_ALTERNATIVE_LIST';
export const GET_DUPLICATED_LIST = 'GET_DUPLICATED_LIST';
export const GET_DUPLICATED_LIST_SUCCESS = 'GET_DUPLICATED_LIST_SUCCESS';
export const GET_DUPLICATED_LIST_FAIL = 'GET_DUPLICATED_LIST_FAIL';
export const ING_SEARCH_JOB_SUCCESS = 'ING_SEARCH_JOB_SUCCESS';
export const DELETE_JOB_SUCCESS = 'DELETE_JOB_SUCCESS';
export const ADD_JOB_SUCCESS = 'ADD_JOB_SUCCESS';
export const ING_UPDATE_JOB_SUCCESS = 'ING_UPDATE_JOB_SUCCESS';
export const DUPLICATE_ING_RESPONSE = 'DUPLICATE_ING_RESPONSE';
export const HAS_FORM_CHANGES = 'HAS_FORM_CHANGES';
