export default function normalizeTreeByLocale(treeList, defaultLocalCode) {
  const normalizedData = [];
  loop(treeList, normalizedData, defaultLocalCode);
  return normalizedData;
}

function loop(treeList, normalizedData, defaultLocalCode) {
  treeList.forEach(tree => {
    let keyInLocale = ' ';
    if (tree.title) {
      let nodeConcatValue = '';
      if (tree.languages) {
        keyInLocale = tree.languages[defaultLocalCode];
        if (!keyInLocale) {
          keyInLocale = tree.languages['en_US'];
        }

        if (!keyInLocale) {
          const localeValues = Object.values(tree.languages);

          keyInLocale = localeValues.find(val => {
            if (val) {
              return true;
            }
            return false;
          });

          keyInLocale = keyInLocale || '';
        }

        nodeConcatValue = keyInLocale;
      } else {
        nodeConcatValue = keyInLocale;
      }
      normalizedData.push({
        title: nodeConcatValue,
        id: tree.id,
        serial: tree.serial,
        parentId: tree.parentId ? tree.parentId : 0
      });
      if (tree.children) loop(tree.children, normalizedData, defaultLocalCode);
    }
  });
}
