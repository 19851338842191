export function checkErrResponse(error, intl, passedId) {
  console.log('ERROR', error.response);

  let errMsg;

  let serverError = intl.formatMessage({
    id: 'Generic.ApiMessages.ServerError'
  });
  let notFoundError = intl.formatMessage({
    id: 'Generic.ApiMessages.NotFoundError'
  });

  let mesageError = intl.formatMessage({
    id: passedId
  });

  if (error.response && error.response.data && error.response.data.error) {
    let statusCode = error.response.data.error.statusCode;

    if (statusCode === 500 || statusCode === 503) {
      errMsg = serverError;
    } else if (error.response.status === 404) {
      errMsg = notFoundError;
    } else if (error.response.data.error.details) {
      errMsg = error.response.data.error.details[0].message;
    } else if (statusCode !== 401) {
    } else {
      errMsg = mesageError;
    }
  } else {
    let statusCode = error.response.status;
    if (statusCode !== 401) {
      errMsg = serverError;
    }
  }
  console.log(errMsg);
  return errMsg;
}
