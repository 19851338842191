import { all, fork, put, takeLatest, call } from 'redux-saga/effects';

import types from '../constant/countries.constants';
import { getCountryListSuccess } from '../actions/countries.action';

import { doRequest } from './api';

// get country list ************************
function* getNewCountryList() {
  try {
    const { data } = yield call(doRequest, {
      method: 'GET',
      url: 'contrans/countries'
    });

    yield put(getCountryListSuccess({ list: data }));
  } catch (error) {
    console.log(error);
  }
}
// get country list ************************//////

function* getNewCountryListWatcher() {
  yield takeLatest(types.GET_COUNTRY_LIST, getNewCountryList);
}

export default function* rootSaga() {
  yield all([fork(getNewCountryListWatcher)]);
}
