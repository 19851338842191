import types from '../constant/countries.constants';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COUNTRY_LIST_SUCCESS: {
      const { list } = action.payload;

      return list;
    }

    default: {
      return state;
    }
  }
};
