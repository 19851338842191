import { all, fork, put, takeLatest, select, call, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import textEditorType from './textEditorPopup.constants';
import {
  setManipulateStringSuccess,
  isFilterOk,
  setTextEditorModifyData,
  spinningLoader,
  setEditorBtnDisable
} from './textEditorPopup.actions';
import { addJobToQueue } from './../../redux/actions/index';

function functionName() {}

function* setModifiedObjFun(options) {
  yield put(setManipulateStringSuccess({ data: options.payload.data }));
  const { ingArray, eNumArray, manipulateString, editorType } = yield select(
    state => state.textEditorPopup
  );
  //console.log(editorType);

  let tagListLocal = 'en_US';
  if (editorType === 'product') {
    tagListLocal = yield select(state => state.product.tagListLocal);
  } else if (editorType === 'moderation') {
    tagListLocal = yield select(state => state.moderation.moderationDetails.droupdownLocale);
  }

  //console.log(ingArray);

  const workerParams = {
    stringModified: manipulateString,
    // stringModified:
    //   'Google Translate, Google översätt, Google översätt #89%k#, TurboLAX - 2, Onion #78#',
    ingArray: ingArray,
    eNumArray: eNumArray,
    locale: tagListLocal ? tagListLocal : 'en_US'
  };

  //console.log(workerParams);

  yield put(
    addJobToQueue({
      workerAction: 'FIND_STRING_MATCH',
      data: workerParams,
      actionToDispatch: isFilterOk
    })
  );

  let { payload } = yield take(textEditorType.IS_FILTER_OK);

  //console.log(payload);

  yield put(setTextEditorModifyData({ data: payload.data.result }));
  //console.log('CALLED');

  //options.payload.cb(payload.data.result);
  yield put(spinningLoader({ loader: 'false' }));
  yield put(setEditorBtnDisable({ disabled: 'false' }));
}

function* functionNameListWatcher() {
  // yield takeLatest(textEditorType.CONSTANT, functionName);
}
function* functionModifiedObjWatcher() {
  yield takeLatest(textEditorType.SET_MANIPULATE_STRING, setModifiedObjFun);
}

export default function* rootSaga() {
  yield all([fork(functionNameListWatcher), fork(functionModifiedObjWatcher)]);
}
