import {
  takeLatest,
  all,
  fork,
  put,
  call,
  select,
  cancelled,
  cancel,
  take,
  race
} from 'redux-saga/effects';
import { doRequest } from 'app/shared/redux/sagas/api';
import { message } from 'antd';
import moderationTypes from './list.constants';
import {
  getDataListSuccess,
  getDataListFail,
  setDataListProductImageSuccess,
  setDataListStoreImageSuccess,
  setDataListUserImageSuccess
} from './list.actions';

// pass intl for get translation message
function* getLocalizedMessage(key) {
  const intlSelector = state => state.intlReact.intl;
  const intl = yield select(intlSelector);
  return intl.formatMessage({ id: key });
}

// get sorted value and sort key
function sortTableRow(sort = {}) {
  if (sort.columnKey && sort.order) {
    if (sort.order === 'ascend') {
      sort.order = 'asc';
    }
    if (sort.order === 'descend') {
      sort.order = 'desc';
    }
  } else {
    sort.columnKey = 'date';
    sort.order = 'desc';
  }

  return sort;
}

// get data range in requested get offset and count
function calculatedRequstedDataList(pagination = {}) {
  let current = pagination.current || 1;
  let pageSize = pagination.pageSize || 5;

  let newDataValue = pageSize * (current - 1) + 1;

  let reqDataObj = {};

  if (current > 1) {
    reqDataObj.offset = newDataValue;
    reqDataObj.count = pageSize;
  } else {
    reqDataObj.offset = current;
    reqDataObj.count = pageSize;
  }

  return reqDataObj;
}

function* getFirstdataList({ payload }) {
  let getImageTask;

  const { pagination, sort } = payload;

  const sortData = yield call(sortTableRow, sort);

  const dataRange = yield call(calculatedRequstedDataList, pagination);

  try {
    const paramsObj = {
      offset: dataRange.offset,
      count: dataRange.count,
      sort_by: sortData.columnKey,
      order: sortData.order
    };
    let response = yield call(getRegularDataList, paramsObj);
    if (response.status === 200) {
      pagination.total = response.data.count;
      let dataList = response.data.docs;

      if (dataList && dataList.length > 0) {
        // get table row data list structure
        dataList = yield call(getTableRowDataList, dataList);

        // get country with flag
        dataList = yield call(getCountryFlagList, dataList);
      } else {
        dataList = [];
      }

      yield put(
        getDataListSuccess({
          dataList: dataList,
          pagination: pagination,
          sort: sort,
          originalData: response.data.docs
        })
      );
      if (dataList && dataList.length > 0) {
        // get image product image and store image
        getImageTask = yield fork(getTiketsImage, dataList);
      }
    }

    /** pause execution to find if the current task is cancelled */
    const { reset } = yield race({
      get: take(moderationTypes.GET_DATA_LIST),
      reset: take(moderationTypes.RESET)
    });

    if (reset) {
      yield cancel(getImageTask);
    }
  } catch (error) {
    console.error(error);

    yield put(getDataListFail());
    const errorMessage = yield call(
      getLocalizedMessage,
      'Generic.ApiMessages.Moderation.GetListError'
    );
    message.error(errorMessage);
  } finally {
    if (yield cancelled()) {
      if (getImageTask) {
        yield cancel(getImageTask);
      }
    }
  }
}

// get table row objects data
function getTableRowDataList(dataList) {
  let customDataArray = [];
  dataList.forEach(element => {
    const tableDataObj = {
      _id: element._id,
      productId: element.non_translate.productId,
      productCollectionId: element.non_translate.mediaCollectionId,
      userId: element.user,
      userName: element.userName,
      date: element.date,
      store: element.store,
      source: element.source,
      locale: element.translatable.locale,
      lang: element.lang,
      country: element.country,
      vegan_type: element.non_translate.vegan_type,
      originProdData: element.originProdData ? element.originProdData : ''
    };
    customDataArray.push(tableDataObj);
  });
  return customDataArray;
}

// send api request to get moderation data list
function* getRegularDataList(paramsObj) {
  const response = yield call(doRequest, {
    url: '/prodmod/product_moderation/tickets',
    method: 'GET',
    params: paramsObj
  });

  return response;
}

// get flag country list   or get country name for the list
function* getCountryFlagList(firstDataList) {
  let newDataListArray = [];
  const countryList = yield select(s => s.indexedItem.countries);

  for (let index = 0; index < firstDataList.length; index++) {
    const item = firstDataList[index];

    // get country flag with  store  address city
    if (item.store && item.store.country) {
      if (item.store.address && item.store.address !== '') {
        if (item.store.address.split(',')) {
          let city = item.store.address.split(',');

          let cityLength = city && city.length ? city.length : 0;
          if (city && cityLength > 0) {
            item.country_flag = city[cityLength - 1];
            item.country_code =
              countryList[item.store.country] && countryList[item.store.country].code
                ? countryList[item.store.country].code.toLowerCase()
                : '';
          } else {
            item.country_flag = '';
            item.country_code =
              countryList[item.store.country] && countryList[item.store.country].code
                ? countryList[item.store.country].code.toLowerCase()
                : '';
          }
        } else {
          item.country_flag = '';
          item.country_code =
            countryList[item.store.country] && countryList[item.store.country].code
              ? countryList[item.store.country].code.toLowerCase()
              : '';
        }
      } else {
        item.country_flag = '';
        item.country_code =
          countryList[item.store.country] && countryList[item.store.country].code
            ? countryList[item.store.country].code.toLowerCase()
            : '';
      }
    } else {
      item.country_flag = '';
      item.country_code = '';
    }

    newDataListArray.push(item);
  }

  return newDataListArray;
}

// get user profile image
function* getUserProfileImage(userId, tiketId) {
  let userImage = '';
  let avatarImageObj = {
    userImage: '',
    tiketId: ''
  };
  if (userId) {
    try {
      let getUserImage = yield call(doRequest, {
        url: `resource/users/profilePic/${userId}`,
        method: 'GET'
      });

      if (getUserImage.status === 200) {
        userImage = getUserImage.data.image;
      }
    } catch (error) {
      userImage = '';
    }
  }
  avatarImageObj.userImage = userImage;
  avatarImageObj.tiketId = tiketId;
  return avatarImageObj;
}

// get moderation data table list id's
function* getIndexedModerationIds() {
  const items = yield select(state => state.indexedItem.moderation);

  return items;
}

// call to get table images from collection
function* getTiketsImage(dataList = []) {
  const chunkedList = yield call(chunk, dataList, 5);

  for (let list of chunkedList) {
    const indexes = yield call(getIndexedModerationIds);

    yield call(getUserAvatarImage, list);

    yield call(getProductImageList, list, indexes);

    yield call(getStoreImageList, list, indexes);
  }
}

// get chank files
function chunk(array, size) {
  const chunked_arr = [];
  let index = 0;
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
}

// get store images and indexes
function* getStoreImageList(list, indexes) {
  const requestsStoreImg = list.map(item =>
    call(getStoreimage, item._id, item.store.mediaCollectionId)
  );

  const responsesStoreImg = yield all(requestsStoreImg);

  const filtertedReponseStoreImg = responsesStoreImg.filter(responsesStoreImg =>
    responsesStoreImg ? true : false
  );

  yield put(setDataListStoreImageSuccess({ image: filtertedReponseStoreImg, indexes }));
}

// get product images and indexes
function* getProductImageList(list, indexes) {
  const requests = list.map(item => call(getProductimage, item._id, item.productCollectionId));

  const responses = yield all(requests);

  const filtertedReponse = responses.filter(response => (response ? true : false));

  yield put(setDataListProductImageSuccess({ image: filtertedReponse, indexes }));
}

//  get avatar image for product
function* getProductimage(id, collectionId) {
  if (!collectionId) return;
  try {
    const imageResponse = yield call(doRequest, {
      url: `/unimup/objctupload/manuf/${collectionId}`,
      method: 'GET'
    });

    const { data } = imageResponse;

    if (data.pages.length) {
      const imageUrl = data.pages[0].thumbnail.fetchUrl;
      const imageResponse2 = yield call(doRequest, {
        url: `unimup/objctupload${imageUrl}`,
        method: 'GET',
        responseType: 'blob'
      });

      const { data: imageData } = imageResponse2;
      const image = URL.createObjectURL(imageData);
      return { id, image };
    }
  } catch (error) {}
}

// get user avatar image
function* getUserAvatarImage(list) {
  const requests = list.map(item => call(getUserProfileImage, item.userId, item._id));

  const responses = yield all(requests);

  const filtertedReponse = responses.filter(response => (response ? true : false));

  yield put(setDataListUserImageSuccess({ image: filtertedReponse }));
}

//  get avatar image
function* getStoreimage(id, collectionId) {
  if (!collectionId) return;
  try {
    const imageResponse = yield call(doRequest, {
      url: `unimup/objctupload/collection/${collectionId}`,
      method: 'GET'
    });

    const { data } = imageResponse;

    if (data.pages.length) {
      const imageUrl = data.pages[0].thumbnail.fetchUrl;
      const imageResponse2 = yield call(doRequest, {
        url: `unimup/objctupload${imageUrl}`,
        method: 'GET',
        responseType: 'blob'
      });

      const { data: imageData } = imageResponse2;
      const image = URL.createObjectURL(imageData);
      return { id, image };
    }
  } catch (error) {}
}

/**
 * Watchers
 */

function* getFirstdataListSagaWatcher() {
  yield takeLatest(moderationTypes.GET_DATA_LIST, getFirstdataList);
}

export default function* rootSaga() {
  yield all([fork(getFirstdataListSagaWatcher)]);
}
