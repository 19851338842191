/** remove the country emoji from the string */
export function filterCountryEmojis(tags = []) {
  return tags.map(tag => {
    if (tag.group === 'Countries') {
      const countryWithEmoji = tag.value.split(' ');
      const countryWithOutEmoji = countryWithEmoji.slice(1);
      const country = countryWithOutEmoji.join(' ');
      return { ...tag, value: country };
    }

    return tag;
  });
}
