import {
  all,
  fork,
  put,
  takeLatest,
  select,
  call,
  race,
  take,
  takeEvery
} from 'redux-saga/effects';
import arrayMove from 'array-move';

import types from './tags.constants';
import {
  getTagsListSuccess,
  updateSaveButtonState,
  saveChangesFail,
  saveChangesSuccess,
  addNewNodeSuccess,
  saveNewNodeSuccess,
  updateSortOrderSuccess,
  searchTagsuccess,
  deleteNodeSuccess,
  updateSaveModal,
  saveChanges,
  getTagsList,
  setSelectedNodeSuccess,
  saveTagsImageSuccess,
  removeTagsImageSuccess,
  showTagsLoader,
  saveTagsImageFail,
  hideTagsImgLoader,
  getTagsImageSuccess,
  getDuplicatedTagList,
  getDuplicatedTagListSuccess,
  getDuplicatedTagListFail,
  searchJobsSuccess,
  searchDuplicateJobsSuccess,
  updateAlternativeLisr,
  deleteJobSuccess,
  duplicateTagsResponse
} from './tags.actions';

import { displaySaveDeleteBtn } from '../../../../shared/redux/actions/imageUploader.actions';
import { addJobToQueue } from '../../../../shared/redux/actions/index';
import { message } from 'antd';
import { doRequest } from '../../../../shared/redux/sagas/api';
import ListTypeEnums from '../../nutrition-values/enums/list-types';
import uuid from 'uuid';

function* getIntlMessage(id) {
  const intl = yield select(({ intlReact: { intl } }) => intl);
  return intl.formatMessage({ id });
}

function* getUserLocale() {
  const [id, languageList] = yield select(s => [s.profile.language, s.languageList.languageList]);
  const language = languageList.find(language => language.id === id);
  return language.locale;
}

function* getList(payload) {
  let intl = yield select(state => state.intlReact.intl);
  try {
    const locale = yield call(getUserLocale);

    const { data } = yield call(doRequest, {
      method: 'GET',
      url: 'prodprop/tags/'
    });
    const stateData = yield call(buildListData, locale, data);

    if (Object.values(stateData).length === 0) {
      stateData[ListTypeEnums.COMPARISON] = [];
    }

    yield put(
      getTagsListSuccess({
        data: stateData,
        search: payload.payload.search
      })
    );
  } catch (error) {
    console.log({ error });
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Tags.GetListError'
      })
    );
  }
}

function buildListData(userLocale, data) {
  const stateData = {};

  const typeSpecificValues = getNodeValues(userLocale, data);

  stateData['comparison'] = typeSpecificValues;

  return stateData;
}

function getNodeValues(userLocale, items) {
  return items.map(item => {
    const node = {};
    node.id = item._id;
    node.position = item.position;
    node.value = getNodeLanguageSpecificName(userLocale, item.translations, item);
    node.serial = item.serial;

    return node;
  });
}

function getNodeLanguageSpecificName(userLocale, languages, item) {
  const { serial } = item;
  if (languages) {
    if (languages[userLocale] && languages[userLocale]['name']) {
      return 'T' + serial + ' - ' + languages[userLocale]['name'];
    }

    if (languages['en_US'] && languages['en_US']['name']) {
      return 'T' + serial + ' - ' + languages['en_US']['name'];
    }

    for (let locale in languages) {
      if (languages[locale]['name']) {
        return 'T' + serial + ' - ' + languages[locale]['name'];
      }
    }
  }

  return 'T' + serial;
}

function* addNode({ payload }) {
  const shouldProceed = yield call(handleChangesBeforeSwitching);

  if (!shouldProceed) return;

  yield put(addNewNodeSuccess());
}

function* saveNode(node) {
  const { newNode, values } = node;
  let intl = yield select(state => state.intlReact.intl);

  let arrValues = values.slice();
  arrValues.shift();

  // remove the temp node and set the values if new node value is empty
  if (!newNode) {
    return yield put(saveNewNodeSuccess({ values: arrValues }));
  }

  const defaultLocale = yield call(getUserLocale);

  let formData = {};

  formData = {
    translations: {
      [defaultLocale]: {
        name: newNode,
        explanation: '',
        links: [],
        alternativeTitle: []
      }
    },
    copyrightText: '',
    imageSrc: '',
    collectionId: ''
  };

  const alternativeTitleObj = {
    [defaultLocale]: newNode
  };
  try {
    let responseAlternative = yield call(doRequest, {
      url: `contrans/translations/mainTitleAddCheck/TAGS`,
      method: 'POST',
      data: alternativeTitleObj
    });

    if (responseAlternative.status === 200) {
      const response = yield call(doRequest, {
        url: 'prodprop/tags',
        method: 'POST',
        data: formData
      });

      const responseData = response.data;
      const value = `T${responseData.serial} - ${newNode}`;
      const newNodeItem = {
        value: value,
        //name: newNode,
        id: responseData['_id'],
        //_id: responseData['_id'],
        position: responseData.position,
        serial: responseData.serial
      };

      const dupNewNodeItem = {
        _id: responseData['_id'],
        id: responseData['_id'],
        name: newNode,
        serial: responseData.serial,
        alternativeTitle: false
      };
      arrValues = [newNodeItem, ...arrValues];

      yield put(
        saveNewNodeSuccess({
          values: arrValues,
          newNode: newNodeItem,
          dupNewNodeItem: dupNewNodeItem
        })
      );
      yield call(setSelectedNode, newNodeItem.id);
    }
  } catch (error) {
    if (error.response.status === 419) {
      message.error(
        intl.formatMessage({
          id: 'Generic.ApiMessages.Tags.AlternativeDuplicateError'
        })
      );
    } else {
      message.error(
        intl.formatMessage({
          id: 'Generic.ApiMessages.Tags.NodeSaveError'
        })
      );
    }

    //revert to original values
    return yield put(saveNewNodeSuccess({ values: arrValues }));
  }
}

function* updateOrder({ payload: { values, oldIndex, newIndex } }) {
  let intl = yield select(state => state.intlReact.intl);
  const updatedValues = arrayMove(values, oldIndex, newIndex);
  const id = values[oldIndex]['id'];
  const newPosition = values[newIndex]['position'];
  // update the values to update ui element positions immediately
  yield put(updateSortOrderSuccess({ values: updatedValues }));

  try {
    const response = yield call(doRequest, {
      method: 'PUT',
      url: `prodprop/tags/dragndrop/${encodeURIComponent(id)}`,
      data: {
        new_pos: newPosition
      }
    });
    message.info(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Tags.NodeOrderChangedSuccess'
      })
    );
    yield put(getTagsList({ search: '' }));
  } catch (error) {
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Tags.NodeOrderChangedError'
      })
    );
    yield put(updateSortOrderSuccess({ values: values }));
  }
}

// get selected nod data *****************************
function* setSelectedNode(id) {
  yield put(displaySaveDeleteBtn(true));
  yield put(duplicateTagsResponse({ duplicateTitles: [] }));

  let intl = yield select(state => state.intlReact.intl);
  const btnState = yield select(s => s.tags.saveButtonState);
  if (btnState === 'saving-changes') {
    message.warn(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Tags.NodeSelectedError'
      })
    );
    return;
  }

  const shouldProceed = yield call(handleChangesBeforeSwitching);

  if (!shouldProceed) return;

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: `prodprop/tags/${encodeURIComponent(id)}`
    });

    const selectedData = response.data || {};

    const getImageId = selectedData.collectionId;

    let getAlternatives = yield call(getAlternativeList, selectedData.translations);

    yield put(setSelectedNodeSuccess({ id, selectedData, alternatives: getAlternatives }));
    yield put(getTagsImageSuccess({ imagedata: [] }));
    yield put(showTagsLoader());
    if (getImageId) {
      yield call(getTagsImageRequest, getImageId);
    } else {
      yield put(hideTagsImgLoader());
    }
  } catch (error) {
    console.log({ error });
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Tags.GetNodeDataError'
      })
    );
  }
}
// get selected nod data *****************************/////////////

/**
 * when to handle
 *  1 - When switching nodes
 *  2 - When user creates a node while has changes
 */
function* handleChangesBeforeSwitching() {
  const btnState = yield select(s => s.tags.saveButtonState);

  if (!(yield call(shouldHandle, btnState))) {
    return true;
  }

  yield put(updateSaveModal({ state: 'show' }));

  const action = yield race({
    saveChanges: take(types.SAVE_CHANGES_AND_SWITCH),
    cancelAndSwitch: take(types.CANCEL_CHANGES_AND_SWITCH),
    cancelAndStay: take(types.CANCEL_CHANGES_AND_STAY)
  });

  if (action.saveChanges) {
    yield put(saveChanges());

    const { saveFailed } = yield race({
      saveSuccess: take(types.SAVE_CHANGES_SUCCESS),
      saveFailed: take(types.SAVE_CHANGES_FAIL)
    });

    if (saveFailed) {
      yield put(updateSaveModal({ state: 'hide' }));
      return false;
    }
  } else if (action.cancelAndStay) {
    yield put(updateSaveModal({ state: 'hide' }));
    return false;
  }

  yield put(updateSaveModal({ state: 'hide' }));
  return true;
}

function shouldHandle(btnState) {
  const btnStateToHandle = ['unsaved', 'unsaved-errors'];
  return btnStateToHandle.includes(btnState);
}

// get main title send obj
function getMainTitleDuplicateCheckObj(mailTitleTranslationOBJ) {
  for (let key in mailTitleTranslationOBJ) {
    if (!mailTitleTranslationOBJ[key].name) {
      delete mailTitleTranslationOBJ[key];
    }
  }
  const dataToSend = Object.entries(mailTitleTranslationOBJ).reduce((dataObject, row) => {
    const [key, value] = row;
    dataObject[key] = value.name;

    return dataObject;
  }, {});
  return dataToSend;
}

// post data******************************************
function* saveFormChanges() {
  let intl = yield select(state => state.intlReact.intl);
  const formPassed = yield call(getFormData);
  const [id, nodeData, searchKey] = yield select(s => [
    s.tags.selectedNode,
    s.tags.selectedNodeData,
    s.tags.searchKey
  ]);

  const translations = {
    ...formPassed.translations
  };

  for (let [key, value] of Object.entries(nodeData.translations)) {
    if (
      !(key in translations) ||
      (key in translations && !Object.values(translations[key]).length)
    ) {
      translations[key] = value;
    }
  }

  formPassed.translations = translations;

  const defaultLocale = yield call(getUserLocale);

  if (formPassed.translations[defaultLocale].name) {
    try {
      const mailTitleTranslationOBJ = JSON.parse(JSON.stringify(formPassed.translations));
      const mainTitlesendObj = yield call(getMainTitleDuplicateCheckObj, mailTitleTranslationOBJ);

      yield put(updateSaveButtonState({ btnState: 'saving-changes' }));

      const responseMainTitle = yield call(doRequest, {
        url: `contrans/translations/mainTitleCheck/TAGS/${id}`,
        method: 'POST',
        data: mainTitlesendObj
      });

      if (responseMainTitle.status === 200) {
        yield put(duplicateTagsResponse({ duplicateTitles: [] }));
        try {
          // get alternative duplicate check request data
          let getAlternativeTitle = yield call(getAlternativeTitleList, formPassed.translations);

          let responseAlternative = yield call(doRequest, {
            url: `contrans/translations/dupsBulkCheck/TAGS`,
            method: 'POST',
            data: getAlternativeTitle
          });

          if (responseAlternative.status === 200) {
            yield call(doRequest, {
              url: `prodprop/tags/${id}`,
              method: 'PUT',
              data: formPassed
            });

            const form = JSON.parse(JSON.stringify(formPassed));

            let getAlternatives = yield call(getAlternativeList, form.translations);
            if (searchKey) {
              yield put(getTagsList({ search: 'has' }));
            } else {
              yield put(getTagsList({ search: '' }));
            }

            yield take(types.GET_LIST_SUCCESS);

            yield put(saveChangesSuccess({ form, alternatives: getAlternatives }));
            yield put(updateSaveButtonState({ btnState: 'saved-changes' }));
          }
        } catch (error) {
          if (error.response.status === 419) {
            const form = JSON.parse(JSON.stringify(formPassed));
            let getAlternatives = yield call(getAlternativeListWithDup, form.translations);
            let responseData = error.response.data;
            let getAlternativesDuplicates = yield call(
              duplicatesAlternatives,
              responseData,
              getAlternatives
            );

            yield put(updateAlternativeLisr({ alternatives: getAlternativesDuplicates }));
            message.error(
              intl.formatMessage({
                id: 'Generic.ApiMessages.Tags.AlternativeDuplicateError'
              })
            );

            yield put(updateSaveButtonState({ btnState: 'unsaved' }));
          } else if (
            error.response &&
            error.response.status === 400 &&
            error.response.data.message.includes('exsiting')
          ) {
            message.error(
              intl.formatMessage({
                id: 'Generic.ApiMessages.Tags.TagsExists'
              })
            );
          } else {
            message.error(
              intl.formatMessage({
                id: 'Generic.ApiMessages.Tags.FormSaveError'
              })
            );
          }
          yield put(saveChangesFail());
          yield put(updateSaveButtonState({ btnState: 'unsaved' }));

          yield put(updateSaveModal({ state: 'hide' }));
        }
      }
    } catch (error) {
      if (error.response.status === 419) {
        message.error(
          intl.formatMessage({
            id: 'Generic.ApiMessages.Tags.AlternativeDuplicateError'
          })
        );

        yield put(updateSaveButtonState({ btnState: 'unsaved' }));
        yield put(duplicateTagsResponse({ duplicateTitles: error.response.data }));
        yield put(updateSaveModal({ state: 'hide' }));
      }
    }
  } else {
    yield put(updateSaveButtonState({ btnState: 'saving-changes' }));
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Tags.FormSaveError'
      })
    );
    yield put(updateSaveButtonState({ btnState: 'unsaved' }));
    yield put(updateSaveModal({ state: 'hide' }));
  }
}

// display duplicate values in alternatives
function duplicatesAlternatives(dataList, alternatives) {
  const alternativesClone = JSON.parse(JSON.stringify(alternatives));
  for (let data of dataList) {
    for (let [locale, value] of Object.entries(data)) {
      if (locale in alternativesClone) {
        const { alternativeTitle } = alternativesClone[locale];
        for (let title of alternativeTitle) {
          const { name } = title;

          if (value.includes(name.toLowerCase().trim())) {
            title['className'] = 'addColorTag';
          }
        }
      }
    }
  }

  return alternativesClone;
}

// create alternative duplicates check sending data structure
function getAlternativeTitleList(alternativeList) {
  let getAllAlterNatives = {};

  for (let key in alternativeList) {
    if (alternativeList[key] && alternativeList[key].alternativeTitle) {
      let getFilterValue = alternativeList[key].alternativeTitle.filter(
        item => (item && item.status === undefined) || (item && item.className !== undefined)
      );

      getAllAlterNatives[key] = getFilterValue;
    } else {
      getAllAlterNatives['en_US'] = [];
    }
  }

  return getAllAlterNatives;
}

// set store alternative data list with update status to old values
function getAlternativeList(translationList) {
  let getAllAlterNatives = {};

  for (let key in translationList) {
    if (translationList[key] && translationList[key].alternativeTitle) {
      getAllAlterNatives[key] = {
        alternativeTitle: translationList[key].alternativeTitle
      };
      getAllAlterNatives[key].alternativeTitle.map(item => {
        if (item && item.className) {
          return delete item.className;
        }
        if (item) {
          return (item['status'] = 'OldValue');
        }
      });
    } else {
      getAllAlterNatives[key] = {
        alternativeTitle: []
      };
    }
  }

  return getAllAlterNatives;
}
// get alternative locale value list
function getAlternativeListWithDup(translationList) {
  let getAllAlterNatives = {};

  for (let key in translationList) {
    if (translationList[key] && translationList[key].alternativeTitle) {
      getAllAlterNatives[key] = {
        alternativeTitle: translationList[key].alternativeTitle
      };
    } else {
      getAllAlterNatives[key] = {
        alternativeTitle: []
      };
    }
  }

  return getAllAlterNatives;
}

function* getFormData() {
  const [formData] = yield select(s => [s.tags.formData]);

  const collectId = yield select(s => s.tags.collectionId);
  const defaultLocale = yield call(getUserLocale);

  const formattedFormData = yield call(formatData, formData, defaultLocale, collectId);

  const dataToSend = {
    ...formattedFormData
  };

  return dataToSend;
}

function formatData(formData, defaultLocale = 'en_US', collectionId = '') {
  let tagsObj = {};
  const data = {};

  for (let key in formData) {
    const langData = formData[key];

    const newLangData = {};

    for (let name in langData) {
      if (typeof langData[name]['value'] !== 'undefined') {
        let keyName = name;
        if (name.startsWith(key)) {
          keyName = name.slice(key.length + 1);
        }
        newLangData[keyName] = langData[name]['value'];
      }
    }
    data[key] = newLangData;
  }

  const { copyrightText, imageSrc, ...translations } = data[defaultLocale];

  data[defaultLocale] = translations;

  const basicProperties = {
    copyrightText,
    imageSrc,
    collectionId
  };

  if (!basicProperties.copyrightText) {
    basicProperties.copyrightText = '';
  }

  if (!basicProperties.imageSrc) {
    basicProperties.imageSrc = '';
  }

  /** transform links to array */
  for (let [locale, value] of Object.entries(data)) {
    const links = Object.entries(value)
      .map(([key, value]) => (key.includes('link') ? value : null))
      .filter(item => item !== null);

    if (links.length) {
      data[locale].links = links;
    }

    for (let key in data[locale]) {
      if (key.includes('links_')) {
        delete data[locale][key];
      }
    }
  }

  /** transform alternative to array */
  for (let [locale, value] of Object.entries(data)) {
    if (value.alternativeTitle && value.alternativeTitle.length > 0) {
      const alternativeTitle = Object.entries(value)
        .map(([key, value]) => (key.includes('alternativeTitle') ? value : null))
        .filter(item => item !== null);

      if (alternativeTitle[0].length > 0) {
        data[locale].alternativeTitle = alternativeTitle[0];
      } else {
      }
    }
    if (data[locale].alternativeTitle && data[locale].alternativeTitle.length === 0) {
      for (let key in data[locale]) {
        if (key.includes('alternativeTitle')) {
          data[locale][key] = [];
        }
      }
    }

    for (let key in data[locale]) {
      if (key.includes('_alternativeTitle')) {
        delete data[locale][key];
      }
    }
  }

  tagsObj = {
    ...basicProperties,
    translations: data
  };

  return tagsObj;
}

function* search(action) {
  const key = action.payload.key.toLowerCase();

  if (!key) {
    return yield put(searchTagsuccess({ data: null }));
  }
  const duplicateList = yield select(({ tags: { duplicatedList } }) => duplicatedList);

  const taskId = uuid();

  yield put(
    addJobToQueue({
      id: taskId,
      workerAction: 'TAGS_SEARCH',
      data: { list: duplicateList, searchKey: key },
      actionToDispatch: searchJobsSuccess
    })
  );

  let resultAction;

  while (true) {
    resultAction = yield take('TAGS_SEARCH_JOB_SUCCESS');
    /** watch for the specific id of this task */
    if (resultAction.payload.id === taskId) {
      break;
    }
  }

  yield put(
    searchTagsuccess({
      data: {
        comparison: resultAction.payload.data.result
      }
    })
  );
}

function* deleteNode(action) {
  const { id } = action.payload;
  let intl = yield select(state => state.intlReact.intl);

  try {
    const response = yield call(doRequest, {
      url: `prodprop/tags/${encodeURIComponent(id)}`,
      method: 'DELETE'
    });

    const tagsState = yield select(state => state.tags);

    const workerParams = {
      originalData: tagsState.originalData.comparison,
      duplicatedList: tagsState.duplicatedList,
      items: tagsState.comparison,
      id: id
    };

    yield put(
      addJobToQueue({
        workerAction: 'TAGS_DELETE',
        data: workerParams,
        actionToDispatch: deleteJobSuccess
      })
    );

    const {
      payload: {
        data: { result }
      }
    } = yield take(types.DELETE_JOB_SUCCESS);

    const { originalData, items, duplicatedList } = result;

    yield put(deleteNodeSuccess({ id, originalData, items, duplicatedList }));
  } catch (error) {
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Tags.NodeDeleteError'
      })
    );
  }
}

function* saveImage() {
  yield put(showTagsLoader());

  try {
    const id = yield select(state => state.tags.selectedNode);
    const image = yield select(state => state.tags.image);
    const imageBinary = yield select(state => state.tags.imageBinary);
    const collectionId = yield select(state => state.tags.collectionId);

    // if a previous image is uploaded
    // delete it and then upload new one
    if (collectionId) {
      yield call(doRequest, {
        url: `unimup/objctupload/prodlabel/${collectionId}`,
        method: 'DELETE'
      });
    }

    const formData = new FormData();
    formData.append('media', imageBinary);

    const response = yield call(doRequest, {
      url: `unimup/objctupload/prodlabel/${id}`,
      method: 'POST',
      data: formData
    });

    const imgObj = {
      collectionId: response.data.CollectionId
    };
    yield call(doRequest, {
      url: `prodprop/tags/${id}`,
      method: 'PATCH',
      data: imgObj
    });

    const newCollectionId = response.data.CollectionId;
    yield put(saveTagsImageSuccess({ img: image, item: newCollectionId }));

    message.info(yield getIntlMessage('Generic.ApiMessages.Tags.ImageUploadSuccess'));
  } catch (error) {
    console.log({ error });

    message.error(yield getIntlMessage('Generic.ApiMessages.Tags.ImageUploadError'));
    yield put(saveTagsImageFail());
  } finally {
    yield put(hideTagsImgLoader());
  }
}

function* deleteImage() {
  try {
    const collectionId = yield select(state => state.tags.collectionId);
    const id = yield select(state => state.tags.selectedNode);

    if (collectionId) {
      yield call(doRequest, {
        url: `unimup/objctupload/prodlabel/${collectionId}`,
        method: 'DELETE'
      });

      const imgObj = {
        collectionId: ''
      };
      yield call(doRequest, {
        url: `prodprop/tags/${id}`,
        method: 'PATCH',
        data: imgObj
      });

      yield put(removeTagsImageSuccess());
      message.info(yield getIntlMessage('Generic.ApiMessages.Tags.ImageDeleteSuccess'));
    }
  } catch (error) {
    console.log({ error });
    message.error(yield getIntlMessage('Generic.ApiMessages.Tags.ImageDeleteError'));
  }
}

function* getTagsImageRequest(id) {
  let fileList = [];
  let tempObj = {};
  let response;
  let response1;

  try {
    if (id && id != '') {
      response = yield call(doRequest, {
        url: `unimup/objctupload/prodlabel/${id}`,
        method: 'GET'
      });

      let imageUrls = imageList(response.data.pages);

      for (var i = 0; i < imageUrls.length; i++) {
        tempObj = {};
        response1 = yield call(doRequest, {
          method: 'GET',
          url: `/unimup/objctupload${imageUrls[i].tempUrl}`,
          responseType: 'blob'
        });

        let image = URL.createObjectURL(response1.data);
        tempObj = {
          uid: `${imageUrls[i].pageId}`,
          url: image,
          active: imageUrls[i].active,
          position: `${i}`,
          type: response1.data ? response1.data.type : ''
        };
        fileList.push(tempObj);
      }
    }

    if (id === '') {
      fileList = [];
    }
    if (response1.status === 200) {
      yield put(getTagsImageSuccess({ imagedata: fileList }));
    }
  } catch (error) {
    return false;
  } finally {
    yield put(hideTagsImgLoader());
  }
}

function imageList(array) {
  let newArr = [];
  let tempObj = {};
  if (array) {
    for (var i = 0; i < array.length; i++) {
      tempObj = {};

      tempObj = {
        tempUrl: array[i].optimized.fetchUrl,
        pageId: array[i].pageId,
        active: array[i].active
      };
      newArr.push(tempObj);
    }
  }

  return newArr;
}

function* getDuplicatedTagListSaga() {
  try {
    const userLocale = yield call(getUserLocale);
    const response = yield call(doRequest, {
      method: 'GET',
      url: `prodprop/tags/alternativeList/${userLocale}`
    });

    yield take(types.GET_LIST_SUCCESS);
    yield put(getDuplicatedTagListSuccess({ data: response.data }));

    const taskId = uuid();
    const searchKey = yield select(({ tags: { searchKey } }) => searchKey);

    if (searchKey) {
      yield put(
        addJobToQueue({
          id: taskId,
          workerAction: 'TAGS_SEARCH_DUPLICATE',
          data: { list: response.data.data, searchKey: searchKey },
          actionToDispatch: searchDuplicateJobsSuccess
        })
      );

      let resultAction;

      while (true) {
        resultAction = yield take(types.SEARCH_DUPLICATE_JOB_SUCCESS);

        /** watch for the specific id of this task */
        if (resultAction.payload.id === taskId) {
          break;
        }
      }

      let obj = {
        comparison: resultAction.payload.data.result
      };

      yield put(searchTagsuccess({ data: obj }));
    }
  } catch (error) {
    yield put(getDuplicatedTagListFail());
  }
}

/** Watchers */

function* getListWatcher() {
  yield takeLatest(types.GET_LIST, getList);
}
function* saveImageWatcher() {
  yield takeLatest(types.SAVE_IMG, saveImage);
}

function* deleteImageWatcher() {
  yield takeLatest(types.REMOVE_IMG, deleteImage);
}

function* saveNodeLabelWatcher() {
  while (true) {
    const action = yield take(types.SAVE_NODE);
    yield call(saveNode, action.payload);
  }
}

function* updateOrderLabelWatcher() {
  // yield takeEvery(types.UPDATE_ORDER, updateOrder);
  while (true) {
    const action = yield take(types.UPDATE_ORDER);
    yield call(updateOrder, action);
  }
}

function* setSelectedNodeLabelWatcher() {
  yield takeLatest(types.SET_SELECTED_NODE, ({ payload: { id, type } }) =>
    setSelectedNode(id, type)
  );
}

function* saveFormChangesLabelWatcher() {
  yield takeLatest(types.SAVE_CHANGES, saveFormChanges);
}

function* searchLabelWatcher() {
  yield takeEvery(types.SEARCH, search);
}

function* deleteNodeLabelWatcher() {
  yield takeLatest(types.DELETE_NODE, deleteNode);
}

function* addNodeLabelWatcher() {
  while (true) {
    const action = yield take(types.ADD_NODE);
    yield call(addNode, action);
  }
}

/**
 * Individual watcher to invoke duplicate list GET request
 */
function* getDuplicatedListWatcher() {
  yield takeLatest(types.GET_DUPLICATED_LIST, getDuplicatedTagListSaga);
}

/**
 * Watch for GET_LIST action and parallely get the new
 * duplicate list
 */
function* getDuplicatedListWithGetListWatcher() {
  yield takeLatest(types.GET_LIST, getDuplicatedTagListSaga);
}

export default function* rootSaga() {
  yield all([
    fork(getListWatcher),
    fork(getDuplicatedListWatcher),
    fork(getDuplicatedListWithGetListWatcher),
    fork(addNodeLabelWatcher),
    fork(saveNodeLabelWatcher),
    fork(updateOrderLabelWatcher),
    fork(setSelectedNodeLabelWatcher),
    fork(saveFormChangesLabelWatcher),
    fork(searchLabelWatcher),
    fork(deleteNodeLabelWatcher),
    fork(saveImageWatcher),
    fork(deleteImageWatcher)
  ]);
}
