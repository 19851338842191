import { createTypes } from 'redux-action-creator';

const types = createTypes(
  [
    'GET_IMAGE_LIST',
    'SET_IMAGE_LIST',
    'DELETE_IMAGE',
    'UPLOAD_IMAGE',
    'UPLOAD_IMAGE_SUCCESS',
    'IMAGE_POSITION_UPDATE',
    'IMAGE_ACTIVATE',
    'SET_COLLECTION_ID',
    'SET_NEW_IMAGE_URL',
    'IMAGE_UPLOAD_LOADER',
    'DISPLAY_SAVE_DELETE_BUTTON',
    'RESET_IMAGE'
  ],
  'ADS'
);

export default types;
