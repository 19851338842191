import { brandTypes } from './brandModal.constants';
import setdefaultDataSort from '../../../../../shared/utils/setdefaultDataSort';

const initialState = {
  list: [],
  id: '',
  isModalOpen: false,
  editForm: null,
  isEditingForm: false,
  loading: false,
  image: null,
  originalImage: null,
  imageBinary: null,
  collectionId: null,
  initialTitle: '',
  initialManufacturer: ''
};

export default function(state = initialState, action) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      if (action.payload.pathname.includes('/content-management/products')) {
        return initialState;
      } else {
        return state;
      }
    }

    case brandTypes.GET_LIST_SUCCESS: {
      let passedArray = action.payload.data;
      let sortedArary = setdefaultDataSort(passedArray, 'brand_name');
      return {
        ...state,
        list: sortedArary
      };
    }

    case brandTypes.UPDATE_MODAL_STATE: {
      const { state: modalState } = action.payload;
      if (modalState === 'open') {
        return {
          ...state,
          isModalOpen: true
        };
      } else {
        return {
          ...state,
          isModalOpen: false,
          isEditingForm: false,
          image: null,
          originalImage: null,
          imageBinary: null,
          id: '',
          collectionId: null,
          editForm: null
        };
      }
    }

    case brandTypes.EDIT_SUCCESS: {
      const { data, id, image } = action.payload;

      return {
        ...state,
        editForm: data,
        isEditingForm: true,
        id: id || '',
        image: image,
        originalImage: image
      };
    }

    case brandTypes.SAVE_DETAILS_SUCCESS: {
      const { id, data } = action.payload;
      const { list } = state;

      if (state.isEditingForm) {
        const itemIndex = list.findIndex(item => item._id === id);
        const newList = [
          ...list.slice(0, itemIndex),
          { ...data, _id: id },
          ...list.slice(itemIndex + 1)
        ];
        let passedArray = newList;
        let sortedArary = setdefaultDataSort(passedArray, 'brand_name');
        return {
          ...state,
          list: sortedArary
        };
      } else {
        const newItem = { _id: id, ...data };

        let passedArray = [newItem, ...list];
        let sortedArary = setdefaultDataSort(passedArray, 'brand_name');

        return {
          ...state,
          list: sortedArary
        };
      }
    }

    case brandTypes.SHOW_LOADER: {
      return {
        ...state,
        loading: true
      };
    }

    case brandTypes.HIDE_LOADER: {
      return {
        ...state,
        loading: false
      };
    }

    case brandTypes.SET_TEMP_IMG: {
      return {
        ...state,
        image: action.payload.img,
        imageBinary: action.payload.file
      };
    }

    case brandTypes.REMOVE_TEMP_IMG: {
      return {
        ...state,
        image: state.originalImage,
        imageBinary: null
      };
    }

    case brandTypes.SAVE_IMG_FAIL: {
      return {
        ...state,
        image: state.originalImage,
        imageBinary: null
      };
    }

    case brandTypes.SAVE_IMG_SUCCESS: {
      let newList = state.list;
      let editForm = state.editForm;

      // update list with new image url
      if (!action.payload.popupFrom && action.payload.item) {
        const item = action.payload.item;
        let list = state.list;

        const id = item._id;
        const itemIndex = list.findIndex(item => item._id === id);
        newList = [...list.slice(0, itemIndex), item, ...list.slice(itemIndex + 1)];
      } else if (action.payload.popupFrom && action.payload.item) {
        const form = action.payload.item;
        editForm = form;
      }
      return {
        ...state,
        image: action.payload.img,
        originalImage: action.payload.img,
        imageBinary: null,
        list: newList,
        editForm
      };
    }

    case brandTypes.REMOVE_IMG_SUCCESS: {
      if (action.payload.popupFrom) {
        return {
          ...state,
          editForm: {
            ...state.editForm,
            collectionId: ''
          },
          image: null,
          originalImage: null
        };
      }
      return {
        ...state,
        image: null,
        originalImage: null
      };
    }

    case brandTypes.SET_INITIAL_TITLE: {
      return {
        ...state,
        initialTitle: action.payload.title,
        initialManufacturer: action.payload.manufacturer
      };
    }

    case brandTypes.REMOVE_INITIAL_TITLE: {
      return {
        ...state,
        initialTitle: '',
        initialManufacturer: ''
      };
    }

    case brandTypes.SET_COLLECTION_ID: {
      return {
        ...state,
        collectionId: action.payload.id
      };
    }

    case brandTypes.RESET_COLLECTION_ID: {
      return {
        ...state,
        collectionId: null
      };
    }

    case brandTypes.RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
