import { actionCreator } from 'redux-action-creator';
import { manufacturerTypes } from './productModals.constants';

export const getManufacturerList = actionCreator(manufacturerTypes.GET_LIST);
export const getManufacturerListSuccess = actionCreator(manufacturerTypes.GET_LIST_SUCCESS, 'data');
export const getManufactureListWorkerSuccess = actionCreator(
  manufacturerTypes.GET_MANUFACTURE_WORKER_LIST_SUCCESS,
  'data'
);
export const updateManufacturerModal = actionCreator(manufacturerTypes.UPDATE_MODAL_STATE, 'state');
export const saveManufacturerDetails = actionCreator(
  manufacturerTypes.SAVE_DETAILS,
  'form',
  'popupFrom',
  'cb'
);
export const saveManufacturerDetailsSuccess = actionCreator(
  manufacturerTypes.SAVE_DETAILS_SUCCESS,
  'data',
  'id'
);
export const getManufacturerListFail = actionCreator(manufacturerTypes.GET_LIST_FAIL);
export const saveManufacturerDetailsFail = actionCreator(manufacturerTypes.SAVE_DETAILS_FAIL);
export const editManufacturer = actionCreator(manufacturerTypes.EDIT, 'id', 'popupFrom');
export const editManufacturerSuccess = actionCreator(
  manufacturerTypes.EDIT_SUCCESS,
  'data',
  'id',
  'image'
);
export const editManufacturerFail = actionCreator(manufacturerTypes.EDIT_FAIL);
export const showManufacturerLoader = actionCreator(manufacturerTypes.SHOW_LOADER);
export const hideManufacturerLoader = actionCreator(manufacturerTypes.HIDE_LOADER);
export const selectManufactImage = actionCreator(manufacturerTypes.SET_TEMP_IMG, 'img', 'file');
export const removeSelectedManufactImage = actionCreator(manufacturerTypes.REMOVE_TEMP_IMG);
export const saveManufactImage = actionCreator(manufacturerTypes.SAVE_IMG, 'popupFrom');
export const saveManufactImageSuccess = actionCreator(
  manufacturerTypes.SAVE_IMG_SUCCESS,
  'img',
  'item',
  'popupFrom'
);
export const saveManufactImageFail = actionCreator(manufacturerTypes.SAVE_IMG_FAIL);
export const removeManufactImage = actionCreator(manufacturerTypes.REMOVE_IMG, 'popupFrom');
export const removeManufactImageSuccess = actionCreator(
  manufacturerTypes.REMOVE_IMG_SUCCESS,
  'popupFrom'
);
export const removeManufactImageFail = actionCreator(manufacturerTypes.REMOVE_IMG_FAIL);
export const setInitialTitle = actionCreator(
  manufacturerTypes.SET_INITIAL_TITLE,
  'title',
  'manufacturer'
);
export const removeInitialTitle = actionCreator(manufacturerTypes.REMOVE_INITIAL_TITLE);
export const setCollectionId = actionCreator(manufacturerTypes.SET_COLLECTION_ID, 'id');
export const removeCollectionId = actionCreator(manufacturerTypes.RESET_COLLECTION_ID);
export const openCatalogueModal = actionCreator(manufacturerTypes.OPEN_CATALOGUE);
export const reset = actionCreator(manufacturerTypes.RESET);
