import {
  takeLatest,
  all,
  fork,
  put,
  call,
  select,
  cancelled,
  cancel,
  take,
  race
} from 'redux-saga/effects';
import { doRequest } from 'app/shared/redux/sagas/api';
import { message } from 'antd';
import adsTypes from './list.constants';
import {
  getDataListSuccess,
  getDataListFail,
  setDataListAdsImageSuccess,
  deleteAdsSuccess,
  getDataList,
  deleteAdsFail
} from './list.actions';

// pass intl for get translation message
function* getLocalizedMessage(key) {
  const intlSelector = state => state.intlReact.intl;
  const intl = yield select(intlSelector);
  return intl.formatMessage({ id: key });
}

// get sorted value and sort key
function sortTableRow(sort = {}) {
  if (sort.columnKey && sort.order) {
    if (sort.order == 'ascend') {
      sort.order = 'asc';
    }
    if (sort.order == 'descend') {
      sort.order = 'desc';
    }
  } else {
    sort.columnKey = 'week';
    sort.order = 'asc';
  }

  return sort;
}

// get data range in requested get offset and count
function calculatedRequstedDataList(pagination = {}) {
  let current = pagination.current || 1;
  let pageSize = pagination.pageSize || 5;

  let newDataValue = pageSize * (current - 1) + 1;

  let reqDataObj = {};

  if (current > 1) {
    reqDataObj.offset = newDataValue;
    reqDataObj.count = pageSize;
  } else {
    reqDataObj.offset = current;
    reqDataObj.count = pageSize;
  }

  return reqDataObj;
}

function* getFirstdataList({ payload }) {
  let getImageTask;

  const { pagination, sort } = payload;

  const sortData = yield call(sortTableRow, sort);

  const dataRange = yield call(calculatedRequstedDataList, pagination);

  try {
    const paramsObj = {
      offset: dataRange.offset,
      count: dataRange.count,
      sort_by: sortData.columnKey,
      order: sortData.order
    };
    let response = yield call(getRegularDataList, paramsObj);

    if (response.status === 200) {
      pagination.total = response.data.count;
      let dataList = response.data.data;

      if (dataList && dataList.length > 0) {
        // get country with flag classes
        dataList = yield call(getCountryFlagClassList, dataList);
      } else {
        dataList = [];
      }

      yield put(
        getDataListSuccess({
          dataList: dataList,
          pagination: pagination,
          sort: sort
        })
      );
      if (dataList && dataList.length > 0) {
        // get image of ads
        getImageTask = yield fork(getAdsImage, dataList);
      }
    }

    /** pause execution to find if the current task is cancelled */
    const { reset } = yield race({
      get: take(adsTypes.GET_DATA_LIST),
      reset: take(adsTypes.RESET)
    });

    if (reset) {
      if (getImageTask) {
        yield cancel(getImageTask);
      }
    }
  } catch (error) {
    console.log(error);

    yield put(getDataListFail());
    const errorMessage = yield call(
      getLocalizedMessage,
      'Generic.ApiMessages.AdManagement.GetListError'
    );
    message.error(errorMessage);
  } finally {
    if (yield cancelled()) {
      if (getImageTask) {
        yield cancel(getImageTask);
      }
    }
  }
}

// send api request to get ads data list
function* getRegularDataList(paramsObj) {
  const response = yield call(doRequest, {
    url: '/adsman/adsman',
    method: 'GET',
    params: paramsObj
  });

  return response;
}

// get flag country list   or get country name for the list
function* getCountryFlagClassList(firstDataList) {
  let newDataListArray = [];
  const countryList = yield select(s => s.indexedItem.countries);

  for (let index = 0; index < firstDataList.length; index++) {
    const item = firstDataList[index];

    if (!item.allCountries && item.country && countryList && countryList.length !== 0) {
      item.country_name =
        countryList[item.country] && countryList[item.country].name
          ? countryList[item.country].name
          : '';
      item.country_code =
        countryList[item.country] && countryList[item.country].code
          ? countryList[item.country].code
          : '';
    } else {
      item.country_name = '';
      item.country_code = '';
    }

    newDataListArray.push(item);
  }

  return newDataListArray;
}

// get ad management data table list id's
function* getIndexedAdsListIds() {
  const items = yield select(state => state.indexedItem.adManagement);

  return items;
}

// call to get table images from collection
function* getAdsImage(dataList = []) {
  const chunkedList = yield call(chunk, dataList, 5);

  for (let list of chunkedList) {
    const indexes = yield call(getIndexedAdsListIds);

    yield call(getAdsImageList, list, indexes);
  }
}

// get chank files
function chunk(array, size) {
  const chunked_arr = [];
  let index = 0;
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
}

// get ads images and indexes
function* getAdsImageList(list, indexes) {
  const requests = list.map(item => call(getAddsimage, item._id, item.media_collection));
  const responses = yield all(requests);
  const filtertedReponse = responses.filter(response => (response ? true : false));

  yield put(setDataListAdsImageSuccess({ image: filtertedReponse, indexes }));
}

//  get avatar image for ads
function* getAddsimage(id, collectionId) {
  if (!collectionId) return;
  try {
    const imageResponse = yield call(doRequest, {
      url: `/unimup/objctupload/ads/${collectionId}`,
      method: 'GET'
    });

    const { data } = imageResponse;

    if (data.pages.length) {
      const imageObj = data.pages.find(image => image.active === 1);

      if (imageObj) {
        const imageUrl = imageObj.thumbnail.fetchUrl;

        const imageResponse2 = yield call(doRequest, {
          url: `unimup/objctupload${imageUrl}`,
          method: 'GET',
          responseType: 'blob'
        });

        const { data: imageData } = imageResponse2;
        const image = URL.createObjectURL(imageData);
        return { id, image };
      }
    }
  } catch (error) {}
}

// delete ads row
function* deleteAdsRow({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(doRequest, {
      url: `/adsman/adsman/${id}`,
      method: 'DELETE'
    });
    if (response.status === 200) {
      const { sort, pagination } = yield select(state => state.adManagement.adManagementList);

      // reset offset
      pagination.current = 1;
      yield put(getDataList({ pagination: pagination, sort: sort }));
      yield put(deleteAdsSuccess());
    }
  } catch (error) {
    const errorMessage = yield call(
      getLocalizedMessage,
      'Generic.ApiMessages.AdManagement.AddDeleteError'
    );
    message.error(errorMessage);

    yield put(deleteAdsFail());
  }
}

/**
 * Watchers
 */

function* getFirstdataListSagaWatcher() {
  yield takeLatest(adsTypes.GET_DATA_LIST, getFirstdataList);
}

function* deleteAdsRowSagaWatcher() {
  yield takeLatest(adsTypes.DELETE_ADS, deleteAdsRow);
}

export default function* rootSaga() {
  yield all([fork(getFirstdataListSagaWatcher), fork(deleteAdsRowSagaWatcher)]);
}
