import manufacturerTypes from './catalogue_manufacturer.constants';
import { tag } from 'postcss-selector-parser';

const initialState = {
  dataSource: [],
  tableLoader: true,
  sort: { order: 'desc', columnKey: 'edit' },
  pagination: { total: 0, pageSize: 50, current: 1 },
  searchLoader: false,
  searchData: [],
  searchResults: [],
  tags: [],
  idList: []
};
export default (state = initialState, action) => {
  switch (action.type) {
    case manufacturerTypes.GET_DATA_LIST: {
      return {
        ...state,
        tableLoader: true
      };
    }

    case manufacturerTypes.GET_DATA_LIST_SUCCESS: {
      const { dataList, pagination, sort } = action.payload;

      return {
        ...state,
        dataSource: dataList.docs,
        pagination: { ...pagination },
        sort: { ...sort },
        tableLoader: false
      };
    }

    case manufacturerTypes.GET_DATA_LIST_FAIL: {
      return {
        ...state,
        tableLoader: false
      };
    }

    case manufacturerTypes.SET_MANUFACTURER_IMG: {
      const {
        payload: { image, indexes }
      } = action;
      let data = state.dataSource;
      for (let manufacImage of image) {
        const itemIndex = indexes[manufacImage.id];
        if (itemIndex || itemIndex === 0) {
          const item = data[itemIndex];
          const clonedItem = { ...item };
          if (clonedItem && clonedItem._id) {
            clonedItem.image = manufacImage.image;
            data = [...data.slice(0, itemIndex), clonedItem, ...data.slice(itemIndex + 1)];
          }
        }
      }

      return { ...state, dataSource: data };
    }

    case manufacturerTypes.SET_SEARCH_LOADER: {
      const {
        payload: { searchLoader }
      } = action;

      return {
        ...state,
        searchLoader: searchLoader
      };
    }
    case manufacturerTypes.GET_SEARCH_SUGGESTION_SUCCESS: {
      const {
        payload: { searchResults }
      } = action;

      return {
        ...state,
        searchResults: searchResults
      };
    }
    case manufacturerTypes.ADD_TAG_SUCCESS: {
      const {
        payload: { tags }
      } = action;

      return {
        ...state,
        tags: tags
      };
    }

    case manufacturerTypes.DELETE_TAG_SUCCESS: {
      const {
        payload: { tags }
      } = action;

      return {
        ...state,
        tags: tags
      };
    }

    case manufacturerTypes.SET_ID_LIST: {
      const {
        payload: { idList }
      } = action;

      return {
        ...state,
        idList: idList
      };
    }

    case manufacturerTypes.RESET: {
      return initialState;
    }

    default:
      return state;
  }
};
