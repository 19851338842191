import { actionCreator } from 'redux-action-creator';
import types from './e-numbers.constants';

export const getENumbersList = actionCreator(types.GET_LIST);
export const getENumbersListFail = actionCreator(types.GET_LIST_FAIL);
export const getENumbersListSuccess = actionCreator(types.GET_LIST_SUCCESS, 'data');
export const addNewNode = actionCreator(types.ADD_NODE);
export const addNewNodeSuccess = actionCreator(types.ADD_NODE_SUCCESS);
export const saveNewNode = actionCreator(types.SAVE_NODE, 'values', 'newNode', 'cb');
export const saveNewNodeSuccess = actionCreator(types.SAVE_NODE_SUCCESS, 'values', 'newNode');
export const saveNewNodeFail = actionCreator(types.SAVE_NODE_FAIL);
export const updateSortOrder = actionCreator(types.UPDATE_ORDER, 'values', 'oldIndex', 'newIndex');
export const updateSortOrderSuccess = actionCreator(types.UPDATE_ORDER_SUCCESS, 'values');
export const updateSortOrderFail = actionCreator(types.UPDATE_ORDER_FAIL);
export const setSelectedNode = actionCreator(types.SET_SELECTED_NODE, 'id', 'type');
export const setSelectedNodeSuccess = actionCreator(
  types.SET_SELECTED_NODE_SUCCESS,
  'id',
  'selectedData'
);
export const updateSaveButtonState = actionCreator(types.UPDATE_BUTTON_STATE, 'btnState');
export const saveChanges = actionCreator(types.SAVE_CHANGES);
export const saveChangesSuccess = actionCreator(types.SAVE_CHANGES_SUCCESS, 'form');
export const saveChangesFail = actionCreator(types.SAVE_CHANGES_FAIL);
export const searchLabel = actionCreator(types.SEARCH, 'key');
export const searchENumbersuccess = actionCreator(types.SEARCH_SUCCESS, 'data');
export const deleteNode = actionCreator(types.DELETE_NODE, 'id');
export const deleteNodeSuccess = actionCreator(types.DELETE_NODE_SUCCESS, 'id');
export const deleteNodeFail = actionCreator(types.DELETE_NODE_FAIL, 'id');
export const updateFormData = actionCreator(types.UPDATE_FORMDATA, 'key', 'formData');
export const updateSaveModal = actionCreator(types.UPDATE_SAVE_MODAL, 'state');
export const saveChangesAndSwitch = actionCreator(types.SAVE_CHANGES_AND_SWITCH);
export const cancelChangesAndSwitch = actionCreator(types.CANCEL_CHANGES_AND_SWITCH);
export const cancelChangesAndStay = actionCreator(types.CANCEL_CHANGES_AND_STAY);
export const resetENumbersState = actionCreator(types.RESET_STATE);
export const cuntrySelectedListUpdate = actionCreator(types.COUNTRY_SELECTED_LIST, 'keys');
export const countryAllSelectDisabled = actionCreator(types.DISABLED_WITH_ALL_COUNTRY, 'disabled');
export const selectENumbersImage = actionCreator(types.SET_TEMP_IMG, 'img', 'file');
export const removeSelectedENumbersImage = actionCreator(types.REMOVE_TEMP_IMG);
export const saveENumbersImage = actionCreator(types.SAVE_IMG);
export const saveENumbersImageSuccess = actionCreator(types.SAVE_IMG_SUCCESS, 'img', 'item');
export const saveENumbersImageFail = actionCreator(types.SAVE_IMG_FAIL);
export const removeENumbersImage = actionCreator(types.REMOVE_IMG);
export const removeENumbersImageSuccess = actionCreator(types.REMOVE_IMG_SUCCESS);
export const removeENumbersImageFail = actionCreator(types.REMOVE_IMG_FAIL);
export const showENumbersLoader = actionCreator(types.SHOW_LOADER);
export const hideENumbersImgLoader = actionCreator(types.HIDE_LOADER);
export const getENumbersImage = actionCreator(types.GET_IMG);
export const getENumbersImageSuccess = actionCreator(types.GET_IMG_SUCCESS, 'imagedata');
