export const MODERATION_GET_IMAGE_LIST = 'MODERATION_GET_IMAGE_LIST';
export const MODERATION_SET_IMAGE_LIST = 'MODERATION_SET_IMAGE_LIST';
export const MODERATION_DELETE_IMAGE = 'MODERATION_DELETE_IMAGE';
export const MODERATION_UPLOAD_IMAGE = 'MODERATION_UPLOAD_IMAGE';
export const MODERATION_UPLOAD_IMAGE_SUCCESS = 'MODERATION_UPLOAD_IMAGE_SUCCESS';
export const MODERATION_IMAGE_POSITION_UPDATE = 'MODERATION_IMAGE_POSITION_UPDATE';
export const MODERATION_IMAGE_ACTIVATE = 'MODERATION_IMAGE_ACTIVATE';
export const MODERATION_SET_COLLECTION_ID = 'MODERATION_SET_COLLECTION_ID';
export const MODERATION_SET_NEW_IMAGE_URL = 'MODERATION_SET_NEW_IMAGE_URL';
export const MODERATION_IMAGE_UPLOAD_LOADER = 'MODERATION_IMAGE_UPLOAD_LOADER';
export const MODERATION_DISPLAY_SAVE_DELETE_BUTTON = 'MODERATION_DISPLAY_SAVE_DELETE_BUTTON';
export const RESET_IMAGES = 'RESET_IMAGES';
export const MODERATION_CHANGE_MOUNT_STATE = 'MODERATION_CHANGE_MOUNT_STATE';
// export const MODERATION_SET_COLLECTION_DATA = 'MODERATION_SET_COLLECTION_DATA';
