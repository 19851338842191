import types from './nutrition-values.constants';

const initialState = {
  comparison: null,
  nutritionalValue: null,
  vitamins: null,
  minerals: null,
  other: null,
  commonSupplements: null,
  energy: null,
  originalData: {
    comparison: [],
    nutritionalValue: [],
    vitamins: [],
    minerals: [],
    other: [],
    commonSupplements: []
  },
  selectedNode: '',
  selectedNodeData: {},
  selectedNodeType: '',
  saveButtonState: 'default',
  formData: null,
  isSaveModalOpen: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        ...data,
        originalData: {
          ...data
        }
      };
    }

    case types.ADD_NODE_SUCCESS: {
      const { key } = action.payload;
      const tempNode = { value: '@@/newNode', id: `${new Date().getTime()}` };

      const values = state[key] || [];
      //check if a new node is there to prevent adding multiple new nodes
      if (values.length && values[0]['value'] === '@@/newNode') {
        return state;
      }

      return {
        ...state,
        [key]: [tempNode, ...values],
        selectedNode: '',
        selectedNodeData: null,
        selectedNodeType: null,
        saveButtonState: 'default',
        formData: null
      };
    }

    case types.SAVE_NODE_SUCCESS: {
      const { key, values, newNode } = action.payload;

      return {
        ...state,
        [key]: [...values],
        originalData: {
          ...state.originalData,
          [key]: newNode ? [newNode, ...state.originalData[key]] : [...state.originalData[key]]
        }
      };
    }

    case types.UPDATE_ORDER_SUCCESS: {
      const { key, values } = action.payload;

      return {
        ...state,
        [key]: values,
        originalData: {
          ...state.originalData,
          [key]: values
        }
      };
    }

    case types.SET_SELECTED_NODE_SUCCESS: {
      const { id, type, selectedData } = action.payload;

      return {
        ...state,
        selectedNode: id,
        selectedNodeData: selectedData,
        selectedNodeType: type,
        saveButtonState: 'default',
        formData: null,
        isSaveModalOpen: false
      };
    }

    case types.UPDATE_BUTTON_STATE: {
      const { btnState } = action.payload;
      return {
        ...state,
        saveButtonState: btnState
      };
    }

    case types.SAVE_CHANGES_SUCCESS: {
      const { form } = action.payload;
      return {
        ...state,
        saveButtonState: 'saved-changes',
        selectedNodeData: {
          ...state.selectedNodeData,
          ...form
        }
      };
    }

    case types.SEARCH_SUCCESS: {
      const { data } = action.payload;
      if (data === null) {
        return {
          ...state,
          ...state.originalData
        };
      }
      return {
        ...state,
        ...data
      };
    }

    case types.DELETE_NODE_SUCCESS: {
      const { type, id } = action.payload;

      const items = state[type];
      const originalData = state.originalData[type];

      const deletedItemIndex = items.findIndex(i => i.id === id);

      const deletedItemOriginalIndex = originalData.findIndex(i => i.id === id);

      const newItems = [...items.slice(0, deletedItemIndex), ...items.slice(deletedItemIndex + 1)];

      const newItemsOriginalData = [
        ...originalData.slice(0, deletedItemOriginalIndex),
        ...originalData.slice(deletedItemOriginalIndex + 1)
      ];

      const newState = {
        ...state,
        [type]: newItems,
        originalData: {
          ...state.originalData,
          [type]: newItemsOriginalData
        }
      };

      if (id === state.selectedNode) {
        newState['selectedNode'] = '';
        newState['selectedNodeData'] = null;
        newState['selectedNodeType'] = null;
        newState['saveButtonState'] = 'default';
        newState['formData'] = null;
      }

      return newState;
    }

    case types.UPDATE_FORMDATA: {
      const { formData, key } = action.payload;

      const data = {};
      data[key] = formData;

      if (state.formData === null) {
        return {
          ...state,
          formData: {
            ...data
          }
        };
      }
      // else if (formData === null) {
      //   return {
      //     ...state,
      //     formData: null
      //   };
      // }

      return {
        ...state,
        formData: {
          ...state.formData,
          ...data
        }
      };
    }
    case types.UPDATE_SAVE_MODAL: {
      const { state: modalState } = action.payload;
      const isOpen = modalState === 'show' ? true : false;

      return {
        ...state,
        isSaveModalOpen: isOpen
      };
    }

    case types.RESET_NUTRIENTS_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
