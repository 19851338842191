import distributorTypes from '../../../module/contentManagement/catalogue/distributor/redux/catalogue_distributor.constants';

/**
 * @description
 *   Reducer to index the distributor catalogue items to efficiently
 *  retrieve brand item and set image of the brand in catalogue_distributor_reducer.js
 */
export default (state = {}, { type, payload }) => {
  switch (type) {
    case distributorTypes.GET_DATA_LIST_SUCCESS: {
      const { dataList } = payload;
    

      const index = {};
      if (dataList.docs.length) {
        for (let i = 0; i < dataList.docs.length; i++) {
          index[dataList.docs[i]._id] = i;
        }
      }

      return index;
    }

    default:
      return state;
  }
};
