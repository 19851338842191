import * as categoriesActions from './categories.constants';

export const getCategories = () => ({ type: categoriesActions.GET_CATEGORIES });

export const getCategoriesSuccess = payload => ({
  type: categoriesActions.GET_CATEGORIES_SUCCESS,
  payload
});

export const getDefualtLocalSuccsess = payload => ({
  type: categoriesActions.GET_DEFUALT_LOCAL_SUCCESS,
  payload
});

export const addCategory = (clickedValue, dataOld, randomNumb) => ({
  type: categoriesActions.ADD_CATEGORY,
  payload: { clickedValue, dataOld, randomNumb }
});

export const plusButtonClickIdentify = payload => ({
  type: categoriesActions.PLUS_BTN_CLICK,
  payload
});

export const addCategorySuccess = payload => ({
  type: categoriesActions.ADD_CATEGORY_SUCCESS,
  payload
});

export const deleteCategory = (clickedValue, dataOld) => ({
  type: categoriesActions.DELETE_CATEGORY,
  payload: { clickedValue, dataOld }
});

export const deleteCategorySuccess = payload => ({
  type: categoriesActions.DELETE_CATEGORY_SUCCESS,
  payload
});

export const handleBlurCategory = (dataOld, changedValue, changedId, defaultLocal, originData) => ({
  type: categoriesActions.HANDLE_BLUR_CATEGORY,
  payload: { dataOld, changedValue, changedId, defaultLocal, originData }
});

export const updateCategoryOrder = (newData, updatedKeys) => ({
  type: categoriesActions.UPDATE_CATEGORIES_ORDER,
  payload: { data: newData, updatedKeys }
});

export const updateCategoryOrderSuccess = newData => ({
  type: categoriesActions.UPDATE_CATEGORIES_ORDER_SUCCESS,
  payload: { data: newData }
});

export const handleBlurCategorySuccess = payload => ({
  type: categoriesActions.HANDLE_BLUR_CATEGORY_SUCCESS,
  payload
});

export const addnodebtnDisabled = payload => ({
  type: categoriesActions.ADD_NODE_BTN_DISABLED,
  payload
});

export const setSelectedCategory = payload => ({
  type: categoriesActions.SET_SELECTED_CATEGORY,
  payload
});

export const getCategoryLanguages = payload => ({
  type: categoriesActions.GET_CATEGORY_LANGUAGES,
  payload
});

export const getCategoryLanguagesSuccess = payload => ({
  type: categoriesActions.GET_CATEGORY_LANGUAGES_SUCCESS,
  payload
});

export const identifyButtonColor = payload => ({
  type: categoriesActions.IDENTIFY_BTN_RED,
  payload
});

export const hideButtonColor = () => ({
  type: categoriesActions.IDENTIFY_BTN_WITHOUT_RED
});

export const showAlertMessage = () => ({
  type: categoriesActions.SHOW_ALERT_WINDOW
});

export const hideAlertMessage = () => ({
  type: categoriesActions.HIDE_ALERT_WINDOW
});

export const treeSelectedKey = payload => ({
  type: categoriesActions.TREE_SELECTED_KEY,
  payload
});

export const nextTreeSelectedkey = payload => ({
  type: categoriesActions.NEXT_TREE_SELECTED_KEY,
  payload
});

export const savecategoryTreeKey = () => ({
  type: categoriesActions.SAVE_TREE_CATEGORY_VALUE
});

export const savecategoryTreeKeySuccess = () => ({
  type: categoriesActions.SAVE_TREE_CATEGORY_VALUE_SUCCESS
});

export const cancelCategoryTreeKey = () => ({
  type: categoriesActions.CANCEL_TREE_CATEGORY_VALUE
});

export const cancelCategoryTreeKeySuccess = () => ({
  type: categoriesActions.CANCEL_TREE_CATEGORY_VALUE_SUCCESS
});

export const resetCategorySelected = () => ({
  type: categoriesActions.RESET_CATEGORY_VALUES
});

export const updateCategoryFormValue = (values, successCb = null, errorCb = null) => ({
  type: categoriesActions.UPDATE_CATEGORY_FORM_DETAILS,
  payload: { values, successCb, errorCb }
});

export const updateICategorySaveLoader = payload => ({
  type: categoriesActions.UPDATE_SAVE_CATEGORY_LOADER,
  payload: payload
});

export const identifyDeleteSuccess = payload => ({
  type: categoriesActions.DELETE_NODE_IDENTIFY,
  payload
});

export const identifyGetCategoryData = loader => ({
  type: categoriesActions.IDENTIFY_GET_CATEGORY_DATA_LOADER,
  payload: loader
});

export const saveCatLastGeneratedRandomNumber = randomNumb => ({
  type: categoriesActions.SET_LAST_GENERATED_CATEGORY_RANDOM_NUMBER,
  payload: randomNumb
});

export const reUpdateCategorySaveDetails = payload => ({
  type: categoriesActions.RE_UPDATE_SAVE_CATEGORY_DETAILS,
  payload: payload
});

export const setUpdateCategoryTree = payload => ({
  type: categoriesActions.UPDATE_CATEGORY__TREE,
  payload: payload
});
export const setSearchString = payload => ({
  type: categoriesActions.SET_SEARCH_STRING,
  payload
});
export const addExpandKey = payload => ({
  type: categoriesActions.ADD_EXPAND_KEY,
  payload
});
export const removeExpandKey = payload => ({
  type: categoriesActions.REMOVE_EXPAND_KEY,
  payload
});

export const setExpandKey = payload => ({
  type: categoriesActions.SET_EXPAND_KEY,
  payload
});

export const removeExpandKeySuceess = payload => ({
  type: categoriesActions.REMOVE_EXPAND_KEY_SUCCESS,
  payload
});

export const setEnableDrag = payload => ({
  type: categoriesActions.SET_DRAG_ENABLE,
  payload
});

export const searchCategory = payload => ({
  type: categoriesActions.CAT_SEARCH,
  payload
});

export const categorySpinLoaderShow = payload => ({
  type: categoriesActions.CAT_SPIN_LOAD,
  payload
});

export const setCategoryTitleTranslationKey = payload => ({
  type: categoriesActions.SET_CATEGORY_TITLE_TRANSLATION,
  payload
});

export const serchWithDeleteIdentify = payload => ({
  type: categoriesActions.SEARCH_WITH_DELETE_CATEGORY,
  payload
});

export const resetCategoryToInitialState = () => ({
  type: categoriesActions.RESET_TO_INITIAL_STATE
});

export const updateCategoryListProduct = (payload) => ({
  type: categoriesActions.UPDATE_CATEGORY_LIST_DETAILS,
  payload
});
