import { createTypes, async } from 'redux-action-creator';

export const tagsTypes = createTypes(
  [
    'UPDATE_MODAL_STATE',
    ...async('SAVE_DETAILS'),
    ...async('EDIT'),
    'SHOW_LOADER',
    'HIDE_LOADER',
    'SET_TEMP_IMG',
    'REMOVE_TEMP_IMG',
    ...async('SAVE_IMG'),
    ...async('REMOVE_IMG'),
    'SET_INITIAL_TITLE',
    'REMOVE_INITIAL_TITLE',
    'SET_COLLECTION_ID',
    'OPEN_CATALOGUE',
    'RESET',
    'ADD_TAG_MANUALLY',
    'DETECT_FORM_CHANGES',
    'GET_EDITED_OBJ',
    'UPDATE_ALTERNATIVE_LIST',
    'DUPLICATE_TAG_RESPONSE'
  ],
  'TAGS_MODAL'
);
