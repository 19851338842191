import { createTypes } from 'redux-action-creator';

const types = createTypes(
  [
    'SAVE_CHANGES',
    'GET_CATEGORY_LIST',
    'GET_CATEGORY_LIST_SUCCESS',
    'GET_INGREDIENT_LIST',
    'GET_INGREDIENT_LIST_SUCCESS',
    'GET_STORE_LIST',
    'GET_STORE_LIST_SUCCESS',
    'GET_STORE_TYPE_LIST',
    'GET_STORE_TYPE_LIST_SUCCESS',
    'GET_TAG_LIST',
    'GET_TAG_LIST_SUCCESS',
    'SET_TAG_IMAGE',
    'GET_ENUMBER_LIST',
    'GET_ENUMBER_LIST_SUCCESS',
    'GET_PRODUCT_LIST',
    'GET_PRODUCT_LIST_SUCCESS',
    'GET_PRODUCT_BY_LOCALE',
    'SET_PRODUCT_STORE',
    'GET_PRODUCT_BY_LOCALE_SUCCESS',
    'GET_PRODUCT_LIST_SUCCESS',
    'GET_LIST_NUTRITION_VALUES',
    'GET_NUTRITION_VALUE_LIST_SUCCESS',
    'GET_MAY_CONTAIN_VALUES_SUCCESS',
    'GET_LABEL_LIST',
    'SET_FILTER_LABEL_LIST',
    'GET_LABEL_LIST_SUCCESS',
    'UPDATE_NUTRITION_LOADED_STATUS',
    'ADD_PRODUCT',
    'EDIT_PRODUCT',
    'DELETE_PRODUCT',
    'DELETE_PRODUCT_SUCCESS',
    'UPDATE_CATEGORY_LOADER',
    'UPDATE_INGREDIENT_LOADER',
    'UPDATE_SAVE_BUTTON_STATUS',
    'UPDATE_PRODUCT_DETAIL_LOAD_STATUS',
    'UPDATE_PRODUCT_LOCALE',
    'MAY_CONTAINT_LOADER',
    'LABEL_LIST_LOADER',
    'SET_STORE_TYPE_IMAGE',
    'RESET',
    'GET_DEFAULT_UNIT',
    'SAVE_NEW_TAG_SUCCESS',
    'EDIT_TAG_SUCCESS',
    'ADD_NEW_TAG',
    'NEW_ADDED_TAG_IMAGE_GET',
    'REMOVE_TAG_IMAGE_FROM_PROD',
    'ADD_NEW_INGREDIENT',
    'EDIT_INGREDIENT',
    'SET_STORE_IMAGE',
    'GET_STORE_UNIC_PRODUCT_PRICE',
    'GET_STORE_UNIC_PRODUCT_PRICE_SUCCESS',
    'CURRANCY_OBJ_UPDATE',
    'GET_BRACKET_PRICE',
    'GET_BRACKET_PRICE_SUCCESS',
    'GET_PRODUCT_ID',
    'DISABLED_PRICE_INPUT',
    'CHANGE_PRODUCT_ADD_PAGE_MOUNT',
    'UPDATE_PRODUCT_DATA_INGREDIENT_ONLY',
    'GET_CATEGORY_WORKER_SUCCESS',
    'GET_INGREDIENT_WORKER_SUCCESS',
    'GET_ENUMBERS_WORKER_SUCCESS',
    'GET_MAY_CONTAIN_WORKER_SUCCESS',
    'GET_TAGS_WORKER_SUCCESS',
    'GET_NUTRITION_WORKER_SUCCESS',
    'GET_LABELS_WORKER_SUCCESS',
    'IDENTIFY_SET_FIELD_VALUE',
    'RESET_BRACKET_PRICE',
    'STORE_DATA_ENABLED',
    'TAG_LIST_LOADER'
  ],
  'PRODUCT'
);

export default types;
