import { actionCreator } from 'redux-action-creator';
import textEditorType from './textEditorPopup.constants';

export const modealOpenCloseState = actionCreator(
  textEditorType.MODAL_OPEN_CLOSE_STATE,
  'modalState',
  'ingArray',
  'eNumArray',
  'eNumArrayIds',
  'form',
  'editorType'
);

export const setOriginalIngValue = actionCreator(textEditorType.ORIGINAL_ING_VALUE, 'data');
export const spinningLoader = actionCreator(textEditorType.SPIN_LOADER, 'loader');
export const setEditorBtnDisable = actionCreator(textEditorType.EDITOR_BTN_DISABLE, 'disabled');
export const setOriginalString = actionCreator(textEditorType.SET_ORIGINAL_STRING, 'data');
export const setManipulateString = actionCreator(
  textEditorType.SET_MANIPULATE_STRING,
  'data'
  //'cb'
);
export const setIngredientArray = actionCreator(textEditorType.SET_INGREDIENT_ARRAY, 'data');
export const getIngredientArrayWorkerSuccess = actionCreator(
  textEditorType.GET_INGREDIENT_ARRAY_WORKER_SUCCESS,
  'data'
);
export const setENumbersArray = actionCreator(textEditorType.SET_ENUMBERS_ARRAY, 'data');
export const getEnumberArrayWorkerSuccess = actionCreator(
  textEditorType.GET_ENUMBER_ARRAY_WORKER_SUCCESS,
  'data'
);
export const isFilterOk = actionCreator(textEditorType.IS_FILTER_OK, 'data');
export const setTextEditorModifyData = actionCreator(textEditorType.SET_TEXT_EDITOR_DATA, 'data');
export const setManipulateStringSuccess = actionCreator(
  textEditorType.SET_MANIPULATE_STRING_SUCCESS,
  'data'
);
export const reset = actionCreator(textEditorType.RESET);
export const setEditAddSelectedValueNode = actionCreator(
  textEditorType.SET_EDIT_ADD_SELECTED_VALUE_NODE,
  'data'
);

export const addNewIngredientToArray = actionCreator(
  textEditorType.ADD_NEW_INGREDIENT_TO_ARRAY,
  'data'
);

export const updateIngredientToArray = actionCreator(
  textEditorType.UPDATE_INGREDIENT_TO_ARRAY,
  'data'
);
export const setLeftIngredients = actionCreator(textEditorType.Set_LEFT_INGREDIENTS, 'data');
