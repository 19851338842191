import {
  all,
  fork,
  put,
  takeLatest,
  takeEvery,
  select,
  call,
  race,
  take,
  cancelled
} from 'redux-saga/effects';
import arrayMove from 'array-move';
import { delay } from 'redux-saga';

import types from './e-numbers.constants';
import {
  getENumbersListSuccess,
  updateSaveButtonState,
  saveChangesFail,
  saveChangesSuccess,
  addNewNodeSuccess,
  saveNewNodeSuccess,
  updateSortOrderSuccess,
  searchENumbersuccess,
  deleteNodeSuccess,
  updateSaveModal,
  saveChanges,
  getENumbersList,
  setSelectedNodeSuccess,
  saveENumbersImageSuccess,
  removeENumbersImageSuccess,
  showENumbersLoader,
  saveENumbersImageFail,
  hideENumbersImgLoader,
  getENumbersImageSuccess
} from './e-numbers.actions';

import { displaySaveDeleteBtn } from '../../../../shared/redux/actions/imageUploader.actions';

import { message } from 'antd';
import { doRequest } from '../../../../shared/redux/sagas/api';
import ListTypeEnums from '../../nutrition-values/enums/list-types';

function* getIntlMessage(id) {
  const intl = yield select(({ intlReact: { intl } }) => intl);
  return intl.formatMessage({ id });
}

function* getUserLocale() {
  const [id, languageList] = yield select(s => [s.profile.language, s.languageList.languageList]);
  const language = languageList.find(language => language.id === id);
  return language.locale;
}

function* getList() {
  let intl = yield select(state => state.intlReact.intl);
  try {
    const locale = yield call(getUserLocale);

    const { data } = yield call(doRequest, {
      method: 'GET',
      url: 'prodprop/enums/'
    });
    const stateData = yield call(buildListData, locale, data);

    if (Object.values(stateData).length === 0) {
      stateData[ListTypeEnums.COMPARISON] = [];
    }

    yield put(
      getENumbersListSuccess({
        data: stateData
      })
    );
  } catch (error) {
    console.log({ error });
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.ENumbers.GetListError'
      })
    );
  }
}

function buildListData(userLocale, data) {
  const stateData = {};

  const typeSpecificValues = getNodeValues(userLocale, data);

  stateData['comparison'] = typeSpecificValues;

  return stateData;
}

function getNodeValues(userLocale, items) {
  return items.map(item => {
    const node = {};
    node.id = item._id;
    node.position = item.position;
    node.value = getNodeLanguageSpecificName(userLocale, item.translations, item);
    node.isVegan = item.isVegan;

    return node;
  });
}

function getNodeLanguageSpecificName(userLocale, languages, item) {
  const { eNumber } = item;
  if (languages) {
    if (languages[userLocale] && languages[userLocale]['name']) {
      return eNumber + ' - ' + languages[userLocale]['name'];
    }

    if (languages['en_US'] && languages['en_US']['name']) {
      return eNumber + ' - ' + languages['en_US']['name'];
    }

    for (let locale in languages) {
      if (languages[locale]['name']) {
        return eNumber + ' - ' + languages[locale]['name'];
      }
    }
  }

  return eNumber;
}

function* addNode({ payload }) {
  const shouldProceed = yield call(handleChangesBeforeSwitching);

  if (!shouldProceed) return;

  yield put(addNewNodeSuccess());
}

function* saveNode(node) {
  const { newNode, values } = node;
  let intl = yield select(state => state.intlReact.intl);

  let arrValues = values.slice();
  arrValues.shift();

  // remove the temp node and set the values if new node value is empty
  if (!newNode) {
    return yield put(saveNewNodeSuccess({ values: arrValues }));
  }

  let formData = {};

  formData = {
    translations: {},
    isVegan: false,
    notHealthy: false,
    eNumber: newNode,
    insNumber: '',
    uNumber: '',
    aNumber: '',
    function: '',
    copyrightText: '',
    imageSrcUrl: '',
    collectionId: ''
  };

  try {
    const response = yield call(doRequest, {
      url: 'prodprop/enums',
      method: 'POST',
      data: formData
    });

    const responseData = response.data;
    const newNodeItem = {
      value: newNode,
      id: responseData['_id'],
      position: responseData.position
    };
    arrValues = [newNodeItem, ...arrValues];

    yield put(saveNewNodeSuccess({ values: arrValues, newNode: newNodeItem }));
    yield call(setSelectedNode, newNodeItem.id);
  } catch (error) {
    if (
      error.response &&
      error.response.status === 400 &&
      error.response.data.message.includes('existing')
    ) {
      message.error(
        intl.formatMessage({
          id: 'Generic.ApiMessages.ENumbers.ENumbersExists'
        })
      );
    } else {
      message.error(
        intl.formatMessage({
          id: 'Generic.ApiMessages.ENumbers.NodeSaveError'
        })
      );
    }
    //revert to original values
    return yield put(saveNewNodeSuccess({ values: arrValues }));
  }
}

function* updateOrder({ payload: { values, oldIndex, newIndex } }) {
  let intl = yield select(state => state.intlReact.intl);
  const updatedValues = arrayMove(values, oldIndex, newIndex);
  const id = values[oldIndex]['id'];
  const newPosition = values[newIndex]['position'];
  // update the values to update ui element positions immediately
  yield put(updateSortOrderSuccess({ values: updatedValues }));

  try {
    const response = yield call(doRequest, {
      method: 'PUT',
      url: `prodprop/enums/dragndrop/${encodeURIComponent(id)}`,
      data: {
        new_pos: newPosition
      }
    });
    message.info(
      intl.formatMessage({
        id: 'Generic.ApiMessages.ENumbers.NodeOrderChangedSuccess'
      })
    );
    yield put(getENumbersList());
  } catch (error) {
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.ENumbers.NodeOrderChangedError'
      })
    );
    yield put(updateSortOrderSuccess({ values: values }));
  }
}

// get selected nod data *****************************
function* setSelectedNode(id) {
  yield put(displaySaveDeleteBtn(true));

  let intl = yield select(state => state.intlReact.intl);
  const btnState = yield select(s => s.eNumbers.saveButtonState);
  if (btnState === 'saving-changes') {
    message.warn(
      intl.formatMessage({
        id: 'Generic.ApiMessages.ENumbers.NodeSelectedError'
      })
    );
    return;
  }

  const shouldProceed = yield call(handleChangesBeforeSwitching);

  if (!shouldProceed) return;

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: `prodprop/enums/${encodeURIComponent(id)}`
    });

    const selectedData = response.data || {};

    const getImageId = selectedData.collectionId;

    yield put(setSelectedNodeSuccess({ id, selectedData }));
    yield put(getENumbersImageSuccess({ imagedata: [] }));
    yield put(showENumbersLoader());
    if (getImageId) {
      yield call(getENumbersImageRequest, getImageId);
    } else {
      yield put(hideENumbersImgLoader());
    }
  } catch (error) {
    console.log({ error });
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.ENumbers.GetNodeDataError'
      })
    );
  }
}
// get selected nod data *****************************/////////////

/**
 * when to handle
 *  1 - When switching nodes
 *  2 - When user creates a node while has changes
 */
function* handleChangesBeforeSwitching() {
  const btnState = yield select(s => s.eNumbers.saveButtonState);

  if (!(yield call(shouldHandle, btnState))) {
    return true;
  }

  yield put(updateSaveModal({ state: 'show' }));

  const action = yield race({
    saveChanges: take(types.SAVE_CHANGES_AND_SWITCH),
    cancelAndSwitch: take(types.CANCEL_CHANGES_AND_SWITCH),
    cancelAndStay: take(types.CANCEL_CHANGES_AND_STAY)
  });

  if (action.saveChanges) {
    yield put(saveChanges());

    const { saveFailed } = yield race({
      saveSuccess: take(types.SAVE_CHANGES_SUCCESS),
      saveFailed: take(types.SAVE_CHANGES_FAIL)
    });

    if (saveFailed) {
      yield put(updateSaveModal({ state: 'hide' }));
      return false;
    }
  } else if (action.cancelAndStay) {
    yield put(updateSaveModal({ state: 'hide' }));
    return false;
  }

  yield put(updateSaveModal({ state: 'hide' }));
  return true;
}

function shouldHandle(btnState) {
  const btnStateToHandle = ['unsaved', 'unsaved-errors'];
  return btnStateToHandle.includes(btnState);
}

// post data******************************************
function* saveFormChanges() {
  let intl = yield select(state => state.intlReact.intl);
  const formPassed = yield call(getFormData);
  const [id, nodeData] = yield select(s => [s.eNumbers.selectedNode, s.eNumbers.selectedNodeData]);

  const translations = {
    ...formPassed.translations
  };

  for (let [key, value] of Object.entries(nodeData.translations)) {
    if (
      !(key in translations) ||
      (key in translations && !Object.values(translations[key]).length)
    ) {
      translations[key] = value;
    }
  }

  formPassed.translations = translations;

  try {
    yield put(updateSaveButtonState({ btnState: 'saving-changes' }));

    yield call(doRequest, {
      url: `prodprop/enums/${id}`,
      method: 'PUT',
      data: formPassed
    });

    yield put(getENumbersList());
    yield take(types.GET_LIST_SUCCESS);

    const form = JSON.parse(JSON.stringify(formPassed));

    yield put(saveChangesSuccess({ form }));
  } catch (error) {
    if (
      error.response &&
      error.response.status === 400 &&
      error.response.data.message.includes('exsiting')
    ) {
      message.error(
        intl.formatMessage({
          id: 'Generic.ApiMessages.ENumbers.ENumbersExists'
        })
      );
    } else {
      message.error(
        intl.formatMessage({
          id: 'Generic.ApiMessages.ENumbers.FormSaveError'
        })
      );
    }
    yield put(saveChangesFail());
    yield put(updateSaveButtonState({ btnState: 'unsaved' }));
  }
}

function* getFormData() {
  const [formData] = yield select(s => [s.eNumbers.formData]);
  const collectId = yield select(s => s.eNumbers.collectionId);
  const defaultLocale = yield call(getUserLocale);
  const formattedFormData = yield call(formatData, formData, defaultLocale, collectId);

  const dataToSend = {
    ...formattedFormData
  };

  return dataToSend;
}

function formatData(formData, defaultLocale = 'en_US', collectionId = '') {
  let eNumbersObj = {};
  const data = {};

  for (let key in formData) {
    const langData = formData[key];

    const newLangData = {};

    for (let name in langData) {
      if (typeof langData[name]['value'] !== 'undefined') {
        let keyName = name;
        if (name.startsWith(key)) {
          keyName = name.slice(key.length + 1);
        }
        newLangData[keyName] = langData[name]['value'];
      }
    }
    data[key] = newLangData;
  }

  const {
    isVegan,
    notHealthy,
    eNumber,
    insNumber,
    uNumber,
    aNumber,
    copyrightText,
    imageSrcUrl,
    function: fn,
    ...translations
  } = data[defaultLocale];

  data[defaultLocale] = translations;

  const basicProperties = {
    isVegan,
    notHealthy,
    eNumber,
    insNumber,
    uNumber,
    aNumber,
    copyrightText,
    imageSrcUrl,
    collectionId,
    function: fn
  };

  /** transform links to array */
  for (let [locale, value] of Object.entries(data)) {
    const links = Object.entries(value)
      .map(([key, value]) => (key.includes('link') ? value : null))
      .filter(item => item !== null);

    if (links.length) {
      data[locale].links = links;
    }

    for (let key in data[locale]) {
      if (key.includes('links_')) {
        delete data[locale][key];
      }
    }
  }

  eNumbersObj = {
    ...basicProperties,
    translations: data
  };

  return eNumbersObj;
}

function* search(action) {
  const key = action.payload.key.toLowerCase();

  if (!key) {
    return yield put(searchENumbersuccess({ data: null }));
  }

  const [comparison] = yield select(({ eNumbers: { originalData: _ } }) => [_.comparison]);

  const [comparisonResult] = yield all([call(individualSearch, `${key}`, comparison)]);

  yield put(
    searchENumbersuccess({
      data: {
        comparison: comparisonResult
      }
    })
  );
}

function individualSearch(key, items) {
  if (!items) return [];
  return items.filter(item => {
    let value = item.value;

    if (!value) {
      return false;
    }

    value = 'e' + value.toLowerCase();

    if (value.includes(key)) {
      return true;
    }

    return false;
  });
}

function* deleteNode(action) {
  const { id } = action.payload;
  let intl = yield select(state => state.intlReact.intl);

  try {
    const response = yield call(doRequest, {
      url: `prodprop/enums/${encodeURIComponent(id)}`,
      method: 'DELETE'
    });
    yield put(deleteNodeSuccess({ id }));
  } catch (error) {
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.ENumbers.NodeDeleteError'
      })
    );
  }
}

function* saveImage() {
  yield put(showENumbersLoader());

  try {
    const id = yield select(state => state.eNumbers.selectedNode);
    const image = yield select(state => state.eNumbers.image);
    const imageBinary = yield select(state => state.eNumbers.imageBinary);
    const collectionId = yield select(state => state.eNumbers.collectionId);

    // if a previous image is uploaded
    // delete it and then upload new one
    if (collectionId) {
      yield call(doRequest, {
        url: `unimup/objctupload/prodlabel/${collectionId}`,
        method: 'DELETE'
      });
    }

    const formData = new FormData();
    formData.append('media', imageBinary);

    const response = yield call(doRequest, {
      url: `unimup/objctupload/prodlabel/${id}`,
      method: 'POST',
      data: formData
    });

    const imgObj = {
      collectionId: response.data.CollectionId
    };
    yield call(doRequest, {
      url: `prodprop/enums/${id}`,
      method: 'PATCH',
      data: imgObj
    });

    const newCollectionId = response.data.CollectionId;
    yield put(saveENumbersImageSuccess({ img: image, item: newCollectionId }));

    message.info(yield getIntlMessage('Generic.ApiMessages.ENumbers.ImageUploadSuccess'));
  } catch (error) {
    console.log({ error });

    message.error(yield getIntlMessage('Generic.ApiMessages.ENumbers.ImageUploadError'));
    yield put(saveENumbersImageFail());
  } finally {
    yield put(hideENumbersImgLoader());
  }
}

function* deleteImage() {
  try {
    const collectionId = yield select(state => state.eNumbers.collectionId);
    const id = yield select(state => state.eNumbers.selectedNode);

    if (collectionId) {
      yield call(doRequest, {
        url: `unimup/objctupload/prodlabel/${collectionId}`,
        method: 'DELETE'
      });

      const imgObj = {
        collectionId: ''
      };
      yield call(doRequest, {
        url: `prodprop/enums/${id}`,
        method: 'PATCH',
        data: imgObj
      });

      yield put(removeENumbersImageSuccess());
      message.info(yield getIntlMessage('Generic.ApiMessages.ENumbers.ImageDeleteSuccess'));
    }
  } catch (error) {
    console.log({ error });
    message.error(yield getIntlMessage('Generic.ApiMessages.ENumbers.ImageDeleteError'));
  }
}

function* getENumbersImageRequest(id) {
  let fileList = [];
  let tempObj = {};
  let response;
  let response1;

  try {
    if (id && id != '') {
      response = yield call(doRequest, {
        url: `unimup/objctupload/prodlabel/${id}`,
        method: 'GET'
      });

      let imageUrls = imageList(response.data.pages);

      for (var i = 0; i < imageUrls.length; i++) {
        tempObj = {};
        response1 = yield call(doRequest, {
          method: 'GET',
          url: `/unimup/objctupload${imageUrls[i].tempUrl}`,
          responseType: 'blob'
        });

        let image = URL.createObjectURL(response1.data);
        tempObj = {
          uid: `${imageUrls[i].pageId}`,
          url: image,
          active: imageUrls[i].active,
          position: `${i}`,
          type: response1.data ? response1.data.type : ''
        };
        fileList.push(tempObj);
      }
    }

    if (id === '') {
      fileList = [];
    }
    if (response1.status === 200) {
      yield put(getENumbersImageSuccess({ imagedata: fileList }));
    }
  } catch (error) {
    return false;
  } finally {
    yield put(hideENumbersImgLoader());
  }
}

function imageList(array) {
  let newArr = [];
  let tempObj = {};
  if (array) {
    for (var i = 0; i < array.length; i++) {
      tempObj = {};

      tempObj = {
        tempUrl: array[i].optimized.fetchUrl,
        pageId: array[i].pageId,
        active: array[i].active
      };
      newArr.push(tempObj);
    }
  }

  return newArr;
}
/** Watchers */

function* getListWatcher() {
  yield takeLatest(types.GET_LIST, getList);
}
function* saveImageWatcher() {
  yield takeLatest(types.SAVE_IMG, saveImage);
}

function* deleteImageWatcher() {
  yield takeLatest(types.REMOVE_IMG, deleteImage);
}

function* saveNodeLabelWatcher() {
  while (true) {
    const action = yield take(types.SAVE_NODE);
    yield call(saveNode, action.payload);
  }
}

function* updateOrderLabelWatcher() {
  // yield takeEvery(types.UPDATE_ORDER, updateOrder);
  while (true) {
    const action = yield take(types.UPDATE_ORDER);
    yield call(updateOrder, action);
  }
}

function* setSelectedNodeLabelWatcher() {
  yield takeLatest(types.SET_SELECTED_NODE, ({ payload: { id, type } }) =>
    setSelectedNode(id, type)
  );
}

function* saveFormChangesLabelWatcher() {
  yield takeLatest(types.SAVE_CHANGES, saveFormChanges);
}

function* searchLabelWatcher() {
  yield takeLatest(types.SEARCH, search);
}

function* deleteNodeLabelWatcher() {
  yield takeLatest(types.DELETE_NODE, deleteNode);
}

function* addNodeLabelWatcher() {
  while (true) {
    const action = yield take(types.ADD_NODE);
    yield call(addNode, action);
  }
}

export default function* rootSaga() {
  yield all([
    fork(getListWatcher),
    fork(addNodeLabelWatcher),
    fork(saveNodeLabelWatcher),
    fork(updateOrderLabelWatcher),
    fork(setSelectedNodeLabelWatcher),
    fork(saveFormChangesLabelWatcher),
    fork(searchLabelWatcher),
    fork(deleteNodeLabelWatcher),
    fork(saveImageWatcher),
    fork(deleteImageWatcher)
  ]);
}
