import { createTypes } from 'redux-action-creator';

const types = createTypes(
  [
    'SAVE_CHANGES',
    'GET_STORE_LIST',
    'GET_STORE_LIST_SUCCESS',
    'GET_STORE_BY_LOCALE',
    'GET_STORE_BY_LOCALE_SUCCESS',
    'GET_STORE_LIST_SUCCESS',
    'GET_LIST_STOREINFORMATION_VALUES',
    'GET_STOREINFORMATION_VALUE_LIST_SUCCESS',
    'GET_MAY_CONTAIN_VALUES_SUCCESS',
    'UPDATE_STOREINFORMATION_LOADED_STATUS',
    'ADD_STORE',
    'EDIT_STORE',
    'DELETE_STORE',
    'DELETE_STORE_SUCCESS',
    'UPDATE_CATEGORY_LOADER',
    'UPDATE_INGREDIENT_LOADER',
    'UPDATE_SAVE_BUTTON_STATUS',
    'UPDATE_STORE_DETAIL_LOAD_STATUS',
    'UPDATE_STORE_LOCALE',
    'MAY_CONTAINT_LOADER',
    'LABEL_LIST_LOADER',
    'ADD_SPECIAL_DAYS',
    'SET_OLD_SPECIAL_DAYS',
    'DELETE_SPECIAL_DAYS',
    'UPDATE_SPECIAL_DAYS_CLOSE',
    'UPDATE_SPECIAL_DAYS_VALIDATION',
    'UPDATE_SPECIAL_DAYS_PRESERVE',
    'SET_STORE_LABLE_IMAGE',
    'SET_FILTER_PAYMENT_LIST',
    'SET_FILTER_FEATURE_LIST',
    'RESET'
  ],
  'STORE'
);

export default types;
