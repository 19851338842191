import { actionCreator } from 'redux-action-creator';
import types from './labels.constants';

export const getLabelsList = actionCreator(types.GET_LIST);
export const getLabelsListFail = actionCreator(types.GET_LIST_FAIL);
export const getLabelsListSuccess = actionCreator(types.GET_LIST_SUCCESS, 'data');
export const addNewNode = actionCreator(types.ADD_NODE);
export const addNewNodeSuccess = actionCreator(types.ADD_NODE_SUCCESS);
export const saveNewNode = actionCreator(types.SAVE_NODE, 'values', 'newNode', 'cb');
export const saveNewNodeSuccess = actionCreator(types.SAVE_NODE_SUCCESS, 'values', 'newNode');
export const saveNewNodeFail = actionCreator(types.SAVE_NODE_FAIL);
export const updateSortOrder = actionCreator(types.UPDATE_ORDER, 'values', 'oldIndex', 'newIndex');
export const updateSortOrderSuccess = actionCreator(types.UPDATE_ORDER_SUCCESS, 'values');
export const updateSortOrderFail = actionCreator(types.UPDATE_ORDER_FAIL);
export const setSelectedNode = actionCreator(types.SET_SELECTED_NODE, 'id', 'type');
export const setSelectedNodeSuccess = actionCreator(
  types.SET_SELECTED_NODE_SUCCESS,
  'id',
  'selectedData'
);
export const updateSaveButtonState = actionCreator(types.UPDATE_BUTTON_STATE, 'btnState');
export const saveChanges = actionCreator(types.SAVE_CHANGES, 'saveType');
export const saveChangesSuccess = actionCreator(types.SAVE_CHANGES_SUCCESS, 'form');
export const saveChangesFail = actionCreator(types.SAVE_CHANGES_FAIL);
export const searchLabel = actionCreator(types.SEARCH, 'key');
export const searchLabelSuccess = actionCreator(types.SEARCH_SUCCESS, 'data');
export const deleteNode = actionCreator(types.DELETE_NODE, 'id');
export const deleteNodeSuccess = actionCreator(types.DELETE_NODE_SUCCESS, 'id');
export const deleteNodeFail = actionCreator(types.DELETE_NODE_FAIL, 'id');
export const updateFormData = actionCreator(types.UPDATE_FORMDATA, 'key', 'formData');
export const updateSaveModal = actionCreator(types.UPDATE_SAVE_MODAL, 'state');
export const saveChangesAndSwitch = actionCreator(types.SAVE_CHANGES_AND_SWITCH);
export const cancelChangesAndSwitch = actionCreator(types.CANCEL_CHANGES_AND_SWITCH);
export const cancelChangesAndStay = actionCreator(types.CANCEL_CHANGES_AND_STAY);
export const resetLabelsState = actionCreator(types.RESET_LABELS_STATE);
export const cuntrySelectedListUpdate = actionCreator(types.COUNTRY_SELECTED_LIST, 'keys');
export const countryAllSelectDisabled = actionCreator(types.DISABLED_WITH_ALL_COUNTRY, 'disabled');
export const selectLableImage = actionCreator(types.SET_TEMP_IMG, 'img', 'file');
export const removeSelectedLableImage = actionCreator(types.REMOVE_TEMP_IMG);
export const saveLableImage = actionCreator(types.SAVE_IMG);
export const saveLableImageSuccess = actionCreator(types.SAVE_IMG_SUCCESS, 'img', 'item');
export const saveLableImageFail = actionCreator(types.SAVE_IMG_FAIL);
export const removeLableImage = actionCreator(types.REMOVE_IMG);
export const removeLableImageSuccess = actionCreator(types.REMOVE_IMG_SUCCESS);
export const removeLableImageFail = actionCreator(types.REMOVE_IMG_FAIL);
export const showLableLoader = actionCreator(types.SHOW_LOADER);
export const hideLableImgLoader = actionCreator(types.HIDE_LOADER);
export const getLableImage = actionCreator(types.GET_IMG);
export const getLableImageSuccess = actionCreator(types.GET_IMG_SUCCESS, 'imagedata');
