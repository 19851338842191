import React from "react";
import { ConnectedRouter } from "react-router-redux";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";

import "assets/vendors/style";
import "styles/wieldy.less";
import configureStore, { history } from "./appRedux/store";
import App from "./containers/App/index";
import { signOutSessionExpiredUser } from 'appRedux/actions/Auth';

export const store = configureStore();
window.store = store;

// logout users in multiple tabs if user 
// sign out in one tab
window.addEventListener('storage', (e) => {
  if(e.key === 'access_token' && e.oldValue && !e.newValue) {
    store.dispatch(signOutSessionExpiredUser());
  }
})

const NextApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </ConnectedRouter>
  </Provider>
);

export default NextApp;
