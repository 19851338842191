import * as profileActions from './profile.constants';

const initialState = {
  firstName: '',
  lastName: '',
  country: '',
  countryCode: '',
  local: '',
  language: '',
  imageUrl: '',
  imageLoader: true,
  btnLoader: false,
  showMessageprop: false,
  resMessage: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case profileActions.BASIC_INFO_UPDATE_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
        country: payload.country
      };
    }
    case profileActions.CHANGE_PASSWORD_UPDATE_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        ...payload
      };
    }

    case profileActions.PROFILE_IMAGE_UPDATE_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        imageUrl: payload.image
      };
    }
    case profileActions.BASIC_SETTINGS_UPDATE_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
        countryCode: payload.country,
        country: payload.country
      };
    }

    case profileActions.PROFILE_IMAGE_GET_SUCCESS: {
      const { payload, imageLoader } = action;

      return {
        ...state,
        imageUrl: payload,
        imageLoader: imageLoader
      };
    }
    case profileActions.PROFILE_INFO_GET_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        firstName: payload.first_name,
        lastName: payload.last_name,
        countryCode: payload.country,
        country: payload.country,
        language: payload.local ? payload.local : '3'
        //language: 1
      };
    }
    case profileActions.PROFILE_IMAGE_UPDATE_FAILURE: {
      return {
        ...state,
        imageUrl: '/profileAvatar.jpg',
        imageLoader: false
      };
    }
    case profileActions.RESET_PROFILE_TO_INITIAL: {
      return {
        state: initialState
      };
    }

    case profileActions.SHOW_LOADER: {
      return {
        ...state,
        btnLoader: true
      };
    }

    case profileActions.HIDE_LOADER: {
      return {
        ...state,
        btnLoader: false
      };
    }

    case profileActions.SHOW_MESSAGE: {
      return {
        ...state,
        showMessageprop: true
      };
    }

    case profileActions.HIDE_MESSAGE: {
      return {
        ...state,
        showMessageprop: false
      };
    }

    default:
      return state;
  }
};
