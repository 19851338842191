import React, {Component} from "react";
import {Layout, Skeleton, Spin} from "antd";

import Sidebar from "../Sidebar/index";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";
import { setIntl } from '../../../src/app/shared/redux/actions/intlReact.actions';
import { injectIntl } from 'react-intl';

import Topbar from "../Topbar/index";
import {footerText} from "util/config";
import App from "routes/index";
import {connect} from "react-redux";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import {getProfileImage, getProfileInfo} from '../../app/module/profile/redux/profile.actions';
import { getlanguageList } from '../../app/shared/languageList/languagelist.action';
import {getCountryList} from '../../app/shared/redux/actions/countries.action';
import IntlMessages from 'util/IntlMessages';

const {Content, Footer} = Layout;

export class MainApp extends Component {

  componentDidMount() {
    this.props.getProfileImage();
    this.props.getProfileInfo();
    this.props.getlanguageList();
    this.props.getCountryList();
    this.props.setIntl(this.props.intl);
  }

  componentDidUpdate(prevProps) {
   
    if(this.props.intl && this.props.intl.messages) this.props.setIntl(this.props.intl)
    
  }

  getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default :
        return ""
    }
  };
  
  getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL :
        return <HorizontalDefault/>;
      case NAV_STYLE_DARK_HORIZONTAL :
        return <HorizontalDark/>;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL :
        return <InsideHeader/>;
      case NAV_STYLE_ABOVE_HEADER :
        return <AboveHeader/>;
      case NAV_STYLE_BELOW_HEADER :
        return <BelowHeader/>;
      case NAV_STYLE_FIXED :
        return <Topbar/>;
      case NAV_STYLE_DRAWER :
        return <Topbar/>;
      case NAV_STYLE_MINI_SIDEBAR :
        return <Topbar/>;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR :
        return <NoHeaderNotification/>;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR :
        return <NoHeaderNotification/>;
      default :
        return null;
    }
  };

  getSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <Sidebar/>;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED :
        return <Sidebar/>;
      case NAV_STYLE_DRAWER :
        return <Sidebar/>;
      case NAV_STYLE_MINI_SIDEBAR :
        return <Sidebar/>;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR :
        return <Sidebar/>;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar/>;
      default :
        return null;
    }
  };

  // footer date generate
renderFooterText=()=>{
  const d = new Date();
  const year = d.getFullYear();
  return this.setIntlValue(year);
}

setIntlValue=(year)=>{
const {intl}=this.props;
const footerMessage=intl.formatMessage({ id: 'Generic.Footer' });
if (footerMessage) {
  const splitzvalue=footerMessage.split('©')
  if (splitzvalue[1]) {
    return splitzvalue[0]+ '© '+year+ ' ' +splitzvalue[1]
  }else{
    return footerMessage
  }
}
}

  render() {
    const {match, width, navStyle,localeLoader } = this.props;
    return (
      <Layout className="gx-app-layout">
        {this.getSidebar(navStyle, width,localeLoader)}
        <Layout>
          {this.getNavStyles(navStyle)}
          <Content className={`gx-layout-content ${ this.getContainerClass(navStyle)} `}>
          <Spin wrapperClassName={'gx-main-content-wrapper'} className={'app-spinner'} spinning={localeLoader}>
            <Skeleton className={'app-skeleton '} loading={localeLoader} active={false} title={false} paragraph={{rows: 1}}>
              <App match={match}/>
            </Skeleton>
          </Spin>
            <Footer>
            <Skeleton  loading={localeLoader}  active={true}  title={false}  paragraph={{ rows: 1, width: ['20%'] }} >
              <div className="gx-layout-footer-content">
             {this.renderFooterText()}   
              </div>
              </Skeleton>
            </Footer>
          </Content>

        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = ({settings, loaders, profile, languageList}) => {
  const {width, navStyle} = settings;
  return {width, navStyle, localeLoader: loaders.locales, userLocale: profile.language, languageList: languageList.languageList,  
  }
};
export default injectIntl(connect(mapStateToProps, 
  {
    getProfileImage, 
    getProfileInfo, 
    getlanguageList, 
    setIntl,
    getCountryList
  })(MainApp));

