import { all } from 'redux-saga/effects';
import manufactureSagas from './manufactureModal/productModals.saga';
import brandSagas from './brandModal/brandModal.saga';
import distributorSagas from './distributorModal/distributorModal.saga';
import ingredientModelSaga from './ingredientModel/ingredientModel.saga';
import tagsModelSagas from './tagModal/tagModal.saga';

export default function* rootSaga() {
  yield all([
    manufactureSagas(),
    brandSagas(),
    distributorSagas(),
    ingredientModelSaga(),
    tagsModelSagas()
  ]);
}
