import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu, Skeleton} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const SubMenu = Menu.SubMenu;
//const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {
  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const {themeType, navStyle, pathname, localeLoader} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (<Auxiliary>

        <SidebarLogo/>
        <div className="gx-sidebar-content">
          {/* <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile/>
             <AppsNavigation/>
          </div> */}
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Skeleton className={'sidebar-skeleton'} loading={localeLoader} active={true} title={false} paragraph={{rows: 6, width: new Array(6).fill(200)}}>            
            <Menu
              className="cccc"
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">

              <Menu.Item key="dashboard">
                <Link to="/dashboard"><i className="icon icon-widgets"/>
                <IntlMessages id="LeftMenu.Dashboard"/></Link>
              </Menu.Item>
              
              <SubMenu key="content_management" className={this.getNavStyleSubMenuClass(navStyle)}
                        title={<span> <i className="icon icon-dasbhoard"/>
                        <IntlMessages id="LeftMenu.ContentManagement"/></span>}>
                <Menu.Item key="content_management/all_categories">
                  <Link to="/content_management/all_categories">
                    {/* <i className="icon icon-crypto"/> */}
                    <IntlMessages id="LeftMenu.ContentManagement.AllCategories"/>
                  </Link>
                </Menu.Item>

                {/* <Menu.Item key="content_management/moderation">
                  <Link to="/content_management/moderation">
                    {/* <i className="icon icon-crypto"/> */}
                    {/* <IntlMessages id="LeftMenu.ContentManagement.AllCategories"/> */}
                    {/* Moderation
                  </Link>
                </Menu.Item> */} 
                <Menu.Item key="content_management/moderation">
                  <Link to="/content-management/moderation">
                    {/* <i className="icon icon-crypto"/> */}
                    <IntlMessages id="LeftMenu.ContentManagement.Moderation"/>
                   
                  </Link>
                </Menu.Item>

                


                
                {/* <Menu.Item key="content-management/products">
                  <Link to="/content-management/products">
                    <IntlMessages id="LeftMenu.ContentManagement.Products"/>
                  </Link>
                </Menu.Item> */}
                <Menu.Item key="content_management/restaurants">
                <Link to="/content-management/restaurants">
                  {/* <Link to="/content_management/restaurants"> */}
                    {/* <i className="icon icon-listing-dbrd"/> */}
                    <IntlMessages id="LeftMenu.ContentManagement.Restaurants"/>
                  </Link>
                </Menu.Item>
                <Menu.Item key="content_management/store">
                  <Link to="/content-management/store">
                    {/* <i className="icon icon-listing-dbrd"/> */}
                    <IntlMessages id="LeftMenu.ContentManagement.Store"/>
                  </Link>
                </Menu.Item>
                {/* <Menu.Item key="content_management/manufacturer">
                  <Link to="/content_management/manufacturer">
                   
                    <IntlMessages id="LeftMenu.ContentManagement.Manufacturer"/>
                  </Link>
                </Menu.Item> */}
                <Menu.Item key="content_management/archive">
                  <Link to="/content_management/archive">
                    {/* <i className="icon icon-listing-dbrd"/> */}
                    <IntlMessages id="LeftMenu.ContentManagement.Archive"/>
                  </Link>
                </Menu.Item>
                <SubMenu
                key="content_management_catalogue" className={this.getNavStyleSubMenuClass(navStyle)}
                        title={<span> <IntlMessages id="LeftMenu.ContentManagement.Catalogue"/></span>}
                >
                  <Menu.Item key="content-management/catalogue/products">
                    <Link to="/content-management/catalogue/products">
                       <IntlMessages id="LeftMenu.ContentManagement.Catalogue.Products"/>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="content-management/catalogue/manufacturer">
                    <Link to="/content-management/catalogue/manufacturer">
                       <IntlMessages id="LeftMenu.ContentManagement.Catalogue.Manufacturer"/>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="content-management/catalogue/distributor">
                    <Link to="/content-management/catalogue/distributor">
                       <IntlMessages id="LeftMenu.ContentManagement.Catalogue.Distributor"/>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="content-management/catalogue/brands">
                    <Link to="/content-management/catalogue/brands">
                       <IntlMessages id="LeftMenu.ContentManagement.Catalogue.Brand"/>
                    </Link>
                  </Menu.Item>
                  
                </SubMenu>
              </SubMenu>
              <SubMenu key="system" className={this.getNavStyleSubMenuClass(navStyle)}
                         title={<span> <i className="icon icon-setting"/>
                         <IntlMessages id="LeftMenu.System"/></span>}>
                <Menu.Item key="system/newIngredients">
                  <Link to="/system/newIngredientsList">
                    <IntlMessages id="LeftMenu.System.NewIngredients"/>
                  </Link>
                </Menu.Item>
                <Menu.Item key="system/ingredients">
                  <Link to="/system/ingredients">
                    {/* <i className="icon icon-crypto"/> */}
                    <IntlMessages id="LeftMenu.System.Ingredients"/>
                  </Link>
                </Menu.Item>
                <Menu.Item key="system/e-numbers">
                  <Link to="/system/e-numbers">
                    <IntlMessages id="LeftMenu.System.ENumbers"/>
                  </Link>
                </Menu.Item>
                <Menu.Item key="system/tags">
                  <Link to="/system/tags">
                    <IntlMessages id="LeftMenu.System.Tags"/>
                  </Link>
                </Menu.Item>
                <Menu.Item key="system/labels">
                  <Link to="/system/labels">
                    {/* <i className="icon icon-listing-dbrd"/> */}
                    <IntlMessages id="LeftMenu.System.Labels"/>
                  </Link>
                </Menu.Item>
                <SubMenu key="categories" className={this.getNavStyleSubMenuClass(navStyle)}
                         title={<span>
                         <IntlMessages id="Categories"/></span>}>
                         <Menu.Item key="system/categories">
                            <Link to="/system/categories">
                            {/* <i className="icon icon-listing-dbrd"/> */}
                            <IntlMessages id="LeftMenu.System.Categories"/>
                            </Link>
                         </Menu.Item>
                         <Menu.Item key="system/category-circles">
                            <Link to="/system/category-circles">
                            {/* <i className="icon icon-listing-dbrd"/> */}
                            <IntlMessages id="LeftMenu.System.CategoriesCircle"/>
                            </Link>
                         </Menu.Item>
                         <Menu.Item key="system/category-show-in-mobile-home">
                            <Link to="/system/category-show-in-mobile-home">
                            {/* <i className="icon icon-listing-dbrd"/> */}
                            <IntlMessages id="LeftMenu.System.CategoriesShowInMobileHome"/>
                            </Link>
                         </Menu.Item>
                </SubMenu>
                {/* <Menu.Item key="system/categories"> */}
                  {/* <Link to="/system/categories"> */}
                    {/* <i className="icon icon-listing-dbrd"/> */}
                    {/* <IntlMessages id="LeftMenu.System.Categories"/> */}
                  {/* </Link> */}
                {/* </Menu.Item> */}
                <Menu.Item key="system/nutrition-values">
                  <Link to="/system/nutrition-values">
                    {/* <i className="icon icon-crypto"/> */}
                    {/* <IntlMessages id="LeftMenu.System.Ingredients"/> */}
                    <IntlMessages id="LeftMenu.System.NutritionValues"/>
                  </Link>
                </Menu.Item>
                <Menu.Item key="system/store-information">
                  <Link to="/system/store-information">
                    <IntlMessages id="LeftMenu.System.StoreInformation"/>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu key="userManagement" className={this.getNavStyleSubMenuClass(navStyle)}
                         title={<span> <i className="icon icon-family "/>
                         <IntlMessages id="LeftMenu.UserManagement"/></span>}>
                <Menu.Item key="user_management/users">
                  <Link to="/user_management/users">
                    {/* <i className="icon icon-crypto"/> */}
                    <IntlMessages id="LeftMenu.UserManagement.Users"/>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu key="addManagement" className={this.getNavStyleSubMenuClass(navStyle)}
                         title={<span> <i className="icon icon-family "/>
                        
                         <IntlMessages id="LeftMenu.AdManagement"/></span>}>
                        
                <Menu.Item key="ad-management/Ads">
                  <Link to="/ad-management/ads">
                    {/* <i className="icon icon-crypto"/> */}
                    <IntlMessages id="LeftMenu.AdManagement.Ads"/>
                  
                  </Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu key="affiliate" className={this.getNavStyleSubMenuClass(navStyle)}
                         title={<span> <i className="icon icon-crm"/>
                         <IntlMessages id="LeftMenu.Affiliate"/></span>}>
                <Menu.Item key="affiliate/my_activites">
                  <Link to="/affiliate/my_activites">
                    {/* <i className="icon icon-crypto"/> */}
                    <IntlMessages id="LeftMenu.Affiliate.MyActivities"/>
                  </Link>
                </Menu.Item>
                <Menu.Item key="affiliate/my_team">
                  <Link to="/affiliate/my_eam">
                    {/* <i className="icon icon-crm"/> */}
                    <IntlMessages id="LeftMenu.Affiliate.MyTeam"/>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu key="support" className={this.getNavStyleSubMenuClass(navStyle)}
                         title={<span> <i className="icon icon-ripple"/>
                         <IntlMessages id="LeftMenu.Support"/></span>}>
                <Menu.Item key="support/faq">
                  <Link to="/support/faq">
                    {/* <i className="icon icon-crypto"/> */}
                    <IntlMessages id="LeftMenu.Support.Faq"/>
                  </Link>
                </Menu.Item>
                <Menu.Item key="support/marketing_material">
                  <Link to="/support/marketing_material">
                    {/* <i className="icon icon-crm"/> */}
                    <IntlMessages id="LeftMenu.Support.MarketingMaterial"/>
                  </Link>
                </Menu.Item>
              </SubMenu>

              
            </Menu>
            </Skeleton>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings, loaders}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  return {navStyle, themeType, locale, pathname, localeLoader: loaders.locales}
};
export default connect(mapStateToProps)(SidebarContent);

