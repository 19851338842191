import types from './e-numbers.constants';

const initialState = {
  comparison: null,
  originalData: {
    comparison: []
  },
  selectedNode: '',
  selectedNodeData: {},
  selectedNodeType: '',
  saveButtonState: 'default',
  formData: null,
  isSaveModalOpen: false,
  selectedCountryItems: [],
  markAllCountry: false,
  image: null,
  originalImage: null,
  loading: false,
  imageBinary: null,
  collectionId: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        ...data,
        originalData: {
          ...data
        }
      };
    }

    case types.ADD_NODE_SUCCESS: {
      const tempNode = { value: '@@/newNode', id: `${new Date().getTime()}` };

      const values = state['comparison'] || [];

      //check if a new node is there to prevent adding multiple new nodes
      if (values.length && values[0]['value'] === '@@/newNode') {
        return state;
      }

      return {
        ...state,
        comparison: [tempNode, ...values],
        selectedNode: '',
        selectedNodeData: null,
        selectedNodeType: null,
        saveButtonState: 'default',
        formData: null,
        image: null,
        originalImage: null,
        loading: false,
        imageBinary: null,
        collectionId: ''
      };
    }

    case types.SAVE_NODE_SUCCESS: {
      const { values, newNode } = action.payload;

      return {
        ...state,
        comparison: [...values],
        originalData: {
          ...state.originalData,
          comparison: newNode
            ? [newNode, ...state.originalData.comparison]
            : [...state.originalData.comparison]
        }
      };
    }

    case types.UPDATE_ORDER_SUCCESS: {
      const { values } = action.payload;

      return {
        ...state,
        comparison: values,
        originalData: {
          ...state.originalData,
          comparison: values
        }
      };
    }

    case types.SET_SELECTED_NODE_SUCCESS: {
      const { id, selectedData } = action.payload;

      return {
        ...state,
        selectedNode: id,
        selectedNodeData: selectedData,
        selectedNodeType: 'comparison',
        saveButtonState: 'default',
        formData: null,
        isSaveModalOpen: false,
        collectionId: selectedData.collectionId ? selectedData.collectionId : ''
      };
    }

    case types.UPDATE_BUTTON_STATE: {
      const { btnState } = action.payload;
      return {
        ...state,
        saveButtonState: btnState
      };
    }

    case types.SAVE_CHANGES_SUCCESS: {
      const { form } = action.payload;
      return {
        ...state,
        saveButtonState: 'saved-changes',
        selectedNodeData: {
          ...state.selectedNodeData,
          ...form
        }
      };
    }

    case types.SEARCH_SUCCESS: {
      const { data } = action.payload;
      if (data === null) {
        return {
          ...state,
          ...state.originalData
        };
      }
      return {
        ...state,
        ...data
      };
    }

    case types.DELETE_NODE_SUCCESS: {
      const { id } = action.payload;
      const items = state['comparison'];
      const originalData = state.originalData.comparison;
      const deletedItemIndex = items.findIndex(i => i.id === id);
      const deletedItemOriginalIndex = originalData.findIndex(i => i.id === id);
      const newItems = [...items.slice(0, deletedItemIndex), ...items.slice(deletedItemIndex + 1)];
      const newItemsOriginalData = [
        ...originalData.slice(0, deletedItemOriginalIndex),
        ...originalData.slice(deletedItemOriginalIndex + 1)
      ];

      const newState = {
        ...state,
        comparison: newItems,
        originalData: {
          ...state.originalData,
          comparison: newItemsOriginalData
        }
      };

      if (id === state.selectedNode) {
        newState['selectedNode'] = '';
        newState['selectedNodeData'] = null;
        newState['selectedNodeType'] = null;
        newState['saveButtonState'] = 'default';
        newState['formData'] = null;
      }

      return newState;
    }

    case types.UPDATE_FORMDATA: {
      const { formData, key } = action.payload;

      const data = {};
      data[key] = formData;

      if (state.formData === null) {
        return {
          ...state,
          formData: {
            ...data
          }
        };
      }
      // else if (formData === null) {
      //   return {
      //     ...state,
      //     formData: null
      //   };
      // }

      return {
        ...state,
        formData: {
          ...state.formData,
          ...data
        }
      };
    }
    case types.UPDATE_SAVE_MODAL: {
      const { state: modalState } = action.payload;
      const isOpen = modalState === 'show' ? true : false;

      return {
        ...state,
        isSaveModalOpen: isOpen
      };
    }

    case types.RESET_STATE: {
      return initialState;
    }

    case types.SHOW_LOADER: {
      return {
        ...state,
        loading: true
      };
    }

    case types.HIDE_LOADER: {
      return {
        ...state,
        loading: false
      };
    }

    case types.SAVE_IMG_SUCCESS: {
      const { img, item } = action.payload;

      return {
        ...state,
        image: img,
        originalImage: img,
        imageBinary: null,
        collectionId: item
      };
    }

    case types.SET_TEMP_IMG: {
      const { img, file } = action.payload;

      return {
        ...state,
        image: img,
        imageBinary: file
      };
    }

    case types.REMOVE_TEMP_IMG: {
      return {
        ...state,
        image: state.originalImage,
        imageBinary: null
      };
    }

    case types.SAVE_IMG_FAIL: {
      return {
        ...state,
        image: state.originalImage,
        imageBinary: null
      };
    }

    case types.REMOVE_IMG_SUCCESS: {
      return {
        ...state,
        image: null,
        originalImage: null,
        imageBinary: null,
        collectionId: ''
      };
    }

    case types.GET_IMG_SUCCESS: {
      const { imagedata } = action.payload;
      return {
        ...state,
        image: imagedata[0] ? imagedata[0].url : '',
        originalImage: imagedata[0] ? imagedata[0].url : ''
      };
    }

    default: {
      return state;
    }
  }
};
