export const GET_IMAGE_LIST = 'GET_IMAGE_LIST';
export const SET_IMAGE_LIST = 'SET_IMAGE_LIST';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const IMAGE_POSITION_UPDATE = 'IMAGE_POSITION_UPDATE';
export const IMAGE_ACTIVATE = 'IMAGE_ACTIVATE';
export const SET_COLLECTION_ID = 'SET_COLLECTION_ID';
export const SET_NEW_IMAGE_URL = 'SET_NEW_IMAGE_URL';
export const IMAGE_UPLOAD_LOADER = 'IMAGE_UPLOAD_LOADER';
export const DISPLAY_SAVE_DELETE_BUTTON = 'DISPLAY_SAVE_DELETE_BUTTON';
export const RESET_IMAGES = 'RESET_IMAGES';
export const CHANGE_IMAGE_UPLOAD_PAGE_MOUNT = 'CHANGE_IMAGE_UPLOAD_PAGE_MOUNT';
export const GET_CLICKED_IMAGE = 'GET_CLICKED_IMAGE';
