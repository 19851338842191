import { all, fork, put, takeLatest, select, call, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import types from './product.constants';
import normalizeTreeByLocale from '../../../../shared/utils/normalizeTreeByLocale';
import {
  getProductListSuccess,
  getNutrientsValueListSuccess,
  updateNutritionLoadedStatus,
  getCategoryListSuccess,
  updateCategoryLoader,
  getIngredientListSuccess,
  updateIngredientLoader,
  getEnumberListSuccess,
  getStoreListSuccess,
  getStoreTypeListSuccess,
  getTagListSuccess,
  setTagImageSuccess,
  getProductByLocalSuccess,
  setProductStore,
  deleteProductSuccess,
  updateSaveButtonStatus,
  updateProductDetailLoadStatus,
  updateProductLocale,
  getMayContainValueSuccess,
  getLabelsListSuccess,
  mayContaintLoader,
  setStoreTypeImageSuccess,
  lablesListLoader,
  setStoreImage,
  getStoreUnicProductPriceSuccess,
  getBracketpriceSuccess,
  getProductID,
  getBracketprice,
  getCategoryWorkerSuccess,
  getIngArrayWorkerSuccess,
  getENumbersWorkerSuccess,
  getMayContainWorkerSuccess,
  getTagsWorkerSuccess,
  getNutritionWorkerSuccess,
  getLabelsWorkerSuccess,
  storeDataEnabled,
  tagListEnabled
} from './product.actions';

import {
  setIngredientArray,
  setENumbersArray,
  getIngredientArrayWorkerSuccess,
  getEnumberArrayWorkerSuccess
} from './../../../../shared/textEditor/redux/textEditorPopup.actions';

import { setCollectionId } from './../../../../shared/redux/actions/imageUploader.actions';
import { doRequest } from './../../../../shared/redux/sagas/api';
import { message } from 'antd';
import { getAlternativeTitle } from '../../../system/ingredients/redux/ingredients.reducer';

import setdefaultDataSort from '../../../../shared/utils/setdefaultDataSort';

import { addJobToQueue } from './../../../../shared/redux/actions/index';
import { generateTagImagesUrls, resetImgUrls } from './getTagImages';

import {
  ReturnStoreImageUrl,
  getOptionArray,
  getStoreDataList
} from '../components/getStoreArrayWithImage';

function* getProductList() {
  yield put(mayContaintLoader({ loader: true }));
  yield put(lablesListLoader({ loader: true }));

  yield put(updateNutritionLoadedStatus({ data: false }));

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: 'prodres/products'
    });

    let modifiedArray = [];

    if (response.data.length > 0) {
      response.data.forEach(element => {
        let elementModified = {
          _id: element._id,
          key: element._id,
          ean: element.ean
        };
        modifiedArray.push(elementModified);
      });
    }

    yield put(updateNutritionLoadedStatus({ data: false }));

    yield put(getProductListSuccess({ data: modifiedArray }));
  } catch (error) {}
}

function* getProductByLocal(payloadPassed) {
  const { payload } = payloadPassed;
  const { languageList } = yield select(state => state.languageList);

  const { data } = payload;

  const languagebyLocaleData = languageList.filter(item => item.locale == data.locale);

  let unitTypeWithLocale;
  languagebyLocaleData.forEach(element => {
    unitTypeWithLocale = element.unit;
  });

  yield put(updateProductDetailLoadStatus({ data: false }));

  let urlPassing = `prodres/products/` + data.pid + '/' + data.locale;

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: urlPassing
    });

    // const response = {
    //   data: {
    //     non_translate: {
    //       _id: '91a90972fbc3b4888603ac343503529a',
    //       _rev: '4-8725b5d870b8ad4b495b9312058a852b',
    //       store: '',
    //       ean: '4343433545',
    //       price: '',
    //       currency: '',
    //       alcohol_content: null,
    //       product_url: '',
    //       vegan_type: 1,
    //       freezer_be: false,
    //       category: [1669],
    //       ingeredent: [
    //         {
    //           id: 1837,
    //           attributes: null,
    //           alternative: null
    //         }
    //       ],
    //       tags: [],
    //       eNumber: [],
    //       temperature_type: '',
    //       temperature: '',
    //       mayContain: [],
    //       country: null,
    //       lables: [],
    //       countryFilter: [],
    //       mediaCollectionId: '',
    //       measuring_type: 1,
    //       manufacturer: '',
    //       brand: '',
    //       local_distributor: '',
    //       internal_comment: 'WEEE',
    //       add_user: {
    //         id: '75b383903c3dac034b9f31260600a4eb',
    //         first_name: 'Test',
    //         last_name: 'Person',
    //         country: '21',
    //         local: '3',
    //         userId: '75b383903c3dac034b9f312606009599'
    //       },
    //       date: 1587659326907,
    //       add_local: 'en_US',
    //       last_edit_date: 1588063913038
    //     },
    //     translatable: {
    //       _id: '6ab463dd96d68a944fab72b5810203b0',
    //       _rev: '4-87c9c83d5c08cf06fad907af71fc515f',
    //       locale: 'en_US',
    //       comment: 'adadads',
    //       product_name: 'TEST PROD',
    //       sub_heading: 'TEST PRODhead',
    //       nutritionValue: {
    //         'unit-NUTnut_valNutfr': 'mg',
    //         'unit-NUTnut_valNutSin': 'gr',
    //         'unit-NUTnut_valNutAR': 'μg'
    //       },
    //       vitamins: {
    //         'unit-NUTvitaminVitaminfr': 'μg',
    //         'unit-NUTvitaminVitaminSin': 'μg',
    //         'unit-NUTvitaminVitamiAR': 'μg'
    //       },
    //       minerals: {
    //         'unit-NUTminaralMinarelfr': 'μg',
    //         'unit-NUTminaralMinarelAR': 'μg',
    //         'unit-NUTminaralMineralUK': 'μg'
    //       },
    //       other: {
    //         'unit-NUTotherOtherfr': 'μg',
    //         'unit-NUTotherOtherAR': 'μg',
    //         'unit-NUTotherOtherUK': 'dr'
    //       },
    //       commonSupplements: {
    //         'unit-NUTcomm_in_suppCommonfr': 'μg',
    //         'unit-NUTcomm_in_suppCommonAR': 'μg',
    //         'unit-NUTcomm_in_suppCommonUK': 'μg'
    //       },
    //       unit: 2,
    //       unit_type: 'oz',
    //       productId: '91a90972fbc3b4888603ac343503529a'
    //     },
    //     origin_data: {
    //       unit: 2,
    //       unit_type: 'oz',
    //       comment: '',
    //       product_name: ''
    //     }
    //   }
    // };

    const { non_translate } = response.data;

    if (non_translate) {
      if (non_translate.mediaCollectionId) {
        yield put(
          setCollectionId({ CollectionId: non_translate.mediaCollectionId, pageType: 'product' })
        );
      }

      yield put(setProductStore({ store: non_translate.store ? non_translate.store : '' }));
    }

    yield put(updateProductDetailLoadStatus({ data: true }));

    let value;
    let getDefaultUnit = yield call(
      getDefaultUnitWithLocal,
      languagebyLocaleData,
      unitTypeWithLocale,
      response.data,
      value
    );

    if (getDefaultUnit && getDefaultUnit != '' && value) {
      response.data.translatable.unit_type = value;
    }

    yield put(getProductByLocalSuccess({ data: response.data }));
    yield put(getProductID({ id: response.data.non_translate._id }));
    yield put(getBracketprice());

    yield put(updateProductLocale({ data: data.locale }));
  } catch (error) {
    // if (error.response.status === 404) {
    //   yield put(getProductByLocalSuccess({ data: {} }));
    // }
  }
}

function getDefaultUnitWithLocal(languagebyLocaleData, unitTypeWithLocale, data, value) {
  if (languagebyLocaleData && unitTypeWithLocale == 'metric') {
    if (data && data.translatable) {
      if (data.translatable.unit_type && data.translatable.unit_type !== '') {
      } else {
        return (value = 'g');
      }
    }
  }

  if (languagebyLocaleData && unitTypeWithLocale == 'imperial') {
    if (data && data.translatable) {
      if (data.translatable.unit_type && data.translatable.unit_type !== '') {
      } else {
        return (value = 'oz');
      }
    }
  }
}

function* getCategoryList(payload) {
  const dataLocal = payload.payload.data;

  yield put(updateProductDetailLoadStatus({ data: true }));

  yield put(getCategoryListSuccess({ data: [] }));

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: 'prodsup/categories/get'
    });

    let selectLocal = 'en_US';
    if (dataLocal) {
      selectLocal = dataLocal.locale ? dataLocal.locale : 'en_US';
    }

    // let response = {
    //   data: [
    //     {
    //       id: 1669,
    //       title: 'Ärtprotein dd',
    //       title_key: 'Ärtprotein dd',
    //       languages: {
    //         ar: 'dfre',
    //         zh_Hans_CN: 'rrwewewe',
    //         en_US: 'Ärtprotein dd',
    //         sv_SE: 'Ärtprotein'
    //       },
    //       serial: 2668,
    //       position: 53,
    //       status: true,
    //       hierarchyLevel: 1,
    //       parentId: null,
    //       children: [
    //         {
    //           id: 1780,
    //           title: 'tyty',
    //           title_key: 'tyty',
    //           languages: {
    //             en_US: 'tyty'
    //           },
    //           serial: 2779,
    //           position: 3,
    //           status: true,
    //           hierarchyLevel: 2,
    //           parentId: 1669
    //         },
    //         {
    //           id: 1629,
    //           title: 'yuio',
    //           title_key: 'yuio',
    //           languages: {
    //             en_US: 'yuio'
    //           },
    //           serial: 2628,
    //           position: 2,
    //           status: true,
    //           hierarchyLevel: 2,
    //           parentId: 1669,
    //           children: [
    //             {
    //               id: 1782,
    //               title: '65tyt',
    //               title_key: '65tyt',
    //               languages: {
    //                 en_US: '65tyt'
    //               },
    //               serial: 2781,
    //               position: 2,
    //               status: true,
    //               hierarchyLevel: 3,
    //               parentId: 1629
    //             },
    //             {
    //               id: 1781,
    //               title: 'tytyty',
    //               title_key: 'tytyty',
    //               languages: {
    //                 en_US: 'tytyty'
    //               },
    //               serial: 2780,
    //               position: 1,
    //               status: true,
    //               hierarchyLevel: 3,
    //               parentId: 1629,
    //               children: [
    //                 {
    //                   id: 1784,
    //                   title: 'rt',
    //                   title_key: 'rt',
    //                   languages: {
    //                     en_US: 'rt'
    //                   },
    //                   serial: 2783,
    //                   position: 1,
    //                   status: true,
    //                   hierarchyLevel: 4,
    //                   parentId: 1781
    //                 }
    //               ]
    //             }
    //           ]
    //         },
    //         {
    //           id: 1770,
    //           title: 'tyttyt',
    //           title_key: 'tyttyt',
    //           languages: {
    //             en_US: 'tyttyt'
    //           },
    //           serial: 2769,
    //           position: 1,
    //           status: true,
    //           hierarchyLevel: 2,
    //           parentId: 1669,
    //           children: [
    //             {
    //               id: 1772,
    //               title: 'tytyt',
    //               title_key: 'tytyt',
    //               languages: {
    //                 en_US: 'tytyt'
    //               },
    //               serial: 2771,
    //               position: 2,
    //               status: true,
    //               hierarchyLevel: 3,
    //               parentId: 1770,
    //               children: [
    //                 {
    //                   id: 1774,
    //                   title: 'ttyt',
    //                   title_key: 'ttyt',
    //                   languages: {
    //                     ar: 'ewewew',
    //                     en_US: 'ttyt'
    //                   },
    //                   serial: 2773,
    //                   position: 1,
    //                   status: true,
    //                   hierarchyLevel: 4,
    //                   parentId: 1772
    //                 }
    //               ]
    //             },
    //             {
    //               id: 1771,
    //               title: 'ryryt',
    //               title_key: 'ryryt',
    //               languages: {
    //                 zh_Hans_CN: 'eweww',
    //                 en_US: 'ryryt'
    //               },
    //               serial: 2770,
    //               position: 1,
    //               status: true,
    //               hierarchyLevel: 3,
    //               parentId: 1770
    //             }
    //           ]
    //         }
    //       ]
    //     }
    //   ]
    // };

    const workerParamsSetCategory = {
      dataArray: response.data,
      selectLocal: selectLocal
    };

    yield put(
      addJobToQueue({
        workerAction: 'CAT_PRODUCT',
        data: workerParamsSetCategory,
        actionToDispatch: getCategoryWorkerSuccess
      })
    );

    let payloadResponse = yield take('PRODUCT_GET_CATEGORY_WORKER_SUCCESS');

    //console.log(payloadResponse);

    if (payloadResponse.payload.data.result) {
      let sortedArray = payloadResponse.payload.data.result;

      yield put(getCategoryListSuccess({ data: sortedArray }));
      yield delay(100);
      yield put(updateCategoryLoader());
    }
  } catch (error) {
    yield put(updateCategoryLoader());
  }
}

function* getIngredientList(payload) {
  const dataLocal = payload.payload.data;

  yield put(updateIngredientLoader({ loader: true }));

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: 'prodsup/ingredient/get'
    });

    //console.time('ingProcess');
    //console.time('ingProcess1');

    // let response = {
    //   data: [
    //     {
    //       alternativeTitle: {},
    //       hierarchyLevel: 1,
    //       id: 1837,
    //       imageLinkDetails: '',
    //       imageSrc: '',
    //       ingredientLinks: [],
    //       ingredientText: null,
    //       isHealthy: false,
    //       isMayContain: false,
    //       isVegan: true,
    //       languages: { en_US: 'frf' },
    //       parentId: null,
    //       position: 1676,
    //       serial: 2836,
    //       status: true,
    //       title: 'frf',
    //       uplodedImageUrl: null
    //     }
    //   ]
    // };

    let selectLocal = 'en_US';
    if (dataLocal) {
      selectLocal = dataLocal.locale ? dataLocal.locale : 'en_US';
    }

    let defaulLocal = 'en_US';
    let languageList = yield select(state => state.languageList.languageList);
    let profileLang = yield select(state => state.profile.language);

    let profileLocal = 'en_US';
    languageList.filter(item => {
      if (item.id == profileLang) {
        profileLocal = item.locale;
      }
    });

    const workerParamsSetProduct = {
      dataArray: response.data,
      newArray: [],
      passedLocal: selectLocal,
      defaultLocal: defaulLocal
    };

    yield put(
      addJobToQueue({
        workerAction: 'ING_PRODUCT',
        data: workerParamsSetProduct,
        actionToDispatch: getIngArrayWorkerSuccess
      })
    );

    let payloadResponse = yield take('PRODUCT_GET_INGREDIENT_WORKER_SUCCESS');

    //console.log(payloadResponse);

    if (payloadResponse.payload.data.result) {
      let sortedArray = payloadResponse.payload.data.result;

      yield put(getIngredientListSuccess({ data: sortedArray }));
      yield delay(100);
      yield put(updateIngredientLoader({ loader: false }));

      //console.timeEnd('ingProcess');
    }

    const workerParamsMayContain = {
      dataArray: response.data,
      newArray: [],
      passedLocal: profileLocal,
      defaultLocal: defaulLocal
    };

    yield put(
      addJobToQueue({
        workerAction: 'MAY_CONTAIN_PRODUCT',
        data: workerParamsMayContain,
        actionToDispatch: getMayContainWorkerSuccess
      })
    );

    let payloadMayContainResponse = yield take('PRODUCT_GET_MAY_CONTAIN_WORKER_SUCCESS');

    //console.log(payloadMayContainResponse);

    if (payloadMayContainResponse.payload.data.result) {
      let mayContaintArrayPassing = payloadMayContainResponse.payload.data.result;

      yield put(getMayContainValueSuccess({ data: mayContaintArrayPassing }));
      yield put(mayContaintLoader({ loader: false }));
    }

    const workerParams = {
      ingList: response.data,
      locale: 'en_US'
    };

    yield put(
      addJobToQueue({
        workerAction: 'SET_ARRAY_LIST',
        data: workerParams,
        actionToDispatch: getIngredientArrayWorkerSuccess
      })
    );

    const { payload } = yield take('TEXT_EDITOR_GET_INGREDIENT_ARRAY_WORKER_SUCCESS');

    if (payload.data.result) {
      let ingArray = payload.data.result;

      yield put(setIngredientArray({ data: ingArray }));
    }
  } catch (error) {
    yield put(updateIngredientLoader({ loader: false }));
  }
}

function* getEnumberList(payload) {
  const dataLocal = payload.payload.data;

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: 'prodprop/enums/'
    });

    let selectLocal = 'en_US';
    if (dataLocal) {
      selectLocal = dataLocal.locale ? dataLocal.locale : 'en_US';
    }

    let defaulLocal = 'en_US';

    const workerParamsEnumSetProduct = {
      dataArray: response.data,
      newArray: [],
      passedLocal: selectLocal,
      defaultLocal: defaulLocal
    };

    yield put(
      addJobToQueue({
        workerAction: 'ENUMBERS_PRODUCT',
        data: workerParamsEnumSetProduct,
        actionToDispatch: getENumbersWorkerSuccess
      })
    );

    let payloadEnumResponse = yield take('PRODUCT_GET_ENUMBERS_WORKER_SUCCESS');

    if (payloadEnumResponse.payload.data.result) {
      let arrayPassing = payloadEnumResponse.payload.data.result;

      yield put(getEnumberListSuccess({ data: arrayPassing }));
    }

    // let response = {
    //   data: [
    //     {
    //       _id: '9cac8a1cf5f241c460324110a1b95630',
    //       isVegan: false,
    //       notHealthy: false,
    //       eNumber: '20037',
    //       insNumber: '',
    //       uNumber: '',
    //       aNumber: '',
    //       function: 'Food Color',
    //       copyrightText: '',
    //       imageSrcUrl: '',
    //       collectionId: '',
    //       type: 'ENUMS',
    //       position: 123,
    //       translations: {
    //         en_US: {},
    //         ar: {},
    //         zh_Hans_CN: {},
    //         da_DK: {},
    //         en_GB: {},
    //         en_AU: {},
    //         fi_FI: {},
    //         fr_FR: {},
    //         de_DE: {},
    //         he_IL: {},
    //         hi_IN: {},
    //         it_IT: {},
    //         la: {},
    //         nb_NO: {},
    //         ru_RU: {},
    //         si_LK: {},
    //         es_ES: {},
    //         sv_SE: {},
    //         ta_LK: {},
    //         tr_TR: {}
    //       }
    //     },
    //     {
    //       _id: '9cac8a1cf5f241c460324110a1b94dd4',
    //       isVegan: false,
    //       notHealthy: false,
    //       eNumber: 'dssdasdhhjj',
    //       insNumber: '',
    //       uNumber: '',
    //       aNumber: '',
    //       function: 'Food Color',
    //       copyrightText: '',
    //       imageSrcUrl: '',
    //       collectionId: '',
    //       type: 'ENUMS',
    //       position: 122,
    //       translations: {
    //         en_US: {},
    //         ar: {},
    //         zh_Hans_CN: {},
    //         da_DK: {},
    //         en_GB: {},
    //         en_AU: {},
    //         fi_FI: {},
    //         fr_FR: {},
    //         de_DE: {},
    //         he_IL: {},
    //         hi_IN: {},
    //         it_IT: {},
    //         la: {},
    //         nb_NO: {},
    //         ru_RU: {},
    //         si_LK: {},
    //         es_ES: {},
    //         sv_SE: {},
    //         ta_LK: {},
    //         tr_TR: {}
    //       }
    //     },
    //     {
    //       _id: '9cac8a1cf5f241c460324110a1b947f8',
    //       isVegan: false,
    //       notHealthy: false,
    //       eNumber: 'ssd',
    //       insNumber: '',
    //       uNumber: '',
    //       aNumber: '',
    //       function: '',
    //       copyrightText: '',
    //       imageSrcUrl: '',
    //       collectionId: '',
    //       type: 'ENUMS',
    //       position: 121
    //     }
    //   ]
    // };
    //console.log('enums', response.data);

    // let arrayPassing = getEnumberListByLocale(response.data, [], selectLocal, defaulLocal);
    // yield put(getEnumberListSuccess({ data: arrayPassing }));

    const workerParams = {
      enumList: response.data,
      locale: 'en_US'
    };

    yield put(
      addJobToQueue({
        workerAction: 'SET_ENUM_ARRAY_LIST',
        data: workerParams,
        actionToDispatch: getEnumberArrayWorkerSuccess
      })
    );

    let payload1 = yield take('TEXT_EDITOR_GET_ENUMBER_ARRAY_WORKER_SUCCESS');

    if (payload1.payload.data.result) {
      let eNumbersArray = payload1.payload.data.result;

      yield put(setENumbersArray({ data: eNumbersArray }));
    }
  } catch (error) {}
}

function* getStoreList(payload) {
  const dataLocal = payload.payload.data;

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: 'storeres/storedata/list'
    });

    let selectLocal = 'en_US';
    let selectLocalCountry = '6';
    if (dataLocal) {
      selectLocal = dataLocal.locale ? dataLocal.locale : 'en_US';
      selectLocalCountry = dataLocal.localeCountryId ? dataLocal.localeCountryId : '6';
    }

    let defaulLocal = 'en_US';

    const countries = yield select(s => s.indexedItem.countries);
    const selectedStore = yield select(s => s.product.store);

    let arrayPassing = getStoreListByLocale(
      response.data,
      [],
      selectLocal,
      defaulLocal,
      countries,
      selectLocalCountry,
      selectedStore
    );

    let storesTypeArray = yield select(state => state.product.storesTypeArray);

    if (arrayPassing && arrayPassing.length > 0 && storesTypeArray && storesTypeArray.length > 0) {
      arrayPassing.forEach((element, index) => {
        if (element.storeTypeId) {
          let xxx = storesTypeArray.find(
            elementStoreType => elementStoreType._id === element.storeTypeId
          );
          if (xxx) {
            element['store_type_url'] = xxx.imageUrl;
          }
        }
      });
    }

    let sortedArray = arrayPassing.slice().sort(compareStoreNameAndBranch);

    // let sortedArary = [
    //   {
    //     id: '0000',
    //     title: 'None',
    //     branch: '',
    //     mediaCollectionId: '',
    //     imageUrl: ''
    //   },
    //   {
    //     id: 'c6a96ea953763bbdb5001989ad021903',
    //     country: {
    //       id: '3',
    //       name: 'United States',
    //       flag: '🇺🇸',
    //       code: 'US',
    //       dial_code: '+1'
    //     },
    //     storeTypeId: '8c21b993051645aedfed37e8ea06df0c',
    //     title: '1111141B',
    //     branch: '',
    //     mediaCollectionId: '0e7bc70d-05b8-4ca5-bd85-fbb1c51eafb1'
    //   },
    //   {
    //     id: 'ba24e305459d62225c0ed1d8ec00e79a',
    //     country: {
    //       id: '3',
    //       name: 'United States',
    //       flag: '🇺🇸',
    //       code: 'US',
    //       dial_code: '+1'
    //     },
    //     storeTypeId: '8c21b993051645aedfed37e8ea06c398',
    //     title: 'AAAAA - English',
    //     branch: '',
    //     mediaCollectionId: '36b72b40-ab47-46cf-848e-b07264b838a8'
    //   }
    // ];

    yield put(getStoreListSuccess({ data: sortedArray }));
    getStoreDataList(sortedArray);
    yield delay(10);
    yield put(storeDataEnabled());
    yield fork(getStoreimages);
  } catch (error) {}
}

function compareStoreNameAndBranch(a, b) {
  const { title, branch = '' } = a;
  const { title: titleB, branch: branchB = '' } = b;

  const leftString = `${title} ${branch}`.toLowerCase();
  const rightString = `${titleB} ${branchB}`.toLowerCase();

  if (leftString > rightString) {
    return 1;
  } else if (leftString < rightString) {
    return -1;
  }

  return 0;
}

function getStoreListByLocale(
  dataArray,
  newArray,
  passedLocal,
  defaultLocal,
  countryList,
  selectLocalCountry,
  selectedStore
) {
  dataArray.forEach((item, index) => {
    if (item.country == selectLocalCountry) {
      const countrySelected = item.country ? countryList[item.country] : '';

      let objEnumber = {
        id: item._id,
        country: countrySelected,
        storeTypeId: item.storetype ? item.storetype : '',
        title: '',
        branch: '',
        mediaCollectionId: item.mediaCollectionId
      };

      if (item.translations) {
        let languagesArray = item.translations;

        objEnumber.title = getStoreFieldByLocal(languagesArray, passedLocal, defaultLocal, 'name');
        objEnumber.branch = getStoreFieldByLocal(
          languagesArray,
          passedLocal,
          defaultLocal,
          'branch'
        );

        newArray.push(objEnumber);
      } else {
        if (item.title) {
          objEnumber.title = item.title;
        }
        newArray.push(objEnumber);
      }
    }
  });

  return newArray;
}

function getStoreFieldByLocal(languagesArray, passedLocal, defaultLocal, field) {
  let xxx = '';
  if (languagesArray[passedLocal] && languagesArray[passedLocal][field]) {
    xxx = languagesArray[passedLocal][field];
  } else if (languagesArray[defaultLocal] && languagesArray[defaultLocal][field]) {
    xxx = languagesArray[defaultLocal][field];
  } else {
    for (var key in languagesArray) {
      if (languagesArray[key] && languagesArray[key][field]) {
        let firstChild = languagesArray[key][field];
        xxx = firstChild;
        break;
      }
      // object[prop]
    }
  }

  return xxx;
}

function* getAllLabelList() {
  let defaulLocal = 'en_US';
  let languageList = yield select(state => state.languageList.languageList);
  let profileLang = yield select(state => state.profile.language);

  let profileLocal = 'en_US';

  try {
    languageList.filter(item => {
      if (item.id == profileLang) {
        profileLocal = item.locale;
      }
    });

    const { data } = yield call(doRequest, {
      method: 'GET',
      url: 'prodprop/labels/'
    });

    const workerParamsSetLabels = {
      data: data,
      newArray: [],
      profileLocal: profileLocal,
      defaulLocal: defaulLocal
    };

    yield put(
      addJobToQueue({
        workerAction: 'LABEL_PRODUCT',
        data: workerParamsSetLabels,
        actionToDispatch: getLabelsWorkerSuccess
      })
    );

    let payloadResponse = yield take('PRODUCT_GET_LABELS_WORKER_SUCCESS');

    if (payloadResponse.payload.data.result) {
      let LabelsArrayPassing = payloadResponse.payload.data.result;
      yield put(getLabelsListSuccess({ data: LabelsArrayPassing }));
    }

    // let LabelsArrayPassing = getLabelsByProfileLocal(data, [], profileLocal, defaulLocal);
    // let LabelsArrayPassing = [
    //   {
    //     id: '9cac8a1cf5f241c460324110a1b936e8',
    //     title: {
    //       name: ' ආහාර අඩංගු සුදුසුකම්'
    //     },
    //     countries: []
    //   },
    //   {
    //     id: '9cac8a1cf5f241c460324110a1b76444',
    //     title: {
    //       name: 'label en t'
    //     },
    //     countries: []
    //   },
    //   {
    //     id: '9cac8a1cf5f241c460324110a1b732c4',
    //     title: {
    //       name: 'TEST'
    //     },
    //     countries: []
    //   }
    // ];
  } catch (error) {
  } finally {
    yield put(lablesListLoader({ loader: false }));
  }
}

// function getLabelsByProfileLocal(dataArray, newArray, profileLocal, defaulLocal) {
//   dataArray.forEach(item => {
//     let objLabel = {
//       id: item.id,
//       title: '',
//       countries: item.countries ? item.countries : []
//     };

//     if (item.languages) {
//       let languagesArray = item.languages;
//       if (languagesArray[profileLocal]) {
//         objLabel.title = languagesArray[profileLocal];
//         newArray.push(objLabel);
//       } else if (languagesArray[defaulLocal]) {
//         objLabel.title = languagesArray[defaulLocal];
//         newArray.push(objLabel);
//       } else {
//         for (var key in languagesArray) {
//           if (languagesArray[key]) {
//             let firstChild = languagesArray[key];
//             objLabel.title = firstChild;

//             newArray.push(objLabel);
//             break;
//           }
//         }
//       }
//     } else {
//       objLabel.title = item.title;
//       newArray.push(objLabel);
//     }
//   });

//   return newArray;
// }

// function getMayContaintByProfileLocal(dataArray, newArray, profileLocal, defaulLocal) {
//   let mayContaintValArray = dataArray.filter(item => item.isMayContain === true);

//   mayContaintValArray.forEach(item => {
//     let objMayContaint = {
//       id: item.id,
//       title: ''
//     };

//     if (item.languages) {
//       let languagesArray = item.languages;
//       if (languagesArray[profileLocal]) {
//         objMayContaint.title = languagesArray[profileLocal];
//         newArray.push(objMayContaint);
//       } else if (languagesArray[defaulLocal]) {
//         objMayContaint.title = languagesArray[defaulLocal];
//         newArray.push(objMayContaint);
//       } else {
//         for (var key in languagesArray) {
//           if (languagesArray[key]) {
//             let firstChild = languagesArray[key];
//             objMayContaint.title = firstChild;

//             newArray.push(objMayContaint);
//             break;
//           }
//         }
//       }
//     } else {
//       objMayContaint.title = item.title;
//       newArray.push(objMayContaint);
//     }
//   });

//   return newArray;
// }

// function getEnumberListByLocale(dataArray, newArray, passedLocal, defaultLocal) {
//   dataArray.forEach(item => {
//     let objEnumber = {
//       id: item._id,
//       title: '',
//       serial: 'E' + item.eNumber,
//       isVegan: item.isVegan
//     };

//     if (item.translations) {
//       let languagesArray = item.translations;
//       if (languagesArray[passedLocal] && languagesArray[passedLocal].name) {
//         objEnumber.title = languagesArray[passedLocal].name;

//         newArray.push(objEnumber);
//       } else if (languagesArray[defaultLocal] && languagesArray[defaultLocal].name) {
//         objEnumber.title = languagesArray[defaultLocal].name;
//         newArray.push(objEnumber);
//       } else {
//         let firstChild;
//         for (var key in languagesArray) {
//           if (languagesArray[key] && languagesArray[key].name) {
//             firstChild = '';
//             firstChild = languagesArray[key].name;
//             objEnumber.title = firstChild;

//             newArray.push(objEnumber);
//             break;
//           }
//           // object[prop]
//         }
//         if (!firstChild) {
//           newArray.push(objEnumber);
//         }
//       }
//     } else {
//       if (item.title) {
//         objEnumber.title = item.title;
//       }
//       newArray.push(objEnumber);
//     }
//   });

//   return newArray;
// }

// function getIngredientListByLocale(dataArray, newArray, passedLocal, defaultLocal) {
//   dataArray.forEach(item => {
//     let objIngredient = {
//       id: item.id,
//       position: item.position,
//       title: '',
//       ingLocale: 'en_US',
//       isVegan: item.isVegan,
//       alternativeTitle: ''
//     };

//     if (item.alternativeTitle) {
//       objIngredient.alternativeTitle = getAlternativeTitle(passedLocal, item.alternativeTitle);
//     }

//     if (item.languages) {
//       let languagesArray = item.languages;
//       if (languagesArray[passedLocal]) {
//         objIngredient.title = languagesArray[passedLocal];
//         objIngredient.ingLocale = passedLocal;

//         newArray.push(objIngredient);
//       } else if (languagesArray[defaultLocal]) {
//         objIngredient.title = languagesArray[defaultLocal];
//         objIngredient.ingLocale = defaultLocal;
//         newArray.push(objIngredient);
//       } else {
//         for (var key in languagesArray) {
//           if (languagesArray[key]) {
//             let firstChild = languagesArray[key];
//             objIngredient.title = firstChild;
//             objIngredient.ingLocale = key;

//             newArray.push(objIngredient);
//             break;
//           }
//           // object[prop]
//         }
//       }
//     } else {
//       objIngredient.title = item.title;
//       newArray.push(objIngredient);
//     }
//   });

//   return newArray;
// }

// function getUnit(languagesArray, passedLocal, defaultLocal) {
//   let unitsValue = '';
//   if (languagesArray[passedLocal] && languagesArray[passedLocal].units) {
//     unitsValue = languagesArray[passedLocal].units;
//   } else if (languagesArray[defaultLocal] && languagesArray[defaultLocal].units) {
//     unitsValue = languagesArray[defaultLocal].units;
//   } else {
//     for (var key in languagesArray) {
//       if (languagesArray[key] && languagesArray[key].units) {
//         unitsValue = languagesArray[key].units;
//         break;
//       }
//       // object[prop]
//     }
//   }
//   return unitsValue;
// }

// function getScale(languagesArray, passedLocal, defaultLocal) {
//   let scaleValue = '';
//   if (languagesArray[passedLocal] && languagesArray[passedLocal].scale) {
//     scaleValue = languagesArray[passedLocal].scale;
//   } else if (languagesArray[defaultLocal] && languagesArray[defaultLocal].scale) {
//     scaleValue = languagesArray[defaultLocal].scale;
//   } else {
//     for (var key in languagesArray) {
//       if (languagesArray[key] && languagesArray[key].scale) {
//         scaleValue = languagesArray[key].scale;
//         break;
//       }
//       // object[prop]
//     }
//   }
//   return scaleValue;
// }

// function getNutriententListValue(type, dataArray, newArray, passedLocal, defaultLocal) {
//   dataArray.forEach(item => {
//     if (item.languages) {
//       if (type !== 'energy') {
//         if (type === 'comp') {
//           let objNutritioValue = {
//             id: item.id,
//             position: item.position,
//             name: ''
//           };
//           let languagesArray = item.languages;
//           if (languagesArray[passedLocal] && languagesArray[passedLocal].name) {
//             objNutritioValue.name = languagesArray[passedLocal].name;
//             if (languagesArray[passedLocal].measure_standard) {
//               objNutritioValue['measure_standard'] = languagesArray[passedLocal].measure_standard;
//             }

//             newArray.push(objNutritioValue);
//           } else if (languagesArray[defaultLocal] && languagesArray[defaultLocal].name) {
//             objNutritioValue.name = languagesArray[defaultLocal].name;

//             if (languagesArray[defaultLocal].measure_standard) {
//               objNutritioValue['measure_standard'] = languagesArray[defaultLocal].measure_standard;
//             }

//             newArray.push(objNutritioValue);
//           } else {
//             for (var key in languagesArray) {
//               if (languagesArray[key] && languagesArray[key].name) {
//                 let firstChild = languagesArray[key];
//                 objNutritioValue.name = firstChild.name;

//                 if (firstChild.measure_standard) {
//                   objNutritioValue['measure_standard'] = firstChild.measure_standard;
//                 }

//                 newArray.push(objNutritioValue);
//                 break;
//               }
//               // object[prop]
//             }
//           }
//         } else {
//           let objNutritioValue = {
//             id: item.id,
//             position: item.position,
//             name: '',
//             scale: '',
//             units: ''
//           };
//           let languagesArray = item.languages;
//           if (languagesArray[passedLocal] && languagesArray[passedLocal].name) {
//             objNutritioValue.name = languagesArray[passedLocal].name;

//             objNutritioValue.units = getUnit(languagesArray, passedLocal, defaultLocal);
//             // if (languagesArray[passedLocal].units) {
//             //   objNutritioValue.units = languagesArray[passedLocal].units;
//             // }

//             objNutritioValue.scale = getScale(languagesArray, passedLocal, defaultLocal);

//             newArray.push(objNutritioValue);
//           } else if (languagesArray[defaultLocal] && languagesArray[defaultLocal].name) {
//             objNutritioValue.name = languagesArray[defaultLocal].name;

//             objNutritioValue.units = getUnit(languagesArray, passedLocal, defaultLocal);

//             objNutritioValue.scale = getScale(languagesArray, passedLocal, defaultLocal);

//             newArray.push(objNutritioValue);
//           } else {
//             for (var key in languagesArray) {
//               if (languagesArray[key] && languagesArray[key].name) {
//                 let firstChild = languagesArray[key];
//                 objNutritioValue.name = firstChild.name;

//                 objNutritioValue.scale = getScale(languagesArray, passedLocal, defaultLocal);

//                 objNutritioValue.units = getUnit(languagesArray, passedLocal, defaultLocal);

//                 newArray.push(objNutritioValue);
//                 break;
//               }
//               // object[prop]
//             }
//           }
//         }
//       } else {
//         let objNutritioValue = {
//           id: item.id,
//           position: item.position,
//           name: '',
//           name_2: '',
//           name_3: ''
//         };
//         let languagesArray = item.languages;
//         if (languagesArray[passedLocal] && languagesArray[passedLocal].name) {
//           if (languagesArray[passedLocal].name) {
//             objNutritioValue.name = languagesArray[passedLocal].name;
//           }
//           if (languagesArray[passedLocal].name_2) {
//             objNutritioValue.name_2 = languagesArray[passedLocal].name_2;
//           }
//           if (languagesArray[passedLocal].name_3) {
//             objNutritioValue.name_3 = languagesArray[passedLocal].name_3;
//           }

//           newArray.push(objNutritioValue);
//         } else if (languagesArray[defaultLocal] && languagesArray[defaultLocal].name) {
//           if (languagesArray[defaultLocal].name) {
//             objNutritioValue.name = languagesArray[defaultLocal].name;
//           }
//           if (languagesArray[defaultLocal].name_2) {
//             objNutritioValue.name_2 = languagesArray[defaultLocal].name_2;
//           }
//           if (languagesArray[defaultLocal].name_3) {
//             objNutritioValue.name_3 = languagesArray[defaultLocal].name_3;
//           }
//           newArray.push(objNutritioValue);
//         } else {
//           for (var key in languagesArray) {
//             if (languagesArray[key]) {
//               if (languagesArray[key].name) {
//                 objNutritioValue.name = languagesArray[key].name;
//               }
//               if (languagesArray[key].name_2) {
//                 objNutritioValue.name_2 = languagesArray[key].name_2;
//               }
//               if (languagesArray[key].name_3) {
//                 objNutritioValue.name_3 = languagesArray[key].name_3;
//               }

//               newArray.push(objNutritioValue);
//               break;
//             }
//             // object[prop]
//           }
//         }
//       }
//     }
//   });

//   return newArray;
// }

function* getListNutritionValues(payload) {
  const dataLocal = payload.payload.data;

  yield put(updateNutritionLoadedStatus({ data: false }));

  const response = yield call(doRequest, {
    method: 'GET',
    url: 'prodprop/nutritions/'
  });

  let dataResponse = response.data ? response.data : {};
  let selectLocal = 'en_US';
  if (dataLocal) {
    selectLocal = dataLocal.locale ? dataLocal.locale : 'en_US';
  }

  let defaulLocal = 'en_US';

  // let comparison = dataResponse.comp
  //   ? getNutriententListValue('comp', dataResponse.comp, [], selectLocal, defaulLocal)
  //   : [];

  // let energy = dataResponse.energy
  //   ? getNutriententListValue('energy', dataResponse.energy, [], selectLocal, defaulLocal)
  //   : [];

  // let nutritionalValue = dataResponse.nut_val
  //   ? getNutriententListValue(
  //       'nutritionalValue',
  //       dataResponse.nut_val,
  //       [],
  //       selectLocal,
  //       defaulLocal
  //     )
  //   : [];

  // let vitamins = dataResponse.vitamin
  //   ? getNutriententListValue('vitamins', dataResponse.vitamin, [], selectLocal, defaulLocal)
  //   : [];

  // let minerals = dataResponse.minaral
  //   ? getNutriententListValue('minerals', dataResponse.minaral, [], selectLocal, defaulLocal)
  //   : [];

  // let other = dataResponse.other
  //   ? getNutriententListValue('other', dataResponse.other, [], selectLocal, defaulLocal)
  //   : [];

  // let commonSupplements = dataResponse.comm_in_supp
  //   ? getNutriententListValue(
  //       'commonSupplements',
  //       dataResponse.comm_in_supp,
  //       [],
  //       selectLocal,
  //       defaulLocal
  //     )
  //   : [];

  const workerParamsSetNutrition = {
    dataResponse: dataResponse,
    selectLocal: selectLocal,
    defaulLocal: defaulLocal
  };

  yield put(
    addJobToQueue({
      workerAction: 'NUTRITION_PRODUCT',
      data: workerParamsSetNutrition,
      actionToDispatch: getNutritionWorkerSuccess
    })
  );

  let payloadResponse = yield take('PRODUCT_GET_NUTRITION_WORKER_SUCCESS');

  if (payloadResponse.payload.data.result) {
    let passedData = payloadResponse.payload.data.result;

    // yield put(
    //   getNutrientsValueListSuccess({
    //     data: {
    //       comparison: comparison,
    //       energy: energy,
    //       nutritionalValue: nutritionalValue,
    //       vitamins: vitamins,
    //       minerals: minerals,
    //       other: other,
    //       commonSupplements: commonSupplements
    //     }
    //   })
    yield put(
      getNutrientsValueListSuccess({
        data: passedData
      })
    );

    yield put(updateNutritionLoadedStatus({ data: true }));
  }

  // let comparison = [
  //   {
  //     id: 'NUTcompCompFr',
  //     position: 29,
  //     name: 'Comp Fr'
  //   },
  //   {
  //     id: 'NUTcompCompAR',
  //     position: 28,
  //     name: 'Comp ARa'
  //   },
  //   {
  //     id: 'NUTcompCopmUK',
  //     position: 27,
  //     name: 'CopmUK'
  //   }
  // ];

  // let energy = [
  //   {
  //     id: 'NUTenergyEnergyEN',
  //     position: 6,
  //     name: 'Energy ENfhdjfhkdhfkdhfkdhdkhkdhfjhkhfkhkfhjkfhkjdhkjdhfkdhkfhdf',
  //     name_2: '',
  //     name_3: ''
  //   },
  //   {
  //     id: 'NUTenergyEnergyfr',
  //     position: 5,
  //     name: 'Energy fr',
  //     name_2: '',
  //     name_3: ''
  //   },
  //   {
  //     id: 'NUTenergyEnergySin',
  //     position: 4,
  //     name: 'Energy Sin',
  //     name_2: '',
  //     name_3: ''
  //   }
  // ];
  // let nutritionalValue = [
  //   {
  //     id: 'NUTnut_valNutfr',
  //     position: 20,
  //     name: 'Nut fr',
  //     scale: 'metric',
  //     units: 'mg'
  //   },
  //   {
  //     id: 'NUTnut_valNutSin',
  //     position: 19,
  //     name: 'Nut Eng',
  //     scale: 'imperial',
  //     units: 'gr'
  //   },
  //   {
  //     id: 'NUTnut_valNutAR',
  //     position: 18,
  //     name: 'Nut AR',
  //     scale: 'metric',
  //     units: 'μg'
  //   }
  // ];
  // let vitamins = [
  //   {
  //     id: 'NUTvitaminVitaminfr',
  //     position: 21,
  //     name: 'Vitamnin gb',
  //     scale: 'metric',
  //     units: 'μg'
  //   },
  //   {
  //     id: 'NUTvitaminVitaminSin',
  //     position: 20,
  //     name: 'Vitamin Sin',
  //     scale: 'metric',
  //     units: 'μg'
  //   },
  //   {
  //     id: 'NUTvitaminVitamiAR',
  //     position: 19,
  //     name: 'Vitami AR',
  //     scale: 'metric',
  //     units: 'μg'
  //   }
  // ];
  // let minerals = [
  //   {
  //     id: 'NUTminaralMinarelfr',
  //     position: 28,
  //     name: 'Minarel fr',
  //     scale: 'metric',
  //     units: 'μg'
  //   },
  //   {
  //     id: 'NUTminaralMinarelAR',
  //     position: 27,
  //     name: 'Minarel AR',
  //     scale: 'metric',
  //     units: 'μg'
  //   },
  //   {
  //     id: 'NUTminaralMineralUK',
  //     position: 26,
  //     name: 'Mineral UK',
  //     scale: 'metric',
  //     units: 'μg'
  //   }
  // ];

  // let other = [
  //   {
  //     id: 'NUTotherOtherfr',
  //     position: 62,
  //     name: 'Other fr',
  //     scale: 'metric',
  //     units: 'μg'
  //   },
  //   {
  //     id: 'NUTotherOtherAR',
  //     position: 61,
  //     name: 'Other AR',
  //     scale: 'metric',
  //     units: 'μg'
  //   },
  //   {
  //     id: 'NUTotherOtherUK',
  //     position: 60,
  //     name: 'Other UK',
  //     scale: 'imperial',
  //     units: 'dr'
  //   }
  // ];
  // let commonSupplements = [
  //   {
  //     id: 'NUTcomm_in_suppCommonfr',
  //     position: 32,
  //     name: 'Common gb',
  //     scale: 'metric',
  //     units: 'μg'
  //   },
  //   {
  //     id: 'NUTcomm_in_suppCommonAR',
  //     position: 31,
  //     name: 'Common AR',
  //     scale: 'metric',
  //     units: 'μg'
  //   },
  //   {
  //     id: 'NUTcomm_in_suppCommonUK',
  //     position: 30,
  //     name: 'Common UK',
  //     scale: 'metric',
  //     units: 'μg'
  //   }
  // ];

  // yield put(
  //   getNutrientsValueListSuccess({
  //     data: {
  //       comparison: comparison,
  //       energy: energy,
  //       nutritionalValue: nutritionalValue,
  //       vitamins: vitamins,
  //       minerals: minerals,
  //       other: other,
  //       commonSupplements: commonSupplements
  //     }
  //   })
  // );

  // yield put(updateNutritionLoadedStatus({ data: true }));
}

function* getStoreTypeList() {
  try {
    // const { data } = yield call(doRequest, {
    //   method: 'GET',
    //   url: 'storeprop/storeprop'
    // });

    let response = {
      data: {
        accepted_payments: [],
        features: [],
        social_media: [],
        store_type: [],
        mobile_app: []
      }
    };

    const { data } = response;

    let storeTypeArray = [];
    if (data && data.store_type && data.store_type.length > 0) {
      data.store_type.forEach(element => {
        if (element.non_translate) {
          storeTypeArray.push(element.non_translate);
        }
      });
    }

    yield put(
      getStoreTypeListSuccess({
        data: storeTypeArray
      })
    );
    let imageFork;

    if (storeTypeArray && storeTypeArray.length > 0) {
      // imageFork = yield fork(getStoreAllStoreTypeimages, storeTypeArray);
    }
  } catch (error) {}
}

// get image array
function chunk(array, size) {
  const chunked_arr = [];
  let index = 0;
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
}

// get All lable images in module
// function* getStoreAllStoreTypeimages(dataList = []) {
//   const chunkedList = yield call(chunk, dataList, 10);
//   for (let list of chunkedList) {
//     const requests = list.map(item => call(getStoreLableimage, item._id, item.image));

//     const responses = yield all(requests);
//     const filtertedReponse = responses.filter(response => (response ? true : false));

//     const indexes = yield call(getIndexedStoreIds);

//     yield put(setStoreTypeImageSuccess({ image: filtertedReponse, indexes }));

//     let stores = yield select(state => state.product.storesArray);
//     let storesTypeArray = yield select(state => state.product.storesTypeArray);

//     if (stores && stores.length > 0 && storesTypeArray && storesTypeArray.length > 0) {
//       stores.forEach(element => {
//         if (element.storeTypeId) {
//           let xxx = storesTypeArray.find(
//             elementStoreType => elementStoreType._id === element.storeTypeId
//           );
//           if (xxx) {
//             element['store_type_url'] = xxx.imageUrl;
//           }
//         }
//       });
//     }

//     yield put(getStoreListSuccess({ data: stores }));
//     yield delay(2000);
//   }
// }

/**
 *
 * @param {Array} dataList StoreList
 *
 * Retrieve Store Images
 */
function* getStoreimages() {
  const dataList = getOptionArray();

  try {
    const chunkedList = yield call(chunk, dataList, 5);
    for (let list of chunkedList) {
      const requests = list.map(item =>
        item && item.id !== '0000' ? call(getStoreLableimage, item.id, item.mediaCollectionId) : ''
      );

      const responses = yield all(requests);
      const filtertedReponse = responses.filter(response => (response ? true : false));

      const indexes = yield call(getStoreIndex);
      // const getStoreImgArray = getOptionArray();
      // if (dataList && dataList.length > 0) {
      //   ReturnStoreImageUrl(filtertedReponse, indexes, dataList);
      //   console.log('ppppppppppppppp');
      // } else {
      // if (getStoreImgArray && getStoreImgArray.length > 0) {
      // console.log('pppppppppppppppppp');

      ReturnStoreImageUrl(filtertedReponse, indexes);
      // } else {
      //   console.log('lllllllllllllllllllll');

      // }
      // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      // }
      // yield put(setStoreImage({ images: filtertedReponse, indexes }));
      // yield delay(2000);
    }
  } catch (error) {
    console.log(error);
  }
}

// get image indexes
function* getIndexedStoreIds() {
  const items = yield select(state => state.indexedItem.storeType);

  return items;
}

function* getStoreIndex() {
  const items = yield select(state => state.indexedItem.stores);

  return items;
}

//  get avatar image
function* getStoreLableimage(id, collectionId) {
  if (!collectionId) return;

  try {
    const imageResponse = yield call(doRequest, {
      url: `unimup/objctupload/collection/${collectionId}`,
      method: 'GET'
    });

    const { data } = imageResponse;

    if (data.pages.length) {
      let imageUrl = '';
      if (data.pages[0].icon_128) {
        imageUrl = data.pages[0].icon_128.fetchUrl;
      } else {
        imageUrl = data.pages[0].thumbnail.fetchUrl;
      }
      const imageResponse2 = yield call(doRequest, {
        url: `unimup/objctupload${imageUrl}`,
        method: 'GET',
        responseType: 'blob'
      });

      const { data: imageData } = imageResponse2;
      const image = URL.createObjectURL(imageData);
      return { id, image };
    }
  } catch (error) {}
}

// function* getStoreImage(id, medialCollectionId) {
//   if (!medialCollectionId) {
//     return;
//   }

//   try {
//     const imageResponse = yield call(doRequest, {
//       url: `unimup/objctupload/collection/${medialCollectionId}`,
//       method: 'GET'
//     });
//   } catch (error) {}
// }

//get tags list
function* getTagsList(payload) {
  const dataLocal = payload.payload.data;

  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: 'prodprop/tags/list'
    });

    let selectLocal = 'en_US';
    let selectLocalCountry = '6';
    if (dataLocal) {
      selectLocal = dataLocal.locale ? dataLocal.locale : 'en_US';
    }

    let defaulLocal = 'en_US';

    // let arrayPassing = getTagsListByLocale(response.data, [], selectLocal, defaulLocal);
    // let arrayPassing = [
    //   {
    //     _id: '58d37649f2b1179d2e0225a9842b40e9',
    //     image: '54bd7b62-39f7-4b54-a3ba-4ea89c91ac83',
    //     serial: 1333,
    //     title: '0909034a3r3r3'
    //   },
    //   {
    //     _id: '89ed36d84234b7656ff9da0fbd118dfd',
    //     image: '775f80bf-b25d-4fa3-a1f0-729bdd6e1c6c',
    //     serial: 1110,
    //     title: '0909034a3r3r3'
    //   },
    //   {
    //     _id: '1a95a3114fd740bb56681122a5437fdd',
    //     image: 'd9585d35-ea2e-4e20-8e84-9a124c34ae6e',
    //     serial: 1502,
    //     title: '0909034a3r3r3h'
    //   }
    // ];

    const workerParamsTags = {
      dataArray: response.data,
      newArray: [],
      selectLocal: selectLocal,
      defaulLocal: defaulLocal
    };

    yield put(
      addJobToQueue({
        workerAction: 'TAGS_PRODUCT',
        data: workerParamsTags,
        actionToDispatch: getTagsWorkerSuccess
      })
    );

    let payloadTagsResponse = yield take('PRODUCT_GET_TAGS_WORKER_SUCCESS');

    if (payloadTagsResponse.payload.data.result) {
      let arrayPassing = payloadTagsResponse.payload.data.result;
      resetImgUrls();
      yield put(getTagListSuccess({ data: arrayPassing, locale: selectLocal }));
      let imageFork;

      if (arrayPassing && arrayPassing.length > 0) {
        let imageTags = arrayPassing.filter(item => {
          if (item.image) {
            return item;
          }
        });

        imageFork = yield fork(getTagsAllImages, imageTags);
      }
      yield delay(500);

      yield put(tagListEnabled());
    }
  } catch (error) {}
}

// function getTagsListByLocale(dataArray, newArray, passedLocal, defaultLocal) {
//   dataArray.forEach(item => {
//     let objTags = {
//       _id: item._id,
//       image: item.collectionId ? item.collectionId : '',
//       serial: item.serial ? item.serial : '',
//       title: ''
//     };

//     if (item.translations) {
//       let languagesArray = item.translations;

//       objTags.title = getTagsFiledByLocal(languagesArray, passedLocal, defaultLocal, 'name');

//       newArray.push(objTags);
//     } else {
//       if (item.title) {
//         objTags.title = item.title;
//       }
//       newArray.push(objTags);
//     }
//   });

//   return newArray;
// }

function getTagsFiledByLocal(languagesArray, passedLocal, defaultLocal, field) {
  let xxx = '';
  if (languagesArray[passedLocal] && languagesArray[passedLocal][field]) {
    xxx = languagesArray[passedLocal][field];
  } else if (languagesArray[defaultLocal] && languagesArray[defaultLocal][field]) {
    xxx = languagesArray[defaultLocal][field];
  } else {
    for (var key in languagesArray) {
      if (languagesArray[key] && languagesArray[key][field]) {
        let firstChild = languagesArray[key][field];
        xxx = firstChild;
        break;
      }
      // object[prop]
    }
  }

  return xxx;
}

// get All lable images in module
function* getTagsAllImages(dataList = []) {
  const chunkedList = yield call(chunk, dataList, 10);

  for (let list of chunkedList) {
    yield call(getImages, list);
    yield delay(2000);
  }
}

function* getImages(list) {
  const requests = list.map(item => call(getStoreLableimage, item._id, item.image));

  const responses = yield all(requests);
  const filtertedReponse = responses.filter(response => (response ? true : false));

  const indexes = yield call(getIndexedTagsIds);

  const tagsArray = yield select(state => state.product.tagsArray);

  generateTagImagesUrls({ image: filtertedReponse, indexes, tagsArray });
  // yield put(setTagImageSuccess({ image: filtertedReponse, indexes }));
}

// get image indexes
function* getIndexedTagsIds() {
  const items = yield select(state => state.indexedItem.tags);

  return items;
}

function* addProductFun({ payload }) {
  let intl = yield select(state => state.intlReact.intl);
  const { data, history } = payload.data;

  if (data && data.non_translate.date) {
    delete data.non_translate.date;
  }

  try {
    const response = yield call(doRequest, {
      method: 'POST',
      url: 'prodres/products',
      data: data
    });
    if (response.status === 200) {
      message.info(
        intl.formatMessage({
          id: 'Generic.ApiMessages.Products.ProductAddSuccess'
        })
      );
      yield put(getBracketprice());
    }

    delay(1000);
    history.push('/content-management/catalogue/products');
  } catch (error) {
    yield put(updateSaveButtonStatus());
    if (
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.trim() == 'Ean number alrady in use'
    ) {
      message.error(
        intl.formatMessage({
          id: 'Generic.ApiMessages.Products.ProductEANAlreadyUseError'
        })
      );
    } else {
      message.error(
        intl.formatMessage({
          id: 'Generic.ApiMessages.Products.ProductSavingError'
        })
      );
    }
  }
}

function* editProductFun({ payload }) {
  let intl = yield select(state => state.intlReact.intl);

  const { data, pid } = payload.data;
  if (data && data.non_translate.date) {
    // api check date format string and it also check date is required true
    delete data.non_translate.date;
  }

  try {
    const response = yield call(doRequest, {
      method: 'PUT',
      url: 'prodres/products/' + pid,
      data: data
    });

    yield put(setProductStore({ store: data.non_translate.store ? data.non_translate.store : '' }));

    message.info(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Products.ProductEditSuccess'
      })
    );

    yield put(getProductByLocalSuccess({ data: data }));
    yield put(getProductID({ id: pid }));
    yield put(getBracketprice());

    yield put(updateSaveButtonStatus());
  } catch (error) {
    if (
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.trim() == 'Invalied EAN'
    ) {
      message.error(
        intl.formatMessage({
          id: 'Generic.ApiMessages.Products.ProductEANSavingError'
        })
      );
    } else {
      message.error(
        intl.formatMessage({
          id: 'Generic.ApiMessages.Products.ProductSavingError'
        })
      );
    }
    yield put(updateSaveButtonStatus());
  }
}
function* deleteProductFun({ payload }) {
  let intl = yield select(state => state.intlReact.intl);

  const { data } = payload;

  let productList = yield select(s => s.product.productList);

  try {
    const response = yield call(doRequest, {
      method: 'DELETE',
      url: 'prodres/products/' + data.productId
    });
    if (response.status === 200) {
      message.info(
        intl.formatMessage({
          id: 'Generic.ApiMessages.Products.ProductDeleteSuccess'
        })
      );
      let newProductList = productList.filter(item => item._id !== data.productId);
      yield put(deleteProductSuccess({ data: newProductList }));
    }
  } catch (error) {}
}

// get store unic pricess
function* getStorePrice({ payload }) {
  const { productId, storeId } = payload.requestObj;

  // if (!productId && !storeId) return
  try {
    const response = yield call(doRequest, {
      method: 'GET',
      url: `prodres/products/store/${storeId}/product/${productId}`
    });

    yield put(getStoreUnicProductPriceSuccess({ data: response.data }));
  } catch (error) {
    let priceObj = {
      price: '',
      currency: ''
    };
    yield put(getStoreUnicProductPriceSuccess({ data: priceObj }));
  }
}

// get braket text
function* getBrakettextTodisplay() {
  const { productId, clearBracketText } = yield select(state => state.product);
  if (productId) {
    try {
      const response = yield call(doRequest, {
        method: 'GET',
        url: `prodres/products/bracket_price/product/${productId}`,
        shouldCancel: true
      });

      if (clearBracketText && response && response.data && response.data.currency) {
        let element = response.data.currency.split('_');
        let priceObj = {
          price: response.data.price,
          currency: element[0]
        };

        let productUrl = `/content-management/products/${productId}`;

        yield put(getBracketpriceSuccess({ value: priceObj, pId: productUrl }));
      }
    } catch (error) {}
  }
}

/** Watchers */

function* getListWatcher() {
  yield takeLatest(types.GET_LIST_NUTRITION_VALUES, getListNutritionValues);
}

function* getBrakettextTodisplayWatcher() {
  yield takeLatest(types.GET_BRACKET_PRICE, getBrakettextTodisplay);
}

function* getStorePriceWatcher() {
  yield takeLatest(types.GET_STORE_UNIC_PRODUCT_PRICE, getStorePrice);
}

function* getProductListWatcher() {
  yield takeLatest(types.GET_PRODUCT_LIST, getProductList);
}
function* getAllLabelListWatcher() {
  yield takeLatest(types.GET_LABEL_LIST, getAllLabelList);
}
function* addProductListner() {
  yield takeLatest(types.ADD_PRODUCT, addProductFun);
}
function* editProductListner() {
  yield takeLatest(types.EDIT_PRODUCT, editProductFun);
}
function* deleteProductListner() {
  yield takeLatest(types.DELETE_PRODUCT, deleteProductFun);
}

function* getCategoryListWatcher() {
  yield takeLatest(types.GET_CATEGORY_LIST, getCategoryList);
}

function* getStoreListWatcher() {
  yield takeLatest(types.GET_STORE_LIST, getStoreList);
}

function* getTagListWatcher() {
  yield takeLatest(types.GET_TAG_LIST, getTagsList);
}

function* getStoreTypeListWatcher() {
  yield takeLatest(types.GET_STORE_TYPE_LIST, getStoreTypeList);
}

function* getEnumberListWatcher() {
  yield takeLatest(types.GET_ENUMBER_LIST, getEnumberList);
}

function* getIngredientListWatcher() {
  yield takeLatest(types.GET_INGREDIENT_LIST, getIngredientList);
}
function* getProductByLocaleWatcher() {
  yield takeLatest(types.GET_PRODUCT_BY_LOCALE, getProductByLocal);
}

export default function* rootSaga() {
  yield all([
    fork(getListWatcher),
    fork(getProductListWatcher),
    fork(getCategoryListWatcher),
    fork(getIngredientListWatcher),
    fork(getStoreListWatcher),
    fork(getTagListWatcher),
    fork(getStoreTypeListWatcher),
    fork(getEnumberListWatcher),
    fork(addProductListner),
    fork(editProductListner),
    fork(getProductByLocaleWatcher),
    fork(deleteProductListner),
    fork(getAllLabelListWatcher),
    fork(getStorePriceWatcher),
    fork(getBrakettextTodisplayWatcher)
  ]);
}
