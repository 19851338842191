import { message } from 'antd';
import { all, call, cancelled, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { doRequest } from '../../../../shared/redux/sagas/api';
import * as types from './newIngredient.constants';
import * as newIngredientActions from './newIngredient.actions';
import Axios from 'axios';
import { delay } from 'redux-saga';

function* getIntlMessage(id) {
  const intl = yield select(({ intlReact: { intl } }) => intl);
  return intl.formatMessage({ id });
}

function* getCountries() {
  try {
    const response = yield call(doRequest, {
      url: 'prodres/ingredientCountryBlacklist/countries',
      method: 'GET'
    });
    if (response.status === 200) {
      yield put(newIngredientActions.getCountriesSuccess(response.data));
    } else {
      message.error(
        yield getIntlMessage('LeftMenu.System.NewIngredients.SweetAlert.Country_GetError')
      );
    }
  } catch (err) {
    message.error(
      yield getIntlMessage('LeftMenu.System.NewIngredients.SweetAlert.Country_GetError')
    );
  }
}

function* getUserLocale() {
  const [id, languageList] = yield select(s => [s.profile.language, s.languageList.languageList]);
  const language = languageList.find(language => language.id === id);
  return language.locale;
}
function getLocaleBasedTitle(language = {}, keyToExtract, locale) {
  if (!keyToExtract) {
    for (let lang of language) {
      if (lang.language_code.languageCode === locale) {
        return lang.name;
      }
    }

    // If 'en_US' was not found, return the name of the first item
    return language[0].name;
  } else {
    for (let lang of language) {
      if (lang.languageCode === locale) {
        return lang.name;
      }
    }
    return language[0].name;
  }
}
function getIngredientTitle(ingredients, locale) {
  if (ingredients.length > 0) {
    return ingredients.map(ingredient => {
      ingredient.title = ingredient.ingredient_translations
        ? getLocaleBasedTitle(ingredient.ingredient_translations, '', locale)
        : ingredient.title;
      return { ...ingredient };
    });
  } else {
    if (ingredients.ingredient_translations) {
      return getLocaleBasedTitle(ingredients.ingredient_translations, '', locale);
    }
  }
}

function* getIngredients({ payload }) {
  payload.setLoadingCallback(true);
  const locale = yield call(getUserLocale);

  try {
    const offset = 50 * (payload.current - 1);
    const response = yield call(doRequest, {
      url: `prodres/ingredientCountryBlacklist/ingredient/list/${offset}/50`,
      method: 'get'
    });

    if (response.status === 200) {
      let config = {
        total: response.data.count,
        pageSize: 50,
        current: payload.current
      };
      getIngredientTitle(response.data.rows, locale);

      yield put(
        newIngredientActions.getNewIngredientsSuccess({ data: response.data.rows, config: config })
      );
    } else {
      message.error(
        yield getIntlMessage('LeftMenu.System.NewIngredients.SweetAlert.Ingredients_GetError')
      );
    }
  } catch (error) {
    message.error(
      yield getIntlMessage('LeftMenu.System.NewIngredients.SweetAlert.Ingredients_GetError')
    );
  }
  payload.setLoadingCallback(false);
}

function* getNewIngredient({ payload }) {
  payload.setLoadingCallback(true);
  let whitelist = [];
  let blacklist = [];
  try {
    const response = yield call(doRequest, {
      url: `prodres/ingredientCountryBlacklist/${payload.ingId}`,
      method: 'GET'
    });
    if (response.status === 200) {
      yield put(newIngredientActions.getNewIngredientSuccess(response.data));
      response.data.ingredient_country_vegan_statuses.length > 0 &&
        response.data.ingredient_country_vegan_statuses.forEach(ing => {
          if (ing.is_blacklisted === 0) {
            whitelist.push({ id: ing.country.id + '', name: ing.country.name });
          } else if (ing.is_blacklisted === 1) {
            blacklist.push({ id: ing.country.id + '', name: ing.country.name });
          }
        });
      yield put(newIngredientActions.setWhitelistCountries(whitelist));
      yield put(newIngredientActions.setBlacklistCountries(blacklist));
    } else {
      message.error(
        yield getIntlMessage('LeftMenu.System.NewIngredients.SweetAlert.Ingredient_GetError')
      );
    }
  } catch (error) {
    message.error(
      yield getIntlMessage('LeftMenu.System.NewIngredients.SweetAlert.Ingredient_GetError')
    );
  }
  payload.setLoadingCallback(false);
}

function* addToCountryList({ payload }) {
  try {
    const response = yield call(doRequest, {
      url: 'prodres/ingredientCountryBlacklist',
      method: 'POST',
      data: {
        ingredientId: payload.ingId,
        countryId: payload.countryId,
        isBlacklisted: payload.isBlackList
      }
    });
    if (response.status === 200) {
      message.success(
        yield getIntlMessage('LeftMenu.System.NewIngredients.SweetAlert.Country_AddSuccess')
      );

      return;
    } else {
      message.error(
        yield getIntlMessage('LeftMenu.System.NewIngredients.SweetAlert.Country_AddFailed')
      );

      return;
    }
  } catch (error) {
    message.error(
      yield getIntlMessage('LeftMenu.System.NewIngredients.SweetAlert.Country_AddFailed')
    );
  }
}

function* removeFromCountryList({ payload: { ingId, countryId } }) {
  try {
    const response = yield call(doRequest, {
      url: `prodres/ingredientCountryBlacklist/${ingId}/${countryId}`,
      method: 'DELETE'
    });
    if (response.status === 200) {
      message.success(
        yield getIntlMessage('LeftMenu.System.NewIngredients.SweetAlert.Country_RemoveSuccess')
      );

      return;
    } else {
      message.error(
        yield getIntlMessage('LeftMenu.System.NewIngredients.SweetAlert.Country_RemoveFailed')
      );
    }
  } catch (error) {
    message.error(
      yield getIntlMessage('LeftMenu.System.NewIngredients.SweetAlert.Country_RemoveFailed')
    );
  }
}

function* handleLocales({ payload }) {
  let data = yield call(getIngData, payload.id);
  const locale = yield call(getUserLocale);
  yield put(newIngredientActions.getNewIngredientName(getIngredientTitle(data, locale)));
  yield put(
    newIngredientActions.updatedImageUrl(
      data.ingredient_metum ? data.ingredient_metum.uplodedImageUrl : null
    )
  );
  payload.setIsCountryLoading(true);
  const locales = yield select(state => state.languageList.languageList);
  const profileLanguage = yield select(state => state.profile.language);
  let profileLanguageData = {};
  if (locales.some(item => item.id === profileLanguage)) {
    profileLanguageData = locales.find(locale => locale.id === profileLanguage);
  } else {
    profileLanguageData = locales.find(locale => locale.id === '3');
  }

  let filteredData = [];

  const excludedLocales = data.ingredient_translations.map(item => item.language_code.languageCode);

  filteredData = locales.filter(item => {
    const isExcluded = excludedLocales.includes(item.locale);
    return !isExcluded;
  });

  let extractedLocales = filteredData.map(item => ({
    id: item.id,
    country: item.country,
    language: item.language,
    locale: item.locale,
    name: '',
    description: ''
  }));

  data.ingredient_translations.forEach(item => {
    const matchingLocale = locales.find(
      locale => locale.locale === item.language_code.languageCode
    );
    if (matchingLocale) {
      extractedLocales.push({
        id: item.id,
        country: matchingLocale.country,
        language: matchingLocale.language,
        locale: item.language_code.languageCode,
        name: item.name,
        description: item.description ? item.description : ''
      });
    }
  });
  console.log('extractedLocales before', extractedLocales);
  const defaultLanguage = extractedLocales.filter(
    item => item.locale === profileLanguageData.locale
  );
  extractedLocales = extractedLocales.filter(item => item.locale !== profileLanguageData.locale);
  defaultLanguage[0].options = {
    vegan: data.veganStatus ? (data.veganStatus = 0 ? false : true) : false,
    mayContain: data.ingredient_metum ? data.ingredient_metum.isMayContain : false,
    notHealthy: data.ingredient_metum ? data.ingredient_metum.isHealthy : false
  };
  defaultLanguage[0].ingId = data.id;
  defaultLanguage[0].uploadedImageUrl = data.ingredient_metum
    ? data.ingredient_metum.uplodedImageUrl
    : '';

  extractedLocales.sort((a, b) => a.language.localeCompare(b.language));
  yield delay(1000);
  yield put(
    newIngredientActions.setNewLocales({
      defaultLanguage: defaultLanguage[0],
      filteredData: extractedLocales,
      defaultUserLocale: profileLanguageData.locale
    })
  );

  yield put(
    newIngredientActions.updatedImageUrl(
      data.ingredient_metum ? data.ingredient_metum.uplodedImageUrl : null
    )
  );

  yield put(
    newIngredientActions.getImage(
      `unimup/objctupload${data.ingredient_metum ? data.ingredient_metum.uplodedImageUrl : ''}`
    )
  );
  yield put(newIngredientActions.setSelectedValues(data));
  payload.setIsCountryLoading(false);
}

function* getIngData(ingId) {
  try {
    const response = yield call(doRequest, {
      url: `prodres/ingredients/${parseInt(ingId)}`,
      method: 'GET'
    });
    console.log('res', response);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log('Get error');
    }
  } catch (error) {
    console.log(error);
  }
}

//image update

function* updateIngredientImage({ payload }) {
  let intl = yield select(state => state.intlReact.intl);
  let selectedValue = yield select(state => state.newIngredient.selectedValue);
  console.log('upload image payload', payload);
  try {
    const { id, attributes, image } = payload;

    const data = new FormData();
    data.append('media', image);

    if (attributes.uploadedImageUrl && attributes.uploadedImageUrl.includes('ings')) {
      const url = `unimup/objctupload${attributes.uploadedImageUrl}`;
      const response = yield call(doRequest, {
        url,
        method: 'PATCH',
        data
      });
      // yield put(updateIngredientImagePatchSuccess());
      if (response.status === 200) {
        const { fetchUrl } = response.data;
        selectedValue.ingredient_metum
          ? (selectedValue.ingredient_metum = {
              isHealthy: selectedValue.ingredient_metum.isHealthy,
              isMayContain: selectedValue.ingredient_metum.isMayContain,
              uplodedImageUrl: fetchUrl
            })
          : (selectedValue.ingredient_metum = {
              isHealthy: false,
              isMayContain: false,
              uplodedImageUrl: fetchUrl
            });
        yield call(doRequest, {
          method: 'PUT',
          url: `prodres/ingredients/${selectedValue.id}`,
          data: {
            ingredient_metum: selectedValue.ingredient_metum,
            ingredient_translations: selectedValue.ingredient_translations.map(item => ({
              id: item.id,
              languageCode: item.language_code.languageCode,
              name: item.name,
              description: item.description
            })),
            veganStatus: selectedValue.veganStatus ? 1 : 0
          }
        });
        yield put(newIngredientActions.updatedImageUrl(fetchUrl));
        let mesageImageUpload = intl.formatMessage({
          id: 'Generic.ApiMessages.Ingredient.IngredientImageUpdated'
        });
        message.info(mesageImageUpload);
      }
    } else {
      const url = `unimup/objctupload/ings/${selectedValue.id}`;

      const response = yield call(doRequest, {
        url: url,
        method: 'POST',
        data: data
      });

      if (response.status === 200) {
        const { fetchUrl } = response.data;
        selectedValue.ingredient_metum
          ? (selectedValue.ingredient_metum = {
              isHealthy: selectedValue.ingredient_metum.isHealthy,
              isMayContain: selectedValue.ingredient_metum.isMayContain,
              uplodedImageUrl: fetchUrl
            })
          : (selectedValue.ingredient_metum = {
              isHealthy: false,
              isMayContain: false,
              uplodedImageUrl: fetchUrl
            });

        console.log('selectedValue format', selectedValue);
        yield call(doRequest, {
          method: 'PUT',
          url: `prodres/ingredients/${id}`,
          data: {
            ingredient_metum: selectedValue.ingredient_metum,
            ingredient_translations: selectedValue.ingredient_translations.map(item => ({
              id: item.id,
              languageCode: item.language_code.languageCode,
              name: item.name,
              description: item.description
            })),
            veganStatus: selectedValue.veganStatus ? 1 : 0
          }
        });
        yield put(newIngredientActions.updatedImageUrl(fetchUrl));

        let mesageImageUpload = intl.formatMessage({
          id: 'Generic.ApiMessages.Ingredient.IngredientImageUpdated'
        });
        message.info(mesageImageUpload);
      }
    }
  } catch (error) {
    let mesageImageUploadFileSize = intl.formatMessage({
      id: 'Generic.ApiMessages.FileSizeExceed'
    });
    if (error.response && error.response.status === 413) {
      message.error(mesageImageUploadFileSize);
    } else {
      if (error.response && error.response.data.error && error.response.data.error.message)
        message.error(error.response.data.error.message);
    }

    // yield put(updateIngredientImageError());
  }
  // if (payload.cb) {
  //   payload.cb();
  // }
}

//getImage
function* ingredientImageGet({ payload }) {
  const cancelSource = Axios.CancelToken.source();
  yield put(newIngredientActions.setImageLoader());
  try {
    if (payload === null || typeof payload === 'undefined') return;

    if (!payload.includes('ings')) {
      // return yield put(getIngredientImageSuccess('', false));
    }
    const response = yield call(doRequest, {
      url: payload,
      method: 'GET',
      responseType: 'blob',
      cancelToken: cancelSource.token
    });

    console.log('image response', response);

    // let imageLoader = true;

    if (response.status === 200) {
      if (response.data) {
        let image = URL.createObjectURL(response.data);
        console.log('image', image);
        // yield put(getIngredientImageSuccess(image, imageLoader));
        // let newRandomKeyValue = yield select(state => state.ingredients.randomNumberLastIngredient);

        // setTimeout(() => {
        //   if (newRandomKeyValue && newRandomKeyValue.randomNumb) {
        //     const element = document.getElementById(newRandomKeyValue.randomNumb.toString());
        //     if (element) {
        //       element.focus();
        //     }
        //   }
        // }, 100);
        yield put(newIngredientActions.getImageSuccess(image));
      }
    }
  } catch (error) {
    yield put(newIngredientActions.getImageFail());
    // yield put(getIngredientImageSuccess('', false));
    // let newRandomKeyValue = yield select(state => state.ingredients.randomNumberLastIngredient);
    // setTimeout(() => {
    //   if (newRandomKeyValue && newRandomKeyValue.randomNumb) {
    //     const element = document.getElementById(newRandomKeyValue.randomNumb.toString());
    //     if (element) {
    //       element.focus();
    //     }
    //   }
    // }, 100);
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

//deleteIngredient

function* deleteIngredientImage({ payload }) {
  let intl = yield select(state => state.intlReact.intl);
  let selectedValue = yield select(state => state.newIngredient.selectedValue);
  let updatedImageUrl = yield select(state => state.newIngredient.updatedImageUrl);

  console.log('uploadedImageUrl', selectedValue.ingredient_metum.uplodedImageUrl);
  try {
    const { uploadedImageUrl, cb } = payload;
    const url = 'unimup/objctupload' + updatedImageUrl;
    yield call(doRequest, {
      method: 'DELETE',
      url: `${url}`
    });

    selectedValue.ingredient_metum.uplodedImageUrl = null;

    yield call(doRequest, {
      method: 'PUT',
      url: `prodres/ingredients/${selectedValue.id}`,
      data: {
        ingredient_metum: {
          isHealthy: selectedValue.ingredient_metum.isHealthy,
          isMayContain: selectedValue.ingredient_metum.isMayContain,
          uplodedImageUrl: null
        },
        ingredient_translations: selectedValue.ingredient_translations.map(item => ({
          id: item.id,
          languageCode: item.language_code.languageCode,
          name: item.name,
          description: item.description
        })),
        veganStatus: selectedValue.veganStatus ? 1 : 0
      }
    });

    if (cb) cb();
    yield put(newIngredientActions.deleteImageSuccess());
    yield put(newIngredientActions.updatedImageUrl(null));
    let mesageImageDelete = intl.formatMessage({
      id: 'Generic.ApiMessages.Ingredient.IngredientImageDeleted'
    });
    message.info(mesageImageDelete);
    // yield put(deleteIngredientImageSuccess());
  } catch (error) {
    message.info(
      intl.formatMessage({
        id: 'Image delete fail'
      })
    );
  }
}

//update ingredient

function* updateIngredient({ payload }) {
  let intl = yield select(state => state.intlReact.intl);
  const locale = yield call(getUserLocale);

  const { data, id } = payload;

  try {
    const response = yield call(doRequest, {
      url: `prodres/ingredients/${parseInt(id)}`,
      method: 'put',
      data
    });
    if (response.status === 200) {
      message.info(
        intl.formatMessage({
          id: 'Ingredient Updated'
        })
      );
      yield put(
        newIngredientActions.getNewIngredientName(getIngredientTitle(response.data, locale))
      );
      yield put(newIngredientActions.setSelectedValues(response.data));

      yield call(getNewIngredient, parseInt(id));
    }
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 400) {
        if (error.response.data.errorType) {
          message.error(
            intl.formatMessage({
              id: 'Please include at least one translation'
            })
          );
        } else {
          const translationsWithErrors = error.response.data.ingredient_translations.filter(
            translation => translation.error
          );
          const errorMessages = translationsWithErrors.map(translation => ({
            languageCode: translation.languageCode,
            errorMessage: translation.error
          }));
          console.log('errorMessages', errorMessages);
          errorMessages.map(errorMessage => {
            message.error(
              intl.formatMessage({
                id: errorMessage.languageCode + ' ' + errorMessage.errorMessage
              })
            );
          });
        }
        console.log('error.response.status', error.response);
      }
    }
  }
}

//Delete Ingredient

function* deleteNewIngredient({ payload }) {
  let intl = yield select(state => state.intlReact.intl);
  const { id, current, setLoadingCallback } = payload;
  try {
    console.log('deleteIngredient');
    const response = yield call(doRequest, {
      url: `prodres/ingredients/${parseInt(id)}`,
      method: 'DELETE'
    });
    if (response.status === 200) {
      yield put(newIngredientActions.getNewIngredients({ current, setLoadingCallback }));

      message.info(
        intl.formatMessage({
          id: 'Ingredient Deleted'
        })
      );
    }
  } catch (error) {
    message.error(error.response.data.msg + ':' + error.response.data.count);
  }
}

//add new ingredient

function* addNewIngredientFieldsSet() {
  const locales = yield select(state => state.languageList.languageList);
  const profileLanguage = yield select(state => state.profile.language);
  let profileLanguageData = {};

  let extractedLocales = locales.map(item => ({
    id: item.id,
    country: item.country,
    language: item.language,
    locale: item.locale,
    name: '',
    description: ''
  }));

  if (extractedLocales.some(item => item.id === profileLanguage)) {
    profileLanguageData = extractedLocales.find(locale => locale.id === profileLanguage);
  } else {
    profileLanguageData = extractedLocales.find(locale => locale.id === '3');
  }
  extractedLocales = extractedLocales.filter(item => item.id !== profileLanguageData.id);

  console.log(extractedLocales, profileLanguageData);
}

function* getCountriesListener() {
  yield takeLatest(types.GET_COUNTRIES, getCountries);
}
function* getIngredientsListener() {
  yield takeLatest(types.GET_NEW_INGREDIENTS, getIngredients);
}

function* getIngredientListener() {
  yield takeLatest(types.GET_NEW_INGREDIENT, getNewIngredient);
}

function* addToCountryListListener() {
  yield takeEvery(types.ADD_TO_LIST, addToCountryList);
}

function* removeFromCountryListener() {
  yield takeEvery(types.REMOVE_FROM_COUNTRY_LIST, removeFromCountryList);
}

function* handleLocalesListener() {
  yield takeLatest(types.GET_LOCALE_LIST, handleLocales);
}

function* updateIngredientImageListener() {
  yield takeLatest(types.UPDATE_IMAGE, updateIngredientImage);
}

function* updateIngredientListener() {
  yield takeLatest(types.UPDATE_INGREDIENT, updateIngredient);
}
function* ingredientImageGetListener() {
  yield takeLatest(types.GET_IMAGE, ingredientImageGet);
}

function* ingredientImageDeleteListener() {
  yield takeLatest(types.DELETE_IMAGE, deleteIngredientImage);
}

function* newIngredientDeleteListener() {
  yield takeLatest(types.DELETE_NEW_INGREDIENT, deleteNewIngredient);
}

function* addNewIngredientFieldsSetListener() {
  yield takeLatest(types.SET_NEW_INGREDIENT_FIELDS, addNewIngredientFieldsSet);
}
export default function* rootSaga() {
  yield all([
    fork(getCountriesListener),
    fork(getIngredientsListener),
    fork(getIngredientListener),
    fork(addToCountryListListener),
    fork(removeFromCountryListener),
    fork(handleLocalesListener),
    fork(updateIngredientImageListener),
    fork(updateIngredientListener),
    fork(ingredientImageGetListener),
    fork(ingredientImageDeleteListener),
    fork(newIngredientDeleteListener),
    fork(addNewIngredientFieldsSetListener)
  ]);
}
