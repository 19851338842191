import adsTypes from './list.constants';

const initialState = {
  dataSource: [],
  tableLoader: true,
  sort: { order: 'asc', columnKey: 'week' },
  pagination: { total: 0, pageSize: 50, current: 1 }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case adsTypes.GET_DATA_LIST: {
      return { ...state, tableLoader: true };
    }

    case adsTypes.GET_DATA_LIST_SUCCESS: {
      const { dataList, pagination, sort } = action.payload;

      return {
        ...state,
        dataSource: dataList,
        pagination: { ...pagination },
        sort: { ...sort },
        tableLoader: false
      };
    }

    case adsTypes.GET_DATA_LIST_FAIL: {
      return { ...state, tableLoader: false };
    }

    case adsTypes.SET_DATA_LIST_ADS_IMAGE_SUCCESS: {
      const {
        payload: { image, indexes }
      } = action;

      let data = state.dataSource;
      for (let ProductImage of image) {
        const itemIndex = indexes[ProductImage.id];
        if (itemIndex || itemIndex === 0) {
          const item = data[itemIndex];
          const clonedItem = { ...item };
          if (clonedItem && clonedItem._id) {
            clonedItem.ads_image = ProductImage.image;
            data = [...data.slice(0, itemIndex), clonedItem, ...data.slice(itemIndex + 1)];
          }
        }
      }

      return {
        ...state,
        dataSource: [...data]
      };
    }
    case adsTypes.DELETE_ADS: {
      return {
        ...state,
        tableLoader: true
      };
    }

    case adsTypes.DELETE_ADS_FAIL: {
      return {
        ...state,
        tableLoader: false
      };
    }

    default:
      return state;
  }
};
