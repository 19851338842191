import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_TOKEN
} from 'constants/ActionTypes';

import { getToken } from '../../app/shared/utils/storage';
import { FORGET_PASSWORD_USER_EMAIL, FORGET_PASSWORD_USER_EMAIL_RESET, OTP_SENT_FAIL, OTP_SENT_SUCCESS } from '../../constants/ActionTypes';

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: getToken(),
  email:'',
  otpVerification:false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      };
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      };
    }
    case FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        loader: false,
        reset_token: true
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loader: false,
        reset_token: false
      };
    }
    case RESET_PASSWORD_TOKEN: {
      return {
        ...state,
        reset_token: false
      };
    }

    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '/',
        loader: false
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      };
    }

    case SIGNIN_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      };
    }
    case SIGNIN_FACEBOOK_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      };
    }
    case SIGNIN_TWITTER_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      };
    }
    case SIGNIN_GITHUB_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      };
    }
    case FORGET_PASSWORD_USER_EMAIL: {
      return {
        ...state,
        email:action.payload
      }
    }
    case FORGET_PASSWORD_USER_EMAIL_RESET: {
      return {
        ...state,
        email:''
      }
    }
    case OTP_SENT_SUCCESS: {
      return{
        ...state,
        otpVerification:true
      }
    }
    case OTP_SENT_FAIL: {
      return {
        ...state,
        otpVerification:false
      }
    }
    default:
      return state;
  }
};
