import { actionCreator } from 'redux-action-creator';
import types from './nutrition-values.constants';

export const getNutrientsList = actionCreator(types.GET_LIST);
export const getNutrientsListFail = actionCreator(types.GET_LIST_FAIL);
export const getNutrientsListSuccess = actionCreator(types.GET_LIST_SUCCESS, 'data');
export const addNewNode = actionCreator(types.ADD_NODE, 'key');
export const addNewNodeSuccess = actionCreator(types.ADD_NODE_SUCCESS, 'key');
export const saveNewNode = actionCreator(types.SAVE_NODE, 'key', 'values', 'newNode', 'cb');
export const saveNewNodeSuccess = actionCreator(
  types.SAVE_NODE_SUCCESS,
  'key',
  'values',
  'newNode'
);
export const saveNewNodeFail = actionCreator(types.SAVE_NODE_FAIL);
export const updateSortOrder = actionCreator(
  types.UPDATE_ORDER,
  'key',
  'values',
  'oldIndex',
  'newIndex'
);
export const updateSortOrderSuccess = actionCreator(types.UPDATE_ORDER_SUCCESS, 'key', 'values');
export const updateSortOrderFail = actionCreator(types.UPDATE_ORDER_FAIL);
export const setSelectedNode = actionCreator(types.SET_SELECTED_NODE, 'id', 'type');
export const setSelectedNodeSuccess = actionCreator(
  types.SET_SELECTED_NODE_SUCCESS,
  'id',
  'type',
  'selectedData'
);
export const updateSaveButtonState = actionCreator(types.UPDATE_BUTTON_STATE, 'btnState');
export const saveChanges = actionCreator(types.SAVE_CHANGES);
export const saveChangesSuccess = actionCreator(types.SAVE_CHANGES_SUCCESS, 'form');
export const saveChangesFail = actionCreator(types.SAVE_CHANGES_FAIL);
export const searchNutrition = actionCreator(types.SEARCH, 'key');
export const searchNutritionSuccess = actionCreator(types.SEARCH_SUCCESS, 'data');
export const deleteNode = actionCreator(types.DELETE_NODE, 'type', 'id');
export const deleteNodeSuccess = actionCreator(types.DELETE_NODE_SUCCESS, 'type', 'id');
export const deleteNodeSuccessFail = actionCreator(types.DELETE_NODE_FAIL, 'type', 'id');
export const updateFormData = actionCreator(types.UPDATE_FORMDATA, 'key', 'formData');
export const updateSaveModal = actionCreator(types.UPDATE_SAVE_MODAL, 'state');
export const saveChangesAndSwitch = actionCreator(types.SAVE_CHANGES_AND_SWITCH);
export const cancelChangesAndSwitch = actionCreator(types.CANCEL_CHANGES_AND_SWITCH);
export const cancelChangesAndStay = actionCreator(types.CANCEL_CHANGES_AND_STAY);
export const resetNutrientsState = actionCreator(types.RESET_NUTRIENTS_STATE);
