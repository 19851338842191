import SearchWorker from './search.worker';

export function createSearchWorker() {
  if (!window.Worker) {
    return console.error(
      "Worker is not supported in this browser, You can't use the search functionality in some modules"
    );
  }

  const searchWorker = new SearchWorker();
  return searchWorker;
}
