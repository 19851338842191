import antd from "antd/lib/locale-provider/en_US";
import appLocaleData from "react-intl/locale-data/en";

let latinLocale = {...appLocaleData};
latinLocale['locale'] = 'la'

let laAntd = {...antd};
laAntd['locale'] = 'la'

const LaLang = {
  antd,
  locale: 'la',
  data: latinLocale
};
export default LaLang;
