import { actionCreator } from 'redux-action-creator';
import { tagsTypes } from './tagModal.constants';

export const updateTagsModal = actionCreator(tagsTypes.UPDATE_MODAL_STATE, 'state');
export const saveTagsDetails = actionCreator(tagsTypes.SAVE_DETAILS, 'form', 'cb');
export const manuallyTagAdd = actionCreator(tagsTypes.ADD_TAG_MANUALLY, 'newAddedValue');
export const getTagsListFail = actionCreator(tagsTypes.GET_LIST_FAIL);
export const saveTagsDetailsFail = actionCreator(tagsTypes.SAVE_DETAILS_FAIL);
export const editTags = actionCreator(tagsTypes.EDIT, 'id');
export const editTagsSuccess = actionCreator(
  tagsTypes.EDIT_SUCCESS,
  'data',
  'id',
  'image',
  'alternatives'
);
export const editTagsFail = actionCreator(tagsTypes.EDIT_FAIL);
export const showTagsLoader = actionCreator(tagsTypes.SHOW_LOADER);
export const hideTagsLoader = actionCreator(tagsTypes.HIDE_LOADER);
export const selectTagsImage = actionCreator(tagsTypes.SET_TEMP_IMG, 'img', 'file');
export const removeSelectedTagsImage = actionCreator(tagsTypes.REMOVE_TEMP_IMG);
export const saveTagsImage = actionCreator(tagsTypes.SAVE_IMG);
export const saveTagsImageSuccess = actionCreator(tagsTypes.SAVE_IMG_SUCCESS, 'img');
export const saveTagsImageFail = actionCreator(tagsTypes.SAVE_IMG_FAIL);
export const removeTagsImage = actionCreator(tagsTypes.REMOVE_IMG);
export const removeTagsImageSuccess = actionCreator(tagsTypes.REMOVE_IMG_SUCCESS);
export const removeTagsImageFail = actionCreator(tagsTypes.REMOVE_IMG_FAIL);
export const setInitialTitle = actionCreator(tagsTypes.SET_INITIAL_TITLE, 'title', 'manufacturer');
export const removeInitialTitle = actionCreator(tagsTypes.REMOVE_INITIAL_TITLE);
export const setCollectionId = actionCreator(tagsTypes.SET_COLLECTION_ID, 'id');
export const openCatalogueModal = actionCreator(tagsTypes.OPEN_CATALOGUE);
export const reset = actionCreator(tagsTypes.RESET);
export const detectFormChanges = actionCreator(tagsTypes.DETECT_FORM_CHANGES, 'changes');
export const getEditedDataObj = actionCreator(tagsTypes.GET_EDITED_OBJ, 'obj', 'formValue');
export const updateAlternativeLisr = actionCreator(
  tagsTypes.UPDATE_ALTERNATIVE_LIST,
  'alternatives'
);
export const duplicateTagResponse = payload => ({
  type: tagsTypes.DUPLICATE_TAG_RESPONSE,
  payload: payload
});
