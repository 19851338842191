import { tagsTypes } from './tagModal.constants';

const initialState = {
  list: [],
  id: '',
  isTagsModalOpen: false,
  tagsEditForm: null,
  isEditingTagsForm: false,
  loading: false,
  image: null,
  originalImage: null,
  imageBinary: null,
  collectionId: null,
  initialTitle: '',
  initialTags: '',
  changeFormValues: null,
  detectChanges: false,
  editTagDataObj: null,
  defaultFormValue: false,
  alternatives: null,
  duplicateTitles: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      if (action.payload.pathname.includes('/content-management/products')) {
        return initialState;
      } else {
        return state;
      }
    }

    case tagsTypes.UPDATE_MODAL_STATE: {
      const { state: modalState } = action.payload;
      if (modalState === 'open') {
        return {
          ...state,
          isTagsModalOpen: true
        };
      } else {
        return {
          ...state,
          isTagsModalOpen: false,
          isEditingTagsForm: false,
          image: null,
          originalImage: null,
          imageBinary: null,
          id: '',
          collectionId: null,
          tagsEditForm: null,
          duplicateTitles: []
        };
      }
    }

    case tagsTypes.DETECT_FORM_CHANGES: {
      const { changes } = action.payload;
      return {
        ...state,
        detectChanges: changes
      };
    }

    case tagsTypes.EDIT_SUCCESS: {
      const { data, id, image, alternatives } = action.payload;

      return {
        ...state,
        tagsEditForm: data,
        isEditingTagsForm: true,
        id: id || '',
        image: image,
        originalImage: image,
        collectionId: data.collectionId,
        alternatives: alternatives
      };
    }

    case tagsTypes.UPDATE_ALTERNATIVE_LIST: {
      const { alternatives } = action.payload;

      return {
        ...state,
        alternatives: alternatives
      };
    }

    case tagsTypes.GET_EDITED_OBJ: {
      const { obj, formValue } = action.payload;

      return {
        ...state,
        editTagDataObj: obj,
        defaultFormValue: formValue
      };
    }

    case tagsTypes.ADD_TAG_MANUALLY: {
      const { newAddedValue } = action.payload;
      return {
        ...state,
        changeFormValues: newAddedValue
      };
    }

    case tagsTypes.SHOW_LOADER: {
      return {
        ...state,
        loading: true
      };
    }

    case tagsTypes.HIDE_LOADER: {
      return {
        ...state,
        loading: false
      };
    }

    case tagsTypes.SET_TEMP_IMG: {
      return {
        ...state,
        image: action.payload.img,
        imageBinary: action.payload.file
      };
    }

    case tagsTypes.REMOVE_TEMP_IMG: {
      return {
        ...state,
        image: state.originalImage,
        imageBinary: null
      };
    }

    case tagsTypes.SAVE_IMG_FAIL: {
      return {
        ...state,
        image: state.originalImage,
        imageBinary: null
      };
    }

    case tagsTypes.SAVE_IMG_SUCCESS: {
      // let newList = state.list;
      // let tagsEditForm = state.tagsEditForm;

      // // update list with new image url
      // if (action.payload.item) {
      //   const item = action.payload.item;
      //   let list = state.list;

      //   const id = item._id;
      //   const itemIndex = list.findIndex(item => item._id === id);
      //   newList = [...list.slice(0, itemIndex), item, ...list.slice(itemIndex + 1)];
      // } else if ( action.payload.item) {
      //   const form = action.payload.item;
      //   tagsEditForm = form;
      // }
      const { img } = action.payload;
      return {
        ...state,
        image: img,
        originalImage: img,
        imageBinary: null
      };
    }

    case tagsTypes.REMOVE_IMG_SUCCESS: {
      return {
        ...state,
        image: null,
        originalImage: null
      };
    }

    case tagsTypes.SET_INITIAL_TITLE: {
      return {
        ...state,
        initialTitle: action.payload.title,
        initialTags: action.payload.manufacturer
      };
    }

    case tagsTypes.REMOVE_INITIAL_TITLE: {
      return {
        ...state,
        initialTitle: '',
        initialTags: ''
      };
    }

    case tagsTypes.SET_COLLECTION_ID: {
      return {
        ...state,
        collectionId: action.payload.id
      };
    }

    case tagsTypes.RESET: {
      return initialState;
    }
    case tagsTypes.DUPLICATE_TAG_RESPONSE: {
      return {
        ...state,
        duplicateTitles: action.payload.duplicateTitles ? action.payload.duplicateTitles : []
      };
    }
    default:
      return state;
  }
}
