import * as types from './newIngredient.constants';

export const getNewIngredients = payload => {
  return {
    type: types.GET_NEW_INGREDIENTS,
    payload
  };
};

export const getNewIngredientsSuccess = payload => {
  return {
    type: types.GET_NEW_INGREDIENTS_SUCCESS,
    payload
  };
};

export const getNewIngredient = payload => {
  return {
    type: types.GET_NEW_INGREDIENT,
    payload
  };
};

export const getNewIngredientSuccess = payload => {
  return {
    type: types.GET_NEW_INGREDIENT_SUCCESS,
    payload
  };
};

export const clearNewIngredient = () => {
  return {
    type: types.CLEAR_NEW_INGREDIENT
  };
};

export const addToList = payload => {
  return {
    type: types.ADD_TO_LIST,
    payload
  };
};

export const removeFromCountryList = payload => {
  return {
    type: types.REMOVE_FROM_COUNTRY_LIST,
    payload
  };
};

export const getCountries = () => {
  return {
    type: types.GET_COUNTRIES
  };
};

export const getCountriesSuccess = payload => {
  return {
    type: types.GET_COUNTRIES_SUCCESS,
    payload
  };
};

export const addToWhitelistCountries = payload => {
  return {
    type: types.ADD_TO_WHITELIST_COUNTRIES,
    payload
  };
};

export const removeFromWhitelistCountries = payload => {
  return {
    type: types.REMOVE_FROM_WHITELIST_COUNTRIES,
    payload
  };
};

export const cleanWhitelistCountries = payload => {
  return {
    type: types.CLEAR_WHITELIST_COUNTRIES
  };
};

export const addToBlacklistCountries = payload => {
  return {
    type: types.ADD_TO_BLACKLIST_COUNTRIES,
    payload
  };
};

export const removeFromBlacklistCountries = payload => {
  return {
    type: types.REMOVE_FROM_BLACKLIST_COUNTRIES,
    payload
  };
};

export const cleanBlacklistCountries = payload => {
  return {
    type: types.CLEAR_BLACKLIST_COUNTRIES
  };
};

export const setBlacklistCountries = payload => {
  return {
    type: types.SET_BLACKLIST_COUNTRIES,
    payload
  };
};

export const setWhitelistCountries = payload => {
  return {
    type: types.SET_WHITELIST_COUNTRIES,
    payload
  };
};

export const resetPagination = () => {
  return {
    type: types.RESET_PAGINATION
  };
};

export const getLocaleList = payload => {
  return {
    type: types.GET_LOCALE_LIST,
    payload
  };
};

export const setNewLocales = payload => {
  return {
    type: types.SET_LOCALE_LIST,
    payload
  };
};

export const setDefaultLocale = payload => {
  return {
    type: types.SET_DEFAULT_LOCALE,
    payload
  };
};

export const setSelectedValues = payload => {
  return {
    type: types.SET_SELECTED_VALUES,
    payload
  };
};

//UPDATE IMAGE URL
export const getImage = payload => {
  return {
    type: types.GET_IMAGE,
    payload
  };
};

export const getImageSuccess = payload => {
  return {
    type: types.GET_IMAGE_SUCCESS,
    payload
  };
};

export const getImageFail = () => {
  return {
    type: types.GET_IMAGE_FAIL
  };
};

export const setImageLoader = () => {
  return {
    type: types.SET_IMAGE_LOADER
  };
};

export const resetImageLoader = () => {
  return {
    type: types.RESET_IMAGE_LOADER
  };
};

export const addTempImage = payload => {
  return {
    type: types.ADD_TEMP_IMAGE,
    payload
  };
};

export const removeTempImage = () => {
  return {
    type: types.REMOVE_TEMP_IMAGE
  };
};

export const updateImage = payload => {
  return {
    type: types.UPDATE_IMAGE,
    payload
  };
};

export const updateImageSuccess = () => {
  return {
    type: types.UPDATE_IMAGE_SUCCESS
  };
};

export const updateImageFail = () => {
  return {
    type: types.UPDATE_IMAGE_FAIL
  };
};

export const updateIngredientAction = payload => {
  return {
    type: types.UPDATE_INGREDIENT,
    payload
  };
};

export const updatedImageUrl = payload => {
  return {
    type: types.SET_UPDATED_IMAGE_URL,
    payload
  };
};

export const clearImages = () => {
  return {
    type: types.CLEAR_IMAGES
  };
};

export const deleteImage = payload => {
  return {
    type: types.DELETE_IMAGE,
    payload
  };
};

export const deleteImageSuccess = () => {
  return {
    type: types.DELETE_IMAGE_SUCCESS
  };
};

export const newIngredientDelete = payload => {
  return {
    type: types.DELETE_NEW_INGREDIENT,
    payload
  };
};

export const getNewIngredientName = payload => {
  return {
    type: types.SET_NEW_INGREDIENT_NAME,
    payload
  };
};

export const setNewIngredientFields = () => {
  return {
    type: types.SET_NEW_INGREDIENT_FIELDS
  };
};
