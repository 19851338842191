export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const HANDLE_BLUR_CATEGORY = 'HANDLE_BLUR_CATEGORY';
export const UPDATE_CATEGORIES_ORDER = 'UPDATE_CATEGORIES_ORDER';
export const UPDATE_CATEGORIES_ORDER_SUCCESS = 'UPDATE_CATEGORIES_ORDER_SUCCESS';
export const HANDLE_BLUR_CATEGORY_SUCCESS = 'HANDLE_BLUR_CATEGORY_SUCCESS';
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
export const GET_CATEGORY_LANGUAGES = 'GET_CATEGORY_LANGUAGES';
export const GET_CATEGORY_LANGUAGES_SUCCESS = 'GET_CATEGORY_LANGUAGES_SUCCESS';
export const IDENTIFY_BTN_RED = 'IDENTIFY_BTN_RED';
export const IDENTIFY_BTN_WITHOUT_RED = 'IDENTIFY_BTN_WITHOUT_RED';
export const SHOW_ALERT_WINDOW = 'SHOW_ALERT_WINDOW';
export const HIDE_ALERT_WINDOW = 'HIDE_ALERT_WINDOW';
export const TREE_SELECTED_KEY = 'TREE_SELECTED_KEY';
export const NEXT_TREE_SELECTED_KEY = 'NEXT_TREE_SELECTED_KEY';
export const SAVE_TREE_CATEGORY_VALUE = 'SAVE_TREE_CATEGORY_VALUE';
export const SAVE_TREE_CATEGORY_VALUE_SUCCESS = 'SAVE_TREE_CATEGORY_VALUE_SUCCESS';
export const CANCEL_TREE_CATEGORY_VALUE = 'CANCEL_TREE_CATEGORY_VALUE';
export const CANCEL_TREE_CATEGORY_VALUE_SUCCESS = 'CANCEL_TREE_CATEGORY_VALUE_SUCCESS';
export const RESET_CATEGORY_VALUES = 'RESET_CATEGORY_VALUES';
export const PLUS_BTN_CLICK = 'CT_PLUS_BTN_CLICK';
export const GET_DEFUALT_LOCAL_SUCCESS = 'GET_DEFUALT_LOCAL_SUCCESS';
export const UPDATE_CATEGORY_FORM_DETAILS = 'UPDATE_CATEGORY_FORM_DETAILS';
export const UPDATE_CATEGORY_FORM_DETAILS_SUCCESS = 'UPDATE_CATEGORY_FORM_DETAILS_SUCCESS';
export const UPDATE_SAVE_CATEGORY_LOADER = 'UPDATE_SAVE_CATEGORY_LOADER';
export const DELETE_NODE_IDENTIFY = 'DELETE_NODE_IDENTIFY';
export const IDENTIFY_GET_CATEGORY_DATA_LOADER = 'IDENTIFY_GET_CATEGORY_DATA_LOADER';
export const SET_LAST_GENERATED_CATEGORY_RANDOM_NUMBER =
  'SET_LAST_GENERATED_CATEGORY_RANDOM_NUMBER';
export const RE_UPDATE_SAVE_CATEGORY_DETAILS = 'RE_UPDATE_SAVE_CATEGORY_DETAILS';
export const UPDATE_CATEGORY__TREE = 'UPDATE_CATEGORY__TREE';
export const SET_SEARCH_STRING = 'SET_SEARCH_STRING';
export const ADD_EXPAND_KEY = 'ADD_EXPAND_KEY';
export const REMOVE_EXPAND_KEY = 'REMOVE_EXPAND_KEY';
export const SET_EXPAND_KEY = 'SET_EXPAND_KEY';
export const REMOVE_EXPAND_KEY_SUCCESS = 'REMOVE_EXPAND_KEY_SUCCESS';
export const SET_DRAG_ENABLE = 'SET_DRAG_ENABLE';
export const CAT_SEARCH = 'CAT_SEARCH';
export const CAT_SPIN_LOAD = 'CAT_SPIN_LOAD';
export const SET_CATEGORY_TITLE_TRANSLATION = 'SET_CATEGORY_TITLE_TRANSLATION';
export const SEARCH_WITH_DELETE_CATEGORY = 'SEARCH_WITH_DELETE_CATEGORY';
export const ADD_NODE_BTN_DISABLED = 'ADD_NODE_BTN_DISABLED';
export const RESET_TO_INITIAL_STATE = 'CAT_RESET_TO_INIITAL_STATE';
export const UPDATE_CATEGORY_LIST_DETAILS = "UPDATE_CATEGORY_LIST_DETAILS";
