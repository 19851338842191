import { SET_REACT_INTL } from '../actions/intlReact.actions';

const initialState = {
  intl: null
};

const intelReactReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REACT_INTL:
      return {
        ...state,
        intl: action.payload
      };

    default:
      return state;
  }
};

export default intelReactReducer;
