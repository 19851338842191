import * as types from './categoryCircle.constants'
const initialState = {
    categories:[]
}

export default (state=initialState, {type, payload}) => {
    switch(type){
        case types.GET_CATEGORIES_CIRCLES_SUCCESS:{
            return{
                ...state,
                categories:payload
            }
        }
        case type.CATEGORIES_CIRCLES_POSITION_CHANGE_SUCCESS:{
            return{
                ...state,
                categories:payload
            }
        }
        default:
            return state
    }

}