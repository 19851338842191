import { call, takeEvery, all, fork, put, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as imageActions from './imageUploader.constants';
import {
  setImageLIst,
  imageUploadSuccess,
  imageUploadLoader,
  setnewImageUrl
} from './imageUploader.actions';
import { doRequest } from './../../../../../shared/redux/sagas/api';
import { message } from 'antd';
import {
  returnProcessToken,
  cancelClick
} from './../../../../../shared/redux/sagas/productImageRequest';

function* getTheImageLIst(payload) {
  // const collectionData = payload.payload.collectionData.collectionData;
  console.log('IMAGE UPLOAD MODERATION SAGA', payload ? payload.payload : '');
  // console.log('IMAGE UPLOAD MODERATION SAGA', payload.payload.collectionData.processToken);
  cancelClick(false);
  let payloadTokenId = null;
  let payloadTokenType = null;
  let payloadclickedPath = null;
  let collectionData = null;
  if (payload && payload.payload && payload.payload.collectionData) {
    collectionData = payload.payload.collectionData;
  }
  if (payload && payload.payload && payload.payload.collectionData) {
    payloadTokenId = payload.payload.collectionData.processToken.tokenId;
    payloadTokenType = payload.payload.collectionData.processToken.tokenType;
    if (payload.payload.collectionData.location && payload.payload.collectionData.location.props) {
      payloadclickedPath = payload.payload.collectionData.location.props.location.pathname;
    }
    // else if (
    //   payload.payload.collectionData.location &&
    //   payload.payload.collectionData.location.location
    // ) {
    //   payloadclickedPath = payload.payload.collectionData.location.location.pathname;
    // }
  }
  console.log(payload.payload, 'collectionData CAllDATA', collectionData.collectionData);
  if (collectionData) {
    let callData = collectionData.collectionData;

    if (callData) {
      //callData.productCollectionId = 'ec9897c2-f7d1-48da-8cfe-83e863976bd6';

      let fileList = [];
      let tempObj = {};
      let response;
      let response1;
      let response2;

      try {
        if (callData && callData.ticketCollectionId) {
          response = yield call(doRequest, {
            method: 'GET',
            url: `/unimup/objctupload/collection/${callData.ticketCollectionId}`,
            shouldCancel: true
          });

          let imageUrls = imageList(response.data.pages);

          for (var i = 0; i < 3; i++) {
            const moderationEditPageIsMount = yield select(
              state => state.moderation.moderationImageList.moderationEditPageIsMount
            );
            if (moderationEditPageIsMount && imageUrls[i]) {
              tempObj = {};
              response1 = yield call(doRequest, {
                method: 'GET',
                url: `/unimup/objctupload${imageUrls[i].tempUrl}`,
                responseType: 'blob',
                shouldCancel: true
              });

              let image = URL.createObjectURL(response1.data);
              tempObj = {
                uid: `${imageUrls[i].pageId}`,
                collectionId: `${callData.ticketCollectionId}`,
                url: image,
                active: 0,
                position: `${fileList.length}`,
                type: response1.data ? response1.data.type : '',
                className: 'MobileThumb'
              };
              fileList.push(tempObj);
            } else {
              break;
            }
          }
        }

        if (callData && callData.productCollectionId) {
          response = yield call(doRequest, {
            method: 'GET',
            url: `/unimup/objctupload/collection/${callData.productCollectionId}`,
            shouldCancel: true
          });

          let imageUrls = imageList(response.data.pages);

          for (var m = 0; m < imageUrls.length; m++) {
            const moderationEditPageIsMount = yield select(
              state => state.moderation.moderationImageList.moderationEditPageIsMount
            );
            if (moderationEditPageIsMount) {
              tempObj = {};
              response2 = yield call(doRequest, {
                method: 'GET',
                url: `/unimup/objctupload${imageUrls[m].tempUrl}`,
                responseType: 'blob',
                shouldCancel: true
              });

              let image = URL.createObjectURL(response2.data);
              tempObj = {
                uid: `${imageUrls[m].pageId}`,
                collectionId: `${callData.productCollectionId}`,
                url: image,
                active: imageUrls[m].active,
                position: `${fileList.length}`,
                type: response2.data ? response2.data.type : ''
              };
              fileList.push(tempObj);
            } else {
              break;
            }
          }
        }

        if ((response1 && response1.status === 200) || (response2 && response2.status === 200)) {
          const moderationEditPageIsMount = yield select(
            state => state.moderation.moderationImageList.moderationEditPageIsMount
          );
          const processToken = returnProcessToken();
          console.log(payloadclickedPath, 'processToken.clickedPath', processToken.clickedPath);
          console.log(payloadTokenId, 'processToken.tokenId', processToken.tokenId);
          if (moderationEditPageIsMount) {
            if (
              processToken &&
              processToken.clickedPath === payloadclickedPath &&
              processToken.tokenId === payloadTokenId
              // payloadTokenId &&
              // payloadTokenId === processToken.tokenId
            ) {
              yield put(setImageLIst(fileList));
            }
          }
        }
      } catch (error) {
        return false;
      }
    }
  }
}
function* imageDelete(payload) {
  let intl = yield select(state => state.intlReact.intl);

  let CollectionId = yield select(state => state.moderation.moderationImageList);

  // let response;
  // if (payload.payload.pageType === 'store') {
  // } else {
  //   response = yield call(doRequest, {
  //     method: 'DELETE',
  //     url: `/unimup/objctupload/collection/${CollectionId}/${payload.payload.imageId}`
  //   });
  // }

  message.info(
    intl.formatMessage({
      id: 'Generic.ApiMessages.Products.ImageDeleteSuccess'
    })
  );

  yield put(setImageLIst(payload.payload.imageList));
}

function* imageUploadFunc(payload) {
  let intl = yield select(state => state.intlReact.intl);
  let fileList = payload.payload.imageList;
  let tempObj = {};
  const data = new FormData();
  data.append('media', payload.payload.singleImage);

  try {
    let response;
    if (payload.payload.CollectionId && payload.payload.CollectionId != '') {
      if (payload.payload.pageType === 'store11') {
      } else {
        response = yield call(doRequest, {
          method: 'PUT',
          url: `/unimup/objctupload/collection/` + payload.payload.CollectionId,
          data: data
        });
      }
    } else {
      if (payload.payload.pageType === 'store11') {
      } else {
        response = yield call(doRequest, {
          method: 'POST',
          url: `/unimup/objctupload/collection/`,
          data: data
        });
      }
    }
    if (response.status === 200) {
      let response1;
      if (payload.payload.pageType === 'store11') {
      } else {
        response1 = yield call(doRequest, {
          method: 'GET',
          url: `/unimup/objctupload/collectionitem/${response.data.PageId}`,
          responseType: 'blob'
        });
      }
      if (response1.status === 200) {
        if (payload.payload.pageType === 'store11') {
          message.info(
            intl.formatMessage({
              id: 'Generic.ApiMessages.Store.ImageUploadSuccess'
            })
          );
        } else {
          message.info(
            intl.formatMessage({
              id: 'Generic.ApiMessages.Products.ImageUploadSuccess'
            })
          );
        }
        tempObj = {};
        let image = URL.createObjectURL(response1.data);
        tempObj = {
          uid: `${response.data.PageId}`,
          url: image,
          active: true,
          position: `${fileList.length}`,
          type: response1.data ? response1.data.type : ''
        };
        fileList.push(tempObj);
        yield put(imageUploadSuccess(response.data.CollectionId, fileList));
        yield put(setnewImageUrl(image, response.data.PageId));
        yield put(imageUploadLoader({ loader: false }));
      }
    }
  } catch (error) {
    console.log(error);
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Products.ImageUploadError'
      })
    );

    yield put(imageUploadLoader({ loader: false }));
    return false;
  }
}
function* imageSwapPositionFunc(payload) {
  let intl = yield select(state => state.intlReact.intl);
  let CollectionId = yield select(state => state.moderation.moderationImageList.CollectionId);
  try {
    let response;
    // if (payload.payload.pageType === 'store') {
    // } else {
    //   response = yield call(doRequest, {
    //     method: 'PUT',
    //     url: `/unimup/objctupload/collection/${CollectionId}/${payload.payload.currentPosition}/${payload.payload.movePosition}`
    //   });
    // }

    console.log(payload);

    message.info(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Products.ImageMoved'
      })
    );

    yield put(setImageLIst(payload.payload.imageList));
  } catch (error) {
    console.log(error);
    return false;
  }
}
function* imageActivateFunc(payload) {
  let intl = yield select(state => state.intlReact.intl);
  let active = 1;
  let CollectionId = yield select(state => state.moderation.moderationImageList.CollectionId);
  // if (payload.payload.activateImage) {
  //   active = 1;
  // } else {
  //   active = 0;
  // }

  message.info(
    intl.formatMessage({
      id: 'Generic.ApiMessages.Products.ImageStatusChanged'
    })
  );
  yield put(setImageLIst(payload.payload.imageList));
}

function* getImageListListener() {
  yield takeEvery(imageActions.MODERATION_GET_IMAGE_LIST, getTheImageLIst);
}
function* imageDeleteListener() {
  yield takeEvery(imageActions.MODERATION_DELETE_IMAGE, imageDelete);
}
function* imageUploadListener() {
  yield takeEvery(imageActions.MODERATION_UPLOAD_IMAGE, payload => imageUploadFunc(payload));
}
function* imagePositionUpdateListener() {
  yield takeEvery(imageActions.MODERATION_IMAGE_POSITION_UPDATE, imageSwapPositionFunc);
}
function* imageActivateListener() {
  yield takeEvery(imageActions.MODERATION_IMAGE_ACTIVATE, imageActivateFunc);
}

export default function* rootSaga() {
  yield all([
    fork(getImageListListener),
    fork(imageDeleteListener),
    fork(imageUploadListener),
    fork(imagePositionUpdateListener),
    fork(imageActivateListener)
  ]);
}
function imageList(array) {
  let newArr = [];
  let tempObj = {};
  if (array) {
    for (var i = 0; i < array.length; i++) {
      tempObj = {};

      tempObj = {
        tempUrl: array[i].optimized.fetchUrl,
        pageId: array[i].pageId,
        active: array[i].active
      };
      // newArr.push(array[i].optimized.fetchUrl)
      newArr.push(tempObj);
    }
  }

  return newArr;
}
