export default function normalizeTree(treeList, defaultLocalCode, expandedKeys) {
  const normalizedData = [];
  loop(treeList, normalizedData, defaultLocalCode, expandedKeys);
  return normalizedData;
}

function loop(treeList, normalizedData, defaultLocalCode, expandedKeys) {
  treeList.forEach(tree => {
    let keyInLocale = ' ';
    if (tree.title) {
      let nodeConcatValue = '';
      if (tree.languages) {
        keyInLocale = tree.languages[defaultLocalCode];
        if (!keyInLocale) {
          keyInLocale = tree.languages['en_US'];
        }

        if (!keyInLocale) {
          const localeValues = Object.values(tree.languages);

          keyInLocale = localeValues.find(val => {
            if (val) {
              return true;
            }
            return false;
          });

          keyInLocale = keyInLocale || '';
        }

        nodeConcatValue = keyInLocale;
      } else {
        nodeConcatValue = tree.title ? tree.title : ' ';
      }

      let hasKey = expandedKeys && expandedKeys.find(item => item === tree.id);

      tree['expanded'] = hasKey ? true : false;

      normalizedData.push({
        //title: nodeConcatValue.toLowerCase(),
        title: nodeConcatValue,
        id: tree.id,
        serial: tree.serial,
        parentId: tree.parentId ? tree.parentId : 0,
        isCircle: tree.isCircle,
        show_in_mobile_home:tree.show_in_mobile_home,
        icon_base64_string:tree.icon_base64_string

      });
      if (tree.children) loop(tree.children, normalizedData, defaultLocalCode, expandedKeys);
    }
  });
}
