import {
  call,
  takeEvery,
  all,
  fork,
  put,
  select,
  race,
  take,
  cancel,
  cancelled
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as imageActions from '../constant/imageUploader.constants';
import {
  setImageLIst,
  imageUploadSuccess,
  imageUploadLoader,
  setnewImageUrl
} from '../actions/imageUploader.actions';
import { doRequest } from './api';
import { message } from 'antd';

import { returnProcessToken, cancelClick } from './productImageRequest';
function* getTheImageLIst(payload) {
  console.log('payloadpayloadpayloadpayloadpayloadpayload', payload);

  let CollectionId = yield select(state => state.imageUploader.CollectionId);
  let fileList = [];
  let response;
  let response1;
  let productImageFork;
  try {
    productImageFork = yield fork(
      getProductImageList,
      CollectionId,
      response,
      response1,
      fileList,
      payload
    );

    const { reset } = yield race({
      get: take(imageActions.GET_IMAGE_LIST),
      reset: take(imageActions.RESET_IMAGES)
    });

    if (reset) {
      yield cancel(productImageFork);
    }
    const processToken = returnProcessToken();
    if (processToken.cancelBtnClick) {
      console.log('CANCEL BTN CLICK YIELD TAKE');
      yield cancel(productImageFork);
      cancelClick(false);
    }
  } catch (error) {
    console.log(error);
    return false;
  } finally {
    // cancel ongoing requests if new row list is fetched
    // if (yield cancelled()) {
    //   if (productImageFork) {
    //     yield cancel(productImageFork);
    //   }
    // }
  }
}

function* getProductImageList(CollectionId, response, response1, fileList, payload) {
  // const cancelClick = returnCancelClick();
  // changeCancelClick;
  cancelClick(false);
  let payloadTokenId = null;
  let payloadTokenType = null;
  let payloadclickedPath = null;
  let responseFirstImage;
  if (
    payload &&
    payload.payload &&
    payload.payload.processToken &&
    payload.payload.processToken.processToken
  ) {
    payloadTokenId = payload.payload.processToken.processToken.tokenId;
    payloadTokenType = payload.payload.processToken.processToken.tokenType;
    payloadclickedPath = payload.payload.processToken.location.props.location.pathname;
  }
  if (CollectionId && CollectionId != '') {
    response = yield call(doRequest, {
      method: 'GET',
      url: `/unimup/objctupload/collection/${CollectionId}`,
      shouldCancel: true
    });

    let imageUrls = imageList(response && response.data.pages);

    if (imageUrls.length > 0) {
      if (imageUrls[0]) {
        responseFirstImage = yield call(doRequest, {
          method: 'GET',
          url: `/unimup/objctupload${imageUrls[0].optimizedUrl}`,
          responseType: 'blob',
          shouldCancel: true
        });
      }
    }

    for (var i = 0; i < imageUrls.length; i++) {
      let userListPageIsMount = yield select(state => state.imageUploader.imageUploadPageIsMount);

      const processToken = returnProcessToken();
      // if (
      //   processToken &&
      //   processToken.tokenId &&
      //   payloadTokenId &&
      //   payloadTokenId !== processToken.tokenId
      // ) {
      //   break;
      // }
      if (userListPageIsMount) {
        let tempObj = {};
        if (imageUrls[i]) {
          response1 = yield call(doRequest, {
            method: 'GET',
            url: `/unimup/objctupload${imageUrls[i].thumbnailUrl}`,
            responseType: 'blob',
            shouldCancel: true
          });

          if (response1) {
            let image = URL.createObjectURL(response1.data);
            tempObj = {
              uid: `${imageUrls[i].pageId}`,
              thumbnailUrl: `${imageUrls[i].thumbnailUrl}`,
              optimizedUrl: `${imageUrls[i].optimizedUrl}`,
              thumbnailImage: image,
              active: imageUrls[i].active,
              position: `${i}`,
              type: response1.data ? response1.data.type : ''
            };
            fileList.push(tempObj);
          }
        }
      } else {
        break;
      }
    }
  }

  if (CollectionId === '') {
    fileList = [];
  }

  if (responseFirstImage && responseFirstImage.status === 200) {
    let imageFirst = URL.createObjectURL(responseFirstImage.data);
    fileList[0]['optimizedImage'] = imageFirst;
  }

  if (response1 && response1.status === 200) {
    const processToken = returnProcessToken();
    if (payloadclickedPath !== '/content-management/products/new') {
      if (
        processToken &&
        processToken.clickedPath === payloadclickedPath &&
        processToken.tokenId === payloadTokenId
        // payloadTokenId &&
        // payloadTokenId === processToken.tokenId
      ) {
        yield put(setImageLIst(fileList));
      }
    }
  }
}

function* imageDelete(payload) {
  let intl = yield select(state => state.intlReact.intl);

  let CollectionId = yield select(state => state.imageUploader.CollectionId);
  try {
    let response;
    if (payload.payload.pageType === 'store') {
    } else {
      response = yield call(doRequest, {
        method: 'DELETE',
        url: `/unimup/objctupload/collection/${CollectionId}/${payload.payload.imageId}`
      });
    }

    if (response && response.status === 200) {
      if (payload.payload.pageType === 'store') {
        message.info(
          intl.formatMessage({
            id: 'Generic.ApiMessages.Products.ImageDeleteSuccess'
          })
        );
      } else {
        message.info(
          intl.formatMessage({
            id: 'Generic.ApiMessages.Products.ImageDeleteSuccess'
          })
        );
      }
      yield put(setImageLIst(payload.payload.imageList));
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

function* imageUploadFunc(payload) {
  let intl = yield select(state => state.intlReact.intl);
  let fileList = payload.payload.imageList;
  let tempObj = {};
  const data = new FormData();
  data.append('media', payload.payload.singleImage);

  try {
    let response;
    if (payload.payload.CollectionId && payload.payload.CollectionId != '') {
      if (payload.payload.pageType === 'store11') {
      } else {
        response = yield call(doRequest, {
          method: 'PUT',
          url: `/unimup/objctupload/collection/` + payload.payload.CollectionId,
          data: data
        });
      }
    } else {
      if (payload.payload.pageType === 'store11') {
      } else {
        response = yield call(doRequest, {
          method: 'POST',
          url: `/unimup/objctupload/collection/`,
          data: data
        });
      }
    }
    if (response && response.status === 200) {
      let response1;
      if (payload.payload.pageType === 'store11') {
      } else {
        response1 = yield call(doRequest, {
          method: 'GET',
          url: `/unimup/objctupload/collectionitem/${response.data.PageId}`,
          responseType: 'blob'
        });
      }
      if (response1 && response1.status === 200) {
        if (payload.payload.pageType === 'store11') {
          message.info(
            intl.formatMessage({
              id: 'Generic.ApiMessages.Store.ImageUploadSuccess'
            })
          );
        } else {
          message.info(
            intl.formatMessage({
              id: 'Generic.ApiMessages.Products.ImageUploadSuccess'
            })
          );
        }
        tempObj = {};
        let image = URL.createObjectURL(response1.data);
        tempObj = {
          uid: `${response.data.PageId}`,
          thumbnailImage: image,
          optimizedImage: image,
          active: true,
          position: `${fileList.length}`,
          type: response1.data ? response1.data.type : ''
        };
        fileList.push(tempObj);
        yield put(imageUploadSuccess(response.data.CollectionId, fileList));
        yield put(setnewImageUrl(image, response.data.PageId));
        yield put(imageUploadLoader({ loader: false }));
      }
    }
  } catch (error) {
    console.log(error);
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Products.ImageUploadError'
      })
    );

    yield put(imageUploadLoader({ loader: false }));
    return false;
  }
}
function* imageSwapPositionFunc(payload) {
  let intl = yield select(state => state.intlReact.intl);
  let CollectionId = yield select(state => state.imageUploader.CollectionId);
  try {
    let response;
    if (payload.payload.pageType === 'store') {
    } else {
      response = yield call(doRequest, {
        method: 'PUT',
        url: `/unimup/objctupload/collection/${CollectionId}/${payload.payload.currentPosition}/${payload.payload.movePosition}`
      });
    }
    if (response && response.status === 200) {
      if (payload.payload.pageType === 'store') {
        message.info(
          intl.formatMessage({
            id: 'Generic.ApiMessages.Products.ImageMoved'
          })
        );
      } else {
        message.info(
          intl.formatMessage({
            id: 'Generic.ApiMessages.Products.ImageMoved'
          })
        );
      }
      yield put(setImageLIst(payload.payload.imageList));
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}
function* imageActivateFunc(payload) {
  let intl = yield select(state => state.intlReact.intl);
  let active = 1;
  let CollectionId = yield select(state => state.imageUploader.CollectionId);
  if (payload.payload.activateImage) {
    active = 1;
  } else {
    active = 0;
  }
  try {
    let response;
    if (payload.payload.pageType === 'store') {
    } else {
      response = yield call(doRequest, {
        method: 'PATCH',
        url: `/unimup/objctupload/collection/${CollectionId}/${payload.payload.preImgUID}/${active}`
      });
    }
    if (response && response.status === 200) {
      message.info(
        intl.formatMessage({
          id: 'Generic.ApiMessages.Products.ImageStatusChanged'
        })
      );
      yield put(setImageLIst(payload.payload.imageList));
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}
function* getClickedImageFunc(payload) {
  let { file, cb, props } = payload.payload;
  let intl = yield select(state => state.intlReact.intl);
  let fileList = yield select(state => state.imageUploader.fileList);

  try {
    let response;

    response = yield call(doRequest, {
      method: 'GET',
      url: `/unimup/objctupload${file.optimizedUrl}`,
      responseType: 'blob',
      shouldCancel: true
    });

    if (response && response.status === 200) {
      let image = URL.createObjectURL(response.data);

      // message.info(
      //   intl.formatMessage({
      //     id: 'Generic.ApiMessages.Products.ImageStatusChanged'
      //   })
      // );

      fileList.map(e => {
        if (e.uid == file.uid) {
          e['optimizedImage'] = image;
        }
      });

      let findFile = fileList.find(e => e.uid === file.uid);

      cb(findFile, fileList, props);

      yield put(setImageLIst(fileList));
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

function* getImageListListener() {
  yield takeEvery(imageActions.GET_IMAGE_LIST, getTheImageLIst);
}
function* imageDeleteListener() {
  yield takeEvery(imageActions.DELETE_IMAGE, imageDelete);
}
function* imageUploadListener() {
  yield takeEvery(imageActions.UPLOAD_IMAGE, payload => imageUploadFunc(payload));
}
function* imagePositionUpdateListener() {
  yield takeEvery(imageActions.IMAGE_POSITION_UPDATE, imageSwapPositionFunc);
}
function* imageActivateListener() {
  yield takeEvery(imageActions.IMAGE_ACTIVATE, imageActivateFunc);
}

function* getClickedImageListener() {
  yield takeEvery(imageActions.GET_CLICKED_IMAGE, getClickedImageFunc);
}

export default function* rootSaga() {
  yield all([
    fork(getImageListListener),
    fork(imageDeleteListener),
    fork(imageUploadListener),
    fork(imagePositionUpdateListener),
    fork(imageActivateListener),
    fork(getClickedImageListener)
  ]);
}
function imageList(array) {
  let newArr = [];
  let tempObj = {};
  if (array) {
    for (var i = 0; i < array.length; i++) {
      tempObj = {};

      tempObj = {
        thumbnailUrl: array[i].thumbnail.fetchUrl,
        optimizedUrl: array[i].optimized.fetchUrl,
        pageId: array[i].pageId,
        active: array[i].active
      };
      // newArr.push(array[i].optimized.fetchUrl)
      newArr.push(tempObj);
    }
  }

  return newArr;
}
