import { take, put, fork, call, all, actionChannel, race } from 'redux-saga/effects';
import { eventChannel, buffers } from 'redux-saga';
import { createSearchWorker } from '../../workers';
import { ADD_JOB_TO_QUEUE, TERMINATE_JOB_QUEUE } from '../constant';

/**
 * @description Create a saga channel to handle a worker events
 * @param {Worker} worker
 * @returns {Worker} Worker
 */
function createWorkerChannel(worker) {
  return eventChannel(emit => {
    const onMessage = event => emit(event);
    worker.addEventListener('message', onMessage);

    const unsubscribe = () => worker.terminate();
    return unsubscribe;
  });
}

/**
 *
 * @description initWorkerSaga controls the
 * entire flow for the saga-web worker thread.
 * This act as a queue for offloading computationally
 * intensive tasks to the web worker.
 *
 * Web Worker listener expects a Worker Action
 * and data object to execute. Once the data is
 * processed, a redux action will dispatched with
 * the processed data from web worker.
 *
 */
function* initWorkerSaga() {
  const worker = yield call(createSearchWorker);

  if (!worker) {
    return;
  }

  const channel = yield call(createWorkerChannel, worker);
  const workerQueue = yield actionChannel(ADD_JOB_TO_QUEUE, buffers.expanding());

  while (true) {
    const { workerAction, terminateWorkerAction } = yield race({
      workerAction: take(workerQueue),
      terminateWorkerAction: take(TERMINATE_JOB_QUEUE)
    });

    if (workerAction) {
      yield call(executeWorkerAction, workerAction, worker, channel);
    } else if (terminateWorkerAction) {
      return channel.close();
    }
  }
}

function* executeWorkerAction(action, worker, channel) {
  const {
    type,
    payload: { workerAction, data = {}, actionToDispatch, id }
  } = action;
  const isPayloadValid = yield call(validatePayload, workerAction, data, actionToDispatch);

  // return execution if payload is not valid
  if (!isPayloadValid) {
    return;
  }

  yield call([worker, 'postMessage'], { workerAction, data });
  const workerResponse = yield take(channel);
  yield put(actionToDispatch({ data: workerResponse.data, id }));
}

function validatePayload(workerAction, data, actionToDispatch) {
  if (!workerAction || typeof workerAction !== 'string') {
    console.error(`workerAction should be type of String, ${workerAction} is not a String`);
    return false;
  }

  if (!data || typeof data !== 'object' || Array.isArray(data)) {
    console.error(`data should be type of Object, ${data} is not an Object`);
    return false;
  }

  if (!actionToDispatch || typeof actionToDispatch !== 'function') {
    console.error(
      `actionToDispatch only accepts Funtions, ${actionToDispatch} is not a Function. Skipping this action`
    );
    return false;
  }

  return true;
}

export default function* rootSaga() {
  yield all([fork(initWorkerSaga)]);
}
