import { all, fork, takeEvery, put, select, call, take, race } from 'redux-saga/effects';
import * as categoriesActions from './categories.constants';
import {
  getCategoriesSuccess,
  deleteCategorySuccess,
  handleBlurCategorySuccess,
  getCategoryLanguagesSuccess,
  setSelectedCategory,
  cancelCategoryTreeKeySuccess,
  savecategoryTreeKeySuccess,
  addCategory,
  treeSelectedKey,
  updateICategorySaveLoader,
  resetCategorySelected,
  saveCatLastGeneratedRandomNumber,
  reUpdateCategorySaveDetails,
  setUpdateCategoryTree,
  removeExpandKeySuceess,
  setExpandKey,
  setEnableDrag,
  categorySpinLoaderShow,
  serchWithDeleteIdentify,
  getCategories as getCategoriesAction,
  addnodebtnDisabled
} from './categories.actions';
import normalizeTree from '../../../../shared/utils/normalizeTree';
import { doRequest } from '../../../../shared/redux/sagas/api';
import { message } from 'antd';
import { checkErrResponse } from '../../../../shared/utils/errorHandler';

function* getCategories() {
  let intl = yield select(state => state.intlReact.intl);
  try {
    const response = yield call(doRequest, {
      url: 'prodsup/categories/get'
    });

    let languageList = yield select(state => state.languageList.languageList);
    let defLocalState = yield select(state => state.profile.language);

    let defaultLocalCode = 'en_US';
    if (languageList) {
      let defaultLanguageLenth = languageList.find(item => item.id === defLocalState);
      if (defaultLanguageLenth) {
        defaultLocalCode = defaultLanguageLenth.locale;
      }
    }
    let expandedKeys = yield select(state => state.categories.expandedKeys);

    const dataList = normalizeTree(response.data, defaultLocalCode, expandedKeys);

    yield put(getCategoriesSuccess({ data: response.data, dataList }));

    let searchVal = yield select(state => state.categories.searchStringVal);

    if (searchVal == '') {
      // reset loader
      yield put(serchWithDeleteIdentify(false));
    }
  } catch (error) {
    let errorMSg = checkErrResponse(error, intl, 'Generic.ApiMessages.Category.CategoryGetError');

    if (errorMSg) message.error(errorMSg);
  }
}

function* plusBtnClick({ payload }) {
  const { clickedValue, dataOld, randomNumb } = payload;
  const { save } = yield race({
    save: take(categoriesActions.SAVE_TREE_CATEGORY_VALUE_SUCCESS),
    cancel: take(categoriesActions.CANCEL_TREE_CATEGORY_VALUE)
  });

  if (save) {
    yield put(addCategory(clickedValue, dataOld, randomNumb));
    payload.cb();
  }
}

function* addCategorys({ payload }) {
  let { clickedValue, dataOld, randomNumb } = payload;

  for (let i = 0; i < dataOld.length; i++) {
    addKeyToNested(dataOld[i], dataOld, clickedValue, i, randomNumb); // ID is string like «30»
  }

  let [expandedKeys, searchString] = yield select(state => [
    state.categories.expandedKeys,
    state.categories.searchStringVal
  ]);

  expandedKeys = [...expandedKeys, clickedValue];

  if (searchString) {
    yield put(removeExpandKeySuceess({ keys: expandedKeys, data: dataOld[0].children }));
  } else {
    let dataToNormalize = [];

    if (dataOld[0]['id']) {
      dataToNormalize = dataOld;
    } else {
      dataToNormalize = dataOld[0]['children'];
    }

    const dataList = normalizeTreeLatest(dataToNormalize, expandedKeys);
    yield put(removeExpandKeySuceess({ keys: expandedKeys, data: dataList }));
  }

  yield put(setUpdateCategoryTree({ isUpdated: true }));
  yield put(saveCatLastGeneratedRandomNumber({ randomNumb }));
}

function* deleteCategory({ payload }) {
  let intl = yield select(state => state.intlReact.intl);
  let { clickedValue, dataOld } = payload;

  let searchVal = yield select(state => state.categories.searchStringVal);
  if (searchVal && searchVal.length > 0) {
    yield put(serchWithDeleteIdentify(true));
  }
  try {
    const response = yield call(doRequest, {
      method: 'DELETE',
      url: `prodsup/categories/${clickedValue}`
    });

    if ((response.status = 200)) {
      let selectedKey = yield select(state => state.categories.selectedKeys);
      let isSelectedKeyDeleted = false;
      if (selectedKey && selectedKey.length && selectedKey[0] === clickedValue) {
        isSelectedKeyDeleted = true;
      }
      //commented for expand key collapsed and expand when delete
      yield put(deleteCategorySuccess({ dataOld, isSelectedKeyDeleted }));
      yield put(getCategoriesAction());
      yield take(categoriesActions.GET_CATEGORIES_SUCCESS);

      let mesage = intl.formatMessage({
        id: 'Generic.ApiMessages.Category.CategoryDeleteSuccess'
      });
      message.info(mesage);
    }
  } catch (error) {
    let mesageError = intl.formatMessage({
      id: 'Generic.ApiMessages.Category.CategoryDeleteError'
    });
    let serverError = intl.formatMessage({
      id: 'Generic.ApiMessages.ServerError'
    });
    let notFoundError = intl.formatMessage({
      id: 'Generic.ApiMessages.NotFoundError'
    });

    if (error.response.status === 404) {
      message.error(notFoundError);
    }
    if (error.response.status === 500) {
      message.error(serverError);
    }
    if (error.response.status === 422) {
      if (error.response.data.message === 'ID ' + clickedValue + ' has child nodes.') {
        let mesage = intl.formatMessage({
          id: 'LeftMenu.System.Categories.TreeMenu.Msg_DeleteNodeChildren'
        });
        message.error(mesage);
      } else {
        message.error(mesageError);
      }
    }
    if (searchVal && searchVal.length === 0) {
      yield call(getCategories);
    }
  } finally {
    yield put(setUpdateCategoryTree({ isUpdated: true }));
    yield put(categorySpinLoaderShow(false));
  }
}

function* removeExpandKey({ payload }) {
  let { key } = payload;

  let expandedKeys = yield select(state => state.categories.expandedKeys);

  let idsArray = getCollapseChildId([key]);

  let keysFilterd = [...expandedKeys];
  if (idsArray.length > 0) {
    idsArray.forEach(element => {
      keysFilterd =
        keysFilterd &&
        keysFilterd.filter(item => {
          return item != element;
        });
    });
  }

  let dataNew = yield select(state => state.categories.data);

  const dataList = normalizeTreeLatest(dataNew[0].children, keysFilterd);

  yield put(removeExpandKeySuceess({ keys: keysFilterd, data: dataList }));

  yield put(setUpdateCategoryTree({ isUpdated: true }));
}
/**
 * Create new node
 * @param {} action
 */
function* handleBlurCategory({ payload }) {
  let { changedValue, dataOld, changedId, defaultLocal, originData } = payload;
  yield put(setUpdateCategoryTree({ isUpdated: false }));

  if (changedValue) {
    const parentId = getParentKey(changedId, dataOld);

    // add language list and map id to locale
    let localeMapLanguageList = yield select(state => state.languageList.languageList);
    let localeMap = {};
    localeMapLanguageList.forEach(element => {
      localeMap[element.id] = element.locale;
    });
    let defaultLocalSelect;

    if (defaultLocal) {
      defaultLocalSelect = localeMap[defaultLocal];
    }

    if (!defaultLocalSelect) {
      defaultLocal = '3';
      defaultLocalSelect = localeMap[defaultLocal];
    }

    const formData = {
      title: changedValue ? changedValue.trim() : changedValue,
      title_key: changedValue ? changedValue.trim() : changedValue,
      parentId: parentId && parentId !== 1000 ? parentId : null
    };

    try {
      const response = yield call(doRequest, {
        method: 'POST',
        url: 'prodsup/categories/add',
        data: formData
      });

      if (response.status === 200) {
        const newAddedNode = response.data;
        newAddedNode.parentId = newAddedNode.parentId || 0;

        for (let i = 0; i < dataOld.length; i++) {
          replaceExistingKey(dataOld[i], dataOld, changedId, newAddedNode, i);
        }

        const searchString = yield select(s => s.categories.searchStringVal);
        let clonedOriginData = [];

        try {
          clonedOriginData = JSON.parse(JSON.stringify(originData));
        } catch (error) {
          console.log(error);
        }

        if (searchString) {
          for (let i = 0; i < clonedOriginData.length; i++) {
            addNewNodeInOriginData(clonedOriginData[i], { ...newAddedNode });
          }
        }
        let dataList = [];
        if (clonedOriginData[0] && clonedOriginData[0].id) {
          dataList = normalizeTree(clonedOriginData);
        } else {
          dataList = normalizeTree(clonedOriginData[0]['children']);
        }

        // add language list and map id to locale
        let localeMapLanguageList = yield select(state => state.languageList.languageList);
        let formData2 = {};
        localeMapLanguageList.forEach(element => {
          formData2[element.locale] = {
            id: element.locale,
            title: ''
          };
        });

        formData2[defaultLocalSelect] = {
          id: defaultLocalSelect,
          title: response.data.title ? response.data.title.trim() : response.data.title
        };
        try {
          if (response.data.id) {
            const response2 = yield call(doRequest, {
              method: 'PATCH',
              url: 'contrans/translations/CAT/' + response.data.id,
              data: formData2
            });

            if (response2.status === 200) {
              let expandedKeys = yield select(state => state.categories.expandedKeys);

              const dataModified = normalizeTreeLatest(dataOld[0].children, expandedKeys);

              yield put(
                handleBlurCategorySuccess({
                  dataOld: dataModified,
                  dataList,
                  newNode: response.data,
                  originData: clonedOriginData
                })
              );
              yield put(resetCategorySelected());
              yield put(setUpdateCategoryTree({ isUpdated: true }));
              yield put(setSelectedCategory({ selectedCategory: [response.data.id] }));
              yield put(treeSelectedKey([response.data.id]));
            }
          }
        } catch (error) {
          for (let i = 0; i < dataOld.length; i++) {
            deleteKeyNested(dataOld[i], dataOld, changedId, i);
          }
          message.error(error.response.data.message.toString());
        }
      } else {
        for (let i = 0; i < dataOld.length; i++) {
          deleteKeyNested(dataOld[i], dataOld, changedId, i);
        }
      }
    } catch (error) {
      for (let i = 0; i < dataOld.length; i++) {
        deleteKeyNested(dataOld[i], dataOld, changedId, i);
      }

      let dataList = yield select(state => state.categories.dataList);

      yield put(handleBlurCategorySuccess({ dataOld: dataOld[0].children, dataList }));

      message.error(error.response.data.message.toString());
    }
  } else {
    for (let i = 0; i < dataOld.length; i++) {
      deleteKeyNested(dataOld[i], dataOld, changedId, i);
    }

    let dataList = yield select(state => state.categories.dataList);

    yield put(handleBlurCategorySuccess({ dataOld: dataOld[0].children, dataList }));
  }

  yield put(setEnableDrag({ isDragEnable: true }));
  yield put(addnodebtnDisabled(false));
}

function* saveCategoryDetails({ payload: { values, successCb, errorCb } }) {
  yield put(updateICategorySaveLoader(true));
  let localIdMap = [];
  let element = values.values[0];
  delete element.isCircle;
  delete element.show_in_mobile_home;
  delete element.icon_base64_string;
  if (element) {
    for (let key in element) {
        const localId = key.split('-');
        let getLocalId = localId[1];
        let formDataObj = {
          id: getLocalId,
          title: element[key] ? element[key].trim() : element[key],
        };
      localIdMap.push(formDataObj);
    }

    let formData = {};
    localIdMap.forEach(local => {
      formData[local.id] = local;
    });

    try {
      const updateOBJ = {
        title: formData[values.defaultLocal].title,
        title_key: formData[values.defaultLocal].title,
        isCircle:values.isCircle,
        show_in_mobile_home:values.show_in_mobile_home,
        icon_base64_string:values.icon_base64_string
      };
      yield call(doRequest, {
        method: 'PATCH',
        url: `prodsup/categories/${values.categorySelectedId}`,
        data: updateOBJ
      });
      yield call(doRequest, {
        method: 'PATCH',
        url: 'contrans/translations/CAT/' + values.categorySelectedId,
        data: formData
      });

      const { categorySelectedId, defaultLocal } = values;
      const title = updateOBJ.title;
      let categoryList = yield select(state => state.categories.data);
      let originData = yield select(state => state.categories.originData);
      let dataList = yield select(state => state.categories.dataList);
      dataList.forEach(element => {
        if (element.id.toString() == categorySelectedId) {
          element.title = title;
        }
      });
      categoryList = [...categoryList];

      let expandedKeys = yield select(state => state.categories.expandedKeys);

      loop(categoryList, categorySelectedId, title, defaultLocal, expandedKeys);
      loop(originData, categorySelectedId, title, defaultLocal);
      yield put(
        reUpdateCategorySaveDetails({ data: categoryList, dataList, originData: originData })
      );
      if (successCb) successCb();
    } catch (error) {
      let intl = yield select(state => state.intlReact.intl);

      if (error.response.data.message) {
        let mesageSaveError = intl.formatMessage({
          id: 'Generic.ApiMessages.Category.CategoryDetailsSaveError'
        });
        let serverError = intl.formatMessage({
          id: 'Generic.ApiMessages.ServerError'
        });
        let notFoundError = intl.formatMessage({
          id: 'Generic.ApiMessages.NotFoundError'
        });

        if (error.response.status === 404) {
          message.error(notFoundError);
        } else if (error.response.status === 500) {
          message.error(serverError);
        } else if (error.response.status === 422) {
          message.error(mesageSaveError);
        } else {
          message.error(error.response.data.message);
        }
      }

      if (errorCb) errorCb();
    }
  }
  yield put(updateICategorySaveLoader(false));
}

function loop(categoryList, categorySelectedId, title, defaultLocal, expandedKeys) {
  categoryList.forEach(tree => {
    expandedKeys &&
      expandedKeys.forEach(item => {
        if (item === tree.id) {
          tree['expanded'] = true;
        }
      });

    if (tree.id.toString() == categorySelectedId) {
      tree.title = title;
      if (tree.languages) tree.languages[defaultLocal] = title;
      //tree = { ...tree };
    }
    if (tree.children) {
      loop(tree.children, categorySelectedId, title, defaultLocal, expandedKeys);
    }
  });
}

function* updateCategoriesOrder({ payload }) {
  let intl = yield select(state => state.intlReact.intl);
  let mesageLoading = intl.formatMessage({
    id: 'Generic.ApiMessages.UpdatingPositions'
  });
  const hide = message.loading(mesageLoading);
  yield put(setUpdateCategoryTree({ isUpdated: false }));
  yield put(setEnableDrag({ isDragEnable: false }));
  try {
    const { dragKey, parentId, newPosition } = payload.updatedKeys;

    yield call(doRequest, {
      method: 'PUT',
      url: `prodsup/draganddrop/categories`,
      data: {
        new_parent: parentId ? parentId : null,
        node: Number(dragKey),
        new_pos: Number(newPosition)
      }
    });
    hide();
    let mesage = intl.formatMessage({
      id: 'Generic.ApiMessages.Category.CategoryOrderUpdated'
    });
    message.info(mesage);
  } catch (error) {
    hide();
    let mesageUpdateError = intl.formatMessage({
      id: 'Generic.ApiMessages.Category.CategoryOrderUpdateError'
    });
    let serverError = intl.formatMessage({
      id: 'Generic.ApiMessages.ServerError'
    });
    let notFoundError = intl.formatMessage({
      id: 'Generic.ApiMessages.NotFoundError'
    });

    if (error.response.status === 404) {
      message.error(notFoundError);
    } else if (error.response.status === 500) {
      message.error(serverError);
    } else if (error.response.status === 422) {
      message.error(mesageUpdateError);
    } else {
      message.error(error.response.data.message);
    }
  } finally {
    let expandedKeys = getExpandedKeys(payload.data);

    yield put(setExpandKey({ expandedKeys }));

    yield call(getCategories);
    yield put(setEnableDrag({ isDragEnable: true }));
    yield put(setUpdateCategoryTree({ isUpdated: true }));
    yield put(categorySpinLoaderShow(false));
  }
}

function* getSelectedCategoryLanguage({ payload }) {
  let data;
  let dataList = yield select(state => state.categories.dataList);
  if (payload['selectedCategory'][0]) {
    const categoryDetails = dataList.find((item) => item.id === payload.selectedCategory[0])
    try {
      const response = yield call(doRequest, {
        url: `contrans/translations/CAT/${payload['selectedCategory'][0]}`
      });

      let getResponse = response.data;
      if (getResponse.length > 0) {
        let languagesList = [];
        getResponse.forEach(item => {
          languagesList.push({
            id: item.values_translation['id'],
            title: item.values_translation['title']
          });
        });

        data = {
          id: '' + getResponse[0].origin_id + '',
          attributes: {
            title: '' + getResponse[0].title + ''
          },
          languages: languagesList,
          categoryDetails
        };

        let newRandomKeyValue = yield select(state => state.categories.randomNumberLastCategory);

        setTimeout(() => {
          // focus input text box

          if (newRandomKeyValue && newRandomKeyValue.randomNumb) {
            const element = document.getElementById(newRandomKeyValue.randomNumb.toString());
            if (element) {
              element.focus();
            }
          }
        }, 100);

        yield put(getCategoryLanguagesSuccess({ data }));
      } else {
        let languagesList = [];

        data = {
          id: payload['selectedCategory'][0],
          attributes: {
            title: ''
          },
          languages: languagesList,
          categoryDetails
        };

        yield put(getCategoryLanguagesSuccess({ data }));
      }
    } catch (error) {}
  }
}

function* cancelCategoryAlert() {
  yield put(cancelCategoryTreeKeySuccess());
}

function* cancelCategoryAlartListener() {
  yield takeEvery(categoriesActions.CANCEL_TREE_CATEGORY_VALUE, cancelCategoryAlert);
}

function* saveCategoryDetailsListener() {
  yield takeEvery(categoriesActions.UPDATE_CATEGORY_FORM_DETAILS, saveCategoryDetails);
}

function* SaveCategoryAlert() {
  let newKeyValue = yield select(state => state.categories.nextSelectedkey);

  yield put(savecategoryTreeKeySuccess());
  if (newKeyValue && newKeyValue.length !== 0) {
    //yield put(identifyGetCategoryData(true));
  }
  yield put(setSelectedCategory({ selectedCategory: newKeyValue }));
  yield put(treeSelectedKey(newKeyValue));
}

function* SaveCategoryAlertListener() {
  yield takeEvery(categoriesActions.SAVE_TREE_CATEGORY_VALUE, SaveCategoryAlert);
}

function* getCategoriesListener() {
  yield takeEvery(categoriesActions.GET_CATEGORIES, getCategories);
}

function* plusBtnClickListener() {
  yield takeEvery(categoriesActions.PLUS_BTN_CLICK, plusBtnClick);
}

function* addCategoryListener() {
  yield takeEvery(categoriesActions.ADD_CATEGORY, addCategorys);
}

function* deleteCategoryListener() {
  while (true) {
    const action = yield take(categoriesActions.DELETE_CATEGORY);
    yield call(deleteCategory, action);
  }
}

function* handleBlurCategoryListener() {
  yield takeEvery(categoriesActions.HANDLE_BLUR_CATEGORY, handleBlurCategory);
}

function* getCategoryLanguagesListener() {
  yield takeEvery(categoriesActions.SET_SELECTED_CATEGORY, getSelectedCategoryLanguage);
}

function* updateCategoriesOrderListener() {
  yield takeEvery(categoriesActions.UPDATE_CATEGORIES_ORDER, updateCategoriesOrder);
}

function* removeExpandKeyListener() {
  yield takeEvery(categoriesActions.REMOVE_EXPAND_KEY, removeExpandKey);
}

export default function* rootSaga() {
  yield all([
    fork(getCategoriesListener),
    fork(addCategoryListener),
    fork(deleteCategoryListener),
    fork(handleBlurCategoryListener),
    fork(getCategoryLanguagesListener),
    fork(updateCategoriesOrderListener),
    fork(cancelCategoryAlartListener),
    fork(SaveCategoryAlertListener),
    fork(plusBtnClickListener),
    fork(saveCategoryDetailsListener),
    fork(removeExpandKeyListener)
  ]);
}

function addKeyToNested(obj, parent, value, i, randomNumb) {
  if (obj.id === value) {
    if (obj.children) {
      obj.children.splice(0, 0, { title: '', id: randomNumb });
    } else {
      obj['expanded'] = true;
      obj['children'] = [];
      obj['children'].push({ title: '', id: randomNumb });
    }

    setTimeout(() => {
      const element = document.getElementById(randomNumb.toString());
      if (element) {
        element.focus();
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
      }
    }, 300);
  }

  if (obj && obj.children) {
    for (let j = 0; j < obj.children.length; j++) {
      addKeyToNested(obj.children[j], obj.children, value, j, randomNumb);
    }
  }
}

function deleteKeyNested(obj, parent, value, i) {
  if (obj.id === value) {
    parent.splice(i, 1);
  }
  if (obj && obj.children) {
    for (let j = 0; j < obj.children.length; j++) {
      deleteKeyNested(obj.children[j], obj.children, value, j);
    }
  }
}

function replaceExistingKey(obj, parent, changedId, newData, i) {
  if (obj.id == changedId) {
    obj.id = newData.id;
    obj.title = newData.title;
    obj.serial = newData.serial;
    obj.parentId = newData.parentId;
    obj.hierarchyLevel = newData.hierarchyLevel;
    obj.position = newData.position;

    return;
  }

  if (obj && obj.children) {
    for (let j = 0; j < obj.children.length; j++) {
      replaceExistingKey(obj.children[j], obj.children, changedId, newData, j);
    }
  }
}

function addNewNodeInOriginData(obj, newData) {
  if (newData.parentId == obj.id) {
    if (!obj.children) {
      obj.children = [];
    }

    obj.children = [newData, ...obj.children];
    return;
  }

  if (obj && obj.children) {
    for (let j = 0; j < obj.children.length; j++) {
      addNewNodeInOriginData(obj.children[j], newData);
    }
  }
}

const getParentKey = (key, tree) => {
  let parentKey = null;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.id === key)) {
        parentKey = node.id;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

function getCollapseChildId(treeList) {
  const collapsibleIds = [];
  loopId(treeList, collapsibleIds);
  return collapsibleIds;
}

function loopId(treeList, collapsibleIds) {
  treeList.forEach(tree => {
    if (tree.id) {
      collapsibleIds.push(tree.id);

      if (tree.children) loopId(tree.children, collapsibleIds);
    }
  });
}

/**
 *
 * Set expanded keys on the tree
 *
 * @param {Array} treeList
 * @param {Array} expandedKeys
 */
function normalizeTreeLatest(treeList, expandedKeys) {
  looplatest(treeList, expandedKeys);
  return treeList;
}

function looplatest(treeList, expandedKeys) {
  treeList.forEach(tree => {
    if (tree.id) {
      let hasKey = expandedKeys && expandedKeys.find(item => item == tree.id);

      tree['expanded'] = hasKey ? true : false;

      if (tree.children) looplatest(tree.children, expandedKeys);
    }
  });
}

function getExpandedKeys(treeList) {
  let expandedIds = [];
  getExpandedKeysLoop(treeList, expandedIds);
  return expandedIds;
}

function getExpandedKeysLoop(treeList, expandedIds) {
  treeList.forEach(tree => {
    if (tree.expanded) {
      expandedIds.push(tree.id);
    }

    if (tree.children) getExpandedKeysLoop(tree.children, expandedIds);
  });
}
