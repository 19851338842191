import { combineReducers } from 'redux';
import manufactureReducer from './manufactureModal/productModals.reducer';
import ingredientModelRedcer from './ingredientModel/ingredientModel.reducer';
import tagsModelReducer from './tagModal/tagModal.reducer';
import brandReducer from './brandModal/brandModal.reducer';
import distributorReducer from './distributorModal/distributorModal.reducer';

const rootReducers = combineReducers({
  manufacture: manufactureReducer,
  brand: brandReducer,
  distributor: distributorReducer,
  ingredientModel: ingredientModelRedcer,
  tagsModel: tagsModelReducer
});

export default rootReducers;
