import { actionCreator } from 'redux-action-creator';
import adsTypes from './list.constants';

export const getDataList = actionCreator(adsTypes.GET_DATA_LIST, 'pagination', 'sort');
export const getDataListSuccess = actionCreator(
  adsTypes.GET_DATA_LIST_SUCCESS,
  'dataList',
  'pagination',
  'sort'
);
export const getDataListFail = actionCreator(adsTypes.GET_DATA_LIST_FAIL);
export const setDataListAdsImageSuccess = actionCreator(
  adsTypes.SET_DATA_LIST_ADS_IMAGE_SUCCESS,
  'id',
  'image',
  'indexes'
);

export const reset = actionCreator(adsTypes.RESET);

export const deleteAds = actionCreator(adsTypes.DELETE_ADS, 'id');
export const deleteAdsSuccess = actionCreator(adsTypes.DELETE_ADS_SUCCESS);
export const deleteAdsFail = actionCreator(adsTypes.DELETE_ADS_FAIL);
