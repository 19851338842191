export function getToken(treeList) {
  let ccc = getCookie('access_token');
  if (ccc) {
    return ccc;
  } else {
    let access_token = localStorage.getItem('access_token');
    return access_token;
  }
}

export function setToken(remeber, access_token) {
  if (remeber) {
    localStorage.setItem('access_token', access_token);
  } else {
    setCookie('access_token', access_token, 0);
  }
}

export function setTokenWithoutRemember(new_access_token) {
  let cookieToken = getCookie('access_token');
  let storeToken = localStorage.getItem('access_token');

  if (cookieToken) {
    setCookie('access_token', new_access_token, 0);
  } else if (storeToken) {
    localStorage.setItem('access_token', new_access_token);
  }
}

export function clearToken() {
  localStorage.removeItem('access_token');
  deleteCookie('access_token', '', -1);
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  //var expires = 'expires=' + d.toUTCString();
  //document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  document.cookie = cname + '=' + cvalue;
}

function deleteCookie(cname, cvalue, exdays) {
  document.cookie = cname + '=' + cvalue + ';' + exdays + ';path=/';
}

function getCookie(cname) {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}
