import { all, fork, takeEvery, put, call, select } from 'redux-saga/effects';
import * as languageActions from './languagelist.constants';
import { getlanguageListSuccess } from './languagelist.action';
import { doRequest } from '../redux/sagas/api';
import { message } from 'antd';
import { checkErrResponse } from '../utils/errorHandler';

function* getlanguageList() {
  try {
    const response = yield call(doRequest, {
      url: 'contrans/locales/get',
      method: 'GET'
    });
    if (response.status === 200) {
      const dataList = response.data.sort(function(a, b) {
        var languageA = a.language.toUpperCase(); // ignore upper and lowercase
        var languageB = b.language.toUpperCase(); // ignore upper and lowercase
        if (languageA < languageB) {
          return -1;
        }
        if (languageA > languageB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
      //filte language list with country
      let newLanguageList = [];
      dataList.forEach(element => {
        if (element.country) {
          newLanguageList.push(element);
        }
      });
      yield put(getlanguageListSuccess(newLanguageList));
    }
  } catch (error) {
    console.log(error);
    let intl = yield select(state => state.intlReact.intl);

    let errorMSg = checkErrResponse(error, intl, 'Generic.ApiMessages.ServerError');

    if (errorMSg) message.error(errorMSg);
  }
}

function* getlanguageListListener() {
  yield takeEvery(languageActions.GET_LANGUAGE_LIST, getlanguageList);
}

export default function* rootSaga() {
  yield all([fork(getlanguageListListener)]);
}
