import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import AsyncProfileContainer from "../app/module/profile/components/AsyncProfileContainer";
import AsyncCategoryContainer from "../app/module/system/categories/components/AsyncCategoryContainer";
import AsyncCategoryCircleContainer from "../app/module/system/categories/categoryCircle/components/AsyncCetegoryCircleContainer";
import AsyncCategoryShowInMobileHomeContainer from "../app/module/system/categories/categoryShowInMobileHome/components/AsyncCategoryShowInMobileHomeContainer";
import AsyncIngredientsContainer from "../app/module/system/ingredients/component/AsyncIngredientsContainer";
import AsyncDashboard from "../app/module/dashboard/components/AsyncDashboard";
import AsyncProductsEditContainer from '../app/module/contentManagement/products/components/AsyncProductsContainer';
import AsyncProductsListContainer from '../app/module/contentManagement/products/list/component/AsyncProductsListContainer'
import AsyncNutritionValuesContainer from "../app/module/system/nutrition-values/component/AsyncNutritionValuesContainer";
import AsyncLablesContainer from '../app/module/system/lables/component/AsyncLablesContainer'
import AsyncENumbersContainer from '../app/module/system/e-numbers/component/AsyncENumbersContainer'
import AsyncProductsCatalogueContainer from '../app/module/contentManagement/catalogue/products/components/AsyncProductCatalogueContainer'
import AsyncManufacturerCatalogContainer from '../app/module/contentManagement/catalogue/manufacturer/components/AsyncManufacturerCatalogContainer'
import AsyncDistributorCatalogueContainer from '../app/module/contentManagement/catalogue/distributor/components/AsyncDistributorCatalogueContainer'
import AsyncBrandCatalogueContainer from '../app/module/contentManagement/catalogue/brand/components/AsyncBrandCatalogueContainer'
import AsyncStoreinformationContainer from '../app/module/system/store-information/component/AsyncStoreinformationContainer'
import AsyncStoreContainer from '../app/module/contentManagement/stores/components/AsyncStoresContainer';
import AsyncStoresListContainer from '../app/module/contentManagement/stores/list/component/AsyncStoresListContainer'
import AsyncTagsContainer from '../app/module/system/tags/component/AsyncTagsContainer'
import AsyncModerationDetailsContainer from '../app/module/contentManagement/moderation/components/details/AsyncModerationDetailsContainer'
import AsyncModerationTableContainer from '../app/module/contentManagement/moderation/components/list/AsyncModerationTableContainer'
import AsyncAdManagementListContainer from '../app/module/adManagement/components/adList/AsyncAdManagementListContainer'
import AsyncAdManagementDetailsContainer from '../app/module/adManagement/components/adDetails/AsyncAdManagementDetailsContainer'
import AsyncNewIngredientsContainer from "../app/module/system/newIngredients/components/list/AsyncNewIngredientsListContainer";
import AsyncNewIngredientsDetailsContainer from "../app/module/system/newIngredients/components/details/AsyncNewIngredientsDetailsContainer";
import AsyncNewIngredientNewContainer from "../app/module/system/newIngredients/components/newIngredient/AsyncNewIngredientNewContainer";

const App = ({match}) => (
  <div>
    <Switch>
      <Route path={`${match.url}dashboard`} component={AsyncDashboard}/>
      <Route path={`${match.url}profile`} component={AsyncProfileContainer}/>
      <Route path={`${match.url}system/categories`} component={AsyncCategoryContainer}/>
      <Route path={`${match.url}system/category-circles`} component={AsyncCategoryCircleContainer}/>
      <Route path={`${match.url}system/category-show-in-mobile-home`} component={AsyncCategoryShowInMobileHomeContainer}/>
      <Route path={`${match.url}system/ingredients`} component={AsyncIngredientsContainer}/>
      <Route path={`${match.url}content-management/moderation/:id/:locale`}  component={AsyncModerationDetailsContainer} />
      <Route path={`${match.url}content-management/moderation`} exact={true} component={AsyncModerationTableContainer} />
      <Route path={`${match.url}ad-management/ads`} exact={true} component={AsyncAdManagementListContainer} />
      <Route path={`${match.url}ad-management/ads/:id`}  component={AsyncAdManagementDetailsContainer} />
      <Route path={`${match.url}content-management/products/new`} exact={true} component={AsyncProductsEditContainer}/>
      <Route path={`${match.url}content-management/products/:id`} exact={true} component={AsyncProductsEditContainer}/>
      <Route path={`${match.url}content-management/products`} exact={true} component={AsyncProductsListContainer}/>
      <Route path={`${match.url}content-management/store`} exact={true} component={AsyncStoresListContainer}/>
      <Route path={`${match.url}content-management/store/new`} exact={true} component={AsyncStoreContainer}/>
      <Route path={`${match.url}content-management/store/:id`} exact={true} component={AsyncStoreContainer}/>
      <Route path={`${match.url}content-management/catalogue/products`} exact={true} component={AsyncProductsCatalogueContainer}/>
      <Route path={`${match.url}content-management/catalogue/manufacturer`} exact={true} component={AsyncManufacturerCatalogContainer}/>
      <Route path={`${match.url}content-management/catalogue/distributor`} exact={true} component={AsyncDistributorCatalogueContainer}/>
      <Route path={`${match.url}content-management/catalogue/brands`} exact={true} component={AsyncBrandCatalogueContainer}/>
      <Route path={`${match.url}system/nutrition-values`} component={AsyncNutritionValuesContainer}/>
      <Route path={`${match.url}system/store-information`} component={AsyncStoreinformationContainer} />
      <Route path={`${match.url}system/labels`} component={AsyncLablesContainer} />
      <Route path={`${match.url}system/e-numbers`} component={AsyncENumbersContainer} />
      <Route path={`${match.url}system/tags`} component={AsyncTagsContainer} />
      <Route path={`${match.url}system/newIngredientsList`} exact={true} component={AsyncNewIngredientsContainer}/>
      <Route path={`${match.url}system/newIngredientsDetails/:id`} exact={true} component={AsyncNewIngredientsDetailsContainer}/>
      <Route path={`${match.url}system/addNewIngredient`} exact={true} component={AsyncNewIngredientNewContainer}/>

      <Redirect from="*" to="/dashboard" />
    </Switch>
  </div>
);

export default App;
