/**
 * @description indec reducer for moderation list and details reducer connect
 */
import { combineReducers } from 'redux';
import moderation_list from './listredux/list.reducer';
import moderation_details from './detailsRedux/details.reducer';
import moderation_image from './imageRedux/imageUploader.reducers';

export default combineReducers({
  moderationList: moderation_list,
  moderationDetails: moderation_details,
  moderationImageList: moderation_image
});
