import * as ingredientsActions from './ingredients.constants';

export const getIngredients = () => ({ type: ingredientsActions.GET_INGREDIENTS });

export const getIngredientsSuccess = payload => ({
  type: ingredientsActions.GET_INGREDIENTS_SUCCESS,
  payload
});
export const getDefualtLocalSuccsessIng = payload => ({
  type: ingredientsActions.GET_DEFUALT_LOCAL_SUCCESS_ING,
  payload
});
export const addIngredient = (clickedValue, dataOld, randomNumb) => ({
  type: ingredientsActions.ADD_INGREDIENT,
  payload: { clickedValue, dataOld, randomNumb }
});

export const addIngredientSuccess = payload => ({
  type: ingredientsActions.ADD_INGREDIENT_SUCCESS,
  payload
});

export const deleteIngredient = (clickedValue, dataOld) => ({
  type: ingredientsActions.DELETE_INGREDIENT,
  payload: { clickedValue, dataOld }
});

export const deleteIngredientSuccess = payload => ({
  type: ingredientsActions.DELETE_INGREDIENT_SUCCESS,
  payload
});

export const deleteIngredientFail = () => ({
  type: ingredientsActions.DELETE_INGREDIENT_FAIL
});

export const handleBlurIngredient = (dataOld, changedValue, changedId, defaultLocal) => ({
  type: ingredientsActions.HANDLE_BLUR_INGREDIENT,
  payload: { dataOld, changedValue, changedId, defaultLocal }
});

export const handleBlurIngredientSuccess = payload => ({
  type: ingredientsActions.HANDLE_BLUR_INGREDIENT_SUCCESS,
  payload
});

export const updateIngredientOrder = payload => ({
  type: ingredientsActions.UPDATE_INGREDIENT_ORDER,
  payload
});

export const updateIngredientOrderSuccess = newData => ({
  type: ingredientsActions.UPDATE_INGREDIENT_ORDER_SUCCESS,
  payload: { data: newData }
});

export const setSelectedIngredient = payload => ({
  type: ingredientsActions.SET_SELECTED_INGREDIENT,
  payload
});

export const getIngredientLanguages = payload => ({
  type: ingredientsActions.GET_INGREDIENT_LANGUAGES,
  payload
});

export const getIngredientLanguagesSuccess = payload => ({
  type: ingredientsActions.GET_INGREDIENT_LANGUAGES_SUCCESS,
  payload
});

export const identifyButtonColor = payload => ({
  type: ingredientsActions.IDENTIFY_BTN_RED_INGREDIENT,
  payload
});

export const plusButtonClickIdentify = payload => ({
  type: ingredientsActions.PLUS_BTN_CLICK,
  payload
});

export const veganCheckBoxChange = payload => ({
  type: ingredientsActions.VEGAN_CHECK_BOX_CHECKED,
  payload
});

export const hideButtonColor = () => ({
  type: ingredientsActions.IDENTIFY_BTN_WITHOUT_RED_INGREDIENT
});

export const showAlertMessage = () => ({
  type: ingredientsActions.SHOW_ALERT_WINDOW_INGREDIENT
});

export const hideAlertMessage = () => ({
  type: ingredientsActions.HIDE_ALERT_WINDOW_INGREDIENT
});

export const treeSelectedKey = payload => ({
  type: ingredientsActions.TREE_SELECTED_KEY_INGREDIENT,
  payload
});

export const nextTreeSelectedkey = payload => ({
  type: ingredientsActions.NEXT_TREE_SELECTED_KEY_INGREDIENT,
  payload
});

export const saveIngredientTreeKey = () => ({
  type: ingredientsActions.SAVE_TREE_INGREDIENT_VALUE
});

export const saveIngredientTreeKeySuccess = () => ({
  type: ingredientsActions.SAVE_TREE_INGREDIENT_VALUE_SUCCESS
});

export const cancelIngredientTreeKey = () => ({
  type: ingredientsActions.CANCEL_TREE_INGREDIENT_VALUE
});

export const cancelIngredientTreeKeySuccess = () => ({
  type: ingredientsActions.CANCEL_TREE_INGREDIENT_VALUE_SUCCESS
});

// ingradient image
export const updateIngredientImageRequest = (data, cb = null) => ({
  type: ingredientsActions.UPDATE_INGREDIENT_IMAGE,
  payload: { ...data, cb }
});

export const updateIngredientImageSuccess = image => ({
  type: ingredientsActions.UPDATE_INGREDIENT_IMAGE_SUCCESS,
  payload: image
});

export const updateIngredientImageError = () => ({
  type: ingredientsActions.UPDATE_INGREDIENT_IMAGE_FAILURE
});

export const updateIngredientImagePatchSuccess = () => ({
  type: ingredientsActions.UPDATE_INGREDIENT_IMAGE_PATCH_SUCESS
});

export const getIngredientImage = url => ({
  type: ingredientsActions.GET_INGREDIENT_IMAGE,
  payload: url
});

export const getIngredientImageSuccess = (payload, imageLoader) => ({
  type: ingredientsActions.GET_INGREDIENT_IMAGE_SUCCESS,
  payload,
  imageLoader
});
export const resetIngredientSelected = () => ({
  type: ingredientsActions.RESET_INGREDIENT_VALUES
});

// INGREDIENT FORM UPDATE
export const updateIngredientFormValue = (values, successCb = null, errorCb = null) => ({
  type: ingredientsActions.UPDATE_INGREDIENT_FORM_DETAILS,
  payload: { values, successCb, errorCb }
});

export const updateIngredientSaveLoader = payload => ({
  type: ingredientsActions.UPDATE_SAVE_INGREDIENT_LOADER,
  payload: payload
});

export const deleteIngredientImage = (data, cb = null) => ({
  type: ingredientsActions.DELETE_INGREDIENT_IMAGE,
  payload: { ...data, cb }
});

export const setTempIngredientImage = image => ({
  type: ingredientsActions.INGREDIENT_IMAGE_TEMP_IMAGE,
  payload: image
});

export const removeTempIngredientImage = () => ({
  type: ingredientsActions.INGREDIENT_IMAGE_CANCEL_CHANGES
});

export const deleteIngredientImageSuccess = () => ({
  type: ingredientsActions.DELETE_INGREDIENT_IMAGE_SUCCESS
});

export const deleteIngredientImageError = () => ({
  type: ingredientsActions.DELETE_INGREDIENT_IMAGE_ERROR
});

export const randomNumRemoveSuccess = payload => ({
  type: ingredientsActions.REMOVE_RANDOM_NUMBER_SUCCESS,
  payload
});

export const identifyDeleteSuccess = payload => ({
  type: ingredientsActions.DELETE_NODE_IDENTIFY,
  payload
});

export const identifyGetIngredientData = loader => ({
  type: ingredientsActions.IDENTIFY_GET_INGREDIENT_DATA_LOADER,
  payload: loader
});

export const saveIngredientLastGeneratedRandomNumber = randomNumb => ({
  type: ingredientsActions.SET_LAST_GENERATED_INGREDIENT_RANDOM_NUMBER,
  payload: randomNumb
});

export const updateFormValuesSuccess = payload => ({
  type: ingredientsActions.UPDATE_INGREDIENT_FORM_DETAILS_SUCCESS,
  payload: payload
});

export const reUpdateIngredientSaveDetails = payload => ({
  type: ingredientsActions.RE_UPDATE_SAVE_INGREDIENT_DETAILS,
  payload: payload
});

export const updateIngredientData = payload => ({
  type: ingredientsActions.UPDATE_ING_DATA,
  payload
});

export const searchIngredients = payload => ({
  type: ingredientsActions.ING_SEARCH,
  payload
});

export const searchIngsuccess = payload => ({
  type: ingredientsActions.ING_SEARCH_SUCCESS,
  payload
});

export const resetIngredientState = () => ({
  type: ingredientsActions.RESET_STATE
});

export const updateAlternativeLisr = payload => ({
  type: ingredientsActions.UPDATE_ALTERNATIVE_LIST,
  payload: payload.alternatives
});

export const searchJobsSuccess = payload => ({
  type: ingredientsActions.ING_SEARCH_JOB_SUCCESS,
  payload: { data: payload.data, id: payload.id }
});

export const getDuplicatedIngListSuccess = payload => ({
  type: ingredientsActions.GET_DUPLICATED_LIST_SUCCESS,
  payload: payload.data
});

export const getDuplicatedIngListFail = payload => ({
  type: ingredientsActions.GET_DUPLICATED_LIST_FAIL,
  payload: payload
});

export const deleteJobSuccess = payload => ({
  type: ingredientsActions.DELETE_JOB_SUCCESS,
  payload: payload.data
});

export const addJobSuccess = payload => ({
  type: ingredientsActions.ADD_JOB_SUCCESS,
  payload: payload.data
});

export const updateJobSuccess = payload => ({
  type: ingredientsActions.ING_UPDATE_JOB_SUCCESS,
  payload: payload.data
});
export const duplicateIngredientResponse = payload => ({
  type: ingredientsActions.DUPLICATE_ING_RESPONSE,
  payload
});
export const hasFormChangesAction = payload => ({
  type: ingredientsActions.HAS_FORM_CHANGES,
  payload
});
