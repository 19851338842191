import * as ingredientsActions from './ingredients.constants';
import matchSorter from 'match-sorter';

function getTitle(locale = 'en_US', data = {}, title = '') {
  if (!data) {
    return title;
  }

  if (data[locale]) {
    return data[locale];
  } else if (data['en_US']) {
    return data['en_US'];
  }

  for (let value of Object.values(data)) {
    if (value) {
      return value;
    }
  }

  return title;
}

export function getAlternativeTitle(locale = 'en_US', data = {}) {
  if (!data) {
    return '';
  }

  if (data[locale]) {
    return data[locale];
  } else if (data['en_US']) {
    return data['en_US'];
  }

  for (let value of Object.values(data)) {
    if (value) {
      return value;
    }
  }

  return '';
}

function searchIngs(key, data, userLocale = 'en_US') {
  return matchSorter(data, key, {
    keys: [
      {
        threshold: matchSorter.rankings.CONTAINS,
        key: item => getTitle(userLocale, item.languages, item.title)
      },
      {
        threshold: matchSorter.rankings.CONTAINS,
        key: item => getAlternativeTitle(userLocale, item.alternativeTitle)
      }
    ]
  });
}

const initialState = {
  data: null,
  originalData: null,
  duplicatedList: [],
  searchKey: '',
  userLocale: '',
  imageLoader: true,
  ingredientSaveLoader: false,
  imageUrl: '',
  selectedIngredient: null,
  selectedIngredientDetails: null,
  isBtnRed: false,
  isAlertShow: false,
  selectedKeys: [],
  nextSelectedkey: [],
  cancelValue: false,
  saveValue: false,
  veganChkChecked: null,
  plusButtonClick: false,
  randomNumVerify: true,
  defaultLocal: '3',
  originalImage: null,
  identifyDeleteNodeIng: false,
  ingredientLoader: false,
  addNewIngredient: false,
  randomNumberLastIngredient: 0,
  ingredintListLoader: false,
  alternatives: {},
  duplicateTitles: [],
  hasFormChanges: false
};

export default (state = initialState, { type, payload, imageLoader }) => {
  switch (type) {
    case ingredientsActions.GET_INGREDIENTS_SUCCESS: {
      const { data } = payload;

      if (state.searchKey) {
        const searchResult = searchIngs(state.searchKey, data, state.userLocale);

        return {
          ...state,
          data: searchResult,
          originalData: data
        };
      } else {
        return {
          ...state,
          data: data,
          originalData: data
        };
      }
    }

    case ingredientsActions.ADD_INGREDIENT_SUCCESS: {
      const { dataOld } = payload;
      return {
        ...state,
        data: dataOld,
        alternatives: null,
        addNewIngredient: false
      };
    }

    case ingredientsActions.DELETE_INGREDIENT: {
      return {
        ...state,
        ingredintListLoader: true
      };
    }

    case ingredientsActions.DELETE_INGREDIENT_FAIL: {
      return {
        ...state,
        ingredintListLoader: false
      };
    }

    case ingredientsActions.UPDATE_ALTERNATIVE_LIST: {
      return {
        ...state,
        alternatives: payload
      };
    }

    case ingredientsActions.DELETE_INGREDIENT_SUCCESS: {
      let { isSelectedKeyDeleted, originalData, duplicatedList, searchData } = payload;
      if (isSelectedKeyDeleted) {
        return {
          ...state,
          selectedIngredient: null,
          selectedIngredientDetails: null,
          selectedKeys: [],
          isBtnRed: false,
          ingredintListLoader: false,
          originalData: originalData,
          duplicatedList: duplicatedList,
          data: searchData
        };
      } else {
        return {
          ...state,
          ingredintListLoader: false,
          originalData: originalData,
          duplicatedList: duplicatedList,
          data: searchData
        };
      }
    }

    case ingredientsActions.HANDLE_BLUR_INGREDIENT_SUCCESS: {
      const { dataOld, originalData, duplicatedList, newItem } = payload;

      //const [firstItemInData, ...rest] = dataOld;
      //const originalData = [firstItemInData, ...state.originalData];

      if (state.searchKey) {
        const isNewItemHaSearchTerm =
          newItem.title.toLowerCase().includes(state.searchKey) ||
          newItem.serial
            .toString()
            .toLowerCase()
            .includes(state.searchKey);
        //   .includes(state.searchKey) ||
        // state.searchKey.toLowerCase().includes('i');
        if (isNewItemHaSearchTerm) {
          //const searchResult = searchIngs(state.searchKey, originalData, state.userLocale);
          return {
            ...state,
            data: dataOld,
            originalData: originalData,
            duplicatedList: duplicatedList
          };
        } else {
          return {
            ...state,
            data: originalData,
            originalData: originalData,
            duplicatedList: duplicatedList,
            searchKey: ''
          };
        }
      } else {
        return {
          ...state,
          data: dataOld,
          originalData: originalData,
          duplicatedList: duplicatedList
        };
      }
    }
    //Lakshan
    case ingredientsActions.SET_SELECTED_INGREDIENT: {
      let { selectedIngredient } = payload;
      if (selectedIngredient.length > 0) {
        return {
          ...state,
          alternatives: {},
          selectedIngredient
        };
      } else {
        return {
          ...state
        };
      }
    }

    case ingredientsActions.GET_INGREDIENT_LANGUAGES_SUCCESS: {
      const { alternatives } = payload;

      return {
        ...state,
        selectedIngredientDetails: payload.data,
        alternatives: alternatives
      };
    }
    //End  Lakshan
    case ingredientsActions.UPDATE_INGREDIENT_ORDER_SUCCESS: {
      return {
        ...state,
        data: payload.data,
        originalData: payload.data
      };
    }

    case ingredientsActions.IDENTIFY_BTN_RED_INGREDIENT: {
      return {
        ...state,
        isBtnRed: payload
      };
    }

    case ingredientsActions.PLUS_BTN_CLICK: {
      return {
        ...state,
        plusButtonClick: payload,
        addNewIngredient: true
      };
    }

    case ingredientsActions.VEGAN_CHECK_BOX_CHECKED: {
      return {
        ...state,
        veganChkChecked: payload
      };
    }

    case ingredientsActions.SHOW_ALERT_WINDOW_INGREDIENT: {
      return {
        ...state,
        isAlertShow: true
      };
    }
    case ingredientsActions.HIDE_ALERT_WINDOW_INGREDIENT: {
      return {
        ...state,
        isAlertShow: false
      };
    }

    case ingredientsActions.IDENTIFY_BTN_WITHOUT_RED_INGREDIENT: {
      return {
        ...state,
        isBtnRed: false
      };
    }

    case ingredientsActions.TREE_SELECTED_KEY_INGREDIENT: {
      return {
        ...state,
        selectedKeys: payload
      };
    }

    case ingredientsActions.NEXT_TREE_SELECTED_KEY_INGREDIENT: {
      return {
        ...state,
        nextSelectedkey: payload
      };
    }

    case ingredientsActions.SAVE_TREE_INGREDIENT_VALUE_SUCCESS: {
      return {
        ...state,
        selectedKeys: state.nextSelectedkey,
        nextSelectedkey: []
        // selectedKeys:state.selectedKeys
      };
    }

    case ingredientsActions.CANCEL_TREE_INGREDIENT_VALUE_SUCCESS: {
      return {
        ...state,
        // selectedKeys: state.selectedKeys,
        // nextSelectedkey: []
        isAlertShow: false,
        addNewIngredient: false
      };
    }

    case ingredientsActions.GET_INGREDIENT_IMAGE: {
      return {
        ...state,
        imageLoader: true
      };
    }

    //ingredientImage
    case ingredientsActions.GET_INGREDIENT_IMAGE_SUCCESS: {
      return {
        ...state,
        imageUrl: payload,
        imageLoader: imageLoader
      };
    }

    case ingredientsActions.UPDATE_INGREDIENT_IMAGE: {
      return {
        ...state,
        imageLoader: true
      };
    }

    case ingredientsActions.UPDATE_INGREDIENT_IMAGE_SUCCESS: {
      const selectedDetails = state.selectedIngredientDetails;
      selectedDetails.attributes.uploadedImageUrl = payload;
      return {
        ...state,
        imageLoader: false,
        originalImage: null,
        selectedIngredientDetails: selectedDetails
      };
    }

    case ingredientsActions.UPDATE_INGREDIENT_IMAGE_PATCH_SUCESS: {
      return {
        ...state,
        imageLoader: false,
        originalImage: null
      };
    }

    case ingredientsActions.UPDATE_INGREDIENT_IMAGE_FAILURE: {
      return {
        ...state,
        imageUrl: state.originalImage,
        originalImage: null,
        imageLoader: false
      };
    }

    case ingredientsActions.RESET_INGREDIENT_VALUES: {
      return {
        ...state,
        selectedIngredient: null,
        selectedIngredientDetails: {},
        isBtnRed: false,
        imageUrl: '',
        originalImage: null,
        ingredientLoader: true,
        duplicateTitles: []
      };
    }

    case ingredientsActions.GET_DEFUALT_LOCAL_SUCCESS_ING: {
      return {
        ...state,
        defaultLocal: payload
      };
    }
    //INGREDIENT FORM UPDATE
    case ingredientsActions.UPDATE_INGREDIENT_FORM_DETAILS_SUCCESS: {
      const selectedDetails = state.selectedIngredientDetails;
      selectedDetails.attributes = { ...payload, isNotHealthy: payload.isHealthy };
      return {
        ...state,
        selectedIngredientDetails: selectedDetails
      };
    }
    //INGREDIENT ON SAVE LOADER
    case ingredientsActions.UPDATE_SAVE_INGREDIENT_LOADER: {
      return {
        ...state,
        ingredientSaveLoader: payload
      };
    }

    case ingredientsActions.UPDATE_SAVE_INGREDIENT_LOADER_CNT: {
      return {
        ...state,
        ingredientLoader: payload
      };
    }

    case ingredientsActions.INGREDIENT_IMAGE_TEMP_IMAGE: {
      return {
        ...state,
        originalImage: state.imageUrl,
        imageUrl: payload
      };
    }

    case ingredientsActions.INGREDIENT_IMAGE_CANCEL_CHANGES: {
      return {
        ...state,
        imageUrl: state.originalImage,
        originalImage: null
      };
    }

    case ingredientsActions.IDENTIFY_GET_INGREDIENT_DATA_LOADER: {
      return {
        ...state,
        ingredientLoader: payload
      };
    }

    case ingredientsActions.DELETE_INGREDIENT_IMAGE_SUCCESS: {
      const selectedDetails = state.selectedIngredientDetails;
      selectedDetails.attributes.uploadedImageUrl = '';
      return {
        ...state,
        imageUrl: '',
        originalImage: null,
        selectedIngredientDetails: selectedDetails
      };
    }

    case ingredientsActions.VERIFY_RANDOM_NUM_GENERATE_SUCCESS: {
      return {
        ...state,
        randomNumVerify: true
      };
    }

    case ingredientsActions.REMOVE_RANDOM_NUMBER_SUCCESS: {
      return {
        ...state,
        randomNumVerify: payload
      };
    }

    case ingredientsActions.DELETE_NODE_IDENTIFY: {
      return {
        ...state,
        identifyDeleteNodeIng: payload
      };
    }

    case ingredientsActions.SET_LAST_GENERATED_INGREDIENT_RANDOM_NUMBER: {
      return {
        ...state,
        randomNumberLastIngredient: payload
      };
    }

    case ingredientsActions.RE_UPDATE_SAVE_INGREDIENT_DETAILS: {
      const { data, originalData, duplicatedList } = payload;
      return {
        ...state,
        data: [...data],
        originalData: [...originalData],
        duplicatedList: [...duplicatedList]
      };
    }

    case ingredientsActions.UPDATE_ING_DATA: {
      const { data, originalData } = payload;
      return {
        ...state,
        data: [...data],
        originalData: [...originalData]
      };
    }

    case ingredientsActions.ING_SEARCH_SUCCESS: {
      const { data } = payload;

      if (data === null) {
        return {
          ...state,
          data: state.originalData
        };
      }
      return {
        ...state,
        data: data
      };
    }

    case ingredientsActions.ING_SEARCH: {
      const { key, userLocale } = payload;

      if (!key) {
        return {
          ...state,
          searchKey: '',
          userLocale: ''
        };
      } else {
        return {
          ...state,
          searchKey: key,
          userLocale
        };
      }
    }

    case ingredientsActions.GET_DUPLICATED_LIST_SUCCESS: {
      return {
        ...state,
        duplicatedList: payload.data
      };
    }

    case ingredientsActions.GET_DUPLICATED_LIST_FAIL: {
      return {
        ...state,
        ingredientLoader: false
      };
    }
    case ingredientsActions.HAS_FORM_CHANGES: {
      return {
        ...state,
        hasFormChanges: payload
      };
    }

    case ingredientsActions.RESET_STATE: {
      return initialState;
    }

    case ingredientsActions.DUPLICATE_ING_RESPONSE: {
      const { data } = payload;
      return {
        ...state,
        duplicateTitles: data ? data : []
      };
    }

    default:
      return state;
  }
};
