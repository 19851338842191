import { actionCreator } from 'redux-action-creator';
import types from './store.constants';

export const saveNewNode = actionCreator(types.SAVE_NODE, 'key', 'values', 'newNode');
export const getStoreInformationsList = actionCreator(
  types.GET_LIST_STOREINFORMATION_VALUES,
  'data'
);
export const getStoreInformationsValueListSuccess = actionCreator(
  types.GET_STOREINFORMATION_VALUE_LIST_SUCCESS,
  'data'
);
export const updateStoreInfomationLoadedStatus = actionCreator(
  types.UPDATE_STOREINFORMATION_LOADED_STATUS,
  'data'
);
export const getStoreList = actionCreator(types.GET_STORE_LIST);
export const getStoreListSuccess = actionCreator(types.GET_STORE_LIST_SUCCESS, 'data');
export const getStoreByLocale = actionCreator(types.GET_STORE_BY_LOCALE, 'data');
export const getStoreByLocalSuccess = actionCreator(
  types.GET_STORE_BY_LOCALE_SUCCESS,
  'data',
  'specialDays',
  'oldSpecialDays'
);
export const getCategoryList = actionCreator(types.GET_CATEGORY_LIST, 'data');
export const updateCategoryLoader = actionCreator(types.UPDATE_CATEGORY_LOADER);
export const getCategoryListSuccess = actionCreator(types.GET_CATEGORY_LIST_SUCCESS, 'data');
export const getIngredientList = actionCreator(types.GET_INGREDIENT_LIST, 'data');
export const updateIngredientLoader = actionCreator(types.UPDATE_INGREDIENT_LOADER);
export const getIngredientListSuccess = actionCreator(types.GET_INGREDIENT_LIST_SUCCESS, 'data');
export const addStore = actionCreator(types.ADD_STORE, 'data');
export const editStore = actionCreator(types.EDIT_STORE, 'data');
export const deleteStore = actionCreator(types.DELETE_STORE, 'data');
export const deleteStoreSuccess = actionCreator(types.DELETE_STORE_SUCCESS, 'data');
export const updateStoreDetailLoadStatus = actionCreator(
  types.UPDATE_STORE_DETAIL_LOAD_STATUS,
  'data'
);
export const updateStoreLocale = actionCreator(types.UPDATE_STORE_LOCALE, 'data');
export const updateSaveButtonStatus = actionCreator(types.UPDATE_SAVE_BUTTON_STATUS);
export const getMayContainValueSuccess = actionCreator(
  types.GET_MAY_CONTAIN_VALUES_SUCCESS,
  'data'
);
export const mayContaintLoader = actionCreator(types.MAY_CONTAINT_LOADER, 'loader');
export const lablesListLoader = actionCreator(types.LABEL_LIST_LOADER, 'loader');
export const addSpecialDays = actionCreator(types.ADD_SPECIAL_DAYS, 'data');
export const setOldSpecialDays = actionCreator(types.SET_OLD_SPECIAL_DAYS, 'data');
export const setStoreLableImageSuccess = actionCreator(
  types.SET_STORE_LABLE_IMAGE,
  'id',
  'image',
  'indexes',
  'type'
);
export const deleteSpecialDays = actionCreator(types.DELETE_SPECIAL_DAYS, 'item');
export const updateSpecialDaysClose = actionCreator(
  types.UPDATE_SPECIAL_DAYS_CLOSE,
  'item',
  'checked'
);
export const updateSpecialDaysValidation = actionCreator(
  types.UPDATE_SPECIAL_DAYS_VALIDATION,
  'item',
  'checked'
);

export const updateSpecialDaysPreserve = actionCreator(types.UPDATE_SPECIAL_DAYS_PRESERVE, 'item');
export const reset = actionCreator(types.RESET);
export const setFilterPaymentList = actionCreator(types.SET_FILTER_PAYMENT_LIST, 'data');
export const setFilterFeatureList = actionCreator(types.SET_FILTER_FEATURE_LIST, 'data');
