import React, {Component} from "react";
import {Button, Form, Input, message} from "antd";
import IntlMessages from "util/IntlMessages";
import {injectIntl } from 'react-intl';
import { connect } from "react-redux";
import CircularProgress from "components/CircularProgress/index";
import { PASSWORD_STRENGTH } from "app/shared/rejex/index";
import { clearToken } from '../app/shared/utils/storage';

import {
  hideMessage,
  showAuthLoader,
  resetPassword
} from "appRedux/actions/Auth";

const FormItem = Form.Item;


class ResetPassword extends Component {

  // constructor(props){
  //   super(props);
  //   localStorage.removeItem('refresh_token');
  //   localStorage.removeItem('user_id');
  //   clearToken();
  // }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.showAuthLoader();
        let newValues =  {
          ...values,
          // token: this.props.match.params.id,
          history: this.props.history,
          email:this.props.email
        }
        this.props.resetPassword(newValues);
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback(this.props.intl.formatMessage({ id: "Generic.MyProfile.ChangePassword.Validation.VerifyPassword", defaultMessage: 'Two passwords that you enter is inconsistent! Minimum 7 character Password must contain a mix of letters, numbers, and/or special characters' }))
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.props.confirmDirty) {
      form.validateFields(['confirm'], {force: true});
    }
    callback();
  };

  componentDidUpdate() {

    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);

    }

    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    const { showMessage, loader, alertMessage, intl } = this.props;
    const {getFieldDecorator} = this.props.form;

    const mediumRegex = PASSWORD_STRENGTH;

    return (
      <div className="gx-login-container gx-loader-container">
        <div className="gx-login-content">

          <div className="gx-login-header">
            <img src={require("assets/images/logo-white.png")} alt="wieldy" title="wieldy"/>
          </div>
          <div className="gx-mb-4 fixedCotentWithTxtAutontication">
            <h2>
            Reset Password
              {/* <IntlMessages defaultMessage='Reset Password' id="AuthenticationPages.ResetPassword.ResetPassword"/> */}
              </h2>
            <p>
            Enter a new password for your account
              {/* <IntlMessages defaultMessage='Enter a new password for your account' id="AuthenticationPages.ResetPassword.Txt_content"/> */}
              </p>
          </div>

          <Form layout="vertical" onSubmit={this.handleSubmit} className="gx-login-form gx-form-row0  validation-align">

            <FormItem>
              {getFieldDecorator('password', {
                rules: [{
                  required: true, 
                  message:'Please input your Password!'
                  // message: intl.formatMessage({ id: "Generic.MyProfile.ChangePassword.ValidationMessages.Msg_InputRequired", defaultMessage: 'Please input your Password!' }),
                }, {
                  validator: this.validateToNextPassword,
                },{
                  min: 7,  
                  message:'Minimum 7 character Password must contain a mix of letters, numbers, and/or special characters'
                  // message: intl.formatMessage({ id: "Generic.MyProfile.ChangePassword.ValidationMessages.Msg_MinLimitReach", defaultMessage: 'Minimum 7 character Password must contain a mix of letters, numbers, and/or special characters' })
                },{
                  max: 32,  
                  message:'Maximum 32 character Password must contain a mix of letters, numbers, and/or special characters'
                  // message: intl.formatMessage({ id: "Generic.MyProfile.ChangePassword.ValidationMessages.Msg_MaxLimitReach", defaultMessage: 'Maximum 32 character Password must contain a mix of letters, numbers, and/or special characters' })
                },{
                  pattern: mediumRegex,
message:'Password must contain a mix of letters, numbers, and/or special characters' 
                  //  message: intl.formatMessage({ id: "Generic.MyProfile.ChangePassword.ValidationMessages.Msg_FromatMissMatch", defaultMessage: 'Password must contain a mix of letters, numbers, and/or special characters' })
                }],
              })(
                // <Input type="password" placeholder= {intl.formatMessage({ id: "AuthenticationPages.ResetPassword.NewPassword", defaultMessage: 'New Password' })}/>
                <Input type="password" placeholder=  'New Password'/>
              )}
            </FormItem>

            <FormItem>
              {getFieldDecorator('confirm', {
                rules: [{
                  required: true,
                  message:'Please confirm your Password!'
                  //  message: intl.formatMessage({ id: "Generic.MyProfile.ChangePassword.ValidationMessages.Msg_InputRequired", defaultMessage: 'Please confirm your Password!' }),
                }, {
                  validator: this.compareToFirstPassword,
                },{
                  min: 7,  
                  message:'Minimum 7 character Password must contain a mix of letters, numbers, and/or special characters'
                  // message: intl.formatMessage({ id: "Generic.MyProfile.ChangePassword.ValidationMessages.Msg_MinLimitReach", defaultMessage: 'Minimum 7 character Password must contain a mix of letters, numbers, and/or special characters' })
                },{
                   max: 32,
                   message: 'Maximum 32 character Password must contain a mix of letters, numbers, and/or special characters' 
                    //  message: intl.formatMessage({ id: "Generic.MyProfile.ChangePassword.ValidationMessages.Msg_MaxLimitReach", defaultMessage: 'Maximum 32 character Password must contain a mix of letters, numbers, and/or special characters' })
                },{
                  pattern: mediumRegex, 
                  message:'Password must contain a mix of letters, numbers, and/or special characters'
                  // message: intl.formatMessage({ id: "Generic.MyProfile.ChangePassword.ValidationMessages.Msg_FromatMissMatch", defaultMessage: 'Password must contain a mix of letters, numbers, and/or special characters' })
                }],
              })(
                <Input placeholder='Retype New Password' type="password" onBlur={this.handleConfirmBlur}/>
                // <Input placeholder={intl.formatMessage({ id: "AuthenticationPages.ResetPassword.RetypeNewPassword", defaultMessage: 'Retype New Password' })} type="password" onBlur={this.handleConfirmBlur}/>
              )}
            </FormItem>

            <FormItem>
              <Button type="primary" htmlType="submit">
              RESET
                {/* <IntlMessages defaultMessage='RESET' id="Generic.Buttons.Reset"/> */}
              </Button>
            </FormItem>
          
          </Form>
          {loader ?
              <div className="gx-loader-view">
                <CircularProgress/>
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}

        </div>
      </div>
    );
  }
}

const WrappedResetPasswordForm = Form.create()(ResetPassword);
const withIntl = injectIntl(WrappedResetPasswordForm);

const mapPropToState = ({auth}) => {
  const { loader, alertMessage, showMessage, authUser,email} = auth;
  return { loader, alertMessage, showMessage, authUser,email};

}

export default connect(mapPropToState, {
  resetPassword,
  showAuthLoader,
  hideMessage
})(withIntl);
