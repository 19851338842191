import productTypes from './catalogue_products.constants';

function buildCategoryHierarchy(fullCategory = [], locale) {
  return fullCategory
    .map(cat => {
      if (cat[locale]) {
        return cat[locale];
      } else if (cat['en_US']) {
        return cat['en_US'];
      } else {
        for (let value of Object.values(cat)) {
          if (value) {
            return value;
          }
        }
      }

      return '';
    })
    .join(' => ');
}

function transformProduct(product, locale) {
  const fullCategory = buildCategoryHierarchy(product.category_hover, locale);

  if (!product.category && product.category_hover && product.category_hover.length) {
    const categories = product.category_hover;
    const lastIndex = categories.length - 1;
    const item = categories[lastIndex];

    if (item['en_US']) {
      product.category = item['en_US'];
    } else {
      const itemArray = Object.values(item);
      product.category = itemArray[1];
    }
  } else if (!product.category[locale] && product.category_hover && product.category_hover.length) {
    const categories = product.category_hover;
    const lastIndex = categories.length - 1;
    const item = categories[lastIndex];

    if (item['en_US']) {
      product.category = item['en_US'];
    } else {
      const itemArray = Object.values(item);
      product.category = itemArray[1];
    }
  }

  return {
    id: product.id,
    name: product.name ? (product.name[locale] ? product.name[locale] : '') : '',
    ean: product.ean,
    category: product.category
      ? product.category[locale]
        ? product.category[locale]
        : product.category
      : '',
    fullCategory: fullCategory,
    manufacturer: product.manufacturer
      ? product.manufacturer.name
        ? product.manufacturer.name
        : ''
      : '',
    country: product.country,
    user: product.user ? (product.user.name ? product.user.name : '') : '',
    userPic: '',
    lastPrice: '',
    scanned: '',
    lastEdit: product.last_edit || '',
    isVegan: product.vegan_type,
    country_code: product.country_code
  };
}

function mapProductData(productList, locale) {
  return productList.map(item => transformProduct(item, locale));
}

const initialState = {
  data: [],
  pagination: { total: 0, current: 1, pageSize: 50 },
  loader: false,
  searchLoader: false,
  searchSuggestions: [],
  tags: []
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case productTypes.SET_LOADER: {
      const { loader } = payload;
      return {
        ...state,
        loader
      };
    }

    case productTypes.FETCH_ROWS: {
      return {
        ...state,
        loader: true
      };
    }

    case productTypes.FETCH_ROWS_SUCCESS: {
      const { data, config, locale } = payload;
      const transformedData = mapProductData(data, locale);

      return {
        ...state,
        data: transformedData,
        pagination: config.pagination,
        sort: config.sort,
        loader: false
      };
    }

    case productTypes.FETCH_ROWS_FAIL: {
      return {
        ...state,
        loader: false
      };
    }

    case productTypes.SET_PRODUCT_IMAGE: {
      const { image, indexes } = payload;
      let data = state.data;

      for (let userImage of image) {
        const itemIndex = indexes[userImage.id];
        if (itemIndex || itemIndex === 0) {
          const item = data[itemIndex];
          const clonedItem = { ...item };

          if (clonedItem && clonedItem.id) {
            clonedItem.productPic = userImage.image;
            data = [...data.slice(0, itemIndex), clonedItem, ...data.slice(itemIndex + 1)];
          }
        }
      }

      return { ...state, data };
    }

    case productTypes.SET_PRODUCT_USER_IMAGE: {
      const { image, indexes } = payload;
      let data = state.data;
      for (let userImage of image) {
        const itemIndex = indexes[userImage.id];
        if (itemIndex || itemIndex === 0) {
          const item = data[itemIndex];
          const clonedItem = { ...item };

          if (clonedItem && clonedItem.id) {
            clonedItem.userPic = userImage.image;
            data = [...data.slice(0, itemIndex), clonedItem, ...data.slice(itemIndex + 1)];
          }
        }
      }

      return { ...state, data };
    }

    case productTypes.RESET: {
      return initialState;
    }

    case productTypes.SET_SEARCH_LOADER: {
      const { searchLoader } = payload;

      return {
        ...state,
        searchLoader
      };
    }

    case productTypes.GET_SEARCH_SUGGESTION_SUCCESS: {
      const { searchResults } = payload;

      return {
        ...state,
        searchSuggestions: searchResults
      };
    }

    case productTypes.ADD_TAG_SUCCESS: {
      const { tags } = payload;

      return {
        ...state,
        tags
      };
    }

    case productTypes.DELETE_TAG_SUCCESS: {
      const { tags } = payload;

      return {
        ...state,
        tags
      };
    }

    case productTypes.SET_SEARCH_IDS: {
      const { ids } = payload;

      return {
        ...state,
        searchProductIds: ids
      };
    }

    default:
      return state;
  }
};
