import { actionCreator } from 'redux-action-creator';
import manufacturerTypes from './catalogue_manufacturer.constants';

export const getDataList = actionCreator(manufacturerTypes.GET_DATA_LIST, 'pagination', 'sort');
export const getDataListSuccess = actionCreator(
  manufacturerTypes.GET_DATA_LIST_SUCCESS,
  'dataList',
  'pagination',
  'sort'
);

export const getDataListFail = actionCreator(manufacturerTypes.GET_DATA_LIST_FAIL);

export const deleteManufacturer = actionCreator(manufacturerTypes.DELETE, 'row');
export const deleteManufacturerSuccess = actionCreator(manufacturerTypes.DELETE_SUCCESS, 'id');
export const deleteManufacturerFail = actionCreator(manufacturerTypes.DELETE_FAIL);
export const setManufacturerImage = actionCreator(
  manufacturerTypes.SET_MANUFACTURER_IMG,
  'id',
  'image',
  'indexes'
);
export const setSearchLoader = actionCreator(manufacturerTypes.SET_SEARCH_LOADER, 'searchLoader');
export const getSearchSuggestion = actionCreator(
  manufacturerTypes.GET_SEARCH_SUGGESTION,
  'searchValue'
);
export const getSearchSuggestionSuccess = actionCreator(
  manufacturerTypes.GET_SEARCH_SUGGESTION_SUCCESS,
  'searchResults'
);

export const addTags = actionCreator(manufacturerTypes.ADD_TAG, 'tag', 'group');
export const addTagsSucess = actionCreator(manufacturerTypes.ADD_TAG_SUCCESS, 'tags');
export const deleteTag = actionCreator(manufacturerTypes.DELETE_TAG, 'tag');
export const deleteTagSuccess = actionCreator(manufacturerTypes.DELETE_TAG_SUCCESS, 'tags');
export const setFilterIdList = actionCreator(manufacturerTypes.SET_ID_LIST, 'idList');
export const reset = actionCreator(manufacturerTypes.RESET);
