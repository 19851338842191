import * as types from './showInMobile.constants'

export const getCategoryShowInMobileHome = () =>{
    return{type:types.GET_SHOW_IN_MOBILE_CATEGORIES}
}

export const getCategoryShowInMobileHomeSuccess = (payload) =>{
    return{type:types.GET_SHOW_IN_MOBILE_CATEGORIES_SUCCESS, payload}
}

export const CategoryShowInMobileHomePositionChangeSuccess = (payload) => {
    return{type: types.SHOW_IN_MOBILE_POSITION_CHANGE_SUCCESS,payload}
} 