import { actionCreator } from 'redux-action-creator';
import { brandTypes } from './brandModal.constants';

export const getBrandList = actionCreator(brandTypes.GET_LIST);
export const getBrandListSuccess = actionCreator(brandTypes.GET_LIST_SUCCESS, 'data');
export const getBrandsListWorkerSuccess = actionCreator(
  brandTypes.GET_BRAND__WORKER_SUCCESS,
  'data'
);
export const updateBrandModal = actionCreator(brandTypes.UPDATE_MODAL_STATE, 'state');
export const saveBrandDetails = actionCreator(brandTypes.SAVE_DETAILS, 'form', 'popupFrom', 'cb');
export const saveBrandDetailsSuccess = actionCreator(brandTypes.SAVE_DETAILS_SUCCESS, 'data', 'id');
export const saveBrandDetailsFail = actionCreator(brandTypes.SAVE_DETAILS_FAIL);
export const editBrand = actionCreator(brandTypes.EDIT, 'id', 'popupFrom');
export const editBrandSuccess = actionCreator(brandTypes.EDIT_SUCCESS, 'data', 'id', 'image');
export const editBrandFail = actionCreator(brandTypes.EDIT_FAIL);
export const showBrandLoader = actionCreator(brandTypes.SHOW_LOADER);
export const hideBrandLoader = actionCreator(brandTypes.HIDE_LOADER);
export const selectBrandImage = actionCreator(brandTypes.SET_TEMP_IMG, 'img', 'file');
export const removeSelectedBrandImage = actionCreator(brandTypes.REMOVE_TEMP_IMG);
export const saveBrandImage = actionCreator(brandTypes.SAVE_IMG, 'popupFrom');
export const saveBrandImageSuccess = actionCreator(
  brandTypes.SAVE_IMG_SUCCESS,
  'img',
  'item',
  'popupFrom'
);
export const saveBrandImageFail = actionCreator(brandTypes.SAVE_IMG_FAIL);
export const removeBrandImage = actionCreator(brandTypes.REMOVE_IMG, 'popupFrom');
export const removeBrandImageSuccess = actionCreator(brandTypes.REMOVE_IMG_SUCCESS, 'popupFrom');
export const removeBrandImageFail = actionCreator(brandTypes.REMOVE_IMG_FAIL);
export const setInitialTitle = actionCreator(brandTypes.SET_INITIAL_TITLE, 'title', 'manufacturer');
export const removeInitialTitle = actionCreator(brandTypes.REMOVE_INITIAL_TITLE);
export const setCollectionId = actionCreator(brandTypes.SET_COLLECTION_ID, 'id');
export const removeCollectionId = actionCreator(brandTypes.RESET_COLLECTION_ID);
export const openCatalogueModal = actionCreator(brandTypes.OPEN_CATALOGUE);
export const reset = actionCreator(brandTypes.RESET);
