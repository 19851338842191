import moderationTypes from './list.constants';

const initialState = {
  dataSource: [],
  tableLoader: true,
  sort: { order: 'desc', columnKey: 'edit' },
  pagination: { total: 0, pageSize: 50, current: 1 },
  searchLoader: false,
  searchData: [],
  searchResults: [],
  tags: [],
  idList: [],
  originalData: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case moderationTypes.GET_DATA_LIST: {
      return { ...state, tableLoader: true };
    }

    case moderationTypes.GET_DATA_LIST_SUCCESS: {
      const { dataList, pagination, sort, originalData } = action.payload;

      return {
        ...state,
        dataSource: dataList,
        pagination: { ...pagination },
        sort: { ...sort },
        tableLoader: false,
        originalData: originalData
      };
    }
    case moderationTypes.GET_DATA_LIST_FAIL: {
      return { ...state, tableLoader: false };
    }

    case moderationTypes.SET_DATA_LIST_PRODUCT_IMAGE_SUCCESS: {
      const {
        payload: { image, indexes }
      } = action;

      let data = state.dataSource;
      for (let ProductImage of image) {
        const itemIndex = indexes[ProductImage.id];
        if (itemIndex || itemIndex === 0) {
          const item = data[itemIndex];
          const clonedItem = { ...item };
          if (clonedItem && clonedItem._id) {
            clonedItem.product_image = ProductImage.image;
            data = [...data.slice(0, itemIndex), clonedItem, ...data.slice(itemIndex + 1)];
          }
        }
      }

      return { ...state, dataSource: data };
    }

    case moderationTypes.SET_DATA_LIST_STORE_IMAGE_SUCCESS: {
      const {
        payload: { image, indexes }
      } = action;

      let data = state.dataSource;
      for (let StoreImage of image) {
        const itemIndex = indexes[StoreImage.id];

        if (itemIndex || itemIndex === 0) {
          const item = data[itemIndex];
          const clonedItem = { ...item };
          if (clonedItem && clonedItem._id) {
            clonedItem.store_image = StoreImage.image;
            data = [...data.slice(0, itemIndex), clonedItem, ...data.slice(itemIndex + 1)];
          }
        }
      }

      return { ...state, dataSource: data };
    }

    case moderationTypes.SET_USER_PROFILE_IMAGE_SUCCESS: {
      const {
        payload: { image }
      } = action;
      let data = state.dataSource;

      for (let UserImage of image) {
        const itemIndex = data.findIndex(item => UserImage.tiketId === item._id);

        if (itemIndex || itemIndex === 0) {
          const item = data[itemIndex];

          const clonedItem = { ...item };

          if (clonedItem && clonedItem._id) {
            clonedItem.userImage = UserImage.userImage;

            data = [...data.slice(0, itemIndex), clonedItem, ...data.slice(itemIndex + 1)];
          }
        }
      }

      return { ...state, dataSource: data };
    }
    case moderationTypes.DELETE_TICKET_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        originalData: data
      };
    }
    default:
      return state;
  }
};
