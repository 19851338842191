import { actionCreator } from 'redux-action-creator';
import distributorTypes from './catalogue_distributor.constants';

export const getDataList = actionCreator(distributorTypes.GET_DATA_LIST, 'pagination', 'sort');
export const getDataListSuccess = actionCreator(
  distributorTypes.GET_DATA_LIST_SUCCESS,
  'dataList',
  'pagination',
  'sort'
);
export const getDataListFail = actionCreator(distributorTypes.GET_DATA_LIST_FAIL);
export const setDataListImageSuccess = actionCreator(
  distributorTypes.SET_DATA_LIST_IMAGE_SUCCESS,
  'id',
  'image',
  'indexes'
);
export const deleteTableRow = actionCreator(distributorTypes.DELETE_TABLE_ROW, 'record');
export const deleteTableRowSuccess = actionCreator(distributorTypes.DELETE_TABLE_ROW_SUCCESS);
export const reset = actionCreator(distributorTypes.RESET);

export const setSearchLoader = actionCreator(distributorTypes.SET_SEARCH_LOADER, 'searchLoader');
export const getSearchSuggestion = actionCreator(
  distributorTypes.GET_SEARCH_SUGGESTION,
  'searchValue'
);
export const getSearchSuggestionSuccess = actionCreator(
  distributorTypes.GET_SEARCH_SUGGESTION_SUCCESS,
  'searchResults'
);

export const addTags = actionCreator(distributorTypes.ADD_TAG, 'tag', 'group');
export const addTagsSucess = actionCreator(distributorTypes.ADD_TAG_SUCCESS, 'tags');
export const deleteTag = actionCreator(distributorTypes.DELETE_TAG, 'tag');
export const deleteTagSuccess = actionCreator(distributorTypes.DELETE_TAG_SUCCESS, 'tags');
export const setFilterIdList = actionCreator(distributorTypes.SET_ID_LIST, 'idList');
