import types from './store.constants';

const initialState = {
  storeData: null,
  storeList: [],
  storeDetailsList: {
    comparison: [],
    energy: [],
    nutritionalValue: [],
    vitamins: [],
    minerals: [],
    other: [],
    commonSupplements: [],
    features: [],
    mobileapps: [],
    socialmedias: [],
    acceptedpayments: [],
    storetypes: [],
    openHours: {}
  },
  specialDays: [],
  oldSpecialDays: [],
  mayContainList: [],
  isStoreDetailsLoaded: true,
  categoryArray: [],
  ingredientsArray: [],
  isSaveModalOpen: false,
  ingredientLoader: true,
  categoryLoader: true,
  storeDetailsLoaded: true,
  saveButtonClick: false,
  productLocale: '',
  mayContainLoader: true,
  filterFeatureList: [],
  filterPaymentList: [],
  labelsLoader: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CATEGORY_LIST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        categoryArray: data
      };
    }
    case types.UPDATE_CATEGORY_LOADER: {
      return {
        ...state,
        categoryLoader: false,
        saveButtonClick: false
      };
    }
    case types.GET_INGREDIENT_LIST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        ingredientsArray: data
      };
    }
    case types.UPDATE_INGREDIENT_LOADER: {
      return {
        ...state,
        ingredientLoader: false
      };
    }
    case types.GET_STORE_BY_LOCALE: {
      return {
        ...state,
        ingredientLoader: true,
        categoryLoader: true
      };
    }
    case types.GET_STOREINFORMATION_VALUE_LIST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        storeDetailsList: {
          ...data
        }
      };
    }

    case types.SET_STORE_LABLE_IMAGE: {
      const {
        payload: { image, indexes, type }
      } = action;
      let OriginalData = JSON.parse(JSON.stringify(state.storeDetailsList));
      let data = JSON.parse(JSON.stringify(state.storeDetailsList[type]));
      if (image && image.length !== 0) {
        for (let distributorImage of image) {
          const itemIndex = indexes[distributorImage.id];

          if (itemIndex || itemIndex === 0) {
            const item = data[itemIndex];

            const clonedItem = JSON.parse(JSON.stringify(item));

            if (clonedItem && clonedItem.id) {
              clonedItem.imageUrl = distributorImage.image;
              data = [...data.slice(0, itemIndex), clonedItem, ...data.slice(itemIndex + 1)];
            }
          }
        }
      }

      return {
        ...state,
        storeDetailsList: {
          ...OriginalData,
          [type]: [...data]
        }
      };
    }

    case types.GET_MAY_CONTAIN_VALUES_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        mayContainList: data
      };
    }

    case types.MAY_CONTAINT_LOADER: {
      const { loader } = action.payload;
      return {
        ...state,
        mayContainLoader: loader
      };
    }

    case types.LABEL_LIST_LOADER: {
      const { loader } = action.payload;
      return {
        ...state,
        labelsLoader: loader
      };
    }

    case types.UPDATE_STOREINFORMATION_LOADED_STATUS: {
      const { data } = action.payload;

      return {
        ...state,
        storeDetailsLoaded: data
      };
    }

    case types.GET_STORE_LIST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        storeList: data
      };
    }
    case types.GET_STORE_BY_LOCALE_SUCCESS: {
      const { data, specialDays, oldSpecialDays } = action.payload;

      return {
        ...state,
        storeData: data,
        specialDays: specialDays ? specialDays : [],
        oldSpecialDays: oldSpecialDays ? oldSpecialDays : []
      };
    }
    case types.ADD_STORE: {
      return {
        ...state,
        saveButtonClick: true
      };
    }
    case types.EDIT_STORE: {
      return {
        ...state,
        saveButtonClick: true
      };
    }

    case types.UPDATE_SAVE_BUTTON_STATUS: {
      return {
        ...state,
        saveButtonClick: false
      };
    }

    case types.DELETE_STORE_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        storeList: data
      };
    }

    case types.UPDATE_STORE_DETAIL_LOAD_STATUS: {
      const { data } = action.payload;

      return {
        ...state,
        isStoreDetailsLoaded: data
      };
    }

    case types.UPDATE_STORE_LOCALE: {
      const { data } = action.payload;

      return {
        ...state,
        productLocale: data
      };
    }

    case types.ADD_SPECIAL_DAYS: {
      const { data } = action.payload;

      return {
        ...state,
        specialDays: [...state.specialDays, data]
      };
    }

    case types.DELETE_SPECIAL_DAYS: {
      const { item } = action.payload;

      let arrayFilterd = state.specialDays.filter(itemObj => itemObj.key !== item.key);

      return {
        ...state,
        specialDays: arrayFilterd
      };
    }
    case types.UPDATE_SPECIAL_DAYS_CLOSE: {
      const { item, checked } = action.payload;

      let arrayFilterd = state.specialDays.map(function(itemObj) {
        if (itemObj.key == item.key) {
          if (checked) {
            itemObj.isClose = true;
          } else {
            itemObj.isClose = false;
          }
        }
        return itemObj;
      });

      return {
        ...state,
        specialDays: arrayFilterd
      };
    }
    case types.UPDATE_SPECIAL_DAYS_PRESERVE: {
      const { item } = action.payload;

      let arrayFilterd = state.specialDays.map(function(itemObj) {
        if (itemObj.key == item.key) {
          itemObj.isPreserve = false;
        } else {
          itemObj.isPreserve = true;
        }
        return itemObj;
      });

      return {
        ...state,
        specialDays: arrayFilterd
      };
    }
    case types.UPDATE_SPECIAL_DAYS_VALIDATION: {
      const { item, checked } = action.payload;

      let arrayFilterd = state.specialDays.map(function(itemObj) {
        if (itemObj.key == item.key) {
          if (checked) {
            itemObj.isHideValidation = true;
          } else {
            itemObj.isHideValidation = false;
          }
        }
        return itemObj;
      });

      return {
        ...state,
        specialDays: arrayFilterd
      };
    }

    case types.SET_FILTER_PAYMENT_LIST: {
      const { data } = action.payload;

      return {
        ...state,
        filterPaymentList: data
      };
    }

    case types.SET_FILTER_FEATURE_LIST: {
      const { data } = action.payload;

      return {
        ...state,
        filterFeatureList: data
      };
    }

    case types.SET_OLD_SPECIAL_DAYS: {
      const { data } = action.payload;

      return {
        ...state,
        oldSpecialDays: data
      };
    }

    case types.RESET: {
      return {
        ...state,
        saveButtonClick: false,
        specialDays: [],
        storeDetailsList: []
      };
    }

    default: {
      return state;
    }
  }
};
