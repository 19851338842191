import {
  takeLatest,
  all,
  fork,
  put,
  call,
  select,
  cancelled,
  cancel,
  race,
  take
} from 'redux-saga/effects';
import manufacturerTypes from './catalogue_manufacturer.constants';
import {
  getDataListSuccess,
  getDataListFail,
  getDataList,
  deleteManufacturerSuccess,
  setManufacturerImage,
  getSearchSuggestionSuccess,
  addTagsSucess,
  deleteTagSuccess,
  setFilterIdList
} from './catalogue_manufacturer.actions';
import { doRequest } from 'app/shared/redux/sagas/api';

import { message } from 'antd';
import { filterCountryEmojis } from '../../../../../shared/utils/index';

/**@description utils */
function* getIndexedManufactureeIds() {
  const items = yield select(state => state.indexedItem.manufacturerCatalogue);
  return items;
}

// get flag country list
function* getCountryFlagList(firstDataList) {
  let newDataListArray = [];
  const countryList = yield select(s => s.indexedItem.countries);
  for (let index = 0; index < firstDataList.length; index++) {
    const item = firstDataList[index];
    // get country flag with  country name
    if (item.country) {
      item.country_name = countryList[item.country].name;
      item.country_code =
        countryList[item.country] && countryList[item.country].code
          ? countryList[item.country].code.toLowerCase()
          : '';
    } else {
      item.country_name = '';
      item.country_code = '';
    }
    newDataListArray.push(item);
  }
  return newDataListArray;
}

function chunk(array, size) {
  const chunked_arr = [];
  let index = 0;
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
}

function transformOptionValues(data = {}, countryList) {
  const countries = data['country_names']['values'].map(id => {
    return id ? countryList[id] : '';
  });

  return [
    {
      title: 'SearchName',
      values: data['manu_names']['values']
    },
    {
      title: 'Countries',
      values: countries
    }
  ];
}

function transformOptionValuesToPostData(tags = [], countries) {
  const values = { manu_names: { values: [] }, country_names: { values: [] } };
  for (let tag of tags) {
    if (tag.group === 'SearchName') {
      values.manu_names.values.push(tag.value);
    } else {
      values.country_names.values.push(tag.value);
    }
  }

  return values;
}

function* getLocalizedMessage(key) {
  const intlSelector = state => state.intlReact.intl;
  const intl = yield select(intlSelector);
  return intl.formatMessage({ id: key });
}

/** END */

function* getRegularDataList(paramsObj) {
  const response = yield call(doRequest, {
    url: '/prodmanu/list/manufacturers',
    method: 'GET',
    params: paramsObj
  });

  return response;
}

function* getSearchDataList(data) {
  const response = yield call(doRequest, {
    url: '/prodmanu/search/idlist/MANU',
    method: 'POST',
    data: data
  });

  return response;
}

function* getFirstdataList({ payload }) {
  let getImageTask;

  const { pagination, sort } = payload;

  const { idList, tags } = yield select(({ catalogue: { manufacturer: { idList, tags } } }) => ({
    idList,
    tags
  }));

  const sortData = yield call(sortTableRow, sort);
  const dataRange = yield call(calculatedRequstedDataList, pagination);

  try {
    let response;

    if (tags.length) {
      const data = {
        offset: dataRange.offset,
        count: dataRange.count,
        sort_key: sortData.columnKey,
        order: sortData.order,
        type: 'MANU'
      };
      data.idlist = idList;
      response = yield call(getSearchDataList, data);
    } else {
      const paramsObj = {
        offset: dataRange.offset,
        count: dataRange.count,
        sort_by: sortData.columnKey,
        order: sortData.order
      };
      response = yield call(getRegularDataList, paramsObj);
    }

    if (response.status === 200) {
      const dataList = response.data;

      if (dataList.docs && dataList.docs.length > 0) {
        dataList.docs = yield call(getCountryFlagList, dataList.docs);
        getImageTask = yield fork(getManufacturersimage, dataList.docs);
      }

      pagination.total = dataList.data.total;

      yield put(getDataListSuccess({ dataList: dataList, pagination: pagination, sort: sort }));

      /** pause execution to find if the current task is cancelled */
      const { reset } = yield race({
        get: take(manufacturerTypes.GET_DATA_LIST),
        reset: take(manufacturerTypes.RESET)
      });

      if (reset) {
        yield cancel(getImageTask);
      }
    }
  } catch (error) {
    console.log(error);

    yield put(getDataListFail());
    const errorMessage = yield call(
      getLocalizedMessage,
      'Generic.ApiMessages.Catalogue.Manufacturer.GetListError'
    );
    message.error(errorMessage);
  } finally {
    if (yield cancelled()) {
      if (getImageTask) {
        yield cancel(getImageTask);
      }
    }
  }
}

// get sorted value
function sortTableRow(sort = {}) {
  if (sort.columnKey && sort.order) {
    if (sort.order == 'ascend') {
      sort.order = 'asc';
    }
    if (sort.order == 'descend') {
      sort.order = 'desc';
    }
  } else {
    sort.columnKey = 'create';
    sort.order = 'desc';
  }
  return sort;
}

// get data range in requested
function calculatedRequstedDataList(pagination = {}) {
  let current = pagination.current || 1;
  let pageSize = pagination.pageSize || 5;

  let newDataValue = pageSize * (current - 1) + 1;

  let reqDataObj = {};

  if (current > 1) {
    reqDataObj.offset = newDataValue;
    reqDataObj.count = pageSize;
  } else {
    reqDataObj.offset = current;
    reqDataObj.count = pageSize;
  }

  return reqDataObj;
}

// get manufacture avatar image
function* getManufacturerImage(id, collectionId) {
  if (!collectionId) return;
  try {
    const imageResponse = yield call(doRequest, {
      url: `unimup/objctupload/manuf/${collectionId}`,
      method: 'GET'
    });

    const { data } = imageResponse;

    if (data.pages.length) {
      const imageUrl = data.pages[0].thumbnail.fetchUrl;
      const imageResponse2 = yield call(doRequest, {
        url: `unimup/objctupload${imageUrl}`,
        method: 'GET',
        responseType: 'blob'
      });

      const { data: imageData } = imageResponse2;
      const image = URL.createObjectURL(imageData);
      return { id, image };
    }
  } catch (error) {}
}

function* getManufacturersimage(dataList = []) {
  const chunkedList = yield call(chunk, dataList, 5);

  for (let list of chunkedList) {
    const requests = list.map(item => call(getManufacturerImage, item._id, item.image));

    const responses = yield all(requests);
    const filtertedReponse = responses.filter(response => (response ? true : false));

    const indexes = yield call(getIndexedManufactureeIds);
    yield put(setManufacturerImage({ image: filtertedReponse, indexes }));
  }
}

function* deleteManufacturerTableRow({ payload }) {
  const id = payload.row._id;
  const { sort, pagination } = yield select(state => state.catalogue.manufacturer);
  try {
    const response = yield call(doRequest, {
      url: `prodmanu/manufacture/${id}`,
      method: 'DELETE'
    });

    if (response.status === 200) {
      yield put(getDataList({ pagination: pagination, sort: sort }));
      yield put(deleteManufacturerSuccess({ id }));
    }
  } catch (error) {
    console.log(error);
    const errorMessage = yield call(
      getLocalizedMessage,
      'Generic.ApiMessages.Catalogue.Manufacturer.ManufacturerDeleteError'
    );
    message.error(errorMessage);
  }
}

export function* getSearchResults(tags = []) {
  try {
    const modifiedTags = yield call(filterCountryEmojis, tags);
    const requestBody = yield call(transformOptionValuesToPostData, modifiedTags);
    const response = yield call(doRequest, {
      method: 'POST',
      url: `/prodmanu/searchbox/bykeys/manu`,
      data: requestBody
    });

    const productIds = response.data;

    yield put(setFilterIdList({ idList: productIds }));

    const { sort, pagination } = yield select(state => state.catalogue.manufacturer);

    // reset offset
    pagination.current = 1;

    yield put(getDataList({ pagination: pagination, sort: sort }));
  } catch (error) {
    console.error(error);
    let intl = yield select(state => state.intlReact.intl);
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Catalogue.Filter.GetError'
      })
    );
  }
}

export function* getSearchSuggestion({ payload }) {
  const { searchValue } = payload;

  if (!searchValue) {
    // clear the current suggestion list
    yield put(getSearchSuggestionSuccess({ searchResults: [] }));
    return;
  }

  try {
    const response = yield call(doRequest, {
      url: '/prodmanu/searchbox/phrase/manu/' + encodeURIComponent(searchValue),
      method: 'GET'
    });

    const countries = yield select(s => s.indexedItem.countries);

    const dataList = yield call(transformOptionValues, response.data, countries);

    yield put(getSearchSuggestionSuccess({ searchResults: dataList }));
  } catch (error) {
    let intl = yield select(state => state.intlReact.intl);
    message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Catalogue.Filter.GetError'
      })
    );
    console.log(error);
  } finally {
  }
}

function* addTag({ payload }) {
  const { tag, group } = payload;

  let intl = yield select(state => state.intlReact.intl);
  let currentTags = yield select(s => s.catalogue.manufacturer.tags);
  let haveAlreadyAtatched = currentTags.find(item => item.value === tag);

  if (haveAlreadyAtatched) {
    yield put(getSearchSuggestionSuccess({ searchResults: [] }));

    return message.error(
      intl.formatMessage({
        id: 'Generic.ApiMessages.Catalogue.Manufacturer.FilterAlreadyAdd'
      })
    );
  }

  const newTags = [...currentTags, { value: tag, group }];

  yield put(addTagsSucess({ tags: newTags }));
  yield put(getSearchSuggestionSuccess({ searchResults: [] }));
  yield call(getSearchResults, newTags);
}

function* deleteTag({ payload }) {
  const { tag } = payload;

  let currentTags = yield select(s => s.catalogue.manufacturer.tags);
  let newTags = currentTags.filter(item => item.value !== tag.value);

  yield put(deleteTagSuccess({ tags: newTags }));

  if (newTags.length === 0) {
    yield put(setFilterIdList({ idList: [] }));
    const { sort, pagination } = yield select(state => state.catalogue.manufacturer);
    // reset offset
    pagination.current = 1;
    yield put(getDataList({ pagination: pagination, sort: sort }));
  } else {
    yield call(getSearchResults, newTags);
  }
}

/**
 * Watchers
 */

function* getFirstdataListSagaWatcher() {
  yield takeLatest(manufacturerTypes.GET_DATA_LIST, getFirstdataList);
}

function* deleteManufacturerTableRowSagaWatcher() {
  yield takeLatest(manufacturerTypes.DELETE, deleteManufacturerTableRow);
}

function* getSearchSuggestionSagaWatcher() {
  yield takeLatest(manufacturerTypes.GET_SEARCH_SUGGESTION, getSearchSuggestion);
}

function* addTagSagaWatcher() {
  yield takeLatest(manufacturerTypes.ADD_TAG, addTag);
}
function* deleteTagSagaWatcher() {
  yield takeLatest(manufacturerTypes.DELETE_TAG, deleteTag);
}
export default function* rootSaga() {
  yield all([
    fork(getFirstdataListSagaWatcher),
    fork(deleteManufacturerTableRowSagaWatcher),
    fork(getSearchSuggestionSagaWatcher),
    fork(addTagSagaWatcher),
    fork(deleteTagSagaWatcher)
  ]);
}
