import { createTypes, async } from 'redux-action-creator';

const moderationTypes = createTypes(
  [
    ...async('GET_DATA_LIST'),
    'SET_DATA_LIST_PRODUCT_IMAGE_SUCCESS',
    'SET_DATA_LIST_STORE_IMAGE_SUCCESS',
    'SET_USER_PROFILE_IMAGE_SUCCESS',
    'RESET',
    'DELETE_TICKET_SUCCESS'
  ],
  'MODERATION_LIST'
);

export default moderationTypes;
