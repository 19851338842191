// let cancelClick = false;
let processToken = {
  tokenId: null,
  tokenType: null,
  clickedPath: null,
  cancelBtnClick: false
};

function createProcessToken(type, location) {
  // cancelClick = boolVal;
  const randomNum = Math.random() * 999999;
  processToken = {
    ...processToken,
    tokenId: randomNum,
    tokenType: type,
    clickedPath: location.pathname
  };
  return processToken;
}
function setRouterPath(path) {
  processToken = {
    ...processToken,
    clickedPath: path
  };
}
function cancelClick(boolVal) {
  console.log('CALLED CANCEL', boolVal);
  processToken = { ...processToken, cancelBtnClick: boolVal };
}
function returnProcessToken() {
  return processToken;
}

export { createProcessToken, setRouterPath, cancelClick, returnProcessToken };
